import React from "react";
import { Input, Tooltip } from "antd";

export const SearchInput = (props) => {
    return <Tooltip title="我支持回车检索哦"><Input
        size={props.size}
        allowClear
        datatype="round"
        className="yh-search-input"
        placeholder={props.placeholder}
        onPressEnter={() => props.triggerSearch ? props.handleSearch?.() : null}
        value={props.searchValues?.[props.fieldName]}
        onChange={(e) => {
            props.onChange && props.onChange({ [props.fieldName]: e.target.value?.trim() })
            // if (props.triggerSearch) props.handleSearch?.();
        }}
        disabled={props.disabled} />
    </Tooltip>
}