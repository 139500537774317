import React, { useState, useEffect, useRef } from "react"
import { Skeleton, Input, Form, Row, Col, Spin, Button, Select, Upload, Modal, Divider, message } from "antd"
import ListComponent from "@/components/listComponent"
import { userPage } from "@/service/orgService"
import TopSearch from "@/components/TopSearch"
import { getUserInfo } from "@/utils/dataStorage"
import { createPassword } from "@/utils/util"
import { getRoleList, userInsert, userUpdate, getUserOne, userDelete, userBindOfficialQrcode, userBindOfficialQuery } from "@/service/permissin"
import md5 from "js-md5"
import YhTooltip from "@/components/YhTooltip"
const { Option } = Select
const UserList = ({ organId }) => {
    if (!organId) {
        organId = getUserInfo().organId
    }
    const [isModalVisible, setIsModalVisible] = useState(false)
    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 16,
        },
    }
    const [islVisible, setIslVisible] = useState(false)
    const [ticket, setTicket] = useState("")
    const [ticketLoading, setTicketLoading] = useState(false)
    const [materialList, setMaterialList] = useState([]) //列表
    const [modify, setModify] = useState(false)
    const [form] = Form.useForm()
    const [polling, setPolling] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]) //默认展示数据
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [userId, setUserId] = useState("")
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [searchValue, setSearchValue] = useState({ organId })
    useEffect(() => {
        loadData(pagination.current, searchValue)
        getRole()
    }, [])

    useEffect(() => {
        let timer = 0
        if (polling) {
            timer = setInterval(() => {
                userBindOfficialQuery(userId).then((res) => {
                    console.log(res)
                    if (res && res.retData) {
                        message.success("绑定成功")
                        setPolling(false)
                        clearInterval(timer)
                        setIslVisible(false)
                        loadData(pagination.current, searchValue)
                    }
                })
            }, 2000)
        }
        return () => {
            clearInterval(timer)
        }
    }, [polling])
    function getRole() {
        getRoleList({
            searchItem: {},
            pageNum: 1,
            pageSize: 110,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }).then((res) => {
            // if (res && res.retCode === 200) {
            setMaterialList(res.retData.list)
            // }
        })
    }
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleDetails("delete"),
            content: "删除",
        },
        // {
        //     click: () => handleDetails('import'),
        //     content: "批量导入"
        // }
    ]

    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入用户名",
                valueName: "nickname",
            },
            {
                type: "input",
                placeholder: "请输入手机号",
                valueName: "phone",
            },
        ],
    }

    const handleOk = () => {
        // setIsModalVisible(false);
        form.validateFields()
            .then((fieldsValue) => {
                console.log(fieldsValue)
                if (modify) {
                    let data = {
                        ...fieldsValue,
                        organId,
                        id: selectInfo.rowInfo.id,
                    }
                    if (!data.accountPwd) {
                        delete data.accountPwd
                    } else {
                        data.accountPwd = md5(data.accountPwd)
                    }
                    userUpdate(data).then((res) => {
                        // if (res && res.retCode === 200) {
                        form.resetFields()
                        message.success("编辑成功")
                        setIsModalVisible(false)
                        loadData(pagination.current, searchValue)
                        // } else {
                        //     message.error(res.retMsg)
                        // }
                    })
                } else {
                    userInsert({
                        ...fieldsValue,
                        organId,
                        accountPwd: md5(fieldsValue.accountPwd),
                    }).then((res) => {
                        // if (res && res.retCode === 200) {
                        form.resetFields()
                        message.success("新增成功")
                        setIsModalVisible(false)
                        loadData(pagination.current, searchValue)
                        // } else {
                        //     message.error(res.retMsg)
                        // }
                    })
                }
            })
            .catch((err) => {
                message.error(err.retMsg)
            })
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }
    const columns = [
        {
            title: "姓名",
            algin: "center",
            dataIndex: "nickname",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "电话",
            algin: "center",
            dataIndex: "phone",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "属性",
            algin: "center",
            dataIndex: "roleNames",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "所属机构",
            algin: "center",
            dataIndex: "organName",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "账号",
            algin: "center",
            dataIndex: "accountNum", ///////////
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "微信绑定",
            algin: "center",
            dataIndex: "officialUnionId",
            render: (officialUnionId, record) => (
                <span>
                    {!officialUnionId ? (
                        <Button
                            size="small"
                            onClick={() => {
                                setIslVisible(true)
                                setTicketLoading(true)
                                setUserId(record.id)
                                userBindOfficialQrcode(record.id)
                                    .then((res) => {
                                        setTicketLoading(false)
                                        // if (res && res.retCode === 200) {
                                        setTicket(res.retData.ticket)
                                        setPolling(true)
                                        // } else {
                                        //     message.error(res.retMsg)
                                        //     setIslVisible(false)
                                        //     setPolling(false)
                                        // }
                                    })
                                    .catch((res) => {
                                        message.error(res.retMsg)
                                        setIslVisible(false)
                                        setPolling(false)
                                    })
                            }}
                        >
                            绑定
                        </Button>
                    ) : (
                        "已绑定"
                    )}
                </span>
            ),
        },
    ]
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }
    const handleDetails = async (mode) => {
        if (mode === "insert") {
            setIsModalVisible(true)
            setModify(false)
            form.resetFields()
            form.setFieldsValue({
                accountPwd: createPassword(),
            })
        } else if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择！")
                return
            } else {
                setModify(true)
                setIsModalVisible(true)
                console.log(selectInfo)
                getUserOne(selectInfo.rowInfo.id).then((res) => {
                    console.log(res.retData)
                    form.setFieldsValue({
                        ...res.retData,
                        roleIds: res.retData.roles.map((item) => item.id),
                        accountPwd: "",
                    })
                })
            }
        } else if ("delete" === mode) {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择！")
                return
            } else {
                const modal = Modal.confirm({
                    content: `确定要删除人员${selectInfo.rowInfo.accountNum}?`,
                    okText: "确定",
                    cancelText: "取消",
                    centered: true,
                    onOk() {
                        userDelete(selectInfo.rowInfo.id).then((res) => {
                            // if (res && res.retCode === 200) {
                            message.success("删除成功")
                            loadData(pagination.current)
                            // } else {
                            //     message.error(res.retMsg)
                            // }
                        })
                    },
                })
            }
        }
    }
    const loadData = (current, search = {}) => {
        console.log("loadData")
        setLoading(true)
        let data = {
            searchItem: { ...search, orderFlag: 0 },
            pageNum: current,
            pageSize: 10,
            orders: [{ column: "createTime", rule: "DESC" }],
        }
        userPage(data)
            .then((res) => {
                console.log(res)
                if (res && res.retCode === 200) {
                    setpagination({
                        current: current,
                        total: res.retData.total,
                        pageSize: 10,
                    })
                    setData(res.retData.list)
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }
    return (
        <>
            <TopSearch
                title="管理"
                justify="end"
                searchListData={searchList}
                searchValue={searchValue}
                setSearchValue={(obj) => setSearchValue(obj)}
                handleSearch={loadData}
            />
            <ListComponent
                buttons={topButtons}
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={pagination}
                onTableChange={onTableChange}
                selectInfo={selectInfo}
                setSelectInfo={(obj) => setSelectInfo(obj)}
            />
            <Modal title={!modify ? "新增用户" : "编辑用户"} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form form={form} {...formItemLayout}>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item label="姓名" name="nickname" rules={[{ required: true, message: "请输入姓名" }]}>
                                <Input placeholder="请输入姓名" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="电话"
                                name="phone"
                                rules={[
                                    { required: true, message: "请输入电话" },
                                    // { pattern: /^[\u4E00-\u9FA5]{2,5}$/, message: '请输入电话' }
                                ]}
                            >
                                <Input placeholder="请输入电话" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="账号" name="accountNum" rules={[{ required: true, message: "请输入账号" }]}>
                                <Input placeholder="请输入账号" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="密码" name="accountPwd" rules={[{ required: !modify, message: "请输入密码" }]}>
                                <Input.Password placeholder="请输入密码" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="角色" name="roleIds" rules={[{ required: true, message: "请选择角色" }]}>
                                <Select mode="multiple" optionFilterProp="children" placeholder="请选择角色" style={{ width: "100%" }}>
                                    {materialList.map((item) => {
                                        return (
                                            <Option value={item.id} key={item.id}>
                                                {item.roleName}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal
                width={350}
                visible={islVisible}
                footer={null}
                onCancel={() => {
                    setIslVisible(false)
                }}
            >
                <Spin spinning={ticketLoading} tip="Loading...">
                    <Skeleton paragraph={{ rows: 6 }} loading={ticketLoading}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <img
                                style={{
                                    width: "300px",
                                }}
                                src={`https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=` + ticket}
                                alt=""
                            />
                            <span>微信扫描二维码关注云豪车服管家公众号</span>
                        </div>
                    </Skeleton>
                </Spin>
            </Modal>
        </>
    )
}

export default UserList
