import React, { useEffect } from "react";
import { Table, message } from "antd";
import { useStockStatisticModel } from "../../stockStatisticModel";
import { formatNum } from "@/erp_subpackage/utils/util";
import { useGlobalModel } from "@/erp_subpackage/model/globalModel";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

export function DataTable() {
    const {
        dataSource, pgCfg, loading,
        loadScmData
    } = useStockStatisticModel();
    const { setMaterialInfo, setOccupyStockInfo } = useGlobalModel(() => []);

    useEffect(() => {
        // loadScmData();
    }, []);

    const columns = [
        {
            title: '序号',
            width: 60,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            width: 260,
            dataIndex: 'materialCode',
            fixed: "left",
            //render: (text) => <YhTooltip text={text} />,
            // render: (text: any, record: ISCMStockStatisticDetail) => <span
            //     style={{ cursor: "pointer", color: "#00f" }}
            //     onClick={(e) => {
            //         e.stopPropagation();
            //         setGoodsRecordModal({
            //             visible: true, materialCode: record.materialCode, materialName: record.materialName
            //         })
            //     }}>
            //     {text}</span >
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        
        }, {
            title: '商品名称',
            width: 300,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '替换编号',
            width: 200,
            dataIndex: 'replaceCode',
            render: text => <p className="ellipsis" title={text}>{text}</p>

        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseName',
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',

        },
        //  {
        //     title: '期初数量',
        //     width: 100,
        //     dataIndex: 'startNum',
        // },
         {
            title: '库存数量',
            width: 100,
            dataIndex: 'inventoryQuantity',
        }, {
            title: '占用库存',
            width: 80,
            dataIndex: 'occupyNum',
            render: (text, record) => <span
                style={{ cursor: "pointer", color: "#00f" }}
                onClick={(e) => {
                    e.stopPropagation();
                    if (text && +text > 0) {
                        setOccupyStockInfo(true, {
                            materialCode: record.materialCode,
                            shopId: record.shopId,
                            shelfCode: record.shelfCode,
                            branch: "scm"
                        });
                        return;
                    }
                    message.warning("没有占用库存");
                }}>
                {text}</span >
        }, {
            title: '可用库存',
            width: 80,
            render: (text, record) => +(record?.inventoryQuantity ?? 0) - +(record?.occupyNum ?? 0)
        }, {
            title: '最新进价',
            width: 100,
            dataIndex: 'lastestPrice',
            render: text => `￥${formatNum(text)}`
        }, {
            title: '库存成本',
            width: 140,
            dataIndex: 'inventoryAmount',
            render: text => `￥${formatNum(text)}`
        }, 
        // {
        //     title: '平均价',
        //     width: 100,
        //     dataIndex: 'averagePrice',
        //     render: text => `￥${formatNum(text)}`
        // }, {
        //     title: '库存总额(平均价)',
        //     width: 140,
        //     dataIndex: 'averagePriceAmount',
        //     render: text => `￥${formatNum(text)}`
        // }, {
        //     title: '最高售价',
        //     width: 100,
        //     dataIndex: 'highestPrice',
        //     render: text => `￥${formatNum(text)}`
        // }, {
        //     title: '年度平均价',
        //     width: 100,
        //     dataIndex: 'annualAveragePrice',
        //     render: text => `￥${formatNum(text)}`
        // }, 
        {
            title: '进货价格',
            width: 100,
            dataIndex: 'buyingPrice',
            render: text => `￥${formatNum(text)}`
        }, {
            title: '零售价格',
            width: 100,
            dataIndex: 'sellingPrice',
            render: text => `￥${formatNum(text)}`
        }, {
            title: '批发价格',
            width: 100,
            dataIndex: 'distributorPrice',
            render: text => `￥${formatNum(text)}`
        }, {
            title: '优惠价格',
            width: 100,
            dataIndex: 'repairPrice',
        }, {
            title: '商品类型',
            width: 160,
            dataIndex: 'typeName',
        }, 
        // {
        //     title: '单位',
        //     width: 100,
        //     dataIndex: 'unitName',
        // }, {
        //     title: '库存上限',
        //     width: 100,
        //     dataIndex: 'limitUpper',
        // }, {
        //     title: '库存下限',
        //     width: 100,
        //     dataIndex: 'limitLower',
        // }, {
        //     title: '积压标记',
        //     width: 100,
        //     dataIndex: 'overstock',
        // }, {
        //     title: '积压等级',
        //     width: 100,
        //     dataIndex: 'overstockGrade',
        // }, {
        //     title: '库龄',
        //     width: 100,
        //     dataIndex: 'warehouseAge',
        // }, {
        //     title: '报废数量',
        //     width: 100,
        //     dataIndex: 'scrapNum',
        // }, {
        //     title: '报废金额',
        //     width: 100,
        //     dataIndex: 'scrapAmount',
        //     render: text => `￥${formatNum(text)}`
        // }, {
        //     title: '含报废库存',
        //     width: 100,
        //     dataIndex: 'realNum',
        // }, {
        //     title: '最早进货时间',
        //     width: 180,
        //     dataIndex: 'earliestTime',
        // }, {
        //     title: '商品编码',
        //     width: 120,
        //     dataIndex: 'insideCode',
        //     render: text => <p className="ellipsis" title={text}>{text}</p>
        // }, {
        //     title: '商品名称',
        //     width: 120,
        //     dataIndex: 'insideName',
        //     render: text => <p className="ellipsis" title={text}>{text}</p>

        // }
    ];

    return (
        <Table
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadScmData({ pageNum, pageSize }).then();
                }
            }}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}

