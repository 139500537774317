import React, { useState, FC, useEffect } from "react";
import { Button, message, Table } from "antd";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { useGlobalModel } from "@/erp_subpackage/model/globalModel";
import { usePsiModel } from "../psiModel";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { formatNum, momentFormat } from "@/erp_subpackage/utils/util";
import { IremainDetail, psiService } from "../psiService";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";

//期初数
export const StartNumModal = () => {
    const { user: { shopId }, setLoadingInfo } = useGlobalModel();
    const {
        goodsRecordModal: { startVisible: visible, row }, onCancel, setDatailsVisible
    } = usePsiModel();
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (visible && row) {
            loadData()
        }
    }, [visible])

    const loadData = async () => {
        setLoading(true);
        try {
            const searchParams = {
                shopId,
                materialCode: row?.materialCode,
                types: ["init"],
            }
            let { retData } = await psiService.purchaseSaleStorageDeatil(searchParams);
            setDataSource(retData ?? []);
            setLoading(false)
        } catch {
            setLoading(false);
            setDataSource([])
        }
    }


    //导出
    const exportDetail = async () => {
        if (!dataSource.length) {
            message.warning("无数据");
            return;
        }
        setLoadingInfo({ loadingVisible: true, txt: "快速导出中" });
        try {
            const searchParams = {
                shopId,
                materialCode: row?.materialCode,
                types: ["init"],
            }
            let result = await psiService.purchaseSaleStorageDeatilExport(searchParams);
            result && setLoadingInfo({ loadingVisible: false, txt: "" });
            convertRes2Blob(result);
        } catch { setLoadingInfo({ loadingVisible: false, txt: "" }) }
    }

    const columns = [
        {
            title: '序号',
            width: 66,
            align: "center",
            render: (t, r, i) => i + 1
        },
        // {
        //     title: '单据类型',
        //     width: 180,
        //     align: "center",
        //     dataIndex: 'createTime',
        // }, {
        //     title: '单位名称',
        //     width: 180,
        //     align: "center",
        //     dataIndex: 'createTime',
        // },
        {
            title: '业务日期',
            width: 180,
            align: "center",
            dataIndex: 'createTime',
        }, {
            title: '单据编号',
            width: 120,
            align: "center",
            dataIndex: 'code',
            render: (text, record, index) => <span className="click-span" onClick={(e) => setDatailsVisible({
                visible: true,
                code: text,
                type: record.type
            })}> {text}</span >,
        }, {
            title: '单价',
            width: 160,
            align: "center",
            dataIndex: 'price',
            render: text => `￥${formatNum(text)}`
        }, {
            title: '数量',
            width: 140,
            align: "center",
            dataIndex: 'num',
        }, {
            title: '金额',
            width: 120,
            align: "center",
            render: (text, record) => `￥${formatNum(record.num * record.price)}`
        },
        // {
        //     title: '业务员',
        //     width: 180,
        //     align: "center",
        //     dataIndex: 'createTime',
        // }, {
        //     title: '备注',
        //     width: 180,
        //     align: "center",
        //     dataIndex: 'createTime',
        // },
    ];

    const action = <Button
        className="btn-item"
        shape="round"
        onClick={() => exportDetail()}
    >导出</Button>

    const desc = [
        {
            label: "期初数量",
            value: dataSource.reduce((c, n) => { c = c + +(n.num ?? 0); return c }, 0),
            color: "red"
        },
    ]

    return <YHModal
        visible={visible}
        onCancel={onCancel}
        title={<p><span>期初数:{row?.beginNum}</span><span className="ml-20">商品编号:{row?.materialCode}</span><span className="ml-20">商品名称:{row?.materialName}</span></p>}
        width={1300}
        bodyHeight={550}
    >
        <YhBox title={action} descriptions={desc}>
            <Table
                loading={loading}
                rowKey={record => record.id}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    </YHModal>
}