import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { organIncome, organIncomeExport } from "@/service/statistics"
import { MyContext } from "@/store/myContext"
import moment from "moment"
import { message } from "antd"

export default () => {
    const { state } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({
        startTime: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
        endTime: moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
    })
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [order, setOrder] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (state.activeMenu === "organIncome") {
            setSearchValue({
                startTime: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
                endTime: moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
            })
            loadData(pagination.current)
        }
    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    //列表数据
    const loadData = (current, search = {}, order) => {
        setLoading(true)
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: order
                ? [order]
                : [
                      {
                          column: "updateTime",
                          rule: "DESC",
                      },
                  ],
            startTime: search.startTime ? search.startTime : moment().startOf("month").format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
            endTime: search.endTime ? search.endTime : moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
        }
        organIncome(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
            setLoading(false)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page, filters, sorter) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        let order = { column: "updateTime", rule: "DESC" }
        if (sorter && sorter.field && sorter.order) {
            let rule = sorter.order === "ascend" ? "ASC" : "DESC"
            order = {
                column: sorter.field,
                rule,
            }
        }
        setOrder(order)
        loadData(page.current, searchValue, order)
    }

    const handleExport = () => {
        let data = {
            searchItem: { ...searchValue },
            orders:
                Object.keys(order).length > 0
                    ? [order]
                    : [
                          {
                              column: "updateTime",
                              rule: "DESC",
                          },
                      ],
            startTime: searchValue.startTime ? searchValue.startTime : moment().startOf("month").format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
            endTime: searchValue.endTime ? searchValue.endTime : moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
        }
        organIncomeExport(data).then((res) => {
            message.success(res.retData)
        })
    }

    //列表button
    const topButtons = [
        {
            click: () => handleExport(),
            content: "导出",
        },
    ]

    const columns = [
        {
            title: "机构名称",
            align: "center",
            dataIndex: "organName",
        },
        {
            title: "客户数量",
            align: "center",
            dataIndex: "customerNum",
            sorter: true,
        },
        {
            title: "用户数量",
            align: "center",
            dataIndex: "customerUserNum",
            sorter: true,
        },
        {
            title: "车辆数量",
            align: "center",
            dataIndex: "vehicleNum",
            sorter: true,
        },
        {
            title: "三包订单数量",
            align: "center",
            dataIndex: "threeGuaranteesOrderNum",
            sorter: true,
        },
        {
            title: "三包工单金额",
            align: "center",
            dataIndex: "threeGuaranteesOrderAmount",
            sorter: true,
        },
        {
            title: "收费工单数量",
            align: "center",
            dataIndex: "tollOrderNum",
            sorter: true,
        },
        {
            title: "收费工单金额",
            align: "center",
            dataIndex: "tollOrderAmount",
            sorter: true,
        },
        {
            title: "收费工单工时费用",
            align: "center",
            dataIndex: "tollOrderItemAmount",
            sorter: true,
        },

        {
            title: "收费工单配件费用",
            align: "center",
            dataIndex: "tollOrderPartAmount",
            sorter: true,
        },
        {
            title: "收费工单其他项目费用",
            align: "center",
            dataIndex: "tollOrderOtherItemAmount",
            sorter: true,
        },
        {
            title: "优惠金额",
            align: "center",
            dataIndex: "tollOrderPreferentialAmount",
            sorter: true,
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                span: 16,
                xxl: 8,
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "organIncome" && (
                <>
                    <TopSearch
                        title="机构占比"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="机构占比"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        scroll={{ x: "max-content" }}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        loading={loading}
                        rowKey={(record) => record.organName}
                    />
                </>
            )}
        </>
    )
}
