import React, { useEffect, useState } from "react";
import { Table, Button, DatePicker, Input } from "antd";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import moment from "moment";
import { momentFormat } from "@/erp_subpackage/utils/util";
import { useStockDetailedModel } from "../stockDetailedModel";
import { stockStatisticService } from "@/erp_subpackage/pages/stock/statistic/stockStatisticService";
import { useGlobalModel, transPgToPagination } from "@/erp_subpackage/model/globalModel";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

const { RangePicker } = DatePicker;

export const TranRecord = () => {
    const {
        recordsVisible: visible, shopId, setRecordsVisible
    } = useStockDetailedModel()
    const { user: {  shopName, shopCode }, whDs, setMaterialInfo } = useGlobalModel(({ user, whDs }) => [user, whDs]);
    //移库记录列表
    const [dataSource, setDataSource] = useState([]);
    //移库记录检索
    const [searchParam, setSearchParam] = useState({});

    const loadData = async (searchVal = searchParam) => {
        let params = {
            ...searchVal,
            pageNum: 1,
            pageSize: 10000,
            shopId,
        }
        let { retData } = await stockStatisticService.records(params);
        let { records } = retData
        setDataSource(records)
    }

    const onCancel = () => {
        setRecordsVisible(false);
        setSearchParam({})
    }

    useEffect(() => {
        visible && loadData()
    }, [visible])

    //实时详情列表货位数据
    const columns = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            width: 120,
            fixed: "left",
            dataIndex: 'materialCode',
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        }, {
            title: '商品名称',
            width: 120,
            fixed: "left",
            dataIndex: 'materialName',
        }, {
            title: '原始仓库',
            width: 160,
            dataIndex: 'warehouseName',
        }, {
            title: '原始货位',
            width: 120,
            dataIndex: 'shelfCode',
        }, {
            title: '可转库存',
            width: 100,
            dataIndex: 'availableStock',
        }, {
            title: '转移仓库',
            width: 120,
            dataIndex: 'warehouseNameNew',
        }, {
            title: '转移货位',
            width: 120,
            dataIndex: 'shelfCodeNew',
        }, {
            title: '转移库存',
            width: 120,
            dataIndex: 'relocationNum'
        }, {
            title: '移库时间',
            width: 120,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }
    ]
    const rightChildren = <div className="flex">
        <RangePicker
            size="small"
            placeholder={['转移日期开始', '转移日期结束']}
            className="mr-10"
            format={"YYYY-MM-DD"}
            value={!searchParam.startTime || !searchParam.endTime ? null : [moment(searchParam.startTime), moment(searchParam.endTime)]
            }
            // onChange={(a: any, arr: string[]) => setSearchParam({ ...searchParam, startTime: arr[0].replace(/\s+/g, "T"), endTime: arr[1].replace(/\s+/g, "T") })}
            onChange={(a, arr) => setSearchParam({ ...searchParam, startTime: arr[0] ? arr[0] + 'T00:00:00' : "", endTime: arr[1] ? arr[1] + 'T23:59:59' : "" })}
        />
        <Input
            allowClear
            size="small"
            className="mr-10"
            value={searchParam.materialCode}
            style={{ width: "250px" }}
            onChange={(e) => setSearchParam({ ...searchParam, materialCode: e.target.value?.trim() })}
            placeholder="商品编号"
        />
        < Button className="btn-item" shape="round" onClick={() => loadData()}> 检索</Button >
        < Button className="btn-item" shape="round" onClick={() => { setSearchParam({}); loadData({}) }}> 重置</Button >

    </div>
    return <YHModal
        visible={visible}
        onCancel={onCancel}
        title="转移记录"
        width={1000}
        bodyHeight={700}
        showFooter={false}
    >
        <YhBox title=" " rightChildren={rightChildren} style={{ margin: "0" }}>
            <Table
                style={{ cursor: "pointer" }}
                columns={columns}
                dataSource={dataSource}
                scroll={{ x: 'max-content', y: 520 }}
                pagination={false}
            >
            </Table>
        </YhBox>
    </YHModal>
}