import { useState, useEffect } from "react";
import { createModel } from "hox";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { stockStatisticService } from "./stockStatisticService";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import { formatNum, getRandomStr } from "@/erp_subpackage/utils/util";
import { commonService } from "@/erp_subpackage/pages/common/commonService";
import { stockDetailedService } from "../detailed/stockDetailedService";
import { message } from "antd";
const initDesc = {
    averageAmount: 0,
    type: 0,
    latestAmountTotal: 0,
    total: 0,
    stockAmount: 0, stockNum: 0
}

export const useStockStatisticModel = createModel(function () {
    const { user: { shopId, shopName, shopCode, organType, orgCode, orgName, allowTransfer } } = useGlobalModel()
    //列表数据源
    const [dataSource, setDataSource] = useState([]);

    const [descTotal, setDescTotal] = useState(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState(initDesc);
    const [loading, setLoading] = useState({ spinning: false, tip: "加载中..." });
    //查询条件
    const initSearchParams = { diffField: "机构" }
    const [searchParam, setSearchParam] = useState(initSearchParams);
    //分页参数
    const initPgCfg = { pageSize: 15, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    //表格多选row
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        let newRowsDescTotal = { ...initDesc };
        selectedRowKeys.forEach((item, index) => {
            let { inventoryNum, latestAmount, averageAmount } = item;
            newRowsDescTotal.averageAmount += +formatNum(averageAmount)
            newRowsDescTotal.type += 1
            newRowsDescTotal.latestAmountTotal += +formatNum(latestAmount)
            newRowsDescTotal.total += inventoryNum
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [selectedRowKeys])

    //列表查询
    const loadData = async (page, searchValue = searchParam) => {
        setLoading({ spinning: true, tip: "列表加载中..." });
        let param = {...initSearchParams,...searchValue,materialCode:searchValue.materialCode?.trim(),materialName:searchValue.materialName?.trim()}
        setSearchParam(param)
        const searchParams = {
            ...searchParam,
            outerShopId: undefined,
            diffField: undefined,
            shopId: searchValue.shopId ?? shopId,
            shopName, shopCode, orgCode, orgName,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
        }
        let { retData } = await stockStatisticService.page(searchParams);
        // let { inventoryTotal, list } = retData[0];
        let { records, ...pg } = retData
        setPgCfg(transPgToPagination({ ...pg, pageNum: pg.current }));
        setDataSource(records.map(item => ({ ...item, key: getRandomStr() })));
        setLoading({ spinning: false });
        setSelectedRowKeys([]);
        let { retData: statistics } = await stockDetailedService.amount(searchParams);
        setDescTotal({ ...initDesc, ...statistics })
    }




    //设置搜索value
    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = async () => {
        await setSearchParam(initSearchParams);
        await setSelectedRowKeys([]);
        await loadData(initPgCfg, {});
    }
    //导出
    const exportList = async (searchValue = searchParam) => {
        // setLoading({ tip: "文件导出中", spinning: true });
        const searchParams = {
            searchItem:{
                ...searchValue,
                outerShopId: undefined,
                diffField: undefined,
                shopId: searchValue.shopId ?? shopId,
                shopName, shopCode, orgCode, orgName,
                id: selectedRowKeys.map(item => item.id)?.join(),
            }
        }

        let {retData} = await stockStatisticService.export(searchParams);
        message.success(retData)
        // convertRes2Blob(result);
        // setLoading({ spinning: false });
    }



    //供应链
    const [statistics, setStatistics] = useState({
        startNum: 0,
        startAmount: 0,
        inventoryNum: 0,
    });
    const loadScmData = async (page, searchValue = searchParam) => {
        if (!searchValue.outerShopId) {
            message.warning("请选择要查询的门店");
            setDataSource([])
            setPgCfg(initPgCfg)
            return
        }
        setLoading({ spinning: true, tip: "列表加载中..." });
        const searchParams = {
            ...searchValue,
            outerShopId: undefined,
            diffField: undefined,
            shopId: searchValue.outerShopId ?? shopId,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
        }
        let { retData } = await commonService.scmStockpage(searchParams);
        // let { retData: Data } = await commonService.scmStatistics({ ...searchParams, pageSize: -1, warehouseCode:" " });
        const { records, ...pg } = retData;

        setPgCfg(transPgToPagination(pg));
        setDataSource(records.map(item => ({
            ...item,
            key: getRandomStr()
        })));
        setLoading({ spinning: false });
        // setStatistics(Data);
    }
    //导出
    const exportStockStatistic = async (searchValue = searchParam) => {
        setLoading({ tip: "文件导出中", spinning: true });
        try {
            const searchParams = {
                ...searchValue,
                outerShopId: undefined,
                pageSize: -1,
                pageNum: 1,
            }

            let result = await commonService.scmStockExport(searchParams);
            convertRes2Blob(result);
            setLoading({ spinning: false });
        } catch { setLoading({ spinning: false }); }
    }

    //md
    const [mdShopList, setMdShopList] = useState([]);
    //获取主数据门店列表
    const getMdShopList = async () => {
        let { retData } = await commonService.getMdShop({ branch: "scm" });
        const { records, ...pg } = retData;
        setMdShopList((records ?? []).map(item => ({
            label: item.name,
            value: item.id,
            shopcode: item.code,
            branch: item.branch
        })))
    }

    return {
        /***列表***/
        searchParam, organType, allowTransfer,
        dataSource,
        selectedRowKeys,
        pgCfg,
        loading, rowsDescTotal, descTotal,
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        setSearchParam,
        exportList,
        //scm
        statistics,
        loadScmData,
        exportStockStatistic,
        //md
        mdShopList,
        getMdShopList,
        initSearchParams,initPgCfg,
    }
});