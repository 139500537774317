import React, { useRef } from "react"
import { YHModal } from "@/erp_subpackage/components/YHModal"
import { useSaleOrderDetailModel } from "./saleOrderDetailModel"
import { YhFormBox } from "@/erp_subpackage/components/YhFormBox"

export const CheckModel = ({ modeKey, mode, rowId, dispatch }) => {
    const { checkModal: visible, detailEditForm, setCheckModal, onExamine, getDetailEditOne } = useSaleOrderDetailModel()
    const formRef = useRef()

    // 拒绝
    const onCancel = () => {
        formRef.current.validateFields().then(async (res) =>{
            let data = {
                ...res,
                id: detailEditForm.id,
                status: "0",
            }
            try {
                let retData = await onExamine(data)
                if(retData){
                    getDetailEditOne(rowId, mode)
                    setCheckModal(false)
                }
            } catch (error) {
                setCheckModal(false)
            }
        })
    }

    // 通过
    const onOk = async () => {
        formRef.current.validateFields().then(async (res) => {
                let data = {
                    ...res,
                    id: detailEditForm.id,
                    status: "1",
                }
                try {
                    let retData = await onExamine(data)
                    if(retData){
                        dispatch({
                            type: "removeTabs",
                            targetKey: modeKey,
                            activeMenu: "saleRo",
                        })
                        setCheckModal(false)
                    }
                } catch (error) {
                    setCheckModal(false)
                }
            })
            .catch((err) => {})
    }

    const onClose = () =>{
        setCheckModal(false)
    }

    const ItemBox = {
        style: { padding: "60px 30px 80px 10px" },
        items: [
            {
                type: "Input",
                fieldLabel: "退款金额",
                fieldName: "totalAmount",
                span: 24,
            },
            {
                type: "Input",
                fieldLabel: "审核备注",
                fieldName: "advise",
                span: 24,
            },
        ],
    }
    const formConfig = {
        formRef,
        formValues: {
            totalAmount:detailEditForm.totalAmount ?? 0
        },
        labelCol: { span: 6 },
        boxs: [ItemBox],
    }
    return (
        <YHModal
            visible={visible}
            onOk={onOk}
            onCancel={onClose}
            onClose={onCancel}
            title="退款审核"
            width={500}
            bodyHeight={200}
            showFooter={true}
            okText="通过"
            cancelText="拒绝"
        >
            <YhFormBox {...formConfig} />
        </YHModal>
    )
}
