import React, { useContext, useEffect, useState } from "react"
import { orderHistory } from "@/service/ordersService"
import { MyContext } from "@/store/myContext"
import Modal from "antd/es/modal/Modal"
import { Table, Button, Row, Col } from "antd"
import { getPcodeDict } from "@/utils/dataStorage"
import TopSearch from "@/components/TopSearch"

export default ({ vehicleId, customerId, isLook, orderStatus, choosedRepairHistoryOk }) => {
    const { message } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [visible, setVisible] = useState(false)
    const [choosedData, setChoosedData] = useState({ orderParts: [], orderItems: [] })

    const [searchValue, setSearchValue] = useState({})

    const [loading, setLoading] = useState(false)
    const [lookDetail, setLookDetail] = useState({})
    const [searchName, setSearchName] = useState("")
    const [dictObj, setDictObj] = useState(null)

    useEffect(() => {
        //初始下工单用到的字典
        initDict()
    }, [])
    const initDict = () => {
        let obj = {
            serviceNature: {}, //服务方式
        }
        getPcodeDict("016").children.forEach((item) => {
            obj.serviceNature[item.dictCode] = item.title
        })
        setDictObj(obj)
    }

    useEffect(() => {
        if (vehicleId) {
            setSearchName("vehicleId")
        } else if (customerId) {
            setSearchName("customerId")
        } else {
            setSearchName("")
        }
    }, [vehicleId, customerId])

    useEffect(() => {
        if (searchName) {
            loadData(1)
        }
    }, [searchName])

    useEffect(() => {
        setChoosedData({ orderParts: [], orderItems: [] })
    }, [visible])

    // 修改搜索
    const onChangeSearchVal = (val) => {
        if (val === "vehicleId") {
            if (vehicleId) {
                setSearchName(val)
            }
        }
        if (val === "customerId") {
            if (customerId) {
                setSearchName(val)
            }
        }
    }

    const handleChoose = (data) => {
        console.log(data)
        if (isLook) {
            return message.error("查看模式不可选择")
        }

        // if(orderStatus === "1"){
        //     return message.error("该工单状态不可选择")
        // }
        message.success("同步成功")

        choosedRepairHistoryOk(data)
    }

    //列表数据
    const loadData = (current, searchValue) => {
        let searchItem = {}
        if (searchName === "vehicleId") {
            searchItem = { vehicleId }
        }
        if (searchName === "customerId") {
            searchItem = { customerId }
        }
        let data = {
            searchItem:{...searchValue, ...searchItem},
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        orderHistory(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setList(res.retData.list)
            setLoading(false)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }

    //table配置
    const columns = [
        {
            title: "工单编号",
            align: "center",
            dataIndex: "orderCode",
        },
        {
            title: "车辆牌照",
            align: "center",
            dataIndex: "vehicleCode",
            width: 140,
        },
        {
            title: "故障描述",
            align: "center",
            dataIndex: "faultDesc",
        },
        {
            title: "行驶里程",
            align: "center",
            dataIndex: "customerVehicleMileage",
        },
        {
            title: "维修项目",
            align: "center",
            dataIndex: "orderItems",
            render: (text) => {
                let br = <br></br>
                let result = null
                for (let j = 0; j < text.length; j++) {
                    const data = (
                        <span>
                            {text[j].itemCode} {text[j].itemName} {text[j].itemWorkHours}*{text[j].workHoursPrice}
                            <b style={{ color: "red" }}>{text[j].itemDeleteStatus ? "(已删除)" : ""}</b>
                        </span>
                    )
                    if (j === 0) {
                        result = data
                    } else {
                        result = (
                            <>
                                {result}
                                <span>
                                    {br}
                                    {data}
                                </span>
                            </>
                        )
                    }
                }
                return <div>{result}</div>
            },
        },
        {
            title: "维修用料",
            align: "center",
            dataIndex: "orderParts",
            render: (text) => {
                let br = <br></br>
                let result = null
                for (let j = 0; j < text.length; j++) {
                    const data = (
                        <span>
                            {text[j].partCode} {text[j].partName} {text[j].partNumber}*{text[j].unitPrice}
                            <b style={{ color: "red" }}>{text[j].partDeleteStatus ? "(已删除)" : ""}</b>
                        </span>
                    )
                    if (j === 0) {
                        result = data
                    } else {
                        result = (
                            <>
                                {result}
                                <span>
                                    {br}
                                    {data}
                                </span>
                            </>
                        )
                    }
                }
                return <div>{result}</div>
            },
        },
        {
            title: "应收金额",
            align: "center",
            dataIndex: "orderActualAmount",
        },
        {
            title: "操作",
            align: "center",
            width: "12%",
            dataIndex: "disableFlag",
            render: (text, record) => (
                <>
                    <Button
                        type="text"
                        style={{ color: "#2e3aeb" }}
                        onClick={() => {
                            setLookDetail(record)
                            setVisible(true)
                        }}
                    >
                        查看
                    </Button>
                    {/* <Button type="text" style={{ color: "#2e3aeb" }} onClick={() => handleChoose(record)}>
                        直接同步
                    </Button> */}
                </>
            ),
        },
    ]

    // 维修table配置
    const itemColumns = [
        {
            title: "项目编码",
            align: "center",
            dataIndex: "itemCode",
        },
        {
            title: "项目名称",
            align: "center",
            dataIndex: "itemName",
            render: (text, record) => (
                <>
                    {text}
                    <b style={{ color: "red" }}>{record.itemDeleteStatus ? "(已删除)" : ""}</b>
                </>
            ),
        },
        {
            title: "服务性质",
            align: "center",
            dataIndex: "serviceNature",
            render: (text) => <>{dictObj.serviceNature[text]}</>,
        },
        {
            title: "工时",
            align: "center",
            dataIndex: "itemWorkHours",
        },
        {
            title: "单价",
            align: "center",
            dataIndex: "workHoursPrice",
        },
        {
            title: "工时费",
            align: "center",
            render: (text, record) => <>{record.itemWorkHours * record.workHoursPrice}</>,
        },
        {
            title: "优惠",
            align: "center",
            dataIndex: "preferentialAmount",
        },
        {
            title: "折扣",
            align: "center",
            dataIndex: "itemDiscount",
        },
    ]

    // 维修table配置
    const partColumns = [
        {
            title: "商品编码",
            align: "center",
            dataIndex: "partCode",
        },
        {
            title: "商品名称",
            align: "center",
            dataIndex: "partName",
            render: (text, record) => (
                <>
                    {text}
                    <b style={{ color: "red" }}>{record.partDeleteStatus ? "(已删除)" : ""}</b>
                </>
            ),
        },
        {
            title: "服务性质",
            align: "center",
            dataIndex: "serviceNature",
            render: (text) => <>{dictObj.serviceNature[text]}</>,
        },
        {
            title: "数量",
            align: "center",
            dataIndex: "partNumber",
        },
        {
            title: "单价",
            align: "center",
            dataIndex: "unitPrice",
        },
        {
            title: "总价",
            align: "center",
            dataIndex: "actualAmount",
        },
        {
            title: "优惠",
            align: "center",
            dataIndex: "preferentialAmount",
        },
    ]

    const itemRowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setChoosedData({ ...choosedData, orderItems: selectedRows })
        },
    }

    const partRowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setChoosedData({ ...choosedData, orderParts: selectedRows })
        },
    }

    const onCancel = () => {
        setVisible(false)
    }

    const onOk = () => {
        if (choosedData.orderItems.length === 0 && choosedData.orderParts.length === 0) {
            message.error("没有要同步的信息")
        } else {
            handleChoose(choosedData)
            setVisible(false)
        }
    }

     //搜索JSON
     const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "维修项目名称",
                valueName: "itemName",
            },
            {
                type: "input",
                placeholder: "维修物料名称",
                valueName: "partName",
            },
        ],
    }

    return (
        <>
            <Row>
                <Col span={4} style={{marginBottom:"-10px"}}>
                    <Button
                        type={searchName === "vehicleId" ? "primary" : "dashed"}
                        onClick={() => {
                            onChangeSearchVal("vehicleId")
                        }}
                    >
                        车辆
                    </Button>
                    <Button
                        type={searchName === "customerId" ? "primary" : "dashed"}
                        onClick={() => {
                            onChangeSearchVal("customerId")
                        }}
                    >
                        客户
                    </Button>
                </Col>
                <Col span={20}>
                <TopSearch
                    // title="维修模版"
                    searchListData={searchList}
                    searchValue={searchValue}
                    setSearchValue={(obj) => setSearchValue(obj)}
                    handleSearch={loadData}
                />
                </Col>
            </Row>

            <Table
                rowKey={(record) => record.orderCode}
                loading={loading}
                columns={columns}
                dataSource={list}
                pagination={pagination}
                onChange={onTableChange}
                style={{marginTop:"10px"}}
            />

            {/* 查看弹窗 */}
            <Modal
                visible={visible}
                width={1260}
                onCancel={onCancel}
                footer={[
                    <Button onClick={onCancel} key="back">
                        {" "}
                        取消
                    </Button>,
                    // <Button type="primary" onClick={onOk} key="submit">
                    //     确认同步
                    // </Button>,
                ]}
            >
                <div style={{ margin: "30px 0 10px 0", fontSize: "20px" }}>
                    维修项目{" "}
                    <span style={{ fontSize: "14px" }}>
                        总金额¥<b style={{ color: "red" }}>{lookDetail.itemActualAmount || 0}</b>元（
                        {(lookDetail.orderItems && lookDetail.orderItems.length) || 0}项）
                    </span>
                </div>
                <Table
                    rowSelection={itemRowSelection}
                    size="small"
                    rowKey={(record) => record.id}
                    dataSource={lookDetail.orderItems}
                    columns={itemColumns}
                    pagination={false}
                />
                <div style={{ margin: "30px 0 10px 0", fontSize: "20px" }}>
                    维修物料{" "}
                    <span style={{ fontSize: "14px" }}>
                        总金额¥<b style={{ color: "red" }}>{lookDetail.partActualAmount || 0}</b>元（
                        {(lookDetail.orderParts && lookDetail.orderParts.length) || 0}项）
                    </span>
                </div>
                <Table
                    rowSelection={partRowSelection}
                    size="small"
                    rowKey={(record) => record.id}
                    dataSource={lookDetail.orderParts}
                    columns={partColumns}
                    pagination={false}
                />
            </Modal>
        </>
    )
}
