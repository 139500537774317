import { createModel } from "hox";
import React, { useState, useEffect } from "react";
import { message } from "antd";
import { saleRoService } from "./saleRoService"
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { formatNum } from "@/erp_subpackage/utils/util";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"

export const useSaleRoModel = createModel(function () {
    const { user: { shopId, shopCode, shopName, organType, organTypeCode } } = useGlobalModel();
    const [print, setPrint] = useState(false);
    const initSearchParam = {};
    const initDataSource = [];
    //查询条件
    const [searchParam, setSearchParam] = useState(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState(initDataSource);
    const [loading, setLoading] = useState({ spinning: false, tip: "加载中..." });
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState({});
    //列表数据源分页参数
    const initPgCfg = { pageSize: 15, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.returnCode === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData)
    }, [selectedRowKeys, dataSource])
    const initDesc = {
        totalNum: 0,
        totalAmount: 0,
        discountTotalAmount: 0,
        receivedAmount: 0,
        latestPurPrice: 0,
        receivableAmount: 0,
        grossProfit: 0
    }
    const [descTotal, setDescTotal] = useState(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState(initDesc);
    const [descRows, setDescRows] = useState([]);
    const [drawerVisible, setDrawerVisible] = useState(false);

    useEffect(() => {
        let newRowsDescTotal = initDesc
        descRows.forEach((item) => {
            if (item.status !== DictCodeEnmu.SALE_RETURN_STATUS_CHANGE) {
                let { totalNum, receivedAmount, receivableAmount, totalCost } = item
                newRowsDescTotal.totalNum += +formatNum(totalNum)
                newRowsDescTotal.totalAmount += +formatNum(receivedAmount)
                newRowsDescTotal.receivableAmount += +formatNum(receivableAmount)
                newRowsDescTotal.latestPurPrice += +formatNum(totalCost)
            }
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [descRows])
    const updatePgCfg = (updateParam) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
        setSelectedRow({})
    }

    const loadData = async (page, searchValue = searchParam) => {
        setLoading({ tip: "列表加载中...", spinning: true });
        let status = Array.isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status;
        const searchParams = {
            ...searchValue, status,
            // shopId: searchValue.shopId ?? shopId,
            // shopId: searchValue.shopId ?? '',//默认查全部
            shopId: searchValue.shopId ? searchValue.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
            // shopName: searchValue.shopName ?? shopName,
            shopCode,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: page?.pageNum ?? pgCfg.current ?? initPgCfg.current,
        }
        let { retData } = await saleRoService.page(searchParams);
        const { records, ...pg } = retData
        let newPg = { ...pg, pageNum: pg.current }
        setPgCfg(transPgToPagination(newPg));
        setDataSource(records);
        setSelectedRowKeys([])
        let { retData: statistics } = await saleRoService.total(searchParams);
        setDescTotal(statistics)
        setLoading({ spinning: false });
    }

    const invalidate = async (cancelReason) => {
        let { id, returnCode } = selectedRow
        let { retData } = await saleRoService.invalidate({ id, returnCode, cancelReason });
        if (retData) {
            loadData()
            message.success("作废成功")
        }
    }

    // 列表导出
    const exportSaleRo = async (searchValue = searchParam) => {
        // setLoading({ tip: "文件导出中", spinning: true });
        let status = Array.isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status;
        const searchParams = {
            searchItem:{
                ...searchValue,
                status,
                // shopId,
                shopId: searchValue.shopId ? searchValue.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
                shopName,
                shopCode,
                id: descRows.map(item => item.id).join(),
            }
        }
        let {retData} = await saleRoService.export(searchParams);
        message.success(retData)
        // convertRes2Blob(result)
        // setLoading({ spinning: false });
    }

    // 明细导出
    const detailsExport = async (searchValue = searchParam) => {
        setLoading({ tip: "文件导出中", spinning: true });
        let status = Array.isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status;
        const searchParams = {
            ...searchValue,
            status,
            // shopId,
            shopId: searchValue.shopId ? searchValue.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
            shopName,
            shopCode,
            id: descRows.map(item => item.id).join(),
        }
        let result = await saleRoService.detailsExport(searchParams);
        convertRes2Blob(result)
        setLoading({ spinning: false });
    }
    const [settleScaniaPrint, setSettleScaniaPrint] = useState(false);

    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        drawerVisible,
        initPgCfg,
        descTotal,
        selectedRow,
        print,
        rowsDescTotal,
        descRows,
        settleScaniaPrint,
        loading,
        organType,
        organTypeCode,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setDrawerVisible,
        invalidate,
        setDescTotal,
        setSelectedRow,
        exportSaleRo,
        setPrint,
        detailsExport,
        setRowsDescTotal,
        setDescRows,
        setSettleScaniaPrint,
    }
});