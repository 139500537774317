import React, { useContext } from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { useSaleRoModel } from "../saleRoModel";
import { SalePrint } from "./SalePrint";
import { MyContext } from "@/store/myContext"

export default () => {
    const { print } = useSaleRoModel();
    const { state } = useContext(MyContext)
    return (
        <>
            {state.activeMenu === "saleRo" && (
                <>
                    <PageHeader />
                    <DataBox />
                    {print && <SalePrint />}
                </>
            )}
        </>
    )
}