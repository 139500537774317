import React, { useContext, useEffect, useState } from "react"
import { Button, Col, Row, message, Select, Divider, DatePicker, Table } from "antd"
import { MyContext } from "@/store/myContext"
// import "./statistics.less"
import moment from "moment"
import { customerLastData, customerStatistics } from "@/service/statistics"
import { organPage } from "@/service/orgService"
import { download, convertRes2Blob } from "@/utils/exportExcel"
import Loading from "@/components/Loading"
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import CustomerList from "./list"
const { RangePicker } = DatePicker


const { Option } = Select
//机构列表
const Statistics = () => {
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const [analyzeType, setAnalyzeType] = useState("")
    const [org, setOrg] = useState([])
    const [loading, setLoading] = useState(false) //团队列表loading
    const [searchValue, setSearchValue] = useState({}) //搜索
    const [accountStaticSummary, setAccountStaticSummary] = useState({
        repairCustomerNum: 0, //"报修客户数量",
        noRepairCustomerNum: 0, // "未报修客户数量",
        newCustomerNum: 0, //"新增客户数量",
        page: [],
    })
    const [newStatic, setNewStatic] = useState({
        repairCustomerNum: 0, //"报修客户数量",
        activeCustomerNum: 0, //"活跃客户数量",
        notActivatedCustomerNum: 0, // "待激活客户数量",
        platformCustomerNum: 0, //"平台客户`指客户总数`"
    })
    const { state } = useContext(MyContext)
    const [pagination, setpagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [param, setParam] = useState({
        startTime: moment(new Date()).format("YYYY-MM-DD"),
        endTime: moment(new Date()).format("YYYY-MM-DD"),
    })
    useEffect(() => {
        //
        if (state.activeMenu === "customerStatistics") {
            customerLastData().then((res) => {
                setNewStatic(res.retData)
            })
        }
        organPage({
            searchItem: { orderFlag: 0 },
            pageNum: 1,
            pageSize: 100,
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            setOrg(res.retData.list)
        })
        return () =>
            setNewStatic({
                repairCustomerNum: 0, //"报修客户数量",
                activeCustomerNum: 0, //"活跃客户数量",
                notActivatedCustomerNum: 0, // "待激活客户数量",
                platformCustomerNum: 0, //"平台客户`指客户总数`"
            })
    }, [state.activeMenu])
    useEffect(() => {
        if (state.activeMenu === "customerStatistics") {
            loadData(pagination.current)
        }
        return () =>
            setAccountStaticSummary({
                repairCustomerNum: 0, //"报修客户数量",
                noRepairCustomerNum: 0, // "未报修客户数量",
                newCustomerNum: 0, //"新增客户数量",
                page: [],
            })
    }, [state.activeMenu, pagination.current])// eslint-disable-line react-hooks/exhaustive-deps

    //分页事件
    const onTableChange = async (pagination) => {
        setpagination({
            ...pagination,
            current: pagination.current,
        })
    }
    function onChange(dates, dateStrings) {
        console.log("From: ", dates[0], ", to: ", dates[1])
        console.log("From: ", dateStrings[0], ", to: ", dateStrings[1])
        setParam({
            startTime: dates[0],
            endTime: dates[1],
        })
    }
    const loadData = async (current, search = searchValue) => {
        setLoading(true)
        const params = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            startTime: moment(param.startTime).format("YYYY-MM-DD") + "T16:00:00.000",
            endTime: moment(param.endTime).format("YYYY-MM-DD") + "T16:23:59.000",
            orders: [
                // {
                //     column: "createTime",
                //     rule: "DESC",
                // },
            ],
        }
        customerStatistics(params).then((res) => {
            setpagination({
                current: current,
                total: res.retData.page.total,
                pageSize: 10,
            })
            setAccountStaticSummary(res.retData)
            setLoading(false)
        })
    }

    const handleChangeOrgan = (value) => {
        setSearchValue({ organId: value })
    }

    const handleExpoert = async (current, search = searchValue) => {
        setExportLoading(true)
        const data = {
            searchItem: { ...search },
            pageNum: pagination.current,
            pageSize: 10,
            startTime: moment(param.startTime).format("YYYY-MM-DD") + "T16:00:00.000",
            endTime: moment(param.endTime).format("YYYY-MM-DD") + "T16:23:59.000",
            orders: [
                // {
                //     column: "createTime",
                //     rule: "DESC",
                // },
            ],
        }
        let resFlie = await download("/ci-customer/analyze/customer/statistics/export", { data })
        if (resFlie && resFlie.status === 200) {
            message.success("已导出")
            convertRes2Blob(resFlie)
        } else {
            message.warning("导出异常，请重新尝试")
        }
        setExportLoading(false)
    }
    //团队列表table配置
    const columns = [
        {
            title: "客户名称",
            align: "center",
            dataIndex: "customerName",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "客户类型",
            align: "center",
            dataIndex: "customerType",
        },
        {
            title: "联系人",
            align: "center",
            dataIndex: "customerCorporation",
            with: 200,
        },
        {
            title: "联系人电话",
            align: "center",
            dataIndex: "customerCorporationPhone",
            with: 200,
        },
        {
            title: "客户地址",
            align: "center",
            dataIndex: "customerAddress",
            with: 200,
        },
        {
            title: "所属机构",
            align: "center",
            dataIndex: "organName",
        },
        {
            title: "车辆总数",
            align: "center",
            dataIndex: "vehicleNum",
        },
        {
            title: "工单总数",
            align: "center",
            dataIndex: "orderTotalNum",
        },

        {
            title: "三包工单",
            align: "center",
            dataIndex: "threeGuaranteesOrderNum",
        },

        {
            title: "收费工单",
            align: "center",
            dataIndex: "chargeOrderNum",
        },
        {
            title: "最新下单时间",
            align: "center",
            dataIndex: "latestOrderTime",
        },
        {
            title: "上线时间",
            align: "center",
            dataIndex: "onlineTime",
        },
    ]

    return (
        <>
            <div className="list-container">
                <div className="list-header">
                    <Row align="middle ">
                        <Col>
                            最新数据<span>更新至{moment().format("YYYY-MM-DD HH:mm:ss")}</span>
                        </Col>
                    </Row>
                </div>
                <Divider style={{ margin: "16px 0" }} />
                <div className="statistics">
                    <div
                        style={{ cursor: "pointer" }}
                        className="item"
                        onClick={() => {
                            setAnalyzeType("platformCustomer")
                        }}
                    >
                        <span>平台客户</span>
                        <p>{newStatic.platformCustomerNum}</p>
                    </div>
                    <div
                        style={{ cursor: "pointer" }}
                        className="item"
                        onClick={() => {
                            setAnalyzeType("repairCustomer")
                        }}
                    >
                        <span>报修客户</span>
                        <p>{newStatic.repairCustomerNum}</p>
                    </div>
                    <div
                        style={{ cursor: "pointer" }}
                        className="item"
                        onClick={() => {
                            setAnalyzeType("activeCustomer")
                        }}
                    >
                        <span>活跃客户</span>
                        <p>{newStatic.activeCustomerNum}</p>{" "}
                    </div>
                    <div
                        style={{ cursor: "pointer" }}
                        className="item"
                        onClick={() => {
                            setAnalyzeType("notActivatedCustomer")
                        }}
                    >
                        <span>待激活客户</span>
                        <p>{newStatic.notActivatedCustomerNum}</p>{" "}
                    </div>
                </div>
                <Divider style={{ margin: "16px 0" }} />
            </div>
            <div className="list-container">
                <div className="list-header">
                    <Row align="middle " gutter="10">
                        <Col span="6">
                            <Select showSearch placeholder="请选择机构" optionFilterProp="children" onChange={handleChangeOrgan} style={{ width: "100%" }}>
                                <Option value={""} key={""}>
                                    全部
                                </Option>
                                {org.map((item) => (
                                    <Option value={item.id} key={item.id}>
                                        {item.organName}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col>
                            <RangePicker
                                size="small"
                                defaultValue={[moment(param.startTime, "YYYY/MM/DD"), moment(param.endTime, "YYYY/MM/DD")]}
                                ranges={{
                                    本日数据: [moment(new Date()), moment(new Date())],
                                    本周数据: [moment(new Date()).subtract(1, "weeks"), moment(new Date())],
                                    本月数据: [moment(new Date()).subtract(1, "month"), moment(new Date())],
                                    本年度数据: [moment(new Date()).subtract(1, "year"), moment(new Date())],
                                }}
                                onChange={onChange}
                            />
                        </Col>
                        <Col>
                            {moment(param.startTime).format("YYYY-MM-DD")}至{moment(param.endTime).format("YYYY-MM-DD")}
                        </Col>
                        <Col>
                            <Button style={{ marginLeft: "5px" }} onClick={() => {loadData()}} shape="round" className="but-left">
                                搜索
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Divider style={{ margin: "16px 0" }} />
                <div className="statistics">
                    <div className="item">
                        <span>新增客户</span>
                        <p>{accountStaticSummary.newCustomerNum}</p>
                    </div>
                    <div className="item">
                        <span>报修客户</span>
                        <p>{accountStaticSummary.repairCustomerNum}</p>
                    </div>
                    <div className="item">
                        <span>未报修客户</span>
                        <p>{accountStaticSummary.noRepairCustomerNum}</p>
                    </div>
                </div>
                <Divider style={{ margin: "16px 0" }} />
                <Row>
                    <Col>
                        <Button style={{ marginLeft: "5px" }} onClick={handleExpoert} shape="round" className="but-left">
                            导出
                        </Button>
                    </Col>
                </Row>

                <Table
                    style={{ marginTop: "20px" }}
                    size="small"
                    columns={columns}
                    dataSource={accountStaticSummary.page.list}
                    rowKey={(record) => record.id}
                    loading={loading}
                    onChange={onTableChange}
                    pagination={{
                        showQuickJumper: true,
                        showSizeChanger: false,
                        showTotal: () => `共${accountStaticSummary.page.total}条`,
                        position: ["bottomCenter"],
                        ...pagination,
                    }}
                />

                {analyzeType ? <CustomerList analyzeType={analyzeType} setAnalyzeType={setAnalyzeType} /> : <></>}
            </div>
            {
                        exportLoading && <Loading />
                    }
        </>
    )
}
export default Statistics
