import React, { useState } from 'react'
import { Modal, Button, Input, message } from 'antd';
import { numberParseChina, formatNum, momentFormat, fmoney } from '@/erp_subpackage/utils/util';
import "./settlePrint.css";
import moment from 'moment';
import useGlobalModel from "@/erp_subpackage/model/globalModel"

export const SettlePrint = ({
    title = "",
    visible,
    onClose,
    printNum,
    onConfirm = () => null,
    columns,
    itemClumns,
    itemDataSource,
    dataSource,
    printShow,
    printHeader,
    printFooter,
    IHeader,
    isBooking = false
}) => {
    const {
        user: { shopName, userName },
    } = useGlobalModel()
    //返回日期格式为YYYY-MM-DD
    const momentFormatYMD = (text) => {
        return text ? moment(text).format("YYYY-MM-DD") : text
    }
    //打印
    const print =() => {
        const el = document.getElementById('public-print-container');
        const iframe = document.createElement('IFRAME');
        let doc = null;
        document.body.appendChild(iframe);
        doc = iframe.contentWindow.document;
        // 引入打印的专有CSS样式，根据实际修改
        doc.write(`<LINK rel="stylesheet" type="text/css" href="./settlePrint.css">`);
        doc.write(el?.innerHTML);
        doc.close();
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus();
        iframe.onload = () => {
            iframe.contentWindow.print();
            document.body.removeChild(iframe);
        };
        if (navigator.userAgent.indexOf("MSIE") > 0) {
            document.body.removeChild(iframe);
        }
    };
    // 遍历商品td
    const mapGoods = (goods) => {
        return columns.filter(item => item).map((item, index) => <td colSpan={item.colSpan ?? 1} key={index}>{item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}</td>)
    }
    //遍历项目td
    const itemGoods = (goods) => {
        return itemClumns?.filter(item => item).map((item, index) => <td colSpan={item.colSpan ?? 1} key={index}>{item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}</td>)
    }
    // const onExport = async () => {
    //     let { BankAccount, address, Bank, phone } = shopObject[shopCode]
    //     let params = {
    //         "account": BankAccount,
    //         "adds": address,
    //         "cheque": Bank,
    //         // "maintenanceCode": formVal.maintenanceCode as string,
    //         "name": title,
    //         "phone": phone,
    //         "type": imgType
    //     }
    //     let result = isBooking ? await maintenanceService.appointmentExcelPrint({ ...params, appointmentCode: formVal.maintenanceCode as string, }) : await maintenanceService.excelPrint({ ...params, "maintenanceCode": formVal.maintenanceCode as string, })
    //     convertRes2Blob(result)
    // }
    // const onExport = () => {

    // }
    const confirmPrint =async () => {
        await printNum?.();
        await print();
    }
    return (
        <Modal
            title="打印详情"
            width={1300}
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button className="btn-item" shape="round" onClick={onClose} key="back"> 取消</Button>,
                <Button className="btn-item" shape="round" onClick={print} key="submit">打印预览</Button>,
                <Button className="btn-item" shape="round" onClick={confirmPrint} key="submit">确认打印</Button>,
                // <Button type="link" onClick={onExport} key="submit">导出excel</Button>,
            ]}
        >
            <div id="public-print-container">
                <div className="print-container finance">
                    <h2>{title}</h2>
                    <ul className="header-odd">
                        {IHeader && IHeader.map((item, index) => {
                            return <li className={item.className ?? ""} key={index}>{item.fieldLabel}{item.fieldValue}</li>
                        })}
                    </ul>
                    <table className="news-table">
                        <tbody>
                            <tr>
                                <td colSpan={2}>车辆牌照</td>
                                <td colSpan={2}>{printHeader.vehicleCode}</td>
                                <td colSpan={1}>客户名称</td>
                                <td style={{ textAlign: "left" }} colSpan={6}>{printHeader.customerName}</td>
                            </tr>
                            <tr>
                                <td colSpan={1}>所属机构</td>
                                <td colSpan={3}>{printHeader.organName}</td>
                                <td colSpan={1}>联系人</td>
                                <td colSpan={3}>{printHeader.customerCorporation}</td>
                                <td colSpan={1}>联系电话</td>
                                <td colSpan={3}>{printHeader.customerCorporationPhone}</td>
                            </tr>
                            <tr>
                                <td colSpan={1}>业务经理</td>
                                <td colSpan={3}>{printHeader.businessManagerName}</td>
                                <td colSpan={1}>部门经理</td>
                                <td colSpan={3}>{printHeader.deptManagerName}</td>
                                <td colSpan={1}>车型</td>
                                <td colSpan={3}>{printHeader.vehicleModelId}</td>
                            </tr>
                            <tr>   
                                <td colSpan={1}>颜色</td>
                                <td colSpan={3}>{printHeader.carColor}</td>
                                <td colSpan={1}>送修日期</td>
                                <td colSpan={3}>{momentFormatYMD(printHeader.repairDate)}</td>
                                <td colSpan={1}>结算日期</td>
                                <td colSpan={3}>{momentFormatYMD(printHeader.settlementDate)}</td>
                            </tr>
                            <tr>
                                <td>故障描述</td>
                                <td style={{ textAlign: "left" }} colSpan={9}>{printHeader.faultDesc}</td>
                            </tr>
                        </tbody>

                    </table>
                    <table className="material-table">
                        <tbody>
                            <tr className="print-center" >
                                {itemClumns && itemDataSource && itemDataSource.length > 0 && itemClumns.filter(item => item).map((item, index) =>
                                    <td key={index}>{item.title}</td>
                                )}
                            </tr>
                            {itemDataSource && itemDataSource.length > 0 &&
                                itemDataSource.map((item, index) =>
                                    <tr key={index} className="print-center">
                                        {itemGoods(item)}
                                    </tr>
                                )}
                        </tbody>
                    </table>
                    <table className="material-table">
                        <tbody>
                            <tr className="print-center" >
                                {columns && dataSource && dataSource.length > 0 && columns.filter(item => item).map((item, index) =>
                                    <td key={index}>{item.title}</td>
                                )}
                            </tr>
                            {dataSource && dataSource.length > 0 &&
                                dataSource.map((item, index) =>
                                    <tr key={index} className="print-center">
                                        {mapGoods(item)}
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>

                    <table className="news-table footer-table">
                        <tbody>
                            <tr>
                                <td>工时费</td>
                                <td>{fmoney(printHeader.returnItemActualAmount, 2)}</td>
                                <td>材料费</td>
                                <td>{fmoney(printHeader.returnMaintainMaterialAmount, 2)}</td>
                                <td>合计金额</td>
                                <td>{fmoney(printHeader.returnTotalAmount, 2)}</td>
                                <td>含税合计</td>
                                <td>{fmoney(printHeader.returnTotalAmount, 2)}</td>
                            </tr>
                            <tr>
                                <td>优惠金额</td>
                                <td>{fmoney(printHeader.returnPreferentialAmount, 2)}</td>
                                <td>实收金额</td>
                                <td>{fmoney(printHeader.returnActualAmount, 2)}</td>
                                <td>(大写)</td>
                                <td colSpan={3}>{numberParseChina(printHeader.returnActualAmount ?? 0)}</td>
                            </tr>
                            <tr>
                                <td>待结金额</td>
                                <td >{}</td>
                                <td>客户签字</td>
                                <td>{}</td>
                                <td>结算人</td>
                                <td>{printHeader.settlementUser}</td>
                                <td>会计</td>
                                <td></td>
                            </tr>
                        </tbody>

                    </table>
                    <ul className="header-odd">
                        <li  className="w90">制表人：{userName}</li>
                        {/* <li>维保管理系统</li> */}
                        <li className="w10">第1页/共1页</li>
                    </ul>
                    {/* <ul className="header-odd">
                        <li>备注：{printHeader.fmsNote}</li>
                    </ul> */}
                </div>
            </div>
        </Modal>

    )
}