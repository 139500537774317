import { createModel } from "hox";
import { useState } from "react";
import {pamentOrderSettle, pamentOrderFranchiseeSettle, accountFind, getOrderOne } from "@/service/ordersService"
import { IsFranchisees } from '@/utils/common'

export const useSettleModel = createModel(function () {
    const [settleVisible, setSettleVisible] = useState(false);   //结算弹框
    //编辑详情form
    const [detailEditForm, setDetailEditForm] = useState({})
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState({})

    //编辑表单缓存
    const updateEditFormVal = (val) => {
        let newVal = val
        setDetailEditForm({ ...detailEditForm, ...newVal })
    }

    //获取一条编辑或查看数据
    const getSettleInfo = async (id, mode) => {
        getOrderOne(id).then((res) => {
            let data = res.retData;
            let formData = {
                id:id,
                orderCode:data.orderCode,
                maintenanceCode: data.orderCode,
                createTime: data.createTime,
                customerCode: data.customerCode,
                customerName: data.customerName,
                // bentityCode: "bentity.bj-yh-auto-service",
                bentityCode: data.ciBusinessEntityId,
                bentityName: data.ciBusinessEntityName,
                shopName: data.organName,
                receivableTotalAmount: data.orderTotalAmount,
                receivableAmount: data.orderActualAmount,
                discountTotalAmount: data.orderPreferentialAmount,
                amount: data.orderActualAmount,
                payType: data.settlementInfo?.payType??"",
                settleTime: data.settlementInfo?.createTime??"",
                payMethodCode: data.settlementInfo?.payMethodCode??"",
                payMethodName: data.settlementInfo?.payMethodName??"",
                accountName: data.settlementInfo?.accountName??"",
                payee:data.settlementInfo?.payee??"",
                note:data.settlementInfo?.note??"",
                settlementPartyName: data.settlementInfo?.settlementPartyName??"",
                orderType: data.orderType
            }
            if(mode == "edit"){
                setDetailEditForm(formData);
            }
            else if(mode == "look"){
                formData = {...formData, key: new Date().getTime().toString(), available:0}
                setDetailLookForm(formData);
            }
        })

    }

    const onSettle = async (paramas, order) => {
        const {orderType} = order
        // let { retData } =  IsFranchisees() && orderType !== "016004" ? await pamentOrderFranchiseeSettle(paramas) : await pamentOrderSettle(paramas) 
        let { retData } =  IsFranchisees() && orderType !== "014006" ? await pamentOrderFranchiseeSettle(paramas) : await pamentOrderSettle(paramas) 
        return retData
    }

    const account = async (paramas) => {
        return await accountFind(paramas);
    }
   
    return {
        detailEditForm,
        detailLookForm,
        setDetailEditForm,
        setDetailLookForm,
        updateEditFormVal,
        getSettleInfo,
        settleVisible, setSettleVisible,
        onSettle,
        account,
    }
})