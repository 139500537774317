import { post, get } from "../utils/request"
import { PORTAL_API_SERVER_HOST } from "@/config/app";
import { getPortalToken } from "@/utils/dataStorage";
import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";

class TaskBookService {

    async taskBookList(searchVo) {
        const apiUrl = "/tco/taskBook/listPage";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

    async oneTaskBook(id){
        const apiUrl = `/tco/taskBook/select/${id}`;
        return await httpRequest.get(apiUrl, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    
   async scheduleSelect (searchVo) {
        const apiUrl = '/tco/schedule/select'
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() })
    }
    
    // 生成维保检查项目排程
   async  getCheckItem (data) {
        const apiUrl = '/tco/taskLibrary/getCheckItem'
        return await httpRequest.post(apiUrl, data, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() })
    }
    
    // 用户自定义设置
    //查询一条
   async userCustomizeConfigOne (key) {
        const apiUrl = `/tco/user/customize/config/one/${key}`
        return await httpRequest.get(apiUrl, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    
    }
    
    //根据类型查询维保任务库
   async  getLibraryType (insertVo) {
        const apiUrl = '/tco/taskLibrary/getLibraryType'
        return await httpRequest.post(apiUrl, insertVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() })
    }
    
    
   async serviceItemPage (searchVo, config) {
        const apiUrl = '/tco/serviceItem/page'
        return httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() })
    }
    
   async allDict(searchVo) {
        const apiUrl = '/tco/dict/selectAllDict'
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() })
    }

    async printCheckDetail (searchVo) {
        const apiUrl = '/tco/taskBook/printCheckDetail'
        return httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() })
    }
}

export const taskBookService = new TaskBookService();