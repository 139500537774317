import React, { useState, useEffect, useImperativeHandle } from "react"
import { InputNumber, Form, Col, Row, message, DatePicker, Radio } from "antd"
import styled from "@emotion/styled"
import { BigBagBillingMethod } from "@/utils/enum"
import moment from "moment"

export default (props) => {
    const { mode, detail, bigBagInfoRef } = props
    const [form] = Form.useForm()

    const [bigBagFlag, setBigBagFlag] = useState(null)
    const [billingMethod, setBillingMethod] = useState(null)

    useEffect(() => {
        if (detail && detail.basicInfo && detail.bigBagInfo) {
            setBigBagFlag(detail.basicInfo.bigBagFlag)
            setBillingMethod(detail.bigBagInfo.billingMethod)
            const { signContractDate, expirationDate } = detail.bigBagInfo
            setTimeout(() => {
                form.setFieldsValue({
                    ...detail.bigBagInfo,
                    bigBagFlag: detail.basicInfo.bigBagFlag,
                    signContractDate: signContractDate ? moment(signContractDate) : null,
                    expirationDate: expirationDate ? moment(expirationDate) : null,
                })
            }, 100)
        }
    }, [detail])

    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    //暴露给父组件的方法
    useImperativeHandle(bigBagInfoRef, () => ({
        //父组件保存的时候 获取所有编辑完后的数据
        upData: () => {
            return form
        },
    }))

    useEffect(() => {
        if (bigBagFlag) {
            let method = Object.keys(BigBagBillingMethod)[0]
            setBillingMethod(method)
            form.setFieldsValue({
                billingMethod: method,
            })
        } else {
            setBillingMethod(null)
            form.setFieldsValue({
                billingMethod: "",
                billingPrice: "",
                signContractDate: null,
                expirationDate: null,
            })
        }
    }, [bigBagFlag])

    useEffect(() => {
        form.setFieldsValue({
            signContractMileage: "",
            billingPrice: "",
        })
    }, [billingMethod])

    return (
        <Box>
            <Form form={form} {...formItemLayout}>
                <div className="type">大包信息</div>
                <Row>
                    <Col span={6}>
                        <Form.Item label="是否大包" name="bigBagFlag">
                            <Radio.Group
                                onChange={(e) => {
                                    setBigBagFlag(e.target.value)
                                }}
                            >
                                <Radio value={0}>否</Radio>
                                <Radio value={1}>是</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    {bigBagFlag === 1 && (
                        <Col span={12}>
                            <Form.Item label="计费方式" name="billingMethod" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                                <Radio.Group
                                    onChange={(e) => {
                                        setBillingMethod(e.target.value)
                                    }}
                                >
                                    {Object.entries(BigBagBillingMethod).map((item) => (
                                        <Radio value={item[0]} key={item[0]}>
                                            按{item[1]}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Row>
                    {billingMethod === "mileage" && (
                        <Col span={6}>
                            <Form.Item label="签约里程" name="signContractMileage">
                                <InputNumber placeholder="请输入签约里程" precision={0} disabled={isLook(mode)} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    )}

                    {bigBagFlag === 1 && (
                        <>
                            <Col span={6}>
                                <Form.Item label="计费价格" name="billingPrice">
                                    <InputNumber placeholder="请输入计费价格" precision={2} disabled={isLook(mode)} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="签约日期" name="signContractDate">
                                    <DatePicker disabled={isLook(mode)} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="截止日期" name="expirationDate">
                                    <DatePicker disabled={isLook(mode)} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </>
                    )}
                </Row>
            </Form>
        </Box>
    )
}

const Box = styled.div`
    width: 100%;
    overflow: hidden;
    border: 1px solid #f0f0f0;
    padding: 10px;
    margin: 20px 0;
    .type {
        font-size: 16px;
        font-weight: 500;
        height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
    }
`
const Lablediv = styled.div`
    text-align: center;
    margin: 10px;
`
