import React, { useEffect, useState } from "react"
import { Form, Input, Col, Row, Select, message, Modal, Upload, Button, Card } from "antd"
import { appBannerInsert, appBannerUpdate, appBannerOne } from  "@/service/dataService"
import BraftEditor from "braft-editor"
import { ContentUtils } from "braft-utils"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"
import { getToken } from "@/utils/dataStorage"



const disabledType = {
    0: "启用",
    1: "禁用",
}

export default (props) => {
    const { mode, rowInfo, visible, setVisible, onSuccess, linkType } = props
    const [form] = Form.useForm()
    const [bannerFileList, setbannerFileList] = useState([]) //图片list
    const [editorCopywrite, setEditorCopywrite] = useState(BraftEditor.createEditorState(null)) //富文本

    const [previewImage, setpreviewImage] = useState({
        //预览展示头像MD5
        visible: false,
        md5: "",
    })

    useEffect(() => {
        queryMotorcade()
    }, [])

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            appBannerOne(rowInfo.id).then((res) => {
                let results = res.retData
                let fileList
                if (results.img) {
                    //回显图片， 有的话在做回显
                    fileList = [
                        {
                            uid: "-1",
                            status: "done",
                            url: `${fileLookUrl().image}${results.img}`,
                            response: {
                                retData: results.img,
                            },
                        },
                    ]
                    setbannerFileList(fileList)
                }
                results.copywrite && setEditorCopywrite(BraftEditor.createEditorState(results.copywrite))
                form.setFieldsValue({
                    ...results,
                    img: fileList,
                })
            })
        }else if(mode === "insert"){
            form.setFieldsValue({
                disableFlag: 0,
            })
        }
    }

    const uploadHandler = (param) => {
        if (param.file.status === "done") {
            setEditorCopywrite(
                ContentUtils.insertMedias(editorCopywrite, [
                    {
                        type: "IMAGE",
                        url: `${fileLookUrl().image}${param?.file?.response?.retData}`,
                    },
                ])
            )
        }
    }

    const extendControls = [
        {
            key: "antd-uploader",
            type: "component",
            component: (
                <Upload
                    accept="image/*"
                    action={`${fileUploadUrl}upload/image_old`}
                    // action={`${fileUrl}/file/uploadFile`}
                    headers={{ authorization: getToken() }}
                    showUploadList={false}
                    onChange={uploadHandler}
                >
                    {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
                    <button type="button" className="control-item button upload-button" data-title="插入图片">
                        {/* <Icon type="picture" theme="filled" /> */}上传图片
                    </button>
                </Upload>
            ),
        },
    ]

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                img: bannerFileList.length > 0 ? bannerFileList[0].response.retData : null,
                copywrite: editorCopywrite.toHTML()
            }

            if (mode === "edit") {
                data.id = rowInfo.id
                appBannerUpdate(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                appBannerInsert(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleCancel = () => {
        setVisible("")
    }

    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }
    // 图片上传
    const handleImgChange = async (info) => {
        setbannerFileList([])
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setpreviewImage({ visible: false, md5: md5 })
            console.log(info.fileList)
            setbannerFileList(info.fileList)
        }
    }

    //富文本配置
    const controls = [
        "undo",
        "redo",
        "separator",
        "font-size",
        "line-height",
        "letter-spacing",
        "separator",
        "text-color",
        "bold",
        "italic",
        "underline",
        "strike-through",
        "separator",
        "superscript",
        "subscript",
        "remove-styles",
        "emoji",
        "separator",
        "text-indent",
        "text-align",
        "separator",
        "headings",
        "list-ul",
        "list-ol",
        "blockquote",
        "code",
        "separator",
        "link",
        "separator",
        "hr",
        "separator",
        // 'media', 'separator',
        "clear",
    ]

    return (
        <>
            <Modal visible={previewImage.visible} title="查看" footer={null} onCancel={() => setpreviewImage({ ...previewImage, visible: false })}>
                <img alt="" style={{ width: "100%" }} src={`${fileLookUrl().image}${previewImage.md5}`} />
            </Modal>
            <Modal forceRender visible={visible} title="banner" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={1200}>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row>
                            <Col span={6}>
                                <Form.Item label="跳转类型" name="linkType">
                                    <Select placeholder="请选择跳转类型" style={{ width: "100%" }}>
                                        {Object.entries(linkType).map((item) => (
                                            <Select.Option value={item[0]} key={item[0]}>
                                                {item[1]}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label="跳转链接" name="link">
                                    <Input placeholder="请输入跳转链接" />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item
                                    label="banner图片"
                                    name="img"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请上传banner图片",
                                        },
                                    ]}
                                >
                                    <Upload
                                        action={`${fileUploadUrl}upload/image_old`}
                                        headers={{ authorization: getToken() }}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={bannerFileList}
                                        onPreview={(file) => {
                                            const md5 = file.response.retData
                                            setpreviewImage({ visible: true, md5 })
                                        }}
                                        onChange={handleImgChange}
                                    >
                                        {bannerFileList.length >= 1 ? null : <Button size="small">上传banner图片</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>


                            <Col span={6}>
                                <Form.Item label="禁用状态" name="disableFlag">
                                    <Select placeholder="请选择禁用状态" style={{ width: "100%" }}>
                                        {Object.entries(disabledType).map((item) => (
                                            <Select.Option value={Number(item[0])} key={item[0]}>
                                                {item[1]}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Card title="文案:" size="small">
                                    <BraftEditor
                                        contentStyle={{ height: 200 }}
                                        value={editorCopywrite}
                                        controls={controls}
                                        onChange={(e) => setEditorCopywrite(e)}
                                        extendControls={extendControls}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
