import React, { useEffect, useState } from "react"
import { Modal, Button, message, Form, Radio, Row, Col, Input } from "antd"
import { orderPay, getOrderOne } from "@/service/ordersService"
import { getShowBalance } from "@/service/customerService"
// import { timeShift } from "@/utils/util";

export default ({ visible, onClose, orderId, loadData, searchValue }) => {
    const [form] = Form.useForm()
    const [accountBalance, setAccountBalance] = useState(0) //授信账户余额
    const [orderInfo, setOrderInfo] = useState({}) //工单详情

    useEffect(() => {
        getOrderOneInfo()
    }, [])

    //根据工单id获取详情
    const getOrderOneInfo = () => {
        getOrderOne(orderId)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    setOrderInfo(res.retData)
                    getCustomerAccountBalance(res.retData)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }
    //查询账户余额
    const getCustomerAccountBalance = (orderInfo) => {
        let data = {
            customerId: orderInfo.customerId,
            accountType: 0,
        }
        getShowBalance(data)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    if (+res.retData < +orderInfo.orderActualAmount) {
                        form.setFieldsValue({ payType: "cash" })
                    }
                    setAccountBalance(res.retData)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }

    //提交
    const onSubmit = () => {
        form.validateFields().then((res) => {
            let data = {
                ...res,
                payType: res.payType,
                orderId: orderId,
                customerId: orderInfo.customerId,
            }
            orderPay(data)
                .then((res) => {
                    // if (res && res.retCode === 200) {
                        message.success("结算成功")
                        loadData && loadData(1, searchValue)
                        onClose()
                    // } else {
                    //     message.warning(res.retMsg)
                    // }
                })
                // .catch((err) => message.error(err.retMsg))
        })
    }
    const switchNum = (num, n = 2) => {
        return Number(num).toFixed(n)
    }
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    return (
        <Modal
            title="工单结算详情"
            width={600}
            visible={visible}
            onCancel={() => onClose()}
            footer={[
                <Button onClick={() => onClose()} key="back">
                    {" "}
                    取消
                </Button>,
                <Button type="primary" onClick={onSubmit} key="submit">
                    确认
                </Button>,
            ]}
        >
            <Form form={form} initialValues={{ ["payType"]: "auth_quota" }} {...formItemLayout}>
                <Form.Item label="工单单号">
                    <span className="ant-form-text">{orderInfo.orderCode}</span>
                </Form.Item>
                <Form.Item label="客户/机构">
                    <span className="ant-form-text">{orderInfo.customerName}</span>
                </Form.Item>
                <Form.Item label="实收金额">
                    <span className="ant-form-text">{switchNum(orderInfo.orderActualAmount)} ￥</span>
                </Form.Item>
                {/* <Form.Item name="payType" label="支付方式" rules={[{ required: true, message: "请选择支付方式" }]}>
                    <Radio.Group>
                        <Radio value="auth_quota">授权额度</Radio>
                        <Radio value="cash">现金</Radio>
                    </Radio.Group>
                </Form.Item> */}
                <Form.Item name="payType" label="支付方式" rules={[{ required: true, message: "请选择支付方式" }]}>
                    <Radio.Group>
                        <Row>
                            <Col span={24}>
                                <Radio value="auth_quota" disabled={accountBalance === -1}>
                                    授权额度{" "}
                                    <span
                                        className="ant-form-text"
                                        style={{
                                            lineHeight: "32px",
                                            marginLeft: "30px",
                                        }}
                                    >
                                        可用额度：
                                        <span style={{ color: "#f00" }}>{accountBalance < 0 ? "没有开通" : `${switchNum(accountBalance)}  ￥`}</span>
                                    </span>
                                </Radio>
                            </Col>
                            <Col span={24}>
                                <Radio value="cash" style={{ lineHeight: "32px" }}>
                                    现金
                                </Radio>
                            </Col>
                        </Row>
                    </Radio.Group>
                </Form.Item>
                <Form.Item  label="备注" name="remark">
                    <Input allowClear autoSize={true} placeholder="请输入备注"  />
                </Form.Item>
            </Form>
        </Modal>
    )
}
