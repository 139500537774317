import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";
class StockEntryService {
    async page(searchVo) {
        const apiUrl = "/ci-storage/stockIo/in/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async one(id) {
        const apiUrl = `/ci-storage/stockIo/findById/${id}`;
        return await httpRequest.get(apiUrl);
    }
    //导出
    async export(params) {
        const apiUrl = "/ci-analyze/stock/in/export";
        return await httpRequest.post(apiUrl, params);
    }
     // 统计入库数、入库总计
     async total(searchVo){
        const apiUrl = "/ci-storage/stockIo/in/total/amount";
        return await httpRequest.post(apiUrl, searchVo);
    }
}

export const stockEntryService = new StockEntryService();