import React from "react"
import { Button, message } from "antd"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { usePrebookModel } from "../prebookModel";
import { debounce } from "lodash"

export const TopButs = ({ mode, modeKey, rowInfo, dispatch, formRef }) => {

    const {
        activeMenu, getInfo,
        setConvertVisible,
        getMaterialList,
        insertPrebook, updatePrebook,
        loadPrebookList, getPrebookOne
    } = usePrebookModel();

    //表单提交
    const onSubmit = debounce(async () => {
        let flag = true;
        //维修物料-销售单价不能为0
        getMaterialList(mode) && getMaterialList(mode).forEach(e => {
            if (Number(e.salePrice) <= 0) {
                message.error(e.materialName + "销售单价不能小于或等于0");
                flag = false;
                return
            }
        });
        if (flag) {
            formRef.current.validateFields().then((val) => {
                if (mode == "add") {
                    insertPrebook(mode, val, () => {
                        loadPrebookList();
                        onClose();
                    });
                }
                if (mode == "edit") {
                    updatePrebook(mode, val, rowInfo.id, () => {
                        loadPrebookList();
                        onClose();
                    });
                }
            }).catch((err) => {
                message.error("保存失败")
            })
        }
    }, 1000)

    //关闭当前页
    const onClose = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: activeMenu.split("/")[0],
        })
    }

    const reset = () => {
        getPrebookOne(mode, rowInfo.id).then(res => {
            message.success("刷新成功")
        })
    }

    const action =
        <>
            {getInfo(mode) && (getInfo(mode).prebookStatus == null || getInfo(mode).prebookStatus === 0) && (<Button
                className="btn-item"
                type="link"
                shape="round"
                onClick={onSubmit}
                style={{ margin: "10px" }}>
                保存
            </Button>)}
            <Button
                className="btn-item"
                type="link"
                shape="round"
                onClick={onClose}
                style={{ margin: "10px" }}>
                返回
            </Button>
            {getInfo(mode) && getInfo(mode).prebookStatus === 0 && <Button
                className="btn-item"
                type="link"
                shape="round"
                onClick={() => setConvertVisible(true)}
                style={{ margin: "10px" }}>
                转接单
            </Button>}
            {mode == "edit" &&
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={reset}
                    style={{ margin: "10px" }}>
                    刷新
                </Button>}
        </>

    return (
        <>
            <YhBox title={mode != "add" && rowInfo.prebookCode}
                action={action}
            />
        </>
    )
}
