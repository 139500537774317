import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { useStockCheckDetailsModel } from './stockCheckDetailModel';
import { stockCheckService } from "../../stockCheckService";

const initPgCfg = { pageSize: 10, current: 1 };
const initStatistics = { num: 0, amount: 0 };

export const StockRecordDialog = ({ modeKey, mode, rowCode, dispatch }) => {
    const { stockRecordDialog: { visible, materialCode }, setStockRecordDialog } = useStockCheckDetailsModel(({ stockRecordDialog }) => [stockRecordDialog])
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    const [loading, setLoadding] = useState(false);
    const [pageType, setPageType] = useState("out");
    const [statistics, setStatistics] = useState(initStatistics);

    useEffect(() => {
        if (visible) {
            loadData()
        }
    }, [visible])

    const onCancel = () => {
        setStockRecordDialog({ visible: false });
    };


    //     amount: 11
    // billingClerk: "erp_test"
    // code: null
    // createTime: "2022-04-21 18:28:21"
    // createUser: "erp_test"
    // discountRate: 0
    // id: "05347a48c1944170bc74e51e443cd4a5"
    // inventoryNum: null
    // ioFlag: 1
    // materialCode: "lanyangyang"
    // materialName: "懒羊羊"
    // note: "备注"
    // num: 1
    // price: 11
    // replaceCode: null
    // shelfCode: "002"
    // shelfId: "4acbb429737f5907cec68accad326ac4"
    // shopId: "2fcfa0802be3efd5ac69a829da597c7a"
    // shopName: "大兴车服"
    // stockInId: "822094493b784657bbf77740143ba984"
    // tradeCode: "DXCCG22042100002"
    // tradeTypeCode: "trade-type.purchase"
    // tradeTypeName: "采购单"
    // userName: null
    // warehouseCode: "WH-M-00000230"
    // warehouseName: "我是杨月新增的仓库"

    //加载商品数据
    const loadData = async (page, type = pageType) => {
        setLoadding(true);
        try {
            const searchParams = {
                "ioFlag": type === "out" ? 0 : 1,   //0出库  1入库
                "pageSize": 15,
                "pageNum": 1,
                materialCode
            }
            let { retData } = await stockCheckService.record(searchParams);
            let { retData: statistics } = await stockCheckService.recordStatistics(searchParams);
            const { records, ...pg } = retData;
            setStatistics(statistics ?? initStatistics)
            setPgCfg(transPgToPagination(pg));
            setDataSource(records ?? []);
            setPageType(type)
            setLoadding(false);
        } catch (err) { setLoadding(false) }
    }

    const columnOut = [
        {
            title: '序号',
            width: 60,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '单据编号',
            width: 120,
            dataIndex: 'tradeCode',
        }, {
            title: '单据类型',
            width: 160,
            dataIndex: 'tradeTypeName',
        }, {
            title: '出库数量',
            width: 80,
            dataIndex: 'num',
        }, {
            title: '出库单位',
            width: 120,
            dataIndex: 'shopName',
        }, {
            title: '出库金额',
            width: 100,
            dataIndex: 'amount',
        }, {
            title: '制单人 ',
            width: 120,
            dataIndex: 'createUser',
        }, {
            title: '制单日期',
            width: 160,
            dataIndex: 'createTime',
        }, {
            title: '库管员',
            width: 120,
            dataIndex: 'billingClerk',
        }, {
            title: '出库日期',
            width: 160,
            dataIndex: 'createTime',
        }, {
            title: '所属机构',
            width: 120,
            dataIndex: 'shopName',
        }, {
            title: '备注',
            width: 120,
            dataIndex: 'note',
        }
    ];

    const columnEntry = [
        {
            title: '序号',
            width: 60,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '单据编号',
            width: 140,
            dataIndex: 'tradeCode',
        }, {
            title: '单据类型',
            width: 160,
            dataIndex: 'tradeTypeName',
        }, {
            title: '入库数量',
            width: 80,
            dataIndex: 'num',
        }, {
            title: '入库单位',
            width: 120,
            dataIndex: 'shopName',
        }, {
            title: '入库金额',
            width: 100,
            dataIndex: 'amount',
        }, {
            title: '制单人 ',
            width: 120,
            dataIndex: 'createUser',
        }, {
            title: '制单日期',
            width: 160,
            dataIndex: 'createTime',
        }, {
            title: '库管员',
            width: 120,
            dataIndex: 'billingClerk',
        }, {
            title: '入库日期',
            width: 160,
            dataIndex: 'createTime',
        }, {
            title: '所属机构',
            width: 120,
            dataIndex: 'shopName',
        }, {
            title: '备注',
            width: 120,
            dataIndex: 'note',
        }
    ];

    const desc = [
        {
            label: `${pageType === "out" ? "出" : "入"}库数量`,
            value: statistics.num,
            color: "red"
        },
        {
            label: `${pageType === "out" ? "出" : "入"}库总金额`,
            value: statistics.amount,
            color: "red"
        }
    ]

    const title = <>
        <Button
            className={`btn-item ${pageType === "out" ? "tab-buts-active" : ""}`}
            shape="round"
            onClick={() => loadData({}, "out")}
        >出库记录</Button>
        <Button
            className={`btn-item ${pageType === "entry" ? "tab-buts-active" : ""}`}
            shape="round"
            onClick={() => loadData({}, "entry")}
        >入库记录</Button>
    </>
    return <YHModal
        visible={visible}
        okText="确认添加"
        onOk={() => { }}
        onCancel={onCancel}
        title="出入库记录"
        width={1200}
        bodyHeight={700}
        showFooter={false}
    >

        <YhBox title={title} descriptions={desc} style={{ margin: "0" }}>
            <Table
                loading={loading}
                rowKey={record => record.id}
                columns={pageType === "out" ? columnOut : columnEntry}
                dataSource={dataSource}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize })
                    }
                }}
                scroll={{ x: 'max-content', y: 460 }}
            />
        </YhBox>
    </YHModal>
}