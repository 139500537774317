import React, { useState, useEffect } from "react"
import { Button, Form, Input, Col, Row, message, Checkbox, Select, Spin } from "antd"
import { insertItem, editItem, getItemOne } from "@/service/maintenanceService"
import { ItemAttr } from "@/utils/enum"
import { classificationList } from "@/service/dataService"
import debounce from "lodash/debounce"
import useGlobalModel from "../../../erp_subpackage/model/globalModel";

const { TextArea } = Input
const ItemAttraArr = Object.entries(ItemAttr)
const ProjectDetails = (props) => {
    const { modeKey, dispatch, mode, rowInfo } = props
    const [form] = Form.useForm()
    const [saveLoading, setSaveLoading] = useState(false)
    const [itemClassificationList, setItemClassificationList] = useState({ data: [], fetching: false })
    const { user: { shopId } } = useGlobalModel();
    useEffect(() => {
        resetFormItem()
        return () => setSaveLoading(false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const materialSubmit = () => {
        // setSaveLoading(true);
        form.validateFields().then((values) => {
            let data = {
                ...values,
                "shopId":shopId,
                recommendStatus: values.recommendStatus ? 1 : 2,
            }
            //新增
            if (mode === "insert") {
                insertItem(data).then((res) => {
                    // if (res && res.retCode === 200) {
                    handleCancel()
                    message.success("维修项目新增成功")
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
                // .catch((err) => {
                //     message.error(err.retMsg)
                // })
                //编辑
            } else if (mode === "edit") {
                editItem({ ...data, id: rowInfo.id }).then((res) => {
                    // if (res && res.retCode === 200) {
                    handleCancel()
                    message.success("维修项目编辑成功")
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
                // .catch((err) => {
                //     message.error(err.retMsg)
                // })
            }
        })
    }
    //回显表单项
    const resetFormItem = () => {
        if (mode === "edit" || mode === "look") {
            getItemOne(rowInfo.id).then((res) => {
                // if (res && res.retCode === 200) {
                form.setFieldsValue({
                    ...res.retData,
                    recommendStatus: res.retData.recommendStatus === 1 ? true : false,
                })
                // }
                setItemClassificationList({
                    data: [
                        {
                            text: res.retData.itemClassificationName,
                            value: res.retData.itemClassificationId,
                        },
                    ],
                    fetching: false,
                })
                console.log( res.itemClassificationName)
                console.log( res.itemClassificationId)
            })
        } else {
            //新增默认推荐展示
            form.setFieldsValue({
                recommendStatus: true,
            })
        }
    }

       //查询机构
       const itemClassificationQuery = (value) => {
        setItemClassificationList({
            data: [],
            fetching: true,
        })
        let data = {
            searchItem: { name: value, disableFlag: 0 },
            pageNum: 1,
            pageSize: 10,
            orders: [{ column: "createTime", rule: "DESC" }],
        }
        classificationList(data)
            .then((res) => {
                    let results = res.retData.list
                    if (results && results.length > 0) {
                        const data = results.map((item) => ({
                            text: item.name,
                            value: item.id,
                        }))
                        console.log(data)
                        setItemClassificationList({
                            data: data,
                            fetching: false,
                        })
                    } else {
                        message.warning("没有查询到系统分类")
                    }
            })
    }
    //防抖
    const itemClassificationQuerys = debounce(itemClassificationQuery, 600)

    //工时和单价失焦的时候触发
    const countTotal = () => {
        let arr = form.getFieldsValue(["itemWorkHours", "workHoursPrice"])
        if (
            !arr.itemWorkHours ||
            !arr.workHoursPrice ||
            !/^[0-9]+(.[0-9]{0,1})?$/.test(arr.itemWorkHours) ||
            !/^[1-9]+(.[0-9]{0,2})?$/.test(arr.workHoursPrice)
        ) {
            form.setFieldsValue({
                itemAmount: "",
            })
            return
        }
        let res = Number(arr.itemWorkHours) * Number(arr.workHoursPrice)
        form.setFieldsValue({
            itemAmount: res % 1 === 0 ? res : res.toFixed(2),
        })
    }
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    //取消
    const handleCancel = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "projectManage",
        })
    }
    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }
    return (
        <>
            <div className="toolbar">
                <span className="modules-name">数据管理</span>
            </div>
            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>维修项目信息</span>
                    </div>
                    <div className="list-but">
                        {!isLook(mode) && (
                            <Button loading={saveLoading} shape="round" className="but-left" onClick={materialSubmit}>
                                保存
                            </Button>
                        )}
                        <Button shape="round" className="but-left" onClick={handleCancel}>
                            取消
                        </Button>
                    </div>
                </div>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row>
                            <Col span={2}>
                                <span className="form-title">基本信息</span>
                            </Col>
                            <Col span={9}>
                                <Form.Item label="项目编码" name="itemCode" rules={[{ required: true, message: "请输入商品编号" }]}>
                                    <Input placeholder="请输入项目编码" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={9}>
                                <Form.Item label="项目名称" name="itemName">
                                    <Input placeholder="请输入项目名称" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col offset={2} span={9}>
                                <Form.Item
                                    label="工时"
                                    name="itemWorkHours"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入工时",
                                        },
                                        {
                                            pattern: /^[0-9]+(.[0-9]{0,1})?$/,
                                            message: "请输入数字且可保留一位小数",
                                        },
                                    ]}
                                >
                                    <Input suffix="￥" placeholder="请输入项目工时" disabled={isLook(mode)} onBlur={countTotal} />
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={9}>
                                <Form.Item
                                    label="单价"
                                    name="workHoursPrice"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入项目单价",
                                        },
                                        {
                                            pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                            message: "请输入数字且可保留两位小数",
                                        },
                                    ]}
                                >
                                    <Input suffix="￥" placeholder="请输入项目单价" disabled={isLook(mode)} onBlur={countTotal} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col offset={2} span={9}>
                                <Form.Item label="工时费" name="itemAmount">
                                    <Input placeholder="自动生成" disabled />
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={9}>
                                <Form.Item label="品牌" name="itemBrand">
                                    <Input placeholder="请输入项目品牌" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col offset={2} span={9}>
                                <Form.Item label="项目属性" name="itemAttr">
                                    <Select disabled={isLook(mode)} allowClear placeholder="请选择项目属性" style={{ width: "100%" }}>
                                        {ItemAttraArr.map((item) => (
                                            <Select.Option value={item[0]} key={item[0]}>
                                                {item[1]}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={9}>
                                <Form.Item label="系统分类" name="itemClassificationId">
                                    <Select
                                        showSearch
                                        placeholder="请选择系统分类"
                                        notFoundContent={itemClassificationList.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onFocus={() => itemClassificationQuery("")}
                                        onSearch={itemClassificationQuerys}
                                        style={{ width: "100%" }}
                                    >
                                        {itemClassificationList.data.map((item, index) => (
                                            <Select.Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col offset={2} span={9}>
                                <Form.Item label="备注" name="remark">
                                    <Input placeholder="请输入备注" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="from-line" />
                        <Row>
                            <Col span={2}>
                                <span className="form-title">预估用料</span>
                            </Col>
                            <Col span={9}>
                                <Form.Item label="商品名称" name="recommendCommodity">
                                    <TextArea rows={4} placeholder="请输入推荐商品名称" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={9}>
                                <Form.Item name="recommendStatus" valuePropName="checked">
                                    <Checkbox disabled={isLook(mode)}>推荐</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}
export default ProjectDetails
