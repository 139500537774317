import React, { useEffect, useRef } from "react"
import { Button, message } from "antd"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { YhFormBox } from "@/erp_subpackage/components/YhFormBox"
import { useWarehouseModel } from "../../warehouseModel";
import { getFormValidatorErrText } from "@/erp_subpackage/utils/util"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { debounce } from "lodash"

export const FormBox = ({ modeKey, mode, rowId, dispatch }) => {
    const {
        editFormVal,
        updateFormVal,
        updateWarehouse,
        getWarehouseOne,
        shelfLoadData
    } = useWarehouseModel()

    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => [])
    const formRef = useRef()

    useEffect(() => {
        if (mode === "edit") {
            getWarehouseOne(rowId).then(res => {
                //拿到仓库详情进行货位列表请求
                res && shelfLoadData({}, { warehouseId: res.id, warehouseCode: res.code })
            })
        }
        return () => {
            updateFormVal(mode)
        }
    }, [rowId])

    //关闭当前页
    const onClose = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "baseWarehouse",
        })
    }

    const onChange = (changedValues) => {
        updateFormVal(mode, changedValues);
    }

    const reset = () => {
        getWarehouseOne(rowId).then(res => {
            res && shelfLoadData({}, { warehouseId: res.id, warehouseCode: res.code })
            message.success("刷新成功")
        })
    }

    //表单提交
    const onSubmit = debounce(async () => {
        formRef.current.validateFields().then((val) => {
            updateWarehouse({
                ...val,
                typeName: getTreeNodeName(val.typeCode)
            })
        }).catch((err) => {
            message.warning(getFormValidatorErrText(err))
        })
    }, 900)


    const baseFormItem = {
        title: "仓库详情",
        style: { padding: "0 20px", marginBottom: "10px" },
        items: [
            {
                type: "Input",
                fieldLabel: "仓库编码",
                fieldName: "code",
                disable: true
            }, {
                type: "Input",
                fieldLabel: "仓库名称",
                fieldName: "name",
                rules: [{ required: true, message: "请输入仓库名称" }]
            }, {
                type: "Input",
                fieldLabel: "所属机构",
                fieldName: "shopName",
                disable: true
            }, {
                type: "Select",
                fieldLabel: "仓库类型",
                fieldName: "typeCode",
                options: getSelectedDictOne(DictCodeEnmu.WAREHOUSE_TYPE),
                rules: [{ required: true, message: "仓库类型" }]
            }, {
                type: "Input",
                fieldLabel: "机构负责人",
                fieldName: "orgContact"
            }, {
                type: "Input",
                fieldLabel: "联系电话",
                fieldName: "phone"
            }, {
                type: "Input",
                fieldLabel: "联系地址",
                fieldName: "address"
            }, {
                type: "Input",
                fieldLabel: "仓库负责人",
                fieldName: "contact"
            }, {
                type: "Input",
                fieldLabel: "仓库地址",
                fieldName: "orgAddress"
            }, {
                type: "Input",
                fieldLabel: "创建时间",
                fieldName: "createTime"
            }, {
                type: "Input",
                fieldLabel: "备注信息",
                fieldName: "note"
            }
        ]
    };


    const fromConfig = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        formValues: editFormVal,
        onChange,
        boxs: [baseFormItem],
    }

    const action = (
        <>
            <Button
                className="btn-item"
                shape="round"
                onClick={onSubmit}
            >保存</Button>
            <Button className="btn-item" shape="round" onClick={() => reset()}>
                刷新
            </Button>
            <Button className="btn-item" shape="round" onClick={onClose}>
                返回
            </Button>
        </>
    )

    return (
        <>
            <YhBox title={`仓库详情`} action={action} style={{ padding: "0 20px", marginBottom: "10px" }}></YhBox>
            <YhFormBox {...fromConfig} />
        </>
    )
}
