import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";

// export interface IVRM2DictDetail {
// note: string,
// ver: number,
// code: string,
// sourceAuthKey: string,
// pcode: string | null,
// children: IVRM2DictDetail[],
// enable: number,
// name: string,
// branch: string,
// value: string,
// key: string,

// branch: string,
// branchList: any | null,
// ver: number,
// enable: number,
// dictType: null,
// code: string,
// name: string,
// nameEn: string | null,
// value: string,
// pcode: string | null,
// note: string,
// status: number,
// children: IVRM2DictDetail[],
// shopCode?: string | any,
// key: string
// }

class DictService {
    //树列表
    async page(searchVo) {
        const apiUrl = "/ci-storage/base/dict/page";
        return await httpRequest.post(apiUrl, searchVo)
    }
    //门店树列表
    async pages(searchVo) {
        // debugger;
        const apiUrl = "/ci-storage/base/dict/pages";
        return await httpRequest.post(apiUrl, searchVo,)
        // return await httpRequest.post(apiUrl, searchVo,{baseURL:"http://49.232.218.5:9527"})
        // return await httpRequest.post(apiUrl, searchVo,{baseURL:"http://82.156.12.57:9000"})
    }
    //新增
    async insert(insertVo) {
        const apiUrl = "/ci-storage/base/dict/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    //编辑
    async update(updateVo) {
        const apiUrl = "/ci-storage/base/dict/update";
        return await httpRequest.post(apiUrl, updateVo)
    }
    //新增
    async insertLocal(insertVo) {
        const apiUrl = "/ci-storage/base/dict/insertLocal";
        return await httpRequest.post(apiUrl, insertVo);
    }
    //编辑
    async updateLocal(updateVo) {
        const apiUrl = "/ci-storage/base/dict/updateLocal";
        return await httpRequest.post(apiUrl, updateVo)
    }
    //获取一条
    async one(oneVo) {
        const apiUrl = "/ci-storage/base/dict/one";
        return await httpRequest.post(apiUrl, oneVo)
    }

    async disable(oneVo) {
        const apiUrl = "/ci-storage/base/dict/disable";
        return await httpRequest.post(apiUrl, oneVo)
    }

    async remove(oneVo) {
        const apiUrl = "/ci-storage/base/dict/remove";
        return await httpRequest.post(apiUrl, oneVo)
    }
    async sync(obj) {
        const apiUrl = "/ci-storage/base/dict/sync";
        return await httpRequest.post(apiUrl, obj)
    }
}

export const dictService = new DictService();
