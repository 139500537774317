import React, { useEffect, useRef } from "react"
import { Button, message } from "antd"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { YhFormBox } from "@/erp_subpackage/components/YhFormBox"
import { useVehicleModel } from "../vehicleModel"
import useGlobalModel from "@/erp_subpackage/model/globalModel"
import { getFormValidatorErrText } from "@/erp_subpackage/utils/util"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { debounce } from "lodash"

export const BaseVehicleDetails = ({ modeKey, mode, rowId, dispatch, customerCode, customerName }) => {
    const {
        addFormVal,
        editFormVal,
        lookFormVal,
        updateAddFormVal,
        updateEditFormVal,
        insertVehicle,
        editVehicle,
        getVehicleOne,
        setAddFormVal,
        setSelectedRowKeys,setSaveBtnLoading,saveBtnLoading
    } = useVehicleModel()
    const {
        user: { shopName },
        fetchYCCustomerList,
    } = useGlobalModel()
    const { getSelectedDictOne } = useDictModel()
    const formRef = useRef()
    const updateFormVal = mode === "add" ? updateAddFormVal : updateEditFormVal

    //编辑时， 没有缓存调用详情接口
    useEffect(() => {
        if (mode === "edit" || mode === "look") {
            getVehicleOne(rowId, mode)
        }
        return () => {
            updateFormVal(null)
        }
    }, [rowId, mode])

    useEffect(() => {
        onChange({
            customerName: customerName,
            code: customerCode,
        })
    }, [])

    //关闭当前页
    const onClose = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "baseVehicle",
        })
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current
            .validateFields()
            .then((res) => {
                if (res.axle && res.axle.length > 3) {
                    message.warning("车桥不可以超过三位数");
                    return;
                }
                let data = {
                    ...res,
                    axle: res.axle ? +res.axle : res.axle
                }
                if (mode === "edit") {
                    editVehicle({ ...data, id: rowId, code: editFormVal.code }).then((res) => {
                        res && onClose()
                    })
                } else {
                    insertVehicle(data).then((res) => {
                        setSelectedRowKeys([res])
                        res && onClose()
                    })
                }
            })
            .catch((err) => {
                setSaveBtnLoading(false)
                message.warning(getFormValidatorErrText(err))
            })
    }

    // 选中客户名称带出客户code
    const customerChange = (value, option) => {
        if (value && option) {
            let { contact, phone, address, category_name, category_code } = option
            option &&
                onChange({
                    customerName: option.label,
                    code: option.value,
                    contact,
                    phone,
                    address,
                    typeCode: category_code,
                    typeName: category_name,
                })
        }
    }

    // 基础信息
    const baseFormItem = {
        title: "基础信息",
        style: { padding: "0 20px", marginBottom: "10px" },
        items: [
            {
                type: "Input",
                fieldLabel: "客户编码",
                fieldName: "code",
                hidden: true,
            },
            {
                type: "AsyncSelect",
                fieldLabel: "客户名称",
                fieldName: "customerName",
                allowClear: true,
                showSearch: true,
                selectfetchList: fetchYCCustomerList,
                onSelectChange: customerChange,
                defaultQuery: mode === "edit" ? editFormVal.customerName : addFormVal.customerName,
                rules: [{ required: true, message: "请选择客户名称" }],
            },
            {
                type: "Input",
                fieldLabel: "1",
                fieldName: "licensePlate",
                rules: [{ required: true, message: "请输入车辆拍照" }],
            },
            {
                type: "Input",
                fieldLabel: "发动机号",
                fieldName: "engineCode",
            },
            {
                type: "Input",
                fieldLabel: "车辆型号",
                fieldName: "model",
            },
            {
                type: "Select",
                fieldLabel: "车辆类型",
                fieldName: "categoryCode",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.VEHICLE_TYPE),
            },
            {
                type: "Input",
                fieldLabel: "变速箱号",
                fieldName: "gearboxCode",
            },
            {
                type: "Input",
                fieldLabel: "使用性质",
                fieldName: "natureName",
            },
            {
                type: "Input",
                fieldLabel: "车桥",
                fieldName: "axle",
                rules: [{ pattern: /^[0-9]*$/, message: '请输入数字类型' }]
            },
            {
                type: "Select",
                fieldLabel: "车辆品牌",
                allowClear: true,
                showSearch: true,
                fieldName: "brandCode",
                options: getSelectedDictOne(DictCodeEnmu.VEHICLE_BRAND),
            },
            {
                type: "Datepicker",
                fieldLabel: "注册日期",
                fieldName: "registerDate",
            },
            {
                type: "Input",
                fieldLabel: "车系",
                fieldName: "series",
            },
            {
                type: "Input",
                fieldLabel: "登记日期",
                fieldName: "createTime",
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "VIN码",
                fieldName: "vin",
                rules: [{ required: true, message: "请填写vin码" }],
            },
            {
                type: "Input",
                fieldLabel: "所属机构",
                fieldName: "shopName",
                disable: true,
            },
            // 带出字段
            {
                type: "Input",
                fieldLabel: "联系人",
                fieldName: "contact",
                hidden: true
            },
            {
                type: "Input",
                fieldLabel: "电话",
                fieldName: "phone",
                hidden: true
            },
            {
                type: "Input",
                fieldLabel: "地址",
                fieldName: "address",
                hidden: true
            },
            {
                type: "Input",
                fieldLabel: "客户类型name",
                fieldName: "typeCode",
                hidden: true
            },
            {
                type: "Input",
                fieldLabel: "客户类型code",
                fieldName: "typeName",
                hidden: true
            },
        ],
    }
    // 保养信息
    const priceFormItem = {
        title: "保养信息(字段暂无)",
        style: { padding: "0 20px", marginBottom: "10px" },
        items: [
            {
                type: "Input",
                fieldLabel: "上次保养日期",
                fieldName: "createTime",
            },
            {
                type: "Input",
                fieldLabel: "上次保养里程",
                fieldName: "createTime",
            },
            {
                type: "Input",
                fieldLabel: "下次保养日期",
                fieldName: "createTime",
            },
            {
                type: "Input",
                fieldLabel: "下次保养里程",
                fieldName: "createTime",
            },
            {
                type: "Input",
                fieldLabel: "最新里程",
                fieldName: "createTime",
            },
        ],
    }

    const onChange = (changedValues) => {
        mode === "edit" ? updateEditFormVal(changedValues) : updateAddFormVal(changedValues)
    }
    const typeForm = {
        add: addFormVal,
        edit: editFormVal,
        look: lookFormVal,
    }

    const typeTitle = {
        add: "新增",
        edit: "编辑",
        look: "查看",
    }
    const VehicleFromConfig = {
        formRef,
        labelCol: { span: 6 },
        disabled: mode === "look",
        labelAlign: "right",
        formValues: { ...typeForm[mode], shopName },
        onChange,
        boxs: [baseFormItem],
    }
    const reset = () => {
        formRef.current.resetFields()
        if (mode === "add") {
            setAddFormVal({})
        } else if (mode === "edit") {
            getVehicleOne(rowId, mode)
        }
        message.success("重置成功")
    }
    const action = (
        <>
            {mode !== "look" && (
                <Button
                    className="btn-item"
                    shape="round"
                    loading={saveBtnLoading}
                    onClick={debounce(() => {
                        onSubmit()
                    }, 900)}
                >
                   {saveBtnLoading ? '保存中' : '保存'}
                </Button>
            )}
            {mode !== "look" && (
                <Button className="btn-item" shape="round" onClick={() => reset()}>
                    重置
                </Button>
            )}
            <Button className="btn-item" shape="round" onClick={onClose}>
                取消
            </Button>
        </>
    )

    return (
        <>
            <YhBox title={`车辆${typeTitle[mode]}`} action={action} style={{ padding: "0 20px", marginBottom: "10px" }}></YhBox>
            <YhFormBox {...VehicleFromConfig} />
        </>
    )
}
