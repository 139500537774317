import React from "react";
import { Form, Input, Tooltip } from "antd";

export const __FormInput = ({ placeholder, fieldLabel, fieldName, ...props }) => {
    const title = <span style={{ cursor: "pointer" }} onClick={(e) => {
        e.stopPropagation();
        if (props.copyText) {
            props.copyText(fieldName)
        }
    }}>复制</span>

    return (
        <Form.Item
            label={<Tooltip title={title}>{fieldLabel}</Tooltip>}
            name={fieldName}
            rules={props.rules}
            hidden={props.hidden}
            labelCol={props.labelCol}
        >
            <Input
                disabled={props.disable}
                allowClear={props.allowClear}
                placeholder={placeholder ? placeholder : typeof fieldLabel === "string" ? fieldLabel : ""}
                onBlur={() => props.onInputBlur?.(fieldName)}
                addonAfter={props.addon}
            />
        </Form.Item>
    )
}