// 权限管理接口
import { post, get } from "../utils/request"

/***
 * 角色管理
 */
//角色列表
function getRoleList(data) {
    return post("/ci-platform/role/page", data)
}

//新增角色
function roleInsert(data) {
    return post("/ci-platform/role/insert", data)
}

//新增编辑
function roleUpdate(data) {
    return post("/ci-platform/role/update", data)
}

// 获取一条数据
function getRoleOne(id) {
    return get(`/ci-platform/role/one/${id}`)
}

//新增用户
function userInsert(data) {
    return post("/ci-platform/user/insert", data)
}

//修改用户
function userUpdate(data) {
    return post("/ci-platform/user/update", data)
}
// 获取一条数据
function getUserOne(id) {
    return get(`/ci-platform/user/one/${id}`)
}
// 获取一条数据
function userDelete(id) {
    return get(`/ci-platform/user/delete/${id}`)
}
// 获取一条数据
function userBindOfficialQrcode(userId) {
    return get(`/ci-platform/user/bind_official_qrcode/${userId}`)
}

// 绑定公众号查询
function userBindOfficialQuery(userId) {
    return get(`/ci-platform/user/bind_official_query/${userId}`)
}
// 团队绑定公众号
function teamUserBindOfficialQrcode(teamUserId) {
    return get(`/ci-platform/official/team_user_bind_official_qrcode/${teamUserId}`)
}
// 团队绑定公众号查询
function teamUserBindOfficialQuery(teamUserId) {
    return get(`/ci-platform/official/team_user_bind_official_query/${teamUserId}`)
}

// 获取一条数据
function deleteRole(id) {
    return get(`/ci-platform/role/delete/${id}`)
}

// 获取全部通知信息
function listAllNotice(id) {
    return get(`/ci-message/notice/all`)
}

// 获取角色绑定的通知tag
function listRoleBindNoticeTag(roleId) {
    return get(`/ci-message/notice/list_role_join_template_tag/${roleId}`)
}

function roleJoinNotice(data) {
    return post("/ci-message/notice/join_platform_role", data)
}

export {
    userUpdate,
    getRoleList,
    deleteRole,
    getUserOne,
    userBindOfficialQuery,
    userBindOfficialQrcode,
    teamUserBindOfficialQrcode,
    teamUserBindOfficialQuery,
    userDelete,
    roleInsert,
    roleUpdate,
    getRoleOne,
    userInsert,
    listRoleBindNoticeTag,
    listAllNotice,
    roleJoinNotice,
}
