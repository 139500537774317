import React from "react"
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { useSaleRoModel } from "../saleRoModel"
import useGlobalModel from "@/erp_subpackage/model/globalModel"
export function PageHeader() {
    const { searchParam, initPgCfg, organTypeCode, updateSearchParam, resetSearchParam, loadData } = useSaleRoModel()
    const { getSelectedDictOne } = useDictModel(() => [])
    const { orgDs } = useGlobalModel(({ orgDs }) => [orgDs])
    const searchFields = [
        ...(organTypeCode === 3
            ? [
                {
                    type: "Select",
                    placeholder: "所属机构",
                    fieldName: "shopId",
                    options: orgDs,
                    xxl:3,
                },
            ]
            : []),
        {
            type: "Input",
            placeholder: "退货单号",
            fieldName: "returnCode",
            xxl:3,
        },
        {
            type: "Input",
            placeholder: "客户名称",
            fieldName: "customerName",
            xxl:3,
        },
        {
            type: "Input",
            placeholder: "原销售单号",
            fieldName: "saleCode",
            xxl:3,
        },
        {
            type: "Select",
            placeholder: "单据状态",
            fieldName: "status",
            mode: "multiple",
            options: getSelectedDictOne(DictCodeEnmu.SALE_RETURN_STATUS),
            xxl:3,
        },
        {
            type: "Select",
            placeholder: "订单来源",
            fieldName: "orderSourceCode",
            options: getSelectedDictOne(DictCodeEnmu.ORDER_SOURCE),
            xxl:3,
        },
        {
            type: "Select",
            placeholder: "单据类型",
            fieldName: "orderTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.TRADE_TYPE),
            xxl:3,
        },
    ]
    const searchMoreFields = [
        {
            type: "Input",
            placeholder: "VIN码",
            fieldName: "vin",
        },
        {
            type: "Input",
            placeholder: "车牌号",
            fieldName: "licensePlate",
        },
        {
            type: "Input",
            placeholder: "车辆型号",
            fieldName: "vehicleModel",
        },
        {
            type: "Select",
            placeholder: "支付状态",
            fieldName: "payStatus",
            options: getSelectedDictOne(DictCodeEnmu.PAY_STATUS),
        },
        {
            type: "Select",
            placeholder: "结算类型",
            fieldName: "payType",
            options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
        },
        {
            type: "Select",
            placeholder: "支付方式",
            fieldName: "payMethodCode",
            options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD),
        },
        {
            type: "Input",
            placeholder: "制单人",
            fieldName: "createUser",
        },
        {
            type: "RangePicker",
            fieldName: '',
            rangePickerFieldName: ["settlementStartTime", "settlementEndTime"],
            placeholder: ["结算日期起始", "结算日期结束"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
        {
            type: "RangePicker",
            fieldName: '',
            rangePickerFieldName: ["startTime", "endTime"],
            placeholder: ["制单日期起始", "制单日期结束"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
    ]

    return (
        <YhPageHeader
            title="销售退货"
            justify="end"
            size="small"
            triggerSearch={true}
            searchValues={searchParam}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            handleSearch={(params) => {
                loadData(initPgCfg, { ...searchParam, ...params })
            }}
            handleReset={resetSearchParam}
        />
    )
}
