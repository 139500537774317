import React, { useEffect, useState } from "react"
import { Button, Form, Input, Col, Row, message, Switch, Upload, Modal, Select } from "antd"
import { insertInstrumentLight, updateInstrumentLight, getInstrumentLightOne, instrumentLightLlassifyPage } from "@/service/instrumentLamp"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"
import { getToken } from "@/utils/dataStorage"
const { Option } = Select
const SystemDetails = (props) => {
    const { modeKey, dispatch, mode, rowInfo } = props
    const [form] = Form.useForm()
    const [previewVisible, setpreviewVisible] = useState(false) //预览图片使用的dialog
    const [previewImage, setpreviewImage] = useState("") //预览展示的图片
    const [previewThumbnailImage, setpreviewThumbnailImage] = useState("") //预览展示的图片
    const [licenseFileList, setLicenseFileList] = useState([]) //图片list
    const [ilImageThumbnailList, setIlImageThumbnailList] = useState([]) //缩略图list
    const [instrumentLampTypeList, setInstrumentLampTypeList] = useState([]) //分类列表

    useEffect(() => {
        resetFormItem()
        getiInstrumentLightLlassifyList()
    }, [])
    const systemSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                ilStatus: values.ilStatus ? 1 : 0,
                ilImage: previewImage,
                ilImageThumbnail: previewThumbnailImage,
            }
            //新增
            if (mode === "insert") {
                insertInstrumentLight(data)
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            handleCancel()
                            message.success("新增成功")
                        // } else {
                        //     message.error(res.retMsg)
                        // }
                    })
                    // .catch((err) => {
                    //     message.error(err.retMsg)
                    // })
                //编辑
            } else if (mode === "edit") {
                updateInstrumentLight({ ...data, id: rowInfo.id })
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            handleCancel()
                            message.success("编辑成功")
                        // } else {
                        //     message.error(res.retMsg)
                        // }
                    })
                    // .catch((err) => {
                    //     message.error(err.retMsg)
                    // })
            }
        })
    }

    const getiInstrumentLightLlassifyList = () => {
        let data = {
            searchItem: {},
            pageNum: 1,
            pageSize: 1000,
            orders: [{ column: "updateTime", rule: "DESC" }],
        }
        instrumentLightLlassifyPage(data).then((res) => {
            // if (res && res.retCode === 200) {
                setInstrumentLampTypeList(res.retData.list)
            // }
        })
    }

    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }

    //行驶证图片查看
    const handlePreview = async (file) => {
        setpreviewVisible(true)
        Modal.info({
            title: `图片查看`,
            content: (<img alt="" style={{ width: "100%" }} src={file.url ||  file.thumbUrl} />),
            okText: "确定",
            cancelText: "取消",
            centered: true,
            icon:(<></>)
        })
    }
    // 图片上传
    const handleImgChange = async (info, type) => {
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            if (type === "image") {
                setLicenseFileList(info.fileList)
                setpreviewImage(md5)
            } else if (type === "thumbnailimage") {
                setIlImageThumbnailList(info.fileList)
                setpreviewThumbnailImage(md5)
            }
        } else if (info.file.status === "removed") {
            if (type === "image") {
                setLicenseFileList([])
                setpreviewImage("")
            } else if (type === "thumbnailimage") {
                setIlImageThumbnailList([])
                setpreviewThumbnailImage("")
            }
        }
    }
    //回显表单项
    const resetFormItem = () => {
        if (mode === "edit" || mode === "look") {
            getInstrumentLightOne(rowInfo.id).then((res) => {
                // if (res && res.retCode === 200) {
                    let result = res.retData
                    let imgFileList = [],
                        thumbnailImgFileList = []
                    if (result.ilImage) {
                        //回显图片， 有的话在做回显
                        imgFileList = [
                            {
                                uid: "-1",
                                status: "done",
                                url: `${fileLookUrl().image}${result.ilImage}`,
                            },
                        ]
                        //回显图片
                        setLicenseFileList(imgFileList)
                        setpreviewImage(result.ilImage)
                    }
                    if (result.ilImageThumbnail) {
                        //回显图片， 有的话在做回显
                        thumbnailImgFileList = [
                            {
                                uid: "-1",
                                status: "done",
                                url: `${fileLookUrl().image}${result.ilImage}`,
                            },
                        ]
                        //回显图片
                        setIlImageThumbnailList(thumbnailImgFileList)
                        setpreviewThumbnailImage(result.ilImageThumbnail)
                    }
                    form.setFieldsValue({
                        ...res.retData,
                        ilImage: imgFileList,
                        ilImageThumbnail: thumbnailImgFileList,
                        ilStatus: res.retData.ilStatus === 1 ? true : false,
                    })
                // }
            })
        } else {
            //新增默认推荐展示
            form.setFieldsValue({
                ilStatus: true,
            })
        }
    }

    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    //取消
    const handleCancel = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "listInstrumentLamp",
        })
    }

    return (
        <>
            <div className="toolbar">
                <span className="modules-name">仪表灯</span>
            </div>
            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>仪表灯</span>
                    </div>
                    <div className="list-but">
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={systemSubmit}>
                            保存
                        </Button>
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={handleCancel}>
                            取消
                        </Button>
                    </div>
                </div>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={100}>
                            <Col offset={3} span={9}>
                                <Form.Item
                                    label="名称"
                                    name="ilName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "名称不可为空",
                                        },
                                    ]}
                                >
                                    <Input disabled={isLook(mode)} placeholder="请输入名称" />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item
                                    label="分类"
                                    name="classifyId"
                                    rules={[
                                        {
                                            required: true,
                                            message: "分类不可为空",
                                        },
                                    ]}
                                >
                                    <Select disabled={isLook(mode)} placeholder="请选择分类" style={{ width: "100%" }}>
                                        {instrumentLampTypeList.map((item) => (
                                            <Option value={item.id} key={item.id}>
                                                {item.classifyName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col offset={3} span={9}>
                                <Form.Item label="排序" name="ilSort">
                                    <Input disabled={isLook(mode)} placeholder="请输入排序" />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item
                                    label="亮灯原因"
                                    name="ilReason"
                                    rules={[
                                        {
                                            required: true,
                                            message: "亮灯原因不可为空",
                                        },
                                    ]}
                                >
                                    <Input disabled={isLook(mode)} placeholder="请输入亮灯原因" />
                                </Form.Item>
                            </Col>
                            <Col offset={3} span={9}>
                                <Form.Item
                                    label="解决方案"
                                    name="ilSolution"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: "解决方案不可为空",
                                    //     },
                                    // ]}
                                >
                                    <Input disabled={isLook(mode)} placeholder="解决方案" />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item name="ilStatus" label="是否展示" valuePropName="checked">
                                    <Switch disabled={isLook(mode)} checkedChildren="是" unCheckedChildren="否" />
                                </Form.Item>
                            </Col>
                            <Col offset={3} span={9}>
                                <Form.Item
                                    label="缩略图"
                                    name="ilImageThumbnail"
                                    valuePropName="fileList"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请上传图片",
                                        },
                                    ]}
                                    getValueFromEvent={normFile}
                                >
                                    <Upload
                                        disabled={isLook(mode)}
                                        accept="image/*"
                                        headers={{ Authorization: getToken() }}
                                        action={`${fileUploadUrl}upload/image_old`}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={ilImageThumbnailList}
                                        onPreview={handlePreview}
                                        onChange={(info) => {
                                            handleImgChange(info, "thumbnailimage")
                                        }}
                                    >
                                        {ilImageThumbnailList.length >= 1 ? null : <Button>上传图片</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item
                                    label="图片"
                                    name="ilImage"
                                    valuePropName="fileList"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请上传图片",
                                        },
                                    ]}
                                    getValueFromEvent={normFile}
                                >
                                    <Upload
                                        disabled={isLook(mode)}
                                        accept="image/*"
                                        headers={{ Authorization: getToken() }}
                                        action={`${fileUploadUrl}upload/image_old`}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={licenseFileList}
                                        onPreview={handlePreview}
                                        onChange={(info) => {
                                            handleImgChange(info, "image")
                                        }}
                                    >
                                        {licenseFileList.length >= 1 ? null : <Button>上传图片</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={100}></Row>
                    </Form>
                </div>
            </div>
            {/* <Modal visible={previewVisible} title="图片查看" onCancel={() => setpreviewVisible(false)} onOk={() => setpreviewVisible(false)}>
                <img alt="" style={{ width: "100%" }} src={`${previewImage}`} />
            </Modal> */}
        </>
    )
}
export default SystemDetails
