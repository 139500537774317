import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { vehicleAttrTemplatePage, vehicleAttrTemplateDelete, vehicleAttrTemplateClone } from "@/service/dataService"
import { MyContext } from "@/store/myContext"
import Detail from "./detail"
import { Modal, Select } from "antd"
import {defFlagObj,templateTypeObj} from "./emun"
const { Option } = Select

export default () => {
    const { state, message } = useContext(MyContext)
    const [showVisible, setShowVisible] = useState(false)
    const [showMode, setShowMode] = useState("")
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息

    useEffect(() => {
        if (state.activeMenu === "vehicleEnclosure") {
            loadData(pagination.current)
        }
    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    //列表数据
    const loadData = (current, search = {}, order = { column: "updateTime", rule: "DESC" }) => {
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: [order],
        }
        vehicleAttrTemplatePage(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
        })
    }
    //分页事件 置空当前行信息
    // const onTableChange = async (page) => {
    //     await setpagination({
    //         ...pagination,
    //         current: page.current,
    //     })
    //     loadData(page.current, searchValue)
    // }

    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleClone(),
            content: "克隆",
        },
        {
            click: () => handleDelete(),
            content: "删除",
        },
    ]

    //克隆
    const handleClone = () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要克隆的车辆附件模版信息")
            return
        }
        Modal.confirm({
            content: `确定要克隆车辆附件模版--${selectInfo.rowInfo.templateName}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                vehicleAttrTemplateClone(selectInfo.rowInfo.id).then((res) => {
                    message.success("克隆成功")
                    loadData(pagination.current, searchValue)
                })
            },
        })
    }

    //删除
    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的车辆附件模版信息")
            return
        }
        Modal.confirm({
            content: `确定要删除车辆附件模版--${selectInfo.rowInfo.templateName}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                vehicleAttrTemplateDelete(selectInfo.rowInfo.id).then((res) => {
                    message.success("删除成功")
                    loadData(pagination.current, searchValue)
                })
            },
        })
    }
    //新增
    const handleDetails = async (mode) => {
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的车辆附件模版")
                return
            }
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的车辆附件模版")
                return
            }
        }
        setShowMode(mode)
        setShowVisible(true)
    }

    //分页事件 置空当前行信息
    const onTableChange = async (page, filters, sorter) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //table配置
    const columns = [
        {
            title: "序号",
            align: "center",
            width: 40,
            fixed: "left",
            render: (t, r, i) => i + 1,
        },
        {
            title: "模板名称",
            align: "center",
            dataIndex: "templateName",
        },
        {
            title: "描述信息",
            align: "center",
            dataIndex: "templateDesc",
        },
        {
            title: "模板类型",
            align: "center",
            dataIndex: "templateType",
            render: (text) => templateTypeObj[text],
        },
        {
            title: "绑定机构",
            align: "center",
            dataIndex: "organName",
        },
        {
            title: "默认模版",
            align: "center",
            dataIndex: "defFlag",
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "模版名称",
                valueName: "templateName",
            },
            {
                type: "input",
                placeholder: "描述信息",
                valueName: "templateDesc",
            },
            {
                type: "select",
                placeholder: "模版类型",
                valueName: "templateType",
                optionList: () =>
                    Object.entries(templateTypeObj).map((item) => (
                        <Option value={item[0]} key={item[0]}>
                            {item[1]}
                        </Option>
                    )),
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "vehicleEnclosure" && (
                <>
                    <TopSearch
                        title="车辆附件模版"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="车辆附件模版"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        scroll={{ x: "max-content" }}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />

                    {showVisible && (
                        <Detail
                            defFlagObj={defFlagObj}
                            templateTypeObj={templateTypeObj}
                            rowInfo={selectInfo.rowInfo}
                            visible={showVisible}
                            mode={showMode}
                            setVisible={setShowVisible}
                            onSuccess={() => {
                                loadData(1, searchValue)
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}
