import React from "react";
import { Button, message, Modal, InputNumber, Upload } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useQuestionModel } from "../questionModel";
import { DataTable } from "./DataTable";
import { questionService } from "../questionService";
import { importPropsConfig } from "@/erp_subpackage/utils/importFile";

export const DataBox = () => {
    const { selectedRowKeys, setDetailDialogInfo, onRemove, exportQuestion, downloadTpl } = useQuestionModel();

    //导入成功
    const importCallback = async (list) => {
        if (list && list.length) {
        }
    }


    //作废
    const onSetting = async () => {
        let mode = "add";
        let day = "1"
        let { retData: retOne } = await questionService.settingOne("trigger_mechanism");
        if (retOne) { mode = "edit"; day = retOne.value }
        Modal.confirm({
            title: '触发设置',
            content: <><span>设置 </span>
                <InputNumber
                    style={{ width: 60 }}
                    size="small"
                    defaultValue={day}
                    min="1"
                    max="1000"
                    onChange={(val) => { day = val }}
                    stringMode />
                <span> 天触发答题机制</span></>,
            okText: '确定',
            cancelText: '取消',
            onOk: async () => {
                if (mode === "add") {
                    let { retData } = await questionService.settingInsert({
                        code: "trigger_mechanism",
                        value: day,
                        deleteFlag: 0
                    });
                    retData && message.success("设置成功")
                } else {
                    let { retData } = await questionService.settingUpdate({
                        id: retOne.id,
                        code: "trigger_mechanism",
                        value: day,
                        deleteFlag: 0
                    });
                    retData && message.success("设置成功")
                }
            },
        });
    }

    const action = <>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => setDetailDialogInfo("add")}
        >新增</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (selectedRowKeys.length !== 1) {
                    message.warning("请选择单条数据进行查看，点击鼠标右键可一键选中哦！")
                    return;
                }
                setDetailDialogInfo("look")
            }}
        >查看</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (selectedRowKeys.length !== 1) {
                    message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！")
                    return;
                }
                setDetailDialogInfo("edit")
            }}
        >编辑</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (selectedRowKeys.length !== 1) {
                    message.warning("请选择单条数据进行删除，点击鼠标右键可一键选中哦！")
                    return;
                }
                onRemove()
            }}
        >删除</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => onSetting()}
        >触发设置</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => exportQuestion()}
        >导出</Button>
        <Upload showUploadList={false}  {...importPropsConfig(`/exam/questionBank/import`, importCallback)}>
            <Button className="btn-item" shape="round">导入</Button>
        </Upload>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => downloadTpl()}
        >下载模板</Button>
    </>;

    return (
        <YhBox title="问题列表" action={action}>
            <DataTable />
        </YhBox>
    )
}

