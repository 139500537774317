import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import { MyContext } from "@/store/myContext"
import Detail from "./detail"
import { Modal, Select } from "antd"
import { quotaSettlePage, deleteQuotaSettle, quotaOrderDetaiUpdate } from "@/service/teamService"
import TopSearch from "@/components/TopSearch"
import { teamInvoiceStatus } from "@/utils/enum"
import Settlement from "./settlement.jsx"
const { Option } = Select
export default () => {
    const { state, message } = useContext(MyContext)
    const [showVisible, setShowVisible] = useState(false)
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [settleVisible, setSettlevisible] = useState(false)

    useEffect(() => {
        if (state.activeMenu === "teamUnsettleds") {
            loadData(pagination.current)
        }
    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { settlementStatus: 1, ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "settlement_time",
                    rule: "DESC",
                },
            ],
        }
        quotaSettlePage(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //列表button
    const topButtons = [
        {
            click: () => {
                setSettlevisible(true)
            },
            content: "结算",
        },
        {
            click: () => {
                setShowVisible(true)
            },
            content: "账单明细",
        },
        {
            click: () => handleDelete(),
            content: "删除",
        },
    ]

    //删除
    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的结算单")
            return
        }
        Modal.confirm({
            content: `确定要删除结算单${selectInfo.rowInfo.settlementNo}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                deleteQuotaSettle(selectInfo.rowInfo.id).then((res) => {
                    message.success("删除成功")
                    loadData(pagination.current, searchValue)
                })
            },
        })
    }

    const handleChangeInvoiceStatus = (id) => {
        let invoiceStatus = ""
        Modal.confirm({
            title: "请选择开票状态",
            content: (
                <Select style={{ width: 240 }} placeholder="请选择开票状态" onChange={(val) => {
                    invoiceStatus = val
                }}>
                    {Object.entries(teamInvoiceStatus).map((item) => (
                        <Option value={item[0]} key={item[0]}>
                            {item[1]}
                        </Option>
                    ))}
                </Select>
            ),
            okText: "确定",
            cancelText: "取消",
            onOk: () => {
                return new Promise((resolve, reject) => {
                    if (invoiceStatus) {
                        resolve(Number(invoiceStatus))
                    } else {
                        message.warning("请选择发票状态")
                        reject("请选择发票状态")
                    }
                }).then((res) => {
                    let data = {
                        invoiceStatus: res,
                        id
                    }
                    quotaOrderDetaiUpdate(data).then((res) => {
                        loadData(1, searchValue)
                        message.success("发票状态更新成功！")
                       
                    })
                })
            },
            onCancel: () => {},
        })
    }

    //table配置
    const columns = [
        {
            title: "账单编号",
            align: "center",
            dataIndex: "settlementNo",
        },
        {
            title: "团队名称",
            align: "center",
            dataIndex: "teamName",
        },
        {
            title: "工单数量",
            align: "center",
            dataIndex: "orderNum",
        },
        {
            title: "账单金额",
            align: "center",
            dataIndex: "totalAmount",
        },
        {
            title: "业务经理",
            align: "center",
            dataIndex: "managerName",
        },
        {
            title: "账单类型",
            align: "center",
            dataIndex: "settlementMethod",
        },
        {
            title: "出账日期",
            align: "center",
            dataIndex: "settlementTime",
        },
        {
            title: "是否打款",
            align: "center",
            dataIndex: "settlementStatus",
            // render: (text) => (text === 0 ? "未打款" : "已打款"),
            render: (text) => (text === 1 ? "未打款" : "已打款"),
        },
        {
            title: "发票",
            align: "center",
            dataIndex: "invoiceStatus",
            render: (text, record) => (
                <>
                    <span>{teamInvoiceStatus[text]}</span>
                    <span style={{ color: "#2e3aeb", cursor: "pointer", marginLeft: "10px" }} onClick={() => {handleChangeInvoiceStatus(record.id)}}>
                        操作
                    </span>
                </>
            ),
        },
    ]
    // 搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "团队名称",
                valueName: "teamName",
            },
            {
                type: "select",
                placeholder: "请选择开票状态",
                valueName: "invoiceStatus",
                optionList: () =>
                    Object.entries(teamInvoiceStatus).map((item) => (
                        <Option value={item[0]} key={item[0]}>
                            {item[1]}
                        </Option>
                    )),
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "teamUnsettleds" && (
                <>
                    <TopSearch
                        title="未结算"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="未结算"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        scroll={{ x: "max-content" }}
                    />

                    {showVisible && <Detail rowInfo={selectInfo.rowInfo} visible={showVisible} setVisible={setShowVisible} setSettlevisible={setSettlevisible} />}

                    {settleVisible && <Settlement rowInfo={selectInfo.rowInfo} visible={settleVisible} setVisible={setSettlevisible} onSuccess={() => {
                                loadData(1)
                            }} />}
                </>
            )}
        </>
    )
}
