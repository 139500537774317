import React from "react";
import { useStockPoDetailModel } from "../backlogModel";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";



export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useStockPoDetailModel();
    const searchFields = [
        {
            type: 'Input',
            placeholder: "单据编号",
            fieldName: "bussCode"
        },
        {
            type:'Input',
            placeholder: "商品编号",
            fieldName: "materialCode"
        },
        {
            type: 'Input',
            placeholder: "商品名称",
            fieldName: "materialName"
        },
        {
            type:'Input',
            placeholder: "供应商名称",
            fieldName: "supplierName"
        },
        {
            type: 'Input',
            placeholder: "替换编号",
            fieldName: "replaceCode"
        },
        {
            type: 'Input',
            placeholder: "货位",
            fieldName: "shelfCode"
        },
    ]


    return (
        <YhPageHeader
            title="积压管理"
            size="small"
            justify="end"
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}