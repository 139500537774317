import React, { useEffect } from "react"
import { Form, Input, Col, Row, Select, message, Modal } from "antd"
import { faultCodeInsert, faultCodeUpdate, faultCodeOne } from "@/service/dataService"
import { getPcodeDict } from "@/utils/dataStorage"

export default (props) => {
    const { mode, rowInfo, visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()

    useEffect(() => {
        queryMotorcade()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            faultCodeOne(rowInfo.id).then((res) => {
                let results = res.retData
                form.setFieldsValue({
                    ...results,
                    threeGuarantees: results.threeGuarantees && results.threeGuarantees.split(","),
                })
            })
        }
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
            }

            if (mode === "edit") {
                data.id = rowInfo.id
                faultCodeUpdate(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                faultCodeInsert(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 2,
        },
        wrapperCol: {
            span: 22,
        },
    }

    const handleCancel = () => {
        setVisible("")
    }

    return (
        <>
            <Modal forceRender visible={visible} title="故障码" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={1000}>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={6}>
                                <Form.Item
                                    label="故障码"
                                    name="faultCode"
                                    rules={[{ required: true, message: "请输入故障码" }]}
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Input placeholder="故障码" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="FMI" name="fmi" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                    <Input placeholder="FMI" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="SPN" name="spn" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                    <Input placeholder="SPN" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="车辆品牌" name="vehicleBrand" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}  rules={[{ required: true, message: "请选择车辆品牌" }]}> 
                                    <Select placeholder="请选择车辆品牌" style={{ width: "100%" }}>
                                        {getPcodeDict("015").children.map((item) => (
                                            <Select.Option value={item.dictCode} key={item.id}>
                                                {item.title}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="所属车型" name="vehicleModel" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                    <Input placeholder="所属车型" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="相关部件" name="vehicleParts" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                                    <Input placeholder="相关部件" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="故障描述" name="faultDescribe" rules={[{ required: true, message: "请输入故障描述" }]}>
                                    <Input placeholder="故障描述" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="系统" name="faultSource">
                                    <Input placeholder="系统" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="导致问题" name="faultProblem">
                                    <Input placeholder="导致问题" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="产生原因" name="faultReason">
                                    <Input placeholder="产生原因" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="解决办法" name="faultSolve">
                                    <Input placeholder="解决办法" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
