import React, { useState, useEffect, useContext } from "react"
import { Tree, Button, Form, Input, message, Upload, Modal } from "antd"
import TopSearch from "@/components/TopSearch"
import { vehicleBrandTree, vehicleBrandInsert, vehicleBrandUpdate, vehicleBrandDelete } from "@/service/dataService"
import { MyContext } from "@/store/myContext"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"
import { getToken } from "@/utils/dataStorage"
import "./dictStyle.less"

const settingTypeObj = {
    brand: "品牌",
    model: "车型",
}
const settingTypeArr = Object.keys(settingTypeObj)
let pid = ""

const AllCarTypeManage = () => {
    const { state } = useContext(MyContext)
    const [form] = Form.useForm()
    const [treeList, setTreeList] = useState(null) //树结构列表
    const [selectTreeObj, setSelectTreeObj] = useState({}) //选择的字典数据
    const [mode, setMode] = useState("edit") //默认展示编辑
    const [settingType, setSettingType] = useState("") //操作的类型

    const [previewVisible, setpreviewVisible] = useState(false) //预览图片使用的dialog
    const [previewImage, setpreviewImage] = useState("") //预览展示的行驶证
    const [licenseFileList, setLicenseFileList] = useState([]) //图片list

    useEffect(() => {
        if (state.activeMenu === "vehicleBrand") {
            loadData()
        }
    }, [state])

    //字典树加载。
    const loadData = () => {
        vehicleBrandTree().then((res) => {
            let result = JSON.parse(
                JSON.stringify(res.retData)
                    .replace(/id/g, "key")
                    .replace(/name/g, "title")
                    .replace(/subItem/g, "children")
            )
            setTreeList(result)
        })
    }

    //选中当前行 根据当前行拿到信息
    const onSelect = (selectedKeys, { node }) => {
        setMode("edit")
        console.log(node)
        if (selectedKeys.length) {
            setSelectTreeObj(node)
            setSettingType(node.type)
            let imgFileList = []
            if (node.logo) {
                //回显图片， 有的话在做回显
                imgFileList = [
                    {
                        uid: "-1",
                        status: "done",
                        url: `${fileLookUrl().image}${node.logo}`,
                    },
                ]
            }
            //回显图片
            setLicenseFileList(imgFileList)
            setpreviewImage(node.logo)
            //编辑回显
            form.setFieldsValue({
                ...node,
                name: node.title,
                logo: imgFileList,
                id: node.key,
            })
        } else {
            setSelectTreeObj({})
            form.resetFields()
        }
    }
    //新增模式
    const handleInsert = (str) => {
        //新增品牌
        if (str === settingTypeArr[0]) {
            //品牌新增
            message.success("品牌新增模式开启")
            //设置该项为了展示
            setSelectTreeObj({ barndInsert: true })
        } else if (str === settingTypeArr[1]) {
            //新增车型
            if (selectTreeObj.type !== settingTypeArr[0]) {
                message.warning("请选择正确的添加位置")
                return
            }
            message.success("车型新增模式开启")
        }
        form.setFieldsValue({
            name: "",
            sort: "",
            logo: [],
        })
        setLicenseFileList([])
        setpreviewImage("")
        setMode("insert")
        setSettingType(str)
    }

    const handleDelete = () => {
        if (!Object.keys(selectTreeObj).length) {
            message.warning("请选择你要操作的选项")
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除车辆 - ${selectTreeObj.title}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                const { key: id } = selectTreeObj
                vehicleBrandDelete(id).then((res) => {
                    loadData()
                    setSelectTreeObj({})
                    message.success(`${selectTreeObj.title}:删除成功`)
                })
            },
        })
    }

    //禁用
    const deleteWv = () => {
        if (!Object.keys(selectTreeObj).length) {
            message.warning("请选择你要操作的选项")
            return
        }
        const { key: id, title: name, disableFlag, type, parentId, logo, sort } = selectTreeObj
        vehicleBrandUpdate({ id, name, type, parentId, logo, sort, disableFlag: disableFlag === 0 ? 1 : 0 }).then((res) => {
            loadData()
            setSelectTreeObj({})
            message.success(`${selectTreeObj.title}:操作成功`)
        })
    }
    //字典新增或编辑
    const wvSubmit = () => {
        form.validateFields().then((values) => {
            let data = { ...values, logo: previewImage }
            //新增
            if (mode === "insert") {
                let sendData = {
                    ...data,
                    disableFlag: 0,
                    type: settingType,
                }
                if (settingType === settingTypeArr[0]) {
                    sendData.parentId = null
                } else {
                    sendData.parentId = selectTreeObj.key
                }
                vehicleBrandInsert(sendData).then((res) => {
                    message.success("增加成功")
                    setMode("edit")
                    loadData()
                })
            } else {
                let sendData = {
                    ...selectTreeObj,
                    ...data,
                    type: settingType,
                    id: selectTreeObj.key,
                }
                //编辑
                vehicleBrandUpdate(sendData).then((res) => {
                    message.success("编辑成功")
                    loadData()
                })
            }
            setSelectTreeObj({})
        })
    }

    // 图片上传
    const handleImgChange = async (info) => {
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setpreviewImage(md5)
            setLicenseFileList(info.fileList)
        } else if (info.file.status === "removed") {
            setLicenseFileList([])
            setpreviewImage("")
        }
    }

    //表单项 布局
    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 12 },
    }

    const handlePreview = async (file) => {
        setpreviewVisible(true)
    }

    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }
    return (
        <>
            {state.activeMenu === "vehicleBrand" && (
                <>
                    <TopSearch title="车型管理" />
                    <div className="dict-container">
                        <div className="dict-list-box">
                            <div className="dict-list-header">
                                <div className="dict-list-name">
                                    <span>品牌列表</span>
                                </div>
                                <div className="dict-list-but">
                                    <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={() => handleInsert(settingTypeArr[0])}>
                                        品牌新增
                                    </Button>
                                    <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={() => handleInsert(settingTypeArr[1])}>
                                        型号新增
                                    </Button>
                                    <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={deleteWv}>
                                        {selectTreeObj.disableFlag ? "启用" : "禁用"}
                                    </Button>
                                    <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={() => handleInsert(handleDelete)}>
                                        删除
                                    </Button>
                                </div>
                            </div>
                            <div className="dict-table-box">
                                {treeList && <Tree selectedKeys={[selectTreeObj.id || ""]} onSelect={onSelect} treeData={treeList} />}
                            </div>
                        </div>
                        <div className="dict-from-box">
                            {Object.keys(selectTreeObj).length ? (
                                <>
                                    <div className="dict-list-header">
                                        <div className="dict-list-but">
                                            <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={wvSubmit}>
                                                保存
                                            </Button>
                                            <Button
                                                type="primary"
                                                shape="round"
                                                size="small"
                                                className="dict-but-left"
                                                onClick={() => {
                                                    setSelectTreeObj({})
                                                    setMode("edit")
                                                }}
                                            >
                                                取消
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="dict-from-content">
                                        <Form form={form} {...formItemLayout}>
                                            <Form.Item name="name" label="名称">
                                                <Input placeholder="名称" />
                                            </Form.Item>
                                            <Form.Item name="sort" label="排序">
                                                <Input placeholder="排序" />
                                            </Form.Item>
                                            <Form.Item label="logo" name="logo" valuePropName="fileList" getValueFromEvent={normFile}>
                                                <Upload
                                                    action={`${fileUploadUrl}upload/image_old`}
                                                    headers={{ authorization: getToken() }}
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    fileList={licenseFileList}
                                                    onPreview={handlePreview}
                                                    onChange={handleImgChange}
                                                >
                                                    {licenseFileList.length >= 1 ? null : <Button>上传logo</Button>}
                                                </Upload>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </>
                            ) : (
                                <div className="dict-init-box">
                                    <span>品牌列表</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <Modal
                        visible={previewVisible}
                        title="logo图片查看"
                        footer={null}
                        onCancel={() => setpreviewVisible(false)}
                        onOk={() => setpreviewVisible(false)}
                    >
                        <img alt="" style={{ width: "100%" }} src={`${fileLookUrl().image}${previewImage}`} />
                    </Modal>
                </>
            )}
        </>
    )
}
export default AllCarTypeManage
