import React, { useState }  from "react";
import { usePurchaseDrModel } from "../purchaseDrModel";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { useEffect } from "react"

export function PageHeader() {
    const { getSelectedDictOne } = useDictModel(() => []);
    const {fetchFmsShopNameList, orgDs } = useGlobalModel(({ orgDs }) => [orgDs])
    const [outList, setOutList] = useState([]);
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData,
        organTypeCode
    } = usePurchaseDrModel();
    useEffect(() => {
        fetchFmsShopNameList(' ',true).then((result) => {
            setOutList(result)
        }).catch((err) => {
            
        });
    }, []) 
    const searchFields = [
        ...(organTypeCode === 3 ? [
            {
                type: "Select",
                placeholder: "所属机构",
                fieldName: "shopId",
                options: orgDs,
            },
        ]
      : []),
        {
            type: "Input",
            placeholder: "调拨单号",
            fieldName: "code"
        },  {
            type: "Input",
            placeholder: "调入单号",
            fieldName: "transferInCode"
        }, {
            type: "Select",
            placeholder: "状态",
            fieldName: "status",
            mode:"multiple",
            options: getSelectedDictOne(DictCodeEnmu.TRANSFER_STATUS)
        }, 
        {
            type: "Select",
            placeholder: "调出机构",
            fieldName: "outShopName",
            showSearch:true,
            options: outList,
        },
         {
            type: "RangePicker",
            fieldName: "",
            rangePickerFieldName: ["inStartTime", "inEndTime"],
            placeholder: ["调入起始日期", "调入结束日期"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        }, {
            type: "Input",
            placeholder: "备注",
            fieldName: "inNote"
        }, {
            type: "RangePicker",
            fieldName: "",
            rangePickerFieldName: ["startTime", "endTime"],
            placeholder: ["请调起始日期", "请调结束日期"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        }
    ]
    // const searchMoreFields = [
    //     {
    //         type: SearchTypeEnum.RangePicker,
    //         fieldName: "",
    //         rangePickerFieldName: ["startTime", "endTime"],
    //         placeholder: ["请调起始日期", "请调结束日期"],
    //         format: "YYYY-MM-DD",
    //         span: 16,
    //         xxl: 8,
    //     }
    // ]
    return (
        <YhPageHeader
            title="调入单"
            size="small"
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}