
import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";
import { PORTAL_API_SERVER_HOST, API_SERVER_HOST } from "@/config/app";
import { getPortalToken } from "@/utils/dataStorage";

// export interface ISCMPage<T, S> extends IPage<T> {
//     statistics: S
// }

// export interface ISCMPurchaseDrDetail {
//     id: string,
//     address: string,   // "大兴长子营"
//     aggregateAmount: string,   // 0
//     applier: string,   // "请调员"
//     applyTime: string,   // "请调时间"
//     bentityCode: string,   // "bentity.bj-ouhao"
//     bentityName: string,   // "北京欧豪"
//     code: string,   // "WCS-X21081300003"
//     contact: string,   // "杨月"
//     createTime: string,   // "2021-08-13 10:string,   //11:string,   //57"
//     createUser: string,   // "管理员"
//     deleteFlag: string,   // 0
//     discountRate: string,   // "0"
//     discountTotalAmount: string,   // 0
//     favorableAmount: string,   // 0
//     note: string,   // null
//     outBentityCode: string,   // "bj-ouhao"
//     outBentityName: string,   // "北京欧豪汽车贸易有限公司"
//     outShopCode: string,   // "material-brand.yuchai"
//     outShopName: string,   // "电商汇总想"
//     paidAmount: string,   // 0
//     payStatus: string,   // null
//     payType: string,   // "结算类型"
//     phone: string,   // "15300035515"
//     shopCode: string,   // "SHOP-M-00000001"
//     shopName: string,   // "潍柴"
//     status: string,   // "transfer-status.out"
//     totalAmount: string,   // 68
//     totalNum: string,   // 1
//     transferIn: string,   // "请调员"
//     transferInTime: string,   // "请调员"
//     transferOut: string,   // "调出员"
//     transferOutTime: string,   // "调出时间"
//     transferTypeCode: string,   // "typeCode"
//     transferTypeName: string,   // "typeName"
//     updateTime: string,   // "2021-08-13 10:string,   //11:string,   //54"
//     updateUser: string,   // null
//     details: ISCMPDrGoodsOne[],
// }

// //列表统计字段
// export interface ISCMPurchaseDrStatistics {
//     // aggregateAmount: number | string,// "68.00"
//     // paidAmount: number | string,// "0.00"
//     totalAmount: number | string,// "68.00"
//     totalNum: number | string,// 2
// }
// //搜索条件
// export interface ISCMPurchaseDrSearchFields {
//     shopId?: string,
// }

// export interface ISCMPurchaseDrSearch
//     extends ISCMPurchaseDrSearchFields, ISearchPage {
// }

// export interface ISCMPurchaseDrFormFields {
//     code?: string,
//     outShopCode?: string,
//     discountRate: string | number,
//     aggregateAmount: string | number,
//     favorableAmount: string | number,
//     discountTotalAmount: string | number,
//     paidAmount?: string | number,
//     transferTypeCode?: string,
//     outBentityCode?: string,
//     shopCode?: string,
//     shopName?: string,
//     contact?: string,
//     payType?: string,
//     bentityCode?: string,
//     phone?: string,
//     note?: string,
//     applier?: string,
//     applyTime?: string,
//     transferOut?: string,
//     transferOutTime?: string,
//     transferIn?: string,
//     transferInTime?: string,
//     createUser?: string,
//     createTime?: string,
//     updateUser?: string,
//     updateTime?: string,
//     bentityName?: string,
//     outShopName?: string,
//     outBentityName?: string,
//     transferTypeName?: string,
//     [key: string]: any
// }

// /**
//  * 商品详情
//  */
// export interface ISCMPDrGoodsOne {
//     id: string,
//     shopCode?: string,       //
//     transferCode?: string,       //调拨单单号
//     materialCode?: string,       //
//     materialName?: string,       //
//     replaceCode?: string,       //
//     typeCode?: string,       //商品类型
//     typeName?: string,       //
//     unitCode?: string,       //
//     unitName?: string,       //
//     num: string | number,       //
//     price: string | number,       //
//     favorableAmount: string | number,       //单品优惠
//     discountRate: string | number,       //单品下浮
//     amount: string | number,       //
//     inShelfId?: string,       //
//     inShelfCode?: string,       //
//     inWarehouseName?: string,          //
//     inWarehouseCode?: string,         //仓库编码
//     outShelfId?: string,       //
//     outShelfCode?: string,       //
//     outWarehouseName?: string,         //
//     outWarehouseCode?: string,          //仓库编码
//     status?: string,       //
//     stockIoStatus?: string,       //出入库状态
//     payStatus?: string,       //* 支付状态
//     note?: string,       //
//     [key: string]: any
// }

// export interface IFmsShopUnitDetail {
//     bentityList: IVRMShopFormFields[],
//     address: string,
//     bentity: string,
//     branch: string,
//     branchList: string,
//     code: string,
//     contact: string,
//     createTime: string,
//     createUser: string,
//     deleteFlag: string,
//     enable: string,
//     id: string,
//     name: string,
//     note: string,
//     phone: string,
//     shop: string,
//     shopCode: string,
//     shopList: string,
//     shopName: string,
//     status: string,
//     tel: string,
//     updateTime: string,
//     updateUser: string,
//     ver: string,
// }

class PurchaseDrService {
    async page(searchVo) {
        const apiUrl = "/sms/transfer/to/listPage";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    async pageStatistics(searchVo) {
        const apiUrl = "/sms/transfer/to/TransferInSummary";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    async one(id) {
        const apiUrl = `/sms/transfer/to/findByIdOrCode/${id}`;
        return await httpRequest.get(apiUrl, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    async insert(insertVo) {
        const apiUrl = "/sms/transfer/to/insert";
        return await httpRequest.post(apiUrl, insertVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    async update(updateVo) {
        const apiUrl = "/sms/transfer/to/update";
        return await httpRequest.post(apiUrl, updateVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //查询门店结算单位的 下属关联
    async queryShopUnitBe(shopId) {
        const apiUrl = `/fms/accountUnit/find/one/${shopId}`;
        return await httpRequest.post(apiUrl, {}, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //查询云创机构 单条信息
    async queryOrganOne(id) {
        const apiUrl = `/ci-platform/organ/one/${id}`;
        return await httpRequest.get(apiUrl, { baseURL: API_SERVER_HOST });
    }
    //驳回
    async reject(id) {
        const apiUrl = `/sms/transfer/to/reject/${id}`;
        return await httpRequest.get(apiUrl, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //调入调出   调入：0，调出 1
    async whConfirm(obj) {
        const apiUrl = `/sms/transfer/to/whConfirm`;
        return await httpRequest.post(apiUrl, obj, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //调入调出   调入：0，调出 1
    async whConfirmCi(obj) {
        const apiUrl = `/ci-storage/transfer/to/whConfirm`;
        return await httpRequest.post(apiUrl, obj);
    }

    async export(searchVo) {
        const apiUrl = "/sms/transfer/to/export";
        return await httpRequest.postFile(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken(), timeout: 300000 });
    }
    async print(id) {
        const apiUrl = `/sms/transfer/to/print/${id}`;
        return await httpRequest.get(apiUrl, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //调入单明细下载模板
    async downloadTpl() {
        const appPageUrl = "/sms/transfer/to/detail/downloadTpl";
        return await httpRequest.getFile(appPageUrl, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //导出调入单明细
    async exportDetail(obj) {
        const appPageUrl = `/sms/transfer/to/exportDetailsByShopId`;
        return await httpRequest.postFile(appPageUrl, obj, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

    //导出调入单明细
    async exportPDrdetail(data) {
        const appPageUrl = `/sms/transfer/to/export`;
        return await httpRequest.postFile(appPageUrl, data, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken(),  timeout: 300000 });
    }

    //供应链采购明细查询接口
    async scm2TransferDetails(searchVo) {
        const apiUrl = "/scm2/inventory/details/page";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //云修采购明细查询接口
    async vrm2TransferDetails(searchVo) {
        const apiUrl = "/vrm2/stock/details/transfer/details";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //ci采购明细查询接口
    async ciTransferDetails(searchVo) {
        const apiUrl = "/ci-storage/stock/details/transfer/details";
        return await httpRequest.post(apiUrl, searchVo);
    }
    //客车采购明细查询接口
    async bcmTransferDetails(searchVo) {
        const apiUrl = "/bcm/stock/details/transfer/details";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //业务系统库房确认接口
    async vrm2WhConfirm(searchVo) {
        const apiUrl = "/ci-storage/stock/details/whConfirm";
        return await httpRequest.post(apiUrl, searchVo);
    }
    //查询商品最近仓库货位
    async vrm2StockQuery(searchVo) {
        const apiUrl = "/ci-storage/stock/details/query";
        return await httpRequest.post(apiUrl, searchVo);
    }
    //查询各机构库存
    async eachSystemFindPage(searchVo) {
        const apiUrl = "/ci-storage/stock/eachSystemFindPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    //本机构下商品已有货位查询
    async getMaterialShelf(searchVo) {
        const apiUrl = "/ci-storage/purchase/getMaterialShelf";
        return await httpRequest.post(apiUrl, searchVo);
    }

    async check (branchIn,params) {
        const apiUrl = `${branchIn}/base/material/checkMaterialExist`
        const baseURL = branchIn === 'ci-storage' ? undefined : PORTAL_API_SERVER_HOST
        return await httpRequest.post(apiUrl, params, {baseURL})
    }
    async checkScm (branchIn,params) {
        const apiUrl = `${branchIn}/purchase/po/checkMaterialExist`
        const baseURL = branchIn === 'ci-storage' ? undefined : PORTAL_API_SERVER_HOST
        return await httpRequest.post(apiUrl, params, {baseURL})
    }
}

export const purchaseDrService = new PurchaseDrService();