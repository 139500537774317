import React, { useEffect, useState } from "react"
import { Form, Input, Col, Row, message, Modal, } from "antd"
import { vehicleBrandnameInsert, vehicleBrandnameUpdate, vehicleBrandnameOne } from "@/service/dataService"

export default (props) => {
    const { mode, rowInfo, visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()

    useEffect(() => {
        queryOne()
    }, [])

    //查看编辑回显
    const queryOne = () => {
        if (mode === "look" || mode === "edit") {
            vehicleBrandnameOne(rowInfo.id).then((res) => {
                let results = res.retData
                form.setFieldsValue({
                    ...results,
                })
            })
        }
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                "brandLevel": 1,
            }

            if (mode === "edit") {
                data.id = rowInfo.id
                vehicleBrandnameUpdate(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                vehicleBrandnameInsert(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleCancel = () => {
        setVisible("")
    }

    return (
        <>
            <Modal forceRender open={visible} title="车辆品牌" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={600}>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Form.Item label="品牌名称" name="brandName" rules={[{ required: true, message: "请输入品牌名称" }]}>
                                    <Input placeholder="品牌名称" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
