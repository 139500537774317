import React, { useEffect, useState } from "react"
import { Modal, Button, Input, message, Radio } from "antd"
import { orderPrintInfo, printNum, getOrderOne } from "@/service/ordersService"
import "./orderPrint.css"
import { getUserInfo, getPcodeDict } from "@/utils/dataStorage"
import { numberParseChina } from "@/utils/util"
import moment from "moment"
import PrintOrderSet from "./printOrderSet"
const ParintMadal = (props) => {
    const { visible, onClose } = props
    // const [protList, setProtList] = useState([])
    const [protListSB, setProtListSB] = useState([])
    const [protListSF, setProtListSF] = useState([])
    const [itemListSB, setItemListSB] = useState([])
    const [itemListSF, setItemListSF] = useState([])
    const [otherList, setOtherList] = useState([])
    const [baseInfo, setBaseInfo] = useState({})
    const [dictObj, setDictObj] = useState({ serviceType: {}, orderSource: {}, orderTypes: {} })
    const [setVisible, setSetVisible] = useState(false)
    const [orderType, setOrderType] = useState("")
    const [serviceNature, setServiceNature] = useState("")
    const [titles, setTieles] = useState("")
    const [price, setPrice] = useState({})
    const [orderCode, setOrderCode] = useState('')
    const [payType, setPayType] = useState("");

    useEffect(() => {
        initDict()
        loadData()
    }, [])
    const loadData = () => {
        let data = {
            pageSize: 1000,
            searchItem: { orderId: visible.orderId },
        }
        // const apis = [getOrderOne(visible.orderId), getMaintanMaterialList(data), getOrderItem(data), getOrderOtherItems(data)]
        // Promise.all(apis).then((res) => {
        //     const [orderInfo, protList, orderItem, otherItem] = res
        //     setBaseInfo(orderInfo.retData)
        //     const { ciBusinessEntityName, orderType: orderTypes } = orderInfo.retData

        //     setOrderType(orderTypes)
        //     setProtListSB(protList.retData.list.filter((item) => item.serviceNature === "016001"))
        //     setProtListSF(protList.retData.list.filter((item) => item.serviceNature === "016002"))
        //     setItemList(orderItem.retData.list)
        //     setOtherList(otherItem.retData.list)
        //     if (orderTypes && orderTypes === "014003") {
        //         setSetVisible(true)
        //     } else if (orderTypes && orderTypes === "014001") {
        //         //三包
        //         setSetVisible("016001")
        //     } else if (orderTypes && orderTypes === "014002") {
        //         //收费
        //         setSetVisible("016002")
        //     }
        //     setTieles(ciBusinessEntityName)
        // })
        getOrderOne(visible.orderId).then((res) => {
            let results = res.retData;
            setPayType(results?.settlementInfo?.payTypeDesc);
        });

        orderPrintInfo({ orderId: visible.orderId,printType:'normal' }).then((res) => {
            console.log(res)
            const {
                orderInfo,
                orderOtherItemBills,
                orderMaintainMaterialThreeGuaranteesBills,
                orderMaintainMaterialBills,
                orderItemBills,
                orderItemThreeGuaranteesBills,
                orderItemActualAmount,
                orderItemThreeGuaranteesAmount,
                orderMaintainMaterialAmount,
                orderMaintainMaterialThreeGuaranteesAmount,
                otherItemActualAmount,
                orderTotalAmount,
                orderActualAmount,
            } = res.retData
            let { orderCode: orderId } = orderInfo
            setOrderCode(orderId)
            setBaseInfo(orderInfo)
            const { ciBusinessEntityName, orderType: orderTypes } = orderInfo
            setOrderType(orderTypes)
            if (orderTypes && orderTypes === "014003") {
                setSetVisible(true)
            } else if (orderTypes && orderTypes === "014001") {
                //收费
                setServiceNature("016002")
            } else if (orderTypes && orderTypes === "014002") {
                // 三包
                setServiceNature("016001")
            }else if (orderTypes && orderTypes === "014006") {
                // 云豪三包
                setServiceNature("016004")
            }
            setTieles(ciBusinessEntityName)
            // 016001 三包
            // 016002 收费
            // let allMaintain=[]
            // let newMaintainList=[]
            // orderMaintainMaterialThreeGuaranteesBills.map((item)=>{
            //     if(allMaintain.includes({materialCode:item.materialCode,unitPrice:switchNum(item.unitPrice)})){
            //         newMaintainList.push(item)
            //         debugger
            //     }else{
            //         allMaintain.push({materialCode:item.materialCode,unitPrice:switchNum(item.unitPrice)})
            //     }
            // })
            let allMaintainSB=[]
            let newMaintainListSB=[]
            orderMaintainMaterialThreeGuaranteesBills.map((item)=>{
                if(allMaintainSB.includes(item.materialCode)){
                    let data=newMaintainListSB.find((list)=>list.materialCode==item.materialCode)
                    let dataIndex=newMaintainListSB.findIndex((list)=>list.materialCode==item.materialCode)
                    if(data.unitPrice==item.unitPrice){
                        newMaintainListSB[dataIndex]={...newMaintainListSB[dataIndex],materialNumber:(+newMaintainListSB[dataIndex].materialNumber)+(+item.materialNumber)}
                    }
                    else{
                        newMaintainListSB.push(item) 
                    }
                }else{
                    allMaintainSB.push(item.materialCode)
                    newMaintainListSB.push(item) 
                }
            })
            let allMaintainSF=[]
            let newMaintainListSF=[]
            orderMaintainMaterialBills.map((item)=>{
                if(allMaintainSF.includes(item.materialCode)){
                    let data=newMaintainListSF.find((list)=>list.materialCode==item.materialCode)
                    let dataIndex=newMaintainListSF.findIndex((list)=>list.materialCode==item.materialCode)
                    if(data.unitPrice==item.unitPrice){
                        newMaintainListSF[dataIndex]={...newMaintainListSF[dataIndex],materialNumber:(+newMaintainListSF[dataIndex].materialNumber)+(+item.materialNumber),actualAmount:(+newMaintainListSF[dataIndex].actualAmount)+(+item.actualAmount)}
                    }
                    else{
                        newMaintainListSF.push(item) 
                    }

                   
                }else{
                    allMaintainSF.push(item.materialCode)
                    newMaintainListSF.push(item) 
                }
            })
            setProtListSB(newMaintainListSB)
            setProtListSF(newMaintainListSF)
            // setProtListSB(orderMaintainMaterialThreeGuaranteesBills)
            // setProtListSF(orderMaintainMaterialBills)
            setItemListSB(orderItemThreeGuaranteesBills)
            setItemListSF(orderItemBills)
            setOtherList(orderOtherItemBills)
            setPrice({
                orderItemActualAmount,
                orderItemThreeGuaranteesAmount,
                orderMaintainMaterialAmount,
                orderMaintainMaterialThreeGuaranteesAmount,
                otherItemActualAmount,
                orderTotalAmount,
                orderActualAmount,
            })
        })
    }

    //初始下订单用到的字典
    const initDict = () => {
        let obj = {
            serviceType: {}, //服务类型
            orderSource: {}, //支付方式
            orderTypes: {},
            threeGuaranteesUnit: {},
        }
        getPcodeDict("011").children.forEach((item) => {
            obj.serviceType[item.dictCode] = item.title
        })
        getPcodeDict("018").children.forEach((item) => {
            obj.orderSource[item.dictCode] = item.title
        })
        getPcodeDict("014").children.forEach((item) => {
            obj.orderTypes[item.dictCode] = item.title
        })
        getPcodeDict("106").children.forEach((item) => {
            obj.threeGuaranteesUnit[item.dictCode] = item.title
        })

        setDictObj(obj)
    }

    const hanldeSet = (val) => {
        const { name, serviceNature: chooseserviceNature } = val
        setTieles(name)
        chooseserviceNature && setServiceNature(chooseserviceNature)
    }
    //设置抬头
    // const setTitle = () => {
    //     let title = titles || ""
    //     let chooseServiceNature = ""
    //     Modal.confirm({
    //         title: "打印设置",
    //         content: (
    //             <>
    //                 {orderType === "014003" && (
    //                     <>
    //                         <div>请选择三包类型</div>
    //                         <Radio.Group
    //                             defaultValue=""
    //                             onChange={(e) => {
    //                                 chooseServiceNature = e.target.value
    //                             }}
    //                         >
    //                             <Radio value="016001">三包</Radio>
    //                             <Radio value="016002">收费</Radio>
    //                             <Radio value="014003">翻修</Radio>
    //                             <Radio value="all">翻修</Radio>
    //                         </Radio.Group>
    //                     </>
    //                 )}
    //                 <div>请输入设置的名称</div>
    //                 <Input
    //                     defaultValue={titles}
    //                     placeholder="请输入设置的名称"
    //                     onChange={(e) => {
    //                         title = e.target.value
    //                     }}
    //                 />
    //             </>
    //         ),
    //         okText: "确定",
    //         cancelText: "取消",
    //         onOk: () => {
    //             // return new Promise((resolve, reject) => {
    //             //     // if (title) {
    //             //     //     resolve(title)
    //             //     // } else {
    //             //     //     message.warning("请输入要设置的名称")
    //             //     //     reject("请输入要设置的名称")
    //             //     // }
    //             // }).then((res) => {
    //             //     setTieles(res)
    //             // })
    //             setTieles(title)
    //             setServiceNature(chooseServiceNature)
    //         },
    //         onCancel: () => {},
    //     })
    // }
    //打印次数

    //打印
    // const print = async () => {

    //     if (!baseInfo.orderCode) {
    //         message.error("打印正在准备，请5秒后再点击")
    //         return
    //     }

    //     const el = document.getElementById("print-container")
    //     const iframe = document.createElement("IFRAME")
    //     let doc = null
    //     // iframe.setAttribute('style', 'position:absolute;top:0px;left:0px;width:100%;height:100%',);
    //     document.body.appendChild(iframe)
    //     doc = iframe.contentWindow.document
    //     // 引入打印的专有CSS样式，根据实际修改
    //     doc.write(`<LINK rel="stylesheet" type="text/css" href="./orderPrint.css">`)
    //     doc.write(el.innerHTML)
    //     doc.close()
    //     // 获取iframe的焦点，从iframe开始打印
    //     iframe.contentWindow.focus()
    //     iframe.onload = () => {
    //         iframe.contentWindow.print()
    //         document.body.removeChild(iframe)
    //     }
    //     if (navigator.userAgent.indexOf("MSIE") > 0) {
    //         document.body.removeChild(iframe)
    //     }
    //     const old = window.document.body.innerHTML
    //     const printPart = document.getElementById("print-container")
    //     window.document.body.innerHTML = ""
    //     window.document.body.appendChild(printPart)
    //     window.print()
    //     await printNum(visible.orderId)
    //     window.document.body.innerHTML = old
    //     window.location.reload()


    // }
    const print = async () => {

        if (!baseInfo.orderCode) {
            message.error("打印正在准备，请5秒后再点击")
            return
        }

        const el = document.getElementById('print-container');
        const iframe = document.createElement('IFRAME');
        let doc = null;
        document.body.appendChild(iframe);
        doc = iframe.contentWindow.document;
        // 引入打印的专有CSS样式，根据实际修改
        doc.write(`<LINK rel="stylesheet" type="text/css" href="./orderPrint.css">`);
        doc.write(el?.innerHTML);
        doc.close();
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus();
        iframe.onload = () => {
            iframe.contentWindow.print();
            document.body.removeChild(iframe);
        };
        if (navigator.userAgent.indexOf("MSIE") > 0) {
            document.body.removeChild(iframe);
        }
        await printNum({orderId:visible.orderId,printType:'financial'})
    }
    // const printPreview = async () => {
    //     if (!baseInfo.orderCode) {
    //         message.error("打印正在准备，请5秒后再点击")
    //         return
    //     }
    //     const el = document.getElementById("print-container")
    //     const iframe = document.createElement("IFRAME")
    //     let doc = null
    //     // iframe.setAttribute('style', 'position:absolute;top:0px;left:0px;width:100%;height:100%',);
    //     document.body.appendChild(iframe)
    //     doc = iframe.contentWindow.document
    //     // 引入打印的专有CSS样式，根据实际修改
    //     doc.write(`<LINK rel="stylesheet" type="text/css" href="./orderPrint.css">`)
    //     doc.write(el.innerHTML)
    //     doc.close()
    //     // 获取iframe的焦点，从iframe开始打印
    //     iframe.contentWindow.focus()
    //     iframe.onload = () => {
    //         iframe.contentWindow.print()
    //         document.body.removeChild(iframe)
    //     }
    //     if (navigator.userAgent.indexOf("MSIE") > 0) {
    //         document.body.removeChild(iframe)
    //     }
    //     const old = window.document.body.innerHTML
    //     const printPart = document.getElementById("print-container")
    //     window.document.body.innerHTML = ""
    //     window.document.body.appendChild(printPart)
    //     window.print()
    //     window.document.body.innerHTML = old
    //     window.location.reload() 
    // }
    //转换number，保留两个0
    const switchNum = (num, n = 2) => {
        return Number(num).toFixed(n)
    }
    return (
        <Modal
            title="工单详情打印"
            width={1120}
            visible={visible.visible}
            onCancel={() => onClose({ visible: false, orderId: "" })}
            footer={[
                <Button onClick={() => onClose({ visible: false, orderId: "" })} key="back">
                    {" "}
                    取消
                </Button>,
                //      <Button type="primary" onClick={printPreview}  key="submit">
                //     打印预览
                //    </Button>,
                <Button type="primary" onClick={print} key="submit">
                    确认打印
                </Button>,
                <Button
                    type="primary"
                    onClick={() => {
                        setSetVisible(true)
                    }}
                    key="setPrintName"
                >
                    设置
                </Button>,
            ]}
        >
            <div id="print-container">
                <div className="print-container">
                    <p className="title" style={{ fontSize: "28px", marginBottom: '20px', marginTop: "40px" }}>
                        {titles}
                        {serviceNature === "016001" && "三包"}
                        {serviceNature === "016002" && "收费"}
                        {serviceNature === "016004" && "云豪大包"}结算单
                    </p>
                    <div className="print-name" style={{ fontSize: "18px", marginBottom: '18px' }}>
                        <div style={{ textAlign: "left", width: '30%' }}>
                            工单编号:
                            <span>
                                {baseInfo.orderCode}
                                {serviceNature === "016001" && "SB"}
                                {serviceNature === "016002" && "SF"}
                                {serviceNature === "016004" && "YHDB"}
                            </span>
                        </div>
                        <div style={{ textAlign: "center", width: '15%' }}>
                            工单类型:
                            <span>
                                {serviceNature === "016001" && "三包"}
                                {serviceNature === "016002" && "收费"}
                                {serviceNature === "016004" && "云豪大包"}
                            </span>
                        </div>
                        <div style={{ textAlign: "center", width: '15%' }}>
                            支付方式:
                            {/* <span>
                                {baseInfo.payType && baseInfo.payType === "auth_quota"
                                    ? "预授权支付"
                                    : baseInfo.payType === "wechart_pay"
                                    ? "微信支付"
                                    : baseInfo.payType === "cash"
                                    ? "现金"
                                    : ''}
                            </span> */}
                            <span>
                                {payType}
                                {/* {!baseInfo.payType && !baseInfo.settlementInfo ? "" : baseInfo.payType && baseInfo.payType === "wechart_pay"
                                    ? "微信支付"
                                    : baseInfo.payType === "cash" || baseInfo.settlementInfo?.accountType == "account-type.cash"
                                        ? "现金"
                                        : "预授权支付"} */}
                            </span>
                        </div>
                        <div style={{ textAlign: "center", width: '40%' }}>
                            {/* 打印日期:
                            <span>{moment().format("YYYY-MM-DD HH:mm")}</span> */}
                            结算方:
                            <span>{baseInfo?.settlementInfo?.settlementPartyName}</span>
                        </div>
                    </div>
                    <table className="print-table print-table-first" style={{ fontSize: "18px", fontWeight: 'bold' }}>
                        <tbody>
                            <tr className="table-head">
                                <td>车辆牌照</td>
                                <td>{baseInfo.customerVehicleCode}</td>
                                <td>客户名称</td>
                                <td colSpan={5}>{baseInfo.customerName}</td>
                            </tr>
                            <tr className="table-head">
                                <td>团队名称</td>
                                <td>{baseInfo.teamName}</td>
                                <td>联 系 人</td>
                                <td>{baseInfo.repairContactName}</td>
                                <td>电话</td>
                                <td>{baseInfo.repairContactPhone}</td>
                                <td>业务员</td>
                                <td>{baseInfo.dockPeople}</td>
                            </tr>
                            <tr className="table-head">
                                <td>车型</td>
                                <td>{baseInfo.customerVehicleModel}</td>
                                <td>颜色</td>
                                <td>无</td>
                                <td>维修日期</td>
                                <td>{baseInfo.createTime ? moment(baseInfo.createTime).format("YYYY-MM-DD HH:mm") : null}</td>
                                <td>完工日期</td>
                                <td>{baseInfo.orderCompletedTime ? moment(baseInfo.orderCompletedTime).format("YYYY-MM-DD HH:mm") : null}</td>
                            </tr>
                            <tr className="table-head">
                                <td>故障描述</td>
                                <td colSpan={7}>{baseInfo.faultDesc}</td>
                            </tr>
                        </tbody>
                    </table>
                    {(serviceNature === "016002" || serviceNature === "016004") && (
                        <table className="print-table" style={{ fontSize: "18px", fontWeight: 'bold' }}>
                            <tbody>
                                <tr className="table-item">
                                    <td>项目编号</td>
                                    <td colSpan={7}>项目名称</td>
                                    <td>工时</td>
                                    <td>工时费</td>
                                    <td>备注</td>
                                    {/* <td>服务类型</td> */}
                                </tr>

                                {/* {serviceNature === "016001" ? (
                                itemListSB.length > 0 ? (
                                    itemListSB.map((item, index) => {
                                        return (
                                            <tr className="table-item" key={item.id}>
                                                <td>{item.itemCode}</td>
                                                <td colSpan={7}>{item.itemName}</td>
                                                <td>{switchNum(item.itemWorkHours, 1)}</td>
                                                <td>{switchNum(item.actualAmount)}</td>
                                                <td>{item.remark}</td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr className="table-prot">
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                    </tr>
                                )
                            ) : (
                                ""
                            )} */}

                                {(serviceNature === "016002" || serviceNature === "016004") ? (
                                    itemListSF.length > 0 ? (
                                        itemListSF.map((item, index) => {
                                            return (
                                                <tr className="table-item" key={item.id}>
                                                    <td>{item.itemCode}</td>
                                                    <td colSpan={7}>{item.itemName}</td>
                                                    <td>{switchNum(item.itemWorkHours, 1)}</td>
                                                    <td>{switchNum(item.actualAmount)}</td>
                                                    <td>{item.remark}</td>
                                                    {/* <td>{dictObj.serviceType[baseInfo.serviceType]}</td> */}
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <tr className="table-prot">
                                            <td>无</td>
                                            <td colSpan={7}>无</td>
                                            <td>无</td>
                                            <td>无</td>
                                            <td>无</td>
                                        </tr>
                                    )
                                ) : (
                                    ""
                                )}
                            </tbody>
                        </table>
                    )}
                    <table className="print-table" style={{ fontSize: "18px", fontWeight: 'bold' }}>
                        <tbody>
                            {(serviceNature === "016002" || serviceNature === "016004") && (
                                <tr className="table-prot">
                                    <td>序号</td>
                                    <td>商品编号</td>
                                    <td>商品名称</td>
                                    <td>数量</td>
                                    <td>单价</td>
                                    <td>总计</td>
                                    {/* <td>品牌</td> */}
                                    <td>备注</td>
                                </tr>
                            )}
                            {serviceNature === "016001" && (
                                <tr className="table-prot">
                                    <td>序号</td>
                                    <td>商品编号</td>
                                    <td>商品名称</td>
                                    <td>单位</td>
                                    <td>数量</td>
                                    <td>单价</td>
                                    <td>三包单位</td>
                                    <td>索赔单号</td>
                                </tr>
                            )}

                            {serviceNature === "016001" ? (
                                protListSB.length > 0 ? (
                                    protListSB.map((item, index) => {
                                        return (
                                            <tr className="table-prot" key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.materialCode}</td>
                                                <td>{item.materialName}</td>
                                                <td>{item.unitName}</td>
                                                <td>{item.materialNumber}</td>
                                                <td>{switchNum(item.unitPrice)}</td>
                                                <td>{dictObj.threeGuaranteesUnit[item.threeGuaranteesUnit]}</td>
                                                <td>{item.claimNumber}</td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr className="table-prot">
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                    </tr>
                                )
                            ) : (
                                ""
                            )}

                            {(serviceNature === "016002" || serviceNature === "016004") ? (
                                protListSF.length > 0 ? (
                                    protListSF.map((item, index) => {
                                        return (
                                            <tr className="table-prot" key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.materialCode}</td>
                                                <td>{item.materialName}</td>
                                                <td>{item.materialNumber}</td>
                                                <td>{switchNum(item.unitPrice)}</td>
                                                <td>{switchNum(item.actualAmount)}</td>
                                                {/* <td>{item.brandName}</td> */}
                                                <td>{item.remark}</td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr className="table-prot">
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                    </tr>
                                )
                            ) : (
                                ""
                            )}

                            {/* {protList.length > 0 ? (
                                protList.map((item, index) => {
                                    if (serviceNature === item.serviceNature) {
                                        if (serviceNature === "016001") {
                                            return (
                                                <tr className="table-prot" key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.materialCode}</td>
                                                    <td>{item.materialName}</td>
                                                    <td>{item.unitName}</td>
                                                    <td>{item.materialNumber}</td>
                                                    <td>{switchNum(item.unitPrice)}</td>
                                                    <td>{dictObj.threeGuaranteesUnit[item.threeGuaranteesUnit]}</td>
                                                    <td>{item.claimNumber}</td>
                                                </tr>
                                            )
                                        } else if (serviceNature === "016002") {
                                            return (
                                                <tr className="table-prot" key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.materialCode}</td>
                                                    <td>{item.materialName}</td>
                                                    <td>{item.materialNumber}</td>
                                                    <td>{switchNum(item.unitPrice)}</td>
                                                    <td>{switchNum(item.actualAmount)}</td>
                                                    <td>{item.brandName}</td>
                                                    <td>{item.remark}</td>
                                                </tr>
                                            )
                                        }
                                    }
                                })
                            ) : (
                                <tr className="table-prot">
                                    <td>无</td>
                                    <td>无</td>
                                    <td>无</td>
                                    <td>无</td>
                                    <td>无</td>
                                    <td>无</td>
                                    <td>无</td>
                                    <td>无</td>
                                </tr>
                            )} */}
                        </tbody>
                    </table>
                    {otherList.length > 0 && (serviceNature === "016002" || serviceNature === "016004") && (
                        <table className="print-table" style={{ fontSize: "18px", fontWeight: 'bold' }}>
                            <tbody>
                                <tr className="table-other">
                                    <td>序号</td>
                                    <td>项目编码</td>
                                    <td>项目名称</td>
                                    <td>数量</td>
                                    <td>单价</td>
                                    <td>金额</td>
                                    <td>备注</td>
                                </tr>
                                {otherList.map((item, index) => (
                                    <tr className="table-other" key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>{item.itemCode}</td>
                                        <td>{item.itemName}</td>
                                        <td>{item.itemNumber}</td>
                                        <td>{switchNum(item.unitPrice)}</td>
                                        <td>{switchNum(item.itemAmount)}</td>
                                        <td>{item.remark}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <table className="print-table print-table-last" style={{ fontSize: "18px", fontWeight: 'bold' }} >
                        <tbody>
                            {serviceNature === "016001" && (
                                <>
                                    <tr className="table-footer">
                                        <td>工时费</td>
                                        <td>{switchNum(price.orderItemThreeGuaranteesAmount)}</td>
                                        <td>材料费</td>
                                        <td>{switchNum(price.orderMaintainMaterialThreeGuaranteesAmount)}</td>
                                        <td>其他费用</td>
                                        {/* <td>{switchNum(price.otherItemActualAmount)}</td> */}
                                        <td>{switchNum(0)}</td>
                                        <td>合计金额</td>
                                        <td>{switchNum(price.orderItemThreeGuaranteesAmount + price.orderMaintainMaterialThreeGuaranteesAmount)}</td>
                                        <td>含税金额</td>
                                        <td>{switchNum(price.orderItemThreeGuaranteesAmount + price.orderMaintainMaterialThreeGuaranteesAmount)}</td>
                                    </tr>
                                    <tr className="table-footer">
                                        <td>实收金额</td>
                                        <td>{switchNum(price.orderItemThreeGuaranteesAmount + price.orderMaintainMaterialThreeGuaranteesAmount)}</td>
                                        <td>大写</td>
                                        <td colSpan={5}>
                                            {numberParseChina(price.orderItemThreeGuaranteesAmount + price.orderMaintainMaterialThreeGuaranteesAmount)}
                                        </td>
                                        <td>收款人</td>
                                        <td></td>
                                    </tr>
                                    <tr className="table-footer">
                                        {/* <td>未结金额</td>
                                <td>{0.00}</td> */}

                                        <td>客户签字</td>
                                        <td colSpan={12}></td>
                                    </tr>
                                </>
                            )}
                            {(serviceNature === "016002" || serviceNature === "016004") && (
                                <>
                                    <tr className="table-footer">
                                        <td>工时费</td>
                                        <td>{switchNum(price.orderItemActualAmount)}</td>
                                        <td>材料费</td>
                                        <td>{switchNum(baseInfo.orderMaintainMaterialAmount)}</td>
                                        <td>其他费用</td>
                                        <td>{switchNum(baseInfo.otherItemActualAmount)}</td>
                                        <td>合计金额</td>
                                        <td>{switchNum(baseInfo.orderTotalAmount)}</td>
                                        <td>含税金额</td>
                                        <td>{switchNum(baseInfo.orderTotalAmount)}</td>
                                    </tr>
                                    <tr className="table-footer">
                                        {baseInfo.orderPreferentialAmount && +baseInfo.orderPreferentialAmount > 0 ? (
                                            <>
                                                <td>优惠金额</td>
                                                <td>{switchNum(baseInfo.orderPreferentialAmount)}</td>
                                                <td>实际金额</td>
                                                <td>{switchNum(baseInfo.orderActualAmount)}</td>
                                                <td>大写</td>
                                                <td colSpan={3}>{numberParseChina(baseInfo.orderActualAmount)}</td>
                                            </>
                                        ) : (
                                            <>
                                                <td>实际金额</td>
                                                <td>{switchNum(baseInfo.orderActualAmount)}</td>
                                                <td>大写</td>
                                                <td colSpan={5}>{numberParseChina(baseInfo.orderActualAmount)}</td>
                                            </>
                                        )}
                                        <td>收款人</td>
                                        <td></td>
                                    </tr>
                                    <tr className="table-footer">
                                        {/* <td>未结金额</td>
                                <td>{0.00}</td> */}

                                        <td>客户签字</td>
                                        <td colSpan={12}></td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                    <div className="print-footer" style={{ fontSize: "18px", marginTop: '10px', fontWeight: 'bold',width:'1060px'}}>
                        <div style={{ textAlign: "left", width: '30%' }}>
                            制单员：
                            {/* <span>{getUserInfo() ? getUserInfo().nickname : "未获取到"}</span> */}
                            <span>{baseInfo.billName??""}</span>
                        </div>
                        <div style={{ textAlign: "left", width: '40%' }}>
                            备注：<span>{baseInfo.remark}</span>
                        </div>
                        <div style={{ textAlign: "right", width: '30%' }}>
                            打印日期:
                            <span>{moment().format("YYYY-MM-DD HH:mm")}</span>
                        </div>
                    </div>
                </div>
            </div>
            {setVisible && <PrintOrderSet visible={setVisible} setVisible={setSetVisible} orderType={orderType} titles={titles} onSuccess={hanldeSet} />}
        </Modal>
    )
}

export default ParintMadal
