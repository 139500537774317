import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import { MyContext } from "@/store/myContext"
import Detail from "./detail"
import { paymentRefundList, paymentRefundSync } from "@/service/ordersService"
import TopSearch from "@/components/TopSearch"
import { Select } from "antd"
const { Option } = Select
const StatusObj = {
    unpaid: "未支付",
    paid: "已支付",
    refunding: "退款中",
    wx_refunding: "微信退款中",
    refunded: "已退款",
    refund_reject: "退款驳回",
}
//访问列表
export default () => {
    const { state, message } = useContext(MyContext)
    const [showVisible, setShowVisible] = useState(false)
    const [showMode, setShowMode] = useState("")
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (state.activeMenu === "refundedOrder") {
            loadData(pagination.current)
        }
    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    //列表数据
    const loadData = (current, search = {}) => {
        setLoading(true)
        let data = {
            searchItem: { disableFlag: 0, ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        paymentRefundList(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
        }).finally(res => {
            setLoading(false)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    const refundSync = () => {
        if (!selectInfo.rowInfo.id) {
            message.warning("请选择你要同步的工单")
            return
        }
        const {orderId,id: orderPaymentRefundId} = selectInfo.rowInfo
        paymentRefundSync({orderId,orderPaymentRefundId}).then(res => {
            message.success("同步成功")
            loadData(pagination.current)
        })
    }
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => refundSync (),
            content: "同步",
        },
    ]

    //新增
    const handleDetails = async (mode) => {
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的工单")
                return
            }
        }
        setShowMode(mode)
        setShowVisible(true)
    }

    //table配置
    const columns = [
        {
            title: "工单编号",
            align: "center",
            dataIndex: "orderCode",
        },
        {
            title: "退款单号",
            align: "center",
            dataIndex: "code",
        },
        {
            title: "退款金额",
            align: "center",
            dataIndex: "refundAmount",
        },
        {
            title: "服务类型",
            align: "center",
            dataIndex: "link",
            render: (text) => "取消工单",
        },
        {
            title: "申请时间",
            align: "center",
            dataIndex: "applicantTime",
        },
        {
            title: "状态",
            align: "center",
            dataIndex: "status",
            render: (text) => StatusObj[text],
        },
    ]
    // 搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入工单编号",
                valueName: "orderCode",
            },
            {
                type: "select",
                placeholder: "状态",
                valueName: "status",
                optionList: () =>
                    Object.entries(StatusObj).map((item) => (
                        <Option value={item[0]} key={item[0]}>
                            {item[1]}
                        </Option>
                    )),
            },
            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                span: 16,
                xxl: 8,
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "refundedOrder" && (
                <>
                    <TopSearch
                        title="退款工单"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="退款工单"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        scroll={{ x: "max-content" }}
                        loading={loading}
                    />

                    {showVisible && (
                        <Detail
                            rowInfo={selectInfo.rowInfo}
                            StatusObj={StatusObj}
                            visible={showVisible}
                            mode={showMode}
                            setVisible={setShowVisible}
                            onSuccess={() => {
                                loadData(1, searchValue)
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}
