import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import TopSearch from "@/components/TopSearch"
import { MyContext } from "@/store/myContext"
import { getPointList, pointSwitch } from "@/service/pointService.js"
import EditPointDrawer from "./editPointDrawer" //修改积分弹框
import UserPointDrawer from "./userPointDrawer" //用户积分消费弹框

//套餐模块
export default () => {
    const { state, message, Switch, Option } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [searchValue, setSearchValue] = useState({})
    const [selectPoint, setSelectPoint] = useState([]) //已选中的用户

    useEffect(() => {
        if (state.activeMenu === "pointManage") {
            loadData(pagination.current)
        }
        return () => setSelectPoint([])
    }, [state.activeMenu])

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        getPointList(data)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                    setpagination({
                        current: current,
                        total: res.retData.total,
                        pageSize: 10,
                    })
                    setList(res.retData.list)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }

    //启用，禁用
    const pointSweitchChange = (checked, e, id) => {
        e.stopPropagation()
        pointSwitch(id, checked ? 0 : 1)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    message.success("操作成功")
                    loadData(1)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }
    //table配置
    const columns = [
        {
            title: "用户昵称",
            align: "center",
            dataIndex: "userName",
        },
        {
            title: "手机号",
            align: "center",
            dataIndex: "userPhone",
        },
        {
            title: "消费金额",
            align: "center",
            dataIndex: "amountSpent",
        },
        {
            title: "积分总数",
            align: "center",
            dataIndex: "totalPoints",
        },
        {
            title: "已用积分",
            align: "center",
            dataIndex: "usedPoints",
        },
        {
            title: "剩余积分",
            align: "center",
            dataIndex: "remainingPoints",
        },
        {
            title: "积分状态",
            align: "center",
            dataIndex: "operational",
            render: (text) => (+text ? "冻结" : "正常"),
        },
        {
            title: "操作",
            align: "center",
            width: "12%",
            dataIndex: "operational",
            render: (text, record) => (
                <Switch size="small" checked={text === 0 ? true : false} onChange={(checked, e) => pointSweitchChange(checked, e, record.id)} />
            ),
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入用户昵称",
                valueName: "userName",
            },
            {
                type: "input",
                placeholder: "请输入手机号",
                valueName: "userPhone",
            },
            {
                type: "select",
                placeholder: "请选择积分状态",
                valueName: "operational",
                optionList: () => (
                    <>
                        <Option value={0} key={0}>
                            正常
                        </Option>
                        <Option value={1} key={1}>
                            冻结
                        </Option>
                    </>
                ),
            },
        ],
    }
    //新增多选
    const rowSelection = {
        selectedRowKeys: selectPoint.reduce((c, n) => {
            c.push(n.id)
            return c
        }, []), //是否选中
        onSelectAll: (selected, selectedRows, changeRows) => {
            //当为全部选中时
            if (selected) {
                let arr = [...selectPoint, ...changeRows]
                setSelectPoint(arr)
            } else {
                let changeRowId = changeRows.map((item) => item.id)
                let arr = selectPoint.filter((item) => changeRowId.indexOf(item.id) < 0)
                setSelectPoint(arr)
            }
        },
        onSelect: (record, selected, selectedRows) => {
            // 当为选中的时候
            if (selected) {
                let arr = [...selectPoint, record]
                setSelectPoint(arr)
            } else {
                let newArr = selectPoint.filter((item) => item.id !== record.id)
                setSelectPoint(newArr)
            }
        },
    }
    const [editPointInfo, setEditPointInfo] = useState({
        visible: false,
        userPointList: [],
    })
    const [userDetailInfo, setUserDetailsInfo] = useState({
        visible: false,
        rowInfo: {},
    })
    //列表button
    const topButtons = [
        {
            click: () => {
                if (!selectInfo.rowInfo.id) {
                    message.warning("请选择你要查看的用户")
                    return
                }
                setUserDetailsInfo({
                    visible: true,
                    rowInfo: selectInfo.rowInfo,
                })
            },
            content: "查看详情",
        },
        {
            click: () => {
                if (!selectPoint.length) {
                    message.warning("请勾选您要修改的用户")
                    return
                }
                setEditPointInfo({ visible: true, userPointList: selectPoint })
            },
            content: "修改积分",
        },
    ]

    return (
        <>
            {state.activeMenu === "pointManage" && (
                <>
                    <TopSearch
                        title="积分管理"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="用户积分列表"
                        buttons={topButtons}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        rowSelection={rowSelection}
                    />
                    <EditPointDrawer
                        visible={editPointInfo.visible}
                        onClose={() =>
                            setEditPointInfo({
                                visible: false,
                                userPointList: [],
                            })
                        }
                        userPointList={editPointInfo.userPointList}
                        pointLoadData={loadData}
                        setSelectPoint={() => setSelectPoint([])}
                    />
                    <UserPointDrawer
                        visible={userDetailInfo.visible}
                        onClose={() => setUserDetailsInfo({ visible: false, rowInfo: {} })}
                        rowInfo={userDetailInfo.rowInfo}
                        pointSweitchChange={pointSweitchChange}
                    />
                </>
            )}
        </>
    )
}
