import React, { useState, useEffect } from 'react'

// import { YhOfferPrint } from "@/erp_subpackage/components/YhOfferPrint/offerPrint"
import { numberParseChina, repairFormatNum, fmoney } from '@/erp_subpackage/utils/util';
import { useDictModel } from '@/erp_subpackage/pages/base/dict/dictModel';
import { SettlePrint } from "@/erp_subpackage/components/settlePrint"
import { returnOrderPrintOne, returnOrderPrintNum } from "@/service/ordersService"
import moment from 'moment';
export function ReturnOrderPrint(props) {
    const { loadData, visible, setPrint, selectedRowKeys } = props
    const [formVal, setFormVal] = useState({});
    const [maintenanceList, setMaintenanceList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const { getTreeNodeName } = useDictModel();

    useEffect(() => {
        console.log(props)
         getDetailOne()
    }, [visible])
    const getDetailOne = async () => {
        console.log(selectedRowKeys[0])
        let { retData } = await returnOrderPrintOne(selectedRowKeys[0]);
        let { returnItemList, returnMaintainMaterialList, orderInfo, enterTime,returnOrderInfo, ...from } = retData
        // receivableTotalAmount = repairFormatNum(receivableTotalAmount)
        const newItemDetails = returnItemList?.map((item, index) => {
            return {
                ...item,
                No: index + 1
            }
        })
        const newMaterialDetails = returnMaintainMaterialList?.map((item, index) => {
            return {
                ...item,
                No: index + 1
            }
        })
        if (retData) {
            setMaintenanceList(newMaterialDetails ?? []);
            setItemList(newItemDetails?? []);
            // setFormVal({ ...from, receivableTotalAmount, receivableTotalAmountCN: numberParseChina(receivableTotalAmount), enterTime: enterTime })
            setFormVal({ ...from,...orderInfo, enterTime,...returnOrderInfo, })
        }
    }
    const printNum = async () => {
        await returnOrderPrintNum(selectedRowKeys[0]);
        await loadData();
    }
    const onClose = () => {
        setPrint(false)
    }
    const itemClumns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '项目编号',
            dataIndex: 'itemCode',
        },
        {
            title: '项目名称',
            dataIndex: 'itemName',
        },
        {
            title: '工时数',
            dataIndex: 'itemWorkHours',
        },
        {
            title: '工时单价',
            dataIndex: 'workHoursPrice',
            render: (text) => fmoney(text, 2)
        },
        {
            title: '折扣',
            dataIndex: 'itemDiscount',
            render: (text) => text? text+ "%":"100%"
        },
        {
            title: '优惠',
            dataIndex: 'preferentialAmount',
            render: (text) => fmoney(text, 2)
        },
        {
            title: '工时费',
            dataIndex: 'actualAmount',
            render: (text) => fmoney(text, 2)
        },
        {
            title: '备注',
            dataIndex: 'remark',
            render: (text) => <span style={{width:'150px'}}>{text}</span>
        },
    ]
    const columns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '商品编号',
            dataIndex: 'materialCode',
        },
        {
            title: '商品名称',
            dataIndex: 'materialName',
        },
        {
            title: '单位',
            dataIndex: 'unitName',
        },
        {
            title: '数量',
            dataIndex: 'materialNumber',
        },
        {
            title: '单价',
            dataIndex: 'unitPrice',
            render: (text) => fmoney(text, 2)
        },
        {
            title: '折扣',
            dataIndex: 'materialDiscount',
            render: (text) => text? text+ "%":"100%"
        },
        {
            title: '金额',
            dataIndex: 'actualAmount',
            render: (text) => fmoney(text, 2)
        },
        {
            title: '备注',
            dataIndex: 'remark',
            render: (text) => <span style={{width:'150px'}}>{text}</span>
        },
    ]
    let IHeader = [
        {
            fieldLabel: "退单编号:",
            fieldValue: formVal.returnCode,
            className: "w35"
            // fieldValue: `${formVal.returnCode}(${getTreeNodeName(formVal?.status)})`
        },
        {
            fieldLabel: "单据编号:",
            fieldValue: formVal.orderCode,
            className: "w40"
        },
         {
            fieldLabel: "结算类型:",
            fieldValue: getTreeNodeName(formVal.settlementType),
            className: "w25"
        },
        {
            fieldLabel: "结算方:",
            fieldValue: formVal.settlementParty, //getTreeNodeName(formVal.payType as string)
            className: "w75"
        }, {
            fieldLabel: "打印日期:",
            fieldValue: moment(new Date()).format("YYYY-MM-DD HH:mm"),
            className: "w25"
        },
    ]
    return (
        <SettlePrint
            title={`云豪车服（科技）服务有限公司修理结算单`}
            visible={visible}
            onClose={onClose}
            printNum={printNum}
            columns={columns}
            itemClumns={itemClumns}
            itemDataSource={itemList}
            dataSource={maintenanceList.filter(item => item.num !== 0)}
            printHeader={formVal}
            printFooter={formVal}
            IHeader={IHeader}
        />

    )
}