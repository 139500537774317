import React from "react";
import { Select } from "antd";

export const SearchSelect = (props) => {
    return <Select
        size={props.size}
        allowClear
        mode={props.mode}
        showSearch={props.showSearch}
        className="yh-search-select"
        value={props.searchValues?.[props.fieldName]}
        placeholder={props.placeholder}
        onChange={(value) => {
            props.onChange && props.onChange({ [props.fieldName]: value })
            if (props.triggerSearch) {
                props.handleSearch && props.handleSearch({ [props.fieldName]: value });
            }
        }}
        disabled={props.disabled}
        options={props.options ?? []}
    />
}