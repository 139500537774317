import React from "react";
import {YhBox} from "../YhBox";
import {Row} from "antd";
import {__FormItem as FormItem} from "./item";


export const __FormItemsBox = ({items, ...props}) => {
    return <YhBox {...props}>
        <Row
            // gutter={[20, 20]}
            justify="start"
        >
            {items.map((el, index) => <FormItem key={index} {...el} disable={el.disable ?? props.disabled} copyText={(fieldName, option) => props?.copyText(fieldName, option)} />)}
        </Row>
    </YhBox>
}
