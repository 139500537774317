import React, { useRef } from "react"
import { TopButs } from "./TopButs";
import { FormBox } from "./FormBox";
import { Item } from "./Item";
import { Material } from "./Material";
import { FixedBottom } from "./FixedBottom";
import { ConvertModel } from "../convert/index";
import { usePrebookModel } from "../prebookModel";
import SanBaoList from './SanbaoList'

export default (props) => {

    const { mode } = props;
    const { getOrderType } = usePrebookModel();

    const formRef = useRef()
    return (
        <>
            <TopButs formRef={formRef} {...props} />
            <FormBox formRef={formRef} {...props} />
            {getOrderType(mode) != '014002' && (
                <>
                    <Item formRef={formRef} {...props} />
                    <Material formRef={formRef} {...props} />
                </>
            )}
            {getOrderType(mode) == '014002' && (
                <SanBaoList formRef={formRef} {...props}></SanBaoList>
            )}
            <FixedBottom formRef={formRef} {...props} />
            <ConvertModel mode={"edit"} rowInfo={props && props.rowInfo} />
        </>
    )
}
