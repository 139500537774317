import React, { useContext } from "react";
import { Button, message, Modal } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useSaleOrderModel } from "../saleOrderModel";
import { formatNum } from "@/erp_subpackage/utils/util";
import { MyContext } from "@/store/myContext"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"

export const DataBox = () => {
    const { selectedRow, descTotal, rowsDescTotal, searchParam, setSettleScaniaPrint, setPrint, detailsExport, onInvalidate, exportSaleOrder } = useSaleOrderModel();
    const { state, dispatch, detailsPage } = useContext(MyContext)
    const desc = [
        {
            label: "订单数量",
            value: selectedRow.length === 0 ? descTotal.totalNum : (+formatNum(rowsDescTotal.totalNum)),
            color: 'red'
        },
        {
            label: "订单总金额",
            value: selectedRow.length === 0 ? formatNum(descTotal.totalAmount) : formatNum(rowsDescTotal.totalAmount),
            color: 'red'
        }
    ]

    //新增 查看 编辑
    const goPageNav = (mode) => {
        let tabName = {
            add: "销售订单新增",
            edit: "销售订单编辑",
            look: "销售订单查看",
        }
        let modeKey = "saleOrder/" + mode

        //动态传入要删除的nav key
        function handleDispatch(obj = {}) {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.SaleOrderDetails({
                    dispatch,
                    modeKey,
                    mode,
                    rowId: selectedRow[0]?.saleOrderCode,
                }),
            })
        }

        if (mode === "edit") {
            const findNav = state.panes.find((item) => item.key === modeKey)
            if (findNav) {
                Modal.confirm({
                    title: "温馨提醒！",
                    content: "您还有销售订单详情未保存，是否跳转",
                    okText: "跳转未保存页",
                    cancelText: "打开新页签",
                    onOk: () => {
                        handleDispatch()
                    },
                    //打开新页签重新生成
                    onCancel: () => {
                        handleDispatch({ deleteKey: modeKey })
                    },
                })
                return
            }
            handleDispatch()
        } else if (mode === "add") {
            handleDispatch()
        } else if (mode === "look") {
            handleDispatch({ deleteKey: modeKey })
        }
    }
    const onSettleSknyPrint = () => {
        if (selectedRow.length !== 1) {
            message.warning('请选择单条数据进行编辑，点击鼠标右键可一键选中哦! ')
            return;
        }
        setSettleScaniaPrint(true)
    }
    const onPrint = () => {
        if (selectedRow.length !== 1) {
            message.warning('请选择单条数据进行编辑，点击鼠标右键可一键选中哦! ')
            return;
        }
        setPrint(true)
    }

    const cancellation = () => {
        Modal.confirm({
            title: "销售订单作废",
            content: <span>是否确认作废</span>,
            okText: "确定",
            onOk: () => {
                onInvalidate()
            },
            cancelText: "取消",
            onCancel: () => { },
        })
    }

    const action = <>
        <Button
            className="btn-item"
            type="link"
            shape="round"
            onClick={() => goPageNav('add')}
        >新增</Button>
        <Button
            className="btn-item"
            type="link"
            shape="round"
            disabled={searchParam.shopId}
            onClick={() => {
                if (selectedRow.length !== 1) {
                    message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！")
                    return
                }
                if (selectedRow[0].status === DictCodeEnmu.SALE_ORDER_CHANGE) {
                    message.warning("该单据已作废,无法进行编辑")
                    return;
                }
                if (selectedRow[0].status === DictCodeEnmu.SALE_ORDER_CANCEL) {
                    message.warning("该单据已取消,无法进行编辑")
                    return;
                }
                if (selectedRow[0].status === DictCodeEnmu.SALE_ORDER_TRANSFER) {
                    message.warning("该单据已转接,无法进行编辑")
                    return;
                }
                goPageNav("edit")
            }}
        >编辑</Button>
        <Button
            className="btn-item"
            type="link"
            shape="round"
            onClick={() => {
                if (selectedRow.length !== 1) {
                    message.warning("请选择单条数据进行查看，点击鼠标右键可一键选中哦！")
                    return
                }
                goPageNav("look")
            }}
        >查看</Button>
        <Button
            className="btn-item"
            type="link"
            shape="round"
            disabled={searchParam.shopId}
            onClick={() => {
                if (selectedRow.length !== 1) {
                    message.warning("请选择单条数据进行作废，点击鼠标右键可一键选中哦！")
                    return;
                }
                if (selectedRow[0].status != DictCodeEnmu.SALE_ORDER_CREATE) {
                    message.warning("该状态下不可进行作废")
                    return;
                }
                cancellation()
            }}
        >作废</Button>
        <Button
            className="btn-item"
            type="link"
            shape="round"
            onClick={() => exportSaleOrder()}
        >导出</Button>
        {/* <Button
            className="btn-item"
            type="link"
            shape="round"
            onClick={() => detailsExport()}
        >导出明细</Button> */}
        <Button
            className="btn-item"
            type="link"
            shape="round"
            onClick={onPrint}
        >打印</Button>
        {/* <Button
                className="btn-item"
                type="link"
                shape="round"
                onClick={onSettleSknyPrint}
            >财务打印</Button> */}
    </>;

    return (
        <YhBox title={"销售订单列表"} descriptions={desc} action={action}>
            <DataTable />
        </YhBox>
    )
}