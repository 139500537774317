import React, { useState, useEffect } from "react";
import { Table, Button, Input, Tooltip } from "antd";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { useSaleDcDetailsModel } from "./saleDcDetailsModel";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { YhBut } from "@/erp_subpackage/components/YhButton";
import { formatNum } from "@/erp_subpackage/utils/util";
import { EditableRow, EditableCell } from "@/erp_subpackage/components/YHEditTable/editTable";
import { saleDcService } from "../../saleDcService";
import { debounce } from "lodash"
import YhTooltip from "@/erp_subpackage/components/YhTooltip"


export const AddGoodsModal = ({ id, mode }) => {
    const { user: { shopId }, setMaterialInfo, orgDs } = useGlobalModel();
    const {
        addGoodsModal: visible, setAddGoodsModal, add, remove, transformsToSDcGoods,
        editSelectGoods, setEditSelectGoods,
        addSelectGoods, setAddSelectGoods,
        addGoodsList,
        editGoodsList,
        addFormVal, editFormVal
    } = useSaleDcDetailsModel();
    const formVal = mode === "add" ? addFormVal : editFormVal
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    //列表数据源分页参数
    const initPgCfg = { pageSize: 10, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg);

    const loadData = async (page) => {
        let param = {...searchParam,materialName:searchParam.materialName?.trim(),materialCode:searchParam.materialCode?.trim()}
        setSearchParam(param)
        const searchParams = {
            shopId,
            ...param,
            age: 1,
            saleTypeCode: "sale-type.general",  //选常规商品
            pageSize: page?.pageSize ?? pgCfg.pageSize,
            pageNum: (page?.current || page?.pageNum) ?? pgCfg.current,

        }
        let { retData } = await saleDcService.ciTransferDetails(searchParams);
        const { records, ...pg } = retData;
        //这里判断 调入或者调出机构任何一方为加盟性质的  价格取加盟商价格
        // debugger
        let findShop = orgDs.find(item => item.value === formVal.shopId) || {}
        let findOutShop = orgDs.find(item => item.value === formVal.outShopId) || {};
        setPgCfg(transPgToPagination(pg));
        let newRecords = records.map(item => {
            return {
                ...item,
                // price: (findOutShop.organ_nature === 3 || findShop.organ_nature === 3) ? item.franchiseePrice : item.price,
                inventoryQuantity: item.inventoryQuantity
            }
        })
        setDataSource(newRecords);
    }

    useEffect(() => {
        if (visible) {
            loadData();
        }
    }, [visible])

    const onCancel = () => {
        setAddGoodsModal(false);
    };

    const column = (optype) => [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            align: "center",
            render: (t, r, i) => <>{i + 1}</>
        }, {
            title: '商品编号',
            width: 160,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
           // render: (text, record, index) => <Tooltip placement="topLeft" title={text}><p className="one-line click-span" onClick={(e) => { e.stopPropagation(); setMaterialInfo(true, { materialCode: text, materialName: record.name }) }}>{text}</p></Tooltip>,
        }, {
            title: '商品名称',
            width: 180,
            dataIndex: 'materialName',
            fixed: "left",
            //render: (text) => <p className="ellipsis" title={text}>{text}</p>
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '商品类型',
            width: 180,
            dataIndex: 'typeName',
        }, {
            title: '采购单价',
            width: 180,
            dataIndex: 'latestPurPrice',
        }, {
            title: '库存',
            width: 120,
            dataIndex: 'inventoryQuantity', //这里取采购数量的值判断
        },
        {
            title: '单位',
            width: 100,
            dataIndex: 'unitName',
        }, {
            title: '采购单号',
            width: 150,
            dataIndex: 'purchaseCode',
        },{
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseName', //这里取采购数量的值判断
        }, {
            title: '货位',
            width: 180,
            dataIndex: 'shelfCode',
        }, {
            title: '操作',
            width: 80,
            fixed: "right",
            align: "center",
            className: "td-wrap",
            render: (text, record) => optype === 'add' ?
                <YhBut
                    type="add"
                    txt="添加"
                    click={() => add(record, mode)}
                    disabled={
                        !!(mode === "edit" ? editSelectGoods : addSelectGoods).find(item => item.id === record.id)
                        || !!(mode === "edit" ? editGoodsList : addGoodsList).find(item => item.key === record.id)
                        || +record.inventoryQuantity <= 0}
                />
                :
                <YhBut
                    type="delete"
                    txt="删除"
                    click={() => remove(record, mode)}
                />
        }
    ];

    const newSelectColumns = column('remove').map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index,
                rowIndex,
                record,
                rules: col.rules,
                options: col.options,
                editType: col.editType,
                editable: col?.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑
    const handleSave = (row) => {
        row.sDcNum = +formatNum(row.sDcNum);
        let dataSource = mode === "edit" ? editSelectGoods : addSelectGoods;
        const index = dataSource.findIndex((item) => row.id === item.id);
        const item = dataSource[index];
        dataSource.splice(index, 1, { ...item, ...row });
        mode === "edit" ? setEditSelectGoods([...dataSource]) : setAddSelectGoods([...dataSource]);
    }

    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-5"
            placeholder="商品编号"
            value={searchParam?.materialCode}
            onChange={(e) => setSearchParam({ ...searchParam, materialCode: e.target.value })}
            onPressEnter={() => loadData(initPgCfg)}
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-5"
            placeholder="商品名称"
            value={searchParam?.materialName}
            onChange={(e) => setSearchParam({ ...searchParam, materialName: e.target.value })}
            onPressEnter={() => loadData(initPgCfg)}
        />
        <Button style={{ margin: "0px 12px 0px 15px" }} shape="round" onClick={() => loadData(initPgCfg)}>检索</Button>
    </div>

    return <YHModal
        visible={visible}
        onOk={debounce(() => transformsToSDcGoods(mode === "edit" ? editSelectGoods : addSelectGoods, mode), 500)}
        onCancel={onCancel}
        title="调出单-选择商品"
        width={1200}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title="待选商品列表" rightChildren={rightChildren} style={{ marginBottom: "5px", paddingBottom: 0 }}>
            <Table
                rowClassName={(record) => {
                    if (record.inventoryQuantity === 0) {
                        return "editable-row yh-edit-table-tr stock-td"
                    } else {
                        return "editable-row yh-edit-table-tr"
                    }
                }}
                rowKey={record => record.id}
                columns={column('add')}
                dataSource={dataSource}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize })
                    }
                }}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
        <YhBox title="已选商品列表">
            <Table
                rowKey={record => record.id}
                rowClassName='editable-row yh-edit-table-tr'
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns}
                dataSource={mode === "edit" ? editSelectGoods : addSelectGoods}
                pagination={false}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
    </YHModal>
}