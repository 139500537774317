import React, { useEffect, useImperativeHandle } from "react"
import { Input, Form, Col, Row, InputNumber } from "antd"
import styled from "@emotion/styled"

export default (props) => {
    const { basicInfoRef, setStartMileage, setAgreementNumber, plan, setEarlyWarningMileage } = props
    const [form] = Form.useForm()

    useEffect(() => {
        if (plan && plan.vehicleInfo) {
            form.setFieldsValue({
                ...plan.vehicleInfo,
                agreementNumber: plan?.planInfo?.agreementNumber,
                earlyWarningMileage: plan?.planInfo?.earlyWarningMileage,
            })
        }
    }, [plan])


    //暴露给父组件的方法
    useImperativeHandle(basicInfoRef, () => ({
        //父组件保存的时候 获取所有编辑完后的数据
        upData: () => {
            return form
        },
    }))

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    return (
        <Box>
            <Form form={form} {...formItemLayout}>
                <div className="type">{plan && plan.vehicleInfo && plan.vehicleInfo.vehicleCode}</div>
                <Row gutter={20}>
                    <Col span={6}>
                        <Form.Item label="客户名称" name="customerName">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="协议编号" name="agreementNumber" rules={[{ required: true, message: "请输入协议编号" }]}>
                            <Input
                                onBlur={(e) => {
                                    setAgreementNumber(e.target.value)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车辆识别代码" name="vehicleVin">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车辆型号" name="vehicleModelId">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="发动机型号" name="engineCode">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="前桥型号" name="firstAxle">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="总成编号" name="firstAxleAssemblyNumber">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="变速箱型号" name="gearboxModel">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="变速箱总成号" name="gearboxOrderNumber">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="变速箱客户编号" name="gearboxNumber">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="中桥型号" name="secondAxle">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="中桥速比" name="secondAxleSpeedRatio">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="中桥总成编号" name="secondAxleAssemblyNumber">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="后桥型号" name="thirdAxle">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="后桥速比" name="thirdAxleSpeedRatio">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="后桥总成编号" name="thirdAxleAssemblyNumber">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="平衡轴型号" name="balanceShaftModel">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="平衡轴总成号" name="balanceShaftAssemblyNumber">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="行驶证注册日期" name="drivingSignDate">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="司机姓名" name="driverName">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="联系电话" name="driverPhone">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="开始里程" name="bigBagSignContractMileage" rules={[{ required: true, message: "请输入开始里程" }]}>
                            <InputNumber
                                placeholder="开始里程"
                                style={{ width: "100%" }}
                                addonAfter="万公里"
                                precision={0}
                                disabled
                                onBlur={(e) => {
                                    setStartMileage(Number(e.target.value))
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="预警里程" name="earlyWarningMileage">
                            <InputNumber
                                placeholder="预警里程"
                                style={{ width: "100%" }}
                                addonAfter="公里"
                                precision={1}
                                onBlur={(e) => {
                                    setEarlyWarningMileage(Number(e.target.value))
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Box>
    )
}

const Box = styled.div`
    width: 100%;
    overflow: hidden;
    border: 1px solid #f0f0f0;
    padding: 10px;
    margin: 20px 0;
    .type {
        font-size: 16px;
        font-weight: 500;
        height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
        color: red;
    }
`
const Lablediv = styled.div`
    text-align: center;
    margin: 10px;
`
