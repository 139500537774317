import React, { useContext, useState } from "react"
import { Button, message, Modal, Upload, Spin, Menu, Dropdown, Input } from "antd"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { useCustomerModel } from "../customerModel"
import { DataTable } from "./DataTable"
import { MyContext } from "@/store/myContext"
import { importPropsConfig } from "@/erp_subpackage/utils/importFile";
import useGlobalModel from "@/erp_subpackage/model/globalModel"
import moment from 'moment';

export const DataBox = () => {
    const { dataSource, selectedRowKeys, searchParam, exportCustomer, sync, downloadTpl, uploading, setUploading, loadData } = useCustomerModel()
    const { state, dispatch, detailsPage, tabs } = useContext(MyContext)
    const { user: { shopId, shopName } } = useGlobalModel()
    //新增 查看 编辑
    const goPageNav = (mode) => {
        let tabName = {
            add: "客户新增",
            edit: "客户编辑",
            look: "客户查看",
        }
        let modeKey = "baseCustomer/" + mode

        //动态传入要删除的nav key
        function handleDispatch(obj = {}) {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.BaseCustomerDetails({
                    dispatch,
                    modeKey,
                    mode,
                    rowId: selectedRowKeys[0],
                }),
            })
        }

        if (mode === "edit") {
            const findNav = state.panes.find((item) => item.key === modeKey)
            if (findNav) {
                Modal.confirm({
                    title: "温馨提醒！",
                    content: "您还有客户详情未保存，是否跳转",
                    okText: "跳转未保存页",
                    cancelText: "打开新页签",
                    onOk: () => {
                        handleDispatch()
                    },
                    //打开新页签重新生成
                    onCancel: () => {
                        handleDispatch({ deleteKey: modeKey })
                    },
                })
                return
            }
            handleDispatch()
        } else if (mode === "add") {
            handleDispatch()
        } else if (mode === "look") {
            handleDispatch({ deleteKey: modeKey })
        }
    }

    // 下属车辆
    const goVehicle = () => {
        if (selectedRowKeys.length !== 1) {
            message.warning('请选择单条数据进行车辆')
            return;
        }
        const modeKey = "baseVehicle"
        dispatch({
            change: true,
            type: "changeTabs",
            name: "车辆管理",
            activeMenu: modeKey,
            targetKey: modeKey,
            content: tabs.baseVehicle({
                customerName: dataSource.find(el => el.id === selectedRowKeys[0])?.name
            }),
        })
    }
    const uploadCallback = (params) => {
        setUploading({ spinning: false })
        params && loadData()
    }

    const setTitle = () => {
        let name = "";
        Modal.confirm({
            title: '同步客户',
            content: <>
                <Input placeholder="请输入要同步的客户名称" onChange={(e) => { name = e.target.value }} />
            </>,
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    if (name) {
                        resolve(name);
                    } else {
                        message.warning("请输入要同步的客户名称");
                        reject('请输入要同步的客户名称');
                    }
                }).then(res => {
                    sync({ name: res });
                });
            },
            onCancel: () => { }
        })
    };

    const menu = (
        <Menu>
            <Menu.Item onClick={() => sync()} key="all">
                <span style={{ color: "#00f" }}>同步所有</span>
            </Menu.Item>
            <Menu.Item onClick={setTitle} key="customer">
                <span style={{ color: "#00f" }}>同步客户</span>
            </Menu.Item>
        </Menu>
    )

    const action = (
        <>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    goPageNav("add")
                }}
            >新增</Button>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    if (selectedRowKeys.length !== 1) {
                        message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！")
                        return
                    }
                    goPageNav("edit")
                }}
                disabled={searchParam.shopId}
            >编辑</Button>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    if (selectedRowKeys.length !== 1) {
                        message.warning("请选择单条数据进行查看，点击鼠标右键可一键选中哦！")
                        return
                    }
                    goPageNav("look")
                }}
            >查看</Button>
            <Upload showUploadList={false}  {...importPropsConfig(`/ci-storage/base/customer/import`, uploadCallback, { shopId, shopName }, () => setUploading({ spinning: true, tip: "加速上传中..." }))}>
                <Button className="btn-item" type="link" shape="round" disabled={searchParam.shopId}>导入</Button>
            </Upload>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => exportCustomer()}
            >
                导出
            </Button>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => downloadTpl()}
                disabled={searchParam.shopId}
            >
                下载模板
            </Button>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    goVehicle()
                }}
            >
                下属车辆
            </Button>
            {/* <Button
                className="btn-item"
                shape="round"
                onClick={() => sync()}
            >
                同步
            </Button> */}
            <Button
                className="btn-item"
                shape="round"
                onClick={() => sync({ createTime: moment(new Date()).format("YYYY-MM-DD") })}
            >
                增量同步
            </Button>
            {/* <Dropdown overlay={menu} placement="bottom">
                <Button
                    className="btn-item"
                    shape="round"
                >
                    同步
                </Button>
            </Dropdown> */}
        </>
    )
    return (
        <Spin {...uploading}>
            <YhBox title="客户列表" action={action}>
                <DataTable />
            </YhBox>
        </Spin>
    )
}
