import React from "react";
import { usePsiModel } from "../psiModel";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";

export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = usePsiModel();

    const searchFields = [
        {
            type: "Input",
            placeholder: "商品编号",
            fieldName: "materialCode"
        },
        {
            type: "Input",
            placeholder: "商品名称",
            fieldName: "materialName"
        }
    ]
    return (
        <YhPageHeader
            title="进销存报表汇总"
            size="small"
            justify="end"
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}