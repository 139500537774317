import React, { useEffect, useState } from "react"
import { Form, Input, Col, Row, Select, Spin, message, Modal, Radio, DatePicker } from "antd"

export default (props) => {
    const { visible, setVisible, onSuccess, orderType, titles } = props
    const [form] = Form.useForm()
    useEffect(() => {
        if (orderType === "014003") {
            form.setFieldsValue({
                serviceNature: "016002"
            })
        }
        form.setFieldsValue({
            name: titles,
        })
    }, [])

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            onSuccess && onSuccess(values)
            setVisible(false)
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 16,
        },
    }

    const handleCancel = () => {
        setVisible(false)
    }

    return (
        <Modal forceRender visible={visible} title="打印设置" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={460}>
            <div className="form-details-warp">
                <Form form={form} {...formItemLayout} initialValues={{}}>
                    <Row>
                        {orderType === "014003" && (
                            <>
                                <Col span={24}>
                                    <Form.Item label="服务方式" name="serviceNature">
                                        <Radio.Group>
                                            <Radio value="016001">三包</Radio>
                                            <Radio value="016002">收费</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                        <Col span={24}>
                            <Form.Item label="所属实体" name="name">
                                <Input placeholder="请输入所属实体" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item hidden>
                        <Input />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}
