import React, { useState, useEffect } from 'react'
import { Badge, Upload, Button, Image, Form, Input, message, Modal } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { fileUploadUrl, fileLookUrl } from '@/config/app'
import { getToken } from '@/utils/dataStorage'
import FontIcon from '@/erp_subpackage/components/YhIcon'
import { UploadOutlined } from '@ant-design/icons'
import { getBaseImgUploadUrl , getBaseUploadData, getBaseImgLookUrl } from '@/config/app'

export const __FormUpload = ({ defaultFileName = '',lookBranch=false, defaultFileMd5 = '', onSetFieldsValue, maxNum = 1, isUsePortalApi = false, ...props }) => {
    const onPreview = (file) => {
        const { md5,name } = file
        const type = name.split('.')[1]
        if (['jpg', 'png', 'jpeg', 'gif'].includes(type)) {
            Modal.info({
                title: '图片查看',
                content: <img style={{ width: '100%' }} src={isUsePortalApi ? getBaseImgLookUrl(md5) : `${fileLookUrl().image}${md5}`} />,
                okText: '确定',
                cancelText: '取消',
                centered: true,
                width: 800,
            })
        } else {
            window.open(isUsePortalApi ? getBaseImgLookUrl(md5) : `${fileLookUrl().image}${md5}`)
        }
    }

    const [data, setdata] = useState([]) //商品缩略图 string[]

    useEffect(() => {
        if (defaultFileMd5) {
            const names = defaultFileName?.split(',') || []
            const md5s = defaultFileMd5?.split(',')
            const md5List = []
            md5s.forEach((item, index) => {
                md5List.push({
                    name: names[index],
                    md5: item,
                })
            })
            setdata(md5List)
        }
    }, [defaultFileMd5])

    useEffect(() => {
        let name = '',
            md5 = ''
        data.forEach((item) => {
            name += item.name
            md5 += item.md5
        })
        onSetFieldsValue && onSetFieldsValue({ [props.fieldName]: name, [props.fieldMd5]: md5 })
    }, [data])

    /*上传图片成功后的回掉 */
    const upLoadCallback = async (file) => {
        try {
            let md5 = file.response.retData
            let name = file.name
            setdata((origin) => [...origin, { md5, name }])
        } catch {}
    }

    return (
        <>
            <Form.Item name={props.fieldName} hidden>
                <Input />
            </Form.Item>
            <Form.Item name={props.fieldMd5} label={props.fieldLabel} rules={props.rules} labelCol={props.labelCol}>
                {data &&
                    data.map((item, index) => (
                        <div key={index} style={{ float: 'left', marginRight: '10px' }}>
                            {lookBranch?<>
                            <FontIcon
                                    onClick={() => {
                                        onPreview(item)
                                    }}
                                    style={{ fontSize: '48px' }}
                                    type="icon-tupian"
                                />
                                <span style={{marginLeft:'15px'}}>{item.name}</span>
                                </>:<Badge
                                count={
                                    props.disable ? null : (
                                        <CloseCircleOutlined
                                            style={{ color: '#f5222d' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                let originImg = data.filter((imgMd5) => imgMd5.md5 !== item.md5)
                                                setdata(originImg)
                                            }}
                                        />
                                    )
                                }
                            >

                                <FontIcon
                                    onClick={() => {
                                        onPreview(item)
                                    }}
                                    style={{ fontSize: '48px' }}
                                    type="icon-tupian"
                                />
                                <span style={{marginLeft:'15px'}}>{item.name}</span>
                            </Badge>}
                        </div>
                    ))}
                {!props.disable && (
                    <Upload
                        showUploadList={false}
                        beforeUpload={(file ) => {
                            // let fileEnd = file.name?.substring(file.name.lastIndexOf('.')) ?? ''
                            // let filetypes = ['.jpg', '.jpeg', '.png', '.gif', '.svg']
                            // let isType = filetypes?.includes(fileEnd)
                            // if (!isType) {
                            //     message.error('不支持该格式的上传')
                            //     return false
                            // }
                            // const isLt2M = file.size / 1024 / 1024 < 2
                            // if (!isLt2M) {
                            //     message.error('上传到文件不能超过2M')
                            //     return false
                            // }
                            // return true
                        }}
                        {...{
                            name: 'file',
                            action: isUsePortalApi ?  getBaseImgUploadUrl() : `${fileUploadUrl}upload/image_old`,
                            headers: { authorization: getToken() },
                            data: isUsePortalApi ? getBaseUploadData() : {},
                            timeout: 300000,
                            onChange(info) {
                                try {
                                    if (info.file.status === 'done' && info.file.response && info?.file?.response?.retData) {
                                        message.info({
                                            content: `导入成功`,
                                            duration: 10,
                                        })
                                        upLoadCallback(info.file)
                                    } else if (info.file.status === 'error' && info.file.response) {
                                        message.error(`${info.file.name}:${info.file.response.message}导入异常，请尝试重新导入`)
                                        upLoadCallback(undefined)
                                    } else if (info.file.status === 'error') {
                                        message.error('导入错误')
                                        upLoadCallback(undefined)
                                    } else if (!info.file.status) {
                                        upLoadCallback(undefined)
                                    }
                                } catch (error) {
                                    message.warning('导出异常，请重新尝试')
                                    upLoadCallback(undefined)
                                }
                            },
                        }}
                    >
                        {data.length < maxNum&&!lookBranch && (
                            <Button className="btn-item" shape="round" disabled={props.disable}>
                                上传
                            </Button>
                        )}
                    </Upload>
                )}
            </Form.Item>
        </>
    )
}
