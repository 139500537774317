import React, { useContext } from "react";
import { DataBox } from "./DataBox";
import {PageHeader} from './PageHeader'
import { MyContext } from "@/store/myContext";

export default () => {
    const { state } = useContext(MyContext);
    return (
        <>
            {state.activeMenu === "contractParticulars" && <>
            <PageHeader/>
            <DataBox />    
        </>}
        </>
    )
}