import React, { useState, useEffect, useContext } from "react"
import { Button, message, Form, Upload, Input, Row, Col, Image } from "antd"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"
import "./index.less"
import headimgCancel from "../../../assets/images/headimg-cancel.png"
import headimgUpload from "../../../assets/images/headimg-upload.png"
import { getUserInfo } from "@/utils/dataStorage"
import { editInfoSmsCode, editUserInfoInfo,getQrCode } from "@/service/settings"
import { removeUserInfo, rmUserOrgan, removeUserBtn, removeToken } from "@/utils/dataStorage"
import { MyContext } from "@/store/myContext"
import md5 from "js-md5"

export default () => {
    const { history } = useContext(MyContext)
    const [head, setHead] = useState("")
    const [form] = Form.useForm()
    const [isUpdate, setIsUpdate] = useState(false)
    const [codeBtnText, setCodeBtnText] = useState("获取验证码")
    const [qrCode, setQrCode] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        handleGetQrCode()
        const userInfo = getUserInfo()
        form.setFieldsValue({
            nickname: userInfo.nickname,
            phone: userInfo.phone,
            phoneVerifyCode: "",
            oldPwd: "",
            newPwd: "",
            confirmPwd: "",
        })
        setHead(userInfo.avatar)
        setQrCode(userInfo.businessPopularizeQrcode)
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    // 图片上传
    const handleImgChange = async (info) => {
        console.log(info)
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setHead(md5)
        }
    }

    // 取消图片上传
    const handleCacelSetHead = () => {
        const userInfo = getUserInfo()
        setHead(userInfo.avatar)
    }

    //去修改手机号
    const handleToChangePhone = () => {
        setIsUpdate(true)
        form.setFieldsValue({
            phone: "",
        })
    }

    //获取验证码
    const handleGetCode = () => {
        if (codeBtnText !== "获取验证码") {
            return
        }
        const phone = form.getFieldValue("phone")
        let reg = /^1\d{10}$/
        if (!reg.test(phone)) {
            return message.error("请输入正确的手机号码")
        }
        editInfoSmsCode({ phone }).then((res) => {
            let time = 60
            let codeInterval = setInterval(() => {
                if (time > 0) {
                    time--
                    setCodeBtnText(`${time}s`)
                } else {
                    clearInterval(codeInterval)
                    setCodeBtnText(`获取验证码`)
                }
            }, 1000)
        })
    }

    //取消
    const handleCancelUpdate = () => {
        const userInfo = getUserInfo()
        form.setFieldsValue({
            nickname: userInfo.nickname,
            phone: userInfo.phone,
            phoneVerifyCode: "",
            oldPwd: "",
            newPwd: "",
            confirmPwd: "",
        })
        setHead(userInfo.avatar)
        setIsUpdate(false)
        setCodeBtnText("获取验证码")
    }

    const handleUpdate = () => {
        form.validateFields().then((values) => {
            const userInfo = getUserInfo()
            let data = {
                ...values,
                id: userInfo.id,
                avatar: head,
                oldPwd: values.oldPwd ? md5(values.oldPwd) : null,
                newPwd: values.newPwd ? md5(values.newPwd) : null,
                confirmPwd: values.confirmPwd ? md5(values.confirmPwd) : null,
            }
            if (values.phone === userInfo.phone) {
                delete data.phone
            }
            editUserInfoInfo(data).then((res) => {
                message.success("修改成功")
                removeUserInfo()
                rmUserOrgan()
                removeUserBtn()
                removeToken()
                history.replace("/login")
            })
        })
    }

    //获取用户的推广二维码
    const handleGetQrCode = (refresh = false) => {
        setLoading(true)
        getQrCode({refresh}).then(({retData}) => {
            setQrCode(retData)
            setLoading(false)
            downloadFile()
        })
    }

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    }
    function downloadFile() { 
        const url = fileLookUrl().image + qrCode +'?response-content-disposition=attachment'; // 补充强制下载的参数 
        // 使用iframe下载 
        const elemIF = document.createElement("iframe"); 
        elemIF.src = url; 
        // elemIF.style.display = "none"; 
        document.body.appendChild(elemIF)
    }

    return (
        <div className="list-container ">
            <Form form={form} {...formItemLayout}>
                <div className="account-set-content">
                    <div className="set-headimg">
                        <img className="headimg" src={head ? fileLookUrl().image + head : headimgUpload}  alt="" />
                        <div className="headimg-btns">
                            <Upload action={`${fileUploadUrl}upload/image_old`} onChange={handleImgChange}>
                                <img className="headimg-u headimg-btn" src={headimgUpload} alt="" />
                            </Upload>
                            <img className="headimg-c headimg-btn" onClick={handleCacelSetHead} src={headimgCancel} alt="" />
                        </div>
                    </div>
                    <div className="set-main">
                        <div className="title">
                            <span></span>信息
                        </div>
                        <div className="set-comtent-main">
                            <Row style={{ marginBottom: "10px" }}>
                                <Col span={18}>
                                    <Form.Item {...{ labelCol: { span: 5 }, wrapperCol: { span: 18 } }} label="姓名" name="nickname">
                                        <Input placeholder="姓名" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col span={18}>
                                    <Form.Item {...{ labelCol: { span: 5 }, wrapperCol: { span: 18 } }} label="电话" name="phone">
                                        <Input placeholder="电话" disabled={!isUpdate} />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    {!isUpdate && (
                                        <Button className="btn" style={{ display: "inline-block", width: "100%" }} onClick={handleToChangePhone}>
                                            更改号码
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col span={18}>
                                    推广二维码{" "}
                                    <Button
                                        className="btn"
                                        style={{ display: "inline-block", marginLeft: "20px" }}
                                        onClick={() => {handleGetQrCode(true)}}
                                        loading={loading}
                                    >
                                       {qrCode ? "重新获取" : "获取"} 
                                    </Button>
                                </Col>
                                <Col span={18}style={{padding:" 20px 50px"}}>
                                    {
                                        qrCode && !loading ? <Image   src={fileLookUrl().image + qrCode} /> : null
                                    }
                                    
                                </Col>
                            </Row>
                            {isUpdate && (
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col offset={4} span={8}>
                                        <Form.Item {...{ wrapperCol: { span: 23 } }} name="phoneVerifyCode">
                                            <Input placeholder="验证码" style={{ marginRight: 0 }} id="set1" maxLength={11} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Button className="btn" style={{ display: "inline-block", width: "100%", padding: 0 }} onClick={handleGetCode}>
                                            {codeBtnText}
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </div>
                        <div className="line"></div>
                        <div className="title">
                            <span></span>更改密码
                        </div>
                        <div className="set-comtent-main">
                            <Row style={{ marginBottom: "10px" }}>
                                <Col span={18}>
                                    <Form.Item label="原始密码" name="oldPwd">
                                        <Input.Password placeholder="原始密码" type="password" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col span={18}>
                                    <Form.Item label="新密码" name="newPwd">
                                        <Input.Password placeholder="新密码" type="password" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col span={18}>
                                    <Form.Item label="确认密码" name="confirmPwd">
                                        <Input.Password placeholder="确认密码" type="password" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>

                        <div className="btns">
                            <Button className="cancel btn" onClick={handleCancelUpdate}>
                                取消
                            </Button>
                            <Button className="ok btn" onClick={handleUpdate}>
                                确认
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
