import { createModel } from "hox";
import { useState, useEffect } from "react";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { internalService } from "../../internalService";
export const useInternalDetailsModel = createModel(function () {
    const { user: { shopId, shopName, shopCode, userName }} = useGlobalModel();
    const initPgCfg = { pageSize: 15, current: 1 }
    const initFormVal = {
        shopCode,
        shopName,
    }
    //编辑缓存
    const [editFormVal, setEditFormVal] = useState(initFormVal);
    const [editGoodsList, setEditGoodsList] = useState([]);

    //新增缓存
    const [addFormVal, setAddFormVal] = useState(initFormVal);
    const [addGoodsList, setAddGoodsList] = useState([]);
    const [pgCfg, setPgCfg] = useState(initPgCfg);

    //新增选中的对账明细key
    const [addAllotRowKeys, setAddAllotRowKeys] = useState([]);
    const [addAllotRow, setAddAllotRow] = useState([]);
    //编辑选中的对账明细key
    const [editAllotRowKeys, setEditAllotRowKeys] = useState([]);
    const [editAllotRow, setEditAllotRow] = useState([]);
    //对账明细打开页面
    const [allDetailVisible, setAllDetailVisible] = useState(false);
    const [allotDetailList, setAllotDetailList] = useState([])
    useEffect(() => {
        resetAddCatch();
        setAddFormVal({ ...initFormVal, shopId, shopName, createUser: userName });
    }, [shopId]);
    //表单新增修改
    const updateAddFormVal = (val) => {
        val ? setAddFormVal({ ...addFormVal, ...val }) : setAddFormVal(initFormVal);
    }
    //表单编辑修改
    const updateEditFormVal = (val) => {
        val ? setEditFormVal({ ...editFormVal, ...val }) : setEditFormVal(initFormVal);
    }
    //重置缓存
    const resetEditCatch = () => {
        updateEditFormVal(null);   //表单
        setEditGoodsList([]);     //详情商品列表

    }
    const resetAddCatch = () => {
        updateAddFormVal(null);
        setAddGoodsList([]);
    }
    const getInternalDetailOne = async (id) => {
        let { retData } = await internalService.one(id);
        setEditFormVal(retData ?? {});
        allotLoadData({}, { writeoffCode: retData?.code }, "edit")
        return retData ?? {}
    }

    const allotLoadData = async (page, search = {}, mode = "add") => {
        let params = {
            ...search,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: page?.pageNum ?? pgCfg.current ?? initPgCfg.current,
        }
        let { retData } = await internalService.allot(params)
        let { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        mode === "add" ? setAddGoodsList(records) : setEditGoodsList(records)
    }
    const allotDetailLoadData = async (search) => {
        let params = {
            ...search,
            pageSize: -1,
            pageNum: 1,
        }
        let { retData } = await internalService.detailPage(params);
        let { records, ...pg } = retData;
        setAllotDetailList(records);

    }
    const saveInternal = async (params) => {
        let { retData } = await internalService.writeoff(params)
        return retData
    }
    const audit = async (params) => {
        let { retData } = await internalService.audit(params)
        return retData
    }
    return {
        editFormVal,
        editGoodsList,
        addFormVal,
        addGoodsList,
        setEditGoodsList,
        setEditFormVal,
        setAddFormVal,
        setAddGoodsList,
        resetEditCatch,
        resetAddCatch,
        updateEditFormVal,
        updateAddFormVal,
        allotLoadData,
        pgCfg, setPgCfg,
        saveInternal,
        getInternalDetailOne,
        audit,
        allDetailVisible, setAllDetailVisible,
        addAllotRowKeys, setAddAllotRowKeys,
        addAllotRow, setAddAllotRow,
        editAllotRowKeys, setEditAllotRowKeys,
        editAllotRow, setEditAllotRow,
        allotDetailLoadData,
        allotDetailList, setAllotDetailList
    }
})