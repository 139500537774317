import React, { useEffect } from 'react'
import { Form, Input, Col, Row, message, Modal, InputNumber, Button } from 'antd'
import { getUserInfo } from '@/utils/dataStorage'
import moment from 'moment'
import { returnOrderSettlement } from '@/service/ordersService'
import { __FormUpload } from '@/erp_subpackage/components/YhFormBox/item/FormUpload'
import { useState } from 'react'
export default (props) => {
    const { visible, setVisible, onSuccess, orderInfoOne, returnOrderInfo, mode, settlementInfo } = props
    const [form] = Form.useForm()
    const [cashAttach, setCashAttach] = useState({ cashAttach: '', cashAttachFileName: '' })
    useEffect(() => {
        if (visible) {
            const {
                returnCode,
                createTime,
                returnTotalAmount,
                returnActualAmount,
                returnAmount,
                returnSettlementTime,
                returnSettlementUser,
               
            } = returnOrderInfo
            const { cashAttach,
                cashAttachFileName,} = settlementInfo
            const {
                customerName,
                customerVehicleCode,
                ciBusinessEntityName,
                organName,
                orderTotalAmount,
                orderActualAmount,
                orderDiscount,
                orderPreferentialAmount,
                payType,
            } = orderInfoOne
            const payTypeName = payType && payType === 'auth_quota' ? '预授权支付' : payType === 'wechart_pay' ? '微信支付' : payType === 'cash' ? '现金' : ''
            form.setFieldsValue({
                returnCode,
                createTime,
                returnTotalAmount,
                returnActualAmount,
                customerName,
                customerVehicleCode,
                ciBusinessEntityName,
                organName,
                orderTotalAmount,
                orderActualAmount,
                orderDiscount,
                orderPreferentialAmount,
                payTypeName,
                returnAmount,
                returnSettlementTime,
                returnSettlementUser,
            })
            setCashAttach({
                cashAttach,
                cashAttachFileName,
            })
        }
    }, [visible])

    const isLook = () => (mode === 'look' ? true : false)

    //表单提交
    const onSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                returnDiscount: values.orderDiscount,
                returnPreferentialAmount: values.orderPreferentialAmount,
                remark: values.remark,
                id: returnOrderInfo.id,
                ...cashAttach,
            }
            returnOrderSettlement(data).then((res) => {
                message.success('结算成功')
                onSuccess && onSuccess()
            })
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const countTotal = () => {
        let { orderActualAmount, orderDiscount, orderPreferentialAmount, returnTotalAmount } = form.getFieldsValue([
            'orderActualAmount',
            'orderDiscount',
            'orderPreferentialAmount',
            'returnTotalAmount',
        ])
        let returnActualAmount = '',
            returnAmount
        if (orderDiscount) {
            returnActualAmount = ((returnTotalAmount * orderDiscount) / 10 - orderPreferentialAmount).toFixed(2)
        } else {
            returnActualAmount = (returnTotalAmount - orderPreferentialAmount).toFixed(2)
        }
        returnAmount = (orderActualAmount - returnActualAmount).toFixed(2)
        form.setFieldsValue({
            returnActualAmount,
            returnAmount,
        })
    }

    const onSetFieldsValue = (data) => {
        form.setFieldsValue(data)
        console.log(22)
        console.log(data)
        setCashAttach(data)
    }

    return (
        <Modal
            forceRender
            visible={visible}
            title="退单结算"
            centered={true}
            footer={
                isLook()
                    ? null
                    : [
                          <Button onClick={handleCancel} key="back">
                              {' '}
                              取消
                          </Button>,
                          <Button type="primary" onClick={onSubmit} key="submit">
                              确认
                          </Button>,
                      ]
            }
            onCancel={() => handleCancel()}
            width={800}
        >
            <div className="form-details-warp">
                <Form form={form} {...formItemLayout}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item label="退单单号" name="returnCode">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="制单日期" name="createTime">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="客户名称" name="customerName">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="车辆牌照" name="customerVehicleCode">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="所属实体" name="ciBusinessEntityName">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="所属机构" name="organName">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="原单应收" name="orderTotalAmount">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="原单实收" name="orderActualAmount">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="整单折扣" name="orderDiscount">
                                <InputNumber style={{ width: '100%' }} min={0} max={10} onBlur={countTotal} disabled={isLook()} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="整单优惠"
                                name="orderPreferentialAmount"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: '请输入数字且可保留两位小数',
                                    },
                                ]}
                            >
                                <Input onBlur={countTotal} disabled={isLook()} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="应退金额" name="returnTotalAmount">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="实退金额" name="returnActualAmount">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="原支付方式" name="payTypeName">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="退后金额" name="returnAmount">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="结算人" name="returnSettlementUser">
                                <Input disabled placeholder="结算后自动生成" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="结算日期" name="returnSettlementTime">
                                <Input disabled placeholder="结算后自动生成" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <__FormUpload
                                fieldLabel="附件"
                                fieldName="cashAttachFileName"
                                fieldMd5="cashAttach"
                                defaultFileName={cashAttach.cashAttachFileName}
                                defaultFileMd5={cashAttach.cashAttach}
                                onSetFieldsValue={onSetFieldsValue}
                                isUsePortalApi={true}
                            />
                        </Col>

                        <Col span={24}>
                            <Form.Item label="结算备注" name="remark" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
                                <Input.TextArea disabled={isLook()} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    )
}
