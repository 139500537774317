import React, { useRef } from "react";
import { Button, Table, Tooltip, message } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { useSaleRoDetailModel } from "./saleRoDetailModel";
import { DetailType } from "@/erp_subpackage/utils/ErpEnum";
import { formatNum, formatIntPoint, formatInt } from "@/erp_subpackage/utils/util";
import { EditableRow, EditableCell } from "@/erp_subpackage/components/YHEditTable/editTable";
import { YhBut } from "@/erp_subpackage/components/YhButton";
import useGlobalModel from "@/erp_subpackage/model/globalModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

export const TableBox = ({ modeKey, mode, detailsList, rowId, dispatch }) => {
    const { setMaterialInfo } = useGlobalModel()
    const {
        setAddModalVisible,
        detailAddList,
        detailLookForm,
        detailDataSource,
        detailLookList,
        detaiAddDataRowKeys,
        detailDataRowKeys,
        detailEditForm,
        detailAddForm,
        setDetaiAddDataRow,
        setDetaiAddDataRowKeys,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailAddList,
        setDetailDataSource,
        detailDetailList,
        setTopButsControl,
    } = useSaleRoDetailModel();

    const form = mode === "add" ? detailAddForm : detailEditForm;
    const action = (mode === "look" || form.status === DictCodeEnmu.SALE_RETURN_STATUS_STOCK_IO) ? <></> : <>
        <Button
            className="btn-item"
            type="link"
            shape="round"
            onClick={() => {
                let { customerCode, customerName, bentityCode } = form;
                if (!customerName) {
                    message.warning("请先选择客户");
                    return false
                }
                if (!bentityCode) {
                    message.warning("请先选择销售实体");
                    return false
                }
                setAddModalVisible(true)
            }}
        >新增商品</Button>
        <Button
            className="btn-item"
            type="link"
            shape="round"
            onClick={() => { detailDetailList(mode) }}
        >删除商品</Button>
    </>

const detailForm= mode === "add" ? detailAddForm :  mode === "edit" ?detailEditForm:detailLookForm
    const columns = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '销售单号',
            width: 120,
            dataIndex: 'saleCode',
            fixed: "left",
            render: (text) => <YhTooltip text={text} />,
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName,customerName:detailForm.customerName,customerCode:detailForm.customerCode})} />,
            // render: (text, record, index) => <Tooltip placement="topLeft" title={text}><p className="one-line click-span" onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })}>{text}</p></Tooltip>,
        }, {
            title: '商品名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text) => <YhTooltip text={text} />,
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '单位',
            width: 50,
            dataIndex: 'unitName',
        }, {
            title: '销售数量',
            width: 100,
            dataIndex: 'saleNum',
        }, {
            title: '销售单价',
            width: 100,
            dataIndex: 'salePrice',
            render: text => formatNum(text)
        }, {
            title: '折扣',
            width: 100,
            dataIndex: 'discountRate',
            render: text => text + '%'
        }, {
            title: '退货数量',
            width: 100,
            dataIndex: 'num',
            editable: mode === DetailType.look || (detailEditForm.status !== DictCodeEnmu.SALE_RETURN_STATUS_CREATE && mode === DetailType.edit) ? false : true,
        }, {
            title: '退货单价',
            width: 100,
            dataIndex: 'price',
            editable: mode === DetailType.look || (detailEditForm.status !== DictCodeEnmu.SALE_RETURN_STATUS_CREATE && mode === DetailType.edit) ? false : true,
            render: text => formatNum(text)
        }, {
            title: '退货折扣',
            width: 100,
            dataIndex: 'discountRate',
            render: text => text + '%'
        }, {
            title: '退货折扣金额',
            width: 100,
            dataIndex: 'discountAmount',
        }, {
            title: '单项优惠金额',
            width: 100,
            dataIndex: 'rdecreaseAmount'
        }, {
            title: '退货金额',
            width: 100,
            dataIndex: 'amount',
            render: text => formatNum(text)
        }, {
            title: '已退数量',
            width: 100,
            dataIndex: 'sreturnNum',
        }, {
            title: '品牌',
            width: 100,
            dataIndex: 'brandName',
        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseName',
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
            editable: mode === DetailType.look || (detailEditForm.status !== DictCodeEnmu.SALE_RETURN_STATUS_CREATE && mode === DetailType.edit) ? false : true,
        }, {
            title: '操作',
            width: 60,
            fixed: "right",
            align: "center",
            render: (text, record) =>
                (mode !== "look" ? (mode === "add" || !(detailEditForm.status !== DictCodeEnmu.SALE_RETURN_STATUS_CREATE)) : false) && <YhBut
                    type="delete"
                    txt="删除"
                    click={() => removeMaterial(record)}
                />
        }
    ];
    const removeMaterial = (record) => {
        detailDetailList(mode, record)
    }
    const newSelectColumns = () =>
        columns.map((col, index) => {
            return {
                ...col,
                onCell: (record, rowIndex) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col?.rules,
                    options: col?.options,
                    editType: col?.editType,
                    //整单退货过来的不可以编辑
                    editable: detailsList ? false : col?.editable,
                    onSelectChange: col?.onSelectChange,
                    onSelectFocus: col?.onSelectFocus,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                }),
            }
        })
    const handleSave = (row) => {
        let { num, price, sreturnNum, saleNum, salePrice, discountRate, rdecreaseAmount } = row
        discountRate = +formatNum(discountRate)
        saleNum = + formatNum(saleNum)
        sreturnNum = + formatNum(sreturnNum)
        // num = +formatInt(num, saleNum - sreturnNum, "退货数量不能大于【销售数量-已退数量】")
        num = +formatNum(num);
        if(num>(saleNum - sreturnNum))
        {
            return message.warning("退货数量不能大于【销售数量-已退数量】");

        }
        price = + formatNum(price)
        if (price > +salePrice) {
            message.warning("退货价格不能高于销售价格");
            price = salePrice;
        } else {
            price = price;
        }
        let newRow = {
            ...row,
            num,
            price,
            amount: +formatNum((num * price * discountRate / 100) - rdecreaseAmount),
            discountAmount: +formatNum(num * price * discountRate / 100)
        }
        let newData = mode === "edit" ? detailDataSource : detailAddList;
        const index = newData.findIndex((item) => row.detailsId === item.detailsId);
        const item = newData[index];

        newData.splice(index, 1, { ...item, ...newRow });
        mode === "edit" ? setDetailDataSource([...newData]) : setDetailAddList([...newData])
        setTopButsControl(true)
    }
    const typeList = {
        "add": detailAddList,
        "edit": detailDataSource,
        "look": detailLookList,
    }
    const setTypeDetailDataRowKeysAndRow = async (selectedRowKeys, mode) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeDetailDataRow = async (selectedRows, mode) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailDataRow(selectedRows)
        }
    }

    return (
        <YhBox title="商品信息" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.detailsId}
                rowSelection={
                    mode === DetailType.look ? undefined : {
                        selectedRowKeys: mode == DetailType.add ? detaiAddDataRowKeys : detailDataRowKeys, //记录选中
                        onChange: (selectedRowKeys, selectedRows) => {
                            setTypeDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeDetailDataRow(selectedRows, mode)
                        }
                    }}
                columns={newSelectColumns()}
                dataSource={typeList[mode]}
                pagination={false}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    }
                }}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}