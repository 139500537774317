import { useState } from "react";
import { createModel } from "hox";
import { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { recordService } from "./recordService";
import useGlobalModel from "@/erp_subpackage/model/globalModel";

export const useRecordModel = createModel(function () {
    const { orgDs } = useGlobalModel(({ orgDs }) => [orgDs]);
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState({ spinning: false, tip: "加载中..." });
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //分页参数
    const initPgCfg = { pageSize: 15, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg);

    // //表格多选id值
    // const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    // //机构缓存查询
    // const orgLoadData = async () => {
    //     let searchParams = {
    //         searchItem: { orderFlag: 0, showPlatformOrgan: true },
    //         pageNum: 1,
    //         pageSize: 1000,
    //         orders: [{ column: "createTime", rule: "DESC" }],
    //     }
    //     let { retData: { list } } = await recordService.orgPage(searchParams);
    //     setOrgDs((list ?? []).map(item => ({ label: item.organName, value: item.id })));
    // }
    //列表查询
    const loadData = async (page, searchValue = searchParam) => {
        setLoading({ spinning: true, tip: "列表加载中..." });
        let orgName = orgDs.find(item => item.value === searchValue.org)?.label
        const searchParams = {
            ...searchValue,
            org: orgName === "全部机构" ? undefined : orgName,
            page: {
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
                pageNum: page?.pageNum ?? pgCfg.current ?? initPgCfg.current,
            }
        }
        let { retData } = await recordService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
        setLoading({ spinning: false });
    }
    //设置搜索value
    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        loadData(initPgCfg, {});
    }

    return {
        /***列表***/
        searchParam,
        dataSource,
        pgCfg,
        loading,
        setDataSource,
        resetSearchParam,
        updateSearchParam,
        loadData
    }
});