import React, { useContext, useEffect, useState } from "react"
import { Button, Col, Row, message, Select, Divider, DatePicker, Table } from "antd"
import { MyContext } from "@/store/myContext"
import "./statistics.less"
import moment from "moment"
import SettledOrder from "./settledOrder"
import { getAccountStaticSummaryList, getAccountStatic } from "@/service/accountStaticSummary"
import VehicleListHistoryModal from "./vehicleListHistoryModal"
const { RangePicker } = DatePicker

const { Option } = Select
const Statistics = ({ rowInfo }) => {
    console.log(rowInfo)
    const [loading, setLoading] = useState(false) //团队列表loading
    const [searchValue, setSearchValue] = useState({}) //搜索
    const [accountStaticSummary, setAccountStaticSummary] = useState({
        threeGuaranteesWorkHoursAmount: 0,
        threeGuaranteesPartAmount: 0,
        threeGuaranteesOrderNum: 0,
        chargeWorkHoursAmount: 0,
        chargePartAmount: 0,
        chargeOrderNum: 0,
        detail: [],
    })
    const [accountStatic, setAccountStatic] = useState({
        orderActualAmount: 0, //"总消费金额",
        orderNum: 0, // "总单据数量",
        usedCredit: 0, // "欠款总额",
        vehicleNum: 0, //"车辆总数",
        unsettledAmount: 0, // "未结算单据",
        customerName: "客户名称", //"客户名称"
    })
    const { dispatch, state } = useContext(MyContext)
    console.log(state)
    const [pagination, setpagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [param, setParam] = useState({
        startTime: moment(new Date()).format("YYYY-MM-DD"),
        // startTime: moment(new Date()).subtract(1, "year").format("YYYY-MM-DD"),
        endTime: moment(new Date()).format("YYYY-MM-DD"),
    })

    const [vehicleMoadl, setVehicleMoadl] = useState({ vehicle: {}, visible: false })
    useEffect(() => {
        //
        if (state.activeMenu === "clientRecord/statis") {
            getAccountStatic(rowInfo.id).then((res) => {
                console.log(res)
                // if (res && res.retCode === 200) {
                if (res.retData) {
                    setAccountStatic(res.retData)
                }
                // } else {
                //     message.error("加载数据失败")
                // }
            })
        }
        return () =>
            setAccountStatic({
                orderActualAmount: 0, //"总消费金额",
                orderNum: 0, // "总单据数量",
                usedCredit: 0, // "欠款总额",
                vehicleNum: 0, //"车辆总数",
                unsettledAmount: 0, // "未结算单据",
                customerName: "客户名称", //"客户名称"
            })
    }, [state.activeMenu])
    useEffect(() => {
        if (state.activeMenu === "clientRecord/statis") {
            loadData(pagination.current)
        }
        return () =>
            setAccountStaticSummary({
                threeGuaranteesWorkHoursAmount: 0,
                threeGuaranteesPartAmount: 0,
                threeGuaranteesOrderNum: 0,
                chargeWorkHoursAmount: 0,
                chargePartAmount: 0,
                chargeOrderNum: 0,
                materialCost:0,
                detail: [],
            })
    }, [state.activeMenu, param.startTime, pagination.current])

    //分页事件
    const onTableChange = async (pagination) => {
        setpagination({
            ...pagination,
            current: pagination.current,
        })
    }
    function onChange(dates, dateStrings) {
        setParam({
            startTime: dates[0],
            endTime: dates[1],
        })
    }
    const loadData = async (current, search = searchValue) => {
        setLoading(true)
        //  {
        //     "pageSize": "分页条件 每页条数",
        //     "pageNum": "分页条件 页码(当前页)`从1开始`",
        //     "searchItem": {
        //         "customerUserId": "客户ID"
        //     },
        //     "startTime": "分页条件 开始时间 格式`2019-07-15T16:00:00.000`",
        //     "orders": [
        //         {
        //         }
        //     ],
        //     "endTime": "分页条件 结束时间 格式`2019-07-15T16:00:00.000`"
        // }
        const params = {
            searchItem: { ...search, customerUserId: rowInfo.id },
            pageNum: current,
            pageSize: 10,
            startTime: moment(param.startTime).format("YYYY-MM-DD") + "T00:00:00",
            endTime: moment(param.endTime).format("YYYY-MM-DD") + "T23:59:59",
            orders: [
                // {
                //     column: "createTime",
                //     rule: "DESC",
                // },
            ],
        }
        getAccountStaticSummaryList(params).then((res) => {
            console.log(res)
            // if (res && res.retCode === 200) {
            setpagination({
                current: current,
                total: res.retData.detail.total,
                pageSize: 10,
            })
            setAccountStaticSummary(res.retData)
            setLoading(false)
            // } else {
            //     message.error("加载数据失败")
            // }
        })
    }
    // @VoAnnotate("车辆牌照")
    // private String vehicleCode;

    // @VoAnnotate("车辆品牌")
    // private String brand;

    // @VoAnnotate("单据数量")
    // private int orderNum;

    // @VoAnnotate("三包工时费")
    // private float threeGuaranteesWorkHoursAmount;

    // @VoAnnotate("三包配件费")
    // private float threeGuaranteesPartAmount;

    // @VoAnnotate("收费工时费")
    // private float chargeWorkHoursAmount;

    // @VoAnnotate("收费配件费")
    // private float chargePartAmount;

    // @VoAnnotate("总消费额")
    // private float orderActualAmount;
    //团队列表table配置
    const columns = [
        {
            title: "车辆牌照",
            align: "center",
            dataIndex: "vehicleCode",
            render: (text, record) => (
                <div
                    style={{ color: "#2e3aeb", cursor: "pointer" }}
                    onClick={() => {
                        setVehicleMoadl({ vehicle: record, visible: true, })
                    }}
                >
                    {text}
                </div>
            ),
        },
        {
            title: "车辆品牌",
            align: "center",
            dataIndex: "brand",
        },
        {
            title: "单据数量",
            align: "center",
            dataIndex: "orderNum",
        },
        {
            title: "三包工时费",
            align: "center",
            dataIndex: "threeGuaranteesWorkHoursAmount",
        },
        {
            title: "三包材料费",
            align: "center",
            dataIndex: "threeGuaranteesPartAmount",
        },

        {
            title: "收费工时费",
            align: "center",
            dataIndex: "chargeWorkHoursAmount",
        },
        {
            title: "收费材料费",
            align: "center",
            dataIndex: "chargePartAmount",
        },
        {
            title: "收费材料成本",
            align: "center",
            dataIndex: "materialCost",
        },
        {
            title: "其他费用",
            align: "center",
            dataIndex: "otherCost",
        },
        {
            title: "总消费金额",
            align: "center",
            dataIndex: "orderActualAmount",
        },
    ]

    return (
        <>
            <div className="list-container">
                <div className="list-header">
                    <Row align="middle ">
                        <Col>{accountStatic.customerName}</Col>
                        <Col style={{ marginLeft: "20px" }}>
                            <Button
                                shape="round"
                                size="small"
                                className="team-but-left"
                                onClick={async () => {
                                    await dispatch({
                                        type: "removeTabs",
                                        targetKey: "statis/settledOrder",
                                    })
                                    dispatch({
                                        type: "changeTabs",
                                        name: "结算订单",
                                        activeMenu: "statis/settledOrder",
                                        content: <SettledOrder customerName={accountStatic.customerName} rowInfo={rowInfo}></SettledOrder>,
                                    })
                                }}
                            >
                                生成账单
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Divider style={{ margin: "16px 0" }} />
                <div className="statistics">
                    <div className="item">
                        <span>总消费金额</span>
                        <p>{accountStatic.orderActualAmount}</p>
                    </div>
                    <div className="item">
                        <span>总单据数量</span>
                        <p>{accountStatic.orderNum}</p>
                    </div>
                    <div className="item">
                        <span>欠款金额</span>
                        <p>{accountStatic.usedCredit}</p>{" "}
                    </div>
                    <div className="item">
                        <span>未结算单据</span>
                        <p>{accountStatic.unsettledAmount}</p>{" "}
                    </div>
                    <div className="item">
                        <span>上线车辆</span>
                        <p>{accountStatic.vehicleNum}</p>{" "}
                    </div>
                </div>
                <Divider style={{ margin: "16px 0" }} />
            </div>
            <div className="list-container">
                <div className="list-header">
                    <Row align="middle " gutter="10">
                        <Col>整体数据</Col>
                        <Col>
                            <RangePicker
                                size="small"
                                defaultValue={[moment(param.startTime, "YYYY/MM/DD"), moment(param.endTime, "YYYY/MM/DD")]}
                                ranges={{
                                    全部: [moment(new Date()).subtract(1, "year"), moment(new Date())],
                                    最近7天: [moment(new Date()).subtract(1, "weeks"), moment(new Date())],
                                    最近30天: [moment(new Date()).subtract(1, "month"), moment(new Date())],
                                }}
                                onChange={onChange}
                            />
                        </Col>
                        <Col>
                            {moment(param.startTime).format("YYYY-MM-DD")}至{moment(param.endTime).format("YYYY-MM-DD")}
                        </Col>
                    </Row>
                </div>
                <Divider style={{ margin: "16px 0" }} />
                <div className="statistics">
                    <div className="item">
                        <span>三包工时费</span>
                        <p>{accountStaticSummary.threeGuaranteesWorkHoursAmount}</p>
                    </div>
                    <div className="item">
                        <span>三包材料费</span>
                        <p>{accountStaticSummary.threeGuaranteesPartAmount}</p>
                    </div>
                    <div className="item">
                        <span>三包单据数量</span>
                        <p>{accountStaticSummary.threeGuaranteesOrderNum}</p>
                    </div>
                    <div className="item">
                        <span>收费工时费</span>
                        <p>{accountStaticSummary.chargeWorkHoursAmount}</p>
                    </div>
                    <div className="item">
                        <span>收费材料费</span>
                        <p>{accountStaticSummary.chargePartAmount}</p>
                    </div>
                    <div className="item">
                        <span>收费材料成本</span>
                        <p>{accountStaticSummary.materialCost}</p>
                    </div>
                    <div className="item">
                        <span>收费单据数量</span>
                        <p>{accountStaticSummary.chargeOrderNum}</p>
                    </div>
                </div>
                <Divider style={{ margin: "16px 0" }} />
                <Table
                    size="small"
                    columns={columns}
                    dataSource={accountStaticSummary.detail.records}
                    rowKey={(record) => record.id}
                    loading={loading}
                    onChange={onTableChange}
                    pagination={{
                        showQuickJumper: true,
                        showSizeChanger: false,
                        showTotal: () => `共${accountStaticSummary.detail.total}条`,
                        position: ["bottomCenter"],
                        ...pagination,
                    }}
                />
                {vehicleMoadl.visible && <VehicleListHistoryModal vehicleMoadl={vehicleMoadl} setVehicleMoadl={setVehicleMoadl} param={param} />}
            </div>
        </>
    )
}
export default Statistics
