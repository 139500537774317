import React, { useEffect } from 'react'
import { Table } from 'antd'
import { taskBookModel } from "./taskBookModel"
import { taskBookService } from "@/service/taskBookService"

export const ItemBox = () => {

    const {
        itemData, setItemData, tabslist, formVal
    } = taskBookModel();

    useEffect(() => {
        if (formVal.intervalMileage && formVal.startingMileage && formVal.firstMaintainLevel && formVal.scheduleRuleChinese) {
            getCheckItemInfo()
        } else {
            setItemData([])
        }
    }, [formVal.intervalMileage, formVal.startingMileage, formVal.firstMaintainLevel, formVal.scheduleRuleChinese])

    const getCheckItemInfo = async () => {
        if (formVal.intervalMileage && formVal.startingMileage && formVal.firstMaintainLevel && formVal.scheduleRuleChinese) {
            let data = {
                vehicleMileage: formVal.startingMileage ?? 0, //车辆初始里程
                intervalMileage: formVal.intervalMileage ?? 1, //间隔里程
                firstMaintainLevel: formVal.firstMaintainLevel.split('~')[1], //首次保养级别
                scheduleRuleChinese: formVal.scheduleRuleChinese, //是否国产
            }

            let { retData } = await taskBookService.getCheckItem(data)

            let newData = retData.map((item) => {
                return {
                    ...item,
                    temporaryId: new Date().getTime() + Math.floor(Math.random() * 10000),
                }
            })
            setItemData(newData);
        }
    }
    const columns = [
        {
            title: (
                <>
                    <span>次数</span>
                </>
            ),
            width: 40,
            align: "center",
            render: (t, r, i) => i + 1,
        },
        {
            title: '公里',
            width: 100,
            align: "center",
            dataIndex: 'maintainMileage',
        },

        ...((tabslist) => {
            let arr = []
            tabslist.forEach((i) => {
                arr.push({
                    title: i,
                    width: 90,
                    dataIndex: 'maintainLevel',
                    align: "center",
                    render: (t, r) => <span>{r.maintainLevel + '保养' === i ? '⚫' : ''}</span>,
                })
            })
            return arr
        })(tabslist),

        {
            title: <span>保养级别</span>,
            width: 90,
            dataIndex: 'maintainLevel',
            align: "center",
        },
        {
            title: <span>备注</span>,
            width: 200,
            dataIndex: 'remark',
            align: "center",
        },
    ]

    return (
        <>
            <Table
                style={{ marginBottom: '20px', border: '1px solid #f0f0f0' }}
                rowKey={(record) => record.temporaryId || record.id}
                bordered
                columns={columns}
                dataSource={itemData}
                scroll={{ x: 'max-content', y: '600px' }}
                pagination={false}
            />
        </>
    )
}
