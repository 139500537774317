import { post, get } from "../utils/request"

/**
 * 车型配置
 */
//获取列表
function getWvSetting(data) {
    return post(`/ci-customer/wv_setting/tree`, data)
}
//编辑
function wvSettingUpdate(data) {
    return post(`/ci-customer/wv_setting/update`, data)
}

//禁用
function wvSettingDisable(data) {
    return post(`/ci-customer/wv_setting/disable`, data)
}

//新增
function wvSettingInsert(data) {
    return post(`/ci-customer/wv_setting/insert`, data)
}

function wvSettingTree(data) {
    return post(`/ci-customer/wv_setting/tree`, data)
}

function wvVehiclePage(data) {
    return post(`/ci-customer/wv_vehicle/page`, data)
}
function wvVehicleIsert(data) {
    return post(`/ci-customer/wv_vehicle/insert`, data)
}
function wvVehicleUpdate(data) {
    return post(`/ci-customer/wv_vehicle/update`, data)
}
function wvVehicleVehicleAttr(data) {
    return post(`/ci-customer/wv_vehicle/vehicleAttr`, data)
}
function wvVehicleOne(id) {
    return get(`/ci-customer/wv_vehicle/one/${id}`)
}
function wvVehicleGetVehicleAttr(id) {
    return get(`/ci-customer/wv_vehicle/getVehicleAttr/${id}`)
}

/**
 *  访问记录
 */
//获取列表
function getWvAccessRecord(data) {
    return post(`/ci-customer/wv_access_record/page`, data)
}

/**
 *  咨询记录
 */
//获取列表
function getWvConsultationRecord(data) {
    return post(`/ci-customer/wv_consultation_record/page`, data)
}

export {
    getWvSetting,
    wvSettingUpdate,
    wvSettingInsert,
    getWvAccessRecord,
    getWvConsultationRecord,
    wvSettingTree,
    wvVehiclePage,
    wvVehicleIsert,
    wvVehicleVehicleAttr,
    wvVehicleOne,
    wvVehicleGetVehicleAttr,
    wvVehicleUpdate,
    wvSettingDisable,
}
