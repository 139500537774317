import React, { useEffect, useState } from "react"
import { Table, Modal, Button, message } from "antd"
import { quotaOrderDetailList } from "@/service/teamService"
import styled from "@emotion/styled"
import PrintMadal from "./print"
import { download, convertRes2Blob } from "@/utils/exportExcel"
import Loading from "@/components/Loading"

export default (props) => {
    const { rowInfo = {}, visible, setVisible, setSettlevisible } = props
    const [data, setData] = useState([])
    const [printVisible, setPrintVisible] = useState(false)
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    useEffect(() => {
        if (visible) {
            quotaOrderDetailList(rowInfo.id).then((res) => {
                setData(res.retData)
            })
        } else {
            setData([])
        }
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            title: "工单编号",
            align: "center",
            dataIndex: "orderCode",
        },
        {
            title: "工单类型",
            align: "center",
            dataIndex: "orderType",
        },
        {
            title: "工时费",
            align: "center",
            dataIndex: "itemActualAmount",
        },
        {
            title: "应收金额",
            align: "center",
            dataIndex: "orderTotalAmount",
        },
        {
            title: "实收金额",
            align: "center",
            dataIndex: "orderActualAmount",
        },
        {
            title: "登记日期",
            align: "center",
            dataIndex: "createTime",
        },
    ]

    const exportData = async () => {
        setExportLoading(true)
        let data = {
            type: "get"
        }
        let resFlie = await download(`/ci/order_team_settlement/quotaOrderDetailListExport/${rowInfo.id}`, data)
        if (resFlie && resFlie.status === 200) {
            message.success("已导出")
            convertRes2Blob(resFlie)
        } else {
            message.warning("导出异常，请重新尝试")
        }
        setExportLoading(false)
    }
    return (
        <>
            <Modal
                forceRender
                visible={visible}
                title="账单明细"
                centered={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={
                    Number(rowInfo.settlementStatus) === 1
                        ? [
                              <Button
                                  key="back"
                                  type="primary"
                                  onClick={() => {
                                      exportData()
                                  }}
                              >
                                  导出
                              </Button>,

                              <Button
                                  key="back"
                                  type="primary"
                                  onClick={() => {
                                      setPrintVisible(true)
                                      setVisible(false)
                                  }}
                              >
                                  打印
                              </Button>,

                              <Button
                                  key="submit"
                                  type="primary"
                                  onClick={() => {
                                      setVisible(false)
                                      setSettlevisible && setSettlevisible(true)
                                  }}
                              >
                                  结算
                              </Button>,
                              <Button
                                  key="link"
                                  onClick={() => {
                                      setVisible(false)
                                      setSettlevisible && setSettlevisible(true)
                                  }}
                              >
                                  关闭
                              </Button>,
                          ]
                        : [
                              <Button
                                  key="back"
                                  type="primary"
                                  onClick={() => {
                                      exportData()
                                  }}
                              >
                                  导出
                              </Button>,
                              <Button
                                  key="back"
                                  type="primary"
                                  onClick={() => {
                                      setPrintVisible(true)
                                      setVisible(false)
                                  }}
                              >
                                  打印
                              </Button>,

                              <Button
                                  key="link"
                                  onClick={() => {
                                      setVisible(false)
                                  }}
                              >
                                  关闭
                              </Button>,
                          ]
                }
                width={1200}
            >
                <SettleTitle>
                    <span>账单账号：{rowInfo.settlementNo}</span>
                    <span>团队名称：{rowInfo.teamName}</span>
                    <span>账单金额：{rowInfo.totalAmount}</span>
                    <span>已结算：{Number(rowInfo.settlementStatus) === 1 ? 0 : rowInfo.totalAmount}</span>
                    <span>未结算：{Number(rowInfo.settlementStatus) === 2 ? 0 : rowInfo.totalAmount}</span>
                </SettleTitle>
                <Table size="small" rowKey={(record) => record.orderCode} columns={columns} dataSource={data} />
            </Modal>
            {printVisible && <PrintMadal visible={printVisible} onClose={(bool) => setPrintVisible(bool)} rowInfo={rowInfo} data={data} />}
            {exportLoading && <Loading />}
        </>
    )
}

const SettleTitle = styled.div`
    span {
        margin-right: 10px;
    }
`
