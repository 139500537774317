import React, { useEffect, useState } from "react"
import { Row, Col, DatePicker, Button, Divider, message, Radio, Table, Modal } from "antd"
import { download, convertRes2Blob } from "@/utils/exportExcel"
import Loading from "@/components/Loading"
import moment from "moment"
import { teamUserIncomeDetail } from "@/service/teamService"
import styled from "@emotion/styled"
import { settlementMethodEmun } from "@/utils/enum"
export default (props) => {
    const { rowInfo, visible, setVisible, defaultStartTime, defaultEndTime } = props
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const dateFormat = "YYYY/MM/DD HH:mm:ss"
    const [loading, setLoading] = useState(false) //列表loading
    const [startTime, setStartTime] = useState(
        defaultStartTime ? defaultStartTime : moment().startOf('month').format("YYYY-MM-DD")
    )
    const [endTime, setEndTime] = useState(defaultEndTime ? defaultEndTime : moment().startOf('day').format("YYYY-MM-DD"))
    const [pagination, setpagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [incomeData, setIncomeData] = useState({
        summary: {
            totalAmount: 0,
            totalOrderNum: 0,
            chargeOrderNum: 0,
            threeGuaranteesOrderNum: 0,
            chargeWorkHoursAmount: 0,
            threeGuaranteesWorkHoursAmount: 0,
            chargePartAmount: 0,
            threeGuaranteesPartAmount: 0,
            bussinessCost: 0,
            sparePartsSalesAmount: 0,
        },
        detail: { records: [] },
    })
    const [orderType, setOrderType] = useState("")

    useEffect(() => {
        if (rowInfo.userId) {
            getStatic()
        }
    }, [rowInfo.userId, orderType]) // eslint-disable-line react-hooks/exhaustive-deps

    const getStatic = (pageNum = 1) => {
        setLoading(true)
        teamUserIncomeDetail({
            startTime: startTime + 'T00:00:00',
            endTime: endTime + 'T23:59:59',
            pageNum: pageNum,
            pageSize: 10,
            searchItem: {
                orderType,
                userId: rowInfo.userId,
            },
        })
            .then((res) => {
                setIncomeData(res.retData)
                setpagination({
                    current: pageNum,
                    total: res.retData.detail.total,
                    pageSize: 10,
                })

                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }
    const setOrder = async (type) => {
        setOrderType(type)
    }

    //分页事件 置空当前行信息  翻页时置空成员列表
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        getStatic(page.current)
    }
    const columns = [
        {
            title: "工单编号",
            dataIndex: "orderCode",
            key: "orderCode",
            align: "center",
        },
        {
            title: "公司名称",
            dataIndex: "customerName",
            key: "customerName",
            align: "center",
        },
        {
            title: "总工时费",
            dataIndex: "totalWorkHoursAmount",
            key: "totalWorkHoursAmount",
            align: "center",
        },
        {
            title: "税费",
            dataIndex: "taxFee",
            key: "taxFee",
            align: "center",
        },
        {
            title: "平台服务费",
            dataIndex: "platServiceFee",
            key: "platServiceFee",
            align: "center",
        },
        {
            title: "团队分成",
            dataIndex: "teamShareRatio",
            key: "teamShareRatio",
            align: "center",
        },
        {
            title: "个人点位",
            dataIndex: "allocationPoint",
            key: "allocationPoint",
            align: "center",
        },
        {
            title: "分配比例",
            dataIndex: "userDistributionPoints",
            key: "userDistributionPoints",
            align: "center",
        },
        {
            title: "结算方式",
            dataIndex: "teamSettlementMethod",
            key: "teamSettlementMethod",
            align: "center",
            render: (text) => <>{settlementMethodEmun[text]}</>,
        },
        {
            title: "维修配件收益",
            dataIndex: "repairPartIncome",
            key: "platIncome",
            align: "center",
        },
        {
            title: "车服收益",
            dataIndex: "platIncome",
            key: "platIncome",
            align: "center",
        },
        {
            title: "团队收益",
            dataIndex: "teamIncome",
            key: "teamIncome",
            align: "center",
        },
        {
            title: "个人收益",
            dataIndex: "teamUserIncome",
            key: "teamUserIncome",
            align: "center",
        },
        {
            title: "竣工日期",
            dataIndex: "finishTime",
            key: "finishTime",
            align: "center",
        },
    ]
    const now = new Date()
    const initStartTime = new Date(now.getTime() - 1000 * 60 * 60 * 24 * 30)
    const { RangePicker } = DatePicker;
    return (
        <>
            <Modal
                forceRender
                visible={visible}
                title="成员收益"
                centered={true}
                width={"100vw"}
                onCancel={() => {
                    setVisible(false)
                }}
                onOk={() => {
                    setVisible(false)
                }}
            >
                <div className="form-details-warp">
                    <>
                        <div className="list-container">
                            <div className="list-header">
                                <Row align="middle " justify="space-between">
                                    <Col>
                                        <Row align="middle">
                                            <Col style={{ textAlign: "right" }}>统计时间：</Col>
                                            <Col>
                                                <RangePicker
                                                    className="search-input"
                                                    defaultValue={[moment(startTime), moment(endTime)]}
                                                    format={"YYYY-MM-DD"}
                                                    onChange={(date, str) => {
                                                        setStartTime(str[0])
                                                        setEndTime(str[1])
                                                    }}
                                                />
                                            </Col>
                                            <Col style={{ color: 'red', marginLeft: 100, fontSize: 22 }}>总收益：{incomeData.summary.totalIncome}</Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Button
                                            shape="round"
                                            size="small"
                                            className="team-but-left"
                                            onClick={() => {
                                                getStatic()
                                            }}
                                        >
                                            查询
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            <Divider style={{ margin: "16px 0" }} />
                            <Contentdiv>
                                <ContentItem>
                                    <span>总收入</span>
                                    <p>{incomeData.summary.totalAmount || 0}</p>
                                </ContentItem>
                                <ContentItem>
                                    <span>总工单</span>
                                    <p>{incomeData.summary.totalOrderNum || 0}</p>
                                </ContentItem>
                                <ContentItem>
                                    <span>三包工单</span>
                                    <p>{incomeData.summary.threeGuaranteesOrderNum || 0}</p>
                                </ContentItem>
                                <ContentItem>
                                    <span>收费工单</span>
                                    <p>{incomeData.summary.chargeOrderNum || 0}</p>
                                </ContentItem>
                                <ContentItem>
                                    <span>经营成本</span>
                                    <p>{incomeData.summary.bussinessCost || 0}</p>
                                </ContentItem>
                                <ContentItem>
                                    <span>三包工时</span>
                                    <p>{incomeData.summary.threeGuaranteesWorkHoursAmount || 0}</p>
                                </ContentItem>
                                <ContentItem>
                                    <span>三包配件</span>
                                    <p>{incomeData.summary.threeGuaranteesPartAmount || 0}</p>
                                </ContentItem>
                                <ContentItem>
                                    <span>收费工时</span>
                                    <p>{incomeData.summary.chargeWorkHoursAmount || 0}</p>
                                </ContentItem>{" "}
                                <ContentItem>
                                    <span>维修配件</span>
                                    <p>{incomeData.summary.chargePartAmount || 0}</p>
                                </ContentItem>
                                <ContentItem>
                                    <span>配件提成</span>
                                    <p>{incomeData.summary.sparePartsSalesAmount || 0}</p>
                                </ContentItem>
                            </Contentdiv>
                            <Divider style={{ margin: "16px 0" }} />
                        </div>
                        <div className="list-container">
                            <div>
                                <div
                                    className="list-header"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Radio.Group defaultValue="d">
                                        <Radio.Button value="d" onClick={() => setOrder("")}>
                                            全部
                                        </Radio.Button>
                                        <Radio.Button value="014002" onClick={() => setOrder("014002")}>
                                            三包
                                        </Radio.Button>
                                        <Radio.Button value="014001" onClick={() => setOrder("014001")}>
                                            收费
                                        </Radio.Button>
                                        <Radio.Button value="014006" onClick={() => setOrder("014006")}>
                                            大包
                                        </Radio.Button>
                                    </Radio.Group>
                                    <Button
                                        shape="round"
                                        size="small"
                                        className="team-but-left"
                                        onClick={async () => {
                                            if (incomeData.detail.records.length === 0) {
                                                message.warning("无可导出数据")
                                                return
                                            }
                                            setExportLoading(true)
                                            // let data = {
                                            //     type: "post",
                                            //     data: {
                                            //         startTime,
                                            //         endTime,
                                            //         searchItem: {
                                            //             // teamAttribute,
                                            //             orderType,
                                            //         },
                                            //     },
                                            // }
                                            let data = {
                                                type: "post",
                                                data: {
                                                    startTime: startTime + 'T00:00:00',
                                                    endTime: endTime + 'T23:59:59',
                                                    pageNum: pagination.current,
                                                    pageSize: 10,
                                                    searchItem: {
                                                        orderType,
                                                        userId: rowInfo.userId,
                                                    },
                                                },
                                            }
                                            let resFlie = await download("/ci/team_user/incomeAnalysis/detail/export", data)
                                            if (resFlie && resFlie.status === 200) {
                                                message.success("已导出")
                                                convertRes2Blob(resFlie)
                                            } else {
                                                message.warning("导出异常，请重新尝试")
                                            }
                                            setExportLoading(false)
                                        }}
                                    >
                                        导出
                                    </Button>
                                </div>
                                <Table
                                    loading={loading}
                                    dataSource={incomeData.detail.records.map((item) => {
                                        return {
                                            key: item.orderCode,
                                            ...item,
                                        }
                                    })}
                                    onChange={onTableChange}
                                    pagination={{
                                        showQuickJumper: true,
                                        showSizeChanger: false,
                                        showTotal: () => `共${pagination.total}条`,
                                        position: ["bottomCenter"],
                                        ...pagination,
                                    }}
                                    columns={columns}
                                    size="small"
                                />
                            </div>
                        </div>
                        {exportLoading && <Loading />}
                    </>
                </div>
            </Modal>
        </>
    )
}

const Contentdiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`

const ContentItem = styled.div`
    width: 15%;
    border: 1px solid rgba(98, 98, 98, 0.2);
    text-align: center;
    align-self: center;
    padding: 20px 0;
    margin: 10px 2%;

    span {
        color: rgba(0, 0, 0, 0.45);
        display: inline-block;
        font-size: 14px;
        margin-bottom: 4px;
    }

    p {
        color: rgba(0, 0, 0, 0.65);
        font-size: 24px;
        margin: 0;
    }
`
