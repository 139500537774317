import React, { useContext, useState, useEffect } from "react"
import {Table,Button} from 'antd'
import TopSearch from "@/components/TopSearch"
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import { moniToring, moniToringTotal,moniToringExport } from "@/service/statistics"
import { MyContext } from "@/store/myContext"
import moment from "moment"
import { message } from "antd"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { formatNum } from '@/erp_subpackage/utils/util';
import useGlobalModel,{transPgToPagination} from "@/erp_subpackage/model/globalModel";
import {YhBox} from '@/erp_subpackage/components/YhBox'


export default () => {
    const { state,Option } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [statusList,setStatusList]=useState([])//单据状态列表·
    const { user: { shopId, shopCode, shopName, organType, orgCode, orgName },fetchFmsShopList,setMaterialInfo} = useGlobalModel()
    const [searchValue, setSearchValue] = useState({
        // startTime: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
        // endTime: moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss"),
    })
     //分页参数
     const initPgCfg = { pageSize: 10, current: 1 }
     const [pgCfg, setPgCfg] = useState(initPgCfg);
    const [shopList, setShopList] = useState([])
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => []);
    // const [pagination, setpagination] = useState({
    //     //分页配置
    //     total: 0,
    //     current: 1,
    //     pageSize: 10,
    // })
    const initdesc = {
        costAmountDiff:0,
        dreceivedAmountDiff:0,
        totalAmountDiff:0,
        receivedMaterialAmountDiff:0,
        receivedItemAmountDiff:0,
        amountDiff:0,
        itemAmountDiff:0,
        numDiff:0,
        itemNumDiff:0,
    }
    const [order, setOrder] = useState({})
    const [descTotal,setDescTotal,] =useState(initdesc)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (state.activeMenu === "monitoringStatistics") {
            loadData()
            getOrderType()
        }

    }, [state.activeMenu]) 

    useEffect(() => {
        fetchFmsShopList('').then(arr => {
            setShopList(arr)  
        })
    }, [])

    //列表数据
    const loadData = async(page,search = searchValue) => {
       
        setLoading(true)
        let data = {
             ...search,
             pageSize: 5,
             pageNum:1,
            shopCode,
            shopId
        }
        let {retData} =  await moniToring(data)
        let {records,...pg} =retData
        setList(records)
        setPgCfg(transPgToPagination(pg));
        setLoading(false)
        moniToringTotal(data).then(({retData:totalVo})=>{
            setDescTotal(totalVo)  
        })
    }
    //导出
    const handleExport = (search=searchValue,page) => {
        const searchParams = {
            searchItem:{
                ...search,
                shopId: search.shopId ?? shopId,
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
                pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
               
            },
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
        }
        moniToringExport(searchParams).then((res) => {
            message.success(res.retData)
        })
    }
    
    // 过滤掉状态为空的单据类型
    const getOrderType =async()=>{
       let list= getSelectedDictOne(DictCodeEnmu.TRADE_TYPE).filter(item=>
        (
        item.value==='trade-type.sale'||
        item.value==='trade-type.sale-return'   ||
        item.value==='trade-type.maintenance'   ||
        item.value==='trade-type.purchase'      ||
        item.value==='trade-type.purchase-return'||
        item.value==='trade-type.maintenance-return'  )
       )
      await setStatusList(list)
      
    }  
     //列表button
         const topButtons = <>
         <Button
             className="btn-item"
             shape="round"
             onClick={() => handleExport()}
         >导出</Button>
     </>


    //table配置
    const columns = [
        { title: '序号', width: 50, fixed: "left", align: "center", render: (t, r, i) => i + 1 },
        { title: "单据编号", width: 200, fixed: "left", dataIndex: "code" ,render:(text)=><YhTooltip text={text}/>},
        {
            title: "主单",
            className: "main",
            children: [
                { title: "单据状态", width: 160, dataIndex: "status", render: text => getTreeNodeName(text) },
                { title: "单据类型", width: 160, dataIndex: "tradeTypeName" ,render:(text)=><YhTooltip text={text}/>},
                { title: "工时数量", width: 160, dataIndex: "itemNum" },
                { title: "配件数量", width: 160, dataIndex: "num" },
                { title: "工时费", width: 120, dataIndex: "itemAmount" },
                { title: "配件费", width: 120, dataIndex: "amount" },
                { title: "优惠后工时", width: 120, dataIndex: "receivedItemAmount" },
                { title: "优惠后配件", width: 160, dataIndex: "receivedMaterialAmount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "费用合计", width: 120, dataIndex: "totalAmount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "优惠金额", width: 120, dataIndex: "discountTotalAmount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "营业额", width: 120, dataIndex: "receivedAmount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "销售成本", width: 120, dataIndex: "costAmount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "营业额差异", width: 120, dataIndex: "receivedAmountDiff", render: (text) => text ? '￥' + text : '￥0' },
                { title: "毛利差异", width: 120, dataIndex: "masterProfitDiff", render: (text) => text ? '￥' + text : '￥0' },
                { title: "费用合计差异", width: 120, dataIndex: "totalAmountDiff", render: (text) => text ? '￥' + text : '￥0' },
            ]
        }, {
            title: "明细",
            children: [
                { title: "工时数量", width: 160, dataIndex: "ditemNum" },
                { title: "配件数量", width: 120, dataIndex: "dnum" },
                { title: "工时费", width: 120, dataIndex: "ditemAmount" },
                { title: "配件费", width: 160, dataIndex: "damount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "工时小计", width: 120, dataIndex: "dreceivedItemAmount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "配件小计", width: 120, dataIndex: "dreceivedMaterialAmount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "销售成本", width: 120, dataIndex: "dcostAmount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "销售毛利", width: 120, dataIndex: "dprofit", render: (text) => text ? '￥' + text : '￥0' },
            ]
        }, {
            title: "主单对应明细",
            children: [
                { title: "工时数差异", width: 160, dataIndex: "itemNumDiff" },
                { title: "配件数差异", width: 120, dataIndex: "numDiff" },
                { title: "工时费差异", width: 120, dataIndex: "itemAmountDiff" },
                { title: "配件费差异", width: 120, dataIndex: "amountDiff", render: (text) => text ? '￥' + text : '￥0' },
                { title: "优惠后工时差异", width: 120, dataIndex: "receivedItemAmountDiff", render: (text) => text ? '￥' + text : '￥0' },
                { title: "优惠后配件差异", width: 120, dataIndex: "receivedMaterialAmountDiff", render: (text) => text ? '￥' + text : '￥0' },
                { title: "费用合计差异", width: 120, dataIndex: "dtotalAmountDiff", render: (text) => text ? '￥' + text : '￥0' },
                { title: "营业额差异", width: 120, dataIndex: "dreceivedAmountDiff", render: (text) => text ? '￥' + text : '￥0' },
                { title: "销售成本差异", width: 120, dataIndex: "costAmountDiff", render: (text) => text ? '￥' + text : '￥0' },
            ]
        }
       
    ]
       //搜索JSON
       const searchList = {
        normalList: [
            
            {
                type: 'input',
                placeholder: '单据编号',
                valueName: 'code',
            },
            {
                type: 'select',
                placeholder: '单据类型',
                valueName: 'tradeTypeCode',
                optionList: () =>  
                statusList.map((item) =>
                    (<Option key={item.key} value={item.value}>{item.label}</Option>) 
                )
                   
                
            },
            {
                type:'select',
                placeholder: "状态(请先选择单据类型)",
                valueName: "status",
                mode: "multiple",
                optionList:()=>{
                    if(searchValue.tradeTypeCode=='trade-type.sale'){
                        return getSelectedDictOne(DictCodeEnmu.SALE).map((item) =>(<Option key={item.key} value={item.value}>{item.label}</Option>)) 
                    }
                    if(searchValue.tradeTypeCode=='trade-type.sale-return'){
                        return getSelectedDictOne(DictCodeEnmu.SALE_RETURN_STATUS).map((item) =>(<Option key={item.key} value={item.value}>{item.label}</Option>)) 
                    }
                    if(searchValue.tradeTypeCode=='trade-type.maintenance-return'){
                        return getSelectedDictOne(DictCodeEnmu.MAINTENANCE_RETURN).map((item) =>(<Option key={item.key} value={item.value}>{item.label}</Option>)) 
                    }
                    if(searchValue.tradeTypeCode=='trade-type.purchase-return'){
                        return getSelectedDictOne(DictCodeEnmu.PURCHASE_RETURN).map((item) =>(<Option key={item.key} value={item.value}>{item.label}</Option>)) 
                    }
                    if(searchValue.tradeTypeCode=='trade-type.purchase'){
                        return getSelectedDictOne(DictCodeEnmu.PURCHASE).map((item) =>(<Option key={item.key} value={item.value}>{item.label}</Option>)) 
                    }
                    if(searchValue.tradeTypeCode=='trade-type.maintenance'){
                        return getSelectedDictOne(DictCodeEnmu.MAINTENANCE).map((item) =>(<Option key={item.key} value={item.value}>{item.label}</Option>)) 
                    }
                    else{
                        return []
                    }
                }
                
            },

        ],
        
    }
    const desc = [
        {
            label: "工时数差异",
            value: descTotal.itemNumDiff ?? 0,
            color: descTotal.itemNumDiff != 0 ? 'green' : 'red',
        },
        {
            label: "配件数差异",
            value: descTotal.numDiff ?? 0,
            color: descTotal.numDiff != 0 ? 'green' : 'red',
            
        },
        {
            label: "工时费差异",
            value: (descTotal.itemAmountDiff ?? 0).toFixed(2),
           color: 'red',
           
        },
        {
            label: "配件费差异",
            value: (descTotal.amountDiff ?? 0).toFixed(2),
            color: 'red',
       
        },
        {
            label: "优惠后工时差异",
            value: (descTotal.receivedItemAmountDiff ?? 0).toFixed(2),
            color: 'red',
          
        },
        {
            label: "优惠后配件差异",
            value: (descTotal.receivedMaterialAmountDiff ?? 0).toFixed(2),
           color:'red',
           
        },
        {
            label: "费用合计差异",
            value: (descTotal.totalAmountDiff ?? 0).toFixed(2),
            color: 'red',
         
        },
        {
            label: "营业额差异",
            value: (descTotal.dreceivedAmountDiff ?? 0).toFixed(2),
            color: 'red',
          
        },
        {
            label: "销售成本差异",
            value: (descTotal.costAmountDiff ?? 0).toFixed(2),
            color: 'red',
           
        },
        
    ]

    return (
        <>
            {state.activeMenu === "monitoringStatistics" && (
                <>
                    <TopSearch
                        title="业务数据监控"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                        defaultSearch={{
                        }}
                    />
                    <YhBox title="业务数据监控" descriptions={desc}   style={{marginTop:20}} action={topButtons}>
                    <Table
                  
                        columns={columns}
                        dataSource={list}
                        pagination={ 
                            {   ...pgCfg,
                                showSizeChanger:true,
                                position: ["bottomCenter"],
                                showTotal: () => `共${pgCfg.total}条`,
                                onChange: (pageNum,pageSize) => {
                                    loadData( {pageNum,pageSize})
                                }
                            }
                        }
                       // onTableChange={onTableChange}
                        scroll={{ x: "max-content" }}
                        loading={loading}
                    />
                    </YhBox>
                </>
            )}
        </>
    )
}