import {
    post,
    get
} from "../utils/request"

/**
 * 数据字典
 */
//获取字典数据
function getDictList() {
    return post("/ci/dict/list/all")
}

//字典新增一条数据
function dictInsert(data) {
    return post("/ci/dict/insert", data)
}

//获取异步初始父级
function asyncDictParent() {
    return get("/ci/dict/async_load")
}
//异步加载数据
function asyncDictData(parentCode) {
    return get(`/ci/dict/async_load/${parentCode}`)
}
//删除一条数据
function deleteDictOne(id) {
    return get(`/ci/dict/delete/${id}`)
}

//获取字典全部
function getDictAll() {
    return get(`/ci/dict/tree`)
}
//数据字典修改
function editDict(data) {
    return post("/ci/dict/update", data)
}
//禁用
function disableDict(data) {
    return post(`/ci/dict/disable/`, data)
}

//获取系统配置信息
function getConfType() {
    return get(`/ci/sys/conf/types`)
}

//获取静态资源展示地址
function getStaticResources() {
    return get(`/ci-file-server/domain`)
}

/** 维修项目*/

//获取维修项目数据
function getMaintenanceTemplateList(data) {
    return post("/ci/maintenance_template/list", data)
}

//获取维修项目一条数据
function maintenanceTemplateOne(id) {
    return get(`/ci/maintenance_template/one/${id}`)
}

//新增
function maintenanceTemplateListInsert(data) {
    return post("/ci/maintenance_template/insert", data)
}

//修改
function maintenanceTemplateListUpdate(data) {
    return post("/ci/maintenance_template/update", data)
}

//删除
function maintenanceTemplateListDelete(id) {
    return post(`/ci/maintenance_template/delete/${id}`)
}

// appbannerset
//获取列表
function getAppBannerList(data) {
    return post("/ci-customer/app/banner/list", data)
}

//获取一条数据
function appBannerOne(id) {
    return get(`/ci-customer/app/banner/one/${id}`)
}

//新增
function appBannerInsert(data) {
    return post("/ci-customer/app/banner/insert", data)
}

//修改
function appBannerUpdate(data) {
    return post("/ci-customer/app/banner/update", data)
}

//删除
function appBannerDelete(id) {
    return get(`/ci-customer/app/banner/delete/${id}`)
}

// 服务定价
//获取列表
function getServiceOriceList(data) {
    return post("/ci-platform/organ_service_price/page", data)
}

//查询一条
function serviceOriceOne(id) {
    return get(`/ci-platform/organ_service_price/one/${id}`)
}

//新增
function serviceOriceInsert(data) {
    return post("/ci-platform/organ_service_price/insert", data)
}

//修改
function serviceOriceUpdate(data) {
    return post("/ci-platform/organ_service_price/update", data)
}

//删除
function serviceOriceDelete(id) {
    return get(`/ci-platform/organ_service_price/delete/${id}`)
}

// 经营成本
//获取列表
function getBussinessCostList(data) {
    return post("/ci-platform/organ_bussiness_cost/page", data)
}

//查询一条
function bussinessCostOne(id) {
    return get(`/ci-platform/organ_bussiness_cost/one/${id}`)
}

//新增
function bussinessCostInsert(data) {
    return post("/ci-platform/organ_bussiness_cost/insert", data)
}

//修改
function bussinessCostUpdate(data) {
    return post("/ci-platform/organ_bussiness_cost/update", data)
}
//删除
function bussinessCostDelete(id) {
    return get(`/ci-platform/organ_bussiness_cost/delete/${id}`)
}


// 账目分类
//获取列表
function getBussinessCategoryList(data) {
    return post("/ci-platform/organ_bussiness_cost_category/page", data)
}

//查询一条
function bussinessCategoryOne(id) {
    return get(`/ci-platform/organ_bussiness_cost_category/one/${id}`)
}

//新增
function bussinessCategoryInsert(data) {
    return post("/ci-platform/organ_bussiness_cost_category/insert", data)
}

//修改
function bussinessCategoryUpdate(data) {
    return post("/ci-platform/organ_bussiness_cost_category/update", data)
}
//删除
function bussinessCategoryDelete(id) {
    return get(`/ci-platform/organ_bussiness_cost_category/delete/${id}`)
}



//所属实体
//获取列表
function getBussinessEntityList(data) {
    return post("/ci-platform/business_entity/page", data)
}

//查询一条
function bussinessEntityOne(id) {
    return get(`/ci-platform/business_entity/one/${id}`)
}

//新增
function bussinessEntityInsert(data) {
    return post("/ci-platform/business_entity/insert", data)
}

//修改
function bussinessEntityUpdate(data) {
    return post("/ci-platform/business_entity/update", data)
}
//删除
function bussinesEntityDelete(id) {
    return get(`/ci-platform/business_entity/delete/${id}`)
}

// 车辆品牌

function vehicleBrandTree(data) {
    return post("/ci-customer/vehicle/brand/tree", data)
}

//新增
function vehicleBrandInsert(data) {
    return post("/ci-customer/vehicle/brand/insert", data)
}

//编辑
function vehicleBrandUpdate(data) {
    return post("/ci-customer/vehicle/brand/update", data)
}

//编辑
function vehicleBrandDelete(id) {
    return get(`/ci-customer/vehicle/brand/delete/${id}`)
}



// 故障码

function faultCodePage(data) {
    return post("/ci-platform/fault_code/page", data)
}

//查询一条
function faultCodeOne(id) {
    return get(`/ci-platform/fault_code/one/${id}`)
}

//新增
function faultCodeInsert(data) {
    return post("/ci-platform/fault_code/insert", data)
}

//编辑
function faultCodeUpdate(data) {
    return post("/ci-platform/fault_code/update", data)
}

//删除
function faultCodeDelete(id) {
    return get(`/ci-platform/fault_code/delete/${id}`)
}

// 车辆附件模版管理
function vehicleAttrTemplatePage(data) {
    return post("/ci-customer/vehicle-attr/template/page", data)
}

//查询一条
function vehicleAttrTemplateOne(id) {
    return get(`/ci-customer/vehicle-attr/template/one/${id}`)
}

//新增
function vehicleAttrTemplateInsert(data) {
    return post("/ci-customer/vehicle-attr/template/insert", data)
}

//编辑
function vehicleAttrTemplateUpdate(data) {
    return post("/ci-customer/vehicle-attr/template/update", data)
}

//删除
function vehicleAttrTemplateDelete(id) {
    return get(`/ci-customer/vehicle-attr/template/delete/${id}`)
}

//克隆
function vehicleAttrTemplateClone(id) {
    return get(`/ci-customer/vehicle-attr/template/clone/${id}`)
}


// 系统分类

function classificationList(data) {
    return post("/ci/maintenance/classification/list", data)
}

//查询一条
function classificationOne(id) {
    return get(`/ci/maintenance/classification/one/${id}`)
}

//新增
function classificationInsert(data) {
    return post("/ci/maintenance/classification/insert", data)
}

//编辑
function classificationUpdate(data) {
    return post("/ci/maintenance/classification/update", data)
}

//删除
function classificationDelete(id) {
    return get(`/ci/maintenance/classification/delete/${id}`)
}

//禁用
function classificationDisable(data) {
    return post(`/ci/maintenance/classification/disable`, data)
}


// 维保组合
function combinationList(data) {
    return post("/ci/maintenance/combination/list", data)
}

//查询一条
function combinationOne(id) {
    return get(`/ci/maintenance/combination/one/${id}`)
}

//新增
function combinationInsert(data) {
    return post("/ci/maintenance/combination/insert", data)
}

//编辑
function combinationUpdate(data) {
    return post("/ci/maintenance/combination/update", data)
}

//删除
function combinationDelete(id) {
    return get(`/ci/maintenance/combination/delete/${id}`)
}

//禁用
function combinationDisable(data) {
    return post(`/ci/maintenance/combination/disable`, data)
}


// 维保计划
function combinationPlanList(data) {
    return post("/ci/maintenance/plan/list", data)
}

//查询一条
function combinationOlanOne(id) {
    return get(`/ci/maintenance/plan/one/${id}`)
}

//根据车辆id查询一条
function combinationOlanOnevehicle(vehicleId) {
    return get(`/ci/maintenance/plan/one/vehicle/${vehicleId}`)
}

//新增
function combinationPlanInsert(data) {
    return post("/ci/maintenance/plan/insert", data)
}

//编辑
function combinationPlanUpdate(data) {
    return post("/ci/maintenance/plan/update", data)
}

//删除
function combinationPlanDelete(id) {
    return get(`/ci/maintenance/plan/delete/${id}`)
}

//生成订单
function planGenerateOrder(data) {
    return post(`/ci/maintenance/plan/generate-order`, data)
}

//车辆品牌-新增
function vehicleBrandnameInsert(data) {
    return post(`/ci-platform/vehicle_brandname/insert`, data)
}

//车辆品牌-修改
function vehicleBrandnameUpdate(data) {
    return post(`/ci-platform/vehicle_brandname/update`, data)
}

//车辆品牌-列表
function vehicleBrandnamePage(data) {
    return post(`/ci-platform/vehicle_brandname/page`, data)
}

//车辆品牌-查询一条
function vehicleBrandnameOne(id) {
    return get(`/ci-platform/vehicle_brandname/one/${id}`)
}

//车辆品牌-删除
function vehicleBrandnameDelete(id) {
    return get(`/ci-platform/vehicle_brandname/delete/${id}`)
}

//车辆品牌-二级删除
function vehicleBrandnameDeleteSecond(parentId, brandName) {
    return get(`/ci-platform/vehicle_brandname/deleteSecond?parentId=${parentId}&brandName=${brandName}`)
}

//车辆品牌-分词匹配
function vehicleBrandSegmentQuery(keyword) {
    return get(`/ci-platform/vehicle_brandname/segmentQuery/${keyword}`)
}


export {
    combinationList,
    combinationOne,
    combinationInsert,
    combinationUpdate,
    combinationDelete,
    combinationDisable,
    getDictList,
    dictInsert,
    asyncDictParent,
    asyncDictData,
    deleteDictOne,
    getDictAll,
    editDict,
    disableDict,
    getConfType,
    getStaticResources,
    getMaintenanceTemplateList,
    maintenanceTemplateOne,
    maintenanceTemplateListInsert,
    maintenanceTemplateListUpdate,
    maintenanceTemplateListDelete,
    getAppBannerList,
    appBannerOne,
    appBannerInsert,
    appBannerUpdate,
    appBannerDelete,
    getServiceOriceList,
    serviceOriceOne,
    serviceOriceInsert,
    serviceOriceUpdate,
    serviceOriceDelete,
    getBussinessCostList,
    bussinessCostOne,
    bussinessCostInsert,
    bussinessCostUpdate,
    bussinessCostDelete,
    getBussinessCategoryList,
    bussinessCategoryOne,
    bussinessCategoryInsert,
    bussinessCategoryUpdate,
    bussinessCategoryDelete,
    getBussinessEntityList,
    bussinessEntityOne,
    bussinessEntityInsert,
    bussinessEntityUpdate,
    bussinesEntityDelete,
    vehicleBrandTree,
    vehicleBrandInsert,
    vehicleBrandUpdate,
    vehicleBrandDelete,
    faultCodePage,
    faultCodeInsert,
    faultCodeUpdate,
    faultCodeDelete,
    faultCodeOne,
    vehicleAttrTemplatePage,
    vehicleAttrTemplateOne,
    vehicleAttrTemplateInsert,
    vehicleAttrTemplateUpdate,
    vehicleAttrTemplateDelete,
    vehicleAttrTemplateClone,
    classificationList,
    classificationOne,
    classificationInsert,
    classificationUpdate,
    classificationDelete,
    classificationDisable,
    combinationPlanList,
    combinationOlanOne,
    combinationOlanOnevehicle,
    combinationPlanInsert,
    combinationPlanUpdate,
    combinationPlanDelete,
    planGenerateOrder,
    vehicleBrandnameInsert,
    vehicleBrandnameUpdate,
    vehicleBrandnamePage,
    vehicleBrandnameOne,
    vehicleBrandnameDelete,
    vehicleBrandnameDeleteSecond,
    vehicleBrandSegmentQuery
}