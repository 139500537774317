import React, { useState, useEffect } from "react";
import { createModel } from "hox";
import useGlobalModel, { defaultMdCmParam } from "@/erp_subpackage/model/globalModel";
import { purchaseDrService } from "../../purchaseDrService";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import { message } from "antd";
import { useLockFn } from "ahooks";
import { getUserInfo } from "@/utils/dataStorage"
import { bigNumber,formatNum } from "../../../../../utils/util";

const initDesc = {
    actualAmount: 0,  //实际总价合计 （数量*单价）
    //对应展示字段
    numAll: 0,     //调拨数量
    amountAll: 0,    //调拨总计   （去除下浮）
    totalPreAmount: 0, //实际总价合计 （数量*单价）
}


export const usePurchaseDrDetailsModel = createModel(function () {
    // const { user: { shopId, shopCode, shopName, userName, orgShopCode, orgShopName, } } = useGlobalModel();
    const {
        user: { shopId, shopCode, shopName, userName, orgShopCode },
    } = useGlobalModel()

    const initFormVal = {
        shopCode: orgShopCode ?? shopCode,
        shopName: shopName, shopId,
        branchIn: defaultMdCmParam.branch,
        createUser: userName,
        applier: userName,
        discountTotalAmount: "0.00", //优惠总额
        favorableAmount: "0",    //整单优惠
        aggregateAmount: "0.00",  //应付金额 
        discountRate: "0",    //整单下浮
    }


    //编辑缓存
    const [editFormVal, setEditFormVal] = useState(initFormVal);
    const [editList, setEditList] = useState([]);
    const [editDesc, setEditDesc] = useState(initDesc)
    //新增缓存
    const [addFormVal, setAddFormVal] = useState(initFormVal);
    const [addList, setAddList] = useState([]);
    const [addDesc, setAddDesc] = useState(initDesc);
    //查看缓存
    const [lookFormVal, setLookFormVal] = useState(initFormVal);
    const [lookList, setLookList] = useState([]);
    const [lookDesc, setLookDesc] = useState(initDesc)

    //添加商品弹框
    const [materialDialog, setMaterialDialog] = useState(false);
   // 控制保存按钮
    const [saveBtn, setSaveBtn] = useState(false)
    const [saveBtnLoading, setSaveBtnLoading] = useState(false); //  保存按钮

    useEffect(() => {
        let newDesc = { ...initDesc };
        editList.forEach(item => {
			// newDesc.numAll += Number(item.num);
			newDesc.numAll = +bigNumber.add(item.num,newDesc.numAll);
			// newDesc.amountAll += Number(item.amount);
			newDesc.amountAll = +bigNumber.add(item.amount,newDesc.amountAll);
			// newDesc.actualAmount += Number(item.num) * Number(item.price);
			newDesc.actualAmount = +bigNumber.add(bigNumber.times(item.num,item.price),newDesc.actualAmount);
			// newDesc.totalPreAmount += Number(item.num) * Number(item.prePrice);
			newDesc.totalPreAmount = +bigNumber.add(bigNumber.times(item.num,item.prePrice),newDesc.totalPreAmount);
        });
        setEditDesc(newDesc);
    }, [editList])

    useEffect(() => {
        let newDesc = { ...initDesc };
        addList.forEach(item => {
			// newDesc.numAll += Number(item.num);
			newDesc.numAll = +bigNumber.add(item.num,newDesc.numAll);
			// newDesc.amountAll += Number(item.amount);
			newDesc.amountAll = +bigNumber.add(item.amount,newDesc.amountAll);
			// newDesc.actualAmount += Number(item.num) * Number(item.price);
			newDesc.actualAmount = +bigNumber.add(bigNumber.times(item.num,item.price),newDesc.actualAmount);
			// newDesc.totalPreAmount += Number(item.num) * Number(item.prePrice);
			newDesc.totalPreAmount = +bigNumber.add(bigNumber.times(item.num,item.prePrice),newDesc.totalPreAmount);
        });
        setAddDesc(newDesc);
    }, [addList])

    useEffect(() => {
        setAddFormVal({ ...initFormVal})
    }, [shopId])

    useEffect(() => {
        let newDesc = { ...initDesc };
        lookList.forEach(item => {
            // newDesc.numAll += Number(item.num);
            newDesc.numAll = +bigNumber.add(newDesc.numAll,Number(item.num)) ;
            // newDesc.amountAll += Number(item.amount);
            newDesc.amountAll = +bigNumber.add(newDesc.amountAll,Number(item.amount)) ;
            // newDesc.actualAmount += (Number(item.num) * Number(item.price));
            newDesc.actualAmount += +bigNumber.times(Number(item.num),Number(item.price)).toFixed(2);
        });
        setLookDesc(newDesc);
    }, [lookList])

    //表单修改
    const updateFormVal = (mode, obj) => {
        console.log(obj)
        const setFormVal = mode === "edit" ? setEditFormVal : mode === "look" ? setLookFormVal : setAddFormVal;
        setFormVal(origin => obj ? { ...origin, ...obj } : initFormVal);
    }

    //商品子表修改
    const updateList = (mode, arr = [], isMerge = false) => {
        const setList = mode === 'edit' ? setEditList : mode === "look" ? setLookList : setAddList;
        setList(origin => isMerge ? [...origin, ...arr] : [...arr]);
    };

    //获取一条数据
    const getPDrDetailOne = useLockFn(async (id) => {
        let { retData } = await purchaseDrService.one(id);
        let { details, ...formVal } = retData;
        if (retData) {
            setEditList(() => details.map(item => ({ ...item, key: item.id, isHave: true })))
            setEditFormVal({...formVal, outShopIdShow: `${formVal.outShopId}--${formVal.branchOut}`});
        };
        return retData;
    });

    //表单商品校验
    const verifyGoods = (mode) => {
        let goods = mode === "edit" ? editList : addList;
        if (!goods.length) {
            message.warning("请添加商品明细");
            return false;
        }
        let filterArr = goods.filter(item => !item.inWarehouseCode || !item.inShelfCode);
        if (filterArr.length) {
            message.warning(`【${filterArr[0].materialName}】请选择调入仓库和调入货位`);
            return false;
        }
        let zeroNum = goods.filter(item => !(+item.num));
        if (zeroNum.length) {
            message.warning(`【${zeroNum.map(item => item.materialName).join()}】商品的开单数量不能为0`)
            return false;
        }
        return true;
    }

    //新增 Api
    const insertPDr = useLockFn(async (val) => {
        if (val.shopCode === val.outShopCode) {
            message.warning("调入门店和调出门店不能为同一个");
            return false;
        }
        // if (!verifyGoods("add")) return false;
        if(addList.length==0){
            message.warning(`未添加商品明细不可生成调入单`)
            return
        } 
        setSaveBtnLoading(true)
        let data = {
            ...val,
            id: undefined,
            details: addList.map(item => { let { key, id, inventoryQuantity, ...params } = item; return params }),
            inAndOut: "0",
            discountRate: 0,
            favorableAmount: 0,
            createUser: getUserInfo()?.nickname,
            totalPreAmount:formatNum(addDesc['totalPreAmount'])
        }
        let { retData, retMsg } = await purchaseDrService.insert(data);
        setSaveBtnLoading(false)
        setSaveBtn(false)
        message.success("新增成功");
        return retData;
    });

    //编辑 Api
    const updatePDr = useLockFn(async (val) => {
        // if (!verifyGoods("edit")) return false;
        if(editList.length==0){
            message.warning(`未添加商品明细不可生成调入单`)
            return
        } 
        setSaveBtnLoading(true)
        let data = {
            ...val,
            id: editFormVal.id,
            discountRate: 0,
            favorableAmount: 0,
            updateUser: userName,
            details: editList.map(item => { let { key, ...params } = item; return params }),
            totalPreAmount:formatNum(editDesc['totalPreAmount'])
        }
        let { retData } = await purchaseDrService.update(data);
        getPDrDetailOne(editFormVal.id);
        setSaveBtnLoading(false)
        setSaveBtn(false)
        message.success("调入单编辑成功");
    })



    //商品子表添加
    const insertMaterial = async (mode, materialArr) => {
        const formVal = mode === "edit" ? editFormVal : addFormVal;
        try {
            const setList = mode === 'edit' ? setEditList : mode === "look" ? setLookList : setAddList;
            const list = mode === 'edit' ? editList : mode === "look" ? lookList : addList;
            const newArr = [...list];
            const repetition = [];
            let codeList = []
            let goodsList = materialArr
            // let branchIn = formVal.branchIn === 'scm' ? 'scm2' : formVal.branchIn === 'vrm' ? 'vrm2' :  formVal.branchIn === 'ci' ? 'ci-storage' : 'bcm'
            let branchIn = ''
            switch(formVal.branchIn){
                case 'scm':
                    branchIn = 'scm2'
                    break;
                case 'vrm':
                    branchIn = 'vrm2'
                    break;
                case 'ci':
                    branchIn = 'ci-storage'
                    break;
                default:
                    branchIn = 'bcm'
                    break;
            }
            let inShopId = formVal.shopId ?? shopId
            goodsList.forEach((item) => {
                codeList.push(item.materialCode)
            })
            if(codeList.length === 0){
                setMaterialDialog(false);
                return
            }
            let data = {
                shopId: inShopId,
                checkoutMaterialCodeList: codeList,
                existMaterialCodeList: [],
                notExistMaterialCodeList: [],
            }
            let { retData } = branchIn === 'scm2' ? await purchaseDrService.checkScm(branchIn, data) : await purchaseDrService.check(branchIn, data)
            let { existMaterialCodeList } = retData
            for (let i = 0; i < materialArr.length; i++) {
                let material = materialArr[i];
                // let isExist = newArr.find((item) => item.purchaseDetailsId === material.id);
                // if (!isExist) {
                    // let { retData } = await purchaseDrService.getMaterialShelf({
                    //     shopId,
                    //     materialCode: material.materialCode
                    // })
                    // retData = retData ?? {};
                    // console.log(retData)
                    newArr.push({
                        isHave: existMaterialCodeList.indexOf(material.materialCode) > -1,
                        id: "",
                        key: material.id,   //
                        purchaseDetailsId: material.id,
                        materialCode: material.materialCode,
                        materialName: material.materialName,
                        replaceCode: material.replaceCode,
                        typeCode: material.typeCode,
                        typeName: material.typeName,
                        unitCode: material.unitCode,
                        unitName: material.unitName,
                        brandCode: material.brandCode,
                        brandName: material.brandName,
                        num: material.pDrNum,
                        purchaseCode: material.purchaseCode, //采购单号
                        price: material.price ?? 0,  //进货价          
                        prePrice:material.price ?? 0, //折前单价
                        preAmount: +material.pDrNum * +(material.price ?? 0), //总计
                        costPrice: material.price ?? 0,  //   成本单价       
                        favorableAmount: "0",  //单品优惠      
                        discountRate: "0",     //下浮            
                        amount: +material.pDrNum * +(material.price ?? 0), //总计                   
                        outShelfId: material.shelfId,
                        outShelfCode: material.shelfCode,
                        outWarehouseName: material.warehouseName,
                        outWarehouseCode: material.warehouseCode,
                        // inWarehouseCode: retData?.warehouseCode,
                        // inWarehouseName: retData?.warehouseName,
                        // inShelfCode: retData?.shelfCode,
                        // inShelfId: retData?.shelfId,
                        inWarehouseCode: material.inWarehouseCode,
                        inWarehouseName: material.inWarehouseName,
                        inShelfCode: material.inShelfCode,
                        inShelfId: material.inShelfId,
                        note: "",
                        inventoryNum: material.inventoryQuantity,   //记录临时库存数量
                        bussType:material.bussType??''
                    });
                // } else {
                //     repetition.push(material.name ?? material.materialName);
                // }
            }
            setList(newArr);
            setMaterialDialog(false);
            !!repetition.length && message.warning(`商品名称：【${repetition.join(",")}】，重复采购单号配件的已过滤。`);
            return newArr
        } catch (err) {
            console.error(err)
            return null
        }
    };

    //商品子表删除、编辑模式下，已有id的需要进行数据库删除 (单条及批量删除)
    const removeMaterial = useLockFn(async (mode, selectedRowKeys) => {
        // if (mode === 'edit') {
        //     let ids = editList.filter(item => selectedRowKeys.indexOf(item.materialCode ?? '') >= 0 && item.id).map(item => ({ id: item.id }));
        //     //存在id在跑接口进行数据库删除
        //     if (ids.length) {
        //         let { retData } = await purchasePoService.materialDelete({ ids, purchaseCode: editFormVal.purchaseCode });
        //         if (!retData) return retData;
        //     }
        // }
        const setList = mode === 'edit' ? setEditList : setAddList;
        setList((origin) => origin.filter((item) => selectedRowKeys.indexOf(item?.key ?? '') < 0));
        message.success("删除成功");
    });

    //调入
    const inWhConfirm = useLockFn(async (val) => {
        if (!verifyGoods("edit")) return false;
        let obj = {
            inAndOut: "0",
            id: editFormVal.id,
            status: editFormVal.status,
            transferIn: userName,
            transferOut: editFormVal.transferOut,
            transferOutTime: editFormVal.transferOutTime,//调出时间  
            transferInTime: editFormVal.transferInTime,//调入时间
            createUser: userName,
            transferOutCode: editFormVal.transferOutCode,
            transferInCode: editFormVal.transferInCode,
        }
        let data = {
            // ...editFormVal,
            ...val,
            aggregateAmount: editFormVal.aggregateAmount,
            id: editFormVal.id,
            transferIn: userName,
            inAndOut: "0",
            details: editList.map(item => { let { key, ...params } = item; return params })
        }
        let params = {
            ...val,
            id: editFormVal.id,
            discountRate: 0,
            favorableAmount: 0,
            updateUser: userName,
            details: editList.map(item => { let { key, ...params } = item; return params })
        }
        let { retData: form } = await purchaseDrService.update(params);
        let { retData } = await purchaseDrService.whConfirmCi(data);
        if (retData) {
            message.success("调入成功");
            getPDrDetailOne(editFormVal.id);
        }
    });

    //明细下载模板
    const pDrDownload = async () => {
        let result = await purchaseDrService.downloadTpl();
        convertRes2Blob(result);
    }

    //导出明细
    const exportPDrdetail = async () => {
        let result = await purchaseDrService.exportDetail(editFormVal?.code ?? "");
        convertRes2Blob(result);
    }

    /**
    * 打印
    */
    const [print, setPrint] = useState(false);

    //各门店库存
    const [stockVisible, setStockVisible] = useState(false);
    return {
        editFormVal,
        addFormVal,
        lookFormVal,
        lookList, addList, editList,
        setAddList,setEditList,
        addDesc, editDesc, lookDesc,
        updateFormVal,
        updateList,
        insertPDr,
        updatePDr,
        getPDrDetailOne,
        pDrDownload,
        exportPDrdetail,
        verifyGoods,
        print, setPrint,
        inWhConfirm,
        materialDialog, setMaterialDialog,
        removeMaterial,
        insertMaterial,
        saveBtn,
        setSaveBtn,
        stockVisible, setStockVisible,  setSaveBtnLoading,saveBtnLoading
    }
})