import React, { useEffect, useState } from 'react'
import { Table, Button, Tooltip, Input, Checkbox, Radio, Space } from 'antd'
import { YHModal } from '@/erp_subpackage/components/YHModal'
import { YhBox } from '@/erp_subpackage/components/YhBox'
import { momentFormat } from '@/erp_subpackage/utils/util'
import { commonService } from '../commonService'
import { ShopStock } from './ShopStock'
import { maintainOrderMateriallHistory } from '@/service/ordersService'
import YhTooltip from '@/erp_subpackage/components/YhTooltip'
import { IsFranchisees } from '@/utils/common'
//商品采销历史 通用查询弹框
export const MaterialHistory = ({
    visible,
    materialCode,
    materialName,
    onCancel,
    shopId,
    customerName: chooseCustomerName = '',
    customerCode: chooseCustomerCode = '',
}) => {
    const [dataSource, setDataSource] = useState([])
    //tab类型 purchase、sale、allShop
    const [type, setType] = useState('purchase')
    const [loading, setLoading] = useState(false)
    const [checked, setChecked] = useState(false)
    const [chooseCustomer, setChooseCustomer] = useState('')
    const [vrmChecked, setVrmChecked] = useState(false)
    const [vrmChooseCustomer, setVrmChooseCustomer] = useState('')
    const [chooseRepairCustomer, setChooseRepairCustomer] = useState('')
    useEffect(() => {
        // setChooseCustomer(chooseCustomerName)
        // setVrmChooseCustomer(chooseCustomerName)
        setChecked(false)
        setVrmChecked(false)
    }, [visible])
    const action = (
        <>
            <Button
                className={`mr-5 btn-item ${type === 'purchase' ? 'tab-buts-active' : ''}`}
                shape="round"
                onClick={() => {
                    getStockStatistic('purchase')
                    setType('purchase')
                }}
            >
                采购历史
            </Button>
            <Button
                className={`mr-5 btn-item ${type === 'sale' ? 'tab-buts-active' : ''}`}
                shape="round"
                onClick={() => {
                    getStockStatistic('sale')
                    setType('sale')
                }}
            >
                销售历史
            </Button>
            {!IsFranchisees() && (
                <Button
                    className={`mr-5 btn-item ${type === 'allShop' ? 'tab-buts-active' : ''}`}
                    shape="round"
                    onClick={() => {
                        setType('allShop')
                    }}
                >
                    各门店库存
                </Button>
            )}
            <Button
                className={`mr-5 btn-item ${type === 'repairHitory' ? 'tab-buts-active' : ''}`}
                shape="round"
                onClick={() => {
                    getRepairHistoryData()
                    setType('repairHitory')
                }}
            >
                维修历史
            </Button>
            {!IsFranchisees() && (
                <Button
                    className={`mr-5 btn-item ${type === 'purchaseHistory' ? 'tab-buts-active' : ''}`}
                    shape="round"
                    onClick={() => {
                        getStockStatistic('purchaseHistory')
                        setType('purchaseHistory')
                    }}
                >
                    云修采购历史
                </Button>
            )}
            {!IsFranchisees() && (
                <Button
                    className={`mr-5 btn-item ${type === 'saleHistory' ? 'tab-buts-active' : ''}`}
                    shape="round"
                    onClick={() => {
                        getStockStatistic('saleHistory')
                        setType('saleHistory')
                    }}
                >
                    云修销售历史
                </Button>
            )}
            {type === 'sale' && (
                <div className="flex justify-center" style={{ float: 'right' }}>
                    <span style={{ fontSize: '14px', lineHeight: '32px' }}>客户名称：</span>
                    <Input
                        style={{ width: 260 }}
                        placeholder="请输入客户名称"
                        value={chooseCustomer}
                        onChange={(e) => {
                            setChecked(true)
                            setChooseCustomer(e.target.value)
                            getStockStatistic('sale', e.target.value)
                        }}
                    />
                    {chooseCustomerName !== '' && (
                        // <Checkbox style={{marginLeft:'25px',lineHeight: '32px'}} checked={checked} onChange={(e) => {setChooseCustomer(''); setChecked(e.target.checked);getStockStatistic('sale', e.target.checked) }}>
                        //     所有单位
                        // </Checkbox>
                        <Radio.Group
                            value={checked}
                            style={{ marginLeft: '25px' }}
                            onChange={(e) => {
                                setChooseCustomer('')
                                setChecked(e.target.value)
                                getStockStatistic('sale', e.target.value)
                            }}
                        >
                            <Space direction="vertical">
                                <Radio value={false}>本单客户</Radio>
                                <Radio value={true}>所有客户</Radio>
                            </Space>
                        </Radio.Group>
                    )}
                </div>
            )}
            {type === 'saleHistory' && (
                <div className="flex justify-center" style={{ float: 'right' }}>
                    <span style={{ fontSize: '14px', lineHeight: '32px' }}>客户名称：</span>
                    <Input
                        style={{ width: 260 }}
                        placeholder="请输入客户名称"
                        value={vrmChooseCustomer}
                        onChange={(e) => {
                            setVrmChecked(true)
                            setVrmChooseCustomer(e.target.value)
                            getStockStatistic('saleHistory', e.target.value)
                        }}
                    />
                    {chooseCustomerName !== '' && (
                        // <Checkbox style={{marginLeft:'25px',lineHeight: '32px'}} checked={vrmChecked} onChange={(e) => {setVrmChooseCustomer(''); setVrmChecked(e.target.checked);getStockStatistic('saleHistory', e.target.checked) }}>
                        //     所有单位
                        // </Checkbox>
                        <Radio.Group
                            value={vrmChecked}
                            style={{ marginLeft: '25px' }}
                            onChange={(e) => {
                                setVrmChooseCustomer('')
                                setVrmChecked(e.target.value)
                                getStockStatistic('saleHistory', e.target.value)
                            }}
                        >
                            <Space direction="vertical">
                                <Radio value={false}>本单客户</Radio>
                                <Radio value={true}>所有客户</Radio>
                            </Space>
                        </Radio.Group>
                    )}
                </div>
            )}
            {type === 'repairHitory' && (
                <div className="flex justify-center" style={{ float: 'right' }}>
                    <span style={{ fontSize: '14px', lineHeight: '32px' }}>客户名称：</span>
                    <Input
                        style={{ width: 260 }}
                        placeholder="请输入客户名称"
                        value={chooseRepairCustomer}
                        onChange={(e) => {
                            setChooseRepairCustomer(e.target.value)
                            getRepairHistoryData(e.target.value)
                        }}
                    />
                </div>
            )}
        </>
    )

    useEffect(() => {
        if (visible) {
            setType('purchase')
            getStockStatistic('purchase')
        }
    }, [visible])

    //获取商品的采效历史
    const getRepairHistoryData = async (value) => {
        let params = {
            searchItem: {
                materialCode,
                customerName: value ?? '',
            },
            pageNum: 1,
            pageSize: 1000,
            orders: [
                {
                    column: 'createTime',
                    rule: 'DESC',
                },
            ],
        }
        const {
            retData: { list },
        } = await maintainOrderMateriallHistory(params)
        setDataSource(list)
    }

    const numFlage = (orderType, num) => {
        switch (orderType) {
            case '采购':
                return num
            case '采购退货':
                return -num
            case '销售':
                return num
            case '维修':
                return num
            case '销售退单':
                return -num
            case '维修退单':
                return -num
            default:
                return
        }
    }

    //获取商品的采效历史
    const getStockStatistic = async (historyType, customerName) => {
        // let params = {
        //     pageSize: 1000,
        //     pageNum: 1,
        //     tradeTypeCode: type,
        //     materialCode,
        //     shopId,
        // }
        // let { retData } = await commonService.detailsFindPage(params)
        // const { records } = retData
        // setDataSource(records)

        setLoading(true)
        let params = {
            pageSize: 1000,
            pageNum: 1,
            materialCode,
            shopId,
            customerName,
            orders: [
                {
                    column: 'createTime',
                    rule: 'DESC',
                },
            ],
        }
        let { retData } =
            historyType === 'purchase'
                ? await commonService.purchaseHistory(params)
                : historyType === 'sale'
                ? await commonService.saleHistory({
                      ...params,
                      customerName: customerName && customerName !== true ? customerName : '',
                      customerCode: customerName ? '' : chooseCustomerCode,
                  })
                : historyType === 'purchaseHistory'
                ? await commonService.vrmPurchaseHistory(params)
                : await commonService.vrmSaleHistory({
                      ...params,
                      customerName: customerName && customerName !== true ? customerName : '',
                      customerCode: customerName ? '' : chooseCustomerCode,
                  })
        const { records } = retData
        let newRecords = records.map((item) => {
            return {
                ...item,
                historyName: historyType === 'purchase' || historyType === 'purchaseHistory' ? item?.supplierName : item?.customerName,
            }
        })
        setDataSource(newRecords)
        setLoading(false)
    }

    const columns = [
        {
            title: '序号',
            width: 40,
            fixed: 'left',
            render: (t, r, i) => i + 1,
        },
        {
            title: '单据编号',
            width: 160,
            fixed: 'left',
            dataIndex: 'orderCode',
            render: (text) => <YhTooltip text={text} />,
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.orderCode.localeCompare(b.orderCode),
        },
        {
            title: '单据类型',
            width: 160,
            fixed: 'left',
            dataIndex: 'orderType',
        },
        {
            title: '制单时间',
            width: 160,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text),
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.createTime && b.createTime && a.createTime.localeCompare(b.createTime),
        },
        {
            title: type === 'purchase' || type === 'purchaseHistory' ? '供应商名称' : '客户名称',
            width: 160,
            dataIndex: 'historyName',
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                    <p className="one-line">{text}</p>
                </Tooltip>
            ),
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.historyName.localeCompare(b.historyName),
        },
        {
            title: '单价',
            width: 120,
            dataIndex: 'price',
        },
        {
            title: '数量',
            width: 80,
            dataIndex: 'num',
        },
        {
            title: '折扣',
            width: 80,
            dataIndex: 'discountRate',
            render: (text) => text + '%',
        },
        {
            title: '总计',
            width: 80,
            dataIndex: 'amount',
        },
    ]

    const repairHitoryColumns = [
        {
            title: '序号',
            width: 40,
            fixed: 'left',
            render: (t, r, i) => i + 1,
        },
        {
            title: '订单编号',
            width: 160,
            dataIndex: 'orderCode',
            render: (text) => <YhTooltip text={text} />,
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.orderCode.localeCompare(b.orderCode),
        },
        type === 'repairHitory' && {
            title: '车辆牌照',
            width: 100,
            dataIndex: 'vehicleCode',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.vehicleCode && b.vehicleCode && a.vehicleCode.localeCompare(b.vehicleCode),
        },
        {
            title: '订单创建时间',
            width: 160,
            dataIndex: 'orderCreateTime',
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.orderCreateTime && b.orderCreateTime && a.orderCreateTime.localeCompare(b.orderCreateTime),
        },
        {
            title: '客户名称',
            width: 160,
            dataIndex: 'customerName',
            render: (text) => <YhTooltip text={text} />,
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.customerName.localeCompare(b.customerName),
        },
        {
            title: '单价',
            width: 160,
            dataIndex: 'unitPrice',
        },
        {
            title: '商品数量',
            width: 160,
            dataIndex: 'materialNumber',
        },
        {
            title: '商品金额',
            width: 160,
            dataIndex: 'materialAmount',
        },
        {
            title: '折扣',
            width: 160,
            dataIndex: 'materialDiscount',
        },
        {
            title: '优惠金额',
            width: 160,
            dataIndex: 'preferentialAmount',
        },
        {
            title: '实际金额',
            width: 160,
            dataIndex: 'actualAmount',
        },
    ]
    return (
        <YHModal
            visible={visible}
            title={`商品编号：${materialCode}  商品名称：${materialName} `}
            onCancel={onCancel}
            width={1200}
            bodyHeight={700}
            showFooter={false}
            zIndex={1005}
        >
            <YhBox title={action} style={{ margin: '0' }}>
                {type === 'allShop' && <ShopStock materialCode={materialCode} materialName={materialName} />}
                {(type === 'purchase' || type === 'sale' || type === 'purchaseHistory' || type === 'saleHistory') && (
                    <Table
                        style={{ cursor: 'pointer' }}
                        rowKey={(record) => record.id}
                        columns={columns}
                        dataSource={dataSource}
                        scroll={{ x: 'max-content', y: 550 }}
                        pagination={false}
                        loading={loading}
                    />
                )}
                {type === 'repairHitory' && (
                    <Table
                        style={{ cursor: 'pointer' }}
                        rowKey={(record) => record.id}
                        columns={repairHitoryColumns}
                        dataSource={dataSource}
                        scroll={{ x: 'max-content', y: 550 }}
                        pagination={false}
                    />
                )}
            </YhBox>
        </YHModal>
    )
}
