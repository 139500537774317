import React, { useEffect, useImperativeHandle } from "react";
import { Form, message } from "antd";
import { __FormItemsBox as FormItemsBox } from "./FormItemsBox";
import copy from "copy-to-clipboard"

export const __FormBox = ({ formRef, boxs, ...props }) => {
    const [form] = Form.useForm();
    const { labelCol, labelAlign, disabled, formValues, onChange, onFinish } = props
    const onValuesChange = (changedValues, values) => {
        // disabled ? form.setFieldsValue(formValues) : onChange?.(changedValues);
        onChange && onChange(changedValues, values);
    }


    useEffect(() => {
        form.setFieldsValue(formValues);
        // console.log(formValues)
    }, [formValues]);

    useImperativeHandle(formRef, () => ({
        validateFields: async () => {
            return await form.validateFields();
        },
        setFieldsValue: (formValues) => {
            form.setFieldsValue(formValues);
        },
        getFieldsValue: () => {
            return form.getFieldsValue()
        },
        resetFields: form.resetFields,
        inst: () => {
            return form;
        }
    }));

    const copyText = (fieldName, option) => {
        if (!option) {
            let value = form.getFieldsValue([fieldName]);
            if (typeof value[fieldName] !== "string") return;
            copy(value[fieldName]);
        } else {
            let value = form.getFieldsValue([fieldName]);
            if (typeof value[fieldName] !== "string") return;
            copy(option?.find(item => item.value == value[fieldName])?.label);
        }
        message.success('复制成功');
    }

    return <Form
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        labelCol={labelCol}
        // wrapperCol={labelAlign}
        labelAlign={labelAlign}
    >
        {
            boxs.map((el, index) => <FormItemsBox key={index} {...el} disabled={el.disabled ?? disabled} copyText={(fieldName, option) => copyText(fieldName, option)} />)
        }
    </Form>
}
