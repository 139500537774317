import { post, get } from "../utils/request"

//分页查询
function getorderList(data) {
    return post("/ci/order_settlement/list", data)
}

//结算
function updateOrderSettle(data) {
    return post("/ci/order_settlement/settle", data)
}

//修改
function updateOrderList(data) {
    return post("/ci/order_settlement/update", data)
}
function getOrderDetailsList(id) {
    return get(`/ci/order_settlement/detailList/${id}`)
}

export { getorderList, updateOrderList, getOrderDetailsList, updateOrderSettle }
