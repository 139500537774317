import React from "react";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import { usePurchasePlanModel } from "../purchasePlanModel";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import useGlobalModel from "@/erp_subpackage/model/globalModel";

export function PageHeader() {
    const { getSelectedDictOne } = useDictModel(() => []);
    const {
        searchParam, organTypeCode,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = usePurchasePlanModel();
    const { orgDs } = useGlobalModel(({ orgDs }) => [orgDs]);

    const searchFields = [
        {
            type: "Input",
            placeholder: "采购订单号",
            fieldName: "planCode",
        },
        ...(organTypeCode === 3 ? [{
            type: "Select",
            placeholder: "所属机构",
            fieldName: "shopId",
            options: orgDs
        }] : []),
        {
            type: "Select",
            placeholder: "采购类型",
            fieldName: "purchaseTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.PURCHASE_TEPE, ["vrm", "ci", "main"]),
        },
        {
            type: "Select",
            placeholder: "商品类型",
            fieldName: "materialTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.MATERIAL_TYPE),
        },
        {
            type: "Input",
            placeholder: "制单人",
            fieldName: "createUser"
        },
        {
            type: "Select",
            placeholder: "单据状态",
            fieldName: "status",
            mode: "multiple",
            // options: [{ label: item.name, value: item.code }],
            options: getSelectedDictOne(DictCodeEnmu.PURCHASE_PLAN),
        },
        {
            type: "RangePicker",
            fieldName: "",
            placeholder: ["制单日期起始", "制单日期结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        }
    ]

    return (
        <YhPageHeader
            title="采购计划"
            size="small"
            triggerSearch
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData({ pageNum: 1 }, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}