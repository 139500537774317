import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { useStockEntryModel } from "../entryModel";
import { momentFormat } from "@/erp_subpackage/utils/util";
import { formatNum } from '@/erp_subpackage/utils/util';
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

export function DataTable({ onLook }) {
    const {
        dataSource,
        selectedRowKeys,
        pgCfg,
        loading,
        loadData,
        getTotal,
        setSelectedRowKeys,
    } = useStockEntryModel();

    useEffect(() => {
        loadData()
        getTotal()
    }, [])
    const columns = [
        {
            title: '序号',
            width: 80,
            align: "center",
            dataIndex: 'branch',
            fixed: "left",
            render: (text, record, index) => index + 1
        }, {
            title: '单据编号',
            width: 160,
            dataIndex: "tradeCode",
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => onLook("look", record)} />,
            // render: (text, record) => <Tooltip placement="topLeft" title={text}><p className="one-line click-span" onClick={(e) => { e.stopPropagation(); onLook('look', record.id) }}>{text}</p></Tooltip>,
        }, {
            title: '单据类型',
            width: 160,
            dataIndex: 'tradeTypeName',
            fixed: "left",
        }, {
            title: '入库数量',
            width: 100,
            dataIndex: 'inTotal',
        }, {
            title: "入库单位",
            width: 160,
            dataIndex: "userName",
            render: (text) => <YhTooltip text={text} />,
            // render: (text, record) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: "入库金额",
            width: 100,
            dataIndex: "amount",
            render: text => typeof text === "number" ? `￥${formatNum(text)}` : ""
        }, {
            title: '制单人',
            width: 160,
            dataIndex: 'createUser'
        }, {
            title: '制单日期',
            width: 200,
            dataIndex: 'billingTime',
            render: (text) => momentFormat(text)
        }, {
            title: '库管员',
            width: 160,
            dataIndex: 'billingClerk',
        }, {
            title: '入库日期',
            width: 160,
            dataIndex: 'stockIoTime',
            render: (text) => momentFormat(text)
        }, {
            title: '所属机构',
            width: 160,
            dataIndex: 'shopName',
        },
        // {
        //     title: '备注',
        //     width: 160,
        //     dataIndex: 'note',
        // },
    ];

    //点击行
    const onRow = (record) => {
        let findObj = selectedRowKeys.find(item => item.id === record.id)
        if (findObj) {
            let newSelectedRowKeys = selectedRowKeys.filter(item => item.id !== record.id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            setSelectedRowKeys(origin => [...origin, record]);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            loading={loading}
            rowSelection={{
                //全部选中时 (存row[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRows])
                        : setSelectedRowKeys((origin) => {
                            let changeRowId = changeRows.map(item => item.id);
                            return origin.filter(item => changeRowId.indexOf(item.id) < 0)
                        });
                },
                onSelect: (record, selected, selectedRows) => onRow(record),
                selectedRowKeys: selectedRowKeys.map(item => item.id)
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record]);
                }
            })}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}


