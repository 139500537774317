import {
    post,
    get
} from "../utils/request"


//获取一条配置
export const getUserCustomizeConfigOne = (key) => {
    return get(`/ci-platform/user/customize/config/one/${key}`)
}

//保存配置
export const saveUserCustomizeConfig = (data) => {
    return post(`/ci-platform/user/customize/config/save`,data)
}

//获取修改手机号码的验证码
export const editInfoSmsCode = (data) => {
    return post(`/ci-platform/user/edit_info_sms_code`,data)
}

//修改个人信息
export const editUserInfoInfo = (data) => {
    return post(`/ci-platform/user/edit_info`,data)
}

//获取推广二维码
export const getQrCode = (data) => {
    return post(`/ci-platform/business_elite/get_popularize_qrcode`,data)
}
