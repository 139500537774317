import React, { useEffect, useState } from "react";
import { Table, Drawer, Form, Input, Row, Col, Button, message } from "antd";
import { useContractModel } from '../contractParticularsModel'
import { usecontractDetailsModel } from './detailsModel'
import { EditableRow, EditableCell } from "@/erp_subpackage/components/YHEditTable/editTable";
import { contractService } from '../contractParticularsServer'

export const MileageDrawer = ({ mode, rowCode }) => {
    const { mileagedata, setMileagedata, searchParam, setSearchParam, newfilterData, setNewfilterData, getdedtailsOne } = usecontractDetailsModel()
    const { mileageVisable, setMileageVisable } = useContractModel()





    //车辆列表上查询
    const handleSearch = (value) => {
        // 进行模糊检索
        const filteredResults = newfilterData.filter((item) =>
            item.vehicleCode.includes(value)
        );
        setMileagedata(filteredResults);

    }

    const onsubmit = () => {
        if (mileagedata.length === 1) {
            let data = {
                signId: rowCode,
                vehicleCode: mileagedata[0].vehicleCode,
                vehicleId: mileagedata[0].vehicleId,
                billPrice: mileagedata[0].billPrice,
                mileageCurrent: mileagedata[0].mileageCurrent,
                signMileage: mileagedata[0].signMileage
            }
            contractService.mileageaddone(data).then(res => {
                message.success('修改成功')
                getdedtailsOne(rowCode)
                setMileageVisable(false)

            })
        } else {
            console.log(mileagedata)
            const filteredData = mileagedata.map(({ vehicleId, vehicleCode, billPrice, mileageCurrent, signMileage }) => ({ vehicleId, vehicleCode, billPrice, mileageCurrent, signId: rowCode, signMileage }));
            contractService.mileageadd(filteredData).then(res => {
                message.success('修改成功')
                getdedtailsOne(rowCode)
                setMileageVisable(false)

            })
        }

    }

    const columns = [
        {
            title: '车辆牌照',
            width: 80,
            dataIndex: 'vehicleCode',
            fixed: "left",
        },
        {
            title: '上个月账单里程',
            width: 120,
            dataIndex: 'mileageLast',
            fixed: "left",
        },
        {
            title: '签约里程',
            width: 120,
            dataIndex: 'signMileage',
            editable: true
        },
        {
            title: '当前里程',
            width: 120,
            dataIndex: 'mileageCurrent',
            editable: true
        },
        {
            title: '计费价格',
            width: 120,
            dataIndex: 'billPrice',
            editable: true,
            render: (amount) => parseFloat(amount).toFixed(3),

        },
        {
            title: '账单金额',
            width: 120,
            dataIndex: 'totalAmount'

        },
    ]

    const onCancel = () => {
        setMileageVisable(false)
    }
    const newSelectColumns = columns.map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                editType: col?.editType,
                editable: col?.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑   (计算价格)
    const handleSave = (row) => {
        const updatedsignMileage = Number(row.mileageCurrent).toFixed(2);
        const updatebillPrice = Number(row.billPrice).toFixed(3);
        const updatesignMileage = Number(row.signMileage).toFixed(2);
        let newtotalAmount = 0
        //计算账单金额
        if (row.mileageLast !== 0) {
            newtotalAmount = ((row.mileageCurrent - row.mileageLast) * row.billPrice).toFixed(2)
        } else {
            newtotalAmount = (row.signMileage * row.billPrice).toFixed(2)
        }
        // 在这里处理保存逻辑，将修改后的值存储到列表中的相应位置
        const updatedList = mileagedata.map(item => {
            if (item.vehicleId === row.vehicleId) {
                return {
                    ...item,
                    mileageCurrent: updatedsignMileage,
                    billPrice: updatebillPrice,
                    signMileage: updatesignMileage,
                    totalAmount: newtotalAmount
                };
            }
            return item;
        });
        setMileagedata(updatedList)
    }
    return (
        <Drawer
            title={<>
                <span>里程维护</span>
                {/* <span style={{float:"right"}}>{vehicleData.length}/{choose}</span> */}
            </>}
            open={mileageVisable}
            onClose={onCancel}
            width={700}
        >
            <div>
                <Row>
                    <Col span={10}>
                        <Form >
                            <Form.Item >
                                <Input allowClear placeholder="请输入车辆牌照回车检索" shape="round" value={searchParam} onChange={(e) => { setSearchParam(e.target.value); handleSearch(e.target.value) }} />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
            <div style={{ marginTop: "10px" }}>
                <Table
                    rowKey={record => record.vehicleId}
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        },
                    }}
                    columns={newSelectColumns}
                    dataSource={mileagedata}
                    scroll={{ y: 600 }}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', gap: '30px', marginTop: '30px' }}>
                <Button type="primary" shape="round" onClick={() => { onsubmit() }}>保存</Button>
                <Button type="primary" shape="round" onClick={() => { onCancel() }}>返回</Button>
            </div>
        </Drawer >

    )
}