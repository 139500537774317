import React from "react"
import { useSupplierModel } from "../supplierModel"
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader"
export function PageHeader() {
    const { searchParam, loadData, updateSearchParam, resetSearchParam } = useSupplierModel()
    const searchFields = [
        {
            type: "Input",
            placeholder: "供应商编码",
            fieldName: "code",
        },
        {
            type: "Input",
            placeholder: "供应商名称",
            fieldName: "name",
        },
        {
            type: "Input",
            placeholder: "联系人",
            fieldName: "contact",
        },
        {
            type: "Input",
            placeholder: "联系电话",
            fieldName: "phone",
        },
        {
            type: "Select",
            placeholder: "供应商状态",
            fieldName: "enable",
            options: [
                { label: "启用", value: 1 },
                { label: "禁用", value: 0 },
            ],
        }
    ]

    return (
        <YhPageHeader
            title="供应商档案"
            size="small"
            triggerSearch={true}
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData({ pageNum: 1 }, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    )
}
