import React, { useState, useEffect, useContext } from "react"
import { Tree, Button, Form, Input, message } from "antd"
import TopSearch from "@/components/TopSearch"
import { noticeStatus, noticeTemplateStatus } from "@/service/noticeService"
import { listAllNotice } from "@/service/permissin"
import { MyContext } from "@/store/myContext"
import "./dictStyle.less"

const userType = {
    1:"内部员工",
    2:"团队",
    3:"客户"
}
const NoticeManage = () => {
    const { state } = useContext(MyContext)
    const [form] = Form.useForm()
    const [treeList, setTreeList] = useState(null) //树结构列表
    const [selectTreeObj, setSelectTreeObj] = useState({}) //选择的字典数据
    const [mode, setMode] = useState("edit") //默认展示编辑
    const [initTree, setInitTree] = useState(null)
    const [noEdit, setNoEdit] = useState(false)
    useEffect(() => {
        if (state.activeMenu === "noticeManage") {
            loadData()
        }
    }, [state])

    //遍历树换title
    const mapTree = (arr) => {
        return arr.forEach((item) => {
            item.title = item.dictNumber + " " + item.title
            item.disabled = item.disableFlag === 1 ? true : false
            item.children && mapTree(item.children)
        })
    }
    //字典树加载。
    const loadData = () => {
        listAllNotice().then((res) => {
            listAllNotice()
                .then((res) => {
                    if (res && res.retCode === 200) {
                        let list = res.retData
                        if (list) {
                            list = convertTreeData(list)
                            setTreeList(list)
                        }
                    } else {
                        message.warning(res.retMsg)
                    }
                })
                .catch((err) => message.error(err.retMsg))
        })
    }

    const convertTreeData = (list) => {
        return list.map((i) => {
            let result = {
                ...i,
                key: i.noticeCode,
                title: i.noticeName,
                // disabled: true
            }
            let templates = i.templates
            if (templates) {
                let temp = templates.map((v) => {
                    return {
                        ...v,
                        key: v.uniqueEnumTag,
                        title: v.noticeMethodDesc,
                    }
                })
                result.children = temp
            }
            return result
        })
    }

    //选中当前行 根据当前行拿到信息
    const onSelect = (selectedKeys, info) => {
        console.log(selectedKeys, info)
        setSelectTreeObj(info.node)
    }

    //删除字典
    const changeStatus = (status) => {
        if (!Object.keys(selectTreeObj).length) {
            message.warning("请选择你要操作选项")
            return
        }
        const data = {disableFlag: status,id:selectTreeObj.id}
        if(selectTreeObj.uniqueEnumTag){
            noticeTemplateStatus(data).then((res) => {
                // if (res && res.retCode === 200) {
                    loadData()
                    message.success(`禁用成功`)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
        }else{
            noticeStatus(data).then((res) => {
                // if (res && res.retCode === 200) {
                    loadData()
                    message.success(`禁用成功`)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg)) 
        }
        
    }

    //表单项 布局
    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 12 },
    }

    return (
        <>
            {state.activeMenu === "noticeManage" && (
                <>
                    <TopSearch title="数据字典" />
                    <div className="dict-container">
                        <div className="dict-list-box">
                            <div className="dict-list-header">
                                <div className="dict-list-name">
                                    <span>消息列表</span>
                                </div>
                                <div className="dict-list-but">
                                    <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={() => {changeStatus(0)}}>
                                        启用
                                    </Button>
                                    <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={() => {changeStatus(1)}}>
                                        禁用
                                    </Button>
                                </div>
                            </div>
                            <div className="dict-table-box">
                                {treeList && <Tree selectedKeys={[selectTreeObj.id || ""]} onSelect={onSelect} treeData={treeList} />}
                            </div>
                        </div>
                        <div className="dict-from-box">
                            {Object.keys(selectTreeObj).length ? (
                                <>
                                    <div className="dict-from-content">
                                        <div className="dict-from-ietm">
                                            <span className="dict-from-title">通知名称：</span>
                                            {selectTreeObj.noticeName}
                                        </div>
                                        <div className="dict-from-ietm">
                                            <span className="dict-from-title">通知编码：</span>
                                            {selectTreeObj.noticeCode}
                                        </div>
                                        <div className="dict-from-ietm">
                                            <span className="dict-from-title">模版标签：</span>
                                            {selectTreeObj.uniqueEnumTag}
                                        </div>
                                        <div className="dict-from-ietm">
                                            <span className="dict-from-title">模版编码：</span>
                                            {selectTreeObj.noticeTemplateCode}
                                        </div>
                                        <div className="dict-from-ietm">
                                            <span className="dict-from-title">通知用户类型：</span>
                                                {userType[selectTreeObj.noticeUserType]}
                                        </div>
                                        <div className="dict-from-ietm">
                                            <span className="dict-from-title">通知方式编码：</span>
                                            {selectTreeObj.noticeMethodCode}
                                        </div>
                                        <div className="dict-from-ietm">
                                            <span className="dict-from-title">通知方式描述：</span>
                                            {selectTreeObj.noticeMethodDesc}
                                        </div>
                                        <div className="dict-from-ietm">
                                            <span className="dict-from-title">通知模版：</span>
                                            {selectTreeObj.noticeTemplate}
                                        </div>
                                        <div className="dict-from-ietm">
                                            <span className="dict-from-title">禁用状态：</span>
                                            {selectTreeObj.disableFlag === 0 ? "正常" : "禁用"}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="dict-init-box">
                                    <span>消息通知</span>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
export default NoticeManage
