import React, { useEffect, useState, useContext } from 'react'
import { Button, Col, Row, message, Select, DatePicker, Form, Input, Upload, Spin, Switch, Image } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { getVehicleList, getCustomerOneNew } from '@/service/customerService'
import { vehicleBrandnamePage } from '@/service/dataService'
import { customerQueryInfo, quickRepair } from '@/service/quickBilling'
import { getPcodeDict, getUserOrgan } from '@/utils/dataStorage'
import { fileUploadUrl, fileLookUrl } from '@/config/app.js'
import { GetChinese, RemoveChinese } from '@/utils/util'
import { getvehicleLicenseInfo } from '@/service/baiduAiService'
import { MyContext } from '@/store/myContext'
import { getToken } from '@/utils/dataStorage'
import { YhBox } from '@/erp_subpackage/components/YhBox'
import InsertAddressMap from '@/components/TMapChoose'
import ChooseVehicle from './chooseVehicle'
import position from '@/assets/images/position.png'
import debounce from 'lodash/debounce'
import moment from 'moment'
import './quickBilling.less'

const { Option } = Select
export default () => {
    const { dispatch } = useContext(MyContext)

    const [form] = Form.useForm()
    const [vehicleVisible, setVehicleVisible] = useState(false)
    const [isSpecial, setIsSpecial] = useState(false) //是否是特种车
    const [licenseFileList, setLicenseFileList] = useState({ file: [], md5: '' }) //图片list
    const [previewImage, setpreviewImage] = useState('') //预览展示的行驶证
    const [previewVisible, setpreviewVisible] = useState(false) //预览图片使用的dialog
    const [scanningLoading, setScanningLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [imgInfo, setImgInfo] = useState({})
    const [mapVisible, setMapVisible] = useState(false) //地图
    const [brandNameList, setBrandNameList] = useState([])
    const [selectBrandId, setSelectBrandId] = useState('')

    //由于图片上传后展示为缩略图(模糊)，产品需要展示为原始图(清晰)
    //a、隐藏缩略图：quickBilling.less: .ant-upload-list-item-info width:0px
    //b、图片上传完成后修改图片地址为原始图
    //c、根据imageUploadComplete状态，还原缩略图区域宽度 .ant-upload-list-item-info width:100%
    const [imageUploadComplete, setImageUploadComplete] = useState(false)
    useEffect(() => {
        let imgDiv = document.getElementsByClassName('ant-upload-list-item-info')
        if (imageUploadComplete) {
            if (imgDiv.length > 0) {
                imgDiv[0].setAttribute('style', 'width:100%')
            }
        } else {
            if (imgDiv.length > 0) {
                imgDiv[0].setAttribute('style', 'width:0px')
            }
        }
    }, [imageUploadComplete])

    useEffect(() => {
        // return current && current < moment().startOf("day")
        form.setFieldsValue({
            repairAddress: getUserOrgan().organAddress,
            repairLng: getUserOrgan().organLng,
            repairLat: getUserOrgan().organLat,
            orderType: '014001',
            serviceMethod: '012001',
        })

        let data = {
            pageNum: 1,
            pageSize: 1000,
            searchItem: {},
            orders: [{ column: 'createTime', rule: 'DESC' }],
        }
        vehicleBrandnamePage(data).then((res) => {
            setBrandNameList(res.retData.list)
        })
    }, [])

    const handleChangebrandName = (value, option) => {
        setSelectBrandId(option.info.id)
    }

    const [vehicleInfo, setVehicleInfo] = useState({
        //车辆列表
        data: [],
        fetching: false,
    })
    //车辆列表查询
    const vehicleQuery = (value) => {
        setVehicleInfo({
            data: [],
            fetching: true,
        })
        let D = {
            pageSize: 15,
            currentPage: 1,
            searchItem: {
                vehicleCode: value,
            },
        }
        getVehicleList(D).then((res) => {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.map((item) => ({
                    text: item.vehicleCode,
                    value: item.id,
                    ...item,
                }))
                setVehicleInfo({
                    data: data,
                    fetching: false,
                })
            } else {
                message.warning('没有此车辆')
            }
        })
    }
    //防抖
    const vehicleQuerys = debounce(vehicleQuery, 1000)
    //车辆选中, 带出牌照
    const handleChangevehicle = (value, option) => {
        if (option.info.specialVehicle == 1) {
            setIsSpecial(true)
        } else {
            setIsSpecial(false)
        }
        form.setFieldsValue({
            vehicleId: option.info.id,
            brandId: option.info.brandId,
            brandSecond: option.info.brandSecond,
            vehicleType: option.info.vehicleType,
            vehicleVin: option.info.vehicleVin,
            engineCode: option.info.engineCode,
            drivingSignDate: option.info.drivingSignDate == null ? '' : moment(option.info.drivingSignDate),
            vehicleNature: option.info.vehicleNature,
            drivingDistributeDate: option.info.drivingDistributeDate == null ? '' : moment(option.info.drivingDistributeDate),
            vehicleVin: option.info.vehicleVin,
            vehicleModelId: option.info.vehicleModelId,
            organId: option.info.organId,
            customerId: option.info.customerId,
            customerName: option.info.customerName,
            customerAddress: option.info.customerAddress,
            sender: option.info.sender,
            senderPhone: option.info.senderPhone,
        })
        corporation(option.info.customerId)
    }

    // 图片上传
    const handleImgChange = async (info) => {
        //上传开始时有两个div  隐藏
        let uploadDiv = document.getElementsByClassName('ant-upload-select')
        if (uploadDiv.length > 0) {
            uploadDiv[0].setAttribute('style', 'display:none')
        }
        //隐藏缩略图
        setImageUploadComplete(false)

        if (info.file.status === 'done') {
            let md5 = info?.file?.response?.retData
            setScanningLoading(true)
            getImgInfo(md5)
            setLicenseFileList({ file: info.fileList, md5: md5 })
        } else if (info.file.status === 'removed') {
            setLicenseFileList({
                file: [],
                md5: '',
            })
        }
    }

    //图像识别
    const getImgInfo = (fileMd5) => {
        setLoading(true)
        getvehicleLicenseInfo(fileMd5)
            .then((res) => {
                if (!res.retData.error_code) {
                    let { words_result, error_msg } = res.retData
                    if (error_msg) {
                        message.error(error_msg)
                        setLicenseFileList({
                            file: [],
                            md5: '',
                        })
                    } else {
                        const imgInfo = {
                            vehicleCode: words_result['号牌号码'].words,
                            vehicleType: words_result['车辆类型'].words,
                            brandId: GetChinese(words_result['品牌型号'].words),
                            vehicleModelId: RemoveChinese(words_result['品牌型号'].words),
                            vehicleNature: words_result['使用性质'].words,
                            vehicleVin: words_result['车辆识别代号'].words,
                            engineCode: words_result['发动机号码'].words,
                            drivingSignDate: moment(words_result['注册日期'].words),
                            drivingDistributeDate: moment(words_result['发证日期'].words),
                            customerName: words_result['所有人'].words,
                            customerAddress: words_result['住址'].words,
                        }
                        setImgInfo(imgInfo)
                        form.setFieldsValue(imgInfo)
                        senderInfo(imgInfo.vehicleCode)
                    }
                    setVehicleVisible(true)
                } else {
                    message.error('请上传清晰的行驶证图片')
                    setLicenseFileList({
                        file: [],
                        md5: '',
                    })
                }
                setScanningLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setScanningLoading(false)
            })
            .finally(() => {
                setLoading(false)

                //图片上传完成后修改图片地址为原始图
                let img = document.getElementsByClassName('ant-upload-list-item-thumbnail')
                if (img.length > 0) {
                    //图片上传完成后修改图片地址为原始图
                    img[0].firstChild.src = fileLookUrl().image + fileMd5
                    //还原缩略图区域宽度
                    setImageUploadComplete(true)
                }
            })
    }

    //根据车牌号，更新送修人等
    const senderInfo = async (vehicleCode) => {
        let D = {
            pageSize: 1,
            currentPage: 1,
            searchItem: {
                exactVehicleCode: vehicleCode,
            },
        }
        getVehicleList(D).then((res) => {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.map((item) => ({
                    text: item.vehicleCode,
                    value: item.id,
                    ...item,
                }))
                if (data[0].specialVehicle == 1) {
                    setIsSpecial(true)
                } else {
                    setIsSpecial(false)
                }
                form.setFieldsValue({
                    vehicleId: data[0].id,
                    customerId: data[0].customerId,
                    organId: data[0].organId,
                    sender: data[0].sender,
                    senderPhone: data[0].senderPhone,
                })
                corporation(data[0].customerId)
            } else {
                setIsSpecial(false)
            }
        })
    }

    //更新客户联系人等
    const corporation = async (customerId) => {
        getCustomerOneNew(customerId).then((res) => {
            form.setFieldsValue({
                customerType: res.retData.customerType,
                customerCorporation: res.retData.customerCorporation,
                customerCorporationPhone: res.retData.customerCorporationPhone,
            })
        })
    }

    //行驶证图片查看
    const handlePreview = async (file) => {
        setpreviewImage(file)
        setpreviewVisible(true)
    }
    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    //车辆确认
    const vehicleConfirm = (data) => {
        let vehicleCode = data.vehicleCode
        let brandName = data.brandName
        let brandSecond = data.brandSecond
        let customerName = data.existCustomerName
        if (data.customerChoose == 2) {
            //更新为识别的客户名称
            customerName = data.customerName
        }
        setSelectBrandId(data.selectBrandId)
        vehicleCode = vehicleCode.replace(/\s/g, '').toUpperCase()
        if (Object.keys(imgInfo).length === 0 && vehicleCode === form.getFieldValue('vehicleCode') && !isSpecial) {
            setVehicleVisible(false)
            return
        }
        const param = {
            vehicleCode,
            customerName: imgInfo.customerName || '',
        }
        customerQueryInfo(param).then((res) => {
            if (res.retData) {
                const { customer, vehicle, users } = res.retData
                if (customer) {
                    let user = {}
                    if (users && users.length > 0) {
                        user = users[0]
                    }
                    let customerInfo = {
                        customerName: customerName,
                        customerCorporation: user.userName || '',
                        customerCorporationPhone: user.userMobile || '',
                        customerId: customer.id,
                    }
                    form.setFieldsValue({ ...customer, ...customerInfo })
                } else {
                    let customerInfo = {
                        organId: getUserOrgan().id,
                        customerName: customerName,
                        customerCorporation: '',
                        customerCorporationPhone: '',
                    }
                    form.setFieldsValue({ ...customerInfo })
                }
                if (vehicle) {
                    const vehicleInfo = {
                        vehicleId: vehicle.id,
                        vehicleCode: vehicle.vehicleCode,
                        brandId: brandName,
                        brandSecond: brandSecond,
                    }
                    form.setFieldsValue(vehicleInfo)
                    senderInfo(vehicleInfo.vehicleCode)
                } else {
                    form.setFieldsValue({
                        vehicleCode: vehicleCode,
                        brandId: brandName,
                        brandSecond: brandSecond,
                    })
                }
            } else {
                form.setFieldsValue({
                    vehicleCode,
                })
            }
            setVehicleVisible(false)
        })
    }

    //地图保存
    const saveAddressDetail = (results) => {
        form.setFieldsValue({
            repairAddress: results.addr,
            repairLng: results.longitude,
            repairLat: results.latitude,
        })
        setMapVisible(false)
    }

    // 提交
    const onOk = async () => {
        try {
            let vehicle = await form.validateFields()
            vehicle.id = vehicle.vehicleId
            vehicle.specialVehicle = isSpecial ? 1 : 0
            vehicle.driverCardImg = licenseFileList.md5 || ''
            
            if(moment(vehicle.drivingSignDate) < moment("2000-01-01")){
                message.error("注册日期请选择2000年以后")
                throw("注册日期请选择2000年以后")
            }

            const customer = {
                id: vehicle.customerId,
                organId: vehicle.organId || getUserOrgan().id,
                customerName: vehicle.customerName,
                customerAddress: vehicle.customerAddress,
                customerType: vehicle.customerType,
                senderRepairUserName: vehicle.sender,
                senderRepairUserPhone: vehicle.senderPhone,
                customerCorporation: vehicle.customerCorporation == '' ? vehicle.sender : vehicle.customerCorporation,
                customerCorporationPhone: vehicle.customerCorporationPhone == '' ? vehicle.senderPhone : vehicle.customerCorporationPhone,
            }
            const order = {
                faultDesc: vehicle.faultDesc,
                repairAddress: vehicle.repairAddress,
                repairLng: vehicle.repairLng,
                repairLat: vehicle.repairLat,
                serviceMethod: vehicle.serviceMethod,
                orderType: vehicle.orderType,
                orderSource: '008002',
            }
            quickRepair({ vehicle, customer, order }).then((res) => {
                if (res && res.retCode === 200) {
                    message.success('报修成功')
                    goBack()
                } else {
                    message.error('报修失败')
                }
            })
        } catch (errorInfo) {
            console.log('Failed:', errorInfo)
        }
    }

    const goBack = async () => {
        dispatch({
            type: 'removeTabs',
            targetKey: 'quickBilling',
            activeMenu: 'pendingOrder',
        })
    }

    const vehicleCodeBlur = (e) => {
        vehicleConfirm({ vehicleCode: e.target.value })
    }

    const action = (
        <>
            <CloseCircleOutlined onClick={goBack} style={{ fontSize: 30, position: 'absolute', top: 20, right: 30 }} />
        </>
    )

    return (
        <Form form={form}>
            <YhBox title="快速开单" action={action}>
                <div className="quickBox">
                    <div className="photo" style={{ height: 720 }}>
                        <Form.Item label="" name="driverCardImg" valuePropName="fileList" getValueFromEvent={normFile}>
                            <Upload
                                action={`${fileUploadUrl}upload/image_old`}
                                headers={{ authorization: getToken() }}
                                listType="picture-card"
                                className="avatar-uploader quickBilling-uploader"
                                fileList={licenseFileList.file}
                                onPreview={() => handlePreview(licenseFileList.md5)}
                                onChange={handleImgChange}
                                maxCount={1}
                            >
                                {licenseFileList.file.length >= 1 ? null : <Button>上传行驶证</Button>}
                            </Upload>
                        </Form.Item>
                        {previewVisible && (
                            <Image
                                style={{ position: 'fixed', top: '5000px', left: '0' }}
                                preview={{
                                    visible: previewVisible,
                                    onVisibleChange(visible) {
                                        if (!visible) {
                                            setpreviewVisible(false)
                                        }
                                    },
                                }}
                                width={'100%'}
                                src={`${fileLookUrl().image}${previewImage}`}
                            />
                        )}
                    </div>
                    <div className="form">
                        <div style={{ height: 360, margin: '0 -10px 20px 40px', padding: 20, border: '1px solid #d9d9d9', borderRadius: 5 }}>
                            <Row>
                                <Col span={24} style={{ paddingBottom: 20 }}>
                                    <div className="list-name">
                                        <span style={{ margin: 10, fontWeight: 'bold', color: 'black' }}>行驶证信息</span>
                                        <Switch
                                            checkedChildren="特种车"
                                            unCheckedChildren="特种车"
                                            onChange={(val) => {
                                                setIsSpecial(val)
                                            }}
                                            checked={isSpecial}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    {isSpecial ? (
                                        <Form.Item
                                            name="vehicleCode"
                                            label="车辆牌照"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请输入车辆牌照',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入车辆牌照" onBlur={vehicleCodeBlur} />
                                        </Form.Item>
                                    ) : (
                                        <Form.Item
                                            label="车辆牌照"
                                            name="vehicleCode"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请输入车辆牌照',
                                                },
                                                {
                                                    pattern: /^[\u4E00-\u9FA5][\da-zA-Z]{6,7}$/,
                                                    message: '请输正确的车辆牌照',
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="车辆牌照"
                                                notFoundContent={vehicleInfo.fetching ? <Spin size="small" /> : null}
                                                filterOption={false}
                                                onFocus={() => vehicleQuery('')}
                                                onSearch={vehicleQuerys}
                                                onChange={handleChangevehicle}
                                                style={{ width: '100%' }}
                                            >
                                                {vehicleInfo.data.map((item) => (
                                                    <Option value={item.text} key={item.value} info={item}>
                                                        {item.text}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    )}
                                    {vehicleVisible && (
                                        <ChooseVehicle
                                            visible={vehicleVisible}
                                            vehicleCode={form.getFieldValue('vehicleCode') || imgInfo.vehicleCode}
                                            brandName={imgInfo.brandId}
                                            isSpecial={isSpecial}
                                            customerName={imgInfo.customerName}
                                            onClose={() => {
                                                setVehicleVisible(false)
                                            }}
                                            onSuc={vehicleConfirm}
                                        />
                                    )}
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        name="brandId"
                                        label="车牌品牌"
                                        rules={[
                                            {
                                                required: true,
                                                message: '请选择车牌品牌',
                                            },
                                        ]}
                                    >
                                        <Select placeholder="请选择车牌品牌" onChange={handleChangebrandName} style={{ width: '100%' }}>
                                            {brandNameList
                                                .filter((e) => e.brandLevel == 1)
                                                .map((item) => (
                                                    <Option value={item.brandName} key={item.brandName} info={item}>
                                                        {item.text}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="brandSecond" label={<span style={{ marginLeft: 28 }}>车系</span>}>
                                        <Select placeholder="请选择车系" style={{ width: '100%' }}>
                                            {brandNameList
                                                .filter((e) => e.brandLevel == 2 && e.parentId == selectBrandId)
                                                .map((item) => (
                                                    <Option value={item.brandName} key={item.brandName} info={item}>
                                                        {item.text}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label={<span style={{ marginLeft: 11 }}>车辆类型</span>} name="vehicleType">
                                        <Input placeholder="请输入车辆类型" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="vehicleVin"
                                        label={<span style={{ marginLeft: 11 }}>Vin码</span>}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请输入车辆识别号',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="请输入Vin码" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label={<span style={{ marginLeft: 11 }}>发动机号</span>} name="engineCode">
                                        <Input placeholder="请输入发动机号" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="注册日期" name="drivingSignDate">
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            disabledDate={(current) => {
                                                return current && current < moment("2000-01-01")
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label={<span style={{ marginLeft: 11 }}>使用性质</span>} name="vehicleNature">
                                        <Input placeholder="请输入使用性质" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="发证日期" name="drivingDistributeDate">
                                        <DatePicker style={{ width: '100%' }} />
                                    </Form.Item>
                                    <Form.Item hidden name="vehicleId">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item hidden name="vehicleModelId">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="客户名称"
                                        name="customerName"
                                        rules={[
                                            {
                                                required: true,
                                                message: '请输入客户名称',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="请输入客户名称" />
                                    </Form.Item>
                                    <Form.Item hidden name="customerId">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item hidden name="organId">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item hidden name="customerType">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item hidden name="customerCorporation">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item hidden name="customerCorporationPhone">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label={<span style={{ marginLeft: 11 }}>联系地址</span>} name="customerAddress">
                                        <Input placeholder="请输入联系地址" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div style={{ height: 320, margin: '0 -10px 20px 40px', padding: 20, border: '1px solid #d9d9d9', borderRadius: 5 }}>
                            <Row>
                                <Col span={24} style={{ paddingBottom: 20 }}>
                                    <div className="list-name">
                                        <span style={{ margin: 10, fontWeight: 'bold', color: 'black' }}>报修信息</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="orderType"
                                                label="工单类型"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择工单类型',
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="请选择工单类型" style={{ width: '100%' }}>
                                                    {getPcodeDict('014').children.map((item) => (
                                                        <Select.Option value={item.dictCode} key={item.dictCode}>
                                                            {item.title}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="服务方式"
                                                name="serviceMethod"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择服务方式',
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="请选择服务方式" style={{ width: '100%' }}>
                                                    {getPcodeDict('012').children.map((item) => (
                                                        <Select.Option value={item.dictCode} key={item.dictCode}>
                                                            {item.title}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="sender"
                                                label={<span style={{ marginLeft: 14 }}>送修人</span>}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入送修人',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="请输入送修人" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="联系电话"
                                                name="senderPhone"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入联系电话',
                                                    },
                                                    {
                                                        message: '联系电话有误',
                                                        pattern: /^[0-9]\d{10}$/,
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="请输入联系电话" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                label="报修地址"
                                                name="repairAddress"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '通过地图实时位置获取地址',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder="通过地图实时位置获取地址"
                                                    disabled={true}
                                                    addonAfter={<img alt="" style={{ width: 30 }} src={position} onClick={() => setMapVisible(true)} />}
                                                />
                                            </Form.Item>
                                            <Form.Item hidden name="repairLng">
                                                <Input />
                                            </Form.Item>
                                            <Form.Item hidden name="repairLat">
                                                <Input />
                                            </Form.Item>
                                            {
                                                mapVisible && <InsertAddressMap
                                                visible={mapVisible}
                                                onCloseMap={() => setMapVisible(false)}
                                                saveAddressDetail={saveAddressDetail}
                                                defaultInfo={{
                                                    lat: form.getFieldsValue().repairLat,
                                                    lng: form.getFieldsValue().repairLng
                                                }}
                                            />
                                            }
                                            
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                label="故障描述"
                                                name="faultDesc"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入故障描述',
                                                    },
                                                ]}
                                            >
                                                <Input.TextArea rows={2} placeholder="请输入故障描述" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Button className="btn-item" shape="round" onClick={onOk} style={{ marginLeft: '45%', marginTop: 20 }}>
                                                确认提交
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </YhBox>
        </Form>
    )
}
