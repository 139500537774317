import React from "react"
import { message, Tooltip } from "antd"
import copy from "copy-to-clipboard"
import { CopyOutlined } from "@ant-design/icons"

// export interface IYhTootip {
//   placement?: object,
//   isCopy?: boolean,
//   text: string,
//   onClick?: (e: any) => void,
// }

const YhTooltip = ({ placement, isCopy = true, text, showText, onClick: handleClick, maxWidth }) => {
    const oSpan = (
        <span
            style={{ cursor: "copy" }}
            onClick={(e) => {
                e.stopPropagation()
                if (isCopy) {
                    copy(text)
                    message.success("复制成功")
                }
            }}
        >
            <span>{text}</span>&nbsp;&nbsp;{isCopy && <CopyOutlined />}
        </span>
    )
    return (
        <Tooltip placement="top" title={oSpan}>
            <p
                style={maxWidth ? { maxWidth } : {}}
                
                className={`one-line ${handleClick ? "click-span" : ""}`}
                onClick={(e) => {
                    handleClick && handleClick(e)
                }}
            >
                {showText ? showText : text}
            </p>
        </Tooltip>
    )
}
export default YhTooltip
