import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import { getMoveCarList, disableMoveCar } from "@/service/moveCarService"
import TopSearch from "@/components/TopSearch"
import { MyContext } from "@/store/myContext"
import { Select } from "antd"
import { organPage } from "@/service/orgService"
import YhTooltip from "@/components/YhTooltip"

const { Option } = Select

const MoveCarManage = ({ organId }) => {
    const { dispatch, state, message, detailsPage, Switch, showAllOrgan } = useContext(MyContext)
    const [data, setData] = useState([]) //默认展示数据
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [org, setOrg] = useState([])

    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState({ organId })

    useEffect(() => {
        if (state.activeMenu === "moveCarManage") {
            loadData(pagination.current, searchValue)
        }
        return () => setData([])
    }, [state])

    useEffect(() => {
        if (showAllOrgan) {
            organPage({
                searchItem: { orderFlag: 0 },
                pageNum: 1,
                pageSize: 100,
                orders: [{ column: "createTime", rule: "DESC" }],
            }).then((res) => {
                setOrg(res.retData.list)
            })
        }
    }, [])

    //默认加载数据列表
    const loadData = (current, search = {}) => {
        setLoading(true)
        setSelectInfo({
            rowInfo: {},
            rowIndex: -1,
        })
        let data = {
            searchItem: { organId, ...search },
            startTime: search.startTime ? search.startTime : null,
            endTime: search.endTime ? search.endTime : null,
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        getMoveCarList(data).then((res) => {
            // if (res && res.retCode === 200) {
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                setData(res.retData.list)
                setLoading(false)
            // } else {
            //     // message.error(res.retMsg)
            // }
        })
    }

    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //移动车新增 查看 编辑
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "移动车新增",
            edit: "移动车编辑",
            look: "移动车查看",
        }
        let modeKey = "moveCarManage/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的移动车信息")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的移动车信息")
                return
            }

            //每次查看把之前的删除， 重新生成
            await dispatch({
                type: "removeTabs",
                targetKey: modeKey,
            })
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.MoveCarDetails({
                modeKey,
                dispatch,
                mode,
                rowInfo: selectInfo.rowInfo,
            }),
        })
    }

    //移动车开关
    const moveCarSweitchChange = (checked, e, id) => {
        e.stopPropagation()
        disableMoveCar({ id: id, disableFlag: checked ? 0 : 1 }).then((res) => {
            // if (res && res.retCode === 200) {
                message.success("操作成功")
            // } else {
            //     message.error(res.retMsg)
            // }
        })
    }
    //table配置
    const columns = [
        {
            title: "所属团队",
            align: "center",
            dataIndex: "teamName",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "车辆牌照",
            align: "center",
            width: 120,
            dataIndex: "vehicleCode",
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "所属机构",
            align: "center",
            dataIndex: "organName",
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "车辆品牌",
            align: "center",
            dataIndex: "vehicleBrand",
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "车系",
            align: "center",
            dataIndex: "vehicleSeries",
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "车型",
            align: "center",
            dataIndex: "vehicleModel",
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "Vin码",
            align: "center",
            dataIndex: "vehicleVin",
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "发动机号",
            align: "center",
            dataIndex: "engineCode",
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "登记日期",
            align: "center",
            dataIndex: "createTime",
        },
        {
            title: "操作",
            align: "center",
            width: 100,
            dataIndex: "disableFlag",
            render: (text, record) => (
                <Switch size="small" defaultChecked={text === 0 ? true : false} onChange={(checked, e) => moveCarSweitchChange(checked, e, record.id)} />
            ),
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入团队名称",
                valueName: "teamName",
            },
            {
                type: "input",
                placeholder: "请输入车辆牌照",
                valueName: "vehicleCode",
            },
            {
                type: "input",
                placeholder: "请输入车辆品牌",
                valueName: "vehicleBrand",
            },
            {
                hidden: !showAllOrgan,
                type: "select",
                placeholder: "请选择客户所属机构",
                valueName: "organId",
                optionList: () =>
                    org.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.organName}
                        </Option>
                    )),
            },
        ],
        hideList: [
            {
                hidden: !showAllOrgan,
                type: "select",
                placeholder: "请选择客户所属机构",
                valueName: "organId",
                optionList: () =>
                    org.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.organName}
                        </Option>
                    )),
            },
            {
                type: "input",
                placeholder: "请输入VIN码",
                valueName: "vehicleVin",
            },
            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                span: 16,
                xxl: 8,
            },
        ],
    }
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("look"),
            content: "查看",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
    ]
    //列表button
    return (
        <>
            {state.activeMenu === "moveCarManage" && (
                <>
                    <TopSearch
                        title="移动车管理"
                        justify="start"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="移动车辆列表"
                        buttons={topButtons}
                        loading={loading}
                        columns={columns}
                        dataSource={data}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />
                </>
            )}
        </>
    )
}
export default MoveCarManage
