/* eslint-disable no-unused-expressions */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Input, Form, Select, message, Cascader, DatePicker, TreeSelect } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const EditableContext = React.createContext(null);

// //tr 行信息
// export interface Item {
//     [key: string]: string | number | boolean | undefined | object
// }

// //表单类型
// type IEditType = "input" | "select" | "datepicker" | "treeSelect";
// interface EditableRowProps {
//     index: number;
// }

// //键码
// enum IKeysCode {
//     up = 38,
//     down = 40,
//     // tab = 9,
//     left = 37,
//     right = 39
// }

export const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

/** 
 *  colIndex: number,
    rowIndex: number,
    editType?: IEditType;
    rules?: Rule[]
    title: React.ReactNode;
    options?: ISelectOption[],
    mode?: "multiple" | "tags" | undefined,
    treeList?: ITreeSelectData[],
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
    onSelectChange?: (value: string, options: ISelectOption, record: Item) => void;
    onTreeSelectChange?: (value: string, options: ITreeSelectData, record: Item) => void;
    onSelectFocus?: (record: Item) => void
    onSelectSearch?: (value: string, record: Item) => void

*/

export const EditableCell = ({
    colIndex, //第几列下标
    rowIndex, //第几行下标
    editType = "input",
    options,
    treeList,
    rules,
    title,
    mode,
    editable,
    children,
    dataIndex,
    record,
    onSelectChange,
    onSelectFocus,
    onSelectSearch,
    onTreeSelectChange,
    //级联动态加载
    cascaderOptions,
    cascaderLoadData,
    cascaderChange,
    cascaderFocus,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
        if (editing && editType === "input") {
            // console.log(record)
            inputRef.current?.focus();
            inputRef.current?.select();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values, changeFields: dataIndex });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    //监听键盘事件
    const onKeyUp = (e) => {
        let rowNum = rowIndex + 1;  //当前行数
        let keyCode = e.keyCode;  //键码
        let tbodyChildren = e.target?.closest("tbody").children;  //当前所有的tr 行let
        let isTotalText = tbodyChildren[tbodyChildren.length - 1].textContent?.indexOf("合计") > -1;//是否包含合计最后一行
        let trLength = tbodyChildren.length - (isTotalText ? 2 : 1);  //减去表头哪一行，所有tr数量

        //上键
        if (keyCode === 38) {
            e.preventDefault();
            e.returnValue = true;
            let isPrev = rowNum <= 1;
            // if (isPrev) {
            //     message.success("没有上一行");
            //     return;
            // }
            tbodyChildren[isPrev ? trLength : rowNum - 1].children[colIndex].children[0]?.click();

            //下键
        } else if (keyCode === 40) {
            e.preventDefault();
            let isNext = rowNum < trLength;
            // if (!isNext) {
            //     message.success("没有下一行");
            //     return;
            // }
            // console.log(tbodyChildren[isNext ? rowNum + 1 : 1].children,tbodyChildren)
            tbodyChildren[isNext ? rowNum + 1 : 1].children[colIndex].children[0]?.click();

            //右键
        } else if (keyCode === 39) {
            e.preventDefault();
            let tdAll = tbodyChildren[rowNum]?.children;  //当前行所有列数；
            let numArr = [];        //记录input输入框所有td的下标
            for (let i = 0; i < tdAll.length ?? 0; i++) {
                tdAll[i].getAttribute("data-type") === "yh-input" && numArr.push(i);
            }
            if (!numArr.length || numArr.length === 1) { return }

            let findIndex = numArr.findIndex(item => item === colIndex); //查找处于第几个
            if (findIndex === numArr.length - 1) {  //最后一个，直接到第一个。否则下一个
                tdAll[numArr[0]].children[0].click();
            } else {
                tdAll[numArr[findIndex + 1]].children[0]?.click();
            }

            //左键
        } else if (keyCode === 37) {
            e.preventDefault();
            let tdAll = tbodyChildren[rowNum]?.children;  //当前行所有列数；
            let numArr = [];        //记录input输入框所有td的下标
            for (let i = 0; i < tdAll.length ?? 0; i++) {
                tdAll[i].getAttribute("data-type") === "yh-input" && numArr.push(i);
            }
            if (!numArr.length || numArr.length === 1) { return }

            let findIndex = numArr.findIndex(item => item === colIndex); //查找处于第几个
            if (findIndex === 0) {  //第一个，直接到最后一个。否则上一个
                tdAll[numArr[numArr.length - 1]].children[0]?.click();
            } else {
                tdAll[numArr[findIndex - 1]].children[0]?.click();
            }
        }
        // //tab切换
        // else if (keyCode === IKeysCode.tab) {
        //     debugger;
        //     e.preventDefault();
        //     let tdAll = tbodyChildren[rowNum]?.children;  //当前行所有列数；
        //     let numArr: number[] = [];        //记录input输入框所有td的下标
        //     for (let i = 0; i < tdAll.length ?? 0; i++) {
        //         tdAll[i].getAttribute("data-type") === "yh-input" && numArr.push(i);
        //     }
        //     let findIndex = numArr.findIndex(item => item === colIndex); //查找处于第几个

        //     if (!numArr.length) { return }
        //     if (findIndex === numArr.length - 1) {  //最后一个，直接到第一个。否则下一个
        //         tdAll[numArr[0]].children[0].click();
        //     } else {
        //         tdAll[numArr[findIndex + 1]].children[0].click();
        //     }
        // }
    }
    let childNode = children;

    const inputTypes = () => {
        switch (editType) {
            case "input":
                return <Input
                    ref={inputRef}
                    // onPressEnter={save}
                    onBlur={save}
                    onKeyUp={onKeyUp}
                />
            case "select":
                return <Select
                    ref={inputRef}
                    showSearch={true}
                    optionFilterProp="label"
                    onBlur={save}
                    onSelect={(value, option) => { onSelectChange?.(value, option, { ...record, [dataIndex]: value }, rowIndex) }}
                    onFocus={() => onSelectFocus?.(record, rowIndex)}
                    onSearch={(value) => onSelectSearch?.(value, record)}
                    options={options ?? []}
                    mode={mode}
                />
            case "datepicker":
                return <DatePicker
                    format="YYYY-MM-DD"
                    showTime={false}
                    showNow={true}
                    onBlur={save}
                />
            case "datepicker2":
                return <DatePicker
                    format="YYYY-MM-DD"
                    showTime={false}
                    showNow={true}
                    onBlur={save}
                    allowClear={false}
                />
            case "datepickeranother":
                return <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={true}
                    showNow={true}
                    onBlur={save}
                />
            case "treeSelect":
                return <TreeSelect
                    ref={inputRef}
                    onBlur={save}
                    treeData={treeList}
                    onSelect={(value, node) => { onTreeSelectChange?.(value, node, record) }}
                    treeDefaultExpandAll
                    switcherIcon={<PlusOutlined />}
                    showSearch
                    treeNodeFilterProp='title'
                />
            case "cascader":
                return <Cascader
                    ref={inputRef}
                    options={cascaderOptions}
                    loadData={(selectedOptions) => { cascaderLoadData?.(selectedOptions, record) }}
                    onChange={(value, selectedOptions) => cascaderChange?.(value, selectedOptions, record)}
                    changeOnSelect
                    onBlur={save}
                    expandTrigger="hover"
                    onFocus={() => cascaderFocus?.(record)}
                />
            default:
                return <></>
        }
    }
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={rules}
            >
                {inputTypes()}
            </Form.Item>
        ) : (
            <div className="yh-editable-cell-value-wrap" onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps} data-type={`${editable ? `yh-${editType}` : ""}`}>{childNode}</td>;
};