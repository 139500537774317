import React, { useEffect, useState } from "react"
import { Form, Input, Col, Row, Select, message, Modal, Switch } from "antd"
import { serviceOriceInsert, serviceOriceUpdate, serviceOriceOne } from "@/service/dataService"
import { organPage } from "@/service/orgService"
import useGlobalModel from '@/erp_subpackage/model/globalModel'

export default (props) => {

    const { mode, rowInfo, visible, setVisible, onSuccess } = props
    const { user: { organTypeCode, organId } } = useGlobalModel();
    const [form] = Form.useForm()
    const [org, setOrg] = useState([])
    const [orgDisable, setOrgDisable] = useState(false);

    useEffect(() => {
        //平台机构可选择其他机构，非平台机构默认本机构并不可编辑
        if (organTypeCode != 3) {
            setOrgDisable(true);
            form.setFieldsValue({ organId: organId })
        }
        queryMotorcade()
        organPage({
            searchItem: { orderFlag: 0 },
            pageNum: 1,
            pageSize: 100,
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            setOrg(res.retData.list)
        })
    }, [])

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            serviceOriceOne(rowInfo.id).then((res) => {
                let results = res.retData
                form.setFieldsValue({
                    ...results,
                    disableFlag: results.disableFlag === 0 ? true : false,
                })
            })
        }
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {

            let data = { ...values }

            if (mode === "edit") {
                data.id = rowInfo.id
                serviceOriceUpdate(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                serviceOriceInsert(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }



    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleCancel = () => {
        setVisible("")
    }

    return (
        <>
            <Modal forceRender visible={visible} title="服务定价" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={600}>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Form.Item
                                    label="所属机构"
                                    name="organId"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择所属机构",
                                        },
                                    ]}
                                >
                                    <Select disabled={orgDisable} placeholder="请选择所属机构" style={{ width: "100%" }}>
                                        {org.map((item) => (
                                            <Select.Option value={item.id} key={item.organName}>
                                                {item.organName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="收费单价" name="incomePrice" >
                                    <Input placeholder="请输入收费单价" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="工时单价" name="hourlyPrice" rules={[{ required: true, message: "请输入工时单价" }]}>
                                    <Input placeholder="请输入工时单价" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="维修配件占比" name="repairPartRate">
                                    <Input placeholder="请输入维修配件占比" suffix="%" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="国产/进口" name="domestic">
                                    <Select placeholder="请选择国产/进口" style={{ width: "100%" }}>
                                        <Select.Option value={1}>国产</Select.Option>
                                        <Select.Option value={0}>进口</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="备注" name="remark">
                                    <Input placeholder="备注" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="status"
                                    label="启用"
                                    getValueProps={value => ({ checked: value === 1, value })}
                                    getValueFromEvent={checked => (checked ? 1 : 2)}
                                    initialValue={1}
                                >
                                    <Switch checkedChildren="是" unCheckedChildren="否" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
