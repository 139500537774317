import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";

// export interface IPsiDetail {
//     id: string, // string
//     beginNum?: string,   // 2
//     bookOccupyNum?: string,   // null
//     busSaleNum?: string,   // null
//     busSreturnNum?: string,   // null
//     compensateNum?: string,   // null
//     currentNum?: string,   // 2
//     differenceNum?: string,   // 0
//     maintainNum?: string,   // null
//     maintainOccupyNum?: string,   // null
//     materialCode?: string,   // "612600060133"
//     materialName?: string,   // "外置节温器"
//     mreturnNum?: string,   // null
//     occupyDifference?: string,   // 0
//     occupyNum?: string,   // 0
//     preturnNum?: string,   // 0
//     preturnOccupyNum?: string,   // 0
//     purchasePrice?: string,   // 85
//     purchasePriceLatest?: string,   // 85
//     purhcaseNum?: string,   // 2
//     remainNum?: string,   // 2
//     saleNum?: string,   // 0
//     saleOccupyNum?: string,   // 0
//     shopId?: string,   // "64eec0c1d6f37078b6b460e4cb0ac9d7"
//     sreturnNum?: string,   // 0
//     stockCost?: string,   // 170
//     stockCostLatest?: string,   // 170
//     unusualSreturnNum?: string,   // 0
//     [key: string]: any
// }

//进销存报表
class PsiService {
    async page(searchVo) {
        const apiUrl = "/ci-storage/statistics/purchaseSaleStorage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async export(searchVo) {
        const apiUrl = "/ci-storage/statistics/purchaseSaleStorageExport";
        return await httpRequest.postFile(apiUrl, searchVo, { timeout: 300000 });
    }
    // 头部统计
    async statistics(searchVo) {
        const apiUrl = "/ci-storage/statistics/purchaseSaleStorageTotal";
        return await httpRequest.post(apiUrl, searchVo);
    }
    //占用明细查询接口
    async occupyDeatil(searchVo) {
        const apiUrl = "/ci-storage/statistics/occupyDeatil";
        return await httpRequest.post(apiUrl, searchVo);
    }
    //进销存明细
    async purchaseSaleStorageDeatil(searchVo) {
        const apiUrl = "/ci-storage/statistics/purchaseSaleStorageDeatil";
        return await httpRequest.post(apiUrl, searchVo);
    }

    // 库存明细查采销历史
    async purchaseSaleHistoryDeatil(searchVo) {
        const apiUrl = "/ci-storage/statistics/purchaseSaleStorageDeatil";
        return await httpRequest.post(apiUrl, searchVo);
    }

    async purchaseSaleStorageDeatilExport(searchVo) {
        const apiUrl = "/ci-storage/statistics/purchaseSaleStorageDeatilExport";
        return await httpRequest.postFile(apiUrl, searchVo, { timeout: 300000 });
    }
}

export const psiService = new PsiService();