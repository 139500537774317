import React from 'react'
import { Tooltip, message, Switch } from 'antd'
// import { getStorage } from "@/utils/dataStorage"
import { getUserCustomizeConfigOne } from '@/service/settings'
import { getPcodeDict } from '@/utils/dataStorage'
import { OrderReturnStatus } from '@/utils/enum.js'
import YhTooltip from '@/components/YhTooltip'
import TextPriviewImage from './TextPriviewImage'
// import { imageUrl } from "./InvoiceModel";
import { getToken } from '../../utils/dataStorage'
export const saveKey = 'saveOrdersManage'

// export const { imageUrl } = invoiceModel();

export const getAllTable = (props) => {
    let commonData = [
        {
            title: '工单编号',
            key: 'orderCode',
        },
        {
            title: '客户名称',
            key: 'customerName',
        },
        {
            title: '车辆牌照',
            key: 'customerVehicleCode',
        },
        {
            title: '报修用户',
            key: 'repairContactName',
        },
        {
            title: '联系电话',
            key: 'repairContactPhone',
        },
        {
            title: '工单状态',
            key: 'orderStatus',
        },
        {
            title: '开票状态',
            key: 'invoiceStatus',
        },
        {
            title: '发票类型',
            key: 'invoiceTypeName',
        },
        {
            title: '发票编号',
            key: 'invoiceNum',
        },
        {
            title: '车辆vin',
            key: 'customerVehicleVin',
        },
        {
            title: '发动机号',
            key: 'customerEngineCode',
        },
        {
            title: '三包信息',
            key: 'threeGuaranteesInfo',
        },
        {
            title: '故障描述',
            key: 'faultDesc',
        },
        {
            title: '维修团队',
            key: 'teamName',
        },
        {
            title: '服务方式',
            key: 'serviceMethod',
        },
        {
            title: '工单类型',
            key: 'orderType',
        },
        {
            title: '开单员',
            key: 'billName',
        },
        {
            title: '服务地点',
            key: 'repairAddress',
        },
        {
            title: '所属机构',
            key: 'organName',
        },

        {
            title: '工单来源',
            key: 'orderSource',
        },
        {
            title: '工时费',
            key: 'itemActualAmount',
        },
        {
            title: '预付款',
            key: 'prepayments',
        },
        {
            title: '应收金额',
            key: 'orderTotalAmount',
        },
        {
            title: '实收金额',
            key: 'orderActualAmount',
        },
        {
            title: '行驶里程数',
            key: 'customerVehicleMileage',
        },
        {
            title: '登记日期',
            key: 'createTime',
        },
        {
            title: '预约日期',
            key: 'reservationTime',
        },
        {
            title: '对接人',
            key: 'dockPeople',
        },
        {
            title: '备注',
            key: 'remark',
        },
        {
            title: '日志',
            key: 'logs',
        },
        {
            title: '维修成员',
            key: 'teamUsers',
        },
        {
            title: '处理结果',
            key: 'processResult',
        },
        {
            title: '取消原因',
            key: 'orderCancelReason',
        },
        {
            title: '审核人',
            key: 'reviewerName',
        },
        {
            title: '故障类型',
            key: 'serviceType',
        },
        // {
        //     title: '故障分类',
        //     key: 'faultClassification',
        // },
        {
            title: '主修人',
            key: 'majorUserName',
        },
        {
            title: '经营实体审核人',
            key: 'ciBusinessEntityReviewerName',
        },
        {
            title: '退单状态',
            key: 'orderReturnStatus',
        },
        {
            title: '打印次数',
            key: 'printingTimes',
        },
        {
            title: '财务打印次数',
            key: 'financialPrintingTimes',
        },
        {
            title: '支付状态',
            key: 'payStatusCn',
        },
        {
            title: '结算类型',
            key: 'payTypeCn',
        },
        {
            title: '结算方式',
            key: 'paymentmethodName',
        },
        {
            title: '结算方',
            key: 'settlementPartyName',
        },
        {
            title: '车辆类型',
            key: 'customerVehicleType',
        },
        {
            title: '车辆型号',
            key: 'customerVehicleModel',
        },
        {
            title: '结算时间',
            key: 'payTime',
        },
        {
            title: '核销时间',
            key: 'writeOffTime',
        },
        {
            title: '进场时间',
            key: 'entryFactoryTime',
        },
        {
            title: '出场时间',
            key: 'outFactoryTime',
        },
        {
            title: '结算备注',
            key: 'settlementRemark',
        },
    ]
    if (props && (props.isOrderReturnVisitStatus || props.isAll)) {
        commonData.push({
            title: '回访状态',
            key: 'orderReturnVisitStatus',
        })
    }

    return commonData
}

export const getTableColumns = async (props, columnsData) => {
    let columns = []
    if (columnsData) {
        columns = columnsData
    } else {
        try {
            // let storageColumns = await getStorage(props.saveKey || saveKey)
            // if (storageColumns) {
            //     columns = storageColumns
            // } else {
            let customizeConfig = await getUserCustomizeConfigOne(props.saveKey || saveKey)
            if (customizeConfig && customizeConfig.retCode == 200) {
                columns = customizeConfig.retData
                    ? JSON.parse(customizeConfig.retData.val)
                    : getAllTable({ isAll: true }).map((item) => {
                          return { ...item, isShow: true }
                      })
            } else {
                message.warning('获取table排序失败，使用默认排序')
                columns = getAllTable({ isAll: true }).map((item) => {
                    return { ...item, isShow: true }
                })
                return
            }
            // }
        } catch (error) {
            message.warning('获取table排序失败，使用默认排序')
            columns = getAllTable({ isAll: true }).map((item) => {
                return { ...item, isShow: true }
            })
        }
    }

    columns = columns.filter((item) => item.isShow)

    let newColumns = []
    columns.forEach((item) => {
        if (item.key === 'orderCode') {
            newColumns.push({
                title: '工单编号',
                width: 220,
                align: 'center',
                dataIndex: 'orderCode',
                render: (text, record) => (
                    <div style={{ color: '#2e3aeb', cursor: 'pointer' }}>
                        <YhTooltip
                            showText={<>{text}{ record.orderSource === "008006" &&  <span style={{color: '#EA1111',  background: "rgba(234,17,17,0.1)", fontWeight:"500",marginLeft: "3px", padding: "2px 5px"}}>TCO</span>}</>}
                            text={text}
                            maxWidth={220}
                            onClick={() => {
                                props.handleClickOrderCode(record)
                            }}
                        ></YhTooltip>
                    </div>
                ),
                sorter: true,
            })
        } else if (item.key === 'customerName') {
            newColumns.push({
                title: '客户名称',
                align: 'center',
                width: 220,
                dataIndex: 'customerName',
                render: (text) => <YhTooltip text={text} />,
                sorter: true,
            })
        } else if (item.key === 'customerVehicleCode') {
            newColumns.push({
                title: '车辆牌照',
                align: 'center',
                dataIndex: 'customerVehicleCode',
                width: 140,
                render: (text) => <YhTooltip text={text} />,
            })
        } else if (item.key === 'customerVehicleCode') {
            newColumns.push({
                title: '报修用户',
                align: 'center',
                width: 140,
                dataIndex: 'repairContactName',
                render: (text) => <YhTooltip maxWidth={220} text={text} />,
            })
        } else if (item.key === 'repairContactPhone') {
            newColumns.push({
                title: '联系电话',
                align: 'center',
                width: 140,
                dataIndex: 'repairContactPhone',
                render: (text) => <YhTooltip maxWidth={220} text={text} />,
            })
        } else if (item.key === 'orderStatus') {
            newColumns.push({
                title: '工单状态',
                align: 'center',
                width: 100,
                dataIndex: 'orderStatus',
                render: (text) => <YhTooltip maxWidth={220} text={props.dictObj.current.orderStatus[text]} />,
            })
        } else if (item.key === 'invoiceStatus') {
            newColumns.push({
                title: '开票状态',
                align: 'center',
                width: 100,
                dataIndex: 'settlementInfo',
                render: (v) => <YhTooltip text={v?.invoiceStatus == 1 ? '已开票' : '未开票'} />,
            })
        } else if (item.key === 'invoiceTypeName') {
            newColumns.push({
                title: '发票类型',
                align: 'center',
                width: 200,
                dataIndex: 'settlementInfo',
                render: (v) => <YhTooltip text={v?.invoiceTypeName} />,
            })
        } else if (item.key === 'invoiceNum') {
            newColumns.push({
                title: '发票编号',
                align: 'center',
                width: 200,
                dataIndex: 'settlementInfo',
                render: (v) => {
                    return (
                        <TextPriviewImage
                            text={v?.invoiceNum}
                            previewList={v?.invoiceMd5?.split(',')}
                            imageUrl={'https://api-2.yunhaogroup.com/fsrv/fetch/' + v?.invoiceMd5 + '?Authorization=' + getToken()}
                        />
                    )
                },
            })
        } else if (item.key === 'customerVehicleVin') {
            newColumns.push({
                title: '车辆vin',
                align: 'center',
                width: 200,
                dataIndex: 'customerVehicleVin',
                render: (text) => <YhTooltip text={text} />,
            })
        } else if (item.key === 'customerEngineCode') {
            newColumns.push({
                title: '发动机号',
                align: 'center',
                width: 200,
                dataIndex: 'customerEngineCode',
                render: (text) => <YhTooltip text={text} />,
            })
        } else if (item.key === 'threeGuaranteesInfo') {
            newColumns.push(
                props.saveKey !== 'saveCancelleOrderTableSort'
                    ? {
                          title: '三包信息',
                          align: 'center',
                          width: 200,
                          dataIndex: 'threeGuaranteesInfo',
                          render: (text, record) => {
                              if (record.orderType === '014001' || record.orderType === '014006') {
                                  return <div style={{ color: '#2e3aeb', cursor: 'pointer' }}>--</div>
                              } else {
                                  let br = <br></br>
                                  let result = null
                                  for (let j = 0; j < text.length; j++) {
                                      const data =
                                          props.dictObj.current.threeGuaranteesUnit[text[j].threeGuaranteesBrand] +
                                          (text[j].claimNumber ? `(${text[j].claimNumber})` : '')
                                      if (j === 0) {
                                          result = data
                                      } else {
                                          result = (
                                              <div key={text[j].id}>
                                                  {result}
                                                  {br}
                                                  {data}
                                              </div>
                                          )
                                      }
                                  }
                                  console.log(result)
                                  return (
                                      <div
                                          style={{ color: '#2e3aeb', cursor: 'pointer' }}
                                          onClick={() => {
                                              props.setShowSanbao(true)
                                              props.setChoosedOrderId(record.id)
                                          }}
                                      >
                                          {result ? result : '添加'}
                                      </div>
                                  )
                              }
                          },
                      }
                    : {
                          title: '三包信息',
                          align: 'center',
                          width: 200,
                          dataIndex: 'threeGuaranteesInfo',
                          render: (text, record) => {
                              if (record.orderType === '014001') {
                                  return <div style={{ color: '#2e3aeb', cursor: 'pointer' }}>--</div>
                              } else {
                                  let br = <br></br>
                                  let result = null
                                  for (let j = 0; j < text.length; j++) {
                                      const data =
                                          props.dictObj.current.threeGuaranteesUnit[text[j].threeGuaranteesBrand] +
                                          (text[j].claimNumber ? `(${text[j].claimNumber})` : '')
                                      if (j === 0) {
                                          result = data
                                      } else {
                                          result = (
                                              <span key={text[j].id}>
                                                  {result}
                                                  {br}
                                                  {data}
                                              </span>
                                          )
                                      }
                                  }
                                  return <div>{result ? result : ''}</div>
                              }
                          },
                      }
            )
        } else if (item.key === 'faultDesc') {
            newColumns.push({
                title: '故障描述',
                align: 'center',
                width: 180,
                dataIndex: 'faultDesc',
                render: (text) => (
                    <Tooltip placement="topLeft" title={text} arrowPointAtCenter>
                        {text && (
                            <div>
                                <p className="ellipsis" style={{ textAlign: 'left' }}>
                                    {text.substring(0, 15)}
                                </p>
                                <p className="ellipsis" style={{ textAlign: 'left' }}>
                                    {text.substring(15, 30)}
                                </p>
                            </div>
                        )}
                    </Tooltip>
                ),
            })
        } else if (item.key === 'teamName') {
            newColumns.push({
                title: '维修团队',
                align: 'center',
                dataIndex: 'teamName',
                width: 120,
                render: (text) => <YhTooltip maxWidth={220} text={text} />,
            })
        } else if (item.key === 'serviceMethod') {
            newColumns.push({
                title: '服务方式',
                align: 'center',
                dataIndex: 'serviceMethod',
                render: (text) => <YhTooltip maxWidth={220} text={props.dictObj.current.serviceMethod[text]} />,
                width: 80,
            })
        } else if (item.key === 'orderType') {
            newColumns.push({
                title: '工单类型',
                align: 'center',
                dataIndex: 'orderType',
                render: (text) => <YhTooltip maxWidth={220} text={props.dictObj.current.orderType[text]} />,
                width: 80,
            })
        } else if (item.key === 'billName') {
            newColumns.push({
                title: '开单员',
                align: 'center',
                dataIndex: 'billName',
                width: 100,
                render: (text) => <YhTooltip maxWidth={220} text={text} />,
            })
        } else if (item.key === 'repairAddress') {
            newColumns.push({
                title: '服务地点',
                align: 'center',
                dataIndex: 'repairAddress',
                width: 180,
                render: (text) => (
                    <Tooltip placement="topLeft" title={text} arrowPointAtCenter>
                        {text && (
                            <div>
                                <p className="ellipsis" style={{ textAlign: 'left' }}>
                                    {text.substring(0, 15)}
                                </p>
                                <p className="ellipsis" style={{ textAlign: 'left' }}>
                                    {text.substring(15, 30)}
                                </p>
                            </div>
                        )}
                    </Tooltip>
                ),
            })
        } else if (item.key === 'organName') {
            newColumns.push({
                title: '所属机构',
                align: 'center',
                dataIndex: 'organName',
                width: 80,
            })
        } else if (item.key === 'orderSource') {
            newColumns.push({
                title: '工单来源',
                align: 'center',
                dataIndex: 'orderSource',
                //render: (text) => <>{props.dictObj.current.orderSource[text]}</>,
                width: 90,
                render: (text) => <YhTooltip maxWidth={220} text={props.dictObj.current.orderSource[text]} />,
            })
        } else if (item.key === 'itemActualAmount') {
            newColumns.push({
                title: '工时费',
                align: 'center',
                dataIndex: 'itemActualAmount',
                width: 80,
            })
        } else if (item.key === 'prepayments') {
            newColumns.push({
                title: '预付款',
                align: 'center',
                dataIndex: 'prepayments',
                width: 80,
            })
        } else if (item.key === 'orderTotalAmount') {
            newColumns.push({
                title: '应收金额',
                align: 'center',
                dataIndex: 'orderTotalAmount',
                width: 120,
            })
        } else if (item.key === 'orderActualAmount') {
            newColumns.push({
                title: '实收金额',
                align: 'center',
                dataIndex: 'orderActualAmount',
                width: 120,
            })
        } else if (item.key === 'customerVehicleMileage') {
            newColumns.push({
                title: '行驶里程数',
                align: 'center',
                dataIndex: 'customerVehicleMileage',
                width: 100,
            })
        } else if (item.key === 'createTime') {
            newColumns.push({
                title: '登记日期',
                align: 'center',
                width: 200,
                dataIndex: 'createTime',
            })
        } else if (item.key === 'reservationTime') {
            newColumns.push({
                title: '预约日期',
                align: 'center',
                width: 200,
                dataIndex: 'reservationTime',
            })
        } else if (item.key === 'dockPeople') {
            newColumns.push({
                title: '对接人',
                align: 'center',
                dataIndex: 'dockPeople',
                width: 80,
                render: (text) => <YhTooltip maxWidth={220} text={text} />,
            })
        } else if (item.key === 'teamUsers') {
            newColumns.push({
                title: '维修成员',
                align: 'center',
                dataIndex: 'teamUsers',
                width: 180,
            })
        } else if (item.key === 'remark') {
            newColumns.push({
                title: '备注',
                align: 'center',
                dataIndex: 'remark',
                width: 180,
                render: (text) => (
                    <Tooltip placement="topLeft" title={text} arrowPointAtCenter>
                        {text && (
                            <div>
                                <p className="ellipsis" style={{ textAlign: 'left' }}>
                                    {text.substring(0, 15)}
                                </p>
                                <p className="ellipsis" style={{ textAlign: 'left' }}>
                                    {text.substring(15, 30)}
                                </p>
                            </div>
                        )}
                    </Tooltip>
                ),
            })
        } else if (item.key === 'logs') {
            newColumns.push({
                title: '日志',
                align: 'center',
                width: 100,
                dataIndex: 'id',
                render: (text, record) => (
                    <div
                        style={{ color: '#2e3aeb', cursor: 'pointer' }}
                        onClick={() => {
                            props.setShowLogs(true)
                            props.setChoosedOrderId(record.id)
                        }}
                    >
                        查看日志
                    </div>
                ),
            })
        } else if (item.key === 'orderReturnVisitStatus') {
            newColumns.push({
                title: '回访状态',
                align: 'center',
                width: 80,
                dataIndex: 'orderReturnVisitStatus',
                render: (text, record) => (
                    <Switch
                        size="small"
                        checked={text === 1 ? true : false}
                        onChange={(checked, e) => props.returnVisitStatusSweitchChange(checked, e, record.id)}
                        checkedChildren="已回访"
                        unCheckedChildren="未回访"
                    />
                ),
            })
        } else if (item.key === 'processResult') {
            newColumns.push({
                title: '处理结果',
                align: 'center',
                dataIndex: 'processResult',
                width: 180,
                render: (text) => (
                    <Tooltip placement="topLeft" title={text} arrowPointAtCenter>
                        {text && (
                            <div>
                                <p className="ellipsis" style={{ textAlign: 'left' }}>
                                    {text.substring(0, 15)}
                                </p>
                                <p className="ellipsis" style={{ textAlign: 'left' }}>
                                    {text.substring(15, 30)}
                                </p>
                            </div>
                        )}
                    </Tooltip>
                ),
            })
        } else if (item.key === 'orderCancelReason') {
            newColumns.push({
                title: '取消原因',
                align: 'center',
                dataIndex: 'orderCancelReason',
                width: 180,
                // render: (text) => <>{props.dictObj.current.serviceMethod[text]}</>,
                render: (text) => {
                    if (text && text.indexOf('041') > -1) {
                        const obj = getPcodeDict('041').children.find((item) => item.dictCode === text)
                        return (
                            <p className="ellipsis" style={{ textAlign: 'left' }}>
                                {obj.title}
                            </p>
                        )
                    } else {
                        return (
                            <Tooltip placement="topLeft" title={text} arrowPointAtCenter>
                                {text && (
                                    <div>
                                        <p className="ellipsis" style={{ textAlign: 'left' }}>
                                            {text.substring(0, 15)}
                                        </p>
                                        <p className="ellipsis" style={{ textAlign: 'left' }}>
                                            {text.substring(15, 30)}
                                        </p>
                                    </div>
                                )}
                            </Tooltip>
                        )
                    }
                },
            })
        } else if (item.key === 'reviewerName') {
            newColumns.push({
                title: '审核人',
                align: 'center',
                dataIndex: 'reviewerName',
                width: 80,
            })
        } else if (item.key === 'serviceType') {
            newColumns.push({
                title: '故障类型',
                align: 'center',
                dataIndex: 'serviceType',
                render: (text) => <YhTooltip maxWidth={220} text={text && props.dictObj.current.serviceType[text]} />,
                width: 90,
            })
        } else if (item.key === 'majorUserName') {
            newColumns.push({
                title: '主修人',
                align: 'center',
                dataIndex: 'majorUserName',
                width: 80,
                render: (text) => <YhTooltip maxWidth={220} text={text} />,
            })
        } else if (item.key === 'ciBusinessEntityReviewerName') {
            newColumns.push({
                title: '经营实体审核人',
                align: 'center',
                dataIndex: 'ciBusinessEntityReviewerName',
                width: 80,
            })
        } else if (item.key === 'orderReturnStatus') {
            newColumns.push({
                title: '退单状态',
                align: 'center',
                dataIndex: 'orderReturnStatus',
                width: 80,
                render: (text) => OrderReturnStatus[text],
            })
        } else if (item.key === 'printingTimes') {
            newColumns.push({
                title: '打印次数',
                align: 'center',
                dataIndex: 'printingTimes',
                width: 90,
            })
        }else if (item.key === 'financialPrintingTimes') {
            newColumns.push({
                title: '财务打印次数',
                align: 'center',
                dataIndex: 'financialPrintingTimes',
                width: 120,
            })
        } else if (item.key === 'payStatusCn') {
            newColumns.push({
                title: '支付状态',
                align: 'center',
                dataIndex: 'payStatusCn',
                width: 90,
            })
        } else if (item.key === 'payTypeCn') {
            newColumns.push({
                title: '结算类型',
                align: 'center',
                dataIndex: 'payTypeCn',
                width: 90,
            })
        } else if (item.key === 'paymentmethodName') {
            newColumns.push({
                title: '结算方式',
                align: 'center',
                dataIndex: 'paymentmethodName',
                width: 90,
            })
        } else if (item.key === 'settlementPartyName') {
            newColumns.push({
                title: '结算方',
                align: 'center',
                dataIndex: 'settlementPartyName',
                width: 90,
            })
        } else if (item.key === 'payTime') {
            newColumns.push({
                title: '结算时间',
                align: 'center',
                dataIndex: 'payTime',
                width: 90,
            })
        } else if (item.key === 'writeOffTime') {
            newColumns.push({
                title: '核销时间',
                align: 'center',
                dataIndex: 'writeOffTime',
                width: 90,
            })
        } else if (item.key === 'entryFactoryTime') {
            newColumns.push({
                title: '进场时间',
                align: 'center',
                dataIndex: 'entryFactoryTime',
                width: 120,
            })
        } else if (item.key === 'outFactoryTime') {
            newColumns.push({
                title: '出场时间',
                align: 'center',
                dataIndex: 'outFactoryTime',
                width: 120,
            })
        } else if (item.key === 'settlementRemark') {
            newColumns.push({
                title: '结算备注',
                align: 'center',
                dataIndex: 'settlementRemark',
                width: 120,
            })
        }else if (item.key === 'customerVehicleType') {
            newColumns.push({
                title: '车辆类型',
                align: 'center',
                dataIndex: 'customerVehicleType',
                width: 120,
            })
        }else if (item.key === 'customerVehicleModel') {
            newColumns.push({
                title: '车辆型号',
                align: 'center',
                dataIndex: 'customerVehicleModel',
                width: 120,
            })
        }
    })
    return newColumns
}
