import React, { useRef, useState, useEffect } from "react";
import { message } from "antd";
import YhDrawer from "@/erp_subpackage/components/YhDrawer";
import { YhForm } from "@/erp_subpackage/components/YhFormBox";
import { getFormValidatorErrText } from "@/erp_subpackage/utils/util";
import { useWarehouseModel } from "../../warehouseModel";
import { debounce } from "lodash";

//货位
export function ShelfDrawer() {
    const {
        shelfDrawer: { visible, mode },
        editFormVal,
        selectedShelfRows,
        setIShelfDrawer,
        insertWarehouseShelf,
        updateWarehouseShelf
    } = useWarehouseModel();

    const formRef = useRef();

    const [isBatch, setIsBatch] = useState(true);

    //监听批量录入字段
    const onChange = (changedValues) => {
        if ('isBatch' in changedValues) {
            setIsBatch(!changedValues['isBatch']);
        }
    }

    const onCancel = () => {
        setIShelfDrawer();
    }

    useEffect(() => {
        !isBatch && formRef.current.setFieldsValue({ code: null, name: null });
    }, [isBatch]);

    //表单提交
    const onSubmit = async () => {
        formRef.current.validateFields().then(val => {
            let data = {
                ...val,
                code: val.code?.trim()
            }
            if (mode === "edit") {
                updateWarehouseShelf(data)
            } else {
                insertWarehouseShelf(data)
            }
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }
    const formConfig = {
        formRef,
        formValues: mode === "edit" ? selectedShelfRows[0] : { warehouseName: editFormVal.name, isBatch: !isBatch },
        labelCol: { span: 6 },
        onChange,
        items: [
            {
                type: "Input",
                fieldLabel: "所属仓库",
                fieldName: "warehouseName",
                span: 24,
                disable: true
            }, {
                type: "Input",
                fieldLabel: "货位编码",
                fieldName: "code",
                span: 24,
                disable: !isBatch,
                rules: [{ required: isBatch, message: "请输入货位编码" }]
            }, {
                type: "Input",
                fieldLabel: "备注",
                fieldName: "note",
                span: 24,
            }, {
                type: "Input",
                fieldLabel: "起始编码",
                fieldName: "startCode",
                span: 24,
                hidden: isBatch,
                rules: [{ required: !isBatch, message: "请输入起始编码" }, { pattern: /^[0-9]*$/, message: "请输入数值类型" }]
            }, {
                type: "Input",
                fieldLabel: "数量",
                fieldName: "num",
                span: 24,
                hidden: isBatch,
                rules: [{ required: !isBatch, message: "请输入数量" }, { pattern: /^[0-9]*$/, message: "请输入数值类型" }]
            }, {
                type: "Input",
                fieldLabel: "前缀",
                fieldName: "prefix",
                span: 24,
                hidden: isBatch,
                rules: [{ required: !isBatch, message: "请输入前缀" }]
            }, {
                type: "Checkbox",
                fieldLabel: "批量录入",
                fieldName: "isBatch",
                span: 24,
                hidden: mode === "edit" ? true : false
            }
        ]
    };

    return (
        <YhDrawer
            {...{
                title: `货位${mode === "edit" ? "编辑" : "新增"}`,
                visible,
                onClose: onCancel,
                okText: "保存",
                width: 600,
                onOk: debounce(() => { onSubmit() }, 900)
            }}
        >
            <div style={{ padding: "20px 36px" }}><YhForm {...formConfig} /></div>
        </YhDrawer>
    )
}
