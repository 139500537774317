import {useState} from "react";
import {createModel} from "hox";
import {supplierService} from "./supplierService";
import {message} from "antd"
import {convertRes2Blob} from "@/erp_subpackage/utils/exportFile";
import useGlobalModel, {transPgToPagination, defaultMdCmParam} from "@/erp_subpackage/model/globalModel";
import {useDictModel} from "../dict/dictModel";
import {useLockFn} from "ahooks";
import {getUserInfo} from "@/utils/dataStorage"
import {customerService} from "../customer/customerService";

export const useSupplierModel = createModel(function () {
    const {sourceApp, branch} = defaultMdCmParam;
    const {user: {shopId, shopCode, shopName, organType, orgCode, orgName}, orgDs} = useGlobalModel()
    const {getTreeNodeName} = useDictModel();
    const [uploading, setUploading] = useState({spinning: false, tip: "加速上传中..."});

    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false)
    //分页参数
    const initPgCfg = {pageSize: 15, current: 1}
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [saveBtnLoading, setSaveBtnLoading] = useState(false); //  保存按钮
    //所有列表查询
    //列表查询
    const loadData = async (page, searchValue = searchParam) => {
        setLoading(true);
        const searchParams = {
            ...searchValue,
            shopId: searchValue.shopId ?? shopId,
            shopName,
            shopCode,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: page?.pageNum ?? pgCfg.current ?? initPgCfg.current,
        }
        let {retData} = await supplierService.page(searchParams);
        const {records, ...pg} = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
        setLoading(false);
        // //机构账号登陆时候， 当没有数据时候自动初始一条平台机构信息为供应商
        // if (!organType && !records.length) {
        //     let { label, value, organType, ...orgInfo } = orgDs.find(item => item.organ_type === 3) ?? {};
        //     //查到平台信息,新增一条信息
        //     label && insertSupplier({
        //         ...orgInfo,
        //         name: label,
        //         enable: true
        //     }, false).then(res => {
        //         loadData()
        //     })
        // }
    }
    //设置搜索value
    const updateSearchParam = (updateParam) => {
        setSearchParam({
            ...searchParam,
            ...updateParam
        })
    }
    //重置搜索参数
    const resetSearchParam = async () => {
        await setSearchParam({});
        await setSelectedRowKeys([]);
        await loadData(initPgCfg, {});
    }
    /**
     * 表单详情
     */
        //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState({});
    //新增表单缓存
    const [addFormVal, setAddFormVal] = useState({enable: true});
    //查看表单缓存
    const [lookFormVal, setLookFormVal] = useState({});

    //表单新增修改
    const updateAddFormVal = (val) => {
        val ? setAddFormVal({
            ...addFormVal,
            ...val
        }) : setAddFormVal({enable: true});
    }
    //表单新增修改
    const updateEditFormVal = (val) => {
        val ? setEditFormVal({
            ...editFormVal,
            ...val
        }) : setEditFormVal({});
    }
    //获取一条详情数据 Api
    const getSupplierOne = async (id, pattern) => {
        let {retData} = await supplierService.one({id,shopCode});
        if (retData && pattern === "edit") {
            setEditFormVal(retData);
        } else if (retData && pattern === "look") {
            setLookFormVal(retData);
        }
    }

    //表单新增 Api
    const insertSupplier = useLockFn(async (data, isMessage = true) => {
        setSaveBtnLoading(true)
        let {isTransfer, ...val} = data;
        let params = {
            ...val,
            shopCode,
            shopName,
            shopId,
            orgCode,
            orgName,
            sourceApp,
            branch,
            createUser: getUserInfo() ? getUserInfo().nickname : "未获取到",
            typeName: getTreeNodeName(data.typeCode),
            enable: data.enable ? 1 : 0,
        }

        let {retData} = await supplierService.insert(params);
        if (isTransfer) {
            await customerService.insert(params);
        }
        if (isMessage) {
            retData ? message.success("供应商新增成功") : message.error("供应商新增失败");
        }
        if (retData) {
            await supplierService.supplierSync({ name: data.name }).then(res => {
                res ? message.success("供应商账户信息同步成功...") : message.error("同步失败");
            })
        }
        setSaveBtnLoading(false)
        return retData;
    })

    //表单编辑 Api
    const editSupplier = useLockFn(async (data) => {
        setSaveBtnLoading(true)
        let params = {
            ...data,
            shopCode,
            shopName,
            shopId,
            orgCode,
            orgName,
            sourceApp,
            branch,
            createUser: getUserInfo() ? getUserInfo().nickname : "未获取到",
            typeName: getTreeNodeName(data.typeCode),
            enable: data.enable ? 1 : 0,

        }
        let {retData} = await supplierService.update(params);
        retData ? message.success("供应商编辑成功") : message.error("供应商编辑失败");
        if (retData) {
            await supplierService.supplierSync({ name: data.name }).then(res => {
                res ? message.success("供应商账户信息同步成功...") : message.error("同步失败");
            })
        }
        setSaveBtnLoading(false)
        return retData;
    })
    //供应商状态
    const onDisabled = async (code, checked) => {
        let {retData} = await supplierService.disable({code,shopCode, enable: checked ? 1 : 0})
        retData ?
            loadData() && message.success("供应商编辑成功") : message.error("供应商编辑失败");
        return retData;
    }
    //导入
    const importSupplier = async (file) => {
        let {retData} = await supplierService.import(file);
        retData ?
            loadData() && message.success("导入成功") : message.error("导入失败");
        return retData;
    }

    //导出
    const exportSupplier = async (searchValue = searchParam) => {
        const searchParams = {
            ...searchValue,
            shopId: searchValue.shopId ?? shopId,
            ids: selectedRowKeys,
            shopCode
        }
        let {retData} = await supplierService.export(searchParams).catch(r=>{ message.error(r)});
        message.success(retData)
    }


    //下载模板
    const downloadTpl = async () => {
        let result = await supplierService.downloadTpl();
        convertRes2Blob(result)
    }
    //同步
    const sync = async (params = {}) => {
        setUploading({spinning: true, tip: "正在同步中..."})
        try {
            let result = await supplierService.sync({
                ...params,
                shopId,
                shopName,
                "sourceApp": "ci"
            });
            loadData()
            setUploading({spinning: false})
            message.success("同步成功")
        } catch (err) {
            setUploading({spinning: false})
        }
    }

    return {
        /***field***/
        searchParam, organType,
        dataSource,
        selectedRowKeys,
        editFormVal,
        addFormVal,
        lookFormVal,
        pgCfg,
        loading,
        /***method***/
        updateAddFormVal,
        updateEditFormVal,
        insertSupplier,
        editSupplier,
        setLoading,
        setPgCfg,
        getSupplierOne,
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        importSupplier,
        exportSupplier,
        downloadTpl,
        setEditFormVal,
        setAddFormVal,
        onDisabled,
        setLookFormVal,
        uploading, setUploading, sync,setSaveBtnLoading,saveBtnLoading
    }
});
