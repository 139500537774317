import React, { useContext, useState, useEffect, useRef } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { shopGetOrderList, shopLogiCompanies, shopOrdersDelivery, shopGetOrderStatic } from "@/service/shop"
import { getCustomerOne } from "@/service/customerService"
import { MyContext } from "@/store/myContext"
import { getPcodeDict } from "@/utils/dataStorage"
import { message, Button, Modal, Form, Row, Col, Input, Select } from "antd"
import moment from "moment"
import Loading from "@/components/Loading"
import { shopDownLoad, convertRes2Blob } from "@/utils/exportExcel"
import YhTooltip from "@/components/YhTooltip"
const { Option } = Select

//商城订单
export default () => {
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const { state, dispatch, detailsPage } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [loading, setLoading] = useState(true) //表格 loading
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [showSend, setShowSend] = useState(false)
    const [form] = Form.useForm()
    const [companies, setCompanies] = useState([])
    const [subTitle, setSubTitle] = useState([])
    const [customerType, setCustomerType] = useState({})

    useEffect(() => {
        if (state.activeMenu === "shopOrderc" || state.activeMenu === "shopOrderb") {
            loadData(pagination.current)
            getCompanies()
        }
    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let obj = {}
        getPcodeDict("017").children.forEach((item) => {
            obj[item.dictCode] = item.title
        })
        setCustomerType(obj)
    }, [])

    //列表数据
    const loadData = (current, search = {}) => {
        setLoading(true)
        let data = {
            ...search,
            page_no: current,
            page_size: 10,
            start_time: search.start_time ? moment(search.start_time).valueOf() : null,
            end_time: search.end_time ? moment(search.end_time).valueOf() : null,
            is_prod: search.is_prod ? 0 : 1,
        }
        shopGetOrderList(data)
            .then((res) => {
                setpagination({
                    current: current,
                    total: res.data_total,
                    pageSize: 10,
                })
                setList(res.data.map((item) => ({ ...item, id: item.sn })))
                setLoading(false)
            })
            .catch((error) => {
                if (error === 403) {
                    loadData(current, search)
                }
            })
        shopGetOrderStatic(data).then((res) => {
            let subTitle = [
                `订单总数量:${res.order_num || 0}单`,
                `未付款订单:${res.un_paid || 0}单`,
                `已发货订单:${res.shipped || 0}单`,
                `未发货订单:${res.shipped || 0}单`,
                `已取消订单:${res.cancelled || 0}单`,
                `已付款订单:${res.paid || 0}单`,
                `已收款金额:${res.pay_amount || 0}元`,
            ]
            setSubTitle(subTitle)
        })
    }

    const showCustomerInfo = (id) => {
        // id = "7847c071d9d596f4bfff45df426507f0"
        getCustomerOne(id).then((res) => {
            let retData = res.retData
            Modal.info({
                title: retData.customerName,
                width: 600,
                content: (
                    <Row>
                        <Col span="12">
                            <span style={{ display: "inline-block", marginRight: "10px", width: "80px", textAlign: "right" }}>客户类型:</span>
                            {retData.customerType || "--"}
                        </Col>
                        <Col span="12">
                            <span style={{ display: "inline-block", marginRight: "10px", width: "80px", textAlign: "right" }}>业务经理:</span>
                            {retData.businessManagerName || "--"}
                        </Col>
                        <Col span="12">
                            <span style={{ display: "inline-block", marginRight: "10px", width: "80px", textAlign: "right" }}>所属统计:</span>
                            {retData.statisticianName || "--"}
                        </Col>
                        <Col span="12">
                            <span style={{ display: "inline-block", marginRight: "10px", width: "80px", textAlign: "right" }}>联系地址:</span>
                            {retData.customerAddress || "--"}
                        </Col>
                        <Col span="12">
                            <span style={{ display: "inline-block", marginRight: "10px", width: "80px", textAlign: "right" }}>联系人:</span>
                            {retData.customerCorporation || "--"}
                        </Col>
                        <Col span="12">
                            <span style={{ display: "inline-block", marginRight: "10px", width: "80px", textAlign: "right" }}>联系电话:</span>
                            {retData.customerCorporationPhone || "--"}
                        </Col>
                        <Col span="12">
                            <span style={{ display: "inline-block", marginRight: "10px", width: "80px", textAlign: "right" }}>所属机构:</span>
                            {retData.organName || "--"}
                        </Col>
                        <Col span="12">
                            <span style={{ display: "inline-block", marginRight: "10px", width: "80px", textAlign: "right" }}>所属团队:</span>
                            {retData.teamName || "--"}
                        </Col>
                        <Col span="12">
                            <span style={{ display: "inline-block", marginRight: "10px", width: "80px", textAlign: "right" }}>所属实体:</span>
                            {retData.ciBusinessEntityName || "--"}
                        </Col>
                        <Col span="12">
                            <span style={{ display: "inline-block", marginRight: "10px", width: "80px", textAlign: "right" }}>推荐人:</span>
                            {retData.referrerName || "--"}
                        </Col>
                        <Col span="12">
                            <span style={{ display: "inline-block", marginRight: "10px", width: "80px", textAlign: "right" }}>登记日期:</span>
                            {retData.createTime || "--"}
                        </Col>
                    </Row>
                ),
                okText: "确定",
                onOk: () => {},
            })
        })
    }

    //快递列表
    const getCompanies = () => {
        shopLogiCompanies()
            .then((res) => {
                setCompanies(res.filter((item) => item.open))
            })
            .catch((err) => {
                if (err === 403) {
                    getCompanies()
                }
            })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }


    const [isOrderCodeClick, setIsOrderCodeClick] = useState(false)
    useEffect(() => {
        if (isOrderCodeClick) {
            handleDetails("edit")
            setIsOrderCodeClick(false)
        }
    }, [isOrderCodeClick]) // eslint-disable-line react-hooks/exhaustive-deps

    //table配置
    const columns = [
        {
            title: "订单编号",
            align: "center",
            dataIndex: "sn",
            render: (text, record, index) => (
                <div style={{ color: "#2e3aeb", cursor: "pointer" }}>
                    <YhTooltip
                        text={text}
                        onClick={() => {
                            setSelectInfo({ rowInfo: record, rowIndex: index })
                            setIsOrderCodeClick(true)
                        }}
                    />
                </div>
            ),
        },
        {
            title: "下单时间",
            align: "center",
            dataIndex: "create_time",
            render: (text) => moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            title: "客户名称",
            align: "center",
            dataIndex: "ci_customer_name",
            render: (text, record) => (
                <div
                    style={{ color: "#2e3aeb", cursor: "pointer" }}
                >
                    <YhTooltip
                        text={text}
                        onClick={() => showCustomerInfo(record.ci_customer_id)}
                    />
                </div>
            ),
        },
        {
            title: "买家",
            align: "center",
            dataIndex: "member_name",
        },
        {
            title: "收货人",
            align: "center",
            dataIndex: "ship_name",
        },
        {
            title: "联系电话",
            align: "center",
            dataIndex: "ship_mobile",
        },
        {
            title: "收货地址",
            align: "center",
            dataIndex: "ship_addr",
        },
        {
            title: "订单状态",
            align: "center",
            dataIndex: "order_status_text",
        },
        {
            title: "付款方式",
            align: "center",
            dataIndex: "payment_type",
            render: (text) => (text === "ONLINE" ? "在线支付" : "货到付款"),
        },
        {
            title: "付款渠道",
            align: "center",
            dataIndex: "payment_name",
        },
        {
            title: "实付金额",
            align: "center",
            dataIndex: "order_amount",
        },
        {
            title: "客户类型",
            align: "center",
            dataIndex: "ci_customer_type",
            render: (text) => <>{customerType[text]}</>,
        },
        {
            title: "操作",
            align: "center",
            width: "12%",
            dataIndex: "sn",
            render: (text, record) => (
                <Button
                    type="primary"
                    disabled={!record.order_operate_allowable_vo.allow_ship}
                    onClick={() => {
                        handleFahuo(text, record)
                    }}
                >
                    发货
                </Button>
            ),
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入客户名称",
                valueName: "ci_customer_name",
            },
            {
                type: "input",
                placeholder: "请输入收货人姓名",
                valueName: "ship_name",
            },
            {
                type: "input",
                placeholder: "手机号",
                valueName: "ship_mobile",
            },
            {
                type: "select",
                placeholder: "请选择订单状态",
                valueName: "order_status",
                optionList: () => (
                    <>
                        <Option value="ALL">全部</Option>
                        <Option value="WAIT_PAY">待付款</Option>
                        <Option value="WAIT_SHIP">待发货</Option>
                        <Option value="WAIT_ROG">待收货</Option>
                        <Option value="COMPLETE">已完成</Option>
                        <Option value="CANCELLED">已取消</Option>
                    </>
                ),
            },
            {
                type: "RangePicker",
                valueName: ["start_time", "end_time"],
                span: 16,
                xxl: 8,
            },
        ],
        hideList: [
            {
                type: "Checkbox",
                valueName: "is_prod",
                placeholder: "所有订单",
                defaultChecked: searchValue.is_prod ? true : false,
            },
        ],
    }

    const handleFahuo = () => {
        setShowSend(true)
    }

    const handleCancel = () => {
        setShowSend(false)
    }

    const handleOk = () => {
        form.validateFields().then((values) => {
            const logi_name = companies.find((item) => item.logistics_company_do.id === values.logi_id).logistics_company_do.name
            const data = {
                ...values,
                logi_name,
            }
            const sn = selectInfo.rowInfo.sn
            shopOrdersDelivery(sn, data)
                .then((res) => {
                    message.success("发货成功")
                    setShowSend(false)
                    loadData(pagination.current, searchValue)
                })
                .catch((err) => {
                    if (err === 403) {
                        handleOk()
                    }
                })
        })
    }

    //列表button
    const topButtons = [
        {
            click: () => handleDetails("look"),
            content: "查看",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => exportData(),
            content: "导出",
        },
    ]

    const exportData = async () => {
        setExportLoading(true)
        console.log(searchValue)
        let val = ""
        Object.entries(searchValue).forEach((item, index) => {
            if (index === 0) {
                val = `?${item[0]}=${item[1]}`
            } else {
                val += `$${item[0]}=${item[1]}`
            }
        })
        let resFlie = await shopDownLoad(`/seller/trade/orders/exportFile${val}`)
        if (resFlie && resFlie.status === 200) {
            message.success("已导出")
            convertRes2Blob(resFlie)
        } else {
            message.warning("导出异常，请重新尝试")
        }
        setExportLoading(false)
    }

    const handleDetails = async (mode) => {
        let tabName = {
            look: "订单查看",
            edit: "订单编辑",
        }
        let modeKey = "shopOrderc/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的订单信息")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的订单信息")
                return
            }

            //每次查看把之前的删除， 重新生成
            await dispatch({
                type: "removeTabs",
                targetKey: modeKey,
            })
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.ShopOrderDetail({
                modeKey,
                dispatch,
                mode,
                rowInfo: selectInfo.rowInfo,
                companies,
            }),
        })
    }

    return (
        <>
            <TopSearch
                title="商城订单"
                justify="start"
                searchListData={searchList}
                searchValue={searchValue}
                setSearchValue={(obj) => setSearchValue(obj)}
                handleSearch={loadData}
            />
            <ListComponent
                subTitle={subTitle}
                loading={loading}
                title="商城订单"
                buttons={topButtons}
                columns={columns}
                dataSource={list}
                pagination={pagination}
                onTableChange={onTableChange}
                selectInfo={selectInfo}
                setSelectInfo={(obj) => setSelectInfo(obj)}
            />

            <Modal title="物流信息" visible={showSend} onOk={handleOk} onCancel={handleCancel}>
                <Form form={form}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="配送公司"
                                name="logi_id"
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择配送公司",
                                    },
                                ]}
                            >
                                <Select placeholder="请选择配送公司" style={{ width: "100%" }}>
                                    {companies.map((item) => (
                                        <Option value={item.logistics_company_do.id} item={item} key={item.logistics_company_do.id}>
                                            {item.logistics_company_do.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="物流单号"
                                name="ship_no"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入物流单号",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入物流单号" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="销售单号"
                                name="sales_order_no"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入怀远销售单号",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入怀远销售单号" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            {exportLoading && <Loading />}
        </>
    )
}
