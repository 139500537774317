import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import { MyContext } from "@/store/myContext"
import Detail from "./detail"
import { returnOrderList, exportReturnOrderData } from "@/service/ordersService"
import TopSearch from "@/components/TopSearch"
import { OrderReturnStatus } from "@/utils/enum"
import YhTooltip from "@/components/YhTooltip"
import { useOrderTableChange} from '../orderHooks'
import { ReturnOrderPrint } from "./returnOrderPrint"
//访问列表
export default () => {
    const { state, message } = useContext(MyContext)
    const [showVisible, setShowVisible] = useState(false)
    const [showMode, setShowMode] = useState("")
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [print, setPrint] = useState(false)
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    
    
    const [selectedRowKeys, setSelectedRowKeys] = useState([]) 
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    //const [rowSelection] = useOrderTableChange( list,setSelectInfo)
    const [loading, setLoading] = useState(false)

    useEffect((record) => {
      
        if (state.activeMenu === "returnOrder") {
            loadData(pagination.current)
        }
    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps


     //点击行选高亮
    const onRow = (record) => {
            let { id } = record     
            let newSelectedRowKeys = [...selectedRowKeys];
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        };

    //列表数据
    const loadData = (current, search = {}) => {
        setLoading(true)
        let data = {
            searchItem: { ...search },
            startTime: search.startTime ? search.startTime : null,
            endTime: search.endTime ? search.endTime : null,
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        returnOrderList(data)
            .then((res) => {
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                setList(res.retData.list)
            })
            .finally((res) => {
                setLoading(false)
            })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => exportOrder("edit"),
            content: "导出",
        },
        {
            click: () => onPrint(),
            content: "打印",
        },
    ]
    //打印
    const onPrint = () => {
        if (selectedRowKeys.length !== 1) {
            message.warning('请选择单条数据进行编辑')
            return;
        }
        setPrint(true)
    }
    //新增
    const handleDetails = async (mode) => {
        if (mode === "edit") {
            if (selectedRowKeys.length !== 1) {
                message.warning("请选择你要编辑的工单")
                return
            }
        }
        setShowMode(mode)
        setShowVisible(true)
    }

    const toDetail = () => {
        setShowMode("edit")
        setShowVisible(true)
    }

    //工单导出
    const exportOrder = async () => {
        let data = {
            searchItem: { ...searchValue ,ids:selectedRowKeys},
            pageNum: pagination.current,
            startTime: searchValue.startTime ? searchValue.startTime : null,
            endTime: searchValue.endTime ? searchValue.endTime : null,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        exportReturnOrderData(data).then((res) => {
            message.success(res.retData)
        })
    }

    //table配置
    const columns = [
        {
            title: "退单单号",
            align: "center",
            dataIndex: "returnCode",
            width: 180,
            render: (text, record, index) => (
                <div style={{ color: "#2e3aeb", cursor: "pointer" }}>
                    <YhTooltip
                        text={text}
                        onClick={() => {
                            setSelectInfo({ rowInfo: record, rowIndex: index })
                            toDetail()
                        }}
                    />
                </div>
            ),
        },
        {
            title: "工单编号",
            align: "center",
            dataIndex: "orderCode",
            width: 160,
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "客户名称",
            align: "center",
            dataIndex: "customerName",
            width: 200,
            render: (text) => <YhTooltip maxWidth={200} text={text} />,
        },
        {
            title: "提交人",
            align: "center",
            dataIndex: "submitUserName",
            width: 90,
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "审核人",
            align: "center",
            dataIndex: "reviewerName",
            width: 90,
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "审核时间",
            align: "center",
            dataIndex: "reviewerTime",
            width: 200,
        },
        {
            title: "结算人",
            align: "center",
            dataIndex: "returnSettlementUser",
            width: 90,
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "结算时间",
            align: "center",
            dataIndex: "returnSettlementTime",
            width: 200,
        },
        {
            title: "入库人",
            align: "center",
            dataIndex: "inboundUser",
            width: 90,
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "入库时间",
            align: "center",
            dataIndex: "inboundTime",
            width: 200,
        },
        // {
        //     title: "驳回原因",
        //     align: "center",
        //     dataIndex: "rejectInfo",
        //     width: 200,
        // },
        {
            title: "退单状态",
            align: "center",
            dataIndex: "returnStatus",
            width: 90,
            render: (text) => OrderReturnStatus[text],
        },
        {
            title: "应退金额",
            align: "center",
            dataIndex: "returnTotalAmount",
            width: 120,
        },
        {
            title: "实收金额",
            align: "center",
            dataIndex: "returnAmount",
            width: 120,
        },
        {
            title: "其他项目实收",
            align: "center",
            dataIndex: "returnOtherItemActualAmount",
            width: 120,
        },
        {
            title: "工时费实收",
            align: "center",
            dataIndex: "returnItemActualAmount",
            width: 120,
        },
        {
            title: "材料费实收",
            align: "center",
            dataIndex: "returnMaintainMaterialAmount",
            width: 120,
        },
        {
            title: "车辆牌照",
            align: "center",
            dataIndex: "customerVehicleCode",
            width: 120,
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "报修人",
            align: "center",
            dataIndex: "repairContactName",
            width: 90,
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "报修人电话",
            align: "center",
            dataIndex: "repairContactPhone",
            width: 120,
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "维修成员",
            align: "center",
            dataIndex: "teamUsers",
            width: 120,
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "主修人",
            align: "center",
            dataIndex: "majorUserName",
            width: 90,
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "结算时间",
            align: "center",
            dataIndex: "returnSettlementTime",
            width: 200,
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
            width: 200,
        },
    ]
    // 搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入工单单号",
                valueName: "orderCode",
            },
            {
                type: "input",
                placeholder: "请输入退单单号",
                valueName: "returnCode",
            },
            {
                type: "input",
                placeholder: "请输入客户名称",
                valueName: "customerName",
            },
            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                span: 16,
                xxl: 8,
            },
        ],
    }
    return (
        <>
            {state.activeMenu === "returnOrder" && (
                <>
                    <TopSearch
                        title="退单工单"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="退单工单"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        rowSelection={{
                            selectedRowKeys: selectedRowKeys, //记录选中
                            onChange: (selectedRowKeys, selectedRows) => {
                                setSelectedRowKeys(selectedRowKeys);
                            }
                        }}
                        scroll={{ x: "max-content" }}
                        loading={loading}
                        onRow={record => ({
                        onClick: () => onRow(record),
                        onContextMenu: (e) => {
                            e.preventDefault();
                            setSelectedRowKeys([record.id]);
                        }
                    })}
                    />

                    {showVisible && (
                        <Detail
                            id={selectedRowKeys[0]}
                            rowInfo={selectInfo.rowInfo}
                            visible={showVisible}
                            mode={showMode}
                            setVisible={setShowVisible}
                            onSuccess={() => {
                                loadData(1, searchValue)
                            }}
                        />
                    )}
                     {print && 
                     <ReturnOrderPrint
                        loadData={loadData}
                        visible={print}
                        setPrint={setPrint}
                        selectedRowKeys={selectedRowKeys}
                     />}
                </>
            )}
        </>
    )
}
