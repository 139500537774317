import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useAllocModel } from "../allocModel";

export const DataBox = () => {
    const { exportAlloc } = useAllocModel();

    const action = <>
            <Button
                className="btn-item"
                type="link"
                shape="round"
                onClick={() => exportAlloc()}
            >导出</Button>
    </>;

    return (
        <YhBox title=" " action={action}>
            <DataTable />
        </YhBox>
    )
}