import React, { useState, useEffect, useContext } from "react"
import { Select, Spin, Button, message } from "antd"
import PubDrawer from "@/components/Drawer"
import { getTeamList } from "@/service/teamService"
import { dispatchOrder } from "@/service/ordersService"
// import debounce from 'lodash/debounce';
import { MyContext } from "@/store/myContext"
import { organPage } from "@/service/orgService"

const { Option } = Select

const DispatchOrder = (props) => {
    const { showAllOrgan } = useContext(MyContext)
    const { dispatchVisible, onDispatClose, onDispatSuccessClose, orderInfo, orderDetailsLoad } = props //拍单信息
    const [teamData, setTeamData] = useState({
        //团队信息
        data: [],
        fetching: false,
    })
    const [selectTeamId, setSelectTeamId] = useState("") //选中团队id
    const [organId, setOrganId] = useState("") //选中organid
    useEffect(() => {
        !dispatchVisible.visible && setSelectTeamId("")
    }, [dispatchVisible])
    //机构列表
    const [org, setOrg] = useState([])
    useEffect(() => {
        if (showAllOrgan) {
            organPage({
                searchItem: { orderFlag: 0 },
                pageNum: 1,
                pageSize: 100,
                orders: [{ column: "createTime", rule: "DESC" }],
            }).then((res) => {
                setOrg(res.retData.list)
            })
        }
    }, [])
    //团队派单信息查询
    const fetchTeam = (value) => {
        if (!organId && showAllOrgan) {
            message.error("请先选择机构")
            return
        }
        setTeamData({
            ...teamData,
            fetching: true,
        })
        // let data = {
        //     teamName: value ? value : null,
        //     searchItem: {},
        //     pageSize: 15,
        //     currentPage: 1
        // };
        let data = {
            searchItem: { organId },
            pageNum: 1,
            pageSize: 1000,
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },
            ],
        }
        getTeamList(data).then((res) => {
            // if (res && res.retCode === 200) {
                let results = res.retData.list
                let isTeam = orderInfo && orderInfo.teamId ? orderInfo.teamId : ""
                let dataMap
                dataMap = results.map((team) => ({
                    text: team.teamName,
                    value: team.id,
                }))
                if (isTeam) {
                    dataMap = dataMap.filter((item) => item.value !== isTeam)
                }
                setTeamData({
                    data: dataMap,
                    fetching: false,
                })
            // }
        })
    }
    //防抖
    // const fetchTeams = debounce(fetchTeam, 1000);

    //选择的团队
    const handleChangeTeam = (value) => {
        setSelectTeamId(value)
    }

    //派单确定
    const handleDispatch = () => {
        if (!selectTeamId) {
            message.error("请选择你要派单的团队")
            return
        }
        dispatchOrder({
            teamId: selectTeamId,
            orderId: dispatchVisible.orderId,
            organId
        })
            .then((res) => {
                // if (res && res.retCode === 200) {
                    message.success(res.retMsg)
                    orderDetailsLoad && orderDetailsLoad()
                    onDispatSuccessClose ? onDispatSuccessClose() : onDispatClose() //成功关闭弹框
                // } else {
                //     message.error(res.retMsg)
                // }
            })
            // .catch((err) => {
            //     if (err && err.retCode === 500) {
            //         message.error("派单异常，请重新尝试，或联系后台人员。")
            //     }
            // })
    }

    // 选择机构
    const handleChangeOrgan = (val) => {
        setOrganId(val)
    }

    return (
        <PubDrawer visible={dispatchVisible.visible} onClose={onDispatClose}>
            <div className="team-drawer-title">
                <span>{dispatchVisible.type === "zhuanPaiDan" ? "转派单" : "派单"}</span>
            </div>
            <div className="team-drawer-content" style={{ textAlign: "center", padding: "30px" }}>
                {dispatchVisible.type === "zhuanPaiDan" && (
                    <div style={{ fontSize: "18px", padding: "10px 0" }}>
                        原派单团队：
                        <span>{orderInfo && orderInfo.teamId ? orderInfo.teamName : "暂无选择团队"}</span>
                    </div>
                )}
                {showAllOrgan && org.length > 0 ? (
                    <Select
                        showSearch
                        placeholder="请选择机构"
                        notFoundContent={teamData.fetching ? <Spin size="small" /> : null}
                        optionFilterProp="children"
                        onChange={handleChangeOrgan}
                        style={{ width: "40%" }}
                        // filterOption={false}
                        // onSearch={fetchTeams}
                    >
                        {org.map((item) => (
                            <Option value={item.id} key={item.id}>
                                {item.organName}
                            </Option>
                        ))}
                    </Select>
                ) : (
                    <></>
                )}

                <Select
                    showSearch
                    placeholder="请选择团队"
                    notFoundContent={teamData.fetching ? <Spin size="small" /> : null}
                    optionFilterProp="children"
                    onFocus={() => fetchTeam()}
                    onChange={handleChangeTeam}
                    style={{ width: "40%" }}
                    // filterOption={false}
                    // onSearch={fetchTeams}
                >
                    {teamData.data.map((item) => (
                        <Option value={item.value} key={item.value}>
                            {item.text}
                        </Option>
                    ))}
                </Select>
                <div className="team-form-footer">
                    <Button onClick={onDispatClose} className="team-but-one">
                        取消
                    </Button>
                    <Button className="team-but-two" onClick={handleDispatch}>
                        提交
                    </Button>
                </div>
            </div>
        </PubDrawer>
    )
}
export default DispatchOrder
