import React, { useEffect, useState } from "react"
import { Table, Modal, Button, Tabs } from "antd"
import { maintainOrderMateriallLog } from "@/service/ordersService"
const { TabPane } = Tabs
export default (props) => {
    const { logData, setLogData } = props
    const [list, setList] = useState([])

    const [pagination, setpagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    })

    useEffect(() => {
        getDetail()
    }, [logData]) // eslint-disable-line react-hooks/exhaustive-deps

    //获取订单日志
    const getDetail = (pageNum = 1) => {
        maintainOrderMateriallLog({
            pageNum: pageNum,
            pageSize: 10,
            searchItem: {
                id: logData.id,
            },
        }).then((res) => {
            setList(res.retData.list)
            setpagination({
                current: pageNum,
                total: res.retData.total,
                pageSize: 10,
            })
        })
    }


    //获取领料日志
    const getGetLog = () => {

    }

    const columns = [
        {
            title: "工单编号",
            align: "center",
            dataIndex: "orderCode",
        },
        {
            title: "客户名称",
            align: "center",
            dataIndex: "customerName",
        },
        {
            title: "车辆牌照",
            align: "center",
            dataIndex: "vehicleCode",
        },
        {
            title: "使用数量",
            align: "center",
            dataIndex: "num",
        },
        {
            title: "登记日期",
            align: "center",
            dataIndex: "createTime",
        },
    ]

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    const handleClose = () => {
        setLogData({
            visible: false,
            id: "",
        })
    }

    return (
        <>
            <Modal
                forceRender
                visible={logData.visible}
                title="日志"
                onCancel={handleClose}
                centered={true}
                footer={[
                    <Button key="link" onClick={handleClose}>
                        关闭
                    </Button>,
                ]}
                width={1200}
            >
                <Tabs defaultActiveKey="1">
                    <TabPane tab="订单日志" key="1">
                        <Table
                            dataSource={list}
                            pagination={{
                                showQuickJumper: true,
                                showSizeChanger: false,
                                showTotal: () => `共${pagination.total}条`,
                                position: ["bottomCenter"],
                                ...pagination,
                            }}
                            columns={columns}
                            size="small"
                        />
                    </TabPane>
                    {/* <TabPane tab="领料日志" key="2">
                        领料日志
                        <LogSteps>
                            <Steps className="logSteps" current={0} progressDot={customDot} direction="vertical">
                                {operasnapshot.map((item) => (
                                    <Step
                                        title={item.describe}
                                        subTitle={`${item.createTime}  ${item.traceId}`}
                                        description={`操作人:${OperatorUserType[item.operatorType] || ""}--${item.operatorName || ""}`}
                                        key={item.id}
                                    />
                                ))}
                            </Steps>
                        </LogSteps>
                    </TabPane> */}
                </Tabs>
            </Modal>
        </>
    )
}
