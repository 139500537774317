import React, { useState, useEffect } from "react";
import { Table, Button, Input, message, Upload } from "antd";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { useStockDetailedModel } from "../stockDetailedModel";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { YhBut } from "@/erp_subpackage/components/YhButton";
// import useGlobalModel, { transOptions } from "@/model/globalModel";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { warehouseService } from "@/erp_subpackage/pages/base/warehouse/warehouseService";
import {  EditableRow, EditableCell } from "@/erp_subpackage/components/YHEditTable/editTable";
import { formatInt } from "@/erp_subpackage/utils/util";
import { purchasePoService } from "@/erp_subpackage/pages/purchase/po/purchasePoService";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
export const SectionShelfModal = () => {
    const { user: { shopId,  shopName, shopCode, }, warehouseList, whDs,setMaterialInfo } = useGlobalModel();
    const {
        sectionShelfModal: visible, setSectionShelfModal,
        setSearchParamShelf, shelfLoading,
        searchParamShelf,
        dataSourceShelf,
        selectedShelfRows, setSelectedShelfRows, shelftDownloadTpl,
        pgCfgShelf,
        loadDataShelf, shiftShelfOldApi
    } = useStockDetailedModel();
    const [shelfDs, setShelfDs] = useState();  // 实时查询货位
    const [shelfAllList, setShelfAllList] = useState([]);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (visible) {
            loadDataShelf({ pageNum: 1 }, {});
            setSearchParamShelf({});
            setSelectedShelfRows([]);
        }
    }, [visible])

    const onCancel = () => {
        setSectionShelfModal(false);
    };

    const shelfChange = (value, option, record, rowIndex) => {
        let newData = selectedShelfRows;
        newData.splice(rowIndex, 1, record);
        setSelectedShelfRows([...newData])
    }

    //货位触焦 
    const shelfFocus = async (record, rowIndex) => {
        // setLoading(true)
        try {
            let newData = selectedShelfRows;
            const newRow = newData[rowIndex];
            let warehouseCode = record["warehouseCode1"]
            if (warehouseCode) {
                //货位列表  判断是当前仓库的货位列表  不做接口请求
                let newRecords = shelfAllList.find(item => item.warehouseCode === warehouseCode) ? shelfAllList : [];
                if (!newRecords.length) {
                    const { retData } = await warehouseService.shelfAllPage({
                        pageSize: 10000,
                        pageNum: 1,
                        warehouseCode,
                    })
                    newRecords = retData.map((item) => { return { ...item, name: item.code } })
                    setShelfAllList(newRecords)
                }
                //查询配件有没有在改仓库下采购过，
                let { retData } = await purchasePoService.getMaterialShelf({
                    shopId,
                    materialCode: record.materialCode,
                    warehouseCode: warehouseCode
                })
                // 采购过的货位带出来不可以编辑， 其它的可以编辑(有临时货位默认带出临时货位)
                if (retData.shelfCode) {
                    newRow.shelfCode1 = retData.shelfCode;
                    newRow.shelfId1 = retData.shelfId;
                    newRow.isPurShelf = retData.shelfCode;  //做是否可编辑
                    message.warning(`自动匹配到配件【${record.materialCode}】在该仓库下采购的货位，货位不可做修改操作！！`)
                    newData.splice(rowIndex, 1, { ...record, ...newRow });
                    setSelectedShelfRows([...newData])
                }
            } else {
                setShelfAllList([]);
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
        }
    }
    const columns = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t, r, i) => <>{i + 1}</>
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
            fixed: "left",
        }, {
            title: '原仓库',
            width: 160,
            dataIndex: 'warehouseName',
            render: (text) => <p className="ellipsis" title={text}>{text}</p>
        }, {
            title: '原货位',
            width: 160,
            dataIndex: 'shelfCode',
        }, {
            title: '原库存',
            width: 160,
            dataIndex: 'availableStock',
        }, {
            title: '操作',
            width: 80,
            align: "center",
            fixed: "right",
            className: "td-wrap",
            render: (text, record) => <YhBut
                type="add"
                txt="添加"
                click={() => setSelectedShelfRows(origin => [...origin, { ...record, moveNum: record.availableStock ?? 0 }])}
                disabled={!!selectedShelfRows.find(item => item.id === record.id) || record.availableStock === 0}
            />
        }
    ];

    //货位根据仓库code查询
    const queryShelf = async (wsCode) => {
        const searchParams = {
            shopId,
            warehouseCode: wsCode,
            pageSize: -1,
            pageNum: 1,
        }
        let { retData: records } = await warehouseService.shelfAllPage(searchParams);

        setShelfDs(records.map((item) => ({
            id: item.id,
            label: item.code,
            value: item.code
        })))
    }

    //仓库选择  (置空货位)
    const wsChange = (value, options, record) => {
        if (options && options.id) {
            queryShelf(options.value)
        }
    }

    const columnsSelect = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t, r, i) => <>{i + 1}</>
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text) => <p className="ellipsis" title={text}>{text}</p>
        }, {
            title: '原仓库',
            width: 140,
            dataIndex: 'warehouseName',
        }, {
            title: '原货位',
            width: 120,
            dataIndex: 'shelfCode',
        }, {
            title: '原库存',
            width: 100,
            dataIndex: 'availableStock',
        }, {
            title: '仓库',
            width: 150,
            dataIndex: "warehouseCode1",
            editable: true,
            editType: "select",
            options: whDs,
            render: (text, record) => whDs.find(item => item.value === text)?.label ?? record.inWarehouseName ?? "请选择仓库",
            onSelectChange: wsChange,
        }, {
            title: '货位',
            width: 150,
            dataIndex: 'shelfCode1',
            editable: true,
            editType: "select",
            options: shelfDs, 
            onSelectFocus: shelfFocus,
            onSelectChange: shelfChange,
            render: (text) => text ?? "请选择货位",
        }, {
            title: '转移库存',
            width: 120,
            editable: true,
            editType: "input",
            dataIndex: 'moveNum',
        }, {
            title: '操作',
            width: 80,
            align: "center",
            fixed: "right",
            className: "td-wrap",
            render: (text, record) => <YhBut
                type="delete"
                txt="移除"
                click={() => {
                    const newData = selectedShelfRows.filter(item => item.id !== record.id);
                    setSelectedShelfRows(newData);
                }}
            />
        }
    ];

    const newSelectColumns = columnsSelect.map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                editable: col.dataIndex === "shelfCode1" ? !record.isPurShelf : col?.editable,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑
    const handleSave = async (row, dataIndex) => {
        //货位的行编辑放到触焦里面操作， 防止覆盖赋值
        if (dataIndex !== "shelfCode") {
            row.moveNum = +formatInt(row.moveNum, row.availableStock, "填写库存数不可以大于原库存")
            let dataSource = [...selectedShelfRows];
            const index = dataSource.findIndex((item) => row.id === item.id);
            console.log(row.warehouseCode1)
            //选中货位的话， 赋值货位code
            if (row.shelfCode1) {
                // debugger;
                row.shelfId1 = shelfDs?.find(item => item.value === row.shelfCode1)?.id;
            }
            //设置仓库名称
            if (row.warehouseCode1) {
                // row.warehouseName1 = warehouseList.find((item) => item.code === row.warehouseCode1)?.name
            }
            //判断仓库变化时，  重置货位
            if (row.warehouseCode1 !== dataSource[index].warehouseCode1) {
                setLoading(true)
                try {
                    //货位列表
                    let newRecords = []
                    let params = {
                        pageSize: 99999,
                        pageNum: 1,
                        warehouseCode: row.warehouseCode1,
                    }
                    const { retData: shelfAll } = await warehouseService.shelfAllPage(params)
                    newRecords = shelfAll.map((item) => { return { ...item, name: item.code } })
                    setShelfAllList(newRecords);
                    //查询配件有没有在改仓库下采购过， 
                    let { retData } = await purchasePoService.getMaterialShelf({
                        shopId,
                        materialCode: row.materialCode,
                        warehouseCode: row.warehouseCode1
                    })
                    // 采购过的货位带出来不可以编辑， 其它的可以编辑(有临时货位默认带出临时货位)
                    if (retData.shelfCode) {
                        row.shelfCode1 = retData.shelfCode;
                        row.shelfId1 = retData.shelfId;
                        row.isPurShelf = retData.shelfCode;  //做是否可编辑
                        message.warning(`自动匹配到配件【${row.materialCode}】在该仓库下采购的货位，货位不可做修改操作！！`)
                    } else {
                        // let isTemp = newRecords?.find(item => (item.code ?? "").indexOf("临时货位") > -1);
                        // row.shelfCode = isTemp?.code;
                        // row.shelfId = isTemp?.id;
                        row.shelfCode1 = undefined;
                        row.shelfId1 = undefined;
                        row.isPurShelf = "";
                    }
                    setLoading(false)
                } catch (e) {
                    setLoading(false)
                }
            }

            const item = dataSource[index];
            dataSource.splice(index, 1, { ...item, ...row });
            setSelectedShelfRows([...dataSource]);
        }
    }

    const action = <>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => shelftDownloadTpl()}
        >下载模板</Button>
        <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/scm2/purchase/po/transfer/library/import/${shopId}`, upLoadCallback)}>
            <Button className="mr-5" type="link" shape="round">导入</Button>
        </Upload> */}
    </>;
    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16  mr-5"
            placeholder="商品名称"
            onChange={(e) => setSearchParamShelf({ ...searchParamShelf, materialName: e.target.value})}
            onBlur={(e) => { setSearchParamShelf({ ...searchParamShelf, materialName:e.target.value?.trim() }) }}
            onPressEnter={() => loadDataShelf({ pageNum: 1, pageSize: 15 })}
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16"
            placeholder="商品编号"
            onChange={(e) => setSearchParamShelf({ ...searchParamShelf, materialCode: e.target.value })}
            onBlur={(e) => { setSearchParamShelf({ ...searchParamShelf, materialCode:e.target.value?.trim() }) }}
            onPressEnter={() => loadDataShelf({ pageNum: 1, pageSize: 15 })}
        />
        <Button style={{ margin: "0px 12px 0px 15px" }} shape="round" onClick={() => loadDataShelf({ pageNum: 1, pageSize: 15 })}>检索</Button>
    </div>
    return <YHModal
        visible={visible}
        onOk={() => {
            let filterPayData = selectedShelfRows.filter(item => (item.shelfCode1));
            if (filterPayData.length !== selectedShelfRows.length) {
                message.warning("请选择要转移的货位");
                return;
            }
            shiftShelfOldApi()
        }}
        onCancel={onCancel}
        title="采购明细-转移货位"
        width={1200}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title="待选配件列表" rightChildren={rightChildren} style={{ marginBottom: "5px" }}>
            <Table
                loading={shelfLoading}
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.id}
                columns={columns}
                dataSource={dataSourceShelf}
                pagination={{
                    ...pgCfgShelf,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        loadDataShelf({ pageNum, pageSize }).then();
                    }
                }}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
        <YhBox title="已选配件列表" rightChildren={action} >
            <Table
                loading={{ tip: "数据查询中...", spinning: loading }}
                rowKey={record => record.id}
                rowClassName='editable-row yh-edit-table-tr'
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns}
                dataSource={selectedShelfRows}
                pagination={false}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
    </YHModal>
}