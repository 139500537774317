import React, { useContext } from "react"
import { Input, Modal, Form, Col, Row, Select, message, Switch, Spin } from "antd"
import { addTeam } from "@/service/teamService"
import { getPcodeDict } from "@/utils/dataStorage"
import { getMoveCarList } from "@/service/moveCarService"
import { platformUserList } from "@/service/customerService"
import debounce from "lodash/debounce"
import { MyContext } from "@/store/myContext"
import { useState } from "react"
const { Option } = Select
const TeamInsert = (props) => {
    const { showAllOrgan } = useContext(MyContext)
    const { visible, onCancel, onTeamLoadData, org, settlementMethodEmun } = props
    const [form] = Form.useForm()
    const [carList, setCarList] = React.useState([])
    //所属经理
    const [businessManager, setBusinessManager] = useState({
        data: [],
        fetching: false,
    })
    const [settlementMethod, setSettlementMethod] = useState("point")
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    React.useEffect(() => {
        if (visible) {
            getCarList({
                searchItem: {},
                pageNum: 1,
                pageSize: 100,
                orders: [
                    {
                        column: "updateTime",
                        rule: "DESC",
                    },
                ],
            })
        }
    }, [visible])
    const getCarList = (param) => {
        getMoveCarList(param).then((res) => {
            console.log(res)
            // if (res && res.retCode === 200) {
            setCarList(res.retData.list)
            // }
        })
    }
    const onCustomerSubmit = () => {
        form.validateFields().then((fieldsValue) => {
            console.log(fieldsValue)
            const data = {
                ...fieldsValue,
                disableFlag: fieldsValue.disableFlag ? 0 : 1,
            }
            addTeam(data).then((res) => {
                onTeamLoadData(1)
                form.resetFields()
                onCancel(false)
                message.success("新增成功")
            })
        })
    }

    //客户经理列表查询
    const businessManagerQuery = (value, key) => {
        setBusinessManager({
            ...businessManager,
            fetching: true,
        })

        platformUserList({
            searchItem: {
                nickname: value,
            },
            pageNum: 1,
            pageSize: 15,
        }).then((res) => {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.reduce((arr, item) => {
                    arr.push({
                        text: item.nickname,
                        value: item.id,
                    })
                    return arr
                }, [])
                setBusinessManager({
                    data: data,
                    fetching: false,
                })
            } else {
                message.error("没有查询到")
            }
        })
    }
    //防抖
    const businessManagerQuerys = debounce(businessManagerQuery, 1000)

    return (
        <Modal visible={visible} title="团队新增" centered={true} onOk={() => onCustomerSubmit()} onCancel={() => onCancel(false)} width={1260}>
            <div>
                <Form form={form} {...formItemLayout} initialValues={{ disableFlag: true, settlementMethod: "point" }}>
                    <Row gutter={20}>
                        <Col span={24} offset={1}>
                            基本信息
                        </Col>
                        <Col span={12}>
                            <Form.Item label="团队名称" name="teamName" rules={[{ required: true, message: "团队名称" }]}>
                                <Input placeholder="请输入团队名称" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="团队联系人"
                                name="contactName"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入团队联系人",
                                    },
                                    {
                                        pattern: /^[\u4E00-\u9FA5]{2,5}$/,
                                        message: "请输入正确的姓名",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入团队联系人" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="联系电话"
                                name="contactPhone"
                                rules={[
                                    {
                                        required: true,
                                        message: `请输入手机号码!`,
                                    },
                                    {
                                        pattern: /^1\d{10}$/,
                                        message: "请输入正确手机号格式",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入联系电话" />
                            </Form.Item>
                        </Col>

                        {showAllOrgan ? (
                            <Col span={12}>
                                <Form.Item
                                    label="所属机构"
                                    name="organId"
                                    rules={[
                                        {
                                            required: true,
                                            message: `请选择所属机构！`,
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择所属机构" style={{ width: "100%" }}>
                                        {org.map((item) => (
                                            <Select.Option value={item.id} key={item.id}>
                                                {item.organName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        ) : (
                            <></>
                        )}

                        <Col span={12}>
                            <Form.Item label="团队属性" name="teamAttribute">
                                <Select showSearch allowClear placeholder="请选择团队属性" style={{ width: "100%" }}>
                                    {getPcodeDict("028").children.map((item) => (
                                        <Select.Option value={item.dictCode} key={item.id}>
                                            {item.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="打印机编号" name="feieyunSn">
                                <Input placeholder="请输入打印机编号" />
                            </Form.Item>
                        </Col>

                        <Col span={24} offset={1}>
                            结算方式
                        </Col>
                        <Col span={12}>
                            <Form.Item label="结算方式" name="settlementMethod"  rules={[{ required: true, message: "结算方式" }]}>
                                <Select allowClear placeholder="请选择结算方式" style={{ width: "100%" }} onChange={setSettlementMethod}>
                                    {Object.entries(settlementMethodEmun).map((item) => (
                                        <Select.Option value={item[0]} key={item[1]}>
                                            {item[1]}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} offset={1}>
                            其他信息
                        </Col>
                        {settlementMethod === "share_ratio" && (
                            <>
                                <Col span={12}>
                                    <Form.Item
                                        label="税点"
                                        name="taxRatio"
                                        rules={[
                                            {
                                                pattern: /^[0|1](\.[0-9]{1,2}){0,1}$/,
                                                message: "税点范围为0-1",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="请输入税点" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="平台服务费"
                                        name="platServiceRatio"
                                        rules={[
                                            {
                                                pattern: /^[0|1](\.[0-9]{1,2}){0,1}$/,
                                                message: "平台服务费范围为0-1",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="请输入平台服务费" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="团队分成" name="shareRatio">
                                        <Input placeholder="请输入分成" />
                                    </Form.Item>
                                </Col>
                            </>
                        )}

                        {settlementMethod === "quota" && (
                            <>
                                <Col span={12}>
                                    <Form.Item
                                        label="定额费用"
                                        name="quotaAmount"
                                    >
                                        <Input placeholder="请输入定额费用" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="佣金比例"
                                        name="quotaRatio"
                                        // rules={[
                                        //     {
                                        //         pattern: /^[0|1](\.[0-9]{1,2}){0,1}$/,
                                        //         message: "平台服务费范围为0-1",
                                        //     },
                                        // ]}
                                    >
                                        <Input placeholder="请输入平台服务费" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="客户经理" name="managerId">
                                        <Select
                                        showSearch
                                        placeholder="请选择客户经理"
                                        notFoundContent={businessManager.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onFocus={() => businessManagerQuerys("")}
                                        onSearch={businessManagerQuerys}
                                        style={{ width: "100%" }}
                                    >
                                        {businessManager.data.map((item) => (
                                            <Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                    </Form.Item>
                                </Col>
                            </>
                        )}

                    

                        {/* <Col span={12}>
                            <Form.Item label="手持设备号" name="handdeviceCode">
                                <Input placeholder="请输入手持设备号" />
                            </Form.Item>
                        </Col>
                       
                        <Col span={12}>
                            <Form.Item label="微信收款二维码" name="wxQrCode">
                                <Input placeholder="请输入微信收款二维码" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="支付宝收款二维码" name="aliPayQrCode">
                                <Input placeholder="请输入支付宝收款二维码" />
                            </Form.Item>
                        </Col> */}
                        <Col span={12}>
                            <Form.Item label="绑定服务车" name="vehicleId">
                                <Select
                                    optionFilterProp="children"
                                    showSearch
                                    allowClear
                                    placeholder="请选择绑定服务车"
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {carList.map((item) => (
                                        <Select.Option value={item.id} key={item.id}>
                                            {item.vehicleCode}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="推荐人" name="refererUserId">
                                <Input placeholder="请输入推荐人" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="备注" name="remark">
                                <Input placeholder="请输入备注" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="disableFlag" label="团队状态" valuePropName="checked">
                                <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    )
}
export default TeamInsert
