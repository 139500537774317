import { post, get } from "../utils/request"

/**
 * 部件数据维护
 */
//列表
function getPartMaintenList(data) {
    return post("/ci/intelligent_diagnosis_component/list", data)
}
//获取一条数据
function getPartMaintenOne(id) {
    return get(`/ci/intelligent_diagnosis_component/one/${id}`)
}
//新增
function partMaintenInsert(data) {
    return post("/ci/intelligent_diagnosis_component/insert", data)
}
//编辑
function partMaintenEdit(data) {
    return post(`/ci/intelligent_diagnosis_component/update`, data)
}
//删除一条数据
function partMaintenDelete(id) {
    return post(`/ci/intelligent_diagnosis_component/delete/${id}`)
}
/**
 *故障口语管理
 */
//列表
function getDiagnoseSpokenList(data) {
    return post("/ci/intelligent_diagnosis_malfunction_spoken/list", data)
}
//获取一条数据
function getDiagnoseSpokenOne(id) {
    return get(`/ci/intelligent_diagnosis_malfunction_spoken/one/${id}`)
}
//新增或编辑
function diagnoseSpokenSave(data) {
    return post("/ci/intelligent_diagnosis_malfunction_spoken/save", data)
}
//删除一条数据
function diagnoseSpokenDelete(id) {
    return get(`/ci/intelligent_diagnosis_malfunction_spoken/delete/${id}`)
}
/**
 *故障术语管理
 */
//列表
function getDiagnoseTermList(data) {
    return post("/ci/intelligent_diagnosis_malfunction_term/list", data)
}
//获取一条数据
function getDiagnoseTermOne(id) {
    return get(`/ci/intelligent_diagnosis_malfunction_term/one/${id}`)
}
//新增
function diagnoseTermInsert(data) {
    return post("/ci/intelligent_diagnosis_malfunction_term/insert", data)
}
//编辑
function diagnoseTermEdit(data) {
    return post("/ci/intelligent_diagnosis_malfunction_term/update", data)
}
//删除一条数据
function diagnoseTermDelete(id) {
    return get(`/ci/intelligent_diagnosis_malfunction_term/delete/${id}`)
}

export {
    getPartMaintenList,
    getPartMaintenOne,
    partMaintenInsert,
    partMaintenEdit,
    partMaintenDelete,
    getDiagnoseSpokenList,
    getDiagnoseSpokenOne,
    diagnoseSpokenSave,
    diagnoseSpokenDelete,
    getDiagnoseTermList,
    getDiagnoseTermOne,
    diagnoseTermInsert,
    diagnoseTermEdit,
    diagnoseTermDelete,
}
