import React, { useEffect, useRef, useState, useContext } from 'react'
import { Button, message } from 'antd'
import { YhFormBox } from '@/erp_subpackage/components/YhFormBox'
import { getFormValidatorErrText, formatNum } from '@/erp_subpackage/utils/util'
import { useDictModel } from '@/erp_subpackage/pages/base/dict/dictModel'
import { usePurchaseDrDetailsModel } from './purchaseDrDetailsModel'
import useGlobalModel, { defaultMdCmParam } from '@/erp_subpackage/model/globalModel'
import { purchaseDrService } from '../../purchaseDrService'
import { MyContext } from '@/store/myContext'
import { DictCodeEnmu } from '@/erp_subpackage/utils/DictCodeEnum'
import { debounce } from "lodash"

export const FormBox = ({ modeKey, mode, rowCode, dispatch, outUnitBeData, inFormVal, inList }) => {
    const {
        fetchFmsShopList,
        user: { allowTransfer, businessEntityCode, businessEntityName, orgShopCode, orgShopName },
    } = useGlobalModel(({ user }) => [user])
    const { detailsPage } = useContext(MyContext)
    const {
        editFormVal,
        addFormVal,
        lookFormVal,
        editList,
        setStockVisible,
        updateFormVal,
        updateList,
        getPDrDetailOne,
        insertPDr,
        updatePDr,
        setPrint,
        inWhConfirm,
        saveBtn,
        saveBtnLoading,setSaveBtnLoading
    } = usePurchaseDrDetailsModel()
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => [])
    const formVal = mode === 'edit' ? editFormVal : mode === 'look' ? lookFormVal : addFormVal
    const formRef = useRef()
    const [outUnitBeList, setOutUnitBeList] = useState([]) //调出机构对应的关联实体
    const [inUnitBeList, setinUnitBeList] = useState([]) //调入机构对应的关联实体

    //根据状态判断是否可以进行修改 调入单：待调入、待调出可以修改
    const [isDisabled, setIsDisabled] = useState(false)
    useEffect(() => {
        if (mode === 'add') {
            setIsDisabled(false)
        } else if (mode === 'edit') {
            if (editFormVal?.status !== DictCodeEnmu.TRANSFER_STATUS_INIT && editFormVal?.status !== DictCodeEnmu.TRANSFER_STATUS_OUT) {
                setIsDisabled(true)
            } else {
                setIsDisabled(false)
            }
        } else {
            setIsDisabled(true)
        }
    }, [mode, editFormVal])

    //编辑时， 没有缓存调用详情接口
    useEffect(() => {
        if (mode === 'edit' || mode === 'look') {
            getPDrDetailOne(rowCode, mode).then((res) => {
                getShopOutUnitDetail({ label: res.outShopName, value: res.outShopId, id: res.outShopId, branch: res.branchOut }, true)
                getShopInUnitDetail(res.shopId)
            })
        }
        if (mode === 'add') {
            getShopInUnitDetail(addFormVal.shopId)
            if (inFormVal) {
                setOutUnitBeList(outUnitBeData)
                updateFormVal(mode, inFormVal)
                updateList(mode, inList)
            } else {
                updateFormVal(mode)
            }
        }
        return () => {
            updateFormVal(mode)
            updateList(mode)
        }
        
    }, [rowCode])

    //刷新
    const onRefresh = () => {
        getPDrDetailOne(rowCode).then((res) => res && message.success('已刷新'))
    }

    //表单change
    const onChange = (changedValues, values) => {
        updateFormVal(mode, changedValues)
    }

    //关闭当前页
    const onClose = () => {
        dispatch({
            type: 'removeTabs',
            targetKey: modeKey,
            activeMenu: 'purchaseDr',
        })
    }

    //表单提交
    const onSubmit = debounce(async () => {
        formRef.current
            .validateFields()
            .then((val) => {
                let data = {
                    ...val,
                    transferTypeName: getTreeNodeName(val.transferTypeCode),
                    bentityName: val.bentityCode ? businessEntityName : undefined,
                    outBentityName: outUnitBeList.find((item) => item.value === val.outBentityCode)?.label,
                    //inNote: val.inNote ? val.inNote : val.note,
                }
                mode === 'edit'
                    ? updatePDr(data)
                    : insertPDr(data).then((res) => {
                          if (res) {
                              onClose()
                              dispatch({
                                  type: 'changeTabs',
                                  name: '调入单编辑',
                                  activeMenu: 'purchaseDr/edit',
                                  deleteKey: 'purchaseDr/edit',
                                  content: detailsPage.PurchaseDrDetails({
                                      modeKey: 'purchaseDr/edit',
                                      mode: 'edit',
                                      dispatch,
                                      rowCode: res.id,
                                  }),
                              })
                          }
                      })
            })
            .catch((err) => {
                setSaveBtnLoading(false)
                message.warning(getFormValidatorErrText(err))
            })
    },900)

    const getShopInUnitDetail = async (id) => {
        let { retData } = await purchaseDrService.queryShopUnitBe(id)
        if (retData && retData.id) {
            if (retData.bentityList && retData.bentityList.length) {
                setinUnitBeList(retData.bentityList.map((item) => ({ label: item.name ?? '', value: item.code ?? '' })))
            } else {
                setinUnitBeList([])
                message.warning('调出机构对应的核算单位没有关联实体')
            }
        }
    }

    //回显选中调出机构带出来的数据
    const getShopOutUnitDetail = async (option, echo = false) => {
        //分支为云创的  查询机构单条
        // if (option.branch === defaultMdCmParam.sourceApp) {
        //     let { retData: erpData } = await purchaseDrService.queryOrganOne(option.id ?? option.code)
        //     let data = {
        //         outShopCode: option.code,
        //         outShopName: option.name,
        //         outShopId: option.id,
        //         branchOut: option.branch,
        //         transferTypeName: option.branch === 'scm' ? '备品调拨' : '其它调拨',
        //         transferTypeCode: option.branch === 'scm' ? DictCodeEnmu.TRANSFER_TYPE_BP : DictCodeEnmu.TRANSFER_TYPE_OTHERS,
        //         outBentityName: undefined, //每次选中的时候对应的实体置空
        //         outBentityCode: undefined,
        //     }
        //     setOutUnitBeList(erpData?.businessEntityCode ? [{ label: erpData.businessEntityName, value: erpData.businessEntityCode }] : [])
        //     //回显时不用执行一下操作
        //     if (echo) return
        //     updateFormVal(mode, { ...data })
        //     updateList(mode)
        // } else {
        let { retData } = await purchaseDrService.queryShopUnitBe(option.id)
        let data = {
            outShopCode: option.code,
            outShopName: option.name,
            outShopId: option.id,
            branchOut: option.branch,
            transferTypeName: option.branch === 'scm' ? '备品调拨' : '其它调拨',
            transferTypeCode: option.branch === 'scm' ? DictCodeEnmu.TRANSFER_TYPE_BP : DictCodeEnmu.TRANSFER_TYPE_OTHERS,
            outBentityName: undefined, //每次选中的时候对应的实体置空
            outBentityCode: undefined,
        }
        if (retData && retData.id) {
            if (retData.bentityList && retData.bentityList.length) {
                setOutUnitBeList(retData.bentityList.map((item) => ({ label: item.name ?? '', value: item.code ?? '' })))
            } else {
                setOutUnitBeList([])
                message.warning('调出机构对应的核算单位没有关联实体')
            }
            //回显时不用执行一下操作
            if (echo) return
            updateFormVal(mode, { ...data })
            updateList(mode)
        } else {
            setOutUnitBeList([])
            message.warning('调出机构没有关联的核算单位')
            if (echo) return
            updateFormVal(mode, { ...data })
            updateList(mode)
        }
        // }
    }

    //调出机构
    const outShopChange = (value, option) => {
        if (option) {
            getShopOutUnitDetail(option)
            updateList(mode, [])
        }
    }

    //调入
    const whConFirm = () => {
        formRef.current
            .validateFields()
            .then((val) => {
                let data = {
                    ...val,
                    transferTypeName: getTreeNodeName(val.transferTypeCode),
                    bentityName: val.bentityCode ? businessEntityName : undefined,
                    outBentityName: outUnitBeList.find((item) => item.value === val.outBentityCode)?.label,
                }
                inWhConfirm(data)
            })
            .catch((err) => {
                message.warning(getFormValidatorErrText(err))
            })
    }

    //重新调入
    const redeployIn = () => {
        dispatch({
            type: 'changeTabs',
            name: '调入单新增',
            activeMenu: 'purchaseDr/add',
            deleteKey: 'purchaseDr/add',
            content: detailsPage.PurchaseDrDetails({
                modeKey: 'purchaseDr/add',
                mode: 'add',
                dispatch,
                outUnitBeData: outUnitBeList,
                inFormVal: { ...editFormVal, code: undefined },
                inList: editList,
            }),
        })
    }

    const rightChildren = (
        <>
            {mode === 'edit' ? (
                <>
                    <Button className="btn-item" shape="round" onClick={() => setPrint(true)}>
                        打印
                    </Button>
                    <Button className="btn-item" shape="round" onClick={onRefresh}>
                        刷新
                    </Button>
                    <Button className="btn-item" shape="round" disabled={editFormVal.status === DictCodeEnmu.TRANSFER_STATUS_IN} loading={saveBtnLoading} onClick={() => onSubmit()}>
                    {saveBtnLoading ? '保存中' : '保存'}
                    </Button>
                    <Button
                        className="btn-item"
                        shape="round"
                        disabled={editFormVal.status !== DictCodeEnmu.TRANSFER_STATUS_OUT||saveBtn}
                        onClick={() => {
                            whConFirm()
                        }}
                    >
                        调入
                    </Button>
                    {editFormVal.status === DictCodeEnmu.TRANSFER_STATUS_REJECT && (
                        <Button
                            className="btn-item"
                            shape="round"
                            onClick={() => {
                                redeployIn()
                            }}
                        >
                            重新调入
                        </Button>
                    )}
                </>
            ) : (
                <>
                    <Button
                        className="btn-item"
                        shape="round"
                        onClick={() => {
                            setStockVisible(true)
                        }}
                    >
                        各门店库存
                    </Button>
                    <Button
                        className="btn-item"
                        shape="round"
                        onClick={() => {
                            onSubmit()
                        }}
                    >
                        请调
                    </Button>
                </>
            )}
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    dispatch({
                        type: 'removeTabs',
                        targetKey: modeKey,
                        activeMenu: 'purchaseDr',
                    })
                }}
            >
                返回
            </Button>
        </>
    )

    const baseFormItem = {
        title: '基本信息',
        style: { padding: '0 20px', marginBottom: '10px' },
        rightChildren,
        items: [
            {
                type: 'Input',
                fieldLabel: '调入单号',
                fieldName: 'transferInCode',
                disable: true,
                placeholder: '自动生成',
            },
            {
                type: 'Input',
                fieldLabel: '调入机构',
                fieldName: 'shopName',
                disable: true,
            },
            {
                type: mode === 'add'?'AsyncSelect':'Input',
                fieldLabel: '调出机构',
                fieldName: mode === 'add'?'outShopIdShow':'outShopName',
                onSelectChange: outShopChange,
                rules: [{ required: true, message: '请选择调出机构' }],
                disable: mode === 'edit',
                selectfetchList: fetchFmsShopList,
                isFmsShop: businessEntityCode && orgShopCode && allowTransfer, //有实体 && 有门店 && 有调拨权限 才可以掉用主数据
                defaultQuery: mode === 'add'?formVal.outShopName:formVal.outShopCode,
            },
            {
                type: 'Input',
                fieldLabel: '备注',
                fieldName: 'inNote',
            },
            {
                type: 'Select',
                fieldLabel: '调拨类型',
                fieldName: 'transferTypeCode',
                options: getSelectedDictOne(DictCodeEnmu.TRANSFER_TYPE),
                rules: [{ required: true, message: '请选择调拨类型' }],
            },
            {
                type: 'Select',
                fieldLabel: '调入实体',
                fieldName: 'bentityCode',
                options: inUnitBeList, //没有实体不是必填项、
                rules: [{ required: !!businessEntityCode && !!orgShopCode && allowTransfer, message: '请选择调入实体' }],
            },
            {
                type: 'Select',
                fieldLabel: '调出实体',
                fieldName: 'outBentityCode',
                options: outUnitBeList, //调出为云创的机构， 实体不是必填
                rules: [{ required: formVal.branchOut !== defaultMdCmParam.sourceApp, message: '请选择调出实体' }],
            },
            {
                type: 'Input',
                fieldLabel: '调出备注',
                fieldName: 'note',
                disable: true,
            },
            {
                type: 'FormUpload',
                fieldLabel: '附件',
                fieldName: 'inName',
                fieldMd5: 'inMd5',
                defaultFileName: formVal.inName,
                defaultFileMd5: formVal.inMd5,
                onSetFieldsValue: onChange,
            },
            {
                type: 'FormUpload',
                fieldLabel: '调出附件',
                fieldName: 'outName',
                fieldMd5: 'outMd5',
                defaultFileName: formVal.outName,
                defaultFileMd5: formVal.outMd5,
                isUsePortalApi:formVal.branchOut!=='ci'?true:false,
                onSetFieldsValue: onChange,
                hidden:mode == 'add'?true:false,
                lookBranch:true
            },
            {
                type: 'Input',
                fieldLabel: '制单员',
                fieldName: 'applier',
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '调入员',
                fieldName: 'transferIn',
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '调出员',
                fieldName: 'transferOut',
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '制单日期',
                fieldName: 'applyTime',
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '调入日期',
                fieldName: 'transferInTime',
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '调出日期',
                fieldName: 'transferOutTime',
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '运费',
                fieldName: 'freight',
                rules: [{ pattern: /^[0-9]+(.[0-9]{0,2})?$/, message: '请输入数字且可保留两位小数' }],
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '其他费用',
                fieldName: 'otherFees',
                rules: [{ pattern: /^[0-9]+(.[0-9]{0,2})?$/, message: '请输入数字且可保留两位小数' }],
                hidden: true,
            },
            //带出字段

            {
                type: 'Input',
                fieldLabel: '调入实体名称',
                fieldName: 'bentityName',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '调入机构Code',
                fieldName: 'shopCode',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '调入机构Id',
                fieldName: 'shopId',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '调出机构名称',
                fieldName: 'outShopName',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '调出机构Id',
                fieldName: 'outShopId',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '调出机构id',
                fieldName: 'outShopCode',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '调入分支',
                fieldName: 'branchIn',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '调出分支',
                fieldName: 'branchOut',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '状态',
                fieldName: 'status',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '调出单号',
                fieldName: 'transferOutCode',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '调拨单号',
                fieldName: 'code',
                hidden: true,
            },
            {
                type: 'Input',
                fieldName: 'createUser',
                hidden: true,
            },
        ],
    }

    const poFromConfig = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: 'right',
        formValues: formVal,
        onChange,
        disabled: isDisabled,
        boxs: [baseFormItem],
    }

    return <YhFormBox {...poFromConfig} />
}
