import React, { useEffect } from "react"
import { Table, Button, Input } from "antd"
import { YHModal } from "@/erp_subpackage/components/YHModal"
import { useSaleOrderDetailModel } from "./saleOrderDetailModel"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { EditableRow, EditableCell } from "@/erp_subpackage/components/YHEditTable/editTable"
import { YhBut } from "@/erp_subpackage/components/YhButton"
import { formatNum, formatIntPoint } from "@/erp_subpackage/utils/util"
import { stockDetailedService } from "@/erp_subpackage/pages/stock/detailed/stockDetailedService"
import { ISalesTypeEnum, IPriceTypeEnum } from "@/erp_subpackage/utils/ErpEnum"
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import YhTooltip from "@/erp_subpackage/components/YhTooltip";

export const AddGoodsModal = ({ mode }) => {
    const {
        user: { shopId }, setMaterialInfo
    } = useGlobalModel()
    const {
        addModalVisible: visible,
        detailAddForm,
        detailEditForm,
        detailLookForm,
        initPgCfg,
        workDataSource,
        workPgCfg,
        addDataSource,
        searchWorkData,
        setWorkDataSource,
        resetWorkloadData,
        setSearchWorkData,
        setAddDataSource,
        transformsToPoDetailList,
        workloadData,
        setAddModalVisible,
    } = useSaleOrderDetailModel()

    useEffect(() => {
        visible && workloadData()
    }, [visible])
    const detailForm= mode === "add" ? detailAddForm :  mode === "edit" ?detailEditForm:detailLookForm
    const column = (optype) => [
        {
            title: "序号",
            width: 80,
            fixed: "left",
            render: (t, r, i) => i + 1,
        },
        {
            title: "商品编号",
            width: 120,
            dataIndex: "materialCode",
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.name,customerName:detailForm.customerName,customerCode:detailForm.customerCode})} />,
        },
        {
            title: "商品名称",
            width: 120,
            dataIndex: "materialName",
            fixed: "left",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "单位",
            width: 50,
            dataIndex: "unitName",
        },
        {
            title: "可用库存",
            width: 100,
            dataIndex: "availableStock",
        },
        {
            title: "库房",
            width: 140,
            dataIndex: "warehouseName",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "数量",
            width: 100,
            dataIndex: "num",
            editable: true,
        },
        optype !== "add" ? {
            title: '价格',
            width: 100,
            dataIndex: 'price',
            editable: true,
        } : {},
        optype !== "add" ? {
            title: '总计',
            width: 100,
            dataIndex: 'amount',

        } : {},
        {
            title: "货位",
            width: 120,
            dataIndex: "shelfCode",
        },
        {
            title: "采购单号",
            width: 160,
            dataIndex: "purchaseCode",
        },
        {
            title: "操作",
            width: 60,
            fixed: "right",
            align: "center",
            render: (text, record) =>
                optype == "add" ? (
                    <YhBut
                        type="add"
                        txt="添加"
                        click={() => add(record)}
                        disabled={!!addDataSource.find((item) => item.id === record.id)}
                    />
                ) : (
                    <YhBut type="delete" txt="删除" click={() => remove(record)} />
                ),
        },
    ]
    const newSelectColumns = (optType) =>
        column(optType).map((col, index) => {
            return {
                ...col,
                onCell: (record, rowIndex) => ({
                    colIndex: index,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: optType == "add" ? notHandleSave : handleSave,
                }),
            }
        })

    const notHandleSave = (row) => {
        let { num, price, purchasePrice, latestPurPrice } = row
        num = +formatIntPoint(num)
        price = +formatIntPoint(price)
        let newRow = {
            ...row,
            num,
            price,
            amount: +formatNum(num * price),
        }
        const index = workDataSource.findIndex((item) => row.id === item.id)
        const item = workDataSource[index]
        workDataSource.splice(index, 1, { ...item, ...newRow })
        setWorkDataSource([...workDataSource])
    }

    const handleSave = (row) => {
        let { num, price, purchasePrice, latestPurPrice } = row
        num = +formatIntPoint(num)
        price = +formatNum(price)
        let newRow = {
            ...row,
            num,
            price,
            amount: +formatNum(num * price),
        }
        const index = addDataSource.findIndex((item) => row.id === item.id)
        const item = addDataSource[index]
        addDataSource.splice(index, 1, { ...item, ...newRow })
        setAddDataSource([...addDataSource])
    }

    // latestPurPrice：采购进货价
    // lateSalePrice：最近销售价
    // purchasePrice：档案进价
    // retailPrice：配件档案销售价
    //销售类型为正常销售 ?配件明细的价格根据售价选择来决定 : 否则取采购进货价
    const add = async (record) => {
        let { latestPurPrice, materialCode, lateSalePrice, purchasePrice, retailPrice, num, ...params } = record
        let { priceTypeCode, salesTypeCode, customerCode } = mode === "add" ? detailAddForm : detailEditForm
        let newPrice = latestPurPrice ?? purchasePrice;
        if (salesTypeCode === ISalesTypeEnum.NORMAL_SALES) {
            switch (priceTypeCode) {
                //售价选择 客户最近销价 (需要通过接口查询，客户最近一次的销售价)
                case IPriceTypeEnum.CUSTOMER_LATEST:
                    let { retData: { price } } = await stockDetailedService.recentSalesPrice(shopId, materialCode, customerCode);
                    newPrice = +price;
                    break;
                //售价选择 最近销价 
                case IPriceTypeEnum.RECENT:
                    newPrice = lateSalePrice ?? retailPrice;
                    break;
                //售价选择 零售价
                case IPriceTypeEnum.RETAIL:
                    newPrice = retailPrice;
                    break;
                //售价选择 采购成本价
                case IPriceTypeEnum.PUR_COST:
                    newPrice = latestPurPrice ?? purchasePrice;
                    break;
                //售价选择 档案进价
                case IPriceTypeEnum.BUYING_PRICE:
                    newPrice = purchasePrice ?? latestPurPrice;
                    break;
            }
        }
        setAddDataSource(origin => [...origin, {
            ...record,
            num,
            price: newPrice,
            amount: +formatNum(num * newPrice)
        }])
    }

    const remove = async (record) => {
        const filterAddDataSource = addDataSource.filter((item) => item.id != record.id)
        setAddDataSource(filterAddDataSource)
    }
    const onOK = async () => {
        transformsToPoDetailList(mode)
        onCancel()
    }
    const onCancel = () => {
        setAddModalVisible(false)
        setAddDataSource([])
        resetWorkloadData()
    }
    const rightChildren = (
        <div className="flex">
            <Input
                allowClear
                datatype="round"
                className="border-r-16 mr-10"
                value={searchWorkData.materialCode}
                style={{ width: "150px" }}
                onChange={(e) => {
                    setSearchWorkData({ ...searchWorkData, materialCode: e.target.value.trim() })
                }}
                onPressEnter={() => workloadData(initPgCfg)}
                placeholder="商品编号"
            />
            <Input
                allowClear
                datatype="round"
                className="border-r-16 mr-10"
                value={searchWorkData.materialName}
                style={{ width: "150px" }}
                onChange={(e) => {
                    setSearchWorkData({ ...searchWorkData, materialName: e.target.value.trim() })
                }}
                placeholder="商品名称"
            />
            <Button onClick={() => workloadData()} style={{ margin: "0px 5px 0px 10px" }} shape="round">
                检索
            </Button>
            <Button onClick={() => resetWorkloadData()} shape="round">
                重置
            </Button>
        </div>
    )

    return (
        <YHModal visible={visible} onOk={onOK} onCancel={onCancel} title="销售订单-选择商品" width={1000} bodyHeight={700} showFooter={true}>
            <YhBox title="待选商品列表" rightChildren={rightChildren} style={{ marginBottom: "15px" }}>
                <Table
                    rowClassName={(record) => {
                        if (record.availableStock === 0) {
                            return "editable-row yh-edit-table-tr stock-td"
                        } else {
                            return "editable-row yh-edit-table-tr"
                        }
                    }}
                    rowKey={(record) => record.id}
                    columns={newSelectColumns("add")}
                    dataSource={workDataSource}
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        },
                    }}
                    pagination={{
                        ...workPgCfg,
                        position: ["bottomCenter"],
                        onChange: (pageNum, pageSize) => {
                            workloadData({ pageNum, pageSize })
                        },
                    }}
                    scroll={{ x: "max-content", y: 206 }}
                />
            </YhBox>
            <YhBox title="已选商品列表">
                <Table
                    rowKey={(record) => record.id}
                    rowClassName="editable-row yh-edit-table-tr"
                    columns={newSelectColumns("remove")}
                    dataSource={[...addDataSource]}
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        },
                    }}
                    pagination={false}
                    scroll={{ x: "max-content", y: 206 }}
                />
            </YhBox>
        </YHModal>
    )
}
