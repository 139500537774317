import React, { useState } from "react";
import { Button, Tooltip, Table, message, Upload,Switch } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { YhBut } from "@/erp_subpackage/components/YhButton";
import { usePurchaseAdvancedDetailsModel } from "./purchaseAdvancedDetailModel";
import { formatInt, formatNum } from "@/erp_subpackage/utils/util";
import { EditableCell, EditableRow } from '@/erp_subpackage/components/YHEditTable/editTable';
import { useGlobalModel } from "@/erp_subpackage/model/globalModel";
import { warehouseService } from "@/erp_subpackage/pages/base/warehouse/warehouseService";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { importFileConfig } from "@/erp_subpackage/utils/importFileConfig";
import YhTooltip from "@/erp_subpackage/components/YhTooltip";
import { purchaseAdvancedService } from "../../purchaseAdvancedService";
export const TableBox = ({ modeKey, mode, rowCode, dispatch }) => {
    const {
        editList, addList, lookList, editFormVal, updateList, setMaterialDialog, removeMaterial, downloadTpl, insertMaterial
    } = usePurchaseAdvancedDetailsModel();
    const { user: { shopId, shopName, shopCode,organNature}, whDs, setMaterialInfo } = useGlobalModel(({ user, whDs }) => [user, whDs]);
    const tableList = mode === "edit" ? editList : mode === "look" ? lookList : addList;
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //货位实时数据
    const [shelfDs, setShelfDs] = useState([]);
    const [loading, setLoading] = useState(false)

    //仓库选择
    const whChange = (value, options) => {
        if (options && options.id) {
            queryShelf(options.value)
        }
    };

    //货位根据仓库code查询
    const queryShelf = async (whCode) => {
        const searchParams = {
            shopId, shopName, shopCode,
            warehouseCode: whCode,
            pageSize: -1,
            pageNum: 1,
        }
        let { retData } = await warehouseService.shelfPage(searchParams);
        const { records, ...pg } = retData;
        setShelfDs(records.filter(item => item.status).map(item => ({
            id: item.id,
            label: item.code,
            value: item.code
        })))
    }

    // //货位触焦 
    // const shelfFocus = (record) => {
    //     if (record["warehouseCode"]) {
    //         queryShelf(record["warehouseCode"])
    //     } else {
    //         setShelfDs([]);
    //     }
    // }

    //导入成功
    const importCallback = async (list) => {
        if (list && list.length) {
            let tempArr = [];
            let str = ""
            list.forEach(item => {
                if (item.status) {
                    tempArr.push(item.entity)
                } else {
                    str += `【${item.msg}】`;
                }
            })
            insertMaterial(mode, tempArr);
            str && message.warning(str)
        }
    }

    //根据状态控制页面是否可操作
    const controlEditable = () => {
        if (mode === "add") return true;
        if (mode === "look") return false;
        if (mode === "edit" && editFormVal.status !== DictCodeEnmu.ADVANCED_CREATE) return false;
        return true;
    }

    /////////////货位
    const shelfChange = (value, option, record, rowIndex) => {
        let dataSource = [...tableList];
        dataSource.splice(rowIndex, 1, record);
        updateList(mode, dataSource);
        // setControlled(true)
    }
    //货位触焦 
    const shelfFocus = async (record, rowIndex) => {
        setLoading(true)
        try {
            let dataSource = [...tableList];
            const newRow = dataSource[rowIndex];
            let warehouseCode = record["warehouseCode"]
            if (warehouseCode) {
                //货位列表  判断是当前仓库的货位列表  不做接口请求
                let newRecords = shelfDs.find(item => item.warehouseCode === warehouseCode) ? shelfDs : [];
                if (!newRecords.length) {
                    const { retData } = await warehouseService.shelfAllPage({
                        pageSize: 10000,
                        pageNum: 1,
                        warehouseCode,
                    })
                    newRecords = retData.map((item) => ({
                        label: item.code,
                        value: item.code,
                        id: item.id,
                        warehouseCode: item.warehouseCode
                    }))
                    setShelfDs(newRecords)
                }
                //查询配件有没有在改仓库下采购过，
                let { retData } = await purchaseAdvancedService.getMaterialShelf({
                    shopId,
                    materialCode: record.materialCode,
                    warehouseCode: warehouseCode
                })
                // 采购过的货位带出来不可以编辑， 其它的可以编辑(有临时货位默认带出临时货位)
                if (retData.shelfCode) {
                    newRow.shelfCode = retData.shelfCode;
                    newRow.shelfId = retData.shelfId;
                    newRow.isPurShelf = retData.shelfCode;  //做是否可编辑
                    message.warning(`自动匹配到配件【${record.materialCode}】在该仓库下采购的货位，货位不可做修改操作！！`)
                    dataSource.splice(rowIndex, 1, { ...record, ...newRow });
                    updateList(mode, dataSource);
                    // setControlled(true)
                }
            } else {
                setShelfDs([]);
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
        }
    }


    const action =  <>
    {controlEditable() &&<Button
        className="btn-item"
        shape="round"
        onClick={() => setMaterialDialog(true)}
    >新增商品</Button>}
    {(mode === "add"||((editFormVal.status == DictCodeEnmu.ADVANCED_CREATE||editFormVal.status == DictCodeEnmu.ADVANCED_WAIT_CONFIRM)&&organNature==3))&&<Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (!selectedRowKeys.length) {
                    message.warning("请选择需要批量的数据")
                    return;
                }
                removeMaterial(mode, selectedRowKeys);
                setSelectedRowKeys([])
            }}
        >批量删除</Button>}
        {/* <Upload showUploadList={false}  {...importFileConfig({
            url: `/ci-storage/purchase/details/import`,
            callback: importCallback,
            params: { shopId, shopName },
            isMessage: false
        })}>
            <Button className="btn-item" shape="round">导入</Button>
        </Upload>

        <Button
            className="btn-item"
            shape="round"
            onClick={() => downloadTpl()}
        >下载模板</Button> */}
    </>

    const columns = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
            // render: (text, record, index) => <Tooltip placement="topLeft" title={text}><p className="one-line click-span" onClick={(e) => { e.stopPropagation(); setMaterialInfo(true, { materialCode: text, materialName: record.materialName }) }}>{text}</p></Tooltip>,
            sorter: {
                compare: (a, b) => a.materialCode.charCodeAt(0) - b.materialCode.charCodeAt(0),
                multiple: 1,
            },
        }, {
            title: '商品名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} />,
            sorter: {
                compare: (a, b) => a.materialName?.localeCompare(b.materialName, 'zh-Hans-CN', { sensitivity: 'accent' }),
                multiple: 2,
            },
        }, {
            title: '单位',
            width: 50,
            dataIndex: 'unitName',
        }, {
            title: '采购数量',
            width: 100,
            dataIndex: 'num',
            editable: mode=='add'||((editFormVal.status == DictCodeEnmu.ADVANCED_CREATE||editFormVal.status == DictCodeEnmu.ADVANCED_WAIT_CONFIRM)&&organNature==3),
        }, 
        organNature==3&&mode=='edit'&&!(editFormVal.status == DictCodeEnmu.ADVANCED_WAIT_TRANSFER||editFormVal.status == DictCodeEnmu.ADVANCED_CREATE||editFormVal.status == DictCodeEnmu.ADVANCED_TRANSFER)&&{
            title: '满足数量',
            width: 100,
            dataIndex: 'satisfiedNum',
        },
        organNature!==3&&mode=='edit'&&editFormVal.status !== DictCodeEnmu.ADVANCED_CREATE&&{
            title: '可满足数量',
            width: 100,
            dataIndex: 'waitSatisfiedNum',
            editable: editFormVal.status == DictCodeEnmu.ADVANCED_SUBMIT||editFormVal.status == DictCodeEnmu.ADVANCED_WAIT_AUDIT,
        },{
            title: '采购单价',
            width: 100,
            dataIndex: 'price',
            editable: controlEditable(),
            render: text => formatNum(text)
        }, {
            title: '采购金额',
            width: 100,
            dataIndex: 'amount',
            render: text => formatNum(text)
        }, 
        // {
        //     title: '到货数量',
        //     width: 100,
        //     dataIndex: 'confirmNum',
        //     editable: controlEditable(),
        // },
        {
            title: '品牌',
            width: 100,
            dataIndex: 'brandName',
        },
        // {
        //     title: '仓库',
        //     width: 150,
        //     dataIndex: 'warehouseCode',
        //     editable: controlEditable(),
        //     editType: "select",
        //     // onSelectChange: whChange,
        //     options: whDs,
        //     render: (text, record) => whDs.find(item => item.value === text)?.label ?? record.warehouseName ?? "请选择仓库",
        // }, {
        //     title: '货位',
        //     width: 150,
        //     dataIndex: 'shelfCode',
        //     editable: controlEditable(),
        //     editType: "select",
        //     onSelectFocus: shelfFocus,
        //     onSelectChange: shelfChange,
        //     options: shelfDs,
        //     render: (text) => text ?? "请选择货位",
        // }, 
        {
            title: '备注',
            width: 230,
            dataIndex: 'note',
            editable: organNature!==3,
        }, 
        (mode=='add'||((editFormVal.status == DictCodeEnmu.ADVANCED_CREATE||editFormVal.status == DictCodeEnmu.ADVANCED_WAIT_CONFIRM)&&organNature==3) )&&{
            title: '操作',
            width: 50,
            fixed: "right",
            align: "center",
            render: (text, record) =>
                 <YhBut
                    type="delete"
                    txt="删除"
                    click={() => removeMaterial(mode, [record.key])}
                />
        },
        // mode=='edit'&&editFormVal.status !== DictCodeEnmu.ADVANCED_CREATE&&{
        //     title: '处理状态',
        //     width: 50,
        //     fixed: "right",
        //     align: "center",
        //     dataIndex: 'isConfirm',
        //     render: (text, record) =>
        //     mode=='edit'&&text == 'advanced.details.confirm'?<span style={{color:'#f87300',fontWeight:'bold'}}>● 已完成</span>:<Switch disabled={organNature==3} checkedChildren="满足" unCheckedChildren="不满足" onChange={(checked) => onSwitchChange(checked,record.id)} checked={text == 'advanced.details.init' ? true : false} style={{backgroundColor:text == 'advanced.details.init' ?'#37be5f':'#ff4d4f'}}  />
        // }
    ];

    const newColumn = columns.filter(list=>list).map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                // editable: col.dataIndex === "shelfCode" ? !record.isPurShelf : col?.editable,
                editable: col?.editable,
                // editable: col?.editable,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    });

    //内联编辑
    const handleSave = async (row, dataIndex) => {
        //货位的行编辑放到触焦里面操作， 防止覆盖赋值
        if (dataIndex !== "shelfCode") {
            let dataSource = [...tableList];
            const index = dataSource.findIndex((item) => row.id?row.id === item.id:row.key === item.key);
            const item = dataSource[index];
            if(row.satisfiedNum&&organNature==3||row.changeFields=="num"){
                row.num = +formatInt(row.num, row.satisfiedNum, "采购数量<=满足数量")
            }
            row.price = +formatNum(row.price);
            // row.waitSatisfiedNum = +formatNum(row.waitSatisfiedNum);
            //采购金额 = 数量 * 单价
            row.amount = formatNum(row.num * row.price);
            // row.waitSatisfiedNum = +formatInt(row.waitSatisfiedNum, row.num, "可满足数量<=采购数量")
            // row.confirmNum = +formatInt(row.confirmNum, row.num, "到货数量<=采购数量")
            // row.damageNum = +formatInt(row.damageNum, row.num, "损坏数量<=采购数量")

            row.warehouseName = whDs.find(item => item.value === row.warehouseCode)?.label;
            // row.shelfId = shelfDs.find(item => item.value === row.shelfCode)?.id;
            //判断仓库变化时，  重置货位
            if (row.warehouseCode !== dataSource[index].warehouseCode) {
                setLoading(true)
                try {
                    //货位列表
                    let newRecords = []
                    let params = {
                        pageSize: 10000,
                        pageNum: 1,
                        warehouseCode: row.warehouseCode,
                    }
                    const { retData: shelfAll } = await warehouseService.shelfAllPage(params)
                    newRecords = shelfAll.map((item) => ({
                        label: item.code,
                        value: item.code,
                        id: item.id,
                        warehouseCode: item.warehouseCode
                    }))
                    setShelfDs(newRecords);
                    //查询配件有没有在改仓库下采购过， 
                    let { retData } = await purchaseAdvancedService.getMaterialShelf({
                        shopId,
                        materialCode: row.materialCode,
                        warehouseCode: row.warehouseCode
                    })
                    // debugger;
                    // 采购过的货位带出来不可以编辑， 其它的可以编辑(有临时货位默认带出临时货位)
                    if (retData.shelfCode) {
                        row.shelfCode = retData.shelfCode;
                        row.shelfId = retData.shelfId
                        row.isPurShelf = retData.shelfCode;  //做是否可编辑
                        message.warning(`自动匹配到配件【${row.materialCode}】在该仓库下采购的货位，货位不可做修改操作！！`)
                    } else {
                        let isTemp = newRecords?.find(item => (item.value ?? "").indexOf("临时货位") > -1);
                        row.shelfCode = isTemp?.value;
                        row.shelfId = isTemp?.id;
                        row.isPurShelf = "";
                    }
                    setLoading(false)
                } catch (e) {
                    setLoading(false)
                }
            }

            dataSource.splice(index, 1, { ...item, ...row });
            updateList(mode, dataSource);
            // setControlled(true)
        }
    }
    //客户开关
    const onSwitchChange = (checked,id) => {
        let dataSource = [...tableList];
        const index = dataSource.findIndex((item) => id === item.id);
        const item = dataSource[index];
        dataSource.splice(index, 1, { ...item, isConfirm:checked? 'advanced.details.init':'advanced.details.wait'});
        updateList(mode, dataSource);
        purchaseAdvancedService.onSwitchChange({id:id}).then((res) => {
            message.success("操作成功")
        })
    }
    return (
        <YhBox title={<span style={{ paddingLeft: "20px" }}>商品信息</span>} rightChildren={action}>
            <Table
                loading={{ tip: "数据查询中...", spinning: loading }}
                rowClassName={(record) => {
                    if ((record.waitSatisfiedNum>=0&&editFormVal.status !== DictCodeEnmu.ADVANCED_CREATE)?+Number(record.num) > +(record.waitSatisfiedNum ?? 0):false) return "editable-row yh-edit-table-tr background-FFB4B4"
                    return "editable-row yh-edit-table-tr"
                }}
                rowSelection={{
                    selectedRowKeys: selectedRowKeys, //记录选中
                    onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRowKeys(selectedRowKeys);
                    }
                }}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newColumn}
                dataSource={tableList}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}

