import React, { useState } from 'react'
import { Button, Modal, Select,message } from 'antd'
import moment from 'moment'
import './settleScaniaPrint.css'
import skny from '../../../assets/images/skny.png'
import ouman from '../../../assets/images/ouman.png'
import zf from '../../../assets/images/zf.png'
import iveco from '../../../assets/images/iveco.png'
import yhjt from '../../../assets/images/yhjt.png'
// import { IIMgEmum, logoOptions,  } from '@/types/ScmEnums';
import { globalPrompt } from '../message'
import { useDictModel } from '../../pages/base/dict/dictModel'
// import { exportPDF } from '../../utils/exportPDF';
// import { outputPDF } from '../../utils/outputPDF';
import { saveAs } from 'file-saver'
import { getUserCustomizeConfigOne, saveUserCustomizeConfig } from "@/service/settings"
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { orderPrintInfo, printNum, getOrderOne } from "@/service/ordersService"

const ExcelJS = require('exceljs')

//打印logo设置
export const IIMgEmum = {
    SKNY: 'skny',
    OUMAN: 'ouman',
    ZF: 'zf',
    IVECO: 'iveco',
}
export const logoOptions = [
    //新路王
    { label: '斯堪尼亚logo', value: IIMgEmum.SKNY },
    { label: '依维柯logo', value: IIMgEmum.IVECO },
    { label: '曼恩logo', value: IIMgEmum.OUMAN },
    { label: '采埃孚logo', value: IIMgEmum.ZF },
]
// let companyOptions = [
//     { label: "北京綦齿机电有限公司", value: "北京綦齿机电有限公司",num:'1' },
//     { label: "北京欧豪汽车贸易有限公司", value: "北京欧豪汽车贸易有限公司",num:'2' },
//     { label: "云豪车服（北京）科技服务有限公司", value: "云豪车服（北京）科技服务有限公司",num:'3' },
//     { label: "天津欧豪富港汽车贸易有限公司", value: "天津欧豪富港汽车贸易有限公司",num:'4' },
// ]
// export let shopObject = {
//     "北京綦齿机电有限公司": {
//         "img": skny,
//         "address": "北京市大兴区长子营镇长通路4号",
//         "phone": "010-81280170",
//         "Bank": "工行北京沙河支行",
//         "BankAccount": "0200011709006770729",
//         "Fax": "010-81280170"
//     },
//     "北京欧豪汽车贸易有限公司": {
//         "img": skny,
//         "address": "北京市大兴区长子营镇长通路4号",
//         "phone": "010-81280170",
//         "Bank": "工行沙河支行",
//         "BankAccount": "0200011719200045213",
//         "Fax": "010-81280170",
//     },
//     "云豪车服（北京）科技服务有限公司": {
//         "img": skny,
//         "address": "北京市大兴区长子营镇长通路4号",
//         "phone": "010-81280170",
//         "Bank": "中国工商银行股份有限公司北京工行沙河支行",
//         "BankAccount": "0200011709200163449",
//         "Fax": "010-81280170"
//     },
//     "天津欧豪富港汽车贸易有限公司": {
//         "img": skny,
//         "address": "天津市津南经济开发区《西区)旺港路15号",
//         "phone": "28577391",
//         "Bank": "中国工商银行股份有限公司天津金地支行",
//         "BankAccount": "0302061609100023871",
//         "Fax": "022-28577398"
//     },
// }

let shopObject= {
    "SHOP-M-00000017": {//新疆新陆王
        "img": skny,
        "address": "新疆乌鲁木齐市米东区金汇西路2877号",
        "phone": "0991-5310666     18016861905",
        "Bank": "招商银行乌鲁木齐分行解放北路支行",
        "BankAccount": "8530 8058 0110 001",
        "Fax": "0991-5310666     18016861905"
    },
    "SHOP-M-00000018": {//新疆云河汇
        "img": ouman,
        "phone": "0991-5310666     13209963230",
        "Bank": "新疆天山农村银行股份有限公司米东区支行",
        "BankAccount": "8070 1001 2010 1140 42179",
        "Fax": "0991-5310666     13209963230"

    },
    "SHOP-M-00000020": {//天津欧豪富港
        "img": skny,
        "address": "天津津南经济开发区 旺港路15号",
        "phone": "022-28577398",
        "Bank": "中国工商银行股份有限公司天津金地支行",
        "BankAccount": "0302061609100023871",
        "Fax": "022-28577392"
    },
    "SHOP-M-00000019": {//沈阳斯堪尼亚
        "img": skny,
        "address": "辽宁省沈阳市于洪区旺港街201号13门",
        "phone": "18600830027",
        "Bank": "中国工商银行股份有限公司沈阳于洪支行",
        "BankAccount": "3301009209200071238",
        "Fax": ""
    },
    "SHOP-M-00000016": {//石家庄斯堪尼亚
        "img": skny,
        "address": "河北省石家庄市无极县经济技术开发区西区泰智产业园14幢3-4座",
        "phone": "0311-88036200  ",
        "Bank": "中国工商银行股份有限公司栾城支行",
        "BankAccount": "0402020709300237638",
        "Fax": ""
    },
    "SHOP-M-00000021": {//斯堪尼亚北京公司
        "img": skny,
        "address": "北京市大兴区长子营镇长通路4号",
        "phone": "010-81280170",
        "Bank": "工行北京沙河支行",
        "BankAccount": "0200 0117 0900 6770 729",
        "Fax": "010-81280170"
    },
    "SHOP-M-00000029": {//内蒙斯堪尼亚
        "img": skny,
        "address": "北京市大兴区长子营镇长通路4号",
        "phone": "010-81280170",
        "Bank": "工行北京沙河支行",
        "BankAccount": "0200 0117 0900 6770 729",
        "Fax": "010-81280170"
    },    
}

export const SettleScaniaPrint = ({
    title = '',
    visible,
    onClose,
    columns,
    itemClumns,
    itemDataSource,
    dataSource,
    formVal,
    isTechnician = false,
    price,
    baseInfo,
    otherClumns,
    otherDataSource,
    // printNum
}) => {
    const { getTreeNodeName, getTreeNodeOne } = useDictModel()
    // const { CustomerData, shopAndOrg: { shopName, shopId } } = useGlobalModel()
    // const [settlementPartyName, setSettlementPartyName] = useState("")
    const [mergeDataSource, setMergeDataSource] = useState()
    const { user: { shopCode, orgShopCode } ,setMaterialInfo} = useGlobalModel();

    // 遍历商品td
    const mapGoods = (goods) => {
        return columns
            .filter((item) => item)
            .map((item, index) => (
                <td colSpan={item.colSpan ?? 1} key={index}>
                    {item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}
                </td>
            ))
    }
    //遍历项目td
    const itemGoods = (goods) => {
        return itemClumns
            ?.filter((item) => item)
            .map((item, index) => (
                <td colSpan={item.colSpan ?? 1} key={index}>
                    {item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}
                </td>
            ))
    }
    //遍其他项目td
    const otherGoods = (goods) => {
        return otherClumns
            ?.filter((item) => item)
            .map((item, index) => (
                <td colSpan={item.colSpan ?? 1} key={index}>
                    {item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}
                </td>
            ))
    }
    const [imgVal, setImgVal] = useState(skny)
    // const [companyVal, setCompanyVal] = useState(companyOptions.find((item)=>item.num==printNum).label)
    // const [printNumber, setPrintNumber] = useState(printNum)

    const convertImageToBase64 = (imageUrl) => {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.crossOrigin = 'Anonymous' // 防止跨域问题
            img.onload = () => {
                const canvas = document.createElement('canvas')
                canvas.width = img.width
                canvas.height = img.height
                const ctx = canvas.getContext('2d')
                ctx.drawImage(img, 0, 0)

                const base64 = canvas.toDataURL('image/png')
                resolve(base64)
            }
            img.onerror = () => {
                reject(new Error('Failed to load image'))
            }
            img.src = imageUrl
        })
    }

    const getItemColumns = () => {
        return itemClumns.map((item) => {
            return item.title
        }).slice(0,7)
        // return ['序号','项目编号','项目名称','工时','单价','折扣','工时费' ]
    }
    const getItemDataSource = () => {
        if (!itemDataSource || itemDataSource.length <= 0) {
            return []
        }

        let itemDataIndexList = itemClumns.map((item) => {
            return item.dataIndex
        })
        // let itemDataIndexList =['序号','商品编号','商品名称','数量','单价','折扣','金额']
        // [][]
        return itemDataSource.map((item) => {
            let arr = []
            itemDataIndexList.forEach((dataIndex) => {
                let val = item[dataIndex]
                arr.push(val)
            })
            return arr.slice(0,7)
        })
    }
    const getMaterialColumns = () => {
        return columns.map((item) => {
            return item.title
        }).slice(0,7)
        // return ['序号','零件编码','配件名称','数量','单价','折扣','零件费']
    }
    const getMaterialDataSource = () => {
        if (!dataSource || dataSource.length <= 0) {
            return []
        }

        let itemDataIndexList = columns.map((item) => {
            return item.dataIndex
        })
        // [][]
        // let itemDataIndexList =['序号','零件编码','配件名称','数量','单价','折扣','零件费']
        return dataSource.map((item) => {
            let arr = []
            itemDataIndexList.forEach((dataIndex) => {
                let val = item[dataIndex]
                arr.push(val)
            })
            return arr.slice(0,7)
        })
    }
    const getOtherColumns = () => {
        return otherClumns.map((item) => {
            return item.title
        })
    }
    const getOtherDataSource = () => {
        if (!otherDataSource || otherDataSource.length <= 0) {
            return []
        }

        let itemDataIndexList = otherClumns.map((item) => {
            return item.dataIndex
        })
        // [][]
        return otherDataSource.map((item) => {
            let arr = []
            itemDataIndexList.forEach((dataIndex) => {
                let val = item[dataIndex]
                arr.push(val)
            })
            return arr
        })
    }

    const printExport = async () => {
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('Sheet1')
        worksheet.properties.defaultRowHeight = 25 // 设置默认行高
        worksheet.properties.defaultColWidth = 15 // 设置默认列宽

        // logo 
        let base64Data = await convertImageToBase64(skny)
        const logoImgId = workbook.addImage({ base64: base64Data, extension: 'png' })

        worksheet.mergeCells('A1:G1')
        worksheet.getCell('A1').value = '工单详情打印'
        // 在 B2:D6 上插入图片
        worksheet.addImage(logoImgId, 'A3:A4')

        // 右侧
        worksheet.mergeCells('D2:G2')
        worksheet.mergeCells('D3:G3')
        worksheet.mergeCells('D4:G4')
        worksheet.mergeCells('D5:G5')
        worksheet.mergeCells('D6:G6')
        worksheet.getCell('D2').value = title
        worksheet.getCell('D3').value = `地址：${shopObject[shopCode]?shopObject[shopCode]["address"]:''}`
        worksheet.getCell('D4').value = `电话：${shopObject[shopCode]?shopObject[shopCode]["phone"]:''}`
        worksheet.getCell('D5').value = `开户银行：${shopObject[shopCode]?shopObject[shopCode]["Bank"]:''}`
        worksheet.getCell('D6').value = `开户账号：${shopObject[shopCode]?shopObject[shopCode]["BankAccount"]:''}`

        worksheet.mergeCells('A8:C8')
        worksheet.mergeCells('D8:G8')
        worksheet.getCell('A8').value = '委托客户:' + formVal.customerName
        worksheet.getCell('D8').value = '工单号:' + formVal.maintenanceCode

        worksheet.mergeCells('A9:B9')
        worksheet.mergeCells('C9')
        worksheet.mergeCells('D9:G9')
        worksheet.getCell('A9').value = '车牌号:' + formVal.licensePlate
        worksheet.getCell('C9').value = '底盘号:' + formVal.vin
        worksheet.getCell('D9').value = '预约进厂时间:' + formVal.enterTime

        worksheet.mergeCells('A10:B10')
        worksheet.mergeCells('C10')
        worksheet.mergeCells('D10:G10')
        worksheet.getCell('A10').value = '送修人:' + formVal.contact
        worksheet.getCell('C10').value = '联系电话:' + formVal.phone
        worksheet.getCell('D10').value = '预约出厂时间:' + formVal.deliveryTime

        worksheet.mergeCells('A11:B11')
        worksheet.mergeCells('C11')
        worksheet.mergeCells('D11:G11')
        worksheet.getCell('A11').value = '维修类型:' + formVal.serviceTypeName
        worksheet.getCell('C11').value = '车辆类型:' + formVal.vehicleTypeName
        worksheet.getCell('D11').value = '行驶里程/工作时间:' + formVal.mileage

        worksheet.mergeCells('A12:G12')
        worksheet.getCell('A12').value = '故障描述:' + formVal.faultDesc

        worksheet.mergeCells('A13:G13')
        worksheet.getCell('A13').value = '原因分析和处理意见:' + formVal.faultResult

        // 写入多个
        // let currRow = worksheet.getRow(13)
        if (itemClumns && itemDataSource && itemDataSource.length > 0) {
            let itemExcelColumns = getItemColumns()
            let itemExcelDataSource = getItemDataSource()
            worksheet.addRow(itemExcelColumns)
            worksheet.addRows(itemExcelDataSource)
        }
        if (columns && dataSource && dataSource.length > 0) {
            let materialExcelColumns = getMaterialColumns()
            let materialExcelDataSource = getMaterialDataSource()
            worksheet.addRow(materialExcelColumns)
            worksheet.addRows(materialExcelDataSource)
        }
        if (otherClumns && otherDataSource && otherDataSource.length) {
            let otherExcelColumns = getOtherColumns()
            let otherExcelDataSource = getOtherDataSource()
            worksheet.addRow(otherExcelColumns)
            worksheet.addRows(otherExcelDataSource)
        }

        let itemRow=itemDataSource.length>0?1+itemDataSource.length:0;
        let materialRow=dataSource.length>0?1+dataSource.length:0;
        let otherRow=otherDataSource.length>0?1+otherDataSource.length:0;
        let rowCount = itemRow+materialRow+otherRow+ 14;

        const secondCell1 = worksheet.getCell(`A${rowCount}`);
        worksheet.mergeCells(`A${rowCount}:G${rowCount}`)
        secondCell1.value = '零件小计：'+price.materialScienceAmount
        secondCell1.alignment = { horizontal: 'right' };

        const secondCell2 = worksheet.getCell(`A${rowCount+1}`);
        worksheet.mergeCells(`A${rowCount+1}:G${rowCount+1}`)
        secondCell2.value = '总合计：'+price.totalAmount
        secondCell2.alignment = { horizontal: 'right' };

        const secondCell3 = worksheet.getCell(`A${rowCount+2}`);
        worksheet.mergeCells(`A${rowCount+2}:G${rowCount+2}`)
        secondCell3.value = '备注：'+formVal.remark

        const secondCell4 = worksheet.getCell(`A${rowCount+3}`);
        worksheet.mergeCells(`A${rowCount+3}:G${rowCount+3}`)
        secondCell4.value = '制单员：'+formVal.createUserName

        const secondCell5 = worksheet.getCell(`A${rowCount+4}`);
        worksheet.mergeCells(`A${rowCount+4}:G${rowCount+5}`)
        secondCell5.value = '声明 1.本人同意按贵厂工单所列的修理项目修理，愿意支付有关项目需要更换的零件款及修理费 2.车辆翻新，拆装前后挡风玻璃，如有损坏，我厂概不赔偿 3.车主提车时，请对我厂修理项目，车况检查清楚，出厂后概不负责'
        secondCell5.alignment = { vertical: 'top', horizontal: 'left', wrapText: true  };
        
        const secondCell6 = worksheet.getCell(`A${rowCount+6}`);
        worksheet.mergeCells(`A${rowCount+6}:G${rowCount+6}`)
        secondCell6.value = '委托人：'

        // 导出文件
        workbook.xlsx.writeBuffer().then((buffer) => {
            let blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            saveAs(blob, '工单打印.xlsx')
        })
    }

    const print = async () => {
        // if (formVal?.orderStatus !== 'completed') {
        //     globalPrompt('message', { text: '请完工之后再进行结算打印', type: 'warning' })
        //     return
        // }
        const el = document.getElementById('settle-scania-print-container')
        const iframe = document.createElement('IFRAME')
        let doc = null
        document.body.appendChild(iframe)
        doc = iframe.contentWindow.document
        // 引入打印的专有CSS样式，根据实际修改
        doc.write(`<LINK rel="stylesheet" type="text/css" href="./settleScaniaPrint.css">`)
        doc.write(el?.innerHTML)
        doc.close()
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus()
        iframe.onload = () => {
            iframe.contentWindow.print()
            document.body.removeChild(iframe)
        }
        if (navigator.userAgent.indexOf('MSIE') > 0) {
            document.body.removeChild(iframe)
        }
        await printNum({orderId:visible.orderId,printType:'settlement'})
        // saveUserCustomizeConfig({ key: 'printModel', val: printNumber }).then((res) => {
        //     message.success("门店记录成功")
        // })
    }
    const setLogo = () => {
        let logoVal = IIMgEmum.SKNY
        globalPrompt('modal', {
            width: 380,
            title: '设置logo',
            type: 'info',
            // text: "",
            text: (
                <>
                    <span>请选择Logo类型</span>
                    <Select showSearch={true} defaultValue={IIMgEmum.SKNY} options={logoOptions} onChange={(value, option) => (logoVal = value)} />
                </>
            ),
            okText: '确定',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    resolve(logoVal)
                }).then((res) => {
                    switch (logoVal) {
                        case IIMgEmum.SKNY:
                            return setImgVal(skny)
                        case IIMgEmum.OUMAN:
                            return setImgVal(ouman)
                        case IIMgEmum.ZF:
                            return setImgVal(zf)
                        case IIMgEmum.IVECO:
                            return setImgVal(iveco)
                        default:
                            return setImgVal(skny)
                    }
                    // setImgVal(logoVal)
                })
            },
            cancelText: '取消',
            onCancel: () => {},
        })
    }
    // const setCompany = () => {
    //     let companyValue = ''
    //     globalPrompt("modal", {
    //         width: 570,
    //         title: "设置公司",
    //         type: "info",
    //         // text: "",
    //         text: <>
    //             <span style={{ marginRight: '30px' }}>请选择公司信息</span>
    //             <Select
    //                 showSearch={true}
    //                 defaultValue={"北京綦齿机电有限公司"}
    //                 options={companyOptions}
    //                 onChange={(value, option) => companyValue = value}
    //             />
    //         </>,
    //         okText: "确定",
    //         onOk: () => {
    //             return new Promise((resolve, reject) => {
    //                 console.log(companyValue, "aaaaaa")
    //                 resolve(companyValue)
    //             }).then(res => {
    //                 switch (companyValue) {
    //                     case '北京綦齿机电有限公司':
    //                         setPrintNumber('1')
    //                         return setCompanyVal('北京綦齿机电有限公司')
    //                     case '北京欧豪汽车贸易有限公司':
    //                         setPrintNumber('2')
    //                         return setCompanyVal('北京欧豪汽车贸易有限公司')
    //                     case '云豪车服（北京）科技服务有限公司':
    //                         setPrintNumber('3')
    //                         return setCompanyVal('云豪车服（北京）科技服务有限公司')
    //                     case '天津欧豪富港汽车贸易有限公司':
    //                         setPrintNumber('4')
    //                         return setCompanyVal('天津欧豪富港汽车贸易有限公司')
    //                     default:
    //                         setPrintNumber('1')
    //                         return setCompanyVal('北京綦齿机电有限公司')
    //                 }
    //             })
    //         },
    //         cancelText: "取消",
    //         onCancel: () => {
    //         }
    //     })
    // }
    // const handleOutput = async () => {
    //     // debugger;
    //     const element = document.querySelector('.pdf-content');
    //     const header = document.querySelector('.pdf-header');
    //     const footer = document.querySelector('.pdf-footer');
    //     try {
    //         await outputPDF({
    //             element: element,
    //             footer: footer,
    //             header: header,
    //             contentWidth: 560
    //         })
    //     } catch (error) {
    //         console.log(error)
    //         message.error(typeof error === 'string' ? error : JSON.stringify(error))
    //     }

    // }

    return (
        <Modal
            title="打印详情"
            width={800}
            visible={visible}
            onCancel={onClose}
            bodyStyle={{ padding: '0' }}
            footer={[
                <Button type="primary" onClick={onClose} key="back">
                    {' '}
                    取消
                </Button>,
                <Button type="primary" onClick={print} key="submit">
                    确认打印
                </Button>,
                <Button type="primary" onClick={printExport} key="export">
                    导出Excel
                </Button>,
                <Button type="primary" onClick={setLogo} key="logo">
                    设置Logo
                </Button>,
                // <Button type="primary" onClick={setCompany} key="company">门店设置</Button>,
                // <Button
                //     className="mr-5"
                //     type="link"
                //     key="exportPdf"
                //     onClick={handleOutput}
                // >导出PDF</Button>,
                // <Button
                //     className="mr-5"
                //     type="link"
                //     key="exportPdf"
                //     onClick={() => {
                //         exportPDF(title, "settle-scania-print-container", () => {
                //         })
                //     }}
                // >导出PDF</Button>,
            ]}
        >
            <div id="settle-scania-print-container" className="pdf-content print-box" style={{ padding: '24px' }}>
                <div className="settle-container">
                    <div className="settle-header">
                        <div className="h2">
                            <h2>{title}修理结算单</h2>
                        </div>
                        <div className={`logo `}>
                            <img src={yhjt} alt="" />
                            {/* {<div>欧豪富港</div>} */}
                        </div>
                        <div className={`skny`}>
                            <img src={imgVal} alt="斯堪尼亚" />
                        </div>
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <td>服务厂:</td>
                                <td colSpan={3}>{title ?? ''}</td>
                                {/* <td>工单号:</td>
                            <td>{formVal.maintenanceCode}</td> */}
                                {/* <td>结算时间:</td>
                            <td>{momentFormat(formVal.settleTime)}</td> */}
                            </tr>
                            {/* <tr>
                            <td colSpan={6}></td>
                        </tr> */}
                            <tr>
                                <td colSpan={6}></td>
                            </tr>
                            <tr>
                                <td colSpan={6}></td>
                            </tr>
                            <tr>
                                <td>工单号:</td>
                                <td>{formVal.maintenanceCode}</td>
                                <td>进厂时间:</td>
                                <td>{formVal.enterTime}</td>
                                <td>出厂时间:</td>
                                <td>{formVal.deliveryTime}</td>
                                {/* <td>托修单位:</td>
                            <td>{formVal.customerName}</td>
                            <td>车辆类型:</td>
                            <td>{formVal.vehicleTypeName}</td>
                            <td>出厂时间:</td>
                            <td>{formVal.deliveryTime}</td> */}
                            </tr>
                            <tr>
                                <td>托修单位:</td>
                                <td colSpan={3}>{formVal.customerName}</td>
                                {/* <td>{formVal.contact}</td>
                            <td>车牌号:</td>
                            <td>{formVal.licensePlate}</td> */}
                                <td>行驶里程:</td>
                                <td>
                                    {formVal.mileage}
                                    <span className="span-right">公里/每小时</span>
                                </td>
                            </tr>
                            <tr>
                                <td>联系人:</td>
                                <td>{formVal.contact}</td>
                                <td>联系电话:</td>
                                <td>{formVal.phone}</td>
                                <td>结算方式:</td>
                                <td>{getTreeNodeName(formVal.payType)}</td>
                                {/* <td>联系电话:</td>
                            <td>{formVal.phone}</td>
                            <td>底盘号:</td>
                            <td>{formVal.vin}</td>
                            <td>结算日期:</td>
                            <td>{formVal.settleTime}</td> */}
                            </tr>
                            <tr>
                                <td>车辆类型:</td>
                                <td>{formVal.vehicleTypeName}</td>
                                <td>车牌号:</td>
                                <td>{formVal.licensePlate}</td>
                                <td>底盘号:</td>
                                <td>{formVal.vin}</td>
                            </tr>
                            <tr>
                                <td>维修类型:</td>
                                <td>{formVal.serviceTypeName}</td>
                                <td>结算方:</td>
                                <td>{formVal.settlementPartyName}</td>
                                <td>运行小时:</td>
                                <td>{formVal.operatingHours}</td>
                            </tr>
                            <tr className="bottom-solid">
                                <td width="100px">客户地址:</td>
                                <td colSpan={3} style={{ wordBreak: 'break-all' }}>
                                    {formVal.customerAddress}
                                </td>
                                {/* {title === '欧豪汽车销售服务（沈阳）有限公司' &&
                            <>
                            <td>工作小时:</td>
                            <td>{formVal.workingHours}</td>
                            </>
                            } */}
                            </tr>
                            {isTechnician && (
                                <tr className="bottom-solid">
                                    <td width="100px">维修技师:</td>
                                    <td colSpan={5} style={{ wordBreak: 'break-all' }}>
                                        {formVal.technicianNames}
                                    </td>
                                </tr>
                            )}
                            <tr className="bottom-solid">
                                <td width="100px">故障描述:</td>
                                <td colSpan={5} style={{ wordBreak: 'break-all' }}>
                                    {formVal.faultDesc}
                                </td>
                            </tr>
                            <tr className="bottom-solid">
                                <td width="100px">处理结果:</td>
                                <td colSpan={5} style={{ wordBreak: 'break-all' }}>
                                    {formVal.faultResult}
                                </td>
                            </tr>
                            {/* <tr>
                            <td>服务厂:</td>
                            <td>{title}</td>
                            <td>工单号:</td>
                            <td>{formVal.maintenanceCode}</td>
                            <td>进厂时间:</td>
                            <td>{formVal.enterTime}</td>
                        </tr>
                        <tr>
                            <td colSpan={6}></td>
                        </tr>
                        <tr>
                            <td>托修单位:</td>
                            <td>{formVal.customerName}</td>
                            <td>车辆类型:</td>
                            <td>{formVal.vehicleTypeName}</td>
                            <td>出厂时间:</td>
                            <td>{formVal.deliveryTime}</td>
                        </tr>
                        <tr>
                            <td>联系人:</td>
                            <td>{formVal.contact}</td>
                            <td>车牌号:</td>
                            <td>{formVal.licensePlate}</td>
                            <td>行驶里程:</td>
                            <td>{formVal.mileage}<span className="span-right">公里/每小时</span></td>
                        </tr>
                        <tr>
                            <td>联系电话:</td>
                            <td>{formVal.phone}</td>
                            <td>底盘号:</td>
                            <td>{formVal.vin}</td>
                            <td>结算日期:</td>
                            <td>{formVal.settleTime}</td>
                        </tr>
                        <tr className="bottom-solid">
                            <td width="100px">故障描述:</td>
                            <td colSpan={5}>{formVal.faultDesc}</td>
                        </tr>
                        <tr className="bottom-solid">
                            <td width="100px">处理结果:</td>
                            <td colSpan={5}>{formVal.faultResult}</td>
                        </tr> */}
                            {/* <tr>
                            <td>服务厂:</td>
                            <td colSpan={3}>{title}</td>
                            <td>结算日期:</td>
                            <td>{formVal.settleTime}</td>
                            <td>工单号:</td>
                            <td>{formVal.maintenanceCode}</td>
                        </tr>
                        <tr>
                            <td>工单号:</td>
                            <td>{formVal.maintenanceCode}</td>
                            <td>进厂时间:</td>
                            <td>{formVal.enterTime}</td>
                            <td>出厂时间:</td>
                            <td>{formVal.deliveryTime}</td>
                        </tr>
                        <tr>
                            <td>托修单位:</td>
                            <td colSpan={3}>{formVal.customerName}</td>
                            <td>客户编码:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>联系人:</td>
                            <td>{formVal.contact}</td>
                            <td>联系电话:</td>
                            <td>{formVal.phone}</td>
                            <td>发票号:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>牌照号:</td>
                            <td>{formVal.licensePlate}</td>
                            <td>底盘号:</td>
                            <td>{formVal.vin}</td>
                            <td>发动机号:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>制造商:</td>
                            <td></td>
                            <td>车辆型号:</td>
                            <td>{formVal.vehicleModel}</td>
                            <td>行驶里程:</td>
                            <td>{formVal.mileage}<span className="span-right">公里/每小时</span></td>

                        </tr>
                        <tr>
                            <td>维修类型:</td>
                            <td>{formVal.serviceNatureName}</td>
                            <td>车辆类型:</td>
                            <td>{formVal.vehicleTypeName}</td>
                            <td>工作小时</td>
                            <td>{formVal.workingHours}</td>
                        </tr>
                        <tr className="bottom-solid">
                            <td>联系地址:</td>
                            <td colSpan={3}>{formVal.customerAddress}</td>
                        </tr>
                        <tr className="bottom-solid">
                            <td width="100px">故障描述:</td>
                            <td colSpan={5}>{formVal.faultDesc}</td>
                        </tr>
                        <tr className="bottom-solid">
                            <td width="100px">处理结果:</td>
                            <td colSpan={5}>{formVal.faultResult}</td>
                        </tr> */}
                        </tbody>
                    </table>
                    <table className="ming-xi">
                        <tbody>
                            {itemClumns && itemDataSource && itemDataSource.length > 0 && (
                                <tr>
                                    {/* <td>序号</td>
                            <td>项目编码</td>
                            <td>修理项目</td>
                            <td>工时</td>
                            <td style={{ textAlign: "right" }}>单价</td>
                            <td style={{ textAlign: "right" }}>折扣</td>

                            <td style={{ textAlign: "right" }}>工时金额</td> */}
                                    {itemClumns
                                        .filter((item) => item)
                                        .map((item, index) => (
                                            <td key={index}>{item.title}</td>
                                        ))}
                                </tr>
                            )}
                            {itemDataSource && itemDataSource.length > 0 && itemDataSource.map((item, index) => <tr key={index}>{itemGoods(item)}</tr>)}
                            {/* <tr style={{ height: "20px" }}>
                            <td ></td>
                        </tr> */}
                            {columns && dataSource && dataSource.length > 0 && (
                                <tr className="part">
                                    {/* <td>序号</td>
                            <td>零件编码</td>
                            <td>零件名称</td>
                            <td>数量</td>
                            <td style={{ textAlign: "right" }}>单价</td>
                            <td style={{ textAlign: "right" }}>折扣</td>
                            <td style={{ textAlign: "right" }}>零件金额</td> */}
                                    {columns
                                        .filter((item) => item)
                                        .map((item, index) => (
                                            <td key={index}>{item.title}</td>
                                        ))}
                                </tr>
                            )}
                            {dataSource && dataSource.length > 0 && dataSource.map((item, index) => <tr key={index}>{mapGoods(item)}</tr>)}
                            {/* <tr style={{ height: "20px" }}>
                            <td colSpan={columns.length}></td>
                        </tr> */}
                            {otherClumns && otherDataSource && otherDataSource.length > 0 && (
                                <tr className="part">
                                    {otherClumns
                                        .filter((item) => item)
                                        .map((item, index) => (
                                            <td key={index}>{item.title}</td>
                                        ))}
                                </tr>
                            )}
                            {otherDataSource && otherDataSource.length > 0 && otherDataSource.map((item, index) => <tr key={index}>{otherGoods(item)}</tr>)}
                            {/* <tr style={{ height: "20px" }}>
                            <td></td>
                        </tr> */}
                        </tbody>
                    </table>
                    <div className="total-price">
                        <table>
                            <tbody>
                                <tr>
                                    <td>工时小计</td>
                                    <td style={{ textAlign: 'right' }} colSpan={2}>
                                        {price.workingHoursAmount ?? 0}
                                    </td>
                                </tr>
                                <tr>
                                    <td>零件小计</td>
                                    <td style={{ textAlign: 'right' }} colSpan={2}>
                                        {price.materialScienceAmount}
                                    </td>
                                </tr>
                                <tr>
                                    <td>其他费用</td>
                                    <td style={{ textAlign: 'right' }} colSpan={2}>
                                        {price.otherItemActualAmount}
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td>合计金额</td>
                                    <td style={{ textAlign: 'right' }} colSpan={2}>
                                        {price.totalAmount}
                                    </td>
                                </tr> */}
                                {/* <tr>
                                <td>含税金额</td>
                                <td style={{ textAlign: "right" }} colSpan={2}>{price.totalAmount}</td>
                            </tr> */}
                                <tr>
                                    <td>费用合计</td>
                                    <td style={{ textAlign: 'right' }} colSpan={2}>
                                        {price.orderSummaryAmount}
                                    </td>
                                </tr>
                                <tr>
                                    <td>优惠金额</td>
                                    <td style={{ textAlign: 'right' }} colSpan={2}>
                                        {price.orderPreferentialAmount}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        {formVal.payType === 'settle-type.cash' ? '实收' : '应收'}人民币金额(RMB)&nbsp;&nbsp;{formVal.paymentMethodName}
                                    </td>
                                    <td style={{ textAlign: 'right' }} colSpan={2}>
                                        {price.totalAmount}
                                    </td>
                                </tr>
                                <tr>
                                    <td>大写人民币</td>
                                    <td style={{ textAlign: 'right' }} colSpan={2}>
                                        {price.receivableTotalAmountCN}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <table>
                        <tbody>
                            <tr style={{ width: "90%" }}>
                                <td style={{ margin: "20px 0 0 -250px" }}>{formVal.payType === "settle-type.cash" ? '实收' : '应收'}人民币金额(RMB)<span>{formVal.customerName}{formVal.paymentMethodName}</span></td>
                                <td style={{ textAlign: "right" }} colSpan={3}>{formVal.receivableTotalAmount}</td>
                            </tr>
                        </tbody>
                    </table> */}
                    </div>
                    {/* <div className="paybox">
                    <div className="paybox-item">结算类型:<span>&nbsp;&nbsp;{getTreeNodeName(formVal.payType as string)}</span></div>
                    <div className="paybox-item">{formVal.payType === "settle-type.cash" ? '实收:' : '应收:'}<span>&nbsp;&nbsp;{formVal.receivableTotalAmount}</span></div>
                </div> */}
                    <p className="remarks" style={{ margin: '10px 0 20px' }}>
                        备注：{formVal.remark}
                    </p>
                    <div className="print-flex">
                        <div>
                            制单<span>&nbsp;&nbsp;{formVal.createUserName}</span>
                        </div>
                        <div>
                            库房<span>&nbsp;&nbsp;</span>
                        </div>
                        <div>
                            质检<span>&nbsp;&nbsp;{formVal.formVal}</span>
                        </div>
                        <div>
                            财务<span>&nbsp;&nbsp;{formVal.formVal}</span>
                        </div>
                    </div>
                    <div className={`sign-open`}>
                        <div>托修人签字:</div>
                        <div>维修厂签字:</div>
                    </div>
                    <div className={`warm-reminder`}>
                        <p>预防性更换温馨提示：</p>
                        <p>前轮轴承80万公里，后轮轴承60万公里，离合器分离轴承70万公里</p>
                    </div>
                    <div className="footer">
                        <span>{title}</span>
                        <span>
                            地址：<span>{shopObject[shopCode]?shopObject[shopCode]["address"]:''}</span>
                        </span>
                        <span>
                            电话：<span>{shopObject[shopCode]?shopObject[shopCode]["phone"]:''}</span>
                        </span>
                    </div>
                    <p style={{ marginTop: '20px', textAlign: 'right' }}>打印日期:&nbsp;&nbsp;&nbsp;{moment(new Date()).format('YYYY-MM-DD HH:mm')}</p>
                </div>
            </div>
            {/* <div className="pdf-header">
            &nbsp;
        </div>
        <div className="pdf-footer" style={{ fontSize: "14px", opacity: 0 }}>
            <span>第<span className="pdf-footer-page"></span>页 / 第<span className="pdf-footer-page-count"></span>页</span>
        </div> */}
        </Modal>
    )
}
