import React from "react"
import YhIcon from "../pubIcon"
import "./Drawer.less"
// interface IProps {
//     closable ?: boolean,
//     destroyOnClose :  boolean,
// }
const Drawer = (props) => {
    const { maskClosable = false, mask = false, zIndex = 1000, onClose, children, visible, width } = props

    const handleClose = () => {
        onClose && onClose()
    }

    return (
        // <>{visible &&
        <div
            className="xDrawerWrap"
            style={{
                transform: visible ? "translateX(0)" : "translateX(100%)",
                zIndex,
            }}
        >
            {!!mask && <div className="xDrawerMask" onClick={maskClosable ? handleClose : null}></div>}
            <div className="xDrawerContent" style={width ? {width:width} : {}}>
                {/* {children} */}
                {!visible ? null : children}

                <span className="xCloseBtn" onClick={handleClose}>
                    <YhIcon type="icon-guanbi" />
                </span>
            </div>
        </div>
        // }
        // </>
    )
}

export default Drawer
