import React, { useEffect, useState } from "react"
import { Form, Input, Col, Row, Select, message, Modal, Upload, Button } from "antd"
import { bussinessCategoryInsert, bussinessCategoryUpdate, bussinessCategoryOne } from "@/service/dataService"
import { organPage } from "@/service/orgService"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"
import { getToken } from "@/utils/dataStorage"
import { AccountClassStatus } from "@/utils/enum"

export default (props) => {
    const { mode, rowInfo, visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()
    const [bannerFileList, setbannerFileList] = useState({
        file: null,
        md5: "",
    }) //图片list

    const [previewImage, setpreviewImage] = useState({
        //预览展示头像MD5
        visible: false,
        md5: "",
    })

    useEffect(() => {
        queryMotorcade()
    }, [])

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            bussinessCategoryOne(rowInfo.id).then((res) => {
                let results = res.retData
                let fileList
                if (results.categoryImg) {
                    //回显图片， 有的话在做回显
                    fileList = [
                        {
                            uid: "-1",
                            status: "done",
                            url: `${fileLookUrl().image}${results.categoryImg}`,
                        },
                    ]
                    setbannerFileList({
                        file: fileList,
                        md5: results.categoryImg,
                    })
                }
                form.setFieldsValue({
                    ...results,
                    categoryImg: fileList,
                })
            })
        }
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                categoryImg: bannerFileList.md5,
            }

            if (mode === "edit") {
                data.id = rowInfo.id
                bussinessCategoryUpdate(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                bussinessCategoryInsert(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }

    const setTeamOnes = (results) => {
        console.log(results)
        form.setFieldsValue({
            ...results,
            disableFlag: results.disableFlag === 0 ? true : false,
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleCancel = () => {
        setVisible("")
    }

    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }
    // 图片上传
    const handleImgChange = async (info) => {
        setbannerFileList([])
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setpreviewImage({ visible: false, md5: md5 })
            setbannerFileList({ file: info.fileList, md5: md5 })
        }
    }

    return (
        <>
            <Modal visible={previewImage.visible} title="头像查看" footer={null} onCancel={() => setpreviewImage({ ...previewImage, visible: false })}>
                <img alt="" style={{ width: "100%" }} src={`${fileLookUrl().image}${previewImage.md5}`} />
            </Modal>
            <Modal forceRender visible={visible} title="服务定价" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={600}>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Form.Item label="名称" name="categoryName" rules={[{ required: true, message: "请输入名称" }]}>
                                    <Input placeholder="请输名称" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="排序" name="categorySort">
                                    <Input placeholder="请输入排序" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="属性" name="categoryType" rules={[{ required: true, message: "请选择属性" }]}>
                                    <Select placeholder="请选择属性" style={{ width: "100%" }} >
                                        {Object.entries(AccountClassStatus).map((item) => (
                                            <Select.Option value={Number(item[0])} key={item[0]}>
                                                {item[1]}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="图片"
                                    name="categoryImg"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    rules={[
                                        {
                                            required: true,
                                            message: "请上传图片",
                                        },
                                    ]}
                                >
                                    <Upload
                                        action={`${fileUploadUrl}upload/image_old`}
                                        headers={{ authorization: getToken() }}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={bannerFileList.file}
                                        onPreview={(file) => {
                                            const md5 = file.response.retData
                                            setpreviewImage({ visible: true, md5 })
                                        }}
                                        onChange={handleImgChange}
                                    >
                                        {bannerFileList.file && bannerFileList.file.length >= 1 ? null : <Button size="small">上传banner图片</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            {/* <Col span={24}>
                                <Form.Item label="备注" name="remark">
                                    <Input placeholder="备注" />
                                </Form.Item>
                            </Col> */}
                            {/* <Col  span={24}>
                                <Form.Item name="disableFlag" label="启用" valuePropName="checked">
                                    <Switch checkedChildren="是" unCheckedChildren="否" />
                                </Form.Item>
                            </Col> */}
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
