import React, { useState, useEffect, useRef, useContext } from "react"
import { Button, message, Modal, Select, Spin, Form, Col, Row, Input, DatePicker, Tooltip, Tabs, Image } from "antd"
import { returnOrderOne, getOrderImg, lookMaintainer, returnOrderEdit, returnOrderWarehouse, returnOrderSettlement } from "@/service/ordersService"
import { getUserInfo, getPcodeDict } from "@/utils/dataStorage"
import LookCommentAndPayment from "../lookComment" //支付和评价查看
import moment from "moment"
import { timeShift } from "@/utils/util"
import { fileLookUrl } from "@/config/app.js"
import SanBaoList from "./sanbaoList"
import { OperatorUserType } from "@/utils/enum.js"
import OtherProject from "./otherProject"
import Material from "./material"
import Maintenance from "./maintenance"
import Settlement from "./settlement"
import "../orderDetail.less"

const { Option } = Select
const { TabPane } = Tabs
export default (props) => {
    const { rowInfo, visible, setVisible, onSuccess, mode,id } = props
    useEffect(() => {
        if (visible) {
            isMode()
        }
    }, [visible])

    const [isChange, setIsChange] = useState(false)

    const maintenanceRef = useRef() //维修项目 子组件
    const materialRef = useRef() //维修物料  子组件
    const otherProjectRef = useRef() //其他项目  子组件

    const [settlementVisible, setSettlementVisible] = useState(false)
    const [settlementMode, setSettlementMode] = useState("")

    const [orderForm] = Form.useForm()

    const [customerInfo, setCustomerInfo] = useState({
        //客户列表
        selectValue: {},
        data: [],
        fetching: false,
    })
    const [vehicleInfo, setVehicleInfo] = useState({
        //车辆列表
        data: [],
        fetching: false,
    })
    const [userInfo, setUserInfo] = useState({
        //用户列表
        data: [],
        fetching: false,
    })
    const [teamInfo, setTeamInfo] = useState({
        //团队列表
        data: [],
        fetching: false,
    })
    const [entityData, setEntityData] = useState({
        //所属实体信息
        data: [],
        fetching: false,
    })
    const [imgLookVisible, setImgLookVisible] = useState({
        //图片查看
        visible: false,
        data: [],
        repairMode: "",
    })
    //工单支付和查看
    const [lookVisible, setLookVisible] = useState({
        visible: false,
        taskMode: "",
    })

    const [itemList, setItemList] = useState([])
    const [maintainMaterialList, setMaintainMaterialList] = useState([])
    const [otherItemList, setOtherItemList] = useState([])
    const [returnOrderInfo, setReturnOrderInfo] = useState({})
    const [sanBaoList, setSanBaoList] = useState([])

    useEffect(() => {
        isMode()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const [orderType, setOrderType] = useState("")
    //保存编辑回显的订单详情数据
    const [orderInfoOne, setOrderInfoOne] = useState({})
    const [settlementInfo, setSettlementInfo] = useState({})
    //模式  反现数据
    const isMode = () => {
        if (mode === "edit" || mode === "look") {
            returnOrderOne(rowInfo.id??id).then((res) => {
                let { orderInfo: results, returnItemList, returnMaintainMaterialList, returnOtherItemList, returnOrderInfo, threeGuaranteesList, settlementInfo } = res.retData
                setOtherItemList(returnOtherItemList || [])
                setMaintainMaterialList(returnMaintainMaterialList || [])
                setItemList(returnItemList || [])
                setSanBaoList(threeGuaranteesList || [])
                setOrderInfoOne(results)
                setSettlementInfo(settlementInfo ?? {})
                setOrderType(results.orderType)
                setReturnOrderInfo(returnOrderInfo)
                setIsChange(false)
                lookMaintainer(rowInfo.orderId).then((res) => {
                    let str = "",
                        mainWeiXiuRenYuan = "" //要展示的维修人员
                    res.retData.forEach((item) => {
                        if (item.majorFlag === 1) {
                            mainWeiXiuRenYuan = item.userName
                        }
                        str += item.userName + ","
                    })
                    orderForm.setFieldsValue({
                        weiXiuRenYuan: str,
                        mainWeiXiuRenYuan,
                    })
                })
                orderForm.setFieldsValue({
                    ...results,
                    createTime: moment(results.createTime),
                    teamEstimatedArrivalTime: results.teamEstimatedArrivalTime ? moment(results.teamEstimatedArrivalTime) : results.teamEstimatedArrivalTime,
                    teamEstimatedFinishTime: results.teamEstimatedFinishTime ? moment(results.teamEstimatedFinishTime) : results.teamEstimatedFinishTime,
                    reservationTime: results.reservationTime ? moment(results.reservationTime) : results.reservationTime,
                    repairTime: results.repairTime ? moment(results.repairTime) : null,
                    customerVehicleCode: results.customerVehicleCode,
                    reviewerTime: results.reviewerTime ? timeShift(results.reviewerTime) : "",
                    billName: results.billName ? results.billName : getUserInfo() ? getUserInfo().nickname : "未获取到", //获取系统业务员
                    billId: results.billId ? results.billId : getUserInfo() ? getUserInfo().id : "", //获取系统业务员ID
                    preferentialAmount: results.orderPreferentialAmount, //优惠金额， 字段写错。 在这改正下。
                })
                setCustomerInfo({
                    selectValue: {
                        customerName: results.customerName,
                        customerId: results.customerId,
                    },
                    data: [
                        {
                            text: results.customerName,
                            value: results.customerId,
                        },
                    ],
                    fetching: false,
                }) //客户列表回显
                setVehicleInfo({
                    data: [
                        {
                            text: results.customerVehicleCode,
                            value: results.customerVehicleId,
                        },
                    ],
                    fetching: false,
                }) //车辆列表回显
                setUserInfo({
                    data: [
                        {
                            text: results.customerUserName,
                            value: results.customerUserId,
                            iphone: results.repairContactPhone,
                        },
                    ],
                    fetching: false,
                }) //用户列表回显
                setTeamInfo({
                    id: results.customerId,
                    data: [{ text: results.teamName, value: results.teamId }],
                    fetching: false,
                }) //团队列表回显

                //所属实体回显
                {
                    const { ciBusinessEntityId: value, ciBusinessEntityName: text } = results
                    value &&
                        text &&
                        setEntityData({
                            data: [
                                {
                                    text,
                                    value,
                                },
                            ],
                            fetching: false,
                        }) //所属实体回显
                }
            })
        } else {
            // orderForm.setFieldsValue({
            //     staffPhone: getUserIdInfo()?.phone || "",
            // })
            //新增带出字典数据
            orderForm.setFieldsValue({
                billName: getUserInfo() ? getUserInfo().nickname : "未获取到", //获取系统业务员
                billId: getUserInfo() ? getUserInfo().id : "", //获取系统业务员ID
                orderSource: "008002", //工单来源
                // orderType: "014003", //工单类型
                // serviceMethod: "012002", //服务方式
                // serviceType: "011001", //服务类型
                customerType: "017001", //一般客户
            })
        }
    }

    //查看车辆故障图片
    const handleLookImg = (str) => {
        let data = {
            pageSize: 100,
            searchItem: {
                orderId: rowInfo.orderId,
                imageGroup: str,
            },
        }
        getOrderImg(data).then((res) => {
            setImgLookVisible({
                visible: true,
                data: res.retData.list,
                repairMode: str,
            })
        })
    }

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    }

    const close = () => {
        setVisible(false)
        onSuccess && onSuccess()
    }

    const handleSava = () => {
        let returnOrderItemList = maintenanceRef.current.upData().map((item) => ({
                id: item.id,
                itemWorkHours: item.workHour,
                workHoursPrice: item.workHoursPrice,
                itemDiscount: item.discount,
                preferentialAmount: item.preferentialAmount,
            })),
            returnOrderMaintainMaterialList = materialRef.current.upData().map((item) => ({
                id: item.id,
                unitPrice: item.unitPrice,
                materialNumber: item.materialNumber,
                materialDiscount: item.materialDiscount,
                preferentialAmount: item.preferentialAmount,
            })),
            returnOrderOtherItemList = otherProjectRef.current
                .upData()
                .map((item) => ({
                    id: item.id,
                    itemNumber: item.itemNumber,
                    unitPrice: item.unitPrice,
                    itemDiscount: item.itemDiscount,
                    preferentialAmount: item.preferentialAmount,
                }))
        returnOrderEdit({ returnOrderItemList, returnOrderMaintainMaterialList, returnOrderOtherItemList, id: returnOrderInfo.id }).then((res) => {
            message.success("保存成功")
            isMode()
        })
    }

    const handleStock = () => {
        if (isChange) {
            return message.error("请先保存")
        }

        Modal.confirm({
            title: "提示",
            content: "确定修改完成进行入库操作?",
            okText: "确定",
            cancelText: "取消",

            onOk: () => {
                returnOrderWarehouse(returnOrderInfo.id).then((res) => {
                    message.success("入库成功")
                    isMode()
                })
            },
            onCancel: () => {},
        })
    }
    return (
        <Modal forceRender visible={visible} title="订单详情" centered={true} footer={null} onCancel={() => close()} width={"100%"}>
            <div
                style={{
                    position: "fixed",
                    zIndex: 999,
                    width: "calc(100% - 80px)",
                    marginTop: "-8px",
                }}
                className="order-box"
            >
                <div className="list-container" style={{}}>
                    <div className="list-header">
                        <div className="list-name">
                            <span style={{ marginRight: "20px" }}>工单详情</span>
                        </div>
                        <div className="list-but">
                            {returnOrderInfo.returnStatus === "billed" && (
                                <>
                                    <Button shape="round" className="but-left" onClick={handleSava}>
                                        保存
                                    </Button>
                                    <Button shape="round" className="but-left" onClick={handleStock}>
                                        确认入库
                                    </Button>
                                </>
                            )}

                            {returnOrderInfo.returnStatus === "in_stock" && (
                                <Button
                                    shape="round"
                                    className="but-left"
                                    onClick={() => {
                                        setSettlementVisible(true)
                                        setSettlementMode()
                                    }}
                                >
                                    确认结算
                                </Button>
                            )}

                            <Button shape="round" className="but-left" onClick={isMode}>
                                刷新
                            </Button>

                            <Button shape="round" className="but-right" onClick={() => handleLookImg("customer")}>
                                故障照片
                            </Button>
                            <Button shape="round" className="but-right" onClick={() => handleLookImg("team")}>
                                现场照片
                            </Button>
                            <Button
                                shape="round"
                                className="but-right"
                                onClick={() =>
                                    setLookVisible({
                                        visible: true,
                                        taskMode: "comment",
                                    })
                                }
                            >
                                客户评价
                            </Button>
                            <Button
                                shape="round"
                                className="but-right"
                                onClick={() =>{
                                    setSettlementVisible(true)
                                        setSettlementMode("look")
                                }
                                }
                            >
                                结算信息
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ maxHeight: "80vh", overflowY: "scroll" }}>
                <Form form={orderForm} {...formLayout}>
                    <div className="list-container" style={{ marginTop: "90px" }}>
                        <div className="form-details-warp" style={{ padding: "20px 20px 0 0", position: "relative" }}>
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="工单信息" key="1">
                                    <Row>
                                        <div style={{ marginBottom: "10px", marginLeft: "20px", color: "#333" }}>
                                            {orderInfoOne.orderCode && (
                                                <span style={{ fontSize: "14px", marginRight: "20px" }}>{`工单编号:${orderInfoOne.orderCode}`}</span>
                                            )}
                                            {orderInfoOne.orderCustomerName && (
                                                <span style={{ fontSize: "14px", marginRight: "20px" }}>{`客户名称:${orderInfoOne.orderCustomerName}`}</span>
                                            )}
                                            {orderInfoOne.customerType && (
                                                <span style={{ fontSize: "14px", marginRight: "20px" }}>{`客户类型:${
                                                    getPcodeDict("017").children.filter((item) => item.dictCode === orderInfoOne.customerType)[0]?.title || "--"
                                                }`}</span>
                                            )}
                                            {orderInfoOne.orderSource && (
                                                <span style={{ fontSize: "14px", marginRight: "20px" }}>{`工单来源:${
                                                    getPcodeDict("008").children.filter((item) => item.dictCode === orderInfoOne.orderSource)[0].title
                                                }`}</span>
                                            )}
                                            {orderInfoOne.customerVehicleCode && (
                                                <span style={{ fontSize: "14px", marginRight: "20px" }}>{`车辆牌照:${orderInfoOne.customerVehicleCode}`}</span>
                                            )}
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col span={7}>
                                            <Form.Item
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                label="客户名称"
                                                name="customerId"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "请选择客户",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    disabled
                                                    showSearch
                                                    placeholder="请选择客户"
                                                    value={customerInfo.selectValue.customerName}
                                                    notFoundContent={customerInfo.fetching ? <Spin size="small" /> : null}
                                                    filterOption={false}
                                                    style={{ width: "100%" }}
                                                >
                                                    {customerInfo.data.map((item) => (
                                                        <Option value={item.value} key={item.value} address={item.address}>
                                                            {item.text}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                label="车辆牌照"
                                                name="customerVehicleId"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "请选择车辆",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    disabled
                                                    showSearch
                                                    placeholder="请选择车辆"
                                                    notFoundContent={vehicleInfo.fetching ? <Spin size="small" /> : null}
                                                    filterOption={false}
                                                    style={{ width: "100%" }}
                                                >
                                                    {vehicleInfo.data.map((item) => (
                                                        <Option value={item.value} key={item.value}>
                                                            {item.text}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                label="报修用户"
                                                name="customerUserId"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "请选择报修用户",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    disabled={mode === "insert" ? false : true}
                                                    showSearch
                                                    placeholder="请选择报修用户"
                                                    filterOption={false}
                                                    style={{ width: "100%" }}
                                                >
                                                    {userInfo.data.map((item) => (
                                                        <Option value={item.value} key={item.value} iphone={item.iphone} name={item.text}>
                                                            {`${item.text}(${item.iphone})`}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                label="服务方式"
                                                name="serviceMethod"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "请选择服务方式",
                                                    },
                                                ]}
                                            >
                                                <Select disabled allowClear placeholder="请选择服务方式" style={{ width: "100%" }}>
                                                    {getPcodeDict("012").children.map((item) => (
                                                        <Option value={item.dictCode} key={item.id}>
                                                            {item.title}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                            <Form.Item
                                                label="故障类型"
                                                name="serviceType"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "请选择故障类型",
                                                    },
                                                ]}
                                            >
                                                <Select disabled allowClear placeholder="请选择故障类型" style={{ width: "100%" }}>
                                                    {getPcodeDict("011").children.map((item) => (
                                                        <Option value={item.dictCode} key={item.id}>
                                                            {item.title}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={7}>
                                            <Form.Item
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                label="报修地址"
                                                name="repairAddress"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "请通过地图来获取地址",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder="通过地图实时位置获取地址"
                                                    disabled
                                                    addonAfter={
                                                        <Button className="map-but" disabled>
                                                            获取位置
                                                        </Button>
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                            <Form.Item name="customerVehicleMileage" label="行驶里程数">
                                                <Input placeholder="请输入里程数" suffix="KM" disabled />
                                            </Form.Item>
                                        </Col>

                                        <Col span={5}>
                                            <Form.Item
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                label="救援里程数"
                                                name="mileage"
                                                rules={[
                                                    {
                                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                                        message: "请输入数字且可保留两位小数",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="请输入里程数" suffix="KM" disabled />
                                            </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                            <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} label="故障内容" name="faultClassification">
                                                <Select disabled placeholder="请选择故障内容" style={{ width: "100%" }}>
                                                    {getPcodeDict("037").children.map((item) => (
                                                        <Option value={item.dictCode} key={item.id}>
                                                            {item.title}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                            <Tooltip
                                                placement="topLeft"
                                                title="温馨提醒：当选中三包时，维修项目和维修物料的单价,工时，数量，金额都将置0，这可能导致您在次选择工单类型(收费或三包/收费)时,所有的价格，数量会恢复到初始值。"
                                                arrowPointAtCenter
                                            >
                                                <Form.Item
                                                    label="工单类型"
                                                    name="orderType"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "请选择工单类型",
                                                        },
                                                    ]}
                                                >
                                                    <Select disabled placeholder="请选择工单类型" style={{ width: "100%" }}>
                                                        {getPcodeDict("014").children.map((item) => (
                                                            <Option value={item.dictCode} key={item.id}>
                                                                {item.title}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Tooltip>
                                        </Col>

                                        <Col span={20}>
                                            <Form.Item labelCol={{ span: 2 }} wrapperCol={{ span: 22 }} label="故障描述" name="faultDesc">
                                                <Input.TextArea allowClear autoSize={true} placeholder="请输入故障描述" disabled />
                                            </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                            <Form.Item label="所属实体" name="ciBusinessEntityId">
                                                <Select
                                                    disabled
                                                    allowClear
                                                    placeholder="请选择所属实体"
                                                    style={{ width: "100%" }}
                                                    optionFilterProp="children"
                                                    filterOption={false}
                                                >
                                                    {entityData.data.map((item) => (
                                                        <Select.Option value={item.value} key={item.value}>
                                                            {item.text}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                    <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} label="入库时间" name="inboundTime">
                                        <DatePicker
                                            disabled
                                            placeholder="自动带出"
                                            className="pub-select"
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                                defaultValue: moment("00:00:00", "HH:mm:ss"),
                                            }}
                                            disabledDate={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                            <Form.Item name="inboundUser" label="入库人">
                                                <Input placeholder="自动带出"  disabled />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane
                                    tab={`${orderInfoOne.customerVehicleCode ? "车辆信息(" + orderInfoOne.customerVehicleCode + ")" : "车辆信息"}`}
                                    key="2"
                                >
                                    <Row>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="车辆品牌" name="customerVehicleBrand">
                                                <Input placeholder="可通过车辆选择带出" disabled={mode === "insert" ? false : true} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} xxl={4}>
                                            <Form.Item label="vin码" name="customerVehicleVin">
                                                <Input placeholder="可通过车辆选择带出" disabled={mode === "insert" ? false : true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="发动机号" name="customerEngineCode">
                                                <Input placeholder="可通过车辆选择带出" disabled={mode === "insert" ? false : true} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} xxl={4}>
                                            <Form.Item label="变速箱号" name="gearboxCode">
                                                <Input placeholder="可通过车辆选择带出" disabled={mode === "insert" ? false : true} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} xxl={4}>
                                            <Form.Item label="车型" name="customerVehicleModel">
                                                <Input placeholder="可通过车辆选择带出" disabled={mode === "insert" ? false : true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="车系" name="customerVehicleSeries">
                                                <Input placeholder="可通过车辆选择带出" disabled={mode === "insert" ? false : true} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} xxl={4}>
                                            <Form.Item label="车桥" name="customerVehicleAxle">
                                                <Input placeholder="可通过车辆选择带出" disabled={mode === "insert" ? false : true} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab={`${orderInfoOne.orderActualAmount ? "结算信息(" + orderInfoOne.orderActualAmount + ")" : "结算信息"}`} key="3">
                                    <Row>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="预付款" name="prepaymentsAmount">
                                                <Input disabled placeholder="预付款" prefix="¥" suffix="元" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="预付款支付状态" name="prepaymentsPayStatus">
                                                <Select disabled style={{ width: "100%" }} placeholder="预付款支付状态" filterOption={false}>
                                                    <Option value="unpaid" key="unpaid">
                                                        未支付
                                                    </Option>
                                                    <Option value="paid" key="paid">
                                                        已支付
                                                    </Option>
                                                    <Option value="refunding" key="refunding">
                                                        退款中
                                                    </Option>
                                                    <Option value="wx_refunding" key="wx_refunding">
                                                        微信退款中
                                                    </Option>
                                                    <Option value="refunded" key="refunded">
                                                        已退款
                                                    </Option>
                                                    <Option value="refund_reject" key="refund_reject">
                                                        退款驳回
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="应收金额" name="orderTotalAmount">
                                                <Input placeholder="0" disabled prefix="¥" suffix="元" />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} xxl={4}>
                                            <Form.Item
                                                label="折扣"
                                                name="orderDiscount"
                                                rules={[
                                                    {
                                                        pattern: /^[1-9]?(\.\d)?$/,
                                                        message: "请输入数字且可保留一位小数",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="请输入折扣" disabled />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} xxl={4}>
                                            <Form.Item
                                                label="优惠金额"
                                                name="preferentialAmount"
                                                rules={[
                                                    {
                                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                                        message: "请输入数字且可保留二位位小数",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="0" prefix="¥" suffix="元" disabled />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} xxl={4}>
                                            <Form.Item label="实收金额" name="orderActualAmount">
                                                <Input placeholder="自动计算" disabled prefix="¥" suffix="元" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="支出金额描述" name="orderPayoutDesc">
                                                <Input placeholder="支出金额描述" disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item
                                                label="支出金额"
                                                name="orderPayoutAmount"
                                                rules={[
                                                    {
                                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                                        message: "请输入数字且可保留二位位小数",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="支出金额" prefix="¥" suffix="元" disabled />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab={`${orderInfoOne.teamName ? "团队信息(" + orderInfoOne.teamName + ")" : "团队信息"}`} key="4">
                                    <Row>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="团队名称" name="teamId">
                                                <Select disabled style={{ width: "100%" }} placeholder="请选择团队" filterOption={false}>
                                                    {teamInfo.data.map((item) => (
                                                        <Option value={item.value} key={item.value}>
                                                            {item.text}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>{" "}
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="服务车辆" name="teamVehicleCode">
                                                <Input placeholder="工单派工后可展示服务车辆" disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="主维修人" name="mainWeiXiuRenYuan">
                                                <Input placeholder="工单派工后可展示维修人员" disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="维修人员" name="weiXiuRenYuan">
                                                <Input placeholder="工单派工后可展示维修人员" disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="联系电话" name="teamMonitorPhone">
                                                <Input placeholder="工单派工后可展示联系电话" disabled />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </Tabs>
                            <Row>
                                <Form.Item hidden name="customerVehicleCode">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="repairContactName">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="repairContactPhone">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="id">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="billId">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="repairLng">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="repairLat">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="ciBusinessEntityName">
                                    <Input />
                                </Form.Item>
                            </Row>
                        </div>
                    </div>
                    <div className="list-container">
                        <div className="form-details-warp" style={{ padding: "10px 20px 0 0px" }}>
                            <Row>
                                <Col span={5}>
                                    <Form.Item labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} label="预约时间" name="reservationTime">
                                        <DatePicker
                                            disabled
                                            placeholder="自动生成"
                                            className="pub-select"
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                                defaultValue: moment("00:00:00", "HH:mm:ss"),
                                            }}
                                            disabledDate={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} label="预计到达时间" name="teamEstimatedArrivalTime">
                                        <DatePicker
                                            disabled
                                            placeholder="自动生成"
                                            className="pub-select"
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                                defaultValue: moment("00:00:00", "HH:mm:ss"),
                                            }}
                                            disabledDate={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} label="预计完工时间" name="teamEstimatedFinishTime">
                                        <DatePicker
                                            disabled
                                            placeholder="自动生成"
                                            className="pub-select"
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                                defaultValue: moment("00:00:00", "HH:mm:ss"),
                                            }}
                                            disabledDate={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} label="备注" name="remark">
                                        <Input.TextArea allowClear autoSize={true} placeholder="请输入备注" disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={10} hidden={!orderInfoOne.processResult}>
                                    <Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} label="处理结果" name="processResult">
                                        <Input.TextArea allowClear autoSize={true} placeholder="请输入处理结果" disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={10} hidden={!orderInfoOne.orderCancelReason}>
                                    {orderInfoOne.orderCancelReason && orderInfoOne.orderCancelReason.indexOf("041") > -1 && (
                                        <Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} label="作废原因" name="orderCancelReason">
                                            <Select disabled allowClear placeholder="请选择服务方式" style={{ width: "100%" }}>
                                                {getPcodeDict("041").children.map((item) => (
                                                    <Option value={item.dictCode} key={item.id}>
                                                        {item.title}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    )}

                                    {orderInfoOne.orderCancelReason && orderInfoOne.orderCancelReason.indexOf("041") === -1 && (
                                        <Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} label="作废原因" name="orderCancelReason">
                                            <Input.TextArea allowClear autoSize={true} placeholder="请输入作废原因" disabled />
                                        </Form.Item>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Form>
                {orderType !== "014001" && <SanBaoList sanBaoList={sanBaoList} returnOrderInfo={returnOrderInfo}></SanBaoList>}
                {orderType !== "014002" && (
                    <>
                        <Maintenance
                            returnMaintainMaterialList={itemList}
                            maintenanceRef={maintenanceRef}
                            setIsChange={setIsChange}
                            returnOrderInfo={returnOrderInfo}
                        />
                        <Material
                            returnMaintainMaterialList={maintainMaterialList}
                            materialRef={materialRef}
                            setIsChange={setIsChange}
                            returnOrderInfo={returnOrderInfo}
                        />
                        <OtherProject
                            returnOtherItemList={otherItemList}
                            otherProjectRef={otherProjectRef}
                            setIsChange={setIsChange}
                            returnOrderInfo={returnOrderInfo}
                        />
                    </>
                )}

                <div style={{ display: orderType !== "014002" ? "block" : "none" }}></div>
                <div className="list-container">
                    <div className="form-details-warp bottom-info" style={{ padding: "10px 20px" }}>
                        <span>开单时间：{orderInfoOne.createTime || "暂无信息"}</span>
                        <span>开单员：{orderInfoOne.billName || "暂无信息"}</span>
                        <span>创建人：{`${OperatorUserType[orderInfoOne.createUserType]}--${orderInfoOne.createUserName}` || "暂无信息"}</span>
                        <span>审核人：{orderInfoOne.reviewerName || "暂无信息"}</span>
                        <span>审核时间：{orderInfoOne.reviewerTime ? moment(orderInfoOne.reviewerTime).format("YYYY-MM-DD HH:mm:ss") : "暂无信息"}</span>
                        <span>经营实体审核人：{orderInfoOne.ciBusinessEntityReviewerName || "暂无信息"}</span>
                        {orderInfoOne.repairTime && (
                            <span>维修时间：{orderInfoOne.repairTime ? moment(orderInfoOne.repairTime).format("YYYY-MM-DD HH:mm:ss") : "暂无信息"}</span>
                        )}
                        {orderInfoOne.orderStatus === "cancelled" && (
                            <>
                                <span>取消人：{orderInfoOne.orderCancelUserName || "暂无信息"}</span>
                                <span>取消时间：{orderInfoOne.orderCancelTime || "暂无信息"}</span>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <Modal
                width={700}
                bodyStyle={{ height: 660, overflowY: "auto" }}
                onCancel={() =>
                    setImgLookVisible({
                        visible: false,
                        data: [],
                        repairMode: "",
                    })
                }
                visible={imgLookVisible.visible}
                title={imgLookVisible.repairMode === "repair_img" ? "故障图片查看" : "维修团队现场图片查看"}
                footer={null}
            >
                {imgLookVisible.data.length > 0 ? (
                    <Image.PreviewGroup>
                        {imgLookVisible.data.map((item) => {
                            return (
                                <div style={{ display: "inline-block" }}>
                                    <div>{item.imageName}</div>
                                    <div className="repair-img-box" key={item.id}>
                                        <Image src={`${fileLookUrl().image}${item.attachmentMd5}`} />
                                    </div>
                                </div>
                            )
                        })}
                    </Image.PreviewGroup>
                ) : (
                    <>暂无照片</>
                )}
            </Modal>
            <LookCommentAndPayment orderInfo={orderInfoOne} lookVisible={lookVisible} onLookClose={() => setLookVisible({ visible: false, taskMode: "" })} />
            <Settlement
                mode={settlementMode}
                visible={settlementVisible}
                setVisible={setSettlementVisible}
                orderInfoOne={orderInfoOne}
                settlementInfo={settlementInfo}
                returnOrderInfo={returnOrderInfo}
                onSuccess={() => {
                    setSettlementVisible(false)
                    setVisible(false)
                    onSuccess && onSuccess()
                }}
            />
        </Modal>
    )
}
