import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

export const SearchDatepicker = (props) => {
    return <DatePicker
        size={props.size}
        className="yh-search-input"
        placeholder={props.placeholder}
        format={props.format || "YYYY-MM-DD"}
        value={props?.searchValues?.[props.fieldName] ? moment(props?.searchValues?.[props.fieldName]) : undefined}
        showTime={props.showTime}
        onChange={(value, timeStr) => {
            props.onChange && props.onChange({ [props.fieldName]: timeStr });
            if (props.triggerSearch) {
                props.handleSearch && props.handleSearch({ [props.fieldName]: timeStr });
            }
        }}
        disabled={props.disabled}
        showNow={false}
    />
}