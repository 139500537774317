import React, { useContext } from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { ShiftShelfModal } from "./ShiftShelfModal";
import { TranRecord } from "./TranRecord";
import { MyContext } from "@/store/myContext";
import { SectionShelfModal } from "./SectionShelfModal";

export default () => {
    const { state } = useContext(MyContext);

    return (
        <>
            {state.activeMenu === "stockDetailed" && <>
                <PageHeader />
                <DataBox />
                <ShiftShelfModal />
                <SectionShelfModal />
                <TranRecord />
            </>}
        </>
    )
}