import React, { useState, useEffect, useRef } from 'react'
import { Button, Modal } from 'antd'
import { allAreas, geoJson } from '@/service/serviceAreaMap'
import { Select } from 'antd'

const ChooseAreaMap = (props) => {
    const TMap = window.TMap
    const mapRef = useRef({}) // 地图
    const editorRef = useRef({}) // 编辑器
    const multiPolygonRef = useRef({}) //行政区域绘制的多边形
    const editorMultiPolygonRef = useRef({}) //行政区域绘制的多边形

    const [data, setData] = useState([])
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (props.visible) {
            let path = props.path.map(i => new TMap.LatLng(i.lat, i.lng))
            setData(path)
            initMap()
            if(props.path &&props.path.length > 0){
                editEditor(path)
                mapRef.current.setCenter(path[0])
            }
        }
    }, [props.visible])

    const initMap = () => {
        // 初始化地图
        mapRef.current = new TMap.Map('container', {
            zoom: 8, // 设置地图缩放级别
            center: new TMap.LatLng(39.984104, 116.307503), // 设置地图中心点坐标
        })

        // 初始化几何图形及编辑器
        editorRef.current = new TMap.tools.GeometryEditor({
            // TMap.tools.GeometryEditor 文档地址：https://lbs.qq.com/webApi/javascriptGL/glDoc/glDocEditor
            map: mapRef.current, // 编辑器绑定的地图对象
            actionMode: TMap.tools.constants.EDITOR_ACTION.DRAW, // 编辑器的工作模式
            snappable: true, // 开启吸附
        })
        // 监听绘制结束事件，获取绘制几何图形
        editorRef.current.on('draw_complete', (geometry) => {
            var id = geometry.id
            if (editorRef.current.getActiveOverlay().id === 'polygon') {
                // 获取多边形顶点坐标
                var geo = editorMultiPolygonRef.current.geometries.filter(function (item) {
                    return item.id === id
                })
                setData(geo[0].paths)
                editEditor(geo[0].paths)
            }
        })


        
    }

    const onSubmit = () => {
        props.onSubmit(data)
    }

    const draowPolygon = () => {
        createEditor()
    }

    const onFocus = () => {
        if (options.length > 0) {
            return
        }
        allAreas()
            .then((res) => {
                console.log(res)
                let tem = res.retData.map((i) => ({
                    label: `${i.name} ${i.adcode}`,
                    value: i.adcode,
                }))
                setOptions(tem)
            })
            .catch((err) => console.log(err))
    }
    const onChangeAdcode = (adcode) => {
        geoJson(adcode)
            .then((res) => {
                let arr = res.retData.features[0].geometry.coordinates[0][0].map((i, indx) => {
                    return new TMap.LatLng(i[1], i[0])
                })
                // 如果已有多边形，重新绘制
                removeMultiPolygon()
                removeEditor()
                multiPolygonRef.current = new TMap.MultiPolygon({
                    map: mapRef.current,
                    geometries: [
                        {
                            paths: arr,
                        },
                    ],
                })
                const center = res.retData.features[0].properties.center
                mapRef.current.setCenter(new TMap.LatLng(center[1], center[0]))
                setData(arr)
            })
            .catch((err) => console.log(err))
    }

    // 如果地图有多边形，移除
    const removeMultiPolygon = () => {
        multiPolygonRef.current.destroy && multiPolygonRef.current.destroy()
    }

    // 如果地图有editor，移除
    const removeEditor = () => {
        // editorRef.current.destroy && editorRef.current.destroy()
        const overlayList = editorRef.current.getOverlayList()
        overlayList.forEach((i) => {
            editorRef.current.removeOverlay(i.id)
        })
        editorMultiPolygonRef.current.destroy && editorMultiPolygonRef.current.destroy()
    }

    //重新绘制多边形
    const createEditor = () => {
        removeMultiPolygon()
        removeEditor()
        editorRef.current.setActionMode(TMap.tools.constants.EDITOR_ACTION.DRAW)
        editorMultiPolygonRef.current = new TMap.MultiPolygon({
            map: mapRef.current,
        })
        editorRef.current.addOverlay({
            overlay: editorMultiPolygonRef.current,
            id: 'polygon',
        })
    }

    //编辑绘制多边形
    const editEditor = (paths) => {
        removeMultiPolygon()
        removeEditor()
        editorMultiPolygonRef.current = new TMap.MultiPolygon({
            map: mapRef.current,
            styles: {
                highlight: new TMap.PolygonStyle({
                    color: 'rgba(255, 255, 0, 0.6)'
                })
            },
            geometries: [
                {
                    paths: paths
                }
            ]
        })
        editorRef.current.addOverlay({
            overlay:editorMultiPolygonRef.current,
            id: 'polygon',
            selectedStyleId: 'highlight'
        })
        editorRef.current.setActiveOverlay('polygon')
        editorRef.current.setActionMode(TMap.tools.constants.EDITOR_ACTION.INTERACT)
        editorRef.current.setSnappable(true)
        editorRef.current.setSelectable(true)

        editorRef.current.on('adjust_complete', (geometry) => {
            setData(geometry.paths)
            editEditor(geometry.paths)
        })
    }

    return (
        <div>
            {!props.visible ? null : (
                <Modal
                    width={1000}
                    style={{ top: 0, padding: '10px' }}
                    visible={props.visible}
                    title="可圈选范围"
                    cancelText={'null'}
                    onCancel={onSubmit}
                    // onCancel={() => setmapVisible(false)}
                    footer={[
                        <Button key="submit" onClick={onSubmit}>
                            确定
                        </Button>,
                    ]}
                >
                    <div style={{ width: '100%', marginTop: '6px' }}>
                        <div style={{ width: '100%', margin: '6px' }}>
                            <Button type="primary" onClick={draowPolygon} style={{ marginRight: 10 }}>
                                多边形区域选择
                            </Button>
                            行政区域选择
                            <Select
                                showSearch
                                onFocus={onFocus}
                                style={{ width: 200, marginLeft: 10 }}
                                placeholder="输入行政名称"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                options={options}
                                onChange={onChangeAdcode}
                            />
                        </div>

                        <div id="container" style={{width:"100%", height:'700px'}}></div>
                    </div>
                </Modal>
            )}
        </div>
    )
}
export default ChooseAreaMap
