import React, { useState, useCallback, useRef, useEffect } from "react"
import { Table, Button, message, Modal } from "antd"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import update from "immutability-helper"
import { getUserCustomizeConfigOne, saveUserCustomizeConfig } from "@/service/settings"
import styled from "@emotion/styled"
const type = "DraggableBodyRow"

//项目
/**
 * saveKey 保存的key
 * allData 对应tabl所有的columns
 */
export default ({ visible, setShowTableSort, saveKey, allData, handleChangeColumns }) => {
    const [data, setData] = useState([])
    const [select, setSelect] = useState([])
    const [isInit, setIsInit] = useState(false)
    const [itemRowSelection, setItemRowSelection] = useState({})

    useEffect(() => {
        if (visible) {
            init()
        } else {
            setData([])
            setSelect([])
            setIsInit(false)
        }
    }, [visible])

    const init = () => {
        getUserCustomizeConfigOne(saveKey).then((res) => {
            let newSelect = []
            if (res.retData) {
                let saveData = JSON.parse(res.retData.val),
                    allList = JSON.parse(JSON.stringify(allData)),
                    newList = [],addList = []
                saveData.forEach((save) => {
                    if(allList.findIndex((item) => item.key === save.key)> -1){
                        newList.push(save)
                        if (save.isShow) {
                            newSelect.push(save.key)
                        }
                    }
                })
                allList.forEach(item => {
                    if(saveData.findIndex((save) => item.key === save.key) === -1){
                        addList.push(item)
                        
                    }
                })
                if (addList.length > 0) {
                    addList.forEach((item) => {
                        newList.push({ ...item, isShow: false })
                    })
                }
                setData(newList)
                setSelect(newSelect)
            } else {
                setData(allData)
                newSelect = allData.map((item) => item.key)
                setSelect(newSelect)
            }
            setItemRowSelection({
                onChange: (selectedRowKeys) => {
                    setSelect(selectedRowKeys)
                },
                defaultSelectedRowKeys: newSelect,
            })
            setIsInit(true)
        })
    }

    const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
        const ref = useRef()
        const [{ isOver, dropClassName }, drop] = useDrop({
            accept: type,
            collect: (monitor) => {
                const { index: dragIndex } = monitor.getItem() || {}
                if (dragIndex === index) {
                    return {}
                }
                return {
                    isOver: monitor.isOver(),
                    dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
                }
            },
            drop: (item) => {
                moveRow(item.index, index)
            },
        })
        const [, drag] = useDrag({
            type,
            item: { index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        })
        drop(drag(ref))

        return <tr ref={ref} className={`${className}${isOver ? dropClassName : ""}`} style={{ cursor: "move", ...style }} {...restProps} />
    }

    const columns = [
        {
            title: "table名字",
            dataIndex: "title",
        },
        {
            title: "table-key",
            dataIndex: "key",
        },
    ]

    const components = {
        body: {
            row: DraggableBodyRow,
        },
    }

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = data[dragIndex]
            setData(
                update(data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                })
            )
        },
        [data]
    )

    const handleSave = () => {
        let saveData = data.map((item) => {
            return {
                ...item,
                isShow: select.filter((selectItem) => item.key === selectItem).length > 0,
            }
        })
        if (saveData.filter((item) => item.isShow).length < 1) {
            return message.error("至少要选择一项！")
        }
        saveUserCustomizeConfig({ key: saveKey, val: JSON.stringify(saveData) }).then((res) => {
            message.success("保存成功")
            handleChangeColumns && handleChangeColumns(saveData)
            setShowTableSort(false)
        })
    }

    const onCancel = () => {
        setShowTableSort(false)
    }

    return (
        <Modal
            visible={visible}
            title="table排序"
            width={1260}
            onCancel={onCancel}
            footer={[
                <Button onClick={onCancel} key="back">
                    {" "}
                    取消
                </Button>,
                <Button type="primary" onClick={handleSave} key="submit">
                    保存
                </Button>,
            ]}
        >
            {isInit && (
                <DndProvider backend={HTML5Backend}>
                    <Contentdiv>
                        <Table
                            rowSelection={itemRowSelection}
                            columns={columns}
                            dataSource={data}
                            components={components}
                            onRow={(record, index) => ({
                                index,
                                moveRow,
                            })}
                            pagination={false}
                            scroll={{
                                y: "60vh",
                            }}
                        />
                    </Contentdiv>
                </DndProvider>
            )}
        </Modal>
    )
}

const Contentdiv = styled.div`
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
        padding: 5px
    }
`
