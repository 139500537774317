import React, { useEffect, useState, useRef } from "react"
import { Form, Input, Col, Row, Select, Spin, message, Modal, Radio, DatePicker } from "antd"
import { maintenanceTemplateOne, maintenanceTemplateListInsert, maintenanceTemplateListUpdate } from "@/service/dataService"
import Maintenance from "./maintenance" //维修项目
import Material from "./material" //物料
import { ItemAttr } from "@/utils/enum"
const { Option } = Select

export default (props) => {
    const { mode, rowInfo, visible, setVisible, onSuccess, from, rawData } = props
    const [form] = Form.useForm()

    const maintenanceRef = useRef() //维修项目 子组件
    const materialRef = useRef() //维修物料  子组件

    const [data, setData] = useState({})

    useEffect(() => {
        queryMotorcade()
    }, [])

    useEffect(() => {
        if (visible) {
            if (rawData) {
                setData(rawData)
            }
        } else {
            setData([])
        }
    }, [visible])

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            maintenanceTemplateOne(rowInfo.id).then((res) => {
                let results = res.retData
                form.setFieldsValue({
                    name: results.name,
                    brand: results.brand,
                    attr: results.attr,
                })
                setData(results)
            })
        }

        //通过订单详情添加
        if (from & (from === "orderDetail")) {
            setData(rawData)
        }
    }

    //表单提交
    const onUserSubmit = async () => {
        let materialData = await materialRef.current.upMaterialData()
        let maintenanceData = await maintenanceRef.current.upMaintenancelData()
        form.validateFields().then((values) => {
            let data = {
                ...values,
                parts: materialData,
                items: maintenanceData,
            }
            if (mode === "edit") {
                data.id = rowInfo.id
                maintenanceTemplateListUpdate(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                maintenanceTemplateListInsert(data).then((res) => {
                    //查看要关闭的是否被关闭
                    message.success("新增成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }

    const handleCancel = () => {
        setVisible("")
    }

    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }

    return (
        <Modal forceRender visible={visible} title="维修模版" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={1800}>
            <Form form={form}>
                <Row>
                    <Col span={6}>
                        <Form.Item
                            name="name"
                            label="模版名字"
                            rules={[
                                {
                                    required: true,
                                    message: "请输入模版名字",
                                },
                            ]}
                        >
                            <Input placeholder="请输入模版名字" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="属性" name="attr">
                            <Select disabled={isLook(mode)} allowClear placeholder="请选择属性" style={{ width: "100%" }}>
                                {Object.entries(ItemAttr).map((item) => (
                                    <Select.Option value={item[0]} key={item[0]}>
                                        {item[1]}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="brand" label="品牌">
                            <Input placeholder="品牌" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className="form-details-warp">
                <Maintenance maintenanceRef={maintenanceRef} mode={mode} initData={data} />
                <Material materialRef={materialRef} mode={mode} initData={data} />
            </div>
        </Modal>
    )
}
