import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { MyContext } from "@/store/myContext"
import { wvSettingTree, wvVehiclePage, wvVehicleUpdate } from "@/service/allCarService"
import { Radio, message, Tooltip } from "antd"
import { getPcodeDict } from "@/utils/dataStorage"
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

import AllCarInsert from "./allCarInsert"
import AllCarEdit from "./allCarEdit"
//访问列表
export default () => {
    const { dispatch, Option, state } = useContext(MyContext)
    const [tree, setTree] = useState([])
    const [series, setSeries] = useState([])
    const [model, setModel] = useState([])
    const [searchValue, setSearchValue] = useState({})
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]) //默认展示数据
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标

    const [emissionStandardMap, setEmissionStandardMap] = useState({})
    useEffect(() => {
        let obj = {}
        getPcodeDict("032").children.forEach((item) => {
            obj[item.dictCode] = item.title
        })
        setEmissionStandardMap(obj)
        wvSettingTree({}).then((res) => {
            console.log(res)
            setTree(res.retData)
        })
    }, [])
    useEffect(() => {
        loadData(pagination.current, searchValue)
    }, [])
    //     useEffect(()=>{
    // console.log(searchValue)
    //     },[searchValue])
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入商品名称",
                valueName: "vehicleName",
            },
            {
                type: "select",
                placeholder: "请选择品牌",
                valueName: "vehicleBrand",
                selectChange: (item, val) => {
                    console.log(val)
                    const index = tree.findIndex((item) => item.id === val)
                    setSeries(tree[index].subItem || [])
                    setSearchValue({
                        ...searchValue,
                        series: null,
                        model: null,
                        vehicleBrand: val,
                    })
                },
                optionList: () => (
                    <>
                        {/* <Option value='' key='000000'>全部</Option> */}
                        {tree &&
                            tree.map((item) => (
                                <Option value={item.id} key={item.id}>
                                    {item.settingName}
                                </Option>
                            ))}
                    </>
                ),
            },
            {
                type: "select",
                placeholder: "请选择车系",
                valueName: "vehicleSeries",
                selectChange: (item, val) => {
                    console.log(val)
                    const index = series ? series.findIndex((item) => item.id === val) : "null"
                    setModel(index === "null" ? [] : series[index].subItem)
                },
                optionList: () => {
                    return (
                        <>
                            {/* <Option value='' key='000000'>全部</Option> */}

                            {series.map((item) => {
                                return (
                                    <Option value={item.id} key={item.id}>
                                        {item.settingName}
                                    </Option>
                                )
                            })}
                        </>
                    )
                },
            },
            {
                type: "select",
                placeholder: "请选择车型",
                valueName: "vehicleModel",
                selectChange: (item, val) => {
                    console.log(item, val)
                },
                optionList: () => {
                    return (
                        <>
                            {model &&
                                model.map((item) => {
                                    return (
                                        <Option value={item.id} key={item.id}>
                                            {item.settingName}
                                        </Option>
                                    )
                                })}
                        </>
                    )
                },
            },
            {
                type: "select",
                placeholder: "请选择车辆类型",
                valueName: "vehicleType",
                optionList: () =>
                    getPcodeDict("029").children.map((item) => (
                        <Option key={item.id} value={item.dictCode}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: "select",
                placeholder: "请选择燃油类型",
                valueName: "fuelType",
                optionList: () =>
                    getPcodeDict("031").children.map((item) => (
                        <Option key={item.id} value={item.dictCode}>
                            {item.title}
                        </Option>
                    )),
            },
        ],
    }
    const loadData = (current, search = {}) => {
        console.log("loadData")
        setLoading(true)
        let data = {
            searchItem: { ...search, orderFlag: 0 },
            pageNum: current,
            pageSize: 10,
            orders: [{ column: "createTime", rule: "DESC" }],
        }
        wvVehiclePage(data).then((res) => {
            console.log(res)
            // if (res && res.retCode === 200) {
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setData(res.retData.list)
                setLoading(false)
            // }
        })
    }
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }
    const handleDetails = async (mode) => {
        console.log(selectInfo)
        let tabName = {
            insert: "整车新增",
            edit: "整车编辑",
        }
        let modeKey = "allCarManage/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择！")
                return
            } else {
                //每次查看把之前的删除， 重新生成
                await dispatch({
                    type: "removeTabs",
                    targetKey: modeKey,
                })
                await dispatch({
                    type: "changeTabs",
                    name: tabName[mode],
                    activeMenu: modeKey,
                    content: <AllCarEdit rowInfo={selectInfo.rowInfo} dispatch={dispatch} />,
                })
            }
        } else if (mode === "insert") {
            await dispatch({
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: <AllCarInsert dispatch={dispatch} />,
            })
        }
    }
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        // {
        //     click: () => handleDetails('import'),
        //     content: "批量导入"
        // }
    ]
    const columns = [
        {
            title: "操作",
            algin: "center",
            dataIndex: "vehicleStatus",
            render: (text, record) => {
                // vehicleStatus
                return (
                    <Radio.Group
                        defaultValue={text}
                        onChange={(e) => {
                            console.log(text)
                            console.log(record)
                            console.log(e.target.value)
                            wvVehicleUpdate({
                                id: record.id,
                                vehicleName: record.vehicleName,
                                vehicleStatus: e.target.value,
                            }).then((res) => {
                                // if (res && res.retCode === 200) {
                                    message.success("操作成功")
                                // } else {
                                //     message.error(res.retMsg)
                                // }
                                loadData(pagination.current, searchValue)
                            })
                        }}
                        buttonStyle="solid"
                        size="small"
                    >
                        <Radio.Button value={1}>上架</Radio.Button>
                        <Radio.Button value={2}>下架</Radio.Button>
                    </Radio.Group>
                )
            },
        },
        {
            title: "商品名称",
            width:180,
            algin: "center",
            dataIndex: "vehicleName",
            ellipsis: {
                showTitle: false,
            },
            // render: (vehicleName) => (
            //     <Tooltip placement="topLeft" title={vehicleName}>
            //         {vehicleName}
            //     </Tooltip>
            // ),
            render: (vehicleName) => <YhTooltip text={vehicleName} />,
        },

        {
            title: "品牌",
            algin: "center",
            dataIndex: "vehicleBrand",
        },
        {
            title: "产地",
            algin: "center",
            dataIndex: "vehicleProductionAddress",
        },
        {
            title: "车系",
            algin: "center",
            dataIndex: "vehicleSeries",
        },
        {
            title: "车型",
            algin: "center",
            dataIndex: "vehicleType", ///////////
        },
        {
            title: "燃油类型",
            algin: "center",
            dataIndex: "fuelType",
        },
        {
            title: "车辆类型",
            algin: "center",
            dataIndex: "vehicleType",
        },
        {
            title: "排放标准",
            algin: "center",
            dataIndex: "emissionStandard",
            render: (text, record) => {
                return emissionStandardMap[text]
            },
        },
        {
            title: "驱动形式",
            algin: "center",
            dataIndex: "driveForm",
        },
        {
            title: "发动机型号",
            algin: "center",
            dataIndex: "engineConfig",
        },
        {
            title: "最大马力",
            algin: "center",
            dataIndex: "maxHorsepower",
        },
        {
            title: "指导价",
            algin: "center",
            dataIndex: "guidePrice",
        },
        {
            title: "创建人",
            algin: "center",
            dataIndex: "createUser",
        },
        {
            title: "创建时间",
            algin: "center",
            dataIndex: "createTime",
        },
    ]
    return (
        <>
            <TopSearch
                title="整车管理"
                justify="start"
                searchListData={searchList}
                searchValue={searchValue}
                setSearchValue={(obj) => setSearchValue(obj)}
                handleSearch={
                    // ()=>{
                    //     console.log(searchValue)
                    // }

                    loadData
                }
            />
            <ListComponent
                title="整车列表"
                buttons={topButtons}
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={pagination}
                onTableChange={onTableChange}
                selectInfo={selectInfo}
                setSelectInfo={(obj) => setSelectInfo(obj)}
            />
        </>
    )
}
