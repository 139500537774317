import React, { useEffect } from "react";
import { Table, Tooltip, Switch, Space } from "antd";
import { useSupplierModel } from "../supplierModel";
import { momentFormat } from "@/erp_subpackage/utils/util"
import YhTooltip from '@/components/YhTooltip'
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, loading, searchParam,
        loadData, setSelectedRowKeys, onDisabled
    } = useSupplierModel();
    const { getTreeNodeName } = useDictModel(() => []);
    useEffect(() => {
        loadData()
    }, [])

    const columns = [
        {
            title: '序号',
            width: 80,
            align: "center",
            dataIndex: 'branch',
            fixed: "left",
            render: (text, record, index) => index + 1
        }, {
            title: '供应商编码',
            width: 160,
            dataIndex: "code",
            fixed: "left",
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        }, {
            title: '供应商名称',
            width: 260,
            dataIndex: 'name',
            fixed: "left",
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        }, {
            title: '供应商类型',
            width: 160,
            dataIndex: 'categoryCode',
            render:  (text) => <YhTooltip maxWidth={300} text={getTreeNodeName(text)}/>,
        }, {
            title: '联系人',
            width: 80,
            dataIndex: 'contact',
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        }, {
            title: '职务',
            width: 80,
            dataIndex: 'position',
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        }, {
            title: '联系电话',
            width: 160,
            dataIndex: 'phone',
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        }, {
            title: "创建人",
            width: "120",
            dataIndex: "createUser",
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        }, {
            title: '创建时间',
            width: 260,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
        }, {
            title: "操作",
            align: "120",
            dataIndex: 'enable',
            render: (text, record) => (
                <Space size="middle">
                    <Switch checked={text === 1} disabled={searchParam.shopId} onChange={(checked) => {
                        onDisabled(record.code, checked);
                    }} />
                </Space>
            ),
        },
    ];

    //点击行选高亮
    const onRow = (id) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            loading={{ tip: "列表加载中...", spinning: loading }}
            rowSelection={{
                //全部选中时  (存string[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    let changeRowId = changeRows.map(item => item.id);
                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                        : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
                },
                onSelect: (record) => onRow(record.id),
                selectedRowKeys: selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id]);
                }
            })}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}
