import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";

// export interface IPurchasePlanFields {
//     bentityCode?: string,
//     bentityName?: string,
//     contact?: string,
//     deliveryMethod?: string,
//     detailedAddress?: string,
//     id?: string,
//     isDirect?: string,
//     materialTypeCode?: string,
//     materialTypeName?: string,
//     note?: string,
//     orderCode?: string,
//     phone?: string,
//     planCode?: string,
//     purchaseTypeCode?: string,
//     purchaseTypeName?: string,
//     receivingUnit?: string,
//     shopId?: string,
//     shopName?: string,
//     supplierCode?: string,
//     supplierName?: string,
//     supplierTypeCode?: string,
//     supplierTypeName?: string,
//     totalAmount?: string,
//     totalNum?: string,
//     updateUser?: string,
//     status?: string,
//     details?: IDetailList[] | null
// }

// export interface IDetailList {
//     amount?: number,    // 0,
//     id?: string,    // "",
//     materialCode?: string,    // "",
//     materialName?: string,    // "",
//     minNum?: number,    // 0,
//     note?: string,    // "",
//     num?: number,    // 0,
//     planCode?: string,    // "",
//     price?: number,    // 0,
//     shelfCode?: string,    // "",
//     shelfId?: string,    // "",
//     arrivedNum?: number,    // 0,
//     arrivedTotal?: number,    // 0,
//     unarrivedNum?: number,    // 0,
//     warehouseCode?: string,    // "",
//     warehouseName?: string,    // ""
//     planNum?:number,
//     [key: string]: any
// }

class PurchasePlanService {
    async page(searchVo) {
        const apiUrl = "/ci-storage/purchase/plan/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async total(searchVo) {
        const apiUrl = "/ci-storage/purchase/plan/total";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async one(id) {
        const apiUrl = `/ci-storage/purchase/plan/findById/${id}`;
        return await httpRequest.get(apiUrl);
    }

    async insert(insertVo) {
        const apiUrl = "/ci-storage/purchase/plan/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async update(updateVo) {
        const apiUrl = "/ci-storage/purchase/plan/update";
        return await httpRequest.post(apiUrl, updateVo);
    }
    //明细删除
    async materialDelete(params) {
        const apiUrl = "/ci-storage/purchase/plan/materialDelete";
        return await httpRequest.post(apiUrl, params);
    }
    //导出
    async export(params) {
        // const apiUrl = "/ci-analyze//purchase/plan/export";
        // return await httpRequest.postFile(apiUrl, params);
        return await httpRequest.post(`/ci-analyze//purchase/plan/export`,params)
    }
    //转采购单
    async transfer(planCode) {
        const apiUrl = `/ci-storage/purchase/plan/transfer/${planCode}`;
        return await httpRequest.get(apiUrl);
    }
    // //详情导入
    // async import(){
    //     const apiUrl = "/ci-storage/purchase/plan/detail/downloadTpl";
    //     return await httpRequest.getFile<boolean>(apiUrl);
    // }
    //下载模板
    async downloadTpl() {
        const apiUrl = "/ci-storage/purchase/detail/downloadTpl";
        // const apiUrl = "/ci-storage/purchase/plan/detail/downloadTpl";
        return await httpRequest.getFile(apiUrl);
    }
    //打印次数
    async printNum(id) {
        const apiUrl = `/ci-storage/purchase/plan/print/${id}`;
        return await httpRequest.get(apiUrl);
    }
}

export const purchasePlanService = new PurchasePlanService();