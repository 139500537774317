import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";
class VehicleService {
    async page(searchVo) {
        const apiUrl = "/ci-storage/base/vehicle/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async one(id) {
        const apiUrl = `/ci-storage/base/vehicle/findById/${id}`;
        return await httpRequest.get(apiUrl);
    }
    async insert(insertVo) {
        const apiUrl = "/ci-storage/base/vehicle/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async update(updateVo) {
        const apiUrl = "/ci-storage/base/vehicle/update";
        return await httpRequest.post(apiUrl, updateVo);
    }

    //启用禁用
    async disable(params) {
        const apiUrl = `/ci-storage/base/vehicle/change`;
        return await httpRequest.post(apiUrl,params);
    }
    //导入
    async import(file) {
        const appPageUrl = "/ci-storage/base/vehicle/import";
        return await httpRequest.post(appPageUrl, file);
    }
    //导出
    async export(searchVo) {
        const appPageUrl = "/ci-storage/base/vehicle/export";
        return await httpRequest.postFile(appPageUrl, searchVo);
    }
    //下载模板
    async downloadTpl() {
        const appPageUrl = "/ci-storage/base/vehicle/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }
}

export  const vehicleService = new VehicleService()