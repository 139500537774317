import React, { useState, useEffect, useContext } from "react"
import { Table, Button, message, Modal, Select } from "antd"
import { getTeamList, getTeamMemberList, deleteTeam } from "@/service/teamService"
import TopSearch from "@/components/TopSearch"
import TeamDrawer from "./teamDetails.jsx"
import { MyContext } from "@/store/myContext"
import TeamInsert from "./teamInsert"
import TeamEdit from "./teamEdit"
import TeamStatic from "./teamStatic"
import { organPage } from "@/service/orgService"
import { getPcodeDict } from "@/utils/dataStorage"
import { settlementMethodEmun } from "@/utils/enum"
import { download, convertRes2Blob } from "@/utils/exportExcel"
import Loading from "@/components/Loading"
import BillSettle from "./billSettle.jsx"
import "./teamStyle.less"
import YhTooltip from "@/components/YhTooltip"

const { Option } = Select

const TeamManage = ({ organId }) => {
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const { state, dispatch, showAllOrgan } = useContext(MyContext)
    const [teamInsertVisible, setTeamInsertVisible] = useState(false)
    const [teamEditVisible, setTeamEditVisible] = useState(false)
    const [teamData, setTeamData] = useState([]) //团队列表数据
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录团队列表当前行下标及当前行信息
    const [loading, setLoading] = useState(false) //团队列表loading
    const [pagination, setpagination] = useState({
        //团队列表分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [searchValue, setSearchValue] = useState({ teamUserName: "", organId, settlementMethod: Object.keys(settlementMethodEmun)[0] }) //搜索
    const [org, setOrg] = useState([])
    const [memberData, setMemberData] = useState([]) // 成员列表的数据
    const [dictObj, setDictObj] = useState(null)
    const [billSettlevisible, setBillSettlevisible] = useState(false)

    const [drawerDetails, setDrawerDetails] = useState({
        mode: "",
        rowInfo: {},
        visible: false,
    })
    useEffect(() => {
        if (state.activeMenu === "teamManage") {
            loadData(pagination.current)
            initDict()
            organPage({
                searchItem: { orderFlag: 0 },
                pageNum: 1,
                pageSize: 100,
                orders: [{ column: "createTime", rule: "DESC" }],
            }).then((res) => {
                setOrg(res.retData.list)
            })
        }
        return () => setOrg([])
    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        loadData(1)
    }, [searchValue]) // eslint-disable-line react-hooks/exhaustive-deps

    //初始下工单用到的字典
    const initDict = () => {
        let obj = {
            joinTypes: {},
        }
        getPcodeDict("028").children.forEach((item) => {
            obj.joinTypes[item.dictCode] = item.title
        })
        setDictObj(obj)
    }

    const pointColumns = [
        {
            title: "团队名称",
            align: "center",
            dataIndex: "teamName",
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "联系人",
            align: "center",
            dataIndex: "contactName",
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "联系电话",
            align: "center",
            dataIndex: "contactPhone",
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "班组属性",
            align: "center",
            dataIndex: "teamAttribute",
            render: (text) => <YhTooltip text={dictObj.joinTypes[text]}/>,
        },
        {
            title: "登记日期",
            align: "center",
            dataIndex: "createTime",
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
        },
    ]
    const shareRatioColumns = [
        {
            title: "团队名称",
            align: "center",
            dataIndex: "teamName",
        },
        {
            title: "联系人",
            align: "center",
            dataIndex: "contactName",
        },
        {
            title: "联系电话",
            align: "center",
            dataIndex: "contactPhone",
        },
        {
            title: "车辆牌照",
            align: "center",
            dataIndex: "vehicleCode",
        },
        {
            title: "班组属性",
            align: "center",
            dataIndex: "teamAttribute",
            render: (text) => <>{dictObj.joinTypes[text]}</>,
        },
        {
            title: "税点",
            align: "center",
            dataIndex: "taxRatio",
            render: (text, r) => (text ? text + "%" : 0),
        },
        {
            title: "平台服务费",
            align: "center",
            dataIndex: "platServiceRatio",
            render: (text, r) => (text ? text + "%" : 0),
        },
        {
            title: "团队分成",
            align: "center",
            dataIndex: "shareRatio",
        },
        {
            title: "车载设备编号",
            align: "center",
            dataIndex: "vehicleEquipmentCode",
        },
        {
            title: "登记日期",
            align: "center",
            dataIndex: "createTime",
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
        },
    ]
    const quotaColumns = [
        {
            title: "团队名称",
            align: "center",
            dataIndex: "teamName",
        },
        {
            title: "联系人",
            align: "center",
            dataIndex: "contactName",
        },
        {
            title: "联系电话",
            align: "center",
            dataIndex: "contactPhone",
        },
        {
            title: "车辆牌照",
            align: "center",
            dataIndex: "vehicleCode",
        },
        {
            title: "班组属性",
            align: "center",
            dataIndex: "teamAttribute",
            render: (text) => <>{dictObj.joinTypes[text]}</>,
        },
        {
            title: "定额费用",
            align: "center",
            dataIndex: "quotaAmount",
        },
        {
            title: "佣金比例",
            align: "center",
            dataIndex: "quotaRatio",
        },
        {
            title: "车载设备编号",
            align: "center",
            dataIndex: "vehicleEquipmentCode",
        },
        {
            title: "登记日期",
            align: "center",
            dataIndex: "createTime",
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
        },
    ]
    //团队列表数据
    const loadData = async (current, search = searchValue) => {
        // console.log(organId)
        await setLoading(true)
        await setSelectInfo({
            rowInfo: {},
            rowIndex: -1,
        })
        await setMemberData([])

        let data = {
            searchItem: { organId, disableFlag: 0, ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },
            ],
        }
        getTeamList(data).then((res) => {
            // if (res && res.retCode === 200) {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setTeamData(res.retData.list)
            setLoading(false)
            // } else {
            //     message.error("加载团队列表数据失败")
            // }
        })
    }

    //分页事件 置空当前行信息  翻页时置空成员列表
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }

    //选择团队列表当前行的信息，调用列表接口
    const selectRow = async (record, index) => {
        console.log(record)
        await setSelectInfo({
            rowInfo: record,
            rowIndex: index,
        })
        // await memberLoadData(record.id);
    }

    //成员列表数据加载
    const memberLoadData = async (temaId = selectInfo.rowInfo.id) => {
        await getTeamMemberList({
            searchItem: { teamId: temaId },
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            // if (res && res.retCode === 200) {
            setMemberData(res.retData.list)
            // } else {
            //     message.error("获取成员列表数据失败")
            // }
        })
    }

    //弹框操作  模式  行信息
    const showDrawer = async (bool, mode = "", rowInfo = {}) => {
        await setDrawerDetails({
            mode: mode,
            rowInfo: rowInfo,
            visible: bool,
        })
    }

    //团队列表table配置
    // const columns = [
    //     {
    //         title: "团队名称",
    //         align: "center",
    //         dataIndex: "teamName",
    //     },
    //     {
    //         title: "联系人",
    //         align: "center",
    //         dataIndex: "contactName",
    //     },
    //     {
    //         title: "联系电话",
    //         align: "center",
    //         dataIndex: "contactPhone",
    //     },
    //     {
    //         title: "车辆牌照",
    //         align: "center",
    //         dataIndex: "vehicleCode",
    //     },
    //     {
    //         title: "班组属性",
    //         align: "center",
    //         dataIndex: "teamAttribute",
    //         render: (text) => <>{dictObj.joinTypes[text]}</>,
    //     },
    //     {
    //         title: "所属机构",
    //         align: "center",
    //         dataIndex: "organName",
    //     },
    //     {
    //         title: "税点",
    //         align: "center",
    //         dataIndex: "taxRatio",
    //         render: (text, r) => (text ? text + "%" : 0),
    //     },
    //     {
    //         title: "平台服务费",
    //         align: "center",
    //         dataIndex: "platServiceRatio",
    //         render: (text, r) => (text ? text + "%" : 0),
    //     },
    //     {
    //         title: "团队分成",
    //         align: "center",
    //         dataIndex: "shareRatio",
    //     },
    //     {
    //         title: "结算方式",
    //         align: "center",
    //         dataIndex: "settlementMethod",
    //         render: (text, r) => settlementMethodEmun[text],
    //     },
    //     {
    //         title: "车载设备编号",
    //         align: "center",
    //         dataIndex: "vehicleEquipmentCode",
    //     },
    //     {
    //         title: "登记日期",
    //         align: "center",
    //         dataIndex: "createTime",
    //     },
    //     {
    //         title: "备注",
    //         align: "center",
    //         dataIndex: "remark",
    //     },
    // ]

    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "select",
                placeholder: "请选择结算方式",
                valueName: "settlementMethod",
                disabledClear: true,
                optionList: () =>
                    Object.entries(settlementMethodEmun).map((item) => (
                        <Option value={item[0]} key={item[0]}>
                            {item[1]}
                        </Option>
                    )),
            },
            {
                type: "input",
                placeholder: "团队名称",
                valueName: "teamName",
            },
            {
                type: "input",
                placeholder: "团队成员名称",
                valueName: "teamUserName",
            },
            {
                type: "input",
                placeholder: "团队联系名称",
                valueName: "contactName",
            },
            {
                type: "input",
                placeholder: "团队联系人电话",
                valueName: "contactPhone",
            },
            {
                hidden: !showAllOrgan,
                type: "select",
                placeholder: "请选择客户所属机构",
                valueName: "organId",
                optionList: () =>
                    org.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.organName}
                        </Option>
                    )),
            },
            {
                type: "Checkbox",
                valueName: "disableFlag",
                placeholder: "禁用状态",
                defaultChecked: searchValue.disableFlag ? true : false,
            },
        ],
    }
    const exportData = async () => {
        setExportLoading(true)
        let data = {
            type: "post",
            data: {
                pageSize: pagination.pageSize,
                pageNum: pagination.current,
                startTime: searchValue.startTime ? searchValue.startTime : null,
                endTime: searchValue.endTime ? searchValue.endTime : null,
                searchItem: {
                    ...searchValue,
                },
                orders: [
                    {
                        column: "createTime",
                        rule: "DESC",
                    },
                ],
            },
        }
        let resFlie = await download("/ci/team/export", data)
        if (resFlie && resFlie.status === 200) {
            message.success("已导出")
            convertRes2Blob(resFlie)
        } else {
            message.warning("导出异常，请重新尝试")
        }
        setExportLoading(false)
    }
    return (
        <>
            {/* <Modal visible={teamStatic} title="统计分析" width={1000}
                footer={null} onCancel={() => setTeamStatic(false)}>
                <TeamStatic visible={teamStatic}
                    teamAttribute={selectInfo.rowInfo.teamAttribute}
                    teamId={selectInfo.rowInfo.id} />
            </Modal> */}
            <TeamInsert
                settlementMethodEmun={settlementMethodEmun}
                org={org}
                visible={teamInsertVisible}
                onTeamLoadData={loadData}
                onCancel={(bool) => setTeamInsertVisible(bool)}
            />
            <TeamEdit
                settlementMethodEmun={settlementMethodEmun}
                org={org}
                visible={teamEditVisible}
                row={selectInfo.rowInfo}
                onTeamLoadData={loadData}
                onCancel={(bool) => setTeamEditVisible(bool)}
            />
            {state.activeMenu === "teamManage" && (
                <>
                    <TopSearch
                        title="团队管理"
                        justify="start"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                        defaultSearch={{ settlementMethod: "point" }}
                    />
                    <div className="teamManage-container">
                        <div className="team-list-box">
                            <div className="team-list-header">
                                <div className="team-list-name">
                                    <span>团队列表</span>
                                </div>
                                <div className="team-list-but">
                                    {/* <Button shape="round" size="small" className="team-but-left" onClick={() => teamInsert()}>新增</Button> */}
                                    <Button shape="round" size="small" className="team-but-left" onClick={() => setTeamInsertVisible(true)}>
                                        新增
                                    </Button>
                                    {/* <Button shape="round" size="small" className="team-but-left" onClick={() => teamEdit()}>编辑</Button>        */}
                                    <Button
                                        shape="round"
                                        size="small"
                                        className="team-but-left"
                                        onClick={() => {
                                            if (!Object.keys(selectInfo.rowInfo).length) {
                                                message.warning("请选择你要编辑的团队信息！")
                                                return
                                            }
                                            setTeamEditVisible(true)
                                        }}
                                    >
                                        编辑
                                    </Button>
                                    <Button
                                        shape="round"
                                        size="small"
                                        className="team-but-left"
                                        onClick={() => {
                                            if (!Object.keys(selectInfo.rowInfo).length) {
                                                message.warning("请选择你要删除的团队！")
                                                return
                                            }
                                            Modal.confirm({
                                                content: `确定要删除团队${selectInfo.rowInfo.teamName}?`,
                                                okText: "确定",
                                                cancelText: "取消",
                                                centered: true,
                                                onOk() {
                                                    deleteTeam(selectInfo.rowInfo.id).then((res) => {
                                                        // if (res && res.retCode === 200) {
                                                        loadData(1)
                                                        message.success("删除成功")
                                                        // } else {
                                                        //     message.error(res.retMsg)
                                                        // }
                                                    })
                                                },
                                            })
                                        }}
                                    >
                                        删除
                                    </Button>
                                    <Button
                                        shape="round"
                                        size="small"
                                        className="team-but-left"
                                        onClick={async () => {
                                            if (!Object.keys(selectInfo.rowInfo).length) {
                                                message.warning("请选择一个团队！")
                                                return
                                            }
                                            await dispatch({
                                                type: "removeTabs",
                                                targetKey: "teamIncome",
                                            })
                                            dispatch({
                                                type: "changeTabs",
                                                name: "团队收益",
                                                activeMenu: "teamIncome",
                                                content: (
                                                    <TeamStatic
                                                        teamAttribute={selectInfo.rowInfo.teamAttribute}
                                                        teamId={selectInfo.rowInfo.id}
                                                        teamName={selectInfo.rowInfo.teamName}
                                                        teamSetSettlementMethod={selectInfo.rowInfo.settlementMethod}
                                                    ></TeamStatic>
                                                ),
                                            })
                                        }}
                                    >
                                        统计分析
                                    </Button>
                                    {searchValue.settlementMethod === "quota" && (
                                        <Button
                                            shape="round"
                                            size="small"
                                            className="team-but-left"
                                            onClick={async () => {
                                                if (!Object.keys(selectInfo.rowInfo).length) {
                                                    message.warning("请选择一个团队！")
                                                    return
                                                }
                                                setBillSettlevisible(true)
                                            }}
                                        >
                                            账单结算
                                        </Button>
                                    )}
                                    <Button shape="round" size="small" className="team-but-left" onClick={exportData}>
                                        导出
                                    </Button>
                                </div>
                            </div>
                            <div className="team-table-box">
                                {(searchValue.settlementMethod === "point" || searchValue.settlementMethod === "work_hour") && (
                                    <Table
                                        size="small"
                                        columns={pointColumns}
                                        dataSource={teamData}
                                        rowKey={(record) => record.id}
                                        loading={loading}
                                        onChange={onTableChange}
                                        pagination={{
                                            showQuickJumper: true,
                                            showSizeChanger: false,
                                            showTotal: () => `共${pagination.total}条`,
                                            position: ["bottomCenter"],
                                            ...pagination,
                                        }}
                                        onRow={(record, index) => ({
                                            onClick: () => selectRow(record, index),
                                        })}
                                        rowClassName={(record, index) => {
                                            let className = ""
                                            if (index === selectInfo.rowIndex) className = "dark-row "
                                            return className
                                        }}
                                    />
                                )}
                                {searchValue.settlementMethod === "share_ratio" && (
                                    <Table
                                        size="small"
                                        columns={shareRatioColumns}
                                        dataSource={teamData}
                                        rowKey={(record) => record.id}
                                        loading={loading}
                                        onChange={onTableChange}
                                        pagination={{
                                            showQuickJumper: true,
                                            showSizeChanger: false,
                                            showTotal: () => `共${pagination.total}条`,
                                            position: ["bottomCenter"],
                                            ...pagination,
                                        }}
                                        onRow={(record, index) => ({
                                            onClick: () => selectRow(record, index),
                                        })}
                                        rowClassName={(record, index) => {
                                            let className = ""
                                            if (index === selectInfo.rowIndex) className = "dark-row "
                                            return className
                                        }}
                                    />
                                )}
                                {searchValue.settlementMethod === "quota" && (
                                    <Table
                                        size="small"
                                        columns={quotaColumns}
                                        dataSource={teamData}
                                        rowKey={(record) => record.id}
                                        loading={loading}
                                        onChange={onTableChange}
                                        pagination={{
                                            showQuickJumper: true,
                                            showSizeChanger: false,
                                            showTotal: () => `共${pagination.total}条`,
                                            position: ["bottomCenter"],
                                            ...pagination,
                                        }}
                                        onRow={(record, index) => ({
                                            onClick: () => selectRow(record, index),
                                        })}
                                        rowClassName={(record, index) => {
                                            let className = ""
                                            if (index === selectInfo.rowIndex) className = "dark-row "
                                            return className
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        {/* <div className="team-member-box">
							<div className="team-list-header">
								<div className="team-list-but">
									<Button shape="round" size="small" className="team-but-left" onClick={() => memberInsert()}>新增</Button>
									<Button shape="round" size="small" className="team-but-left" onClick={() => memberEdit()}>编辑</Button>
								</div>
							</div>
							<Table
								size="small"
								rowKey={record => record.id}
								columns={memberColumns}
								dataSource={memberData}
								loading={memberLoading}
								pagination={false}
								onRow={(record, index) => ({
									onClick: () => selectMemberRow(record, index)
								})}
								rowClassName={(record, index) => {
									let className = '';
									if (index === selectMemberInfo.rowIndex) className = 'dark-row';
									return className;
								}}
							/>
						</div> */}
                    </div>
                    <TeamDrawer
                        mode={drawerDetails.mode}
                        rowInfo={drawerDetails.rowInfo}
                        teamVisible={drawerDetails.visible}
                        onMemberLoadData={memberLoadData}
                        onTeamLoadData={loadData}
                        onShow={showDrawer}
                        memberData={memberData}
                    />
                    {exportLoading && <Loading />}
                    {
                      billSettlevisible &&   <BillSettle  visible={billSettlevisible} setVisible={setBillSettlevisible} rowInfo={selectInfo.rowInfo} />
                    }
                </>
            )}
        </>
    )
}
export default TeamManage
