import React, { useContext } from "react";
import { Button, message, Modal } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { usePurchasePlanModel } from "../purchasePlanModel";
import { DataTable } from "./DataTable";
import { MyContext } from "@/store/myContext";
import { formatNum } from "@/erp_subpackage/utils/util";

export const DataBox = () => {
    const { selectedRowKeys, descTotal, rowsDescTotal, searchParam, shopId, setPrint, exportList } = usePurchasePlanModel();
    const { state, dispatch, detailsPage } = useContext(MyContext);

    //新增 查看 编辑
    const goPageNav = (mode, rowCode) => {
        let tabName = {
            add: "采购订单新增",
            edit: "采购订单编辑",
            look: "采购订单查看",
        }
        let modeKey = "purchasePlan/" + mode;

        //动态传入要删除的nav key
        const handleDispatch = (obj = {}) => {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.PurchasePlanDetails({
                    modeKey,
                    mode,
                    dispatch,
                    rowCode: rowCode ?? selectedRowKeys[0]?.planCode
                }),
            })
        }

        if (mode === "edit") {
            const findNav = state.panes.find(item => item.key === modeKey);
            if (findNav) {
                Modal.confirm({
                    title: '温馨提醒！',
                    content: "您还有采购订单详情未保存，是否跳转",
                    okText: '跳转未保存页',
                    cancelText: '打开新页签',
                    onOk: () => {
                        handleDispatch()
                    },
                    //打开新页签重新生成
                    onCancel: () => {
                        handleDispatch({ deleteKey: modeKey })
                    }
                });
                return;
            }
            handleDispatch()
        }
        else if (mode === "add") {
            handleDispatch()
        }
        else if (mode === "look") {
            handleDispatch({ deleteKey: modeKey })
        }
    }

    const action = <>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                goPageNav("add")
            }}
        >新增</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (selectedRowKeys.length !== 1) {
                    message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！")
                    return;
                }
                goPageNav("edit")
            }}
            disabled={searchParam.shopId === "" || (searchParam.shopId && searchParam.shopId !== shopId)}
        >编辑</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (selectedRowKeys.length !== 1) {
                    message.warning("请选择单条数据进行查看，点击鼠标右键可一键选中哦！")
                    return;
                }
                goPageNav("look")
            }}
        >查看</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => exportList()}
        >导出</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (selectedRowKeys.length !== 1) {
                    message.warning("请选择单条数据进行操作，点击鼠标右键可一键选中哦！")
                    return;
                }
                setPrint(true)
            }}
        >打印</Button>
    </>;

    const desc = [
        {
            label: "采购数量",
            value: !selectedRowKeys.length ? descTotal.totalNum : rowsDescTotal.totalNum,
            color: "red"
        },
        {
            label: "采购金额",
            value: !selectedRowKeys.length ? descTotal.totalAmount : formatNum(rowsDescTotal.totalAmount),
            color: "red"
        }
    ]

    return (
        <YhBox title="采购计划列表" action={action} descriptions={desc}>
            <DataTable goPageNav={goPageNav} />
        </YhBox>
    )
}

