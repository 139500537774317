import React, { useEffect, useState } from "react";
import { Table, Button, Input, Tooltip,Checkbox } from "antd";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { useSaleSoDetailModel } from "./saleSoDetailModal";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { EditableRow, EditableCell } from "@/erp_subpackage/components/YHEditTable/editTable";
import { YhBut } from "@/erp_subpackage/components/YhButton";
import { formatNum, formatIntPoint, formatIntPoint1 } from "@/erp_subpackage/utils/util";
import { ISalesTypeEnum, IPriceTypeEnum } from "@/erp_subpackage/utils/ErpEnum"
import { stockDetailedService } from "@/erp_subpackage/pages/stock/detailed/stockDetailedService";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { stockStatisticService } from "@/erp_subpackage/pages/stock/statistic/stockStatisticService";
import YhTooltip from "@/erp_subpackage/components/YhTooltip";
import {saleSoService} from './../../saleSoService'
import { number } from "yargs";

export const AddGoodsModal = ({ modeKey, mode, rowId, dispatch }) => {
    const { user: { shopId, shopName, shopCode, }, setMaterialInfo } = useGlobalModel();
    const { addModalVisible: visible,
        addDataSource,
        detailAddForm,
        detailEditForm,
        detailLookForm,
        setAddDataSource, transformsToPoDetailList, setAddModalVisible
    } = useSaleSoDetailModel();
    const initPgCfg = { pageSize: 15, current: 1 }
    //商品列表配置
    const [dataSource, setDataSource] = useState([])
    const [searchValue, setSearchValue] = useState({})
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    const [checked, setChecked] = useState(true);
    const [isHovered, setIsHovered] = useState(false);
    const [maxPrice,setMaxPrice]=useState('')
    const [minPrice,setMinPrice]=useState('')
      //表格多选row
      const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //加载商品数据
    const loadData = async (page, searchValues = searchValue) => {
        let params = {...searchValues, materialCode:  searchValues.materialCode?.trim(), materialName:  searchValues.materialName?.trim(),}
        setSearchValue(params)
        const searchParams = {
            ...params,
            shopId,
            shopName,
            shopCode,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
            status: "purchase-status.bill,purchase.bill,purchase.completely-io,purchase.allocation"
        }
        let { retData } = await stockStatisticService.findPage(searchParams);
        const { records, ...pg } = retData;
        const newRecords = records.map(item => {
            let lateSalePrice = Number(item.lateSalePrice);
            return {
                ...item,
                num: 1,
                lateSalePrice,
                amount: lateSalePrice * 1,
            }
        })
        setPgCfg(transPgToPagination(pg));
        setDataSource(newRecords);
    }

    useEffect(() => {
        visible && loadData(initPgCfg,{ age: 1 })
    }, [visible])

    const onRow = (record) => {
        if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record) {
            setSelectedRowKeys([]);
        } else {
            setSelectedRowKeys([record]);
        }
        let { customerCode } = mode === "add" ? detailAddForm : detailEditForm;
        let { materialCode, } = record 
        let data={
            customerCode,
            shopId,materialCode
          }
       saleSoService.price(data).then(res=>{
        
        let{retData}=res
        let max=(retData).maxPrice.toFixed(2)
        let min=(retData).minPrice.toFixed(2)
        setMaxPrice(max)
        setMinPrice(min)
        })
    };

    const handleMouseOver = (record) => {
        setIsHovered(true);
        let { customerCode } = mode === "add" ? detailAddForm : detailEditForm;
        let { materialCode, } = record 
        let data={
            customerCode,
            shopId,materialCode
          }
       saleSoService.price(data).then(res=>{
        
        let{retData}=res
        let max=(retData).maxPrice.toFixed(2)
        let min=(retData).minPrice.toFixed(2)
        setMaxPrice(max)
        setMinPrice(min)
        })
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };
      const detailForm= mode === "add" ? detailAddForm :  mode === "edit" ?detailEditForm:detailLookForm
    const column = (optype) => [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            //render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })}  onMouseOver={()=>{handleMouseOver(record)}} onMouseLeave={()=>{handleMouseLeave()}}></YhTooltip>,
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName,customerName:detailForm.customerName,customerCode:detailForm.customerCode })}></YhTooltip>,
        }, {
            title: '商品名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text,record) => <YhTooltip text={text}  onClick={()=>{handleMouseOver(record)}}/>,
        }, {
            title: '单位',
            width: 50,
            dataIndex: 'unitName',
        }, {
            title: '可用库存',
            width: 100,
            dataIndex: 'availableStock',
        }, {
            title: '库房',
            width: 140,
            dataIndex: 'warehouseName',
            render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '数量',
            width: 100,
            dataIndex: 'num',
            editable: true,
        }, {
            title: '价格',
            width: 100,
            dataIndex: 'price',
            editable: true,
        }, {
            title: '总计',
            width: 100,
            dataIndex: 'amount',
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
        }, {
            title: '采购单号',
            width: 160,
            dataIndex: 'purchaseCode',
        }, {
            title: '操作',
            width: 60,
            fixed: "right",
            align: "center",
            render: (text, record) => optype === 'add' ? (
                <YhBut
                    type="add"
                    txt="添加"
                    click={() => add(record)}
                    disabled={!!addDataSource.find(item => item.id === record.id) || (record.availableStock === 0)}
                />
            ) : (
                <YhBut
                    type="delete"
                    txt="删除"
                    click={() => remove(record)}
                />
            )
        }
    ];
    const newSelectColumns = (optType) =>
        column(optType).map((col, index) => {
            return {
                ...col,
                onCell: (record, rowIndex) => ({
                    colIndex: index,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: optType === 'add' ? notHandleSave : handleSave,
                }),
            }
        })
    const notHandleSave = (row) => {
        let { num, price, availableStock, purchasePrice, latestPurPrice } = row
        num = dispatch ? +formatIntPoint(num) : +formatIntPoint(num, availableStock, "销售数量>可用库存数量")
        // price = +formatIntPoint1(price, newPurPrice, `修改价格不应小于进货价${newPurPrice}`);
        price = +formatNum(price);

        let newRow = {
            ...row,
            num,
            price,
            amount: +formatNum(num * price)
        }
        const index = dataSource.findIndex((item) => row.id === item.id);
        const item = dataSource[index];
        dataSource.splice(index, 1, { ...item, ...newRow });
        setDataSource([...dataSource])
    }
    const handleSave = (row) => {
        let { num, price, availableStock, purchasePrice, latestPurPrice } = row
        num = dispatch ? +formatIntPoint(num) : +formatIntPoint(num, availableStock, "销售数量>可用库存数量")
        // num = +formatIntPoint(num, availableStock, "销售数量>可用库存数量")
        // price = +formatIntPoint1(price, newPurPrice, `修改价格不应小于进货价${newPurPrice}`);
        price = +formatIntPoint(price);

        let newRow = {
            ...row,
            num,
            price,
            amount: +formatNum(num * price)
        }
        const index = addDataSource.findIndex((item) => row.id === item.id);
        const item = addDataSource[index];
        addDataSource.splice(index, 1, { ...item, ...newRow });
        setAddDataSource([...addDataSource])
    }
    const add = async (record) => {
        // setAddDataSource(origin => [...origin, record])
        let { priceTypeCode, salesTypeCode, customerCode } = mode === "add" ? detailAddForm : detailEditForm;
        let { latestPurPrice, materialCode, lateSalePrice, purchasePrice, retailPrice, petroleumPrice, num, ...params } = record
        let newPrice = latestPurPrice ?? purchasePrice;
        if (salesTypeCode === ISalesTypeEnum.NORMAL_SALES) {
            switch (priceTypeCode) {
                //售价选择 客户最近销价 (需要通过接口查询，客户最近一次的销售价)
                case IPriceTypeEnum.CUSTOMER_LATEST:
                    let { retData: { price } } = await stockDetailedService.recentSalesPrice(shopId, materialCode, customerCode);
                    newPrice = +price;
                    break;
                //售价选择 最近销价 
                case IPriceTypeEnum.RECENT:
                    newPrice = lateSalePrice ?? retailPrice;
                    break;
                //售价选择 零售价
                case IPriceTypeEnum.RETAIL:
                    newPrice = retailPrice;
                    break;
                //售价选择 采购成本价
                case IPriceTypeEnum.PUR_COST:
                    newPrice = latestPurPrice ?? purchasePrice;
                    break;
                //售价选择 档案进价
                case IPriceTypeEnum.BUYING_PRICE:
                    newPrice = purchasePrice ?? latestPurPrice;
                    break;
                //售价选择 石油价格
                case IPriceTypeEnum.PETROLEUM:
                    newPrice = petroleumPrice ?? purchasePrice;
                    break;
            }
        }
        setAddDataSource(origin => [...origin, {
            ...record,
            num,
            price: newPrice,
            amount: +formatNum(num * newPrice)
        }])
    }
    const remove = async (record) => {
        const filterAddDataSource = addDataSource.filter(item => item.id !== record.id)
        setAddDataSource(filterAddDataSource)
    }
    const onOK = async () => {
        transformsToPoDetailList(mode)
        onCancel()
    }
    const onCancel = () => {
        setAddModalVisible(false);
        setAddDataSource([]);
        setChecked(true)
    };

    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchValue.materialCode}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchValue({ ...searchValue, materialCode: e.target.value }) }}
            onPressEnter={() => loadData(initPgCfg)}
            placeholder="商品编号"
            onBlur={(e) => { setSearchValue({ ...searchValue, materialCode:e.target.value}) }}
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchValue.materialName}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchValue({ ...searchValue, materialName: e.target.value }) }}
            onPressEnter={() => loadData(initPgCfg)}
            placeholder="商品名称"
        />
       <Checkbox checked={checked} onChange={(e) => { setChecked(e.target.checked); loadData(initPgCfg, { ...searchValue, age: e.target.checked ? 1 : 0 }); setSearchValue({ ...searchValue, age: e.target.checked ? 1 : 0 }) }}>
            是否有库存
        </Checkbox>
        <Button onClick={() => loadData(initPgCfg)} style={{ margin: "0px 5px 0px 10px" }} shape="round">检索</Button>
        <Button onClick={() => {
            setSearchValue({});
            loadData(initPgCfg, {});
            setChecked(false) 
        }} shape="round">重置</Button>
    </div>

    return <YHModal
        visible={visible}
        onOk={onOK}
        onCancel={onCancel}
        title="销售单-选择商品"
        width={1000}
        bodyHeight={710}
        showFooter={true}
    >
        <YhBox title="待选商品列表" rightChildren={rightChildren} style={{ marginBottom: "15px" }}>
            <Table
                rowClassName={(record) => {
                    if (record.availableStock === 0) {
                        return 'editable-row yh-edit-table-tr stock-td'
                    } else {
                        return 'editable-row yh-edit-table-tr'
                    }
                }}
                rowKey={record => record.id}
                columns={newSelectColumns('add')}
                dataSource={dataSource}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                onRow={record => ({
                    onClick: () => onRow(record),
                    onContextMenu: (e) => {
                        e.preventDefault();
                        setSelectedRowKeys([record.materialCode]);
                    }
                })}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize });
                    }
                }}
                scroll={{ x: 'max-content', y: 206 }}
            />
        </YhBox>
        <YhBox title="已选商品列表">
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.id}
                columns={newSelectColumns('remove')}
                dataSource={addDataSource}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                pagination={false}
                
                scroll={{ x: 'max-content', y: 206 }}
            />
            <div style={{paddingRight:'50px', display: 'inline-block'}}><span style={{fontSize:'18px',fontWeight:"bold",color:'#323232'}}>最高售价:</span><span style={{fontSize:'18px',color:'red',fontWeight:"bold"}}>{maxPrice}</span></div>
            <div style={{display: 'inline-block'}}><span style={{fontSize:'18px',fontWeight:"bold",color:'#323232'}}>最低售价:</span><span style={{fontSize:'18px',color:'red',fontWeight:"bold"}}>{minPrice}</span></div>
        </YhBox>
    </YHModal>
}