import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { useStockStatisticModel } from "../stockStatisticModel";
import { momentFormat, formatNum } from "@/erp_subpackage/utils/util";
import { useGlobalModel } from "@/erp_subpackage/model/globalModel";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys,setSearchParam,initSearchParams,initPgCfg
    } = useStockStatisticModel();
    const { setMaterialInfo, setOccupyStockInfo, setWaitStockInfo } = useGlobalModel(() => []);

    useEffect(() => {
        setSearchParam(initSearchParams)
        setSelectedRowKeys([])
        loadData(initPgCfg, {})
    }, [])
    const columns = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
            // render: (text, record, index) => <Tooltip placement="topLeft" title={text}><p className="one-line click-span" onClick={(e) => { e.stopPropagation(); setMaterialInfo(true, { materialCode: text, materialName: record.materialName }) }}>{text}</p></Tooltip>,
        }, {
            title: '商品名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text) => <YhTooltip text={text} />,
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '所属机构',
            width: 150,
            dataIndex: 'shopName',
        }, {
            title: '移库记录',
            width: 80,
            dataIndex: 'num',
            // render: (text: string, record: any) => <span onClick={() => {
            //     if (record.num === 0) {
            //         return false
            //     } else {
            //         setRecordsSearch({ stockId: record.id })
            //         setRecordsVisible(true)
            //     }

            // }} className="click-span">{text}</span>
        }, {
            title: '商品类型',
            width: 120,
            dataIndex: 'typeName',
        }, {
            title: '品牌',
            width: 100,
            dataIndex: 'brandName',
        }, {
            title: '替换编号',
            width: 120,
            dataIndex: 'replaceCode',
        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
            render: (text) => <YhTooltip text={text} />,
        }, 
        // {
        //     title: '初始库存',
        //     width: 80,
        //     dataIndex: 'inventoryInitial',
        // }, 
        {
            title: '库存数量',
            width: 80,
            dataIndex: 'inventoryNum',
        }, {
            title: '占用库存',
            width: 80,
            dataIndex: 'inventoryOccupied',
            render: (text, record) => <p className="one-line click-span" onClick={(e) => {
                e.stopPropagation();
                setOccupyStockInfo(true, {
                    materialCode: record.materialCode
                });
            }}>{text}</p>,
        }, {
            title: '待入库库存',
            width: 100,
            dataIndex: 'inventoryWait',
            render: (text, record) => <p className="one-line click-span" onClick={(e) => {
                e.stopPropagation();
                setWaitStockInfo(true, {
                    materialCode: record.materialCode
                });
            }}>{text}</p>,
        }, {
            title: '可用库存',
            width: 80,
            dataIndex: 'availableStock',
        }, {
            title: '进货价（参考价）',
            width: 130,
            dataIndex: 'purchasePrice',
            render: text => typeof text === "number" ? `￥${formatNum(text)}` : ""
        }, {
            title: '销售价（参考价）',
            width: 130,
            dataIndex: 'retailPrice',
            render: text => typeof text === "number" ? `￥${formatNum(text)}` : ""
        },
        {
            title: '进货价（最近进价）',
            width: 140,
            dataIndex: 'latestPurPrice',
            render: text => typeof text === "number" ? `￥${formatNum(text)}` : ""
        },
        {
            title: '进货价（平均价）',
            width: 130,
            dataIndex: 'averagePurchasePrice',
        }, 
        {
            title: '库存成本（最近进价）',
            width: 160,
            dataIndex: 'latestAmount',
            render: text => typeof text === "number" ? `￥${formatNum(text)}` : ""
        },
         {
            title: '库存成本（平均价）',
            width: 140,
            dataIndex: 'averageAmount',
        }, 
        {
            title: '零售价（最近）',
            width: 120,
            dataIndex: 'lateSalePrice',
            render: text => typeof text === "number" ? `￥${formatNum(text)}` : ""
        }, {
            title: '库存上限',
            width: 80,
            dataIndex: 'limitUpper',
        }, {
            title: '库存下限',
            width: 80,
            dataIndex: 'limitLower',
        }
    ];


    //点击行
    const onRow = (record) => {
        let findObj = selectedRowKeys.find(item => item.key === record.key)
        if (findObj) {
            let newSelectedRowKeys = selectedRowKeys.filter(item => item.key !== record.key);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            setSelectedRowKeys(origin => [...origin, record]);
        }
    };


    return (
        <Table
            loading={loading}
            rowSelection={{
                //全部选中时 (存row[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRows])
                        : setSelectedRowKeys((origin) => {
                            let changeRowId = changeRows.map(item => item.key);
                            return origin.filter(item => changeRowId.indexOf(item.key) < 0)
                        });
                },
                onSelect: (record, selected, selectedRows) => onRow(record),
                selectedRowKeys: selectedRowKeys.map(item => item.key)
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record]);
                }
            })}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}


