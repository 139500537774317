import React from "react"
import { useVehicleModel } from "../vehicleModel"
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader"
import useGlobalModel from "@/erp_subpackage/model/globalModel";

export function PageHeader() {
    const { searchParam, organType, loadData, updateSearchParam, resetSearchParam } = useVehicleModel()
    const { orgDs } = useGlobalModel(({ orgDs }) => [orgDs]);
    const searchFields = [
        ...(organType ? [{
            type: "Select",
            placeholder: "所属机构",
            fieldName: "shopId",
            options: orgDs
        }] : []),
        {
            type: "Input",
            placeholder: "客户名称",
            fieldName: "customerName",
        },
        {
            type: "Input",
            placeholder: "车辆牌照",
            fieldName: "licensePlate",
        },
        {
            type: "Input",
            placeholder: "vin码",
            fieldName: "vin",
        },
        {
            type: "Input",
            placeholder: "发动机号",
            fieldName: "engineCode",
        },
        {
            type: "Datepicker",
            placeholder: "登记日期",
            fieldName: "createTime",
        },
    ]

    return (
        <YhPageHeader
            title="车辆管理"
            size="small"
            triggerSearch={true}
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData({ pageNum: 1 }, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    )
}
