import React, { useState, useEffect } from "react"
import { Modal, Button, Steps, Popover, Tabs } from "antd"
import { orderLogs, operaSnapshot } from "@/service/ordersService"
import moment from "moment"
import { OperatorUserType } from "@/utils/enum.js"
import styled from "@emotion/styled"
const { Step } = Steps
const { TabPane } = Tabs
//项目
export default ({ orderId, visible, setShowLogs }) => {
    const [logs, setLogs] = useState([])
    const [operasnapshot, setOperasnapshot] = useState([])

    useEffect(() => {
        if (visible) {
            loadData()
        } else {
            setLogs([])
        }
    }, [visible])

    const loadData = () => {
        let data = {
            searchItem: {
                orderId,
            },
            pageNum: 1,
            pageSize: 10000,
            orders: [{ column: "updateTime", rule: "DESC" }],
        }
        orderLogs(data).then((res) => {
            let { list } = res.retData
            list.sort((a, b) => {
                return moment(b.recordTime).unix() - moment(a.recordTime).unix()
            })
            setLogs(list)
        })
        operaSnapshot("ORDER", orderId).then(res => {
            let  list  = res.retData
            list.sort((a, b) => {
                return moment(b.createTime).unix() - moment(a.createTime).unix()
            })
            setOperasnapshot(list)
        })
    }

    const onCancel = () => {
        setShowLogs(false)
    }

    const customDot = (dot, { status, index }) => (
        <Popover
            content={
                <span>
                    step {index} status: {status}
                </span>
            }
        >
            {dot}
        </Popover>
    )

    return (
        <Modal
            visible={visible}
            // title="订单日志"
            width={800}
            onCancel={onCancel}
            footer={[
                <Button onClick={onCancel} key="back">
                    {" "}
                    取消
                </Button>,
            ]}
        >
            <Tabs defaultActiveKey="1">
                <TabPane tab="订单日志" key="1">
                    <LogSteps>
                        <Steps className="logSteps" current={0} progressDot={customDot} direction="vertical">
                            {logs.map((item) => (
                                <Step
                                    title={item.description}
                                    subTitle={`${item.recordTime}`}
                                    description={`操作人:${OperatorUserType[item.operatorUserType] || ""}--${item.operatorUserName || ""}`}
                                    key={item.id}
                                />
                            ))}
                        </Steps>
                    </LogSteps>
                </TabPane>
                <TabPane tab="操作快照" key="2">
                    <LogSteps>
                        <Steps className="logSteps" current={0} progressDot={customDot} direction="vertical">
                            {operasnapshot.map((item) => (
                                <Step
                                    title={item.describe}
                                    subTitle={`${item.createTime}  ${item.traceId}`}
                                    description={`操作人:${OperatorUserType[item.operatorType] || ""}--${item.operatorName || ""}`}
                                    key={item.id}
                                />
                            ))}
                        </Steps>
                    </LogSteps>
                </TabPane>
            </Tabs>
        </Modal>
    )
}

const LogSteps = styled.div`
    .ant-steps-item-title {
        font-size: 20px;
    }
    .ant-steps-item-subtitle {
        font-size: 16px;
    }
    .ant-steps-item-description {
        font-size: 16px;
        color: #2e3aeb !important;
    }
`
