import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { getMaintenanceTemplateList, maintenanceTemplateListDelete } from "@/service/dataService"
import { MyContext } from "@/store/myContext"
import RepairTemplateDetail from "./repairTemplateDetail"
import { ItemAttr } from "@/utils/enum"
import { Select, message, Modal } from "antd"
import YhTooltip from '@/components/YhTooltip'
//访问列表
export default () => {
    const { state } = useContext(MyContext)
    const [showVisible, setShowVisible] = useState(false)
    const [showMode, setShowMode] = useState("")
    const [goodsList, setGoodsList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息

    useEffect(() => {
        if (state.activeMenu === "repairTemplate") {
            loadData(pagination.current)
        }
    }, [state.activeMenu])// eslint-disable-line react-hooks/exhaustive-deps

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        getMaintenanceTemplateList(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setGoodsList(res.retData.list)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("look"),
            content: "查看",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleDelete(),
            content: "删除",
        },
    ]


    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的品牌信息")
            return
        }
        Modal.confirm({
            content: `确定要删除维修套餐 - ${selectInfo.rowInfo.name}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                maintenanceTemplateListDelete(selectInfo.rowInfo.id).then((res) => {
                    message.success("删除成功")
                    loadData(pagination.current)
                })
            },
        })
    }

    //新增
    const handleDetails = async (mode) => {
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的回访信息")
                return
            }
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的回访信息")
                return
            }
        }
        setShowMode(mode)
        setShowVisible(true)
    }

    //table配置
    const columns = [
        {
            title: "套餐名称",
            align: "center",
            dataIndex: "name",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "套餐属性",
            align: "center",
            dataIndex: "attr",
            render: (text) => <YhTooltip text={ItemAttr[text]} />,
        },
        {
            title: "品牌",
            align: "center",
            dataIndex: "brand",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "维修项目",
            align: "center",
            dataIndex: "items",
            render: (text) => {
                if(!text){
                    text=[]
                }
                let br = <br></br>
                let result = null
                for (let j = 0; j < text.length; j++) {
                    const data = (
                        <span>
                            {text[j].itemCode} {text[j].itemName} {text[j].itemWorkHours}*{text[j].workHoursPrice}
                            <b style={{ color: "red" }}>{text[j].itemDeleteStatus ? "(已删除)" : ""}</b>
                        </span>
                    )
                    if (j === 0) {
                        result = data
                    } else {
                        result = (
                            <>
                                {result}
                                <span>
                                    {br}
                                    {data}
                                </span>
                            </>
                        )
                    }
                }
                return <div>{result}</div>
            },
        },
        {
            title: "维修用料",
            align: "center",
            dataIndex: "parts",
            render: (text) => {
                if(!text){
                    text=[]
                }
                let br = <br></br>
                let result = null
                for (let j = 0; j < text.length; j++) {
                    const data = (
                        <span>
                            {text[j].partCode} {text[j].partName} {text[j].partNumber}*{text[j].partRetailPrice}
                            <b style={{ color: "red" }}>{text[j].partDeleteStatus ? "(已删除)" : ""}</b>
                        </span>
                    )
                    if (j === 0) {
                        result = data
                    } else {
                        result = (
                            <>
                                {result}
                                <span>
                                    {br}
                                    {data}
                                </span>
                            </>
                        )
                    }
                }
                return <div>{result}</div>
            },
        },
        {
            title: "应收金额",
            align: "center",
            dataIndex: "totalAmount",
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "select",
                placeholder: "请选择项目属性",
                valueName: "attr",
                optionList: () =>
                    Object.entries(ItemAttr).map((item) => (
                        <Select.Option value={item[0]} key={item[0]}>
                            {item[1]}
                        </Select.Option>
                    )),
            },

            {
                type: "input",
                placeholder: "维修模版名称",
                valueName: "name",
            },
            {
                type: "input",
                placeholder: "品牌",
                valueName: "brand",
            },

            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                span: 16,
                xxl: 8,
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "repairTemplate" && (
                <>
                    <TopSearch
                        title="维修模版"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="维修模版"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={goodsList}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />

                    <RepairTemplateDetail />
                    {showVisible && (
                        <RepairTemplateDetail
                            rowInfo={selectInfo.rowInfo}
                            visible={showVisible}
                            mode={showMode}
                            setVisible={setShowVisible}
                            onSuccess={() => {
                                loadData(1)
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}
