import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { getReturnVisitList } from "@/service/customerService"
import { MyContext } from "@/store/myContext"
import { getPcodeDict } from "@/utils/dataStorage"
import ReturnVisitdetail from "./returnVisitdetail"
//访问列表
export default () => {
    const { state, message, Option } = useContext(MyContext)
    const [showVisible, setShowVisible] = useState(false)
    const [showMode, setShowMode] = useState("")
    const [goodsList, setGoodsList] = useState([]) //列表
    const [dictObj, setDictObj] = useState(null)
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息

    useEffect(() => {
        if (state.activeMenu === "returnVisit") {
            loadData(pagination.current)
            let obj = {
                type: {}, 
                title:{}
            }
            getPcodeDict("037").children.forEach((item) => {
                obj.type[item.dictCode] = item.title
            })
            getPcodeDict("021").children.forEach((item) => {
                obj.title[item.dictCode] = item.title
            })
            setDictObj(obj)
        }
    }, [state.activeMenu])

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        getReturnVisitList(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setGoodsList(res.retData.list)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("look"),
            content: "查看",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
    ]

    //新增
    const handleDetails = async (mode) => {
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的回访信息")
                return
            }
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的回访信息")
                return
            }
        }
        setShowMode(mode)
        setShowVisible(true)
    }

    //table配置
    const columns = [
        {
            title: "回访时间",
            align: "center",
            dataIndex: "returnVisitTime",
        },
        {
            title: "回访方式",
            align: "center",
            dataIndex: "returnVisitMethod",
            render: (text) => <>{dictObj.type[text]}</>,
        },
        {
            title: "客户名称",
            align: "center",
            dataIndex: "customerName",
        },
        {
            title: "车辆牌照",
            align: "center",
            dataIndex: "vehicleCode",
        },
        {
            title: "工单编号",
            align: "center",
            dataIndex: "orderCode",
        },
        {
            title: "联系人",
            align: "center",
            dataIndex: "corporationName",
        },
        {
            title: "联系电话",
            align: "center",
            dataIndex: "corporationPhone",
        },
        {
            title: "职务",
            align: "center",
            dataIndex: "corporationJobTitle",
            render: (text) => <>{dictObj.title[text]}</>,
        },
        {
            title: "登记时间",
            align: "center",
            dataIndex: "createTime",
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入客户名称",
                valueName: "customerName",
            },
            {
                type: "input",
                placeholder: "车辆编码(车牌号)",
                valueName: "vehicleCode",
            },
            {
                type: "select",
                placeholder: "回访方式",
                valueName: "returnVisitMethod",
                optionList: () =>
                    getPcodeDict("037").children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },

            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                span: 16,
                xxl: 8,
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "returnVisit" && (
                <>
                    <TopSearch
                        title="客户回访"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="客户回访"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={goodsList}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />

                    <ReturnVisitdetail />
                    {showVisible && (
                        <ReturnVisitdetail
                            rowInfo={selectInfo.rowInfo}
                            visible={showVisible}
                            mode={showMode}
                            setVisible={setShowVisible}
                            onSuccess={() => {
                                loadData(1)
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}
