import React from 'react'
import { message, Modal, Select, Row, Col, Switch, Input } from 'antd'
import { getPcodeDict, getUserBtn } from '@/utils/dataStorage'
import { orderCancelReview, orderReject, organDispatch, exportOrderData, exportOrderDetailData } from '@/service/ordersService'
import { getUserCustomizeConfigOne } from '@/service/settings'

const { Option } = Select
/**
 * 不同类型的订单状态
 */
const activeMenuOrderStatus = {
    ordersManage: ['pending_labor', 'pending_departure', 'already_departure', 'in_maintenance', 'tobe_confirmed'],
    allOrder: [],
    pendingOrder: ['pending_order'],
    paymentOrder: ['pending_payment'],
    reviewOrder: ['pending_system_review'],
    cancelleOrder: ['cancelled'],
    completeOrder: ['completed'],
}
//作废工单
export const cancellationOrder = async (selectInfo, loadData, searchValue) => {
    if (!Object.keys(selectInfo.rowInfo).length) {
        message.warning('请选择你要作废的工单')
        return
    }
    if (selectInfo.rowInfo.orderStatus === 'cancelled') {
        message.warning('该工单状态已是作废')
        return
    }
    let orderCancelReason = '' //记录工单作废原因
    Modal.confirm({
        title: '请选择取消工单的原因',
        content: (
            <Select
                showSearch
                placeholder="请选择取消工单的原因"
                optionFilterProp="children"
                onChange={(value) => {
                    orderCancelReason = value
                }}
                style={{ width: '200px' }}
            >
                {getPcodeDict('041').children.map((item) => (
                    <Option value={item.dictCode} key={item.id}>
                        {item.title}
                    </Option>
                ))}
            </Select>
        ),
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
            return new Promise((resolve, reject) => {
                if (orderCancelReason) {
                    resolve(orderCancelReason)
                } else {
                    message.warning('请输入工单作废的原因')
                    reject('请输入工单作废的原因')
                }
            }).then((res) => {
                let data = {
                    submitReason: res,
                    orderId: selectInfo.rowInfo.id,
                }
                orderCancelReview(data).then((res) => {
                    loadData(1, searchValue)
                    message.success('本条工单已申请作废')
                })
            })
        },
        onCancel: () => {},
    })
}

//机构派单
export const handleDispatchOrgan = (selectInfo, allOrg, loadData, searchValue) => {
    if (!Object.keys(selectInfo.rowInfo).length) {
        message.warning('请选择你要派单的工单')
        return
    }
    let organId = ''
    let clearTeam = false
    Modal.confirm({
        title: '选择转派的机构',
        content: (
            <Row>
                <Col span={24}>
                    <span style={{ display: 'inline-block', width: '100px', textAlign: 'right', marginRight: '10px' }}>请选择机构:</span>
                    <Select
                        showSearch
                        placeholder="请选择机构"
                        optionFilterProp="children"
                        onChange={(value) => {
                            organId = value
                        }}
                        style={{ width: '200px' }}
                    >
                        {allOrg.map((item) => (
                            <Option value={item.id} key={item.id}>
                                {item.organName}
                            </Option>
                        ))}
                    </Select>
                </Col>
                {/* <Col span={24} style={{ marginTop: '20px' }}>
                    <span style={{ display: 'inline-block', width: '100px', textAlign: 'right', marginRight: '10px' }}>清除团队信息:</span>
                    <Switch
                        checkedChildren="清除团队信息"
                        unCheckedChildren="关闭"
                        value={clearTeam}
                        onChange={(value) => {
                            clearTeam = value
                        }}
                    />
                </Col> */}
            </Row>
        ),
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
            let data = {
                organId,
                clearTeam,
                orderId: selectInfo.rowInfo.id,
            }
            organDispatch(data).then((res) => {
                loadData(1, searchValue)
                message.success(res.retData)
            })
        },
        onCancel: () => {},
    })
}

/**
 * 打印
 * @param {*} selectInfo
 * @param {*} setPrintVisible
 * @returns
 */
export const handlePrint = (selectInfo, setPrintVisible) => {
    if (!Object.keys(selectInfo.rowInfo).length) {
        message.warning('请选择你要打印的工单')
        return
    }
    setPrintVisible({ visible: true, orderId: selectInfo.rowInfo.id })
}
/**
 * 打印
 * @param {*} selectInfo
 * @param {*} setPrintSettleScaniaVisible
 * @returns
 */
export const handleSettleScaniaPrint = async (selectInfo, setPrintSettleScaniaVisible) => {
    if (!Object.keys(selectInfo.rowInfo).length) {
        message.warning('请选择你要打印的工单')
        return
    }
    if (!selectInfo.rowInfo.ciBusinessEntityName){
        message.warning('请选择完善工单实体后打印')
        return
    }
    // let printNum = '1'
    // try {
    //     let printModelList = await getUserCustomizeConfigOne('printModel')
    //     if (printModelList && printModelList.retCode == 200) {
    //         printNum=printModelList.retData.val
    //     } else {
    //         message.warning('没有获取到用户打印门店，使用默认门店')
    //         printNum='1'
    //     }
    //     // }
    // } catch (error) {
    //     message.warning('没有获取到用户打印门店，使用默认门店')
    //     printNum=1
    // }
    // setPrintSettleScaniaVisible({ visible: true, orderId: selectInfo.rowInfo.id,printNum })
    setPrintSettleScaniaVisible({ visible: true, orderId: selectInfo.rowInfo.id })
}
/**
 * 打印
 * @param {*} selectInfo
 * @param {*} setPrintSnkyVisible
 * @returns
 */
 export const snkyPrint = async (selectInfo, setPrintSnkyVisible,type=1) => {
    if (!Object.keys(selectInfo.rowInfo).length) {
        message.warning('请选择你要打印的工单')
        return
    }
    if (!selectInfo.rowInfo.ciBusinessEntityName){
        message.warning('请选择完善工单实体后打印')
        return
    }
    // let printNum = '1'
    // try {
    //     let printModelList = await getUserCustomizeConfigOne('printModel')
    //     if (printModelList && printModelList.retCode == 200) {
    //         printNum=printModelList.retData.val
    //     } else {
    //         message.warning('没有获取到用户打印门店，使用默认门店')
    //         printNum='1'
    //     }
    //     // }
    // } catch (error) {
    //     message.warning('没有获取到用户打印门店，使用默认门店')
    //     printNum=1
    // }
    setPrintSnkyVisible({ visible: true, orderId: selectInfo.rowInfo.id,type })
}
/**
 * 资源权限
 * @param {*} str
 * @returns
 */
export const butResourceApply = (str) => {
    let resource = getUserBtn('ordersManage') || {}
    resource = resource.children || []
    return resource.find((item) => item.path === str) ? true : false
}

/**
 * 订单导出明细
 * @param {*} columns
 * @param {*} searchValue
 * @param {*} activeMenu
 */
export const exportOrderDetailed = async (columns, searchValue, activeMenu = 'ordersManage',selectInfo) => {
    const tables = columns.map((item) => {
        return {
            title: item.title,
            key: item.dataIndex,
        }
    })
    let data = {
        type: 'post',
        data: {
            startTime: searchValue.startTime ? searchValue.startTime : null,
            endTime: searchValue.endTime ? searchValue.endTime : null,
            searchItem: {
                ...searchValue,
                tables,
                orderStatus: searchValue.orderStatus ? [searchValue.orderStatus] : activeMenuOrderStatus[activeMenu],
                ids:selectInfo.selectedRowKeys
            },
            orders: [
                {
                    column: 'updateTime',
                    rule: 'DESC',
                },
            ],
        },
    }
    //导出记录去查看
    exportOrderDetailData(data.data).then((res) => {
        message.success(res.retData)
    })
}
/**
 * 工单导出
 * @param {*} columns
 * @param {*} searchValue
 * @param {*} activeMenu
 */
export const exportOrder = async (columns, searchValue, activeMenu = 'ordersManage',selectInfo) => {
    const tables = columns.map((item) => {
        return {
            title: item.title,
            key: item.dataIndex,
        }
    })
    let data = {
        type: 'post',
        data: {
            startTime: searchValue.startTime ? searchValue.startTime : null,
            endTime: searchValue.endTime ? searchValue.endTime : null,
            searchItem: {
                ...searchValue,
                tables,
                orderStatus: searchValue.orderStatus ? [searchValue.orderStatus] : activeMenuOrderStatus[activeMenu],
                 ids:selectInfo.selectedRowKeys
            },
            orders: [
                {
                    column: 'updateTime',
                    rule: 'DESC',
                },
            ],
        },
    }
    //导出记录去查看
    exportOrderData(data.data).then((res) => {
        message.success(res.retData)
    })
}

/**
 * 派单
 * @param {*} status
 * @param {*} selectInfo
 * @param {*} setShowOrderDispatchVisible
 * @returns
 */
export const handleDispatchOrder = (status, selectInfo, setShowOrderDispatchVisible) => {
    if (!Object.keys(selectInfo.rowInfo).length) {
        message.warning('请选择你要派单的工单')
        return
    }
    setShowOrderDispatchVisible(status || true)
}

/**
 * 审核记录
 * @param {*} selectInfo
 * @param {*} setReviewInfo
 * @returns
 */
export const reviewRecord = (selectInfo, setReviewInfo) => {
    if (!selectInfo.rowInfo.id) {
        message.warning('请选择你要查看的工单')
        return
    }
    setReviewInfo({ visible: true, orderId: selectInfo.rowInfo.id })
}

/**
 * 驳回工单
 * @param {*} selectInfo
 * @param {*} loadData
 * @param {*} searchValue
 * @returns
 */
export const rejectOrder = (selectInfo, loadData, searchValue) => {
    if (!selectInfo.rowInfo.id) {
        message.warning('请选择你要驳回的工单')
        return
    }
    if (selectInfo.rowInfo.orderStatus === 'pending_system_review') {
        let str = '' //记录工单驳回原因
        Modal.confirm({
            title: '请输入驳回工单的原因',
            content: (
                <Input
                    placeholder="请输入工单驳回的原因"
                    onChange={(e) => {
                        str = e.target.value
                    }}
                />
            ),
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    if (str) {
                        resolve(str)
                    } else {
                        message.warning('请输入工单驳回的原因')
                        reject('请输入工单驳回的原因')
                    }
                }).then((res) => {
                    orderReject({
                        orderId: selectInfo.rowInfo.id,
                        rejectInfo: res,
                    }).then((res) => {
                        loadData(1, searchValue)
                        message.success('驳回成功')
                    })
                })
            },
            onCancel: () => {},
        })
    } else {
        message.warning('错误。当前订单不是待审核状态。无法进行驳回操作')
    }
}
