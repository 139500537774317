import React, { useEffect, useRef, useState } from "react"
import { Button, message,Modal } from "antd"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { YhFormBox } from "@/erp_subpackage/components/YhFormBox"
import { getFormValidatorErrText } from "@/erp_subpackage/utils/util"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { debounce } from "lodash"
import { useStockCheckDetailsModel } from "./stockCheckDetailModel";
import { Description } from "@/erp_subpackage/components/YhBox/Description";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { warehouseService } from "@/erp_subpackage/pages/base/warehouse/warehouseService";

export const FormBox = ({ modeKey, mode, rowCode, dispatch }) => {
    const {
        editFormVal, addFormVal, lookFormVal,
        updateFormVal,
        getStockCheckOne,
        insertDetail,
        editDetail,
        onComplete,
        updateList,
        editList,
        addList,
        lookList,
        onFinalAudit,saveBtnLoading
    } = useStockCheckDetailsModel();
    const { whDs } = useGlobalModel(({ whDs }) => [whDs]);
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => [])
    const formRef = useRef()
    const formVal = mode === "edit" ? editFormVal : mode === "look" ? lookFormVal : addFormVal;
    const materialList=mode === "edit" ? editList : mode === "look" ? lookList : addList;
    //货位实时数据
    const [shelfDs, setShelfDs] = useState([]);

    //编辑时， 没有缓存调用详情接口
    useEffect(() => {
        if (mode === "edit" || mode === "look") {
            getStockCheckOne(rowCode, mode)
        }
        return () => {
            updateFormVal(mode);
            updateList(mode)
        }
    }, [rowCode])

    //关闭当前页
    const onClose = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "stockCheck",
        })
    }

    const onChange = (changedValues) => {
        updateFormVal(mode, changedValues);
    }

    const reset = () => {
        getStockCheckOne(rowCode, mode).then(res => {
            message.success("刷新成功")
        })
    }

    //表单提交
    const onSubmit = debounce(async () => {
       
        formRef.current.validateFields().then((val) => {
            let data = {
                ...val,
                shelfCode: val.shelfCode.toString()
            }
            let title=materialList.filter(item=>{return item.inventoryOccupied>0})
           
            if(title.length!==0){
            Modal.confirm({
                title: "温馨提醒！",
                content: "该盘库单存在占用库存商品，建议释放占用后进行盘库；如未释放，盘库结果可能会对单据及数据产生差异，请谨慎处理",
                okText: "继续",
                cancelText: "取消",
                onOk: () => {
                  

            if (mode === "edit") {
                editDetail(data).then(res => {
                    res && getStockCheckOne(rowCode, mode)
                })
            }
            else {
                insertDetail(data).then(res => {
                    res && onClose()
                })
            }
        },
        //打开新页签重新生成
        onCancel:() => { }
    })}else{
            if (mode === "edit") {
                editDetail(data).then(res => {
                    res && getStockCheckOne(rowCode, mode)
                })
            }
            else {
                insertDetail(data).then(res => {
                    res && onClose()
                })
            }
        }
        }).catch((err) => {
            message.warning(getFormValidatorErrText(err))
        })
    }, 900)

    //仓库选择 置空货位
    const warehousChange = async (value, option) => {
        if (option.value) {
            updateFormVal(mode, { warehouseName: option.label, warehouseCode: option.value, shelfCode: [] });
            getShelfList(value)
        }
    }

    //根据仓库code 获取货位列表
    const getShelfList = async (code) => {
        let params = {
            pageSize: 10000,
            pageNum: 1,
            warehouseCode: code,
        }
        const { retData } = await warehouseService.shelfAllPage(params)
        setShelfDs(retData.map(item => ({
            id: item.id,
            label: item.code,
            value: item.code
        })))
    }

    const baseFormItem = {
        title: "基本信息",
        style: { padding: "0 20px", marginBottom: "10px" },
        contentClass: "pr-77",
        items: [
            {
                type: "Input",
                fieldLabel: "盘库单号",
                disable: true,
                fieldName: "stocktakingCode",
            }, {
                type: "Input",
                fieldLabel: "盘点结果",
                fieldName: "result",
                disable: true,
                // options: [{ label: "盘盈", value: "盘盈" }, { label: "盘亏", value: "盘亏" }, { label: "相符", value: "相符" }],
            }, {
                type: "Select",
                fieldLabel: "盘点仓库",
                fieldName: "warehouseCode",
                onSelectChange: warehousChange,
                options: whDs,
                rules: [{ required: true, message: "请选择盘点仓库" }]
            }, {
                type: "Select",
                fieldLabel: "盘点货位",
                fieldName: "shelfCode",
                selectMode: "multiple",
                options: shelfDs,
            }, {
                type: "Select",
                fieldLabel: "盘点类型",
                options: [{ label: "盘点单", value: "trade-type.stocktaking.stt" }, { label: "期初单", value: "trade-type.stocktaking.init" }],
                fieldName: "type",
            }, {
                type: "Datepicker",
                fieldLabel: "开始时间",
                showTime: true,
                fieldName: "startTime",
            }, {
                type: "Datepicker",
                fieldLabel: "结束时间",
                showTime: true,
                fieldName: "endTime",
            }, {
                type: "Input",
                fieldLabel: "制单人",
                disable: true,
                fieldName: "createUser",
            }, {
                type: "Input",
                fieldLabel: "制单时间",
                disable: true,
                fieldName: "createTime",
            }, {
                type: "Select",
                fieldLabel: "盘点状态",
                disable: true,
                fieldName: "status",
                options: getSelectedDictOne(DictCodeEnmu.STOCKTAKING),
            }, {
                type: "Input",
                fieldLabel: "盘库时长",
                fieldName: "duration",
                disable: true,
            }, {
                type: "Input",
                fieldLabel: "备注",
                fieldName: "note",
            }, {
                type: "Input",
                fieldLabel: "所属机构",
                fieldName: "shopName",
            }, {
                type: "Input",
                fieldLabel: "仓库名称",
                fieldName: "warehouseName",
                hidden: true
            }
        ]
    };


    const action = <>
        <Button
            className="btn-item"
            shape="round"
            onClick={onComplete}
            disabled={formVal.status ===  "stocktaking.audit"  || formVal.status === DictCodeEnmu.STOCKTAKING_COMPLETE}
            hidden={mode !== "edit"}
        >盘点完成</Button>
        <Button
            className="btn-item"
            shape="round"
            disabled={formVal.status ===  "stocktaking.audit" || formVal.status === DictCodeEnmu.STOCKTAKING_CREATE} 
            onClick={onFinalAudit}
            hidden={mode !== "edit"}
        >审核</Button>
        <Button
            className="btn-item"
            shape="round"
            disabled={formVal.status ===  "stocktaking.audit"}
            onClick={onSubmit}
            loading={saveBtnLoading}
            hidden={mode === "look"}
        >{saveBtnLoading ? '保存中' : '保存'}</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => reset()}
            hidden={mode === "add"}
        >刷新</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={onClose}
        >返回</Button>
    </>

    const rightChildren = <>

    </>
    const fromConfig = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        formValues: formVal,
        disabled: mode === "look",
        onChange,
        boxs: [baseFormItem],
    }



    const orderStatu = [
        {
            label: "盘点单号",
            value: formVal.stocktakingCode ?? "暂无",
            color: "blue",
            status: true
        },
        {
            label: "状态",
            value: getTreeNodeName(formVal.status),
            color: "green",
            status: true
        }
    ]

    return (
        <>
            <YhBox title={orderStatu.map((item, index) => <Description key={index} {...item} />)}
                style={{ padding: "0 20px", marginBottom: "10px" }}
                action={action}
            />
            <YhFormBox {...fromConfig} />
        </>
    )
}
