import { useState, useEffect, useRef } from 'react'
import { getPcodeDict } from '@/utils/dataStorage'
import { organPage } from '@/service/orgService'

import { getTableColumns } from './table'
import { message } from 'antd'
import { getOrderList } from '@/service/ordersService'
/**
 * 不同类型的订单状态
 */
const activeMenuOrderStatus = {
    ordersManage: ['pending_labor', 'pending_departure', 'already_departure', 'in_maintenance', 'tobe_confirmed', 'system_review_fail'],
    allOrder: [],
    pendingOrder: ['pending_order'],
    paymentOrder: ['pending_payment'],
    reviewOrder: ['pending_system_review'],
    cancelleOrder: ['cancelled'],
    completeOrder: ['completed', 'organ_transferred'],
}

/**
 * 计算订单title附近的文字
 * @param {*} data
 * @param {*} setSelectInfo
 * @returns
 */
export const useOrderTableChange = function (data, setSelectInfo) {
    useEffect(() => {
        setSubTitle(['工时费:0.00元', '预付款:0.00元', '材料费:0.00元', '应收金额:0.00元', '优惠金额:0.00元', '实收金额:0.00元'])
        setSelectedRowKeys([])
    }, [data])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [subTitle, setSubTitle] = useState(['工时费:0.00元', '预付款:0.00元', '材料费:0.00元', '应收金额:0.00元', '优惠金额:0.00元', '实收金额:0.00元'])
    const onSelectChange = (newSelectedRowKeys, rowInfos) => {
        if (newSelectedRowKeys.length === 1) {
            setSelectInfo({
                rowInfo: rowInfos[0],
                rowIndex: data.findIndex((item) => item.id === newSelectedRowKeys[0]),
            })
        } else {
            setSelectInfo({
                rowInfo: [],
                rowIndex: -1,
            })
        }
        setSelectedRowKeys(newSelectedRowKeys)
        let itemActualAmount = 0,
            prepaymentsAmount = 0,
            orderMaintainMaterialAmount = 0,
            orderTotalAmount = 0,
            orderPreferentialAmount = 0,
            orderActualAmount = 0
        rowInfos.forEach((item) => {
            itemActualAmount += item.itemActualAmount
            prepaymentsAmount += item.prepaymentsAmount
            orderMaintainMaterialAmount += item.orderMaintainMaterialAmount
            orderTotalAmount += item.orderTotalAmount
            orderPreferentialAmount += item.orderPreferentialAmount
            orderActualAmount += item.orderActualAmount
        })
        setSubTitle({
            itemActualAmount,
            prepaymentsAmount,
            orderMaintainMaterialAmount,
            orderTotalAmount,
            orderPreferentialAmount,
            orderActualAmount,
        })
        setSubTitle([
            `工时费:${itemActualAmount.toFixed(2)}元`,
            `预付款:${prepaymentsAmount.toFixed(2)}元`,
            `材料费:${orderMaintainMaterialAmount.toFixed(2)}元`,
            `应收金额:${orderTotalAmount.toFixed(2)}元`,
            `优惠金额:${orderPreferentialAmount.toFixed(2)}元`,
            `实收金额:${orderActualAmount.toFixed(2)}元`,
        ])
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }
    return [subTitle, rowSelection]
}

/**
 * 初始化
 * @param {*} setShowSanbao
 * @param {*} setChoosedOrderId
 * @param {*} setShowLogs
 * @param {*} saveKey
 * @param {*} handleClickOrderCode
 */
export const useInit = function (tableColumnsParam) {
    const dictObj = useRef({})
    const [org, setOrg] = useState([])
    const [allOrg, setAllOrg] = useState([])
    const [columns, setColumns] = useState([])

    useEffect(() => {
        initDict()
        getOrganPage()
        initTableColumns()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //初始下工单用到的字典
    const initDict = () => {
        let obj = {
            orderSource: {}, //工单来源
            orderType: {}, //工单类型
            serviceMethod: {}, //服务方式
            orderStatus: {}, //工单状态
            threeGuaranteesUnit: {}, //三包品牌
            faultClassification: {},
            serviceType: {},
        }
        getPcodeDict('008').children.forEach((item) => {
            obj.orderSource[item.dictCode] = item.title
        })
        getPcodeDict('014').children.forEach((item) => {
            obj.orderType[item.dictCode] = item.title
        })
        getPcodeDict('012').children.forEach((item) => {
            obj.serviceMethod[item.dictCode] = item.title
        })
        getPcodeDict('106').children.forEach((item) => {
            obj.threeGuaranteesUnit[item.dictCode] = item.title
        })
        getPcodeDict('order_status').children.forEach((item) => {
            obj.orderStatus[item.dictCode] = item.title
        })
        getPcodeDict('011').children.forEach((item) => {
            obj.serviceType[item.dictCode] = item.title
        })
        dictObj.current = obj
    }

    //获取机构列表
    const getOrganPage = () => {
        organPage({
            searchItem: {
                orderFlag: 1,
            },
            pageNum: 1,
            pageSize: 100,
            orders: [
                {
                    column: 'createTime',
                    rule: 'DESC',
                },
            ],
        }).then((res) => {
            setOrg(res.retData.list)
        })
        organPage({
            searchItem: {
                orderFlag: 1,
                showPlatformOrgan: true,
            },
            pageNum: 1,
            pageSize: 100,
            orders: [
                {
                    column: 'createTime',
                    rule: 'DESC',
                },
            ],
        }).then((res) => {
            setAllOrg(res.retData.list)
        })
    }

    const initTableColumns = async (changedColumns) => {
        if (dictObj.current) {
            let newColumns = await getTableColumns(
                {
                    dictObj,
                    ...tableColumnsParam,
                },
                changedColumns
            )
            setColumns(newColumns)
        } else {
            setTimeout(() => {
                initTableColumns()
            }, 500)
        }
    }
    return [dictObj, org, allOrg, columns, initTableColumns]
}

/**
 * 用户点击订单编号
 * @param {*} state
 * @param {*} selectInfo
 * @param {*} dispatch
 * @param {*} detailsPage
 * @param {*} setPrintVisible
 * @param {*} setOrderPayInfo
 * @param {*} dictObj
 * @returns
 */
export const useOrderCodeClick = function (state, parms) {
    const [isOrderCodeClick, setIsOrderCodeClick] = useState(false)
    useEffect(() => {
        if (isOrderCodeClick) {
            const status = state.activeMenu === 'allOrder' || state.activeMenu === 'cancelleOrder' || state.activeMenu === 'completeOrder' ? 'look' : 'edit'
            orderHandle(status, state.activeMenu, parms)
            setIsOrderCodeClick(false)
        }
    }, [isOrderCodeClick]) // eslint-disable-line react-hooks/exhaustive-deps
    return [setIsOrderCodeClick]
}

//新增 查看 编辑
export const orderHandle = async (mode, activeMenu, parms) => {
    const { selectInfo, dispatch, detailsPage, setPrintVisible, setOrderPayInfo, dictObj } = parms
    let tabName = {
        insert: '工单新增',
        edit: '工单编辑',
        look: '工单查看',
    }
    let modeKey = activeMenu+"/" + mode
    if (mode === 'edit') {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning('请选择你要编辑的工单')
            return
        }
        if (selectInfo.rowInfo.orderStatus === 'cancelled' || selectInfo.rowInfo.orderStatus === 'completed') {
            message.warning(`已${selectInfo.rowInfo.orderStatus === 'cancelled' ? '作废' : '完成'}的工单不可再次编辑`)
            return
        }
        modeKey += '/' + selectInfo.rowInfo.id
    } else if (mode === 'look') {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning('请选择你要查看的工单')
            return
        }
        console.log('modeKey')
        console.log(modeKey)
        //每次查看把之前的删除， 重新生成
        await dispatch({
            type: 'removeTabs',
            targetKey: modeKey,
        })
    }
    await dispatch({
        type: 'changeTabs',
        name: tabName[mode],
        activeMenu: modeKey,
        content: detailsPage.OrderDetails({
            modeKey,
            mode,
            rowInfo: selectInfo.rowInfo,
            setPrintVisible: (bool) => setPrintVisible(bool),
            setOrderPayInfo: (obj) => setOrderPayInfo(obj),
            orderStatus: dictObj.current.orderStatus,
            parentModeKey: activeMenu
        }),
    })
}

/**
 *
 * @param {*} state 订单状态
 * @returns
 */
export const useOrderTable = function (state) {
    const [data, setData] = useState([]) //默认展示数据
    const [selectInfo, setSelectInfo] = useState({
        rowInfo: {},
        rowIndex: -1,
    }) //记录当前行下标
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [loading, setLoading] = useState(false) //表格 loading
    const [searchValue, setSearchValue] = useState({}) //搜索
    const order = useRef({ column: "createTime", rule: "DESC" })

    //默认加载数据列表
    const loadData = (current, search = {}) => {
        setLoading(true)
        let data = {
            searchItem: {
                ...search,
                orderStatus: search.orderStatus ? [search.orderStatus] : activeMenuOrderStatus[state.activeMenu],
            },
            startTime: search.startTime ? search.startTime : null,
            endTime: search.endTime ? search.endTime : null,
            pageNum: current,
            pageSize: 10,
            orders: [
                order.current
            ],
        }
        getOrderList(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({
                rowInfo: {},
                rowIndex: -1,
            })
            setData(res.retData.list)
            setLoading(false)
        })
    }

    //分页事件 置空当前工单
    const onTableChange = async (page, filters, sorter) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        let orderC = { column: "createTime", rule: "DESC" }
        if (sorter && sorter.field && sorter.order) {
            let rule = sorter.order === "ascend" ? "ASC" : "DESC"
            orderC = {
                column: sorter.field,
                rule,
            }
        }
        order.current = orderC
        loadData(page.current, searchValue)
    }
    return [data, selectInfo, setSelectInfo, pagination, loading, loadData, searchValue, setSearchValue, onTableChange]
}
