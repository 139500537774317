import React, { useContext, useState, useEffect } from "react"
import { MyContext } from "@/store/myContext"
import { Table, Space, Button, Tag } from "antd"
import { getMenuTree } from "@/service/menuService.js"
import MenuEdit from "./menuEdit"
import { menuStatusEnum, menuTypeEnum } from "@/config/Constants"

/**
 * 菜单管理
 */
export default () => {
    const { state, message } = useContext(MyContext)
    const [editVisible, setEditVisible] = useState(false)
    const [datasource, setDataSource] = useState([])
    const [selectInfo, setSelectInfo] = useState({}) //记录列表当前行下标及当前行信息
    const [editId, setEditId] = useState(null)
    const [status, setStatus] = useState("")
    const columns = [
        {
            title: "菜单名称",
            dataIndex: "name",
            key: "name",
            width: "20%",
        },
        {
            title: "菜单类型",
            dataIndex: "type",
            key: "type",
            render: (tag) => <Tag key={tag}>{menuTypeEnum[tag]}</Tag>,
        },
        {
            title: "菜单状态",
            dataIndex: "menuStatus",
            key: "menuStatus",
            render: (tag) => <Tag key={tag}>{menuStatusEnum[tag]}</Tag>,
        },
        {
            title: "权限标识",
            dataIndex: "perms",
            key: "perms",
        },
        {
            title: "前端跳转URL",
            dataIndex: "path",
            key: "path",
        },
        {
            title: "菜单组件",
            dataIndex: "component",
            key: "component",
        },

        {
            title: "图标",
            dataIndex: "icon",
            key: "icon",
        },

        {
            title: "排序",
            dataIndex: "sort",
            key: "sort",
        },
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            width: "3%",
        },
    ]

    useEffect(() => {
        if (state.activeMenu === "menuManage") {
            loadData()
        }
    }, [state.activeMenu])

    const builderTree = (treeData) => {
        if (!treeData) {
            return
        }
        treeData.map((i) => {
            let children = i.children
            if (!children || children.length == 0) {
                delete i.children
            } else {
                builderTree(children)
            }
        })
    }

    //选择行
    const selectRow = async (record) => {
        await setSelectInfo({ ...record })
        await setEditId(record.id)
    }

    //列表数据
    const loadData = (search = {}) => {
        let data = { ...search }
        getMenuTree(data)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    let treeData = res.retData
                    builderTree(treeData)
                    setDataSource(treeData)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }

    // rowSelection objects indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows)
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows)
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows)
        },
    }

    return (
        <>
            <MenuEdit status={status} editId={editId} visible={editVisible} onLoadData={loadData} onCancel={(bool) => setEditVisible(bool)} />

            <Space align="center" style={{ marginBottom: 16 }}>
                <Button
                    shape="round"
                    className="but-left"
                    onClick={() => {
                        setStatus("add")
                        setEditId(null)
                        setEditVisible(true)
                    }}
                >
                    新增
                </Button>
                <Button
                    shape="round"
                    className="but-left"
                    onClick={() => {
                        setStatus("edit")
                        setEditVisible(true)
                    }}
                >
                    编辑
                </Button>
            </Space>

            <Table
                columns={columns}
                indentSize={30}
                rowKey={"id"}
                dataSource={datasource}
                onRow={(record, index) => ({
                    onClick: () => selectRow(record),
                })}
                rowClassName={(record, index) => {
                    let className = ""

                    if (record.id === selectInfo.id) className = "dark-row "
                    return className
                }}
            />
        </>
    )
}
