import React, { useEffect, useReducer, useState } from 'react'
import { Switch as RouterSweitch, Route, Redirect } from 'react-router-dom'
import { message, Switch, Select, Input, Modal, Tooltip, Button, notification } from 'antd'
import { isLogin } from './utils/dataStorage'
import LoadableComponent from './utils/LoadableComponent'
import { getUserOrgan, getToken } from '@/utils/dataStorage'
//挂载全局状态
import { MyContext } from './store/myContext'
import { reducer, initialState } from './store'
import { tabs, menu, detailsPage } from './pages/tabs'
import { UploadFile } from './uploadFile'
import { internalMessageCurrent, internalMessageRead } from '@/service/message'
import Storage from 'good-storage'

const { Option } = Select
const Index = LoadableComponent(import('./pages/Index'), true)
const Login = LoadableComponent(import('./pages/Login'), true)

//域名解析 test1
function urlParse(url) {
    let obj = {}
    /*
     * 正则匹配
     * [?&]:匹配?或&其一
     * [^?&]:非?或非&
     * */
    let reg = /[?&][^?&]+=[^?&]+/g
    let arr = url.match(reg)
    if (arr) {
        arr.forEach((item) => {
            let tempArr = item.substring(1).split('=')
            let key = tempArr[0]
            let val = tempArr[1]
            obj[key] = val
        })
    }
    return obj
}

const App = (props) => {
    const [showAllOrgan, setShowAllOrgan] = useState(false)
    useEffect(() => {
        let showAllOrgan = getUserOrgan()?.organType === 3 ? true : false
        setShowAllOrgan(showAllOrgan)
        if (process.env.REACT_APP_MODE !== 'pro' && document.URL !== 'http://test.app.yunhaochefu.com/#/uploadFile') {
            document.title = '云创测试'
        } else if (process.env.REACT_APP_MODE !== 'pro' && document.URL === 'http://test.app.yunhaochefu.com/#/uploadFile') {
            // http://test.app.yunhaochefu.com/#/uploadFile
            document.title = '资料上传'
        } else if (process.env.REACT_APP_MODE === 'pro' && document.URL === 'https://app.yunhaochefu.com/#/uploadFile') {
            document.title = '资料上传'
        }
        
      
    }, [])
    const [state, dispatch] = useReducer(reducer, initialState)
    useEffect(() => {
        getInternalMessageCurrent()
        let interval = setInterval(() => {
            getInternalMessageCurrent()
        }, 30000)
        return () => {
            clearInterval(interval)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getInternalMessageCurrent = async () => {
        if (!getToken()) {
            return
        }
        internalMessageCurrent().then(({ retData }) => {
            if (retData && retData.latestUnreadMessage) {
                const { id, content, attrs } = retData.latestUnreadMessage
                const latestUnreadMessageId = Storage.session.get('latestUnreadMessageId')
                if (latestUnreadMessageId === id) {
                    return
                }
                Storage.session.set('latestUnreadMessageId', id)
                const orderInfo = attrs.find((item) => item.attrKey === 'orderId')
                if (!orderInfo) {
                    return
                }
                const key = `open${Date.now()}`
                const btn = (
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            internalMessageRead(id).then((res) => {
                                dispatch({
                                    type: 'changeTabs',
                                    activeMenu: 'pendingOrder',
                                    name: '待派单工单',
                                })
                                notification.close(key)
                            })
                        }}
                    >
                        去处理
                    </Button>
                )
                notification.open({
                    message: '订单通知',
                    description: (
                        <div>
                            {content.split('\n').map((item) => (
                                <p style={{ marginBottom: 0 }}>{item}</p>
                            ))}
                        </div>
                    ),
                    btn,
                    key,
                    placement: 'bottomRight',
                    duration: 5,
                    onClose: () => {
                        notification.close(key)
                    },
                })
            }
        })
    }

    return (
        <MyContext.Provider
            value={{
                state,
                dispatch,
                history: props,
                tabs,
                menu,
                detailsPage,
                message,
                Switch,
                Option,
                Input,
                Modal,
                Tooltip,
                showAllOrgan,
            }}
        >
            {/* <Button type="primary" onClick={openNotification}>
                Open the notification box
            </Button> */}
            <RouterSweitch>
                <Route
                    path="/login"
                    render={(props) => {
                        return <Login {...props} setShowAllOrgan={setShowAllOrgan} />
                    }}
                />
                <Route path="/uploadFile" component={() => UploadFile()} />
                {/* <Redirect
					exact
					from="/"
					to={`/${menu[1].key}`}
				/> */}
                <Route
                    path="/"
                    render={(props) => {
                        return isLogin() ? <Index {...props} /> : <Redirect to="/login" />
                    }}
                />
            </RouterSweitch>
        </MyContext.Provider>
    )
}

export default App
