import React, { useContext } from "react";
import { Button, message, Modal, Input } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { usePurchaseAdvancedModel } from "../purchaseAdvancedModel";
import { DataTable } from "./DataTable";
import { MyContext } from "@/store/myContext";
import { formatNum } from "@/erp_subpackage/utils/util";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";

export const DataBox = () => {
    const { selectedRowKeys, descTotal, rowsDescTotal, searchParam, shopId, setPrint, exportList, invalidate, exportDetail } = usePurchaseAdvancedModel();
    const { state, dispatch, detailsPage } = useContext(MyContext);
    const { user: { organNature} } = useGlobalModel()

    //新增 查看 编辑
    const goPageNav = (mode, rowCode) => {
        let tabName = {
            add: "采购平台新增",
            edit: "采购平台编辑",
            look: "采购平台查看",
        }
        let modeKey = "purchaseAdvanced/" + mode;

        //动态传入要删除的nav key
        const handleDispatch = (obj = {}) => {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.PurchaseAdvancedDetails({
                    modeKey,
                    mode,
                    dispatch,
                    rowCode: rowCode ?? selectedRowKeys[0]?.advancedCode
                }),
            })
        }

        if (mode === "edit") {
            const findNav = state.panes.find(item => item.key === modeKey);
            if (findNav) {
                Modal.confirm({
                    title: '温馨提醒！',
                    content: "您还有采购平台详情未保存，是否跳转",
                    okText: '跳转未保存页',
                    cancelText: '打开新页签',
                    onOk: () => {
                        handleDispatch()
                    },
                    //打开新页签重新生成
                    onCancel: () => {
                        handleDispatch({ deleteKey: modeKey })
                    }
                });
                return;
            }
            handleDispatch()
        }
        else if (mode === "add") {
            handleDispatch()
        }
        else if (mode === "look") {
            handleDispatch({ deleteKey: modeKey })
        }
    }

    //作废
    const cancellation = () => {
        if (selectedRowKeys.length !== 1) {
            message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！")
            return;
        }

        let cancelReason = ""
        Modal.confirm({
            title: '采购平台作废',
            type: "error",
            content: <><span>请输入作废原因</span>
                <Input onChange={(e) => { cancelReason = e.target.value }} /></>,
            okText: '确定',
            cancelText: '取消',
            onOk: () => new Promise((resolve, reject) => {
                if (cancelReason) {
                    resolve(cancelReason);
                } else {
                    message.warning("请输入作废原因");
                    reject('请输入作废原因');
                }
            }).then(res => {
                invalidate(res);
            }),
        });
    }
    const action = <>
        {organNature==3&&<Button
            className="btn-item"
            shape="round"
            onClick={() => {
                goPageNav("add")
            }}
            disabled={organNature!=3}
        >新增</Button>}
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (selectedRowKeys.length !== 1) {
                    message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！")
                    return;
                }
                // if (selectedRowKeys[0].status === DictCodeEnmu.PO_CHANGE) {
                //     message.warning("已作废的单据不可以进行编辑");
                //     return;
                // }
                // if (selectedRowKeys[0].transferOrder) {
                //     message.warning("调拨单据不可以编辑")
                //     return
                // }
                // if (selectedRowKeys[0].status === DictCodeEnmu.PO_BILL || selectedRowKeys[0].status === DictCodeEnmu.PO_SETTLE) {
                //     message.warning("已结算的单据不可以进行编辑");
                //     return;
                // }
                goPageNav("edit")
            }}
            disabled={searchParam.shopId === "" || (searchParam.shopId && searchParam.shopId !== shopId)}
        >编辑</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (selectedRowKeys.length !== 1) {
                    message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！")
                    return;
                }
                goPageNav("look")
            }}
        >查看</Button>
        {/* <Button
            className="btn-item"
            shape="round"
            onClick={() => exportList()}
        >导出</Button> */}
        {/* <Button
            className="btn-item"
            shape="round"
            onClick={() => exportDetail()}
        >导出明细</Button> */}
        {/* <Button
            className="btn-item"
            shape="round"
            onClick={cancellation}
            disabled={searchParam.shopId === "" || (searchParam.shopId && searchParam.shopId !== shopId)}
        >作废</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                setPrint(true)
            }}
        >打印</Button> */}
    </>;

    const desc = [
        {
            label: "采购数量",
            // value: !selectedRowKeys.length ? descTotal.totalNum : rowsDescTotal.totalNum,
            value:0,
            color: "red"
        },
        {
            label: "采购金额",
            // value: !selectedRowKeys.length ? descTotal.totalAmount : formatNum(rowsDescTotal.totalAmount),
            value:0,
            color: "red"
        },
        {
            label: "已付金额",
            // value: !selectedRowKeys.length ? descTotal.paidAmount : formatNum(rowsDescTotal.paidAmount),
            value:0,
            color: "red"
        }
    ]

    return (
        <YhBox title="采购平台列表" action={action} descriptions={desc}>
            <DataTable goPageNav={goPageNav} />
        </YhBox>
    )
}

