import React, { useState, useEffect } from "react"
import { Input, Button, Table, message, Tooltip } from "antd"
import Drawer from "@/components/Drawer"
import { objectValueAllEmpty } from "@/utils/util"
import { YhBut } from "@/erp_subpackage/components/YhButton"
import { combinationList } from "@/service/dataService"
export default ({ visible, onClose, onItemOk }) => {
    const [searchValue, setSearchValue] = useState({})
    const [addData, setAddData] = useState([]) //搜索数据
    const [selectItem, setSelectItem] = useState([]) //选中的项目 或者 物料
    const [loading, setLoading] = useState(false)

    //回车事件
    const handEnter = (e) => {
        if (e.nativeEvent.keyCode === 13) {
            if (objectValueAllEmpty(searchValue)) {
                message.warning(`请输入你要搜索的维修配件`)
                return
            }
            loadData(30)
        }
    }

    //弹框关闭 清空数据
    useEffect(() => {
        if (!visible) {
            setSelectItem([])
            setAddData([])
            setSearchValue({})
            loadData(100)
        }
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    //项目 新增数据列表
    const loadData = (pageSize = 20, search = searchValue) => {
        setLoading(true)
        let api = combinationList

        let data = {
            pageSize: pageSize,
            pageNum: 1,
            searchItem: { ...search },
        }
        api(data)
            .then((res) => {
                if (res && res.retCode === 200) {
                    let results = res.retData
                    if (results?.list?.length > 0) {
                        setAddData(results.list)
                    } else {
                        message.warning(`没有匹配您要搜索的维修组合`)
                    }
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    //不为选中或者删除的时候
    const deleteItem = (id) => {
        let arr = [...selectItem]
        let newArr = arr.filter((item) => item.id !== id)
        setSelectItem(newArr)
    }

    //新增项目的table配置
    const addColumns = [
        {
            title: "组合名称",
            width: 120,
            dataIndex: "name",
            fixed: "left",
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                    <p className="one-line">{text}</p>
                </Tooltip>
            ),
        },
        {
            title: "间隔里程",
            align: "center",
            dataIndex: "intervalMileage",
        },
        {
            title: "预警里程",
            align: "center",
            dataIndex: "earlyWarningMileage",
        },
        {
            title: "维修项目工时费用",
            align: "center",
            dataIndex: "itemAmount",
        },
        {
            title: "操作",
            width: 60,
            fixed: "right",
            align: "center",
            render: (text, record) => {
                return <YhBut type="add" txt="添加" click={() => add(record)} />
            },
        },
    ]

    const add = (record) => {
        let findArr = selectItem.find((item) => item.id === record.id)
        if (findArr) {
            let filterArr = selectItem.filter((item) => item.id !== findArr.id)
            setSelectItem(filterArr)
        } else {
            setSelectItem((origin) => [...origin, record])
        }
    }
    return (
        <Drawer visible={visible} onClose={() => onClose(false)} zIndex={1001}>
            <div className="team-drawer-title">
                <span>维保组合</span>
            </div>
            <div className="team-drawer-content">
                <div className="drawer-search-box">
                    <span>组合名称:</span>
                    <Input
                        className="search-input1"
                        allowClear
                        placeholder="请输如名称"
                        value={searchValue.name}
                        onChange={(e) =>
                            setSearchValue({
                                ...searchValue,
                                name: e.target.value,
                            })
                        }
                        onKeyPress={handEnter}
                    />
                    <Button className="search-but1" onClick={() => loadData(100)}>
                        查询
                    </Button>
                </div>
                <Table
                    size="small"
                    rowKey={(record) => record.id}
                    columns={addColumns}
                    dataSource={addData}
                    pagination={false}
                    scroll={{ y: 360 }}
                    loading={loading}
                />
                <div className="team-form-footer">
                    <Button onClick={() => onClose(false)} className="team-but-one">
                        取消
                    </Button>
                    <Button
                        className="team-but-two"
                        onClick={() => {
                            onItemOk(selectItem)
                        }}
                    >
                        提交
                    </Button>
                </div>
                <div className="select-item-box">
                    <h4>已添加维修组合</h4>
                    <ul>
                        {selectItem &&
                            selectItem.map((item) => {
                                return (
                                    <li key={item.id}>
                                        <span>{item.name}</span>
                                        <span onClick={() => deleteItem(item.id)}>删除</span>
                                    </li>
                                )
                            })}
                    </ul>
                </div>
            </div>
        </Drawer>
    )
}
