import React from "react";
import { FormBox } from "./FormBox";
import { TopButs } from "./TopButs";
import { TableBox } from "./TableBox";
import { FixedBottom } from "./FixedBottoms";
import { MaterialDialog } from "./MaterialDialog";
import { TransferSaleDrawer } from "./TransferSaleDrawer";
import { SettleDrawer } from "./SettleDrawer";
import { WhConfirmDrawer } from "./WhConfirmDrawer";
import { StockDialog } from "./StockDialog";

export default (props) => {
    const { mode } = props;
    return (
        <div className="yh-detail-box">
             {mode !== "add" && <TopButs {...props} />}
            <FormBox {...props} />
            <TableBox {...props} />
            <FixedBottom {...props} />
            <MaterialDialog {...props} />
            <TransferSaleDrawer {...props}/>
            <SettleDrawer {...props}/>
            <WhConfirmDrawer {...props}/>
            <StockDialog {...props} />
        </div>
    )
}