import { useState} from "react";
import { useMap } from 'ahooks';
import { createModel } from "hox";
import { message } from "antd"
import { prebookService } from "./prebookService";
import { debounce } from "lodash"
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import moment from "moment"

export const usePrebookModel = createModel(function(){

    const {user: { shopId, organId }} = useGlobalModel();
    const [activeMenu, setActiveMenu] = useState(null);
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [prebookList, setPrebookList] = useState([]) //列表
    const [pagination, setpagination] = useState({total: 0, current: 1, pageSize: 10});
    const [staticInfo, setStaticInfo] = useState({quantity: 0, totalAmount: 0, turnoverAmount: 0});
    //订单类型：用于详情页收费(维修项目、维修物料)和三包信息展示判断
    const [orderType, {  set: setOrderType, setAll: setAllOrderType, remove:removeOrderType, reset:resetOrderType, get:getOrderType }] = useMap([]);
    //表单数据
    const [info, {  set: setInfo, setAll: setAllInfo, remove:removeInfo, reset:resetInfo, get:getInfo }] = useMap([]);
    //维修项目列表
    const [itemList, {  set: setItemList, setAll: setAllItemList, remove:removeItemList, reset:resetItemList, get:getItemList }] = useMap([]);
    //维修物料列表
    const [materialList, {  set: setMaterialList, setAll: setAllMaterialList, remove:removeMaterialList, reset:resetMaterialList, get:getMaterialList }] = useMap([]);
    //三包信息
    const [threeGuaranteesList, {  set: setThreeGuaranteesList, setAll: setAllThreeGuaranteesList, remove:removeThreeGuaranteesList, reset:resetThreeGuaranteesList, get:getThreeGuaranteesList }] = useMap([]);
    const [threeGuaranteeId, setThreeGuaranteeId] = useState("");
    //转工单弹窗
    const [convertVisible, setConvertVisible] = useState(false);
    const [convertLoading, setConvertLoading] = useState({ spinning: false, tip: "操作中...",  size:"large" });
    const [printVisible, setPrintVisible] = useState({
        visible: false,
        orderId: '',
    }) //打印
    const [printSettleScaniaVisible, setPrintSettleScaniaVisible] = useState({
        visible: false,
        orderId: '',
    })
    const [printSnkyVisible, setPrintSnkyVisible] = useState({
        visible: false,
        orderId: '',
    })
    //列表数据
    const loadPrebookList = debounce((current, search = {}, active) => {
        active = active ?? activeMenu;
        let prebookStatus = active == 'preBookNew' ? 0 : active == 'preBookConvert' ? 1 : 2 ;
        let data = {
            searchItem: { ...search, 
                "shopId": shopId,
                "prebookStatus": prebookStatus
            },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },
            ],
        };
        prebookService.page(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setPrebookList(res.retData.list)
        });

        //统计数据
        prebookService.prebookStatic(data).then((res) => {
            setStaticInfo(res.retData);
        });
    }, 500);

    //详情
    const getPrebookOne = async (mode, id) => {
        let { retData } = await prebookService.one(id);
        let newRetData = {
            ...retData,
            entryFactoryTime: retData.entryFactoryTime ? moment(retData.entryFactoryTime) : null,
            outFactoryTime: retData.outFactoryTime ? moment(retData.outFactoryTime) : null,
            allRepair: retData.allRepair ? retData.allRepair : []
        }
        setInfo(mode, newRetData);
        setOrderType(mode, newRetData.orderType);
        setItemList(mode, retData.items);
        setMaterialList(mode, retData.materials);
        setThreeGuaranteesList(mode, retData.threeGuarantees);
        return newRetData;
    }

    const insertPrebook = async (mode, data, callback) => {
        let params = {
            ...data,
            organId: organId,
            entryFactoryTime: data.entryFactoryTime ? data.entryFactoryTime.format("YYYY-MM-DD HH:mm:ss") : null,
            outFactoryTime: data.outFactoryTime ? data.outFactoryTime.format("YYYY-MM-DD HH:mm:ss") : null,
            items: getItemList(mode),
            materials: getMaterialList(mode),
            threeGuarantees: getThreeGuaranteesList(mode),
        }
        let ret = await prebookService.insert(params);
        if (ret && ret.retData) {
            message.success("保存成功");
            callback();
        }
        else {
            message.error("保存失败");
        }
    }

    const updatePrebook = async (mode, data, id, callback) => {
        let params = {
            ...data,
            id: id,
            entryFactoryTime: data.entryFactoryTime ? data.entryFactoryTime.format("YYYY-MM-DD HH:mm:ss") : null,
            outFactoryTime: data.outFactoryTime ? data.outFactoryTime.format("YYYY-MM-DD HH:mm:ss") : null,
            items: getItemList(mode),
            materials: getMaterialList(mode),
            threeGuarantees: getThreeGuaranteesList(mode),
        }
        let ret = await prebookService.update(params);
        if (ret && ret.retData) {
            message.success("保存成功");
            callback();
        }
        else {
            message.error("保存失败");
        }
    }

    //转工单 提交
    const submitConvert = debounce((formRef) => {
        let mode = "edit";
        formRef.current.validateFields().then((val) => {
            //判断是否库存全部满足
            let notenough = 0;
            getMaterialList(mode).forEach(e => {
                notenough += e.availableStock < e.materialNumber ? 1 : 0;
            });
            if(notenough > 0){
                message.error("库存不足");
                return;
            }
            
            let items = getItemList(mode).map(item => {
                return {...item, 
                    id: null,
                    itemDiscount: item.itemDiscount == 0 ? null : item.itemDiscount
                }
            });

            //收费订单参数
            let orderParam = {
                "customerId": getInfo(mode).customerId, 
                "customerVehicleId": getInfo(mode).vehicleId, 
                "customerUserId": val.customerUserId, 
                "serviceMethod": val.serviceMethod, 
                "serviceType": val.serviceType, 
                "repairAddress": getInfo(mode).repairAddress, 
                "customerVehicleMileage": getInfo(mode).mileage, 
                "orderType": val.orderType, 
                "faultDesc": getInfo(mode).faultDesc, 
                "senderRepairUserName": getInfo(mode).senderRepairUserName, 
                "senderRepairUserPhone": getInfo(mode).senderRepairUserPhone, 
                "ciBusinessEntityId": getInfo(mode).entityCode, 
                "customerVehicleCode": getInfo(mode).vehicleCode, 
                "repairLng": getInfo(mode).repairLng, 
                "repairLat": getInfo(mode).repairLat, 
                "repairContactName": val.repairContactName, 
                "repairContactPhone": val.repairContactPhone, 
                "ciBusinessEntityName": getInfo(mode).entityName, 
                "entryFactoryTime": getInfo(mode).entryFactoryTime, 
                "outFactoryTime": getInfo(mode).outFactoryTime,  
                "operatingHours": getInfo(mode).workHours, 
                "tobeRepairedItems": getInfo(mode).tobeRepairedItems, 
                "remark": val.remark, 
                "maintainMaterials": [], 
                "items": val.orderType != '014002' ? items : [],
                "dispatchInfo": {
                    "teamId": val.teamId,
                    "teamUserIds": val.allRepair, 
                    "majorUserId": val.mainRepair,
                }
            };

            //领料单参数
            let maintainOrder = {
                "userId": val.mainRepair, 
                "prebook": true,
                "materialList": getMaterialList(mode).map(item => {
                    return {
                        ...item, 
                        inventoryDetailsId: item.purchaseDetailId,
                        num: item.materialNumber,
                        price: item.purchaseDetailPrice, 
                        lateSalePrice:item.salePrice, 
                    }
                })
            }

            //三包订单参数
            let threeGuarantees = getThreeGuaranteesList(mode).map(item => {
                return {
                    ...item, 
                    id: null,
                    "items": items.filter((e) => e.threeGuaranteeId == item.id)
                }
            })
            
            setConvertLoading({...convertLoading, spinning: true});
            
            prebookService.convertOrder({
                "prebookId": getInfo(mode).id,
                "organId": val.organId, 
                "order": orderParam,
                "maintainOrder": maintainOrder,
                "threeGuarantees": threeGuarantees
            }).then((res) => {
                if (res && res.retCode === 200) {
                    setConvertVisible();

                    setInfo(mode, {});
                    setItemList(mode, []);
                    setMaterialList(mode, []);
                    setThreeGuaranteesList(mode, []);
                    loadPrebookList();
                    message.success("操作成功");
                }
                else {
                    message.error("操作失败");
                }
            })
            .finally(() => {
                setConvertLoading({...convertLoading, spinning: false});
            });

        }).catch((err) => {
            message.error("操作失败")
            setConvertLoading({...convertLoading, spinning: false});
        })

    }, 1000)

    const guid = () => {
       return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    const handlePrint = (selectInfo, setPrintVisible) => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning('请选择你要打印的工单')
            return
        }
        setPrintVisible({ visible: true, orderId: selectInfo.rowInfo.id })
    }
    const handleSettleScaniaPrint = async (selectInfo, setPrintSettleScaniaVisible) => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning('请选择你要打印的工单')
            return
        }
        setPrintSettleScaniaVisible({ visible: true, orderId: selectInfo.rowInfo.id })
    }
    const snkyPrint = async (selectInfo, setPrintSnkyVisible,type=1) => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning('请选择你要打印的工单')
            return
        }
        setPrintSnkyVisible({ visible: true, orderId: selectInfo.rowInfo.id,type })
    }
    return {
        activeMenu, setActiveMenu,
        getOrderType, setOrderType,
        selectInfo, setSelectInfo,
        pagination, setpagination,
        prebookList, loadPrebookList,
        staticInfo, setStaticInfo,
        getInfo, setInfo,
        getItemList, setItemList,
        getMaterialList, setMaterialList,
        getThreeGuaranteesList, setThreeGuaranteesList,
        threeGuaranteeId, setThreeGuaranteeId,
        convertVisible, setConvertVisible,
        convertLoading, setConvertLoading,
        getPrebookOne, guid,
        insertPrebook, updatePrebook,
        submitConvert,handlePrint,snkyPrint,handleSettleScaniaPrint,
        printVisible, setPrintVisible,
        printSettleScaniaVisible, setPrintSettleScaniaVisible,
        printSnkyVisible, setPrintSnkyVisible
    }
})