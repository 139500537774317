import React from "react";
import { TreeSelect } from "antd";

export const SearchTreeSelect = (props) => {
    return <TreeSelect
        size={props.size}
        allowClear
        className="yh-search-select"
        value={props.searchValues?.[props.fieldName]}
        placeholder={props.placeholder}
        onChange={(value) => {
            props.onChange && props.onChange({ [props.fieldName]: value })
            if (props.triggerSearch){
                props.handleSearch && props.handleSearch({ [props.fieldName]: value });
            } 
        }}
        disabled={props.disabled}
        treeData={props.treeData}
        showSearch
        treeNodeFilterProp='title'
    />
}