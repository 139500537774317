import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";

// export interface IDetailList {
//     amount?: number,      // 0,
//     detailsId?: string,      // "",
//     id?: string,      // "",
//     materialCode?: string,      // "",
//     materialName?: string,      // "",
//     note?: string,      // "",
//     num?: number,      // 0,
//     preturnNum?: number,      // 0,
//     price?: number,      // 0,
//     purchaseCode?: string,      // "",
//     purchaseNum?: number,      // 0,
//     purchasePrice?: number,      // 0,
//     shelfCode?: string,      // "",
//     shelfId?: string,      // "",
//     warehouseCode?: string,      // "",
//     warehouseName?: string,      // ""
//     [key: string]: any
// }

// export interface IPurchaseRoFields {
//     bentityCode?: string,     // "",
//     bentityName?: string,     // "",
//     contact?: string,     // "",
//     createUser?: string,     // "",
//     id?: string,     // "",
//     invoiceTypeCode?: string,     // "",
//     invoiceTypeName?: string,     // "",
//     note?: string,     // "",
//     paidAmount?: number,     // 0,
//     payMethodName?: string,     // "",
//     payType?: string,     // "",
//     payableAmount?: number,     // 0,
//     phone?: string,     // "",
//     returnAddress?: string,     // "",
//     returnCode?: string,     // "",
//     returnReason?: string,     // "",
//     shopId?: string,     // "",
//     shopName?: string,     // "",
//     supplierCode?: string,     // "",
//     supplierName?: string,     // "",
//     supplierTypeCode?: string,     // "",
//     supplierTypeName?: string,     // "",
//     totalNum?: string,     // "",
//     status?:string,
//     createTime?:string,
//     fmsNote?:string
//     details?: IDetailList[] | null
//     [key:string] 
// }

// export interface IPurchaseRoStatistics{
//     totalNum: number,
//     totalAmount: number,
//     paidAmount:number
// }

// //采购退货商品商品详情
// export interface IPurchaseDetails {
//     amount?: number,   // number,
//     confirmNum?: number,   // number,
//     damageNum?: number,   // number,
//     id?: string,   // "",
//     inTotal?: number,   // number,
//     materialCode?: string,   // "",
//     materialName?: string,   // "",
//     note?: string,   // "",
//     num?: number,   // number,
//     preturnNum?: number,   // number,
//     price?: number,   // number,
//     purchaseCode?: string,   // "",
//     shelfCode?: string,   // "",
//     shelfId?: string,   // "",
//     warehouseCode?: string,   // "",
//     warehouseName?: string,   // ""
//     supplierCode?:string,
//     returnNum?:number,
// }

class PurchaseRoService {
    async page(searchVo) {
        const apiUrl = "/ci-storage/preturn/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async total(searchVo) {
        const apiUrl = "/ci-storage/preturn/total";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async one(id) {
        const apiUrl = `/ci-storage/preturn/findById/${id}`;
        return await httpRequest.get(apiUrl);
    }

    async insert(insertVo) {
        const apiUrl = "/ci-storage/preturn/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async update(updateVo) {
        const apiUrl = "/ci-storage/preturn/update";
        return await httpRequest.post(apiUrl, updateVo);
    }
    //明细删除
    async materialDelete(params) {
        const apiUrl = "/ci-storage/preturn/materialDelete";
        return await httpRequest.post(apiUrl, params);
    }
    //导出
    async export(params) {
        const apiUrl = "/ci-analyze/preturn/export";
        return await httpRequest.post(apiUrl, params);
    }
    //作废
    async invalidate(params) {
        const apiUrl = "/ci-storage/preturn/invalidate";
        return await httpRequest.post(apiUrl, params);
    }
    //退货结算
    async settle(params) {
        const apiUrl = "/ci-storage/preturn/settlePro";
        return await httpRequest.post(apiUrl, params);
    }
    //加盟商退货结算
    async settleJoin(params) {
        const apiUrl = "/ci-storage/preturn/settleJoin";
        return await httpRequest.post(apiUrl, params);
    }
    //采购退货查询详情
    async details(params) {
        const apiUrl = "/ci-storage/purchase/details/findPage";
        return await httpRequest.post(apiUrl, params);
    }
    //库房确认
    async warehousing(params) {
        const apiUrl = "/ci-storage/preturn/warehousing";
        return await httpRequest.post(apiUrl, params);
    }
    //详情导出
    async detailsExport(params) {
        const apiUrl = "/ci-storage/preturn/detailsExport";
        return await httpRequest.postFile(apiUrl, params);
    }
    //打印次数
    async printNum(id) {
        const apiUrl = `/ci-storage/preturn/print/${id}`;
        return await httpRequest.get(apiUrl);
    }
    //查询特定得收款账户
    accountFind(params) {
        const apiUrl = "/fms/account/find";
        return httpRequest.post(apiUrl, params);
    }
}

export const purchaseRoService = new PurchaseRoService();