import React, { FC, useState } from 'react'
import { YHModal } from '@/erp_subpackage/components/YHModal'
import { usePurchaseDrDetailsModel } from './purchaseDrDetailsModel'
import { YhBox } from '@/erp_subpackage/components/YhBox'
import { Table, Select, message } from 'antd'

import useGlobalModel from '@/erp_subpackage/model/globalModel'
import { warehouseService } from "@/erp_subpackage/pages/base/warehouse/warehouseService";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

export const BatchSelectWarehouseModal = (props) => {
    const { mode, batchSelectWarehouseModal, setBatchSelectWarehouseModal, batchSelectWarehouseList, selectedRowKeys } = props
    const { editList, setEditList, addList, setAddList } = usePurchaseDrDetailsModel()
    const {
        whDs,
        user: { shopId },
        setMaterialInfo
    } = useGlobalModel()

    const [shelfInfo, setShelfInfo] = useState(null) //记录选中的货位信息

    const onCancel = () => {
        setBatchSelectWarehouseModal(false)
        setShelfInfo(null)
    }

    //货位列表 Api
    const shelfLoadData = async (whObj) => {
        const searchParams = {
            shopId,
            warehouseId: whObj.id,
            warehouseCode: whObj.value,
            code: '临时货位',
            pageSize: 10,
            pageNum: 1,
        }
        let { retData: records } = await warehouseService.shelfAllPage(searchParams)
        if (!records || !records.length) {
            message.warning('该仓库下没有临时货位，请联系后台人员添加')
            setShelfInfo(null)
            return
        }
        setShelfInfo(records[0])
    }

    const onSubmit = () => {
        if (!shelfInfo) {
            message.warning('请选择仓库或者该仓库没有临时货位')
            return
        }
        let dataSource = mode === 'edit' ? [...editList] : [...addList]
        dataSource = dataSource.map((item) => {
            // return selectedRowKeys.includes(item.id)
            //     ? {
            //           ...item,
            //           inShelfId: shelfInfo.id,
            //           inShelfCode: shelfInfo.code,
            //           inWarehouseName: shelfInfo.warehouseName,
            //           inWarehouseCode: shelfInfo.warehouseCode,
            //       }
            //     : item
            return !item.inWarehouseCode
                ? {
                      ...item,
                      inShelfId: shelfInfo.id,
                      inShelfCode: shelfInfo.code,
                      inWarehouseName: shelfInfo.warehouseName,
                      inWarehouseCode: shelfInfo.warehouseCode,
                  }
                : item
            
        })
        mode === 'edit' ? setEditList(dataSource) : setAddList(dataSource)
        onCancel()
    }

    const columns = [
        {
            title: '序号',
            width: 40,
            align: 'center',
            render: (t, r, i) => i + 1,
        },
        {
            title: '商品编号',
            align: 'center',
            dataIndex: 'materialCode',
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        },
        {
            title: '商品名称',
            align: 'center',
            dataIndex: 'materialName',
        },
    ]

    const rightChildren = (
        <div className="flex justify-center translateX-70">
            <span className="label-name">仓库：</span>
            <Select
                style={{ width: 260 }}
                options={whDs}
                placeholder="请选择仓库"
                onChange={(value, option) => {
                    value && shelfLoadData(option)
                }}
            />
        </div>
    )

    return (
        <YHModal visible={batchSelectWarehouseModal} onOk={onSubmit} onCancel={onCancel} title="批量选择仓库" width={800} bodyHeight={500} showFooter={true}>
            <YhBox title={rightChildren} style={{ marginBottom: '15px' }}>
                <Table columns={columns} dataSource={batchSelectWarehouseList} pagination={false} scroll={{ x: 'max-content', y: 400 }} />
            </YhBox>
        </YHModal>
    )
}
