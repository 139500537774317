import React, { useContext, useState, useEffect } from 'react'
import TopSearch from '@/components/TopSearch'
import ListComponent from '@/components/listComponent'
import { appointmentList, appointmentCancel } from '@/service/bookingManagement'
import { MyContext } from '@/store/myContext'
import Detail from './detail'
import { appointmentOrderStatus } from '@/utils/enum'
import AppointmentTransfer from './appointmentTransfer'
import { Modal, Input } from 'antd'
import { download, convertRes2Blob } from '@/utils/exportExcel'
import Loading from '@/components/Loading'
import moment from 'moment'

export default () => {
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const { state, message } = useContext(MyContext)
    const [showVisible, setShowVisible] = useState(false)
    const [showMode, setShowMode] = useState('')
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息

    const [appointmentTransferVisible, setAppointmentTransferVisible] = useState(false)

    useEffect(() => {
        if (state.activeMenu === 'appointmentCancelled') {
            loadData(pagination.current)
        }
    }, [state.activeMenu])

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search, appointmentOrderStatus: 'cancelled' },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: 'updateTime',
                    rule: 'DESC',
                },
            ],
        }
        appointmentList(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //列表button
    const topButtons = [
        // {
        //     click: () => handleDetails('insert'),
        //     content: '新增',
        // },
        {
            click: () => handleExpoert(),
            content: '导出',
        },
    ]

    //新增
    const handleDetails = async (mode) => {
        setShowMode(mode)
        setShowVisible(true)
    }

    //作废
    const handleCancel = (record) => {
        let orderCancelReason = '' //记录工单作废原因
        Modal.confirm({
            title: '请选择取消工单的原因',
            content: (
                <Input
                    placeholder="作废原因"
                    optionFilterProp="children"
                    onChange={(e) => {
                        orderCancelReason = e.target.value
                    }}
                    style={{ width: '200px' }}
                ></Input>
            ),
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    if (orderCancelReason) {
                        resolve(orderCancelReason)
                    } else {
                        message.warning('请输入作废的原因')
                        reject('请输入工单作废的原因')
                    }
                }).then((res) => {
                    let data = {
                        cancelDesc: res,
                        id: record.id,
                    }
                    appointmentCancel(data).then((res) => {
                        message.success('作废成功')
                        loadData()
                    })
                })
            },
            onCancel: () => {},
        })
    }

    const handleExpoert = async (current, search = searchValue) => {
        setExportLoading(true)
        const data = {
            searchItem: { ...search },
            pageNum: pagination.current,
            pageSize: 10,
        }
        let resFlie = await download('/ci/appointment/export', { data })
        if (resFlie && resFlie.status === 200) {
            message.success('已导出')
            convertRes2Blob(resFlie)
        } else {
            message.warning('导出异常，请重新尝试')
        }
        setExportLoading(false)
    }

    //table配置
    const columns = [
        {
            title: '预约单号',
            align: 'center',
            dataIndex: 'appointmentOrderCode',
            width: 140,
            render: (text, record, index) => (
                <div
                    style={{ color: '#2e3aeb', cursor: 'pointer' }}
                    onClick={() => {
                        setShowMode('look')
                        setShowVisible(true)
                    }}
                >
                    {text}
                </div>
            ),
        },
        {
            title: '采埃孚单号',
            align: 'center',
            dataIndex: 'sourceCode',
            width: 140,
        },
        {
            title: '工单编号',
            align: 'center',
            dataIndex: 'orderCode',
            width: 140,
        },
        {
            title: '单据类型',
            align: 'center',
            dataIndex: 'sourceTypeCn',
            width: 140,
        },
        {
            title: '单据状态',
            align: 'center',
            dataIndex: 'appointmentOrderStatus',
            width: 140,
            render: (text) => appointmentOrderStatus[text],
        },
        {
            title: '车辆牌照',
            align: 'center',
            dataIndex: 'vehicleCode',
            width: 140,
        },
        {
            title: '车辆VIN码',
            align: 'center',
            dataIndex: 'vehicleVin',
            width: 140,
        },
        {
            title: '联系人',
            align: 'center',
            dataIndex: 'contactName',
            width: 140,
        },
        {
            title: '联系电话',
            align: 'center',
            dataIndex: 'contactPhone',
            width: 140,
        },
        {
            title: '报修地址',
            align: 'center',
            dataIndex: 'repairAddress',
            width: 240,
        },
        {
            title: '故障描述',
            align: 'center',
            dataIndex: 'faultDesc',
            width: 240,
        },
        {
            title: '预约时间',
            align: 'center',
            dataIndex: 'appointmentTime',
            width: 140,
        },
        {
            title: '创建时间',
            align: 'center',
            dataIndex: 'createTime',
            width: 140,
        },
        // {
        //     title: '操作',
        //     align: 'center',
        //     width: '120px',
        //     dataIndex: 'deleteFlag',
        //     fixed: 'right',
        //     render: (text, record) => (
        //         <>
        //             <span
        //                 style={{ cursor: 'pointer', color: '#2e3aeb', marginRight: '10px' }}
        //                 onClick={() => {
        //                     setAppointmentTransferVisible(true)
        //                 }}
        //             >
        //                 转工单
        //             </span>
        //             <span
        //                 style={{ cursor: 'pointer', color: 'red' }}
        //                 onClick={() => {
        //                     handleCancel(record)
        //                 }}
        //             >
        //                 作废
        //             </span>
        //         </>
        //     ),
        // },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: 'input',
                placeholder: '预约单号',
                valueName: 'appointmentOrderCode',
            },
            {
                type: 'input',
                placeholder: '采埃孚单号',
                valueName: 'sourceCode',
            },
            {
                type: 'input',
                placeholder: '车辆牌照',
                valueName: 'vehicleCode',
            },
            {
                type: 'input',
                placeholder: '车辆vin码',
                valueName: 'vehicleVin',
            },
            {
                type: 'RangePicker',
                valueName: ['startTime', 'endTime'],
                span: 16,
                xxl: 8,
            },
        ],
    }

    return (
        <>
            {state.activeMenu === 'appointmentCancelled' && (
                <>
                    <TopSearch
                        title="已取消"
                        justify="start"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="已取消"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        scroll={{ x: 'max-content' }}
                    />

                    {showVisible && (
                        <Detail
                            rowInfo={selectInfo.rowInfo}
                            visible={showVisible}
                            mode={showMode}
                            setVisible={setShowVisible}
                            onSuccess={() => {
                                loadData(1, searchValue)
                            }}
                        />
                    )}
                    {appointmentTransferVisible && (
                        <AppointmentTransfer
                            rowInfo={selectInfo.rowInfo}
                            visible={appointmentTransferVisible}
                            setVisible={setAppointmentTransferVisible}
                            onSuccess={() => {
                                loadData(1, searchValue)
                            }}
                        />
                    )}
                </>
            )}
            {exportLoading && <Loading />}
        </>
    )
}
