import React, { useRef, useContext, useState } from "react";
import { message } from "antd";
import YhDrawer from "@/erp_subpackage/components/YhDrawer";
import { YhForm } from "@/erp_subpackage/components/YhFormBox";
import { usePurchasePoDetailsModel } from "./purchasePoDetailModel";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum";
import { getFormValidatorErrText } from "@/erp_subpackage/utils/util";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { debounce } from "lodash";
import { MyContext } from "@/store/myContext"

//转销售单
export const TransferSaleDrawer = ({ modeKey, mode, rowCode, dispatch }) => {
    const { fetchYCCustomerList } = useGlobalModel(() => []);
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => []);
    const { transferSaleDrawer: visible, editFormVal, setTransferSaleDrawer, insertSaleDetail } = usePurchasePoDetailsModel();
    const [formVal, setFormVal] = useState({})
    const formRef = useRef();
    const { detailsPage } = useContext(MyContext);

    //监听批量录入字段
    const onChange = (changedValues) => {
        setFormVal(changedValues)
    }

    const onCancel = () => {
        setTransferSaleDrawer(false);
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current.validateFields().then(res => {
            let { purchaseCode, ...params } = res
            insertSaleDetail({
                ...params,
                saleOrderCode: purchaseCode,
                favorableAmount: 0,
                orderSourceCode: DictCodeEnmu.SOURCE_ADD,
                orderSourceName: getTreeNodeName(DictCodeEnmu.SOURCE_ADD),
                customerTypeName: getTreeNodeName(res.customerTypeCode)
            }).then(res => {
                res && onCancel()
                dispatch({
                    type: "removeTabs",
                    targetKey: modeKey,
                    activeMenu: "saleSo",
                })
            })
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }


    //客户名称
    const customerChange = (value, option) => {
        if (value && option) {
            let { contact, phone, address, category_name, category_code } = option
            option && setFormVal({
                customerName: option.label,
                customerCode: option.value,
                contact,
                phone,
                address,
                customerTypeCode: category_code,
                customerTypeName: category_name,
            })
        }
    }

    const goCustomer = () => {
        // dispatch({
        //     type: "changeTabs",
        //     name: "客户新增",
        //     activeMenu: "baseCustomer/add",
        //     content: detailsPage.BaseCustomerDetails({
        //         dispatch,
        //         modeKey,
        //         mode: "add",
        //     }),
        // })
        dispatch({
            type: "changeTabs",
            name: "客户新增",
            activeMenu: "clientRecord/insert",
            content: detailsPage.ClientDetails({
                dispatch,
                modeKey,
                mode: "insert",
                rowInfo: {},
            }),
        })
    }

    const formConfig = {
        formRef,
        formValues: { ...formVal, purchaseCode: editFormVal.purchaseCode },
        labelCol: { span: 6 },
        onChange,
        items: [
            {
                type: "Input",
                fieldLabel: "采购单号",
                fieldName: "purchaseCode",
                span: 24,
                disable: true
            }, {
                type: "AsyncSelect",
                fieldLabel: <span className="click-span" onClick={goCustomer}>客户名称</span>,
                fieldName: "customerCode",
                onSelectChange: customerChange,
                selectfetchList: fetchYCCustomerList,
                rules: [{ required: true, message: "请选择客户名称" }],
                span: 24,
            }, {
                type: "Select",
                fieldLabel: "客户类型",
                fieldName: "customerTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.CUSTOMER_TYPE),
                span: 24,
            }, {
                type: "Input",
                fieldLabel: "联系人",
                fieldName: "contact",
                span: 24,
                rules: [{ required: true, message: "请输入联系人" }]
            }, {
                type: "Input",
                fieldLabel: "联系电话",
                fieldName: "phone",
                span: 24,
                rules: [{ required: true, message: "请输入手机号" }]
            }, {
                type: "Input",
                fieldLabel: "收货人",
                fieldName: "consignee",
                span: 24,
            }, {
                type: "Input",
                fieldLabel: "收货电话",
                fieldName: "consigneePhone",
                span: 24,
            }, {
                type: "Input",
                fieldLabel: "收货地址",
                fieldName: "consigneeAddress",
                span: 24,
            },
            //带出字段
            {
                type: "Input",
                fieldLabel: "客户名称",
                fieldName: "customerName",
                hidden: true
            }, {
                type: "Input",
                fieldLabel: "客户类型Name",
                fieldName: "customerTypeName",
                hidden: true
            }, {
                type: "Input",
                fieldLabel: "客户地址",
                fieldName: "address",
                hidden: true
            }
        ]
    };
    return (
        <YhDrawer
            title="转销售单"
            visible={visible}
            onClose={onCancel}
            okText="保存"
            width={600}
            onOk={debounce(() => { onSubmit() }, 900)}
        >
            <div style={{ padding: "20px 36px" }}><YhForm {...formConfig} /></div>
        </YhDrawer>
    )
}
