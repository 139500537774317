import React, { useEffect, useState } from 'react'
import { Form, Input, Col, Row, Spin, message, Modal, Select } from 'antd'
import { appointmentOne, appointmentTransfer } from '@/service/bookingManagement'
import moment from 'moment'
import { organPage } from '@/service/orgService'
import debounce from 'lodash/debounce'
import { getCustomerListNew } from '@/service/customerService'
import { getPcodeDict } from '@/utils/dataStorage'
const { Option } = Select
export default (props) => {
    const { rowInfo, visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()
    const [org, setOrg] = useState([])

    useEffect(() => {
        queryMotorcade()
        getOrgan()
    }, [])

    //查看编辑回显
    const queryMotorcade = () => {
        appointmentOne(rowInfo.id).then((res) => {
            let results = res.retData
            form.setFieldsValue({
                ...results,
                appointmentTime: results.appointmentTime ? moment(results.appointmentTime) : results.appointmentTime,
            })
        })
    }

    const getOrgan = () => {
        organPage({
            searchItem: { orderFlag: 0, showPlatformOrgan: true },
            pageNum: 1,
            pageSize: 100,
            orders: [{ column: 'createTime', rule: 'DESC' }],
        }).then((res) => {
            setOrg(res.retData.list)
        })
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            const { customerId, organId, orderType, serviceMethod } = values
            let data = {
                customerId,
                organId,
                orderType,
                serviceMethod,
                id: rowInfo.id,
            }
            appointmentTransfer(data).then((res) => {
                message.success('转接成功')
                onSuccess && onSuccess()
                handleCancel()
            })
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleCancel = () => {
        setVisible('')
    }

    const [customerInfo, setCustomerInfo] = useState({
        //客户列表
        selectValue: {},
        data: [],
        fetching: false,
    })

    //客户列表查询
    const customerQuery = (value) => {
        setCustomerInfo({
            ...customerInfo,
            // data: [],
            fetching: true,
        })
        getCustomerListNew({
            pageSize: 15,
            currentPage: 1,
            searchItem: {
                queryTemporaryCustomer: 1,
                customerName: value,
            },
        }).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.reduce((arr, item) => {
                    if (item.customerStatus === 0) {
                        arr.push({
                            text: item.customerName,
                            value: item.id,
                            address: item.customerAddress,
                        })
                    }
                    return arr
                }, [])
                setCustomerInfo({
                    ...customerInfo,
                    data: data,
                    fetching: false,
                })
            } else {
                message.warning('系统目前没有客户，请通过左侧添加')
            }
            // }
        })
        // .catch((err) => {
        //     if (err && err.retCode === 500) {
        //         message.warning(err.retMsg)
        //     }
        // })
    }
    //防抖
    const customerQuerys = debounce(customerQuery, 1000)

    return (
        <>
            <Modal forceRender visible={visible} title="转工单" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={400}>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Form.Item label="预约单号" name="appointmentOrderCode">
                                    <Input placeholder="请输入自动生成" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="采埃孚单号" name="采埃孚单号">
                                    <Input placeholder="请输入采埃孚单号" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="客户名称" name="customerId" rules={[{ required: true, message: '请选择客户' }]}>
                                    <Select
                                        showSearch
                                        placeholder="请选择客户"
                                        value={customerInfo.selectValue.customerName}
                                        notFoundContent={customerInfo.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onFocus={() => customerQuery('')}
                                        onSearch={customerQuerys}
                                        style={{ width: '100%' }}
                                    >
                                        {customerInfo.data.map((item) => (
                                            <Option value={item.value} key={item.value} address={item.address}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="联系人" name="contactName">
                                    <Input placeholder="请输入联系人" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="联系电话" name="contactPhone">
                                    <Input placeholder="请输入联系电话" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="车辆牌照" name="vehicleCode">
                                    <Input placeholder="请输入车辆牌照" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="VIN码" name="vehicleVin">
                                    <Input placeholder="请输入VIN码" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="选择机构" name="organId" rules={[{ required: true, message: '请选择机构' }]}>
                                    <Select showSearch allowClear placeholder="请选择机构" style={{ width: '100%' }}>
                                        {org.map((item) => (
                                            <Select.Option value={item.id} key={item.id}>
                                                {item.organName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="工单类型" name="orderType" rules={[{ required: true, message: '请选择工单类型' }]}>
                                    <Select placeholder="请选择工单类型" optionFilterProp="children" allowClear>
                                        {getPcodeDict('014').children.map((item) => (
                                            <Option value={item.dictCode} key={item.id}>
                                                {item.title}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="服务方式" name="serviceMethod" rules={[{ required: true, message: '请选择服务方式' }]}>
                                    <Select placeholder="请选择服务方式" optionFilterProp="children" allowClear>
                                        {getPcodeDict('012').children.map((item) => (
                                            <Option value={item.dictCode} key={item.id}>
                                                {item.title}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
