import React, { useState, useEffect } from "react"
import { Table } from "antd"
import YhIcon from "@/components/pubIcon"
import ItemDrawer from "@/components/pubPartDrawer/itemDrawer" //项目弹框
import { EditableRow, EditableCell } from "../../pubEditble"
import { getPcodeDict } from "@/utils/dataStorage"
import { usePrebookModel } from "../prebookModel";
import { ItemAttr } from "@/utils/enum"

//项目
export default ({ mode, popupMode, threeGuaranteeId }) => {
    const { getInfo, getItemList, setItemList, guid } = usePrebookModel();
    const [visible, setVisible] = useState(false) //弹框
    const [totalPrice, setTotalPrice] = useState(0) //维修项目的总价
    const [dictObj, setDictObj] = useState({
        serviceNature: {},
        threeGuaranteesUnit: {},
    })
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);


    //点开弹框初始下页面需要的字典数据
    const initDict = () => {
        let serviceNature = {}
        let threeGuaranteesUnit = {}
        //服务性质
        getPcodeDict("016").children.forEach((item) => {
            serviceNature[item.dictCode] = item.title
        })
        //三包单位
        getPcodeDict("106").children.forEach((item) => {
            threeGuaranteesUnit[item.dictCode] = item.title
        })
        setDictObj({
            serviceNature: serviceNature,
            threeGuaranteesUnit: threeGuaranteesUnit,
        })
    }

    useEffect(() => {
        initDict()
    }, [])

    //计算总价
    useEffect(() => {
        let itemAmount = 0;
        getItemList(mode) && getItemList(mode).filter((item) => item.threeGuaranteeId == threeGuaranteeId).forEach((item) => {
            itemAmount = Number(itemAmount) + Number(item.actualAmount);
            itemAmount = itemAmount.toFixed(2);
        })
        setTotalPrice(itemAmount)
    }, [threeGuaranteeId, getItemList(mode)])

    //新增确定。 有选中的，过滤掉列表里面有的，在合并到一起。
    const handleItemList = (items) => {
        if (items.length) {
            let newArr = []
            items.forEach((item) => {
                let unselect = item
                let itemData = {
                    id: guid().replaceAll("-", ""),
                    threeGuaranteeId: threeGuaranteeId.replaceAll("-", ""),
                    itemId: unselect.id,
                    itemCode: unselect.itemCode,
                    itemName: unselect.itemName,
                    itemAttr: unselect.itemAttr,
                    itemWorkHours: unselect.itemWorkHours, //工时
                    workHoursPrice: unselect.workHoursPrice, //单价
                    itemAmount: unselect.itemAmount, //工时费
                    preferentialAmount: 0, //优惠
                    discount: "", //折扣
                    actualAmount: unselect.itemAmount, //总金额
                    remark: unselect.remark, //备注
                    giftFlag: "not_gift",
                }
                newArr.push(itemData)
            })
            setItemList(mode, [...getItemList(mode), ...newArr])
        }
        setVisible(false)
    }

    const isMode = () => {
        return mode === 'look' ? false : true
    }

    //选中展示table配置
    const columns = [
        {
            title: '序号',
            width: 40,
            align: "center",
            render: (t, r, i) => i + 1
        },
        {
            title: "项目编码",
            width: 120,
            align: "center",
            dataIndex: "itemCode",
        },
        {
            title: "项目名称",
            width: 200,
            align: "center",
            dataIndex: "itemName",
        },
        {
            title: "项目属性",
            width: 120,
            align: "center",
            dataIndex: "itemAttr",
            render: (text) => (text === 'import' ? "进口" : text === 'domestic' ? "国产" : ""),
        },
        {
            title: "工时",
            width: 100,
            align: "center",
            dataIndex: "itemWorkHours",
            editable: true,
        },
        {
            title: "单价",
            width: 100,
            align: "center",
            dataIndex: "workHoursPrice",
            editable: true,
        },
        {
            title: "工时费",
            width: 100,
            align: "center",
            dataIndex: "itemAmount",
        },
        {
            title: "优惠",
            width: 100,
            align: "center",
            dataIndex: "preferentialAmount",
            editable: true,
        },
        {
            title: "折扣",
            width: 100,
            align: "center",
            dataIndex: "itemDiscount",
            editable: true,
        },
        {
            title: "总金额",
            width: 100,
            align: "center",
            dataIndex: "actualAmount",
        },
        {
            title: "是否赠送",
            width: 120,
            align: "center",
            dataIndex: "giftFlag",
            render: (text) => (text === 'not_gift' ? "不赠送" : text === 'gift' ? "赠送" : ""),
        },
        {
            title: "备注",
            width: 120,
            align: "center",
            dataIndex: "remark",
            editable: true,
        },
        {
            title: "操作",
            width: 120,
            align: "center",
            dataIndex: "operation",
            render: (_, record) => {
                return (getInfo(mode).prebookStatus == null || getInfo(mode).prebookStatus === 0) &&
                    <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => deleteOne(record)} />
            },
        },
    ]

    const maintenanceColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: itemHandleSave,
            }),
        }
    })

    //单元格编辑保存
    const itemHandleSave = (row, status) => {
        row.itemAmount = (row.itemWorkHours * row.workHoursPrice).toFixed(2);
        if (row.itemDiscount > 0) {
            row.actualAmount = ((row.itemAmount * row.itemDiscount) / 10 - row.preferentialAmount).toFixed(2)
        } else {
            row.actualAmount = (row.itemAmount - row.preferentialAmount).toFixed(2)
        }

        const newData = [...getItemList(mode)]
        const index = newData.findIndex((item) => row.id === item.id)
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setItemList(mode, newData);
    }

    const onRow = (record) => {
        let findIndex = selectedRowKeys.indexOf(record.id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(record.id);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
        }
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const deleteOne = (record) => {
        let list = getItemList(mode).filter((item) => item.id !== record.id);
        setItemList(mode, list);
        setSelectedRowKeys([]);
    };

    const deleteBatch = () => {
        let list = getItemList(mode);
        selectedRowKeys.forEach((e) => {
            list = list.filter((item) => item.id !== e)
        })
        setItemList(mode, list);
        setSelectedRowKeys([]);
    };

    return (
        <div className="list-container">
            <div className="list-header">
                <div className="list-name">
                    <span>维修项目</span>
                    <span className="total-price">
                        总金额&nbsp;¥&nbsp;<span>{totalPrice}</span>
                        &nbsp;元&nbsp;({getItemList(mode) && getItemList(mode).length}项){" "}
                    </span>
                    <div className="list-name-but">
                        {getInfo(mode) && (getInfo(mode).prebookStatus == null || getInfo(mode).prebookStatus === 0) &&
                            <>
                                <YhIcon type="icon-zengjia" style={{ color: "#37B36F", fontSize: "26px" }} onClick={() => setVisible(true)} />
                                <span className="list-delete" onClick={() => deleteBatch(true)}>
                                    批量删除
                                </span>
                            </>
                        }
                    </div>
                </div>
            </div>
            <Table
                size="small"
                rowKey={(record) => record.id || record.temporaryId}
                components={{ body: { row: EditableRow, cell: EditableCell } }}
                columns={maintenanceColumns}
                dataSource={getItemList(mode) && getItemList(mode).filter((item) => item.threeGuaranteeId == threeGuaranteeId)}
                pagination={false}
                scroll={{ x: 1800, y: 260 }}
                rowSelection={{
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        let changeRowId = changeRows.map(item => item.id);
                        if (selected) {
                            setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                        }
                        else {
                            setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
                        }
                    },
                    onSelect: (record, selected, selectedRows) => onRow(record),
                    selectedRowKeys: selectedRowKeys
                }}
            />
            {isMode(mode) && <ItemDrawer visible={visible} onClose={(bool) => setVisible(bool)} onItemOk={handleItemList} drawerMode="item" />}
        </div>
    )
}
