import React, { useContext, useState, useEffect } from "react"

import { Input, Modal, Form, Col, Row, Select, message, Switch, Button, TreeSelect, Upload } from "antd"

import { tdmsPictureUpdate, tdmsPictureInsert } from "@/service/tdmsService.js"
import { menuStatusEnum, menuTypeEnum } from "@/config/Constants.js"
import { getToken } from "@/utils/dataStorage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"

const defaultData = {
    name: "", // 名称
    url: "", // 链接
    fileMd5: "", // MD5
    orderNum: "", //顺位
}

const EditModal = (props) => {
    const { row, visible, onCancel, onLoadData, mode, classify } = props
    const [form] = Form.useForm()
    const [editInfo, setEditInfo] = useState({})
    const [previewThumbnailImage, setpreviewThumbnailImage] = useState("") //预览展示的图片
    const [ilImageThumbnailList, setIlImageThumbnailList] = useState([]) //缩略图list

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    useEffect(() => {
        if (visible) {
            if (row && mode === "edit") {
                //初始化
                setpreviewThumbnailImage(row.fileMd5)
                setIlImageThumbnailList([
                    {
                        url: `${fileLookUrl().image}${row.fileMd5}`,
                    },
                ])
                form.setFieldsValue({
                    ...row,
                    ilImageThumbnail: [
                        {
                            url: `${fileLookUrl().image}${row.fileMd5}`,
                        },
                    ],
                })
            } else {
                form.setFieldsValue(defaultData)
                setEditInfo(defaultData)
            }
        } else {
            setpreviewThumbnailImage("")
            setIlImageThumbnailList([])
            form.resetFields()
        }
    }, [visible])

    const onSubmit = () => {
        form.validateFields()
            .then((fieldsValue) => {
                let data = {
                    ...fieldsValue,
                    fileMd5: previewThumbnailImage,
                    classify,
                }
                console.log("fieldsValue:", data)
                //修改操作
                if (mode === "edit") {
                    tdmsPictureUpdate({ ...data, id: row.id }).then((res) => {
                        onLoadData()
                        form.resetFields()
                        onCancel(false)
                        message.success("修改成功")
                    })
                    return
                } else {
                    tdmsPictureInsert(data).then((res) => {
                        onLoadData()
                        form.resetFields()
                        onCancel(false)
                        message.success("新增成功")
                    })
                }
            })
            .catch((err) => {
                message.error(err.retMsg)
            })
    }
    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }
    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }
    // 图片上传
    const handleImgChange = async (info, type) => {
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setIlImageThumbnailList(info.fileList)
            setpreviewThumbnailImage(md5)
        } else if (info.file.status === "removed") {
            setIlImageThumbnailList([])
            setpreviewThumbnailImage("")
        }
    }
    //行驶证图片查看
    const handlePreview = async (file) => {
        Modal.info({
            title: `图片查看`,
            content: <img alt="" style={{ width: "100%" }} src={file.url || file.thumbUrl} />,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            icon: <></>,
        })
    }

    return (
        <Modal
            visible={visible}
            title="图片上传"
            // centered={true}
            onOk={() => onSubmit()}
            onCancel={() => onCancel(false)}
            width={1260}
        >
            <div>
                <Form form={form} {...formItemLayout}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="名称"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "名称是必填项",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入名称" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="链接"
                                name="url"
                                rules={[
                                    {
                                        required: true,
                                        message: "链接是必填项",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入链接" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="排序" name="orderNum" initialValue={1}>
                                <Input placeholder="请输入排序" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="图片"
                                name="ilImageThumbnail"
                                valuePropName="fileList"
                                rules={[
                                    {
                                        required: true,
                                        message: "请上传图片",
                                    },
                                ]}
                                getValueFromEvent={normFile}
                            >
                                <Upload
                                    disabled={isLook(mode)}
                                    accept="image/*"
                                    headers={{ Authorization: getToken() }}
                                    action={`${fileUploadUrl}upload/image_old`}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={ilImageThumbnailList}
                                    onPreview={handlePreview}
                                    onChange={(info) => {
                                        handleImgChange(info)
                                    }}
                                >
                                    {ilImageThumbnailList.length >= 1 ? null : <Button>上传图片</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    )
}
export default EditModal
