import React, { useContext, useEffect, useState } from "react"
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Spin, Upload, Radio } from "antd"
import Modal from "@/components/Modal"
import { editVehicle, getCustomerListNew, getVehicleOne, insertVehicle } from "@/service/customerService"
import { getFleetList } from "@/service/fleetService"
import { orderEditCustomerVehicle } from "@/service/ordersService"
import { getvehicleLicenseInfo } from "@/service/baiduAiService"
import { getPcodeDict, getToken } from "@/utils/dataStorage"
import { GetChinese, RemoveChinese } from "@/utils/util"
import debounce from "lodash/debounce"
import moment from "moment"
import { MyContext } from "@/store/myContext"
import { fileLookUrl, fileUploadUrl } from "@/config/app"

const { Option } = Select

const MoveCarDetails = (props) => {
    const { dispatch, state } = useContext(MyContext)
    const { modeKey, mode, rowInfo, customerOrder } = props //父组件传过来的方法。
    const [form] = Form.useForm()
    const [customerInfo, setCustomerInfo] = useState({
        id: "", //客户列表
        data: [],
        fetching: false,
    })
    const [vehicleInfo, setVehicleInfo] = useState({
        //车队列表
        data: [],
        fetching: false,
    })
    const [previewVisible, setpreviewVisible] = useState(false) //预览图片使用的dialog
    const [previewImage, setpreviewImage] = useState("") //预览展示的行驶证
    const [licenseFileList, setLicenseFileList] = useState([]) //图片list
    const [scanningLoading, setScanningLoading] = useState(false)
    //是否是特种车
    const [isSpecial, setIsSpecial] = useState(0)
    useEffect(() => {
        resetFormItem()
        return () => {
            form.resetFields()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //订单新增， 带过来客户信息就设置上
    useEffect(() => {
        if (customerOrder && Object.keys(customerOrder).length > 0) {
            setCustomerInfo({
                data: [
                    {
                        text: customerOrder.customerName,
                        value: customerOrder.customerId,
                    },
                ],
                fetching: false,
            })
            form.setFieldsValue({
                customerId: customerOrder.customerId,
            })
        }
    }, [customerOrder]) // eslint-disable-line react-hooks/exhaustive-deps
    //客户列表查询
    const customerQuery = (value) => {
        setCustomerInfo({
            ...customerInfo,
            data: [],
            fetching: true,
        })
        getCustomerListNew({
            pageSize: 15,
            currentPage: 1,
            filterCustomer: 1,
            // customerName: value,
            searchItem: { customerName: value }
        }).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                if (item.customerStatus === 0) {
                    arr.push({
                        text: item.customerName,
                        value: item.id,
                    })
                }
                return arr
            }, [])
            setCustomerInfo({
                ...customerInfo,
                data: data,
                fetching: false,
            })
            // }
        })
    }
    //防抖
    const customerQuerys = debounce(customerQuery, 1000)
    const handleChangeCustomer = (value, option) => {
        form.setFieldsValue({ motorcadeId: "" })
        setVehicleInfo({ data: [], fetching: false })
        if (value) {
            form.setFieldsValue({
                customerName: option.children,
            })
            setCustomerInfo({
                ...customerInfo,
                id: value,
            })
        } else {
            setCustomerInfo({
                ...customerInfo,
                id: "",
            })
        }
    }
    //车队列表查询
    const vehicleQuery = (value) => {
        setVehicleInfo({
            data: [],
            fetching: true,
        })
        getFleetList({
            pageSize: 15,
            pageNum: 1,
            searchItem: {},
            motorcadeName: value,
            customerId: customerInfo.id || null,
        }).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            if (results.length <= 0) {
                message.warning("该客户下没有车队，请移步到车队新增车队")
                return
            }
            const data = results.map((item) => ({
                text: item.motorcadeName,
                value: item.id,
            }))
            setVehicleInfo({
                data: data,
                fetching: false,
            })
            // }
        })
    }
    //防抖
    const vehicleQuerys = debounce(vehicleQuery, 1000)
    //返回按钮
    const handleBack = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "vehicleRecord",
        })
    }
    //客户车辆表单提交
    const carSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                drivingSignDate: values.drivingSignDate ? values.drivingSignDate.format("YYYY-MM-DDTHH:mm:ss") : null,
                lastMaintainDate: values.lastMaintainDate ? values.lastMaintainDate.format("YYYY-MM-DDTHH:mm:ss") : null,
                nextMaintainDate: values.nextMaintainDate ? values.nextMaintainDate.format("YYYY-MM-DDTHH:mm:ss") : null,
                driverCardImg: previewImage,
            }
            //新增
            if (mode === "insert") {
                insertVehicle(data).then((res) => {
                    // if (res && res.retCode === 200) {
                    let isOrderKey = state.panes.find((i) => i.key === rowInfo.orderEditModeKey)
                    if (isOrderKey && rowInfo.orderEditModeKey) {
                        dispatch({
                            type: "changeTabs",
                            activeMenu: rowInfo.orderEditModeKey,
                            name: rowInfo.orderEditModeKey.length > 30 ? "订单编辑" : "订单新增",
                        })
                        dispatch({
                            type: "removeTabs",
                            targetKey: modeKey,
                        })
                    } else {
                        dispatch({
                            type: "removeTabs",
                            targetKey: modeKey,
                            activeMenu: "ordersManage",
                        })
                    }
                    message.success("新增成功")
                    !rowInfo.orderEditModeKey && handleBack()
                    message.success("车辆新增成功")
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
                // .catch((err) => {
                //     if (err && err.retCode === 500) {
                //         message.error(err.retMsg)
                //         return
                //     }
                // })
                //编辑  行信息为2个字段的是订单带过来的。
            } else if (mode === "edit") {
                if (Object.keys(rowInfo).length === 3) {
                    // console.log(rowInfo)
                    orderEditCustomerVehicle({
                        ...data,
                        id: rowInfo.id,
                        orderId: rowInfo.orderId,
                    }).then(async (res) => {
                        // if (res && res.retCode === 200) {
                        //车辆信息在返回去
                        let vehicleInfo = {
                            customerId: data.customerId,
                            customerName: data.customerName, //客户名称
                            customerVehicleCode: data.vehicleCode, //车辆牌照
                            customerVehicleBrand: data.brandId, //车辆品牌
                            customerVehicleSeries: data.vehicleSeriesId, //车系
                            customerVehicleModel: data.vehicleModelId, //车型
                            customerEngineCode: data.engineCode, //发动机号
                            customerVehicleAxle: data.axle, //车桥
                            gearboxCode: data.gearboxCode, //变速箱号
                            customerVehicleVin: data.vehicleVin, //vin码
                            customerVehicleId: rowInfo.id, //车辆id
                        }
                        let isOrderKey = state.panes.find((i) => i.key === rowInfo.orderEditModeKey)
                        if (isOrderKey) {
                            await dispatch({
                                type: "changeTabs",
                                activeMenu: rowInfo.orderEditModeKey,
                                name: "订单详情",
                                vehicleInfo,
                            })
                            await dispatch({
                                type: "removeTabs",
                                targetKey: modeKey,
                            })
                        } else {
                            dispatch({
                                type: "removeTabs",
                                targetKey: modeKey,
                                activeMenu: "ordersManage",
                            })
                        }
                        // }
                    })
                    // .catch((err) => {
                    //     if (err && err.retCode === 500) {
                    //         message.error(err.retMsg)
                    //         return
                    //     }
                    // })
                } else {
                    //设置id
                    data.id = rowInfo.id
                    editVehicle(data).then((res) => {
                        // if (res && res.retCode === 200) {
                        handleBack()
                        message.success("车辆信息编辑成功")
                        // } else {
                        //     message.error(res.retMsg)
                        // }
                    })
                    // .catch((err) => {
                    //     if (err && err.retCode === 500) {
                    //         message.error(err.retMsg)
                    //         return
                    //     }
                    // })
                }
            }
        })
    }
    //行驶证图片查看
    const handlePreview = async (file) => {
        setpreviewVisible(true)
    }
    //图像识别
    const getImgInfo = (fileMd5) => {
        getvehicleLicenseInfo(fileMd5)
            .then((res) => {
                if (res && res.retCode === 200) {
                    let { words_result, error_msg } = res.retData
                    if (error_msg) {
                        message.error(error_msg)
                        setLicenseFileList([])
                        form.resetFields(["driverCardImg"])
                    } else {
                        form.setFieldsValue({
                            vehicleCode: words_result["号牌号码"].words,
                            vehicleType: words_result["车辆类型"].words,
                            vehicleNature: words_result["使用性质"].words,
                            brandId: GetChinese(words_result["品牌型号"].words),
                            vehicleModelId: RemoveChinese(words_result["品牌型号"].words),
                            vehicleVin: words_result["车辆识别代号"].words,
                            engineCode: words_result["发动机号码"].words,
                            drivingSignDate: moment(words_result["注册日期"].words),
                        })
                    }
                } else {
                    message.error("请上传清晰的行驶证图片")
                    setLicenseFileList([])
                    form.resetFields(["driverCardImg"])
                }
                setScanningLoading(false)
            })
            .catch((err) => {
                form.resetFields(["driverCardImg"])
                setScanningLoading(false)
            })
    }
    // 图片上传
    const handleImgChange = async (info) => {
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setScanningLoading(true)
            getImgInfo(md5)
            setpreviewImage(md5)
            setLicenseFileList(info.fileList)
        } else if (info.file.status === "removed") {
            setLicenseFileList([])
            setpreviewImage("")
        }
    }

    //回显表单项
    const resetFormItem = () => {
        if (mode === "edit" || mode === "look") {
            getVehicleOne(rowInfo.id).then((res) => {
                // if (res && res.retCode === 200) {
                let retData = res.retData
                let imgFileList = []
                if (retData.driverCardImg) {
                    //回显图片， 有的话在做回显
                    imgFileList = [
                        {
                            uid: "-1",
                            status: "done",
                            url: `${fileLookUrl().image}${retData.driverCardImg}`,
                        },
                    ]
                    //回显图片
                    setLicenseFileList(imgFileList)
                    setpreviewImage(retData.driverCardImg)
                }

                setIsSpecial(retData.specialVehicle)

                form.setFieldsValue({
                    ...retData,
                    driverCardImg: imgFileList,
                    drivingSignDate: retData.drivingSignDate ? moment(retData.drivingSignDate) : null,
                    lastMaintainDate: retData.lastMaintainDate ? moment(retData.lastMaintainDate) : null,
                    nextMaintainDate: retData.nextMaintainDate ? moment(retData.nextMaintainDate) : null,
                })
                setCustomerInfo({
                    id: retData.customerId,
                    data: [
                        {
                            text: retData.customerName,
                            value: retData.customerId,
                        },
                    ],
                    fetching: false,
                }) //客户列表回显
                setVehicleInfo({
                    data: [
                        {
                            text: retData.motorcadeName,
                            value: retData.motorcadeId,
                        },
                    ],
                    fetching: false,
                }) //车队列表回显
                // }
            })
        }
    }

    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }
    //控制时间
    const disabledDate = (current) => {
        return current && current < moment().startOf("day")
    }
    //控制时间 当天之后
    const disabledDates = (current) => {
        return current && current > moment().startOf("day")
    }
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }
    return (
        <>
            <div className="toolbar">
                <span className="modules-name">车辆管理</span>
            </div>

            <Spin spinning={scanningLoading} tip="图片快速识别中">
                <div className="list-container">
                    <div className="list-header">
                        <div className="list-name">
                            <span>车辆详情</span>
                        </div>
                        <div className="list-but">
                            {!isLook(mode) && (
                                <Button shape="round" className="but-left" onClick={carSubmit}>
                                    保存
                                </Button>
                            )}
                            <Button shape="round" className="but-left" onClick={handleBack}>
                                取消{" "}
                            </Button>
                        </div>
                    </div>
                    <div className="form-details-warp">
                        <Form form={form} {...formItemLayout} initialValues={{ specialVehicle: 0 }}>
                            <Row>
                                <Col span={1}>
                                    <span className="form-title">基本信息</span>
                                </Col>
                                <Col span={9}>
                                    <Form.Item
                                        label="客户名称"
                                        name="customerId"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择客户",
                                            },
                                        ]}
                                    >
                                        <Select
                                            disabled={isLook(mode)}
                                            showSearch
                                            allowClear
                                            placeholder="请选择客户"
                                            notFoundContent={customerInfo.fetching ? <Spin size="small" /> : null}
                                            filterOption={false}
                                            onChange={handleChangeCustomer}
                                            onFocus={() => customerQuery()}
                                            onSearch={customerQuerys}
                                            style={{ width: "100%" }}
                                        >
                                            {customerInfo.data.map((item) => (
                                                <Option value={item.value} key={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="车队名称" name="motorcadeId">
                                        <Select
                                            disabled={isLook(mode)}
                                            showSearch
                                            allowClear
                                            placeholder="请选择车队"
                                            notFoundContent={vehicleInfo.fetching ? <Spin size="small" /> : null}
                                            filterOption={false}
                                            onFocus={() => vehicleQuery()}
                                            onSearch={vehicleQuerys}
                                            style={{ width: "100%" }}
                                        >
                                            {vehicleInfo.data.map((item) => (
                                                <Option value={item.value} key={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="云小牛设备编号" name="yxnEquipmentCode">
                                        <Input placeholder="请输入云小牛设备编号" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="云小牛设备sn码" name="yxnSn">
                                        <Input placeholder="云小牛设备sn码" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item
                                        label="是否是特殊车辆"
                                        name="specialVehicle"
                                        onChange={(e) => {
                                            setIsSpecial(e.target.value)
                                            form.validateFields()
                                        }}
                                    >
                                        <Radio.Group>
                                            <Radio value={0}>否</Radio>
                                            <Radio value={1}>是</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item
                                        label="是否大包"
                                        name="bigBagFlag"
                                    >
                                        <Radio.Group>
                                            <Radio value={0}>否</Radio>
                                            <Radio value={1}>是</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item
                                        label="车辆牌照"
                                        name="vehicleCode"
                                        rules={
                                            Number(isSpecial) === 1
                                                ? [
                                                    {
                                                        required: true,
                                                        message: "请输入车辆牌照",
                                                    },
                                                    {
                                                        pattern: /^[\u4E00-\u9FA5 | \da-zA-Z]{1,100}$/,
                                                        message: "请输正确的车辆牌照",
                                                    },
                                                ]
                                                : [
                                                    {
                                                        required: true,
                                                        message: "请输入车辆牌照",
                                                    },
                                                    {
                                                        pattern: /^[\u4E00-\u9FA5][\da-zA-Z]{5,100}$/,
                                                        message: "请输正确的车辆牌照",
                                                    },
                                                ]
                                        }
                                    >
                                        <Input placeholder="请输入车辆牌照" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                {/* <Col offset={1} span={9}>
                                    <Form.Item label="适用品牌" name="vehicleBrand">
                                        <Select disabled={isLook(mode)} showSearch allowClear placeholder="请选择适用品牌" style={{ width: "100%" }}>
                                            {getPcodeDict("022001").children.map((item) => (
                                                <Option value={item.dictCode} key={item.id}>
                                                    {item.title}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item label="适用车型" name="vehicleModel">
                                        <Select disabled={isLook(mode)} showSearch allowClear placeholder="请选择车型" style={{ width: "100%" }}>
                                            {getPcodeDict("022002").children.map((item) => (
                                                <Option value={item.dictCode} key={item.id}>
                                                    {item.title}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col> */}
                                <Col offset={1} span={9}>
                                    <Form.Item label="车辆类型" name="vehicleType">
                                        <Input placeholder="请输入车辆类型" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item label="车辆排放" name="vehicleEmission">
                                        <Select disabled={isLook(mode)} showSearch allowClear placeholder="请选择车辆排放" style={{ width: "100%" }}>
                                            {getPcodeDict("022003").children.map((item) => (
                                                <Option value={item.dictCode} key={item.id}>
                                                    {item.title}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="动力来源" name="powerSource">
                                        <Select disabled={isLook(mode)} showSearch allowClear placeholder="请选择动力来源" style={{ width: "100%" }}>
                                            {getPcodeDict("022007").children.map((item) => (
                                                <Option value={item.dictCode} key={item.id}>
                                                    {item.title}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="车辆品牌" name="brandId">
                                        <Input placeholder="请输入车辆品牌" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item label="车辆型号" name="vehicleModelId">
                                        <Input placeholder="请输入车辆型号" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item label="车辆发动机型号" name="vehicleEngineModel">
                                        <Select disabled={isLook(mode)} showSearch allowClear placeholder="请选择发动机型号" style={{ width: "100%" }}>
                                            {getPcodeDict("022004").children.map((item) => (
                                                <Option value={item.dictCode} key={item.id}>
                                                    {item.title}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="车辆颜色" name="carColor">
                                        <Input placeholder="请输入车辆颜色" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item label="车辆发动机" name="vehicleEngine">
                                        <Select disabled={isLook(mode)} showSearch allowClear placeholder="请选择车辆发动机" style={{ width: "100%" }}>
                                            {getPcodeDict("022005").children.map((item) => (
                                                <Option value={item.dictCode} key={item.id}>
                                                    {item.title}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="发动机号" name="engineCode">
                                        <Input placeholder="请输入发动机号" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item label="车辆厂家三包" name="vehicleThreeGuarantees">
                                        <Select disabled={isLook(mode)} showSearch allowClear placeholder="请选择车辆厂家三包" style={{ width: "100%" }}>
                                            {getPcodeDict("022006").children.map((item) => (
                                                <Option value={item.dictCode} key={item.id}>
                                                    {item.title}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="发动机型号" name="engineModel">
                                        <Input placeholder="请输入发动机型号" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item label="三包厂商" name="manufacturerId">
                                        <Select disabled={isLook(mode)} showSearch allowClear placeholder="请选择三包厂商" style={{ width: "100%" }}>
                                            {getPcodeDict("107").children.map((item) => (
                                                <Option value={item.dictCode} key={item.id}>
                                                    {item.title}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="发动机订货号" name="engineOrderNumber">
                                        <Input placeholder="请输入发动机订货号" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item label="第一车桥" name="firstAxle">
                                        <Input placeholder="请输入第一车桥" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="变速箱号" name="gearboxCode">
                                        <Input placeholder="请输入变速箱号" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item label="第二车桥" name="secondAxle">
                                        <Input placeholder="请输入第二车桥" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="变箱编号" name="boxNumber">
                                        <Input placeholder="请输入变箱编号" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item label="第三车桥" name="thirdAxle">
                                        <Input placeholder="请输入第三车桥" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="变箱类型" name="boxType">
                                        <Input placeholder="请输入变速类型" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item label="第四车桥" name="fourthAxle">
                                        <Input placeholder="请输入第四车桥" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item
                                        label="Vin码"
                                        name="vehicleVin"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入车辆识别号",
                                            },
                                            // { pattern: /^(?!(?:\d+|[a-zA-Z]+)$)[\da-zA-Z]{17}$/, message: '请输17位字母加数字格式' }
                                        ]}
                                    >
                                        <Input placeholder="请输入Vin码" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item label="第五车桥" name="fifthAxle">
                                        <Input placeholder="请输入第五车桥" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="车系" name="vehicleSeriesId">
                                        <Input placeholder="请输入车系" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item label="使用性质" name="vehicleNature">
                                        <Input placeholder="请输入使用性质" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="车桥" name="axle">
                                        <Input placeholder="请输入车桥" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item label="用户编码" name="userNumber">
                                        <Input placeholder="请输入用户编码" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="注册日期" name="drivingSignDate">
                                        <DatePicker
                                            disabled={isLook(mode)}
                                            style={{ width: "100%" }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                                defaultValue: moment("00:00:00", "HH:mm:ss"),
                                            }}
                                        // disabledDate={disabledDate}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item label="住址" name="carAdd">
                                        <Input placeholder="请输入车主住址" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="登记日期" name="createTime">
                                        <Input placeholder="自动生成" disabled />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item
                                        label="马力"
                                        name="horsepower"
                                        rules={[
                                            {
                                                pattern: /^(0|[1-9][0-9]*)$/,
                                                message: "请输入数字格式",
                                            },
                                        ]}
                                    >
                                        <Input suffix="匹" placeholder="请输入马力" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item
                                        label="行驶证"
                                        name="driverCardImg"
                                        valuePropName="fileList"
                                        getValueFromEvent={normFile}
                                    // rules={[{ required: true, message: "请上传行驶证" }]}
                                    >
                                        <Upload
                                            disabled={isLook(mode)}
                                            action={`${fileUploadUrl}upload/image_old`}
                                            headers={{ Authorization: getToken() }}
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            fileList={licenseFileList}
                                            onPreview={handlePreview}
                                            onChange={handleImgChange}
                                        >
                                            {licenseFileList.length >= 1 ? null : <Button>上传行驶证</Button>}
                                        </Upload>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="from-line" />
                            <Row>
                                <Col span={1}>
                                    <span className="form-title">保养信息</span>
                                </Col>
                                <Col span={9}>
                                    <Form.Item label="上次保养日期" name="lastMaintainDate">
                                        <DatePicker
                                            disabled={isLook(mode)}
                                            style={{ width: "100%" }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                                defaultValue: moment("00:00:00", "HH:mm:ss"),
                                            }}
                                            disabledDate={disabledDates}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item
                                        label="上次保养里程"
                                        name="lastMaintainMileage"
                                        rules={[
                                            {
                                                pattern: /^(0|[1-9][0-9]*)$/,
                                                message: "请输入数字格式",
                                            },
                                        ]}
                                    >
                                        <Input suffix="KM" placeholder="请输入上次保养里程" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item label="下次保养日期" name="nextMaintainDate">
                                        <DatePicker
                                            disabled={isLook(mode)}
                                            style={{ width: "100%" }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                                defaultValue: moment("00:00:00", "HH:mm:ss"),
                                            }}
                                            disabledDate={disabledDate}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item
                                        label="下次保养里程"
                                        name="nextMaintainMileage"
                                        rules={[
                                            {
                                                pattern: /^(0|[1-9][0-9]*)$/,
                                                message: "请输入数字格式",
                                            },
                                        ]}
                                    >
                                        <Input suffix="KM" placeholder="请输入下次保养里程" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={9}>
                                    <Form.Item
                                        label="最新里程"
                                        name="latestMileage"
                                        rules={[
                                            {
                                                pattern: /^(0|[1-9][0-9]*)$/,
                                                message: "请输入数字格式",
                                            },
                                        ]}
                                    >
                                        <Input suffix="KM" placeholder="请输入最新里程" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Form.Item hidden name="customerName">
                                    <Input />
                                </Form.Item>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Spin>
            <Modal visible={previewVisible} title="行驶图片查看" footer={null} onOk={() => setpreviewVisible(false)}>
                <img alt="" style={{ width: "100%" }} src={`${fileLookUrl().image}${previewImage}`} />
            </Modal>
        </>
    )
}

export default MoveCarDetails
