import React, { useRef } from "react";
import { TopButs } from "./TopButs";
import { FormBox } from "./FormBox";
import { TableBox } from "./TableBox";
import { FixedBottom } from "./FixedBottom";
import { AddGoodsModal } from "./AddGoodsModal";
import { SettleDrawer } from "./SettleDrawer";
import { WhConfirmDrawer } from "./WhConfirmDrawer";
import { DiscountModel } from "./discountModel";
import { CheckModel } from "./chenckModel"

export const SaleSoDetails = (props) => {
    const formRef = useRef()
    return (
        <div className="yh-detail-box">
            <TopButs formRef={formRef} {...props} />
            <FormBox formRef={formRef} {...props} />
            <TableBox {...props} />
            <FixedBottom {...props} />
            <AddGoodsModal {...props} />
            <SettleDrawer {...props} />
            <WhConfirmDrawer  {...props} />
            <DiscountModel />
            <CheckModel {...props} />
        </div>
    )
}