import { useState, useEffect } from "react";
import { createModel } from "hox";
import { useLockFn } from "ahooks";
import { purchaseRoService } from "../../purchaseRoService"
import useGlobalModel, { defaultMdCmParam } from "@/erp_subpackage/model/globalModel";
import { formatInt, formatNum, getRandomStr } from "@/erp_subpackage/utils/util";
import { message } from "antd";
import { bigNumber } from "../../../../../utils/util";
import { IsFranchisees } from '@/utils/common'

const initDesc = {
    numAll: 0,     //开单
    totalAll: 0 //总计
}
export const usePurchaseRoDetailsModel = createModel(function () {
    const { sourceApp, branch } = defaultMdCmParam;
    const { user: { shopId, shopName, shopCode, userName, orgName, orgCode, bentityName, bentityCode } } = useGlobalModel();

    const initFormVal = {
        shopName,
        createUser: userName,
        bentityName: bentityName,
        status: true,
        documentTypeCode: 'preturn-type.general'
    }

    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState(initFormVal);
    const [editList, setEditList] = useState([]);
    const [editDesc, setEditDesc] = useState(initDesc);
    //新增表单缓存
    const [addFormVal, setAddFormVal] = useState(initFormVal);
    const [addList, setAddList] = useState([]);
    const [addDesc, setAddDesc] = useState(initDesc);
    //查看表单缓存
    const [lookFormVal, setLookFormVal] = useState(initFormVal);
    const [lookList, setLookList] = useState([]);
    const [lookDesc, setLookDesc] = useState(initDesc);

    //添加商品弹框
    const [materialDialog, setMaterialDialog] = useState(false);

    //结算弹框
    const [settleVisible, setSettleVisible] = useState(false);

    //库房确认
    const [wsConfirmVisible, setWsConfirmVisible] = useState(false);

    //单据变化、按钮可操作控制
    const [controlled, setControlled] = useState(false);

    const [saveBtnLoading, setSaveBtnLoading] = useState(false); //  保存按钮

    //切换机构需重置的缓存
    useEffect(() => {
        setAddFormVal(initFormVal)
    }, [shopId])

    useEffect(() => {
        let newDesc = { ...initDesc };
        editList.forEach(item => {
            // newDesc.numAll += Number(item.num);
            newDesc.numAll = +bigNumber.add(newDesc.numAll,Number(item.num)) ;
            // newDesc.totalAll += Number(item.price) * Number(item.num);
            newDesc.totalAll += +bigNumber.times(Number(item.price),Number(item.num)).toFixed(2);

        });
        setEditDesc(newDesc);
    }, [editList])

    useEffect(() => {
        let newDesc = { ...initDesc };
        addList.forEach(item => {
            // newDesc.numAll += Number(item.num);
            newDesc.numAll = +bigNumber.add(newDesc.numAll,Number(item.num)) ;
            // newDesc.totalAll += Number(item.price) * Number(item.num);
            newDesc.totalAll += +bigNumber.times(Number(item.price),Number(item.num)).toFixed(2);
        });
        setAddDesc(newDesc);
    }, [addList])

    useEffect(() => {
        let newDesc = { ...initDesc };
        lookList.forEach(item => {
            // newDesc.numAll += Number(item.num);
            newDesc.numAll = +bigNumber.add(newDesc.numAll,Number(item.num)) ;
            // newDesc.totalAll += Number(item.price) * Number(item.num);
            newDesc.totalAll += +bigNumber.times(Number(item.price),Number(item.num)).toFixed(2);
        });
        setLookDesc(newDesc);
    }, [lookList])

    //表单修改
    const updateFormVal = (mode, obj) => {
        const setFormVal = mode === "edit" ? setEditFormVal : mode === "look" ? setLookFormVal : setAddFormVal;
        setFormVal(origin => obj ? { ...origin, ...obj } : initFormVal);
    }

    //商品子表修改
    const updateList = (mode, arr = []) => {
        const setList = mode === 'edit' ? setEditList : mode === "look" ? setLookList : setAddList;
        setList([...arr]);
        if (mode === "edit") { setControlled(true) }
    };

    //获取一条数据
    const getPurchaseRoOne = async (id, mode = "edit") => {
        let { retData } = await purchaseRoService.one(id);
        let { details, ...from } = retData;
        const settleDetails = retData.settleDetails
            if (settleDetails && settleDetails.length > 0) {
                const { cashAttach, cashAttachFileName } = settleDetails[0]
                if(cashAttach && cashAttachFileName){
                    from.cashAttach = cashAttach
                    from.cashAttachFileName = cashAttachFileName
                }
            }
        updateFormVal(mode, from ?? {});
        updateList(mode, (details ?? []).map(item => ({ ...item, key: item.id })));
        setControlled(false);
        return retData;
    }


    //校验商品信息的仓库和货位是否选中
    const verifyWhOrShelf = (list = editList) => {
        if (!list || !list.length) {
            message.warning("请选择商品");
            return false;
        }
        let filterArr = list.filter(item => !item.warehouseCode || !item.shelfCode);
        if (filterArr.length) {
            message.warning(`【${filterArr[0].materialName}】请选择仓库和货位`);
            return false;
        }
        return true;
    }

    //新增
    const insertDetail = useLockFn(async (params) => {
        setSaveBtnLoading(true)
        if(addList.length==0){
            message.warning(`未添加商品明细不可生成采购退货单`)
            return
        } 
        let newParams = {
            ...params,
            details: addList,
            createUser: userName,
            sourceApp, branch,
            shopId, shopName, shopCode, userName, orgName, orgCode,
        }
        let { retData } = await purchaseRoService.insert(newParams);
        setSaveBtnLoading(false)
        message.success("新增成功")
        return retData;
    })

    //编辑
    const editDetail = useLockFn(async (params) => {
        setSaveBtnLoading(true)
        //if (!verifyWhOrShelf(editList)) return false;
        if(editList.length==0){
            message.warning(`未添加商品明细不可生成采购退货单`)
            return
        } 
        let newParams = {
            ...editFormVal,
            ...params,
            id: editFormVal.id,
            details: editList,
            updateUser: userName,
            sourceApp, branch,
            shopId, shopName, shopCode, userName, orgName, orgCode,
        }
        let { retData } = await purchaseRoService.update(newParams);
        getPurchaseRoOne(editFormVal.returnCode)
        setSaveBtnLoading(false)
        message.success("编辑成功")
        return retData;
    })

    //商品子表添加
    const insertMaterial = (mode, materialArr) => {
        const setList = mode === 'edit' ? setEditList : mode === "look" ? setLookList : setAddList;
        const repetition = [];
        setList((origin) => {
            const newArr = [...origin];
            materialArr.forEach((material) => {
                // //选中的是否存在列表
                // let isExist = origin.find((item) => item.materialCode == material.materialCode);
                // if (!isExist) {
                newArr.push({
                    ...material,
                    inventoryNum: material.availableStock,
                    purchaseNum: material.num,
                    purchasePrice: material.price,
                    price: material.preturnPrice,
                    num: material.returnNum,
                    detailsId: material.id,
                    id: "",
                    note: "",
                    key: getRandomStr()
                });
                // } else {
                //     repetition.push(material.name);
                // }
            });
            return newArr;
        });
        setMaterialDialog(false);
        !!repetition.length && message.warning(`商品名称：${repetition.join(",")}，重复的将不在添加。`);
        if (mode === "edit") { setControlled(true) }
    };

    //商品子表删除、编辑模式下，已有id的需要进行数据库删除 (单条及批量删除)
    const removeMaterial = useLockFn(async (mode, selectedRowKeys) => {
        if (mode === 'edit') {
            let ids = editList.filter(item => selectedRowKeys.indexOf(item.key ?? '') >= 0 && item.id).map(item => ({ id: item.id }));
            //存在id在跑接口进行数据库删除
            if (ids.length) {
                let { retData } = await purchaseRoService.materialDelete({ ids, returnCode: editFormVal.returnCode });
                if (!retData) return retData;
            }
        }
        const setList = mode === 'edit' ? setEditList : setAddList;
        setList((origin) => origin.filter((item) => selectedRowKeys.indexOf(item.key ?? '') < 0));
        message.success("删除成功");
        if (mode === "edit") { setControlled(true) }
    });

    //结算
    const onSettle = useLockFn(async (params) => {
        // let { retData } = await purchaseRoService.settle(params);
        let { retData } = !IsFranchisees() ? await purchaseRoService.settle(params) : await purchaseRoService.settleJoin(params);
        getPurchaseRoOne(editFormVal.returnCode);
        message.success("结算成功");
        return retData
    })


    //库房确认
    const onWarehous = useLockFn(async () => {
        let { id, returnCode } = editFormVal;
        let paramas = {
            id,
            returnCode,
            storekeeper: userName
        }
        let { retData } = await purchaseRoService.warehousing(paramas);
        setWsConfirmVisible(false);
        getPurchaseRoOne(returnCode);
        message.success("出库成功");
        return retData
    })

    return {
        controlled,
        editFormVal,
        editList,
        addFormVal,
        addList,
        lookFormVal,
        lookList,
        addDesc, editDesc, lookDesc,
        materialDialog, setMaterialDialog,
        settleVisible, setSettleVisible,
        wsConfirmVisible, setWsConfirmVisible,
        updateFormVal,
        updateList,
        getPurchaseRoOne,
        insertMaterial, removeMaterial,
        onSettle,
        insertDetail,
        editDetail,
        onWarehous,
        verifyWhOrShelf,
        setSaveBtnLoading,saveBtnLoading
    }
})