import React, { useState, useEffect } from "react"
import { Table, message } from "antd"
import { getPcodeDict } from "@/utils/dataStorage"
import { orderThreeGuaranteesList, orderThreeGuaranteesDelete } from "@/service/ordersService"
import YhIcon from "@/components/pubIcon"
import SanbaoPopup from "./sanbaoPopup"

//物料
export default ({ orderId, mode, parentOrderType, orderType }) => {
    const [visible, setVisible] = useState(false) //弹框
    const [data, setData] = useState([]) //表格数据
    const [popupMode, setPopupMode] = useState("")
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [isOperation, setIsOperation] = useState(false)
    const [dictObj, setDictObj] = useState({
        threeGuaranteesUnit: {},
    })

    // const [loading, setLoading] = useState(false)

    //点开弹框初始下页面需要的字典数据
    const initDict = () => {
        let threeGuaranteesUnit = {}
        //三包品牌
        getPcodeDict("106").children.forEach((item) => {
            threeGuaranteesUnit[item.dictCode] = item.title
        })
        setDictObj({
            threeGuaranteesUnit: threeGuaranteesUnit,
        })
    }

    useEffect(() => {
        initDict()
        if (!visible) {
            loadData(1)
            if (parentOrderType !== "014001") {
                setIsOperation(true)
            } else {
                setIsOperation(false)
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (orderType) {
            if (orderType !== "014001") {
                setIsOperation(true)
            } else {
                setIsOperation(false)
            }
        }
    }, [orderType])

    //默认加载数据列表
    const loadData = (current, search = {}) => {
        // setLoading(true)
        let data = {
            searchItem: {
                orderId,
                ...{ search },
            },
            pageNum: current,
            pageSize: 100,
            orders: [{ column: "updateTime", rule: "DESC" }],
        }
        orderThreeGuaranteesList(data).then((res) => {
            let list = res.retData.list
            setData(list)
            // setLoading(false)
        })
    }

    const [choosedItem, setChoosedItem] = useState([]) // 批量删除的key
    //批量删除多选的成员id
    const maintenanceSelection = {
        columnWidth: "30px",
        onChange: (selectedRowKeys, selectedRows) => {
            setChoosedItem(selectedRowKeys)
        },
    }

    //批量删除
    const deleteTemaAll = () => {
        if (choosedItem.length === 0) {
            message.warning("请选择你要删除的三包")
            return
        }
        orderThreeGuaranteesDelete({ ids: choosedItem }).then((res) => {
            loadData(1)
        })
    }

    //是否是查看
    const isMode = () => {
        return mode === "look" ? false : true
    }
    //table配置
    const columns = [
        {
            title: "三包品牌",
            dataIndex: "threeGuaranteesBrand",
            editable: true,
            render: (text) => (
                <span
                    style={{ color: "#2e3aeb", cursor: "pointer" }}
                    onClick={() => {
                        setPopupMode("edit")
                        setVisible(true)
                    }}
                >
                    {dictObj.threeGuaranteesUnit[text]}
                </span>
            ),
        },
        {
            title: "维修人员",
            dataIndex: "maintenanceStaff",
            editable: true,
        },
        {
            title: "维修时间",
            dataIndex: "repairTime",
            width: 220,
            editable: true,
        },

        {
            title: "索赔单号",
            dataIndex: "claimNumber",
            editable: true,
        },
        {
            title: "故障处理",
            dataIndex: "faultHandle",
            editable: true,
        },
        {
            title: "材料费",
            dataIndex: "materialAmount",
            editable: true,
        },
        {
            title: "出库金额",
            dataIndex: "outboundAmount",
        },
        {
            title: "工时费",
            dataIndex: "hourlyWage",
            editable: true,
        },
        {
            title: "外出费",
            dataIndex: "travelExpenses",
            editable: true,
        },
        {
            title: "餐补费",
            dataIndex: "mealSupplement",
            editable: true,
        },
        {
            title: "其他费用",
            dataIndex: "otherAmount",
            editable: true,
        },
        {
            title: "索赔总额",
            dataIndex: "totalClaim",
        },
        {
            title: "结算单号",
            dataIndex: "statementNumber",
            editable: true,
        },
        {
            title: "三包工时费汇总",
            dataIndex: "totalHourlyWage",
        },
    ]

    return (
        <div className="list-container" style={{marginBottom:"100px"}}>
            <div className="list-header">
                <div className="list-name">
                    <span>三包信息</span>
                    <span className="total-price">
                        {/* 总工时费&nbsp;¥&nbsp;<span>{totalPrice}</span> */}
                        &nbsp;元&nbsp;({data.length}项){" "}
                    </span>
                    {isMode() && isOperation && (
                        <div className="list-name-but">
                            <YhIcon
                                type="icon-zengjia"
                                style={{ color: "#37B36F", fontSize: "26px" }}
                                onClick={() => {
                                    setPopupMode("insert")
                                    setVisible(true)
                                }}
                            />
                            <span className="list-delete" onClick={() => deleteTemaAll()}>
                                批量删除
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <Table
                size="small"
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{ x: 2000, y: 260 }}
                rowSelection={maintenanceSelection}
                onRow={(record, index) => ({
                    onClick: () =>
                        setSelectInfo({
                            rowInfo: record,
                            rowIndex: index,
                        }),
                })}
            />
            {visible && (
                <SanbaoPopup
                    rowInfo={selectInfo.rowInfo}
                    visible={visible}
                    orderId={orderId}
                    setVisible={setVisible}
                    mode={popupMode}
                    onSuccess={() => {loadData(1)}}
                />
            )}
        </div>
    )
}
