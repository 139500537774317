import React, { useState } from "react";
import { Row } from "antd";
import { SearchItem } from "./item";
import "./header.less";

export const YhPageHeader = (props) => {
    const {
        title,
        handleReset = () => { },
        handleSearch = () => { },
        searchFields,
        searchMoreFields,
        size = "small",
        justify = "end",
        style,
        searchBtnVisible = true,
        resetBtnVisible = true,
    } = props;

    const [showMore, setShowMore] = useState(false);
    return (
        <div className="yh-header" style={style}>
            <span className="modules-name" hidden={!title}>{title}</span>
            <div
                hidden={!searchFields || searchFields?.length === 0}
                className="pub-search-box"
            >
                <Row
                    gutter={[20, 0]}
                    justify={justify}
                >
                    {searchFields?.map(
                        (el, index) => <SearchItem
                            key={index}
                            {...{ ...props, ...el, size }} />
                    )}
                </Row>
                {showMore && <Row
                    // hidden={!searchMoreFields || searchMoreFields?.length === 0}
                    gutter={[20, 0]}
                    justify="start"
                >
                    {searchMoreFields?.map(
                        (el, index) => <SearchItem
                            key={index}
                            {...{ ...props, ...el, size }}
                        />
                    )}
                </Row>}
            </div>
            <div
                hidden={!searchFields || searchFields?.length === 0}
                className="pub-search-right"
            >
                {searchBtnVisible &&
                    <span className="btn-item" onClick={() => handleSearch?.()}>
                        检索
                    </span>
                }
                {resetBtnVisible &&
                    <span className="btn-item" onClick={() => handleReset?.()}>
                        重置
                    </span>
                }
                <span hidden={!(searchMoreFields && searchMoreFields.length !== 0)} className="added-btn" onClick={() => setShowMore(!showMore)}>
                    {showMore ? "隐藏" : "更多"}
                </span>
            </div>
        </div>
    );
}