import React, { useState, useEffect, useContext } from "react"
import { Table, Empty, Modal } from "antd"
import { customerVehicleOrder } from "@/service/customerService"
import { orderPrintInfo } from "@/service/ordersService"
import "./reset.less"

const VehicleList = (props) => {
    const { orderId, visible, setVisible } = props
    const [item, setItem] = useState({ price: 0, list: [] })
    const [part, setPart] = useState({ price: 0, list: [] })
    const [other, setOther] = useState({ price: 0, list: [] })
    const [orderType, setOrderType] = useState("")
    const [orderCode, setOrderCode] = useState("")
    
    useEffect(() => {
        loadData(1)
    }, [])
    //table配置

    //默认加载
    const loadData = async () => {
        const { retData } = await orderPrintInfo({ orderId,printType:'normal' })
        const {
            orderInfo: { orderType, orderCode },
            orderItemBills,
            orderItemActualAmount,
            // orderItemThreeGuaranteesBills,
            // orderItemThreeGuaranteesAmount,
            orderMaintainMaterialBills,
            orderMaintainMaterialAmount,
            orderMaintainMaterialThreeGuaranteesAmount,
            orderMaintainMaterialThreeGuaranteesBills,
            orderOtherItemBills,
            otherItemActualAmount,
        } = retData
        setOrderType(orderType)
        setOrderCode(orderCode)
        if (orderType === "014002") {
            //三包
            setPart({
                price: orderMaintainMaterialThreeGuaranteesAmount,
                list: orderMaintainMaterialThreeGuaranteesBills,
            })
        } else if (orderType === "014001") {
            //收费
            setItem({ price: orderItemActualAmount, list: orderItemBills })
            setPart({
                price: orderMaintainMaterialAmount,
                list: orderMaintainMaterialBills,
            })
            setOther({ price: otherItemActualAmount, list: orderOtherItemBills })
        }
    }

    const partColumns = [
        {
            title: "序号",
            align: "center",
            render: (t, r, i) => i + 1,
            width: "40px",
        },
        {
            title: "商品名称",
            align: "center",
            dataIndex: "materialName",
            //  width: "200px",
        },
        {
            title: "商品编号",
            align: "center",
            dataIndex: "materialCode",
            //  width: "200px",
        },
        {
            title: "单价",
            align: "center",
            width: "100px",
            dataIndex: "unitPrice",
        },
        {
            title: "数量",
            align: "center",
            width: "100px",
            dataIndex: "materialNumber",
        },
        {
            title: "材料成本",
            align: "center",
            dataIndex: "materialCost",
        },
        {
            title: "优惠",
            align: "center",
            width: "100px",
            dataIndex: "preferentialAmount",
        },
        {
            title: "折扣",
            align: "center",
            width: "100px",
            dataIndex: "materialDiscount",
        },
        {
            title: "总金额",
            align: "center",
            width: "100px",
            dataIndex: "receivableAmount",
        },
    ]

    const itemColumns = [
        {
            title: "序号",
            align: "center",
            render: (t, r, i) => i + 1,
            width: "40px",
        },
        {
            title: "项目名称",
            align: "center",
            dataIndex: "itemName",
            //  width: "200px",
        },
        {
            title: "工时费",
            align: "center",
            dataIndex: "itemAmount",
            width: "100px",
        },
        {
            title: "优惠",
            align: "center",
            width: "100px",
            dataIndex: "preferentialAmount",
        },
        {
            title: "折扣",
            align: "center",
            width: "100px",
            dataIndex: "itemDiscount",
        },
        {
            title: "总金额",
            align: "center",
            width: "100px",
            dataIndex: "receivableAmount",
        },
    ]

    const otherColumns = [
        {
            title: "序号",
            align: "center",
            render: (t, r, i) => i + 1,
            width: "40px",
        },
        {
            title: "项目名称",
            align: "center",
            dataIndex: "itemName",
        },
        {
            title: "工时费",
            align: "center",
            dataIndex: "itemAmount",
            width: "100px",
        },
        {
            title: "总金额",
            align: "center",
            width: "100px",
            dataIndex: "itemAmount",
        },
    ]

    return (
        <Modal title="订单详情" visible={visible} width={1000} footer={null} onCancel={() => setVisible(false)} className="ant-empty-normal0">
            <div style={{ fontSize: "18px", fontWeight: "600" ,textIndent:"12px"}}>{orderCode}</div>
            {(orderType === "014002" || orderType === "014001") && (
                <div className="list-container">
                    <div className="list-header">
                        <div className="list-name">
                            <span>材料明细</span>
                            <span className="total-price">
                                总金额&nbsp;¥&nbsp;<span>{part.price}</span>
                                &nbsp;元&nbsp;({part.list.length}项){" "}
                            </span>
                        </div>
                    </div>
                    <Table size="small" rowKey={(record) => record.id} columns={partColumns} dataSource={part.list} pagination={false} scroll={{ y: 260 }} />
                </div>
            )}

            {orderType === "014001" && (
                <div className="list-container">
                    <div className="list-header">
                        <div className="list-name">
                            <span>维修项目明细</span>
                            <span className="total-price">
                                总金额&nbsp;¥&nbsp;<span>{item.price}</span>
                                &nbsp;元&nbsp;({item.list.length}项){" "}
                            </span>
                        </div>
                    </div>
                    <Table size="small" rowKey={(record) => record.id} columns={itemColumns} dataSource={item.list} pagination={false} scroll={{ y: 260 }} />
                </div>
            )}

            {orderType === "014001" && (
                <div className="list-container">
                    <div className="list-header">
                        <div className="list-name">
                            <span>其他明细</span>
                            <span className="total-price">
                                总金额&nbsp;¥&nbsp;<span>{other.price}</span>
                                &nbsp;元&nbsp;({other.list.length}项){" "}
                            </span>
                        </div>
                    </div>
                    <Table size="small" rowKey={(record) => record.id} columns={otherColumns} dataSource={other.list} pagination={false} scroll={{ y: 260 }} />
                </div>
            )}

            {
              orderType !== "014001" && orderType !== "014002" &&  <Empty />
            }
        </Modal>
    )
}
export default VehicleList
