import React, { useState, useEffect, useImperativeHandle } from "react"
import { Table, message } from "antd"
import { EditableRow, EditableCell } from "./sanbaoEditable"
import { orderThreeGuaranteesList, orderThreeGuaranteesUpdate, getOrderOne, lookMaintainer } from "@/service/ordersService"
import { getBussinessEntityList } from "@/service/dataService"
import { getPcodeDict } from "@/utils/dataStorage"
import { Modal, Button } from "antd"
import moment from "moment"
import YhIcon from "@/components/pubIcon"

//项目
export default ({ orderId, mode, visible, setShowSanbe }) => {
    const [loading, setLoading] = useState(false) //表格 loading
    const [data, setData] = useState([]) //表格数据
    const [orderDetail, setOrderDetail] = useState({}) //订单详情
    const [repairUser, setRepairUser] = useState([]) //订单详情
    // const [defaultData, setDefaultData] = useState({})
    const [dictObj, setDictObj] = useState({
        threeGuaranteesUnit: {},
        entityData:{}
    })

    //点开弹框初始下页面需要的字典数据
    const initDict = async () => {
        let threeGuaranteesUnit = {}
        let entityData = {}
        //三包品牌
        getPcodeDict("106").children.forEach((item) => {
            threeGuaranteesUnit[item.dictCode] = item.title
        })
       
        let data = {
            searchItem: {},
            pageNum: 1,
            pageSize: 10,
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },
            ],
        }
        let entityList = await getBussinessEntityList(data)
        entityList.retData.list.forEach(item => {
            entityData[item.id] = item.entityName
        })
        setDictObj({
            threeGuaranteesUnit,
            entityData
        })
    }

    useEffect(() => {
        initDict()
        // loadData(1)
    }, [])

    useEffect(() => {
        if (visible) {
            getOrderDetail()
            loadData(1)
            getLookMaintainer()
        } else {
            setOrderDetail({})
            setRepairUser([])
        }
    }, [visible])

    //获取订单详情
    const getOrderDetail = () => {
        getOrderOne(orderId).then((res) => {
            setOrderDetail(res.retData)
        })
    }

    //获取维修人员
    const getLookMaintainer = () => {
        lookMaintainer(orderId).then((res) => {
            const list = res.retData.map((item) => {
                return item.userName
            })
            // console.log(list)
            // console.log(1111)
            // console.log(defaultData)
            // setDefaultData({ ...defaultData, maintenanceStaff: list.join(",") })
            setRepairUser(list)
        })
    }

    //默认加载数据列表
    const loadData = (current, search = {}) => {
        setLoading(true)
        let data = {
            searchItem: {
                orderId,
                ...{ search },
            },
            pageNum: current,
            pageSize: 100,
            orders: [{ column: "updateTime", rule: "DESC" }],
        }
        orderThreeGuaranteesList(data).then((res) => {
            // console.log(res);
            // if (res && res.retCode === 200) {
            setData(res.retData.list)
            setLoading(false)
            // } else {
            //     // message.error(res.retMsg)
            //     message.error("请求异常")
            // }
        })
    }


    //选中展示table配置
    const columns = [
        {
            title: "三包品牌",
            dataIndex: "threeGuaranteesBrand",
            editable: true,
            render: (text) => <span>{dictObj.threeGuaranteesUnit[text]}</span>,
        },
        {
            title: "所属实体",
            dataIndex: "ciBusinessEntityId",
            editable: true,
            render: (text) => <span>{dictObj.entityData[text]}</span>,
        },
        {
            title: "维修人员",
            dataIndex: "maintenanceStaff",
            editable: true,
        },
        {
            title: "维修时间",
            dataIndex: "repairTime",
            width: 220,
            editable: true,
        },

        {
            title: "索赔单号",
            dataIndex: "claimNumber",
            editable: true,
        },
        {
            title: "故障处理",
            dataIndex: "faultHandle",
            editable: true,
        },
        {
            title: "材料费",
            dataIndex: "materialAmount",
            editable: true,
        },
        {
            title: "出库金额",
            dataIndex: "outboundAmount",
        },
        {
            title: "工时费",
            dataIndex: "hourlyWage",
            editable: true,
        },
        {
            title: "外出费",
            dataIndex: "travelExpenses",
            editable: true,
        },
        {
            title: "餐补费",
            dataIndex: "mealSupplement",
            editable: true,
        },
        {
            title: "其他费用",
            dataIndex: "otherAmount",
            editable: true,
        },
        {
            title: "索赔总额",
            dataIndex: "totalClaim",
        },
        {
            title: "结算单号",
            dataIndex: "statementNumber",
            editable: true,
        },
        {
            title: "三包工时费汇总",
            dataIndex: "totalHourlyWage",
        },
        // {
        //     title: "操作",
        //     align: "center",
        //     width: "80px",
        //     dataIndex: "operation",
        //     render: (_, record) => {
        //         return (
        //             <YhIcon
        //                 type="icon-guanbi"
        //                 style={{ color: "#D81E06", cursor: "pointer" }}
        //                 onClick={() => handleDeleteOne(record.id || record.temporaryId)}
        //             />
        //         )
        //     },
        // },
    ]

    //单元格编辑保存
    const handleSave = (row) => {
        row.totalClaim = (row.materialAmount + row.hourlyWage + row.travelExpenses + row.mealSupplement).toFixed(2)
        row.totalHourlyWage = (row.hourlyWage + row.travelExpenses + row.mealSupplement).toFixed(2)
        const newData = [...data]
        let index
        if (row.id) {
            index = newData.findIndex((item) => row.id === item.id)
        } else {
            index = newData.findIndex((item) => row.temporaryId === item.temporaryId)
        }
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }
    const maintenanceColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //判断数据是否可以保存或者新增
    const isEmpty = () => {
        let isEmpty = true
        if (data.length === 0) return isEmpty
        isEmpty = data.every((item) => !!item.threeGuaranteesBrand)
        if (!isEmpty) {
            message.error("三包品牌为必填项！")
        }
        return isEmpty
    }

    //新增一条数据
    const handleAdd = () => {
        if (isEmpty()) {
            console.log(orderDetail.orderCompletedTime)
            setData([
                ...data,
                {
                    maintenanceStaff: repairUser.join(","),
                    remark: "",
                    updateTime: "",
                    mealSupplement: 0,
                    totalStatement: "",
                    repairTime: orderDetail.orderCompletedTime ? orderDetail.orderCompletedTime :  moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    hourlyWage: 0,
                    threeGuaranteesBrand: "",
                    faultHandle: orderDetail.faultDesc,
                    materialAmount: 0,
                    travelExpenses: 0,
                    otherAmount: 0,
                    totalHourlyWage: 0,
                    statementNumber: "",
                    claimNumber: "",
                    totalClaim: 0,
                    temporaryId: new Date().getTime(),
                    ciBusinessEntityName:"",
                    ciBusinessEntityId:"",
                },
            ])
        }
    }

    /**
     * 以下是页面展示
     */
    const [choosedItem, setChoosedItem] = useState([]) // 批量删除的key
    //批量删除多选的成员id
    const maintenanceSelection = {
        columnWidth: "30px",
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRowKeys)
            setChoosedItem(selectedRowKeys)
        },
    }

    //批量删除
    const deleteTemaAll = () => {
        if (choosedItem.length === 0) {
            message.warning("请选择你要删除的维修项目")
            return
        }
        let arr = data.filter((item) => choosedItem.indexOf(item.id || item.temporaryId) < 0)
        setData(arr)
        setChoosedItem([])
    }

    //删除
    const handleDeleteOne = (keys) => {
        const newData = []
        data.forEach((item) => {
            if (keys != item.id && keys != item.temporaryId) {
                newData.push(item)
            }
        })
        setData(newData)
    }

    const onOk = () => {
        if (!isEmpty()) return false
        let threeGuarantees = data.map((item) => {
            item.repairTime = item.repairTime ? moment(item.repairTime).format().split("+")[0] : null
            return item
        })
        orderThreeGuaranteesUpdate({ orderId, threeGuarantees }).then((res) => {
            loadData(1)
            message.success("保存成功")
            setShowSanbe(false)
        })
    }

    const onCancel = () => {
        setShowSanbe(false)
    }

    return (
        <Modal
            visible={visible}
            title="三包信息"
            width={1260}
            onCancel={onCancel}
            footer={[
                <Button onClick={onCancel} key="back">
                    {" "}
                    取消
                </Button>,
                <Button type="primary" onClick={onOk} key="submit">
                    保存
                </Button>,
            ]}
        >
            <div className="list-but" style={{ marginBottom: "20px" }}>
                <Button shape="round" className="but-left" style={{ marginRight: "20px" }} onClick={handleAdd}>
                    新增
                </Button>
                {/* <Button shape="round" className="but-left" onClick={deleteTemaAll}>
                    批量删除
                </Button> */}
            </div>
            <Table
                size="small"
                rowKey={(record) => (record.id ? record.id : record.temporaryId)}
                components={components}
                columns={maintenanceColumns}
                dataSource={data}
                pagination={false}
                loading={loading}
                scroll={{ x: 1800, y: 660 }}
                rowSelection={maintenanceSelection}
            />
        </Modal>
    )
}
