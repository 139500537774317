import React, { useRef, useEffect, useState } from "react";
import { message } from "antd";
import YhDrawer from "@/erp_subpackage/components/YhDrawer";
import { YhForm } from "@/erp_subpackage/components/YhFormBox";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { debounce } from "lodash";
import { usePurchaseRoDetailsModel } from "./purchaseRoDetailModel";
import { getFormValidatorErrText,repairFormatNum,formatNum } from "@/erp_subpackage/utils/util"
import { IPayMethodCreditDs } from "@/erp_subpackage/utils/ErpEnum"
import { defaultMdCmParam } from "@/erp_subpackage/model/globalModel";
import {purchaseRoService} from '../../purchaseRoService'
import { IsFranchisees } from '@/utils/common'


export const SettleDrawer = ({ modeKey, mode, rowCode, dispatch }) => {
    const { settleVisible: visible, editFormVal, lookFormVal, setSettleVisible, onSettle,addFormVal,updateFormVal } = usePurchaseRoDetailsModel();
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => []);
    const { user: { userName,shopCode } } = useGlobalModel();
    const { sourceApp, branch } = defaultMdCmParam;
    const formVal = mode === "edit" ? editFormVal : lookFormVal;
    const [settleType, setSettleType] = useState(formVal.payType);   //结算类型
    const [cash, setCash] = useState(false);
    const [accountTypeDs, setAccountTypeDs] = useState([]) //实时存取收款账户类型值settle-type.cash
    const [newCase,setNewcase] = useState('')
    const formRef = useRef();
    const typeForm = {
        add: addFormVal,
        edit: editFormVal,
        look: lookFormVal
    }
    const [editPayMethodOptions, setEditPayMethodOptions] = useState(getSelectedDictOne(DictCodeEnmu.PAY_METHOD))//编辑根据结算类型展示结算方式
    useEffect(() => {
        console.log(111)
        console.log(lookFormVal)
        // visible && setSettleType(formVal.payType)
        visible &&queryAccount(typeForm[mode].supplierCode, typeForm[mode].payType === DictCodeEnmu.SETTLE_TYPE_CASH ? 0 :  typeForm[mode].payType ===  DictCodeEnmu.SETTLE_TYPE_BALANCE ? 1 : 1,  typeForm[mode].payType)
    }, [visible])

    const setPayMethodOptions = (payType) => {
        let newPayMethodOptions = getSelectedDictOne(DictCodeEnmu.PAY_METHOD).filter((item) => {
            if (item.relationvalue === payType) {
                return item
            }
        })
        setEditPayMethodOptions(newPayMethodOptions)
    }
    // 查询客户账户类型及可用额度
    const queryAccount =async (partnerCode, creditFlag, payType)=>{
        //console.log(creditFlag)
         //加盟商不需要查询账户
         if (IsFranchisees()) {
            return
        }
        if(mode=='look'){ 
          //  debugger
            const {settleDetails} =lookFormVal
            const {payMethodName}=settleDetails[0]
            let cashname1 = payMethodName
           
            updateFormVal(mode,{
                cashname1,...lookFormVal
            })
            console.log(lookFormVal)
        }
        let params = {
            partnerCode,  //供应商编码 
            beCode: typeForm[mode].bentityCode,
            status: 0,
            creditFlag,  //为记账类型的  1挂账 2现结
            sourceApp,
            shopCode,
        }
        const { retData } = await purchaseRoService.accountFind(params)
        //根据优惠类型， 过滤授信账户的 （返利|授信）
        let filterResult = retData.filter(item => {
            return item;
        })
        let newAccTypeDs = [] // filterResult.map(item => ({ label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }));
        if (payType === DictCodeEnmu.SETTLE_TYPE_CASH) {
             newAccTypeDs = filterResult.map(item => ({ label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0, accountTypeCode: item.accountTypeCode, key: item.key ?? "" }));
             console.log(newAccTypeDs)
        } else if (payType ===DictCodeEnmu.SETTLE_TYPE_CREDIT) {//挂账  account-type.credit
            newAccTypeDs = filterResult.filter(item => {
                if (item.accountTypeCode !== "account-type.credit-month") return { label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }
            }).map(item1 => ({ label: item1.name ?? "", value: item1.code ?? "", available: item1.availableAmount ?? 0, accountTypeCode: item1.accountTypeCode , key: item1.key ?? "" }));
            console.log(newAccTypeDs)
            // newAccTypeDs = filterResult.map(item1 => ({ label: item1.name ?? "", value: item1.code ?? "", available: item1.availableAmount ?? 0, accountTypeCode: item1.accountTypeCode , key: item1.key ?? "" }));
        } else if (payType === DictCodeEnmu.SETTLE_TYPE_BALANCE) {//月结  account-type.credit-month
            newAccTypeDs = filterResult.filter(item => {
                if (item.accountTypeCode == "account-type.credit-month") return { label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }
            }).map(item1 => ({ label: item1.name ?? "", value: item1.code ?? "", available: item1.availableAmount ?? 0, accountTypeCode: item1.accountTypeCode , key: item1.key ?? "" }));
            console.log(newAccTypeDs)
            //  newAccTypeDs = filterResult.map(item1 => ({ label: item1.name ?? "", value: item1.code ?? "", available: item1.availableAmount ?? 0, accountTypeCode: item1.accountTypeCode , key: item1.key ?? "" }));
        }
        setAccountTypeDs(newAccTypeDs);
        let accTypeDs= newAccTypeDs[0]
        if (newAccTypeDs.length > 0) {
            setCash(accTypeDs.accountTypeCode === "account-type.cash");
            formRef.current.setFieldsValue({ payMethodName: accTypeDs.label, payMethodCode: accTypeDs.value, payMethodValue: accTypeDs.accountTypeCode, available: repairFormatNum(accTypeDs?.available) }); 
        } else {
            setCash(false);
            message.warning(`该${typeForm[mode].supplierName}供应商没有对应账户`);
            formRef.current.setFieldsValue({ payMethodName: undefined, payMethodCode: undefined, payMethodValue: undefined, available: undefined });
        }
        
    }

    //每次类型选择重置表格数据
    const settleTypeChange = (value, option) => {
        //加盟商不需要查询账户
        if (option && !IsFranchisees()) {
            setSettleType(option.value);
            //重置支付方式
            //formRef.current.setFieldsValue({ payType: option.value, payMethodCode: undefined });
            queryAccount(typeForm[mode].supplierCode,value === DictCodeEnmu.SETTLE_TYPE_CASH ? 0 : value ===  DictCodeEnmu.SETTLE_TYPE_BALANCE ? 1 : 1, value)
            setPayMethodOptions(value)
        }
    }
    const payMethodChange = (value, option) => {
        if (option.accountTypeCode === DictCodeEnmu.ACC_TYPE_CASH) {
            setCash(true);
        } else {
            setCash(false);
        }
        return option && formRef.current.setFieldsValue({
            payMethodName: option.label,
            payMethodValue: option.accountTypeCode,
            available: formatNum(option.available),
            cashName: undefined,
            cashCode: undefined
        });
    }
    const cashSelectChange = (value, option) => {
        setNewcase(option.label)
        return  option && formRef.current.setFieldsValue({ cashName: option.label, cashCode: option.value });
     }

    const onCancel = () => {
        setSettleVisible(false);
    }

    const [okLoading, setOkLoading] = useState(false)
    //加盟商结算
    const franchiseesSettle = () => {
        formRef.current.validateFields().then(async (val) => {
            let {
                payType,
                payableAmount,
                note
            } = val
            let params = {
                id: formVal.id,
                payableAmount,
                payType,
                reckoner: userName,
                note: note,
            }
            setOkLoading(true)
            onSettle(params).then((res) => {
                res && onCancel()
            }).finally(() => {
                setTimeout(() => {
                    setOkLoading(false)
                }, 1000);
            })
        })
    }

   
    //表单提交
    const onSubmit = async () => {
        if (IsFranchisees()) {
            //加盟商结算
            return franchiseesSettle()
        }
    
        formRef.current.validateFields().then(val => {
            let { payType, payMethodName, payableAmount, returnCode, note, payMethodCode, payMethodValue, cashCode, cashAttach, cashAttachFileName } = val
            let param =   {
                id: formVal.id,
                returnCode,
                note,
                reckoner: userName,
                payType,
                accountCode: payMethodCode,
                accountName: accountTypeDs.find(item => item.value === payMethodCode).label,
                accountType: accountTypeDs.find(item => item.value === payMethodCode).accountTypeCode,
                payableAmount,
                payMethodCode: cashCode,
                payMethodName: newCase,
                // payMethodName: accountTypeDs.find(item => item.value === payMethodCode).label,
                // payMethodCode,
                details: accountTypeDs.find(item => item.value === payMethodCode).accountTypeCode!== "account-type.cash" ? [] : [
                    
                    {
                        payMethodCode: cashCode,
                        payMethodName: newCase,
                        accountName: accountTypeDs.find(item => item.value === payMethodCode).label,
                        accountCode: payMethodCode,
                        amount: payableAmount,
                        payee: "",
                        cashAttach, 
                        cashAttachFileName
                    }
                ]
            }
            setOkLoading(true)
            onSettle(
                param
            ).then(res => {
                res && onCancel()
            }).finally(() => {
                setTimeout(() => {
                    setOkLoading(false)
                }, 1000);
            })
        })
        // .catch(err => {
        //     message.warning(getFormValidatorErrText(err));
        // })
    }

     //图片变更缓存保留  保留缓存及回显表单
     const onSetFieldsValue = (formValues) => {
        updateFormVal(mode, formValues)
    }

    const formConfig = {
        formRef,
        formValues: formVal,
        labelCol: { span: 6 },
        disabled: mode === "look",
        items: [
            {
                type: "Input",
                fieldLabel: "采购退货单",
                fieldName: "returnCode",
                span: 24,
                disable: true
            }, {
                type: "Input",
                fieldLabel: "结算日期",
                fieldName: "settleTime",
                span: 24,
                disable: true
            }, {
                type: "Input",
                fieldLabel: "供应商名称",
                fieldName: "supplierName",
                span: 24,
                disable: true
            }, {
                type: "Input",
                fieldLabel: "供应商编码",
                fieldName: "supplierCode",
                span: 24,
                disable: true
            }, {
                type: "Input",
                fieldLabel: "采购实体",
                fieldName: "bentityName",
                span: 24,
                disable: true
            }, {
                type: "Input",
                fieldLabel: "所属门店",
                fieldName: "shopName",
                span: 24,
                disable: true
            }, {
                type: "Input",
                fieldLabel: "应收金额",
                fieldName: "payableAmount",
                span: 24,
                disable: true
            },
            // {
            //     type: "Input",
            //     fieldLabel: "已收金额",
            //     fieldName: "paidAmount",
            //     span: 24,
            //     disable: true
            // },
            {
                type: "Select",
                fieldLabel: "结算类型",
                fieldName: "payType",
                onSelectChange: settleTypeChange,
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                rules: [{ required: true, message: "请选择支付方式" }],
                span: 24,
            }, 
            ...((str) => {
                if (str) {
                    return [
                        {
                            type: "Select",
                            fieldLabel: "支付方式",
                            fieldName: "payMethodCode",
                            onSelectChange: payMethodChange,
                            // options: settleType ? getSelectedDictOne(DictCodeEnmu.PAY_METHOD).filter(item => {
                            //     if (settleType === DictCodeEnmu.SETTLE_TYPE_CREDIT) {
                            //         return item.value === DictCodeEnmu.PAY_METHOD_LIMIT
                            //     } else {
                            //         return item.value !== DictCodeEnmu.PAY_METHOD_LIMIT
                            //     }
                            // }) : [],
                            options:accountTypeDs,
                            //options: settleType ? settleType === DictCodeEnmu.SETTLE_TYPE_BALANCE || settleType === DictCodeEnmu.SETTLE_TYPE_CREDIT ? IPayMethodCreditDs : getSelectedDictOne(DictCodeEnmu.PAY_METHOD) : [],
                            rules: [{ required: true, message: "请选择支付方式" }],
                            span: 24,
                            // hidden: mode === "look"
                        },
                    ]
                } else {
                    return []
                }
            })(!IsFranchisees()),
            
           
            // {
            //     type: "Input",
            //     fieldLabel: "支付方式",
            //     fieldName: "payMethodName",
            //     span: 24,
            //     hidden: !(mode === "look")
            // },
            // {
            //     type: "Input",
            //     fieldLabel: "现金类支付",
            //     fieldName: "cashName",
            //     hidden: !(mode === "look"),
            //     span: 24,
            // },
            ...((str) => {
                if( IsFranchisees()) {
                    return []
                }
                if (str) {
                    let arr = []
                    if (mode == 'look') {
                        arr = [
                            {
                                type: 'Input',
                                fieldLabel: '现金类支付',
                                fieldName: 'cashname1',
                                span: 24,
                            },
                        ]
                    } else {
                        arr = [
                            {
                                type: 'Select',
                                fieldLabel: '现金类支付',
                                fieldName: 'cashCode',
                                span: 24,
                                options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD_CASHS),
                                onSelectChange: cashSelectChange,
                                // hidden: mode === "look"
                            },
                        ]
                    }
                    return [
                        ...arr,
                        {
                            type: 'FormUpload',
                            fieldLabel: '附件',
                            fieldName: 'cashAttachFileName',
                            fieldMd5: 'cashAttach',
                            defaultFileName: formVal.cashAttachFileName,
                            defaultFileMd5: formVal.cashAttach,
                            onSetFieldsValue,
                            span: 24,
                            isUsePortalApi: true
                        },
                    ]
                } else {
                    return [
                        {
                            type: 'Input',
                            fieldLabel: '可用额度',
                            fieldName: 'available',
                            span: 24,
                            hidden: mode === 'look',
                            disable: true,
                        },
                    ]
                }
            })(cash),
            // }, {
            //     type: "Select",
            //     fieldLabel: "结算方式",
            //     fieldName: "payMethodCode",
            //     onSelectChange: payMethodChange,
            //     options: editPayMethodOptions,
            //     span: 24,
            // }, 
            {
                type: "Input",
                fieldLabel: "结算备注",
                fieldName: "note",
                span: 24,
            },
            // {
            //     type: "Input",
            //     fieldLabel: "支付方式对应得账户类型",
            //     fieldName: "payMethodValue",
            //     hidden: true
            // },
        ]
    };

    return (
        <YhDrawer
            title="采购付款"
            visible={visible}
            onClose={onCancel}
            okText="保存"
            width={600}
            onOk={debounce(() => { onSubmit() }, 600)}
            showFooter={formVal.status === DictCodeEnmu.PRO_SETTLE || mode !== "look"}
            okLoading={okLoading}
        >
            <div style={{ padding: "20px 36px" }}><YhForm {...formConfig} /></div>
        </YhDrawer>
    )
}
