import React, { useEffect, useState, useRef } from 'react'
import { getVehicleOne, getOne } from '@/service/customerService'
import { Col, message, Modal, Row, Form, Select, Spin, Table } from 'antd'
import styled from '@emotion/styled'
import { getCustomerListNew, getVehicleList, vehicleTransfer } from '@/service/customerService'
import debounce from 'lodash/debounce'
const { Option } = Select
const MoveVehicle = (props) => {
    const { visible, customerData, vehicleData, setVisible, onSuccess } = props
    const [form] = Form.useForm()
    const [dataSource, setDataSource] = useState([])
    const [select, setSelect] = useState([])
    const [selectKeys, setSelectKeys] = useState([])

    const [oldCustomer, setOldCustomer] = useState({})
    const [newCustomer, setNewCustomer] = useState({})
    const [oldCustomerInfo, setOldCustomerInfo] = useState({
        id: '', //客户列表
        data: [],
        fetching: false,
    })
    const [newCustomerInfo, setNewCustomerInfo] = useState({
        id: '', //客户列表
        data: [],
        fetching: false,
    })

    //订单新增， 带过来客户信息就设置上
    useEffect(() => {
        if (oldCustomer.id) {
            loadData()
        }
    }, [oldCustomer]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (vehicleData && vehicleData.length > 0) {
            setSelect(vehicleData)
            setSelectKeys(vehicleData.map((i) => i.id))
        }
    }, [vehicleData]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const { id, name } = customerData
        if (id && name) {
            setOldCustomerInfo({
                id: '', //客户列表
                data: [{ text: name, value: id }],
                fetching: false,
            })
            setOldCustomer({ ...customerData })
            form.setFieldsValue({ oldCustomerId: id })
        }
    }, [customerData])

    //默认加载
    const loadData = () => {
        let data = {
            searchItem: {
                customerId: oldCustomer.id,
            },
            pageNum: 1,
            pageSize: 100000,
        }
        getVehicleList(data)
            .then((res) => {
                setDataSource(res.retData.list)
            })
            .catch((err) => {
                setDataSource([])
            })
    }

    const handleCancel = () => {
        form.resetFields()
        setSelect([])
        setSelectKeys([])
        setDataSource([])
        setOldCustomer([])
        setVisible(false)
    }

    const onSubmit = async () => {
        form.validateFields().then((values) => {
            const { customerId } = values
            const vehicleInfoList = select.map((i) => ({ id: i.id, vehicleCode: i.vehicleCode }))
            if (vehicleInfoList.length === 0) {
                return message.error('请选择要转移的车辆')
            }
            vehicleTransfer({ customerId, vehicleInfoList }).then((res) => {
                message.success('车辆转移成功')
            })
            handleCancel()
            onSuccess && onSuccess()
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleChangeCustomer = (value, option, type) => {
        type === 'old' ? setOldCustomer({ ...option, id: value }) : setNewCustomer({ ...option, id: value })
    }

    //客户列表查询
    const customerQuery = (value, type) => {
        type === 'old'
            ? setOldCustomerInfo({
                  ...oldCustomerInfo,
                  data: [],
                  fetching: true,
              })
            : setNewCustomerInfo({
                  ...newCustomerInfo,
                  data: [],
                  fetching: true,
              })

        getCustomerListNew({
            pageSize: 15,
            currentPage: 1,
            filterCustomer: 1,
            searchItem: { customerName: value },
        }).then((res) => {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                if (item.customerStatus === 0) {
                    arr.push({
                        text: item.customerName,
                        value: item.id,
                    })
                }
                return arr
            }, [])
            type === 'old'
                ? setOldCustomerInfo({
                      ...oldCustomerInfo,
                      data,
                      fetching: true,
                  })
                : setNewCustomerInfo({
                      ...newCustomerInfo,
                      data,
                      fetching: true,
                  })
        })
    }
    //防抖
    const customerQuerys = debounce(customerQuery, 1000)

    const columns = [
        {
            title: '车辆牌照',
            dataIndex: 'vehicleCode',
        },
        {
            title: '车辆品牌',
            dataIndex: 'vehicleCode',
        },
    ]

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectKeys(selectedRowKeys)
            setSelect(selectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
        selectedRowKeys: selectKeys,
    }

    return (
        <Modal visible={visible} title="转移车辆" centered={true} onOk={() => onSubmit()} onCancel={() => handleCancel(false)} width={760}>
            <Form form={form} {...formItemLayout}>
                <Row gutter={20}>
                    <Col span={24}>
                        <Form.Item label="客户名称" name="oldCustomerId" rules={[{ required: true, message: '请选择客户' }]}>
                            <Select
                                showSearch
                                allowClear
                                placeholder="请选择客户"
                                notFoundContent={oldCustomerInfo.fetching ? <Spin size="small" /> : null}
                                filterOption={false}
                                onChange={(value, option) => {
                                    handleChangeCustomer(value, option, 'old')
                                }}
                                onFocus={() => customerQuery('', 'old')}
                                onSearch={(val) => {
                                    customerQuerys(val, 'old')
                                }}
                                style={{ width: '100%' }}
                            >
                                {oldCustomerInfo.data.map((item) => (
                                    <Option value={item.value} key={item.value}>
                                        {item.text}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20} style={{ marginBottom: '20px' }}>
                    <Col span={4} style={{ color: '#6b6b6b', textAlign: 'right', padding: 0 }}>
                        转移的车辆:
                    </Col>
                    <Col span={18}>
                            <Table
                                className="table"
                                columns={columns}
                                dataSource={dataSource}
                                rowKey={(record) => record.id}
                                rowSelection={{
                                    type: 'checkbox',
                                    ...rowSelection,
                                }}
                                pagination={false}
                                scroll={{ y: 420 }}
                            />
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={24}>
                        <Form.Item label="转移到客户" name="customerId" rules={[{ required: true, message: '请选择客户' }]}>
                            <Select
                                showSearch
                                allowClear
                                placeholder="请选择客户"
                                notFoundContent={newCustomerInfo.fetching ? <Spin size="small" /> : null}
                                filterOption={false}
                                onChange={(value, option) => {
                                    handleChangeCustomer(value, option, 'new')
                                }}
                                onFocus={() => customerQuery('', 'new')}
                                onSearch={(val) => {
                                    customerQuerys(val, 'new')
                                }}
                                style={{ width: '100%' }}
                            >
                                {newCustomerInfo.data.map((item) => (
                                    <Option value={item.value} key={item.value}>
                                        {item.text}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

const Box = styled.div`
   .ant-table-tbody{
       height: 380px
   }
`
export default MoveVehicle
