import React, { useRef } from "react";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { useSaleSoDetailModel } from "./saleSoDetailModal";
import { YhFormBox } from "@/erp_subpackage/components/YhFormBox";

export const DiscountModel = () => {
    const { discountModal: visible, setDiscountModal } = useSaleSoDetailModel();
    const formRef = useRef();

    const onCancel = () => {
        setDiscountModal(false);
    };
    const onFinish = (val) => {

    }
    //监听批量录入字段
    const onChange = (changedValues) => {
        if ('isBatch' in changedValues) {
        }
    }

    //表单提交
    const onSubmit = async () => {

    }

    const ItemBox = {
        style: { padding: "60px 30px 80px 10px" },
        items: [
            {
                type: 'Select',
                fieldLabel: "折扣类型",
                fieldName: "note",
                options: [{ label: "1", value: "1" }],
                span: 24,
            }, {
                type: 'Input',
                fieldLabel: "折扣系数",
                fieldName: "code",
                span: 24,
                suffix: "%"
            }
        ]
    }
    const formConfig = {
        formRef,
        onFinish,
        formValues: {},
        labelCol: { span: 6 },
        onChange,
        boxs: [ItemBox]
    };
    return <YHModal
        visible={visible}
        onOk={onCancel}
        onCancel={onCancel}
        title="请输入全部系数折扣"
        width={500}
        bodyHeight={200}
        showFooter={true}
    >
        <YhFormBox {...formConfig} />
    </YHModal>
}
