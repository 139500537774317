import React, { useRef, useEffect, useState } from "react";
import { Table, Drawer, Form, Input, Row, Col, Button, message } from "antd";
import { useContractModel } from '../contractParticularsModel'
import { usecontractDetailsModel } from './detailsModel'
import { YhBut } from '@/erp_subpackage/components/YhButton'
import { EditableRow, EditableCell } from "@/erp_subpackage/components/YHEditTable/editTable";
import { getPcodeDict } from "@/utils/dataStorage"

export const ViechDrawer = ({ mode }) => {
    const {
        vehicleData, searchParam, choose, addSelect, editSelect,
        newVehicleData, editFormVal, addFormVal, selectedVehicle,
        setChoose, setSearchParam, setVehicleData, setEditSelect, setAddSelect,
        setSelectedVehicle, addSelectedVehicles, selectAllVehicles,
    } = usecontractDetailsModel();
    const { vehicleVisable, setVehicleVisable } = useContractModel()
    const listLength = mode === 'add' ? addSelect.length : editSelect.length
    const addVechielSelect = mode === 'add' ? addSelect : editSelect

    const [model, setModel] = useState('')
    const [dictObj, setDictObj] = useState(null)

    const formval = mode === 'add' ? addFormVal : editFormVal

    useEffect(() => {
        initDict();
        setModel(mode)
        setChoose(listLength)
    }, [vehicleVisable])

    const initDict = () => {
        let obj = {
            bigBagMode: {},
        }
        getPcodeDict("047").children.forEach((item) => {
            obj.bigBagMode[item.dictCode] = item.title
        })
        setDictObj(obj)
    }

    //车辆列表上查询
    const handleSearch = (value) => {
        // 进行模糊检索
        const filteredResults = newVehicleData.filter((item) =>
            item.vehicleCode.includes(value)
        );
        setVehicleData(filteredResults);

    }

    const columns = [
        {
            title: '车辆牌照',
            width: 80,
            dataIndex: 'vehicleCode',
            fixed: "left",
        },
        {
            title: '车辆品牌',
            width: 80,
            dataIndex: 'brandId',
            fixed: "left",
        },
        {
            title: '签约类型',
            width: 80,
            dataIndex: 'signType',
            render: (text) => { return text === '046001' ? text = '大包' : text === '046002' ? text = "月结" : text = "" }
        },
        {
            title: '大包模式',
            width: 80,
            dataIndex: 'bigBagMode',
            render: (text) => <>{dictObj.bigBagMode[text]}</>,
        },
        {
            title: '签约里程',
            width: 100,
            dataIndex: 'signMileage',
        },
        {
            title: "操作",
            align: "center",
            dataIndex: 'enable',
            width: 50,
            render: (text, record) =>
                <YhBut
                    type="add"
                    disabled={record.signFlag === 1 ||
                        !selectedVehicle.every(item => item.vehicleId !== record.vehicleId) ? true : false ||
                            !addVechielSelect.every(item => item.vehicleId !== record.vehicleId) ? true : false
                    }
                    click={() => {
                        setSelectedVehicle([...selectedVehicle, record]);
                    }}
                />
        }
    ]

    const selectedColumns = [
        {
            title: '车辆牌照',
            width: 80,
            dataIndex: 'vehicleCode',
            fixed: "left",
        },
        {
            title: '车辆品牌',
            width: 80,
            dataIndex: 'brandId',
            fixed: "left",
        },
        {
            title: '签约类型',
            width: 80,
            dataIndex: 'signType',
            render: (text) => { return formval.signType === '046001' ? text = '大包' : formval.signType === '046002' ? text = "月结" : text = "" }
        },
        {
            title: '大包模式',
            width: 80,
            dataIndex: 'bigBagMode',
            render: (text) => <>{dictObj.bigBagMode[formval.bigBagMode]}</>,
        },
        {
            title: '签约里程',
            width: 100,
            dataIndex: 'signMileage',
            editable: (formval.bigBagMode == '047002' || formval.bigBagMode == '047001') ? true : false,
            render: (text) => { return text ?? 0 }
        },
        {
            title: "操作",
            align: "center",
            dataIndex: 'enable',
            width: 50,
            render: (text, record) =>
                <YhBut
                    type="delete"
                    click={() => {
                        let list = selectedVehicle.filter(e => e.vehicleCode != record.vehicleCode);
                        setSelectedVehicle(list);

                        const index = addVechielSelect.findIndex(item => item.vehicleCode === record.vehicleCode);
                        if (index > -1) {
                            addVechielSelect.splice(index, 1);
                            mode === 'edit' ? setEditSelect(addVechielSelect) : setAddSelect(addVechielSelect);
                        }
                    }}
                />
        },
    ]

    const onCancel = () => {
        setVehicleVisable(false)
    }
    const newSelectColumns = selectedColumns.map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                editType: col?.editType,
                editable: col?.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑   (计算价格)
    const handleSave = (row) => {
        const updatedsignMileage = Number(row.signMileage).toFixed(2);
        // 在这里处理保存逻辑，将修改后的值存储到列表中的相应位置
        const updatedList = selectedVehicle.map(item => {
            if (item.vehicleId === row.vehicleId) {
                return {
                    ...item,
                    signMileage: updatedsignMileage
                };
            }
            return item;
        });
        setSelectedVehicle(updatedList)
    }
    return (
        <Drawer
            title={<>
                <span>选择车辆</span>
                <span style={{ float: "right" }}>{vehicleData.length}/{choose}</span>
            </>}
            open={vehicleVisable}
            onClose={onCancel}
            width={700}>
            <div>
                <Row>
                    <Col span={8}>
                        <Form >
                            <Form.Item >
                                <Input allowClear placeholder="请输入车辆牌照回车检索" shape="round" value={searchParam} onChange={(e) => { setSearchParam(e.target.value); handleSearch(e.target.value) }} />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={8} offset={8}>
                        <Button type="primary" shape="round" onClick={() => {
                            selectAllVehicles(mode)
                        }}>选择全部车辆</Button>
                    </Col>
                </Row>
            </div>
            <div style={{ marginTop: "10px" }}>
                <div style={{ margin: "10px", fontSize: 16, fontWeight: 'bold' }}>待选车辆列表：</div>
                <Table
                    rowKey={record => record.vehicleId}
                    columns={columns}
                    dataSource={vehicleData}
                    scroll={{ y: 600 }}
                />
            </div>

            <div style={{ marginTop: "10px" }}>
                <div style={{ margin: "10px", fontSize: 16, fontWeight: 'bold' }}>已选车辆列表：</div>
                <Table
                    rowKey={record => record.vehicleId}
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        },
                    }}
                    columns={newSelectColumns}
                    dataSource={selectedVehicle}
                    scroll={{ y: 600 }}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', gap: '30px', marginTop: '30px' }}>
                <Button type="primary" shape="round" onClick={() => { addSelectedVehicles(model); setVehicleVisable(false); }}>保存</Button>
                <Button type="primary" shape="round" onClick={() => { setVehicleVisable(false); }}>返回</Button>
            </div>

        </Drawer >

    )
}