import React, { useContext, useEffect, useState } from "react"
import { Button, Col, Row, message, Select, Divider, DatePicker, Table, InputNumber } from "antd"
import { MyContext } from "@/store/myContext"
import "./statistics.less"
import moment from "moment"

import { confirmSettleOrder, customerUserUnsettledOrder } from "@/service/accountStaticSummary"
const { RangePicker } = DatePicker

const { Option } = Select
const SettledOrder = ({ rowInfo, customerName }) => {
    console.log(rowInfo)
    const [loading, setLoading] = useState(false) //团队列表loading
    const [searchValue, setSearchValue] = useState({}) //搜索

    const { dispatch, state } = useContext(MyContext)
    const [pagination, setpagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [param, setParam] = useState({
        startTime: moment(new Date()).subtract(1, "year").format("YYYY-MM-DD"),
        endTime: moment(new Date()).format("YYYY-MM-DD"),
    })
    const [settlementType, setSettlementType] = useState(0)
    const [settlementAmount, setSettlementAmount] = useState(0)
    const [customerUserUnsettledOrderOne, setCustomerUserUnsettledOrderOne] = useState({
        totalAmount: 0, //账单金额
        orderNum: 0, //单据数量
        orderList: { orders: [] },
    })
    useEffect(() => {
        if (state.activeMenu === "statis/settledOrder") {
            loadData(pagination.current)
        }
        return () =>
            setCustomerUserUnsettledOrderOne({
                totalAmount: 0, //账单金额
                orderNum: 0, //单据数量
                orderList: { orders: [] },
            })
    }, [pagination.current, state.activeMenu])

    //分页事件
    const onTableChange = async (pagination) => {
        setpagination({
            ...pagination,
            current: pagination.current,
        })
    }

    const loadData = async (current, search = searchValue) => {
        setLoading(true)
        const params = {
            searchItem: {
                ...search,
                customerUserId: rowInfo.id,
                settlementType,
                settlementAmount,
            },
            pageNum: current,
            pageSize: 10,
            startTime: moment(param.startTime).format("YYYY-MM-DD") + "T16:00:00.000",
            endTime: moment(param.endTime).format("YYYY-MM-DD") + "T16:00:00.000",
            orders: [
                // {
                //     column: "createTime",
                //     rule: "DESC",
                // },
            ],
        }
        customerUserUnsettledOrder(params).then((res) => {
            console.log(res)
            // if (res && res.retCode === 200) {
                setpagination({
                    current: current,
                    total: res.retData.orderList.total,
                    pageSize: 10,
                })
                setCustomerUserUnsettledOrderOne(res.retData)
                setLoading(false)
            // } else {
            //     message.error("加载数据失败")
            // }
        })
    }

    // @VoAnnotate("订单ID")
    // private String orderId;

    // @VoAnnotate("工单编号")
    // private String orderCode;

    // @VoAnnotate("工单类型")
    // private String orderType;

    // @VoAnnotate("车辆牌照")
    // private String customerVehicleCode;

    // @VoAnnotate("联系人")
    // private String customerCorporation;

    // @VoAnnotate("联系人电话")
    // private String customerCorporationPhone;

    // @VoAnnotate("服务地点")
    // private String repairAddress;

    // @VoAnnotate("工时费")
    // private float itemActualAmount;

    // @VoAnnotate("配件费")
    // private float partActualAmount;

    // @VoAnnotate("应收金额")
    // private float orderTotalAmount;

    // @VoAnnotate("实收金额")
    // private float orderActualAmount;

    // @VoAnnotate("竣工日期")
    // private String finishTime;

    const columns = [
        {
            title: "工单编号",
            align: "center",
            dataIndex: "orderCode",
        },
        {
            title: "工单类型",
            align: "center",
            dataIndex: "orderType",
        },
        {
            title: "车辆牌照",
            align: "center",
            dataIndex: "customerVehicleCode",
        },
        {
            title: "联系人",
            align: "center",
            dataIndex: "customerCorporation",
        },
        {
            title: "联系人电话",
            align: "center",
            dataIndex: "customerCorporationPhone",
        },

        {
            title: "服务地点",
            align: "center",
            dataIndex: "repairAddress",
        },

        {
            title: "工时费",
            align: "center",
            dataIndex: "itemActualAmount",
        },
        {
            title: "配件费",
            align: "center",
            dataIndex: "partActualAmount",
        },
        {
            title: "应收金额",
            align: "center",
            dataIndex: "orderTotalAmount",
        },
        {
            title: "实收金额",
            align: "center",
            dataIndex: "orderActualAmount",
        },
        {
            title: "1",
            align: "center",
            dataIndex: "finishTime",
        },
    ]

    return (
        <>
            <div className="list-container">
                <div className="list-header">
                    <Row align="middle ">
                        <Col
                            style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                color: "#434343",
                            }}
                        >
                            <div>{customerName}</div>
                        </Col>
                    </Row>
                    <div className="pub-search-boxs">
                        <Row align="middle " gutter="10">
                            <div
                                style={{
                                    fontWeight: 700,
                                    color: "#7d7d7d",
                                    width: "87px",
                                    marginLeft: "9px",
                                    verticalAlign: "top",
                                }}
                            >
                                生成方式
                            </div>
                            <Select
                                className="pub-select"
                                size="small"
                                defaultValue={0}
                                style={{ width: 120 }}
                                onChange={(e) => {
                                    setSettlementType(e)
                                }}
                            >
                                <Option value={0}>全部</Option>
                                <Option value={2}>按金额</Option>
                                <Option value={1}>日期</Option>
                            </Select>
                            {settlementType === 1 ? (
                                <RangePicker
                                    size="small"
                                    className="search-input"
                                    defaultValue={[moment(param.startTime, "YYYY/MM/DD"), moment(param.endTime, "YYYY/MM/DD")]}
                                    ranges={{
                                        最近7天: [moment(new Date()).subtract(1, "weeks"), moment(new Date())],
                                        最近30天: [moment(new Date()).subtract(1, "month"), moment(new Date())],
                                    }}
                                    onChange={(dates, dateStrings) => {
                                        setParam({
                                            startTime: dates[0],
                                            endTime: dates[1],
                                        })
                                    }}
                                />
                            ) : null}
                            {settlementType === 2 ? (
                                <InputNumber
                                    size="small"
                                    className="search-input"
                                    placeholder="请输入金额"
                                    style={{ width: 200 }}
                                    onChange={(e) => {
                                        console.log(e)
                                        setSettlementAmount(e)
                                    }}
                                />
                            ) : null}
                            <span
                                className="common"
                                onClick={() => {
                                    loadData(1)
                                }}
                            >
                                搜索
                            </span>
                        </Row>
                    </div>
                </div>
            </div>
            <div className="list-container">
                <div className="list-header">
                    <div className="list-but">
                        {
                            <Button
                                shape="round"
                                className="but-left"
                                onClick={() => {
                                    const params = {
                                        searchItem: {
                                            customerUserId: rowInfo.id,
                                            settlementType,
                                            settlementAmount,
                                        },
                                        pageNum: pagination.current,
                                        pageSize: 10,
                                        startTime: moment(param.startTime).format("YYYY-MM-DD") + "T16:00:00.000",
                                        endTime: moment(param.endTime).format("YYYY-MM-DD") + "T16:00:00.000",
                                        orders: [
                                            // {
                                            //     column: "createTime",
                                            //     rule: "DESC",
                                            // },
                                        ],
                                    }
                                    confirmSettleOrder(params).then((res) => {
                                        // console.log(res)
                                        // if (res && res.retCode === 200) {
                                            message.success("操作成功")
                                        // } else {
                                        //     message.error("操作失败")
                                        // }
                                    })
                                }}
                            >
                                确定
                            </Button>
                        }
                        <Button
                            shape="round"
                            className="but-left"
                            onClick={() => {
                                dispatch({
                                    type: "removeTabs",
                                    targetKey: "/statis/settledOrder",
                                    activeMenu: "clientRecord/statis",
                                })
                            }}
                        >
                            取消{" "}
                        </Button>
                    </div>
                </div>
                <Divider style={{ margin: "16px 0" }} />
                <div className="statistics">
                    <div className="item">
                        <span>账单金额</span>
                        <p>{customerUserUnsettledOrderOne.totalAmount}</p>
                    </div>
                    <div className="item">
                        <span>单据数量</span>
                        <p>{customerUserUnsettledOrderOne.orderNum}</p>
                    </div>
                </div>
                <Divider style={{ margin: "16px 0" }} />
                <Table
                    size="small"
                    columns={columns}
                    dataSource={customerUserUnsettledOrderOne.orderList.records}
                    rowKey={(record) => record.id}
                    loading={loading}
                    onChange={onTableChange}
                    pagination={{
                        showQuickJumper: true,
                        showSizeChanger: false,
                        showTotal: () => `共${customerUserUnsettledOrderOne.orderList.total}条`,
                        position: ["bottomCenter"],
                        ...pagination,
                    }}
                />
            </div>
        </>
    )
}
export default SettledOrder
