import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import TopSearch from "@/components/TopSearch"
import { getDiagnoseTermList, diagnoseTermDelete } from "@/service/aiDiagnoseService"
import { MyContext } from "@/store/myContext"

//智能诊断
export default () => {
    const { dispatch, state, message, detailsPage } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [searchValue, setSearchValue] = useState({ filterCustomer: 1 }) //搜索
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })

    useEffect(() => {
        if (state.activeMenu === "aiDiagnose") {
            loadData(pagination.current)
        }
        return () => setList([])
    }, [state.activeMenu])

    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入故障名称",
                valueName: "termName",
            },
            {
                type: "input",
                placeholder: "请输入故障术语",
                valueName: "spokenNames",
            },
            {
                type: "input",
                placeholder: "所属部件",
                valueName: "componentName",
            },
        ],
    }

    //列表数据
    const loadData = (current, search = searchValue) => {
        let data = {
            searchItem: search,
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        getDiagnoseTermList(data)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                    setpagination({
                        current: current,
                        total: res.retData.total,
                        pageSize: 10,
                    })
                    setList(res.retData.list)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }
    //返回组件判断
    const switchComponent = (mode, modeKey) => {
        switch (mode) {
            case "partMaintenance":
                return detailsPage.PartMaintenance({ modeKey, dispatch })
            case "diagnoseSpoken":
                return detailsPage.DiagnoseSpoken({ modeKey, dispatch })
            default:
                return detailsPage.AiDiagnoseDetails({
                    modeKey,
                    dispatch,
                    mode,
                    rowInfo: selectInfo.rowInfo,
                })
        }
    }
    //新增 编辑
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "故障术语新增",
            edit: "故障术语编辑",
            partMaintenance: "部件维护",
            diagnoseSpoken: "故障口语",
        }
        let modeKey = "aiDiagnose/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的维保套餐")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: switchComponent(mode, modeKey),
        })
    }

    //删除
    const handledeleteOne = () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的故障术语")
            return
        }
        diagnoseTermDelete(selectInfo.rowInfo.id)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    loadData(1)
                    message.success(`删除成功`)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }
    //table配置
    const columns = [
        {
            title: "故障名称",
            align: "center",
            dataIndex: "termName",
        },
        {
            title: "故障术语",
            align: "center",
            dataIndex: "spokenNames",
        },
        {
            title: "所属部件",
            align: "center",
            dataIndex: "componentName",
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
        },
    ]
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handledeleteOne(),
            content: "删除",
        },
        {
            click: () => handleDetails("partMaintenance"),
            content: "部件维护",
        },
        {
            click: () => handleDetails("diagnoseSpoken"),
            content: "故障口语",
        },
    ]
    return (
        <>
            {state.activeMenu === "aiDiagnose" && (
                <>
                    <TopSearch
                        title="智能诊断"
                        j
                        ustify="start"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                        defaultSearch={{ filterCustomer: 1 }}
                    />
                    <ListComponent
                        title="故障术语列表"
                        buttons={topButtons}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />
                </>
            )}
        </>
    )
}
