import React, { useContext, useState, useEffect } from "react"

import { Input, Modal, Form, Col, Row, Select, message, Switch, TreeSelect } from "antd"

import { addMenu, editMenu, getMenuAll, getMenuOne } from "@/service/menuService.js"
import { menuStatusEnum, menuTypeEnum } from "@/config/Constants.js"

const defaultData = {
    name:"",
    perms:"",
    path:"",
    component:"",
    parentId:"",
    isFrame:false,
    icon:"",
    sort:"",
    type:"",
    menuStatus: "normal"
}

const MenuEdit = (props) => {
    // editId 被修改的数据。有内容，进行修改操作
    const { editId, status, visible, onCancel, onLoadData } = props
    const [form] = Form.useForm()
    const [treeData, setTreeData] = useState(null)
    const [editInfo, setEditInfo] = useState({})

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    const menuStatusEnumArray = Object.keys(menuStatusEnum).map((i) => {
        return {
            name: menuStatusEnum[i],
            val: i,
        }
    })

    const menuTypeEnumArray = Object.keys(menuTypeEnum).map((i) => {
        return {
            name: menuTypeEnum[i],
            val: i,
        }
    })

    useEffect(() => {
        if (visible) {
            loadParentSelectData()
            if (editId && status === "edit") {
                //初始化
                initEditInfo(editId)
            }else{
                form.setFieldsValue(defaultData)
                setEditInfo(defaultData)
            }
        }
    }, [visible])

    const initEditInfo = (editId) => {
        console.log("初始化edit id" + editId)
        getMenuOne(editId)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    let data = res.retData
                    form.setFieldsValue({
                        ...data,
                    })
                    setEditInfo(data)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }

    const loadParentSelectData = () => {
        getMenuAll()
            .then((res) => {
                // if (res && res.retCode === 200) {
                    let list = res.retData.list
                    if (list) {
                        let result = list.map((i) => {
                            return {
                                id: i.id,
                                pId: i.parentId,
                                value: i.id,
                                title: i.name,
                            }
                        })
                        setTreeData(result)
                    }
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }

    const onCustomerSubmit = () => {
        form.validateFields()
            .then((fieldsValue) => {
                fieldsValue.parentId = fieldsValue.parentId ? fieldsValue.parentId : null
                //修改操作
                if (editId) {
                    editMenu({ ...fieldsValue, id: editId }).then((res) => {
                        // if (res && res.retCode === 200) {
                            onLoadData()
                            form.resetFields()
                            onCancel(false)
                            message.success("修改成功")
                        // } else {
                        //     message.error(res.retMsg)
                        // }
                    })
                    return
                } else {
                    addMenu({ ...fieldsValue }).then((res) => {
                        // if (res && res.retCode === 200) {
                            onLoadData()
                            form.resetFields()
                            onCancel(false)
                            message.success("新增成功")
                        // } else {
                        //     message.error(res.retMsg)
                        // }
                    })
                }
            })
            .catch((err) => {
                message.error(err.retMsg)
            })
    }

    return (
        <Modal
            visible={visible}
            title="菜单新增"
            // centered={true}
            onOk={() => onCustomerSubmit()}
            onCancel={() => onCancel(false)}
            width={1260}
        >
            <div>
                <Form form={form} {...formItemLayout}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="菜单名称"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "菜单名称是必填项",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入菜单名称" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="菜单权限标识" name="perms" initialValue={null}>
                                <Input placeholder="请输入菜单权限标识" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="前端跳转URL" name="path" initialValue={null}>
                                <Input placeholder="请输入前端跳转URL" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="菜单组件" name="component" initialValue={null}>
                                <Input placeholder="请输入菜单组件" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="父菜单" name="parentId" initialValue={null}>
                                <TreeSelect
                                    style={{ width: "100%" }}
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: "auto",
                                    }}
                                    treeData={treeData}
                                    placeholder="Please select"
                                    treeDefaultExpandAll
                                    treeDataSimpleMode
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="图标" name="icon" initialValue={null}>
                                <Input placeholder="请输入图标" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="排序" name="sort" initialValue={1}>
                                <Input placeholder="请输入排序" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="是否为外链" name="isFrame" valuePropName="checked">
                                <Switch checkedChildren="外链" unCheckedChildren="内部链接" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="菜单类型" name="type" required initialValue={menuTypeEnumArray[0].val}>
                                <Select
                                    placeholder="请选择菜单类型"
                                    style={{ width: "100%" }}
                                    onClick={(e) => {
                                        console.log(e)
                                    }}
                                >
                                    {menuTypeEnumArray.map((item) => (
                                        <Select.Option value={item.val} key={item.val}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="菜单状态" name="menuStatus" initialValue={menuStatusEnumArray[0].val}>
                                <Select placeholder="请选择菜单状态" style={{ width: "100%" }}>
                                    {menuStatusEnumArray.map((item) => (
                                        <Select.Option value={item.val} key={item.val}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    )
}
export default MenuEdit
