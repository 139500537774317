import { useState, useEffect } from "react";
import { createModel } from "hox";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { purchaseAdvancedService } from "./purchaseAdvancedService";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import { message } from "antd";
import { formatNum } from "@/erp_subpackage/utils/util";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"

const initDesc = { totalNum: 0, totalAmount: 0, paidAmount: 0 };

export const usePurchaseAdvancedModel = createModel(function () {
    const { user: { shopId, shopName, shopCode, organType,organNature, organTypeCode } } = useGlobalModel()
    const { user } = useGlobalModel()
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    const [descTotal, setDescTotal] = useState(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState(initDesc);
    const [loading, setLoading] = useState({ spinning: false, tip: "加载中..." });
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //分页参数
    const initPgCfg = { pageSize: 15, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    //表格多选row
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //打印
    const [print, setPrint] = useState(false);

    useEffect(() => {
        let newRowsDescTotal = { ...initDesc };
        selectedRowKeys.forEach((item) => {
            // if (item.status !== DictCodeEnmu.PO_CHANGE) {
                newRowsDescTotal.totalNum += +formatNum(item.totalNum)
                newRowsDescTotal.totalAmount += +formatNum(item.payableAmount)
                newRowsDescTotal.paidAmount += +formatNum(item.paidAmount)
            // }
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [selectedRowKeys])

    //列表查询
    const loadData = async (page, searchValue = searchParam) => {
        setLoading({ spinning: true, tip: "列表加载中..." });
        let status = Array.isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status;
        const searchParams = {
            ...searchValue,
            status,
            // shopId: searchValue.shopId ?? shopId,
            shopId: searchValue.shopId ? searchValue.shopId : organNature === 3 ? shopId :undefined ,//平台默认查全部
            shopName:organNature === 3 ? shopName :undefined , 
            shopCode:organNature === 3 ? shopName :undefined , 
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
            statusList:organNature === 3 ?undefined:['advanced.submit','advanced.wait.transfer','advanced.transfer','advanced.wait.audit','advanced.wait.confirm']
        }
        let { retData } = await purchaseAdvancedService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
        setLoading({ spinning: false });
        setSelectedRowKeys([]);
    //     purchaseAdvancedService.total(searchParams).then(({retData:statistics})=>{
    //     if(retData){
    //         setDescTotal(statistics)
    //     }
    //    }).catch(()=>{
    //     console.log('统计数据失败')
    //    })  
    }
    //设置搜索value
    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    //导出
    const exportList = async (searchValue = searchParam) => {
        setLoading({ tip: "文件导出中", spinning: true });
        let status = Array.isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status;
        const searchParams = {
            searchItem:{
                ...searchValue,
                // shopId: searchValue.shopId ?? shopId,
                shopId: searchValue.shopId ? searchValue.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
                shopName, shopCode,status,id: selectedRowKeys.map(item => item.id).join(),
           },
        }
     purchaseAdvancedService.export(searchParams).then((res)=>{
        message.success(res.retData)
     })
        // convertRes2Blob(result);
        setLoading({ spinning: false });
        // return result;
    }

    //导出详情
    const exportDetail = async (searchValue = searchParam) => {
        setLoading({ tip: "文件导出中", spinning: true });
        let status = Array.isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status;
        const searchParams = {
            ...searchValue,status,
            shopId: searchValue.shopId ? searchValue.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
            shopName, shopCode,
            id: selectedRowKeys.map(item => item.id).join(),
        }
        let result = await purchaseAdvancedService.detailsExport(searchParams);
        convertRes2Blob(result);
        setLoading({ spinning: false });
        return result;
    }

    const invalidate = async (cancelReason) => {
        let { id, advancedCode } = selectedRowKeys[0];
        let { retData } = await purchaseAdvancedService.invalidate({ id, advancedCode, cancelReason });
        if (retData) {
            loadData()
            message.success("作废成功")
        }
    }

    return {
        /***列表***/
        searchParam, organType,organNature, shopId, organTypeCode,
        dataSource,
        selectedRowKeys,
        pgCfg,
        loading, descTotal, rowsDescTotal,
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        exportList,
        invalidate,
        exportDetail,
        print, setPrint
    }
});