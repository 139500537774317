import React, { useRef, useState, useEffect } from "react";
import YHDrawer from '@/erp_subpackage/components/YhDrawer';
import { YhForm } from '@/erp_subpackage/components/YhFormBox';
import { commonService } from '@/erp_subpackage/pages/common/commonService';
import { Button, Form, message, Upload } from "antd";
import { useLockFn } from "ahooks";
import { trasDsToTreeData } from '@/erp_subpackage/utils/util';
import { getBentityListOption } from '@/utils/dataStorage'
import FontIcon from "@/erp_subpackage/components/YhIcon";
import { getBaseImgUploadUrl , getBaseUploadData } from '@/config/app'
import { creditFlagDs } from '@/erp_subpackage/utils/ErpEnum'


export const RechargeDrawer = ({visible, setVisible, supplier, onSucess, selectedData}) => {
   
    const formRef = useRef();

    const [fmsTreeOrgDs, setFmsTreeOrgDs] = useState([])
    const [payMethodDs, setPayMethodDs] = useState([])

    useEffect(() => {
        if (visible) {
            getFmsTreeOrgan()
            getFmsPayMethodDs()
        }
    }, [visible])

  

    const getFmsTreeOrgan = async () => {
        let { retData } = await commonService.fmsAllTree({})
        let treeData = trasDsToTreeData(retData, 'code', 'name')
        setFmsTreeOrgDs(treeData)
    }

    const getFmsPayMethodDs = async  () => {
        let { retData } = await commonService.fmsDictAllTree({code: "pay-method"})
        let treeData = trasDsToTreeData(retData, 'code', 'name')
        setPayMethodDs(treeData)
    }

    const normFile = (e) => {
        let filetypes = [".jpg", ".png", ".jpeg", "svg", ".gif", ".pdf", ".doc"];
        let isType = false;
        let fileEnd = e?.file.name.substring(e?.file.name.lastIndexOf("."));
        isType = filetypes?.includes(fileEnd) ?? false;
        console.log('Upload event:', e);
        if (e?.file.status === 'done') {
            if (e?.file?.response?.retCode == 0) {
                const data = {
                    name: e.file.name,
                    size: e.file.size,
                    type: e.file.type,
                    uid: e?.file?.response?.retData
                }
                // e.fileList = [data]
                e.fileList[e.fileList.length - 1] = data
            } else {
                message.error('文件上传失败，请重新上传!')
                e.fileList.pop()
            }
        }
        if (e.file.status === 'error') {
            message.error('文件上传失败，请重新上传!')
            e.fileList.pop()
        }
        if (Array.isArray(e)) {
            return e;
        }
        if (!isType) {
            e.fileList.pop()
        }
        return e && e.fileList;
    };

    const formConfig = {
        formRef,
        labelCol: { span: 6 },
        disabled: false,
        formValues: { ...selectedData },
        items: [
            {
                type: 'Input',
                fieldLabel: "供应商",
                fieldName: "code",
                disable: true,
                span: 24,
            },
            {
                type: 'Input',
                fieldLabel: "账户",
                fieldName: "name",
                disable: true,
                span: 24,
            },
            {
                type: 'Select',
                fieldLabel: "授信类型",
                fieldName: "creditFlag",
                disable: true,
                span: 24,
                options: creditFlagDs,
            },
            {
                type: 'TreeSelect',
                fieldLabel: "机构",
                fieldName: "orgCode",
                disable: true,
                span: 24,
                treeData: fmsTreeOrgDs
            },
            {
                type: 'Select',
                fieldLabel: "实体",
                fieldName: "beCode",
                disable: true,
                span: 24,
                options: getBentityListOption(),
            },
            {
                type: 'Input',
                fieldLabel: "剩余金额",
                fieldName: "remainingAmount",
                disable: true,
                span: 24,
            },
            {
                type: 'Input',
                fieldLabel: "充值金额",
                fieldName: "rechargeAmount",
                span: 24,
                rules: [{
                    required: true,
                    pattern: new RegExp(/^[+|-]?(\d+)$|^[+|-]?(\d+\.\d+)$/),
                    message: "请输入数字!"
                }],
            },
            {
                type: 'TreeSelect',
                fieldLabel: "支付方式",
                fieldName: "payMethodCode",
                span: 24,
                // ds: payMethodDs?.filter(item => item.value !== 'pay-method.jz'),
                treeData: payMethodDs,
                rules: [{
                    required: true,
                    message: "授信类型不能为空"
                }],
            },
            {
                type: 'Other',
                fieldLabel: "附件",
                fieldName: "file",
                span: 24,
                colClassName: "ant-col-h-auto",
                formItem: <Form.Item
                    name="fileList"
                    label="附件"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={
                        [
                            {
                                required: true,
                                message: '现结账户充值需要上传附件信息'
                            }
                        ]}
                >
                    <Upload
                        name="file"
                        action={getBaseImgUploadUrl()}
                        data={getBaseUploadData()}
                        // headers={{
                        //     authorization: getToken()
                        // }}
                        listType="picture"
                        maxCount={1}
                        // disabled={editModel === 'look'}
                        beforeUpload={(file ) => {
                            let fileEnd = file.name.substring(file.name.lastIndexOf("."));
                            // let filetypes: string[] = [".jpg", ".png", ".doc", ".xls", ".pdf", ".docx", ".xlsx", ".mp4", ".mp3"];
                            let filetypes = [".jpg", ".png", ".jpeg", "svg", ".gif", ".pdf", ".doc"];
                            // var filetypes = [".jpg", ".png", ".rar", ".txt", ".zip", ".doc", ".ppt", ".xls", ".pdf", ".docx", ".xlsx", ".mp4", ".mp3"];
                            let isType = filetypes?.includes(fileEnd);
                            if (!isType) {
                                message.error('不支持该格式的上传,当前仅支持 图片、pdf和.doc 类型文件');
                            }
                            const isLt20M = file.size / 1024 / 1024 < 20;
                            if (!isLt20M) {
                                message.error('上传到文件不能超过20M')
                                    file.status='done';
                            }
                            return isType && isLt20M;
                        }}

                        iconRender={(file) => {
                            if (file?.type?.indexOf('image/') !== -1) return <FontIcon style={{ fontSize: '24px' }} type="icon-tupian2" />
                            if (file?.type?.indexOf('pdf') !== -1) return <FontIcon style={{ fontSize: '24px' }} type="icon-pdf" />
                            if (file?.type?.indexOf('msword') !== -1) return <FontIcon style={{ fontSize: '24px' }} type="icon-doc" />
                        }}
                    >
                        <Button>选择文件上传</Button>
                    </Upload>
                </Form.Item >
            },
            {
                type: 'Input',
                fieldLabel: "收款人",
                fieldName: "operator",
                span: 24,
            },
            {
                type: 'Input',
                fieldLabel: "备注",
                fieldName: "remark",
                span: 24,
            },
        ]
    };

    const onOk = useLockFn(async () => {
        try {
            let formData = await formRef.current?.validateFields();
            if (formData) {
                const { fileList = [], ...params } = formData
                const reqData = {
                    ...params,
                    accountCode: formData.code,
                    partnerTypeCode: "user-type.supplier",
                    partnerCode: supplier.code,
                    note: formData.remark
                };
                if (fileList.length > 0) {
                    const file = fileList[0];
                    if (!file?.uid) {
                        console.log(file);
                        return message.error('文件信息有误，请重新上传文件')
                    }
                    if (file?.status) {
                        console.log(file);
                        return message.warn('文件正在上传，请等待文件上传成功！')
                    }
                    reqData.md5 = file?.uid;
                    reqData.fileName = file.name;
                    reqData.fileSize = file.size;
                }
                let insertRet = await commonService.fmsRecharge(reqData);
                if (insertRet.success) {
                    message.success("充值申请成功");
                    onSucess && onSucess();
                    setVisible(false);
                } else {
                    message.error("充值失败!" + insertRet.retMsg);
                }
            }
        } catch (e) {
            console.log(e);
            console.log("数据校验失败");
        }
    });
    const onClose = () => {
        setVisible(false);
    }
    return (
        <YHDrawer
            {...{
                title: "账户充值",
                visible: visible,
                onOk,
                onClose,
                showFooter: true,
                style: { top: 0 },
                width: 600,
            }}
        >
            <div style={{ padding: '20px' }}>
            <YhForm {...formConfig} />
            </div>
            
        </YHDrawer>
    )
}
