import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import TopSearch from "@/components/TopSearch"
import { instrumentLightPage, updateInstrumentLight, instrumentLightLlassifyPage } from "@/service/instrumentLamp"
import { MyContext } from "@/store/myContext"
import { fileLookUrl } from "@/config/app"
import { Image, Select } from "antd"
const { Option } = Select

//系统配置
const InstrumentLampList = () => {
    const { dispatch, state, message, detailsPage, Switch } = useContext(MyContext)
    const [instrumentLampList, setInstrumentLampList] = useState([]) //列表
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [searchValue, setSearchValue] = useState({}) //搜索
    const [instrumentLampTypeList, setInstrumentLampTypeList] = useState([]) //分类列表
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })

    useEffect(() => {
        if (state.activeMenu === "listInstrumentLamp") {
            loadData()
            getiInstrumentLightLlassifyList()
        }
        return () => setInstrumentLampList([])
    }, [state.activeMenu])

    const getiInstrumentLightLlassifyList = () => {
        let data = {
            searchItem: {},
            pageNum: 1,
            pageSize: 1000,
            orders: [{ column: "updateTime", rule: "DESC" }],
        }
        instrumentLightLlassifyPage(data).then((res) => {
            // if (res && res.retCode === 200) {
                setInstrumentLampTypeList(res.retData.list)
            // }
        })
    }

    //列表数据
    const loadData = (current, searchValue = {}) => {
        let data = {
            searchItem: searchValue,
            pageNum: current,
            pageSize: 10,
            orders: [{ column: "updateTime", rule: "DESC" }],
        }
        instrumentLightPage(data).then((res) => {
            // if (res && res.retCode === 200) {
                setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setInstrumentLampList(res.retData.list)
            // }
        })
    }
    //分页事件
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }
    //新增 编辑
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "仪表灯新增",
            edit: "仪表灯编辑",
            look: "仪表灯查看",
        }
        let modeKey = "listInstrumentLamp/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的信息")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        }
        if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的信息")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.InstrumentLampListDetails({
                modeKey,
                dispatch,
                mode,
                rowInfo: selectInfo.rowInfo,
            }),
        })
    }
    //配置开关
    const sweitchChange = (checked, e, id) => {
        e.stopPropagation()
        updateInstrumentLight({ id: id, ilStatus: checked ? 1 : 0 }).then((res) => {
            // if (res && res.retCode === 200) {
                message.success("操作成功")
            // } else {
            //     message.error(res.retMsg)
            // }
        })
    }
    //table配置
    const columns = [
        {
            title: "名称",
            align: "center",
            dataIndex: "ilName",
        },
        {
            title: "分类",
            align: "center",
            dataIndex: "classifyName",
        },
        {
            title: "排序",
            align: "center",
            dataIndex: "ilSort",
        },
        {
            title: "图片",
            align: "center",
            dataIndex: "ilImage",
            render: (text) => (text ? <Image src={`${fileLookUrl().image}${text}`} width={34} height={34} /> : null),
        },
        {
            title: "操作",
            align: "center",
            width: "12%",
            dataIndex: "ilStatus",
            render: (text, record) => (
                <Switch size="small" defaultChecked={text === 1 ? true : false} onChange={(checked, e) => sweitchChange(checked, e, record.id)} />
            ),
        },
    ]

    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入仪表灯名称",
                valueName: "ilName",
            },
            {
                type: "select",
                placeholder: "请选择仪表灯分类",
                valueName: "classifyId",
                optionList: () =>
                    instrumentLampTypeList.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.classifyName}
                        </Option>
                    )),
            },
        ],
    }
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("look"),
            content: "查看",
        },
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
    ]
    return (
        <>
            {state.activeMenu === "listInstrumentLamp" && (
                <>
                    <TopSearch
                        title="仪表灯"
                        justify="start"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="仪表灯列表"
                        buttons={topButtons}
                        columns={columns}
                        dataSource={instrumentLampList}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />
                </>
            )}
        </>
    )
}
export default InstrumentLampList
