import React, { useEffect, useState } from "react"
import { Modal, Col, Row, Button, Divider, message, Select, Table } from "antd"
import { paymentRefundReview, paymentRefundDetail } from "@/service/ordersService"
import styled from "@emotion/styled"
import { getPcodeDict } from "@/utils/dataStorage"
import { ItemAttr } from "@/utils/enum"

export default (props) => {
    const { rowInfo, visible, setVisible, onSuccess, StatusObj } = props
    const [detail, setDetail] = useState({})
    useEffect(() => {
        if (visible) {
            paymentRefundDetail(rowInfo.id).then(({ retData }) => {
                setDetail(retData)
            })
        } else {
            setDetail({})
        }
    }, [visible])

    const refuse = () => {
        let val = ""
        Modal.confirm({
            title: "请选择拒绝的原因",
            content: (
                <Select
                    placeholder="请选择拒绝的原因"
                    onChange={(e) => {
                        val = e
                    }}
                    style={{width:'100%'}}
                >
                    {getPcodeDict("043").children.map((item) => (
                        <Select.Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Select.Option>
                    ))}
                </Select>
            ),
            okText: "确定",
            cancelText: "取消",
            onOk: () => {
                return new Promise((resolve, reject) => {
                    if (val) {
                        resolve(val)
                    } else {
                        message.warning("请选择拒绝的原因")
                        reject("请选择拒绝的原因")
                    }
                }).then((res) => {
                    paymentRefundReview({
                        orderId: detail.orderId,
                        orderPaymentRefundId: detail.id,
                        passed: false,
                        rejectInfo: val,
                    }).then((res) => {
                        close()
                        onSuccess && onSuccess(1)
                    })
                })
            },
            onCancel: () => {},
        })
    }

    const agree = () => {
        paymentRefundReview({
            orderId: detail.orderId,
            orderPaymentRefundId: detail.id,
            passed: true,
        }).then((res) => {
            close()
            onSuccess && onSuccess(1)
        })
    }

    const close = () => {
        setVisible(false)
    }

    const columns = [
        {
            title: "项目编码",
            align: "center",
            width: 120,
            fixed: "left",
            dataIndex: "itemCode",
        },
        {
            title: "项目名称",
            align: "center",
            width: 400,
            fixed: "left",
            dataIndex: "itemName",
            render: (text, record) =>
                record.state === "refuse_repair" ? (
                    <span>
                        {text}
                        <span style={{ color: "#f00" }}>(拒修)</span>
                    </span>
                ) : (
                    <>{text}</>
                ),
        },
        {
            title: "预付款",
            align: "center",
            width: "120px",
            dataIndex: "prepayFlag",
            render: (text) => (text == 0 ? "否" : "是"),
        },
        {
            title: "项目属性",
            align: "center",
            width: "120px",
            dataIndex: "itemAttr",
            render: (text) => <p>{ItemAttr[text]}</p>,
        },

        {
            title: "工时",
            align: "center",
            width: 100,
            dataIndex: "itemWorkHours",
        },
        {
            title: "单价",
            align: "center",
            width: 185,
            dataIndex: "workHoursPrice",
        },
        {
            title: "工时费",
            align: "center",
            width: 200,
            dataIndex: "itemAmount",
        },
        {
            title: "优惠",
            align: "center",
            width: 185,
            dataIndex: "preferentialAmount",
        },
        {
            title: "折扣",
            align: "center",
            width: 100,
            dataIndex: "itemDiscount",
        },
        {
            title: "总金额",
            align: "center",
            width: "120px",
            dataIndex: "actualAmount",
        },
    ]

    return (
        <Modal forceRender visible={visible} title="退款申请" centered={true} footer={null} onCancel={() => close()} width={1200}>
            <Titlediv>退款申请</Titlediv>
            <Row>
                <Col span="6">工单编号：{detail.orderCode}</Col>
                <Col span="6">状态：{StatusObj[detail.status]}</Col>
                <Col span="6">退款方式：微信（原路返回）</Col>
                {/* <Col span="6">收款账号：{}</Col> */}
            </Row>
            <Divider />
            <Titlediv>用户信息</Titlediv>
            <Row>
                <Col span="6">用户姓名：{detail.customerInfo && detail.customerInfo.customerCorporation}</Col>
                <Col span="6">联系电话：{detail.customerInfo && detail.customerInfo.customerCorporationPhone}</Col>
                <Col span="6">付款方式：微信</Col>
            </Row>
            <Divider />
            <Titlediv>工单信息 共计：{rowInfo.refundAmount}元</Titlediv>
            <Table columns={columns} dataSource={detail.itemList} pagination={false} rowKey={(record) => record.id} />
            <Titlediv>退款进度</Titlediv>
            <Row>
                <Col span="6">时间：{detail.createTime}</Col>
                <Col span="6">状态：{StatusObj[detail.status]}</Col>
                <Col span="6">操作员：{detail.reviewerUserName}</Col>
                <Col span="6">耗时：{detail.spentTime}</Col>
            </Row>
            <Divider />
            {detail.status === "refunding" && (
                <ButtonBar>
                    <Button style={{ marginRight: "10px" }} type="primary" danger onClick={refuse}>
                        拒绝退款
                    </Button>
                    <Button type="primary" onClick={agree}>
                        同意退款
                    </Button>
                </ButtonBar>
            )}
        </Modal>
    )
}

const Titlediv = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin: 20px 0;
`

const ButtonBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
`
