import React from "react";
import { Button } from "antd";
import { YhForm } from "@/erp_subpackage/components/YhFormBox";
import { useDictModel } from "../dictModel";

export const FormBox = () => {
    const { mode, setSelectedNodes, onSubmit, formVal, onChangeFormVal, reset } = useDictModel();


    const formConfig = {
        labelCol: { span: 6 },
        formValues: formVal,
        onChange: onChangeFormVal,
        items: [
            {
                type: "Input",
                fieldLabel: "所属分支",
                fieldName: "branch",
                span: 18,
                disable: true
            }, {
                type: "Input",
                fieldLabel: "父级编码",
                fieldName: "pcode",
                span: 18,
                disable: true
            }, {
                type: "Input",
                fieldLabel: "字典编码",
                fieldName: "code",
                span: 18,
                disable: mode === "edit" ? true : false,
                rules: [{ required: true, message: "该项为必填项" }]
            }, {
                type: "Input",
                fieldLabel: "字典名称",
                fieldName: "name",
                span: 18,
                rules: [{ required: true, message: "该项为必填项" }]
            },
            {
                type: "Input",
                fieldLabel: "Value",
                fieldName: "value",
                span: 18,
            },
            {
                type: "Input",
                fieldLabel: "备注",
                fieldName: "note",
                span: 18,
            },
            {
                type: "Switch",
                fieldLabel: "状态",
                fieldName: "status",
                span: 18,
            }
        ]
    };


    return (
        <>
            <div className="dict-list-header">
                <div className="dict-list-but">
                    <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={onSubmit}>保存</Button>
                    <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={() => setSelectedNodes([])}>取消</Button>
                    <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={() => reset()}>重置</Button>
                </div>
            </div>
            <div className="dict-from-content">
                <YhForm {...formConfig} />
            </div>
        </>
    )
}