import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";
import { PORTAL_API_SERVER_HOST } from "@/config/app";
import { getPortalToken } from "@/utils/dataStorage";

class SaleDcService {
    async page(searchVo) {
        const apiUrl = "/sms/transfer/to/listPage";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    async pageStatistics(searchVo) {
        const apiUrl = "/sms/transfer/to/TransferInSummary";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    async one(id) {
        const apiUrl = `/sms/transfer/to/findByIdOrCode/${id}`;
        return await httpRequest.get(apiUrl, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    async insert(insertVo) {
        const apiUrl = "/sms/transfer/to/insert";
        return await httpRequest.post(apiUrl, insertVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    async update(updateVo) {
        const apiUrl = "/sms/transfer/to/update";
        return await httpRequest.post(apiUrl, updateVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //  查询门店结算单位的 下属关联
    async queryShopUnitBe(shopId) {
        const apiUrl = `/fms/accountUnit/find/one/${shopId}`;
        return await httpRequest.post(apiUrl, {}, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //  驳回
    async reject(id) {
        const apiUrl = `/sms/transfer/to/reject/${id}`;
        return await httpRequest.get(apiUrl, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

    //  调入调出   调入：0，调出 1
    async whConfirm(obj) {
        const apiUrl = `/sms/transfer/to/whConfirm`;
        return await httpRequest.post(apiUrl, obj, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

    async export(searchVo) {
        const apiUrl = "/sms/transfer/to/export";
        return await httpRequest.postFile(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    async print(id) {
        const apiUrl = `/sms/transfer/to/print/${id}`;
        return await httpRequest.get(apiUrl, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

    //  调出单明细下载模板
    async downloadTpl() {
        const appPageUrl = "/sms/transfer/to/detail/downloadTpl";
        return await httpRequest.getFile(appPageUrl, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //  导出调出单明细
    async exportDetail(obj) {
        const appPageUrl = `/sms/transfer/to/exportDetailsByShopId`;
        return await httpRequest.postFile(appPageUrl, obj, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    // async exportDetail(code) {
    //     const appPageUrl = `/sms/transfer/to/exportDetails/${code}`;
    //     return await httpRequest.getFile(appPageUrl, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    // }

    // 采购明细查询接口
    async ciTransferDetails(searchVo) {
        const apiUrl = "/ci-storage/stock/details/transfer/details";
        return await httpRequest.post(apiUrl, searchVo);
    }
    //  业务系统库房确认接口
    async ciWhConfirm(searchVo) {
        const apiUrl = "/ci-storage/stock/details/whConfirm";
        return await httpRequest.post(apiUrl, searchVo);
    }

    //调入调出   调入：0，调出 1
    async whConfirmCi(obj) {
        const apiUrl = `/ci-storage/transfer/to/whConfirm`;
        return await httpRequest.post(apiUrl, obj);
    }

    async check (branchIn,params) {
        const apiUrl = `${branchIn}/base/material/checkMaterialExist`
        const baseURL = branchIn === 'ci-storage' ? undefined : PORTAL_API_SERVER_HOST
        return await httpRequest.post(apiUrl, params, {baseURL})
    }
    async checkScm (branchIn,params) {
        const apiUrl = `${branchIn}/purchase/po/checkMaterialExist`
        const baseURL = branchIn === 'ci-storage' ? undefined : PORTAL_API_SERVER_HOST
        return await httpRequest.post(apiUrl, params, {baseURL})
    }
}

export const saleDcService = new SaleDcService();