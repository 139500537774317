import React from "react"
import { Description } from "@/erp_subpackage/components/YhBox/Description";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { formatNum } from "@/erp_subpackage/utils/util";
import { useSaleDcDetailsModel } from "./saleDcDetailsModel";

export const FixedBottom = ({ id, mode }) => {
    const { editDesc, addDesc, } = useSaleDcDetailsModel();
    const { collapsed } = useGlobalModel(({ collapsed }) => [collapsed]);
    const retuenText = (field, money = false) => {
        let desc = mode === "edit" ? editDesc : addDesc;
        return money ? `￥${formatNum(desc[field])}` : desc[field];
    }

    const orderStatistics = [
        {
            label: "调拨数量",
            value: retuenText("numAll"),
            color: 'green',
            status: true
        },
        {
            label: "折前总计",
            value: retuenText("totalPreAmount", true),
            color: 'green',
            status: true
        },
        {
            label: "调拨总计",
            value: retuenText("actualAmount", true),
            color: 'red',
            status: true
        }
    ]
    return (
        <div className="details-bot-fixed-warp" style={{ width: `calc(100% - ${collapsed ? '80px' : '200px'})`, paddingLeft: "43px" }}>
            {orderStatistics.map((item, index) => <Description key={index} {...item} />)}
        </div>
    )
}