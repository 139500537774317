import { useState, useEffect } from "react";
import { createModel } from "hox";
import { useLockFn } from "ahooks";
import { purchaseAdvancedService } from "../../purchaseAdvancedService"
import useGlobalModel, { defaultMdCmParam } from "@/erp_subpackage/model/globalModel";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import { formatInt, formatNum, getRandomStr } from "@/erp_subpackage/utils/util";
import { message } from "antd";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { bigNumber,momentFormat } from "../../../../../utils/util";
import { IsFranchisees } from '@/utils/common'
import { getBentityListOption } from '@/utils/dataStorage'

const initDesc = {
    numAll: 0,  //数量
    itemNumAll: 0, //项数
    totalAll: 0 //总计
}

export const usePurchaseAdvancedDetailsModel = createModel(function () {
    const { sourceApp, branch } = defaultMdCmParam;
    const { user: { shopId, shopName, shopCode, userName, orgName, orgCode, bentityName, bentityCode, organType,organNature } } = useGlobalModel();

    const initFormVal = {
        shopName,
        createUser: userName,
        status: true,
        supplierName:'云豪科技',
        supplierCode:'SUP-M-00000597',
        supplierTypeCode:'supplier-type.yh-internal',
        supplierTypeName:'集团内部供应商',
        contact:'刘永强',
        phone:'15611901621',
        purchaseTypeCode: "purchase-type.S",
        purchaseTypeName: "S-库存订单"
    }

    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState({});
    const [editList, setEditList] = useState([]);
    const [editDesc, setEditDesc] = useState(initDesc);
    //新增表单缓存
    const [addFormVal, setAddFormVal] = useState(initFormVal);
    const [addList, setAddList] = useState([]);
    const [addDesc, setAddDesc] = useState(initDesc);
    //查看表单缓存
    const [lookFormVal, setLookFormVal] = useState({});
    const [lookList, setLookList] = useState([]);
    const [lookDesc, setLookDesc] = useState(initDesc);

    //添加商品弹框
    const [materialDialog, setMaterialDialog] = useState(false);

    //转销售单弹框
    const [transferSaleDrawer, setTransferSaleDrawer] = useState(false);

    //结算弹框
    const [settleVisible, setSettleVisible] = useState(false);

    //库房确认
    const [wsConfirmVisible, setWsConfirmVisible] = useState(false);

    //单据变化、按钮可操作控制
    const [controlled, setControlled] = useState(false);

    const [saveBtnLoading, setSaveBtnLoading] = useState(false); //  保存按钮
    //切换机构需重置的缓存
    useEffect(() => {
        let a=getBentityListOption()
        setAddFormVal({
            ...initFormVal,
            bentityName:a?a[0].label:"",
            bentityCode:a?a[0].value:"",
        })
    }, [shopId])

    useEffect(() => {
        let newDesc = { ...initDesc };
        editList.forEach(item => {
            newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.price) * Number(item.num);
            newDesc.totalAll += +bigNumber.times(Number(item.price),Number(item.num)).toFixed(2);
        });
        newDesc.itemNumAll = editList.length;
        setEditDesc(newDesc);
    }, [editList])

    useEffect(() => {
        let newDesc = { ...initDesc };
        addList.forEach(item => {
            newDesc.numAll += Number(item.num);
            newDesc.totalAll += +bigNumber.times(Number(item.price),Number(item.num)).toFixed(2);
        });
        newDesc.itemNumAll = addList.length;
        setAddDesc(newDesc);
    }, [addList])

    useEffect(() => {
        let newDesc = { ...initDesc };
        lookList.forEach(item => {
            newDesc.numAll += Number(item.num);
            newDesc.totalAll += +bigNumber.times(Number(item.price),Number(item.num)).toFixed(2);
        });
        newDesc.itemNumAll = lookList.length;
        setLookDesc(newDesc);
    }, [lookList])

    //表单修改
    const updateFormVal = (mode, obj) => {
        const setFormVal = mode === "edit" ? setEditFormVal : mode === "look" ? setLookFormVal : setAddFormVal;
        setFormVal(origin => obj ? { ...origin, ...obj } : initFormVal);
        if (mode === "edit") { setControlled(true) }
    }

    //商品子表修改
    const updateList = (mode, arr = []) => {
        const setList = mode === 'edit' ? setEditList : mode === "look" ? setLookList : setAddList;
        setList([...arr]);
        if (mode === "edit") { setControlled(true) }
    };

    //获取一条数据
    const getPurchaseAdvancedOne = useLockFn(async (code, mode = "edit") => {
        let { retData } = await purchaseAdvancedService.one(code);
        if (retData) {
            let { details, ...from } = retData;
            const settleDetails = retData.settleDetails
            if (settleDetails && settleDetails.length > 0) {
                const { cashAttach, cashAttachFileName } = settleDetails[0]
                if(cashAttach && cashAttachFileName){
                    from.cashAttach = cashAttach
                    from.cashAttachFileName = cashAttachFileName
                }
            }
            from.createTime = momentFormat(from.createTime)
            updateFormVal(mode, from ?? {});
            updateList(mode, (details ?? []).map(item=>({
                ...item,
                key:getRandomStr()
            })));
            setControlled(false);
        }
        return retData;
    })

    //校验商品信息的仓库和货位是否选中
    const verifyWhOrShelf = (list = editList) => {
        if (!list || !list.length) {
            message.warning("请选择商品");
            return false;
        }
        let filterArr = list.filter(item => !item.warehouseCode || !item.shelfCode);
        if (filterArr.length) {
            message.warning(`【${filterArr[0].materialName}】请选择仓库和货位`);
            return false;
        }
        return true;
    }

    //新增采购单
    const insertDetail = useLockFn(async (params) => {
        // if (!verifyWhOrShelf(addList)) return false;
       
        if(addList.length==0){
            message.warning(`未添加商品明细不可生成采购单`)
            return
        } 
        let list =addList.map(item => { let { key, ...params } = item; return params; })
        let lists  =list.map((obj)=>{ 
            delete obj.createUser
            delete obj.createTime
            delete obj.deleteFlag
            delete obj.updateUser
            delete obj.updateTime
            return obj
        }).filter(obj => Object.keys(obj).length !== 0);
        let newParams = {
            ...params,
            note: ((params.note ?? "")),
            details:lists,
            createUser: userName,
            // bentityName, bentityCode,
            sourceApp, branch,
            shopId, shopName, shopCode, userName, orgName, orgCode,
        }
       try {
        setSaveBtnLoading(true)
        let { retData } = await purchaseAdvancedService.insert(newParams);
        setSaveBtnLoading(false)
        message.success("新增成功")
        return retData;
       } catch (error) {
        setSaveBtnLoading(false)
       }
    })

    //编辑采购单
    const editDetail = useLockFn(async (params) => {
        // if (!verifyWhOrShelf(editList)) return false;
        if(editList.length==0){
            message.warning(`未添加商品明细不可生成采购单`)
            return
        } 
        let errorList=editList.filter((item)=>item.waitSatisfiedNum?+Number(item.num) > +(item.waitSatisfiedNum ?? 0):false)
        if (errorList.length>0&&organNature==3) {
            message.warning('商品明细中有不符合标准采购数量不可生成采购单')
            return
        }
        let newParams = {
            ...params,
            note: (params.note ?? ""),
            id: editFormVal.id,
            details: editList.map(item => { let { key, ...params } = item; return params; }),
            updateUser: userName,
            // bentityName, bentityCode,
            sourceApp, branch,
            shopId, shopName, shopCode, userName, orgName, orgCode,
        }
        try {
        setSaveBtnLoading(true)
        let { retData } = await purchaseAdvancedService.update(newParams);
        getPurchaseAdvancedOne(editFormVal.advancedCode);
        setSaveBtnLoading(false)
        message.success("编辑成功")
        return retData;
        } catch (error) {
        setSaveBtnLoading(false)
        }

    })

    //商品子表添加
    const insertMaterial = (mode, materialArr) => {
        const setList = mode === 'edit' ? setEditList : mode === "look" ? setLookList : setAddList;
        const repetition = [];
        setList((origin) => {
            const newArr = [...origin];
            materialArr.forEach((material) => {
                //选中的是否存在列表
                let isExist = newArr.find((item) => item.materialCode === (material.code ?? material.materialCode));
                if (!isExist) {
                    delete material.createUser
                    delete material.createTime
                    delete material.deleteFlag
                    delete material.updateUser
                    delete material.updateTime
                    delete material.warehouseCode
                    delete material.warehouseId
                    delete material.warehouseName
                    delete material.shelfCode
                    delete material.shelfId
                    newArr.push({
                        key: getRandomStr(),
                        ...material,
                        confirmNum: 0, //到货数量
                        damageNum: 0,  //损坏数量
                        materialCode: material.code ?? material.materialCode,
                        materialName: material.name ?? material.materialName,
                        // materialNameEn:Item.nameEn ?? Item.materialNameEn,
                        // warehouseCode: '',
                        // warehouseId: '',
                        // warehouseName:'',
                        // shelfCode:'',
                        // shelfId:'',
                        price: material.purchasePrice ?? material.price,
                        id: "",
                        note: ""
                    });
                } else {
                    repetition.push(material.name ?? material.materialName);
                }
            });
            return newArr;
        });
        setMaterialDialog(false);
         !!repetition.length && message.warning(`商品名称：【${repetition.join(",")}】，重复的将不在添加。`);
         //message.success(`导入成功！`);
        if (mode === "edit") { setControlled(true) }
    };

    //商品子表删除、编辑模式下，已有id的需要进行数据库删除 (单条及批量删除)
    const removeMaterial = useLockFn(async (mode, selectedRowKeys) => {
        if (mode === 'edit') {
            let ids = editList.filter(item => selectedRowKeys.indexOf(item.key ?? '') >= 0 && item.id).map(item => ({ id: item.id }));
            //存在id在跑接口进行数据库删除
            if (ids.length) {
                let { retData } = await purchaseAdvancedService.materialDelete({ ids, advancedCode: editFormVal.advancedCode });
                if (!retData) return retData;
            }
        }
        const setList = mode === 'edit' ? setEditList : setAddList;
        setList((origin) => origin.filter((item) => selectedRowKeys.indexOf(item?.key ?? '') < 0));
        message.success("删除成功");
        if (mode === "edit") { setControlled(true) }
    });

    //下载模板
    const downloadTpl = useLockFn(async () => {
        let result = await purchaseAdvancedService.downloadTpl();
        convertRes2Blob(result)
    })

    //结算
    const onSettle = useLockFn(async (params) => {

        let { retData } = !IsFranchisees() ? await purchaseAdvancedService.settle(params) : await purchaseAdvancedService.settleJoin(params);
        getPurchaseAdvancedOne(editFormVal.advancedCode);
        message.success("结算成功");
        return retData
    })

    //库房确认
    const onWarehous = useLockFn(async () => {
        let { id, advancedCode } = editFormVal;
        let paramas = {
            id,
            advancedCode,
            storekeeper: userName
        }
        let { retData } = await purchaseAdvancedService.warehousing(paramas);
        setWsConfirmVisible(false);
        getPurchaseAdvancedOne(advancedCode);
        message.success("入库成功");
        return retData
    })

    //转销售单
    const insertSaleDetail = useLockFn(async (params) => {
        let newParams = {
            ...params,
            createUser: userName,
            sourceApp, branch,
            shopId, shopName, shopCode, userName, orgName, orgCode,
            orderCode: editFormVal.advancedCode,
            orderTypeCode: "purchase-type.transfer",
            orderTypeName: "采购单转接"
        }
        let { retData } = await purchaseAdvancedService.transfer(newParams);
        message.success("转接成功");
        return retData;
    });
    //确认提交
    const decisionSubmit = useLockFn(async () => {
        try {
            purchaseAdvancedService.decisionSubmit({advancedCode: editFormVal.advancedCode,organNature}).then(res => {
                res.retCode==200&&message.success("提交成功！");
                res.retCode==500&&message.warning("提交失败！");
                return true
            })

        } catch (error) {
            message.success("提交失败！"+error);
            return false
        }
    })
    //转接提交
    const transferSubmit = useLockFn(async () => {
        try {
            purchaseAdvancedService.transferSubmit({
                id: editFormVal.id,
                shopName: shopName,
                shopId: shopId,
                advancedCode: editFormVal.advancedCode}).then((res)=>{
                    if(res.retMsg=="操作成功"){
                        message.success("转接成功！");
                        return true
                    }
                    else{
                        return message.warning(res.retMsg);
                    }

                })

        } catch (error) {
            message.success("转接失败！"+error);
            return false
        }
    })

    //各门店库存
    const [stockVisible, setStockVisible] = useState(false);

    return {
        controlled, organType,
        editFormVal,
        editList,
        addFormVal,
        addList,
        lookFormVal,
        lookList,
        addDesc, editDesc, lookDesc,
        materialDialog, setMaterialDialog,
        transferSaleDrawer, setTransferSaleDrawer,
        settleVisible, setSettleVisible,
        wsConfirmVisible, setWsConfirmVisible,
        updateFormVal,
        updateList,
        getPurchaseAdvancedOne,
        insertMaterial, removeMaterial,
        insertDetail,
        editDetail,
        downloadTpl,
        onSettle,
        onWarehous,
        insertSaleDetail,
        verifyWhOrShelf,setSaveBtnLoading,saveBtnLoading,
        stockVisible, setStockVisible,setLookFormVal,decisionSubmit,transferSubmit
    }
})