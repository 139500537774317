import React from "react";
import { useJxcModel } from "../jxcModel";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum";
import useGlobalModel from "@/erp_subpackage/model/globalModel";

export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useJxcModel();
    const { getTreeSelectOne } = useDictModel(() => []);
    const { whDs } = useGlobalModel();
    const searchFields = [
        {
            type: "Input",
            placeholder: "商品编号",
            fieldName: "materialCode"
        },
        {
            type: "Input",
            placeholder: "商品名称",
            fieldName: "materialName"
        },
        {
            type: "TreeSelect",
            placeholder: "商品类型",
            fieldName: "typeCode",
            treeData: getTreeSelectOne(DictCodeEnmu.MATERIAL_TYPE, true)
        },
        {
            type: "RangePicker",
            fieldName: "createTime",
            rangePickerFieldName: ["startTime", "endTime"],
            placeholder: ["出入库起始日期", "出入库结束日期"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
        {
            type: "Select",
            placeholder: "仓库",
            fieldName: "warehouseCode",
            options: whDs
        },
        // {
        //     type: "Input",
        //     placeholder: "仓库",
        //     fieldName: "货位",
        // },
    ]
    return (
        <YhPageHeader
            title="进销存统计表"
            size="small"
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}