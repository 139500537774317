import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import TopSearch from "@/components/TopSearch"
import { getSystemList, disableSystem } from "@/service/maintenanceService"
import { MyContext } from "@/store/myContext"

//系统配置
const SystemConfig = () => {
    const { dispatch, state, message, detailsPage, Switch } = useContext(MyContext)
    const [stystemList, setStystemList] = useState([]) //列表
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })

    useEffect(() => {
        if (state.activeMenu === "systemConfig") {
            loadData()
        }
        return () => setStystemList([])
    }, [state.activeMenu])

    //列表数据
    const loadData = (current) => {
        let data = {
            searchItem: {},
            pageNum: current,
            pageSize: 10,
            orders: [{ column: "updateTime", rule: "DESC" }],
        }
        getSystemList(data).then((res) => {
            // if (res && res.retCode === 200) {
                setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setStystemList(res.retData.list)
            // }
        })
    }
    //分页事件
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }
    //新增 编辑
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "配置新增",
            edit: "配置编辑",
        }
        let modeKey = "systemConfig/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的配置信息")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.SystemDetails({
                modeKey,
                dispatch,
                mode,
                rowInfo: selectInfo.rowInfo,
            }),
        })
    }
    //配置开关
    const systemSweitchChange = (checked, e, id) => {
        e.stopPropagation()
        disableSystem({ id: id, disableFlag: checked ? 0 : 1 }).then((res) => {
            // if (res && res.retCode === 200) {
                message.success("操作成功")
            // } else {
            //     message.error(res.retMsg)
            // }
        })
    }
    //table配置
    const columns = [
        {
            title: "配置的key",
            align: "center",
            dataIndex: "confKey",
        },
        {
            title: "配置的值",
            align: "center",
            dataIndex: "confVal",
        },
        {
            title: "配置类型",
            align: "center",
            dataIndex: "confType",
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
        },
        {
            title: "操作",
            align: "center",
            width: "12%",
            dataIndex: "disableFlag",
            render: (text, record) => (
                <Switch size="small" defaultChecked={text === 0 ? true : false} onChange={(checked, e) => systemSweitchChange(checked, e, record.id)} />
            ),
        },
    ]
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
    ]
    return (
        <>
            {state.activeMenu === "systemConfig" && (
                <>
                    <TopSearch title="系统配置" />
                    <ListComponent
                        title="系统配置列表"
                        buttons={topButtons}
                        columns={columns}
                        dataSource={stystemList}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />
                </>
            )}
        </>
    )
}
export default SystemConfig
