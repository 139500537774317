import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";

//进销存报表
class JxcService {
    async page(searchVo) {
        const apiUrl = "/ci-storage/purchaseSaleStock/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async export(searchVo) {
        const apiUrl = "/ci-storage/purchaseSaleStock/export";
        return await httpRequest.postFile(apiUrl, searchVo, { timeout: 300000 });
    }
    // 头部统计
    async statistics(searchVo) {
        const apiUrl = "/ci-storage/purchaseSaleStock/count";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async purchasePin(searchVo){
        const apiUrl = "/ci-storage/stockIo/find";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async purchasePinExport(searchVo){
        const apiUrl = "/ci-storage/stockIo/exportFind";
        return await httpRequest.postFile(apiUrl, searchVo, { timeout: 300000 });
    }
}

export const jxcService = new JxcService();