import React from "react"
import { Table, Tooltip, Switch, Space } from "antd"
import { useVehicleModel } from "../vehicleModel"
import { momentFormat } from "@/erp_subpackage/utils/util"

export function DataTable() {
    const { dataSource, selectedRowKeys, pgCfg, loading, searchParam, loadData, setSelectedRowKeys, onDisabled } = useVehicleModel()

    const columns = [
        {
            title: "序号",
            width: 80,
            align: "center",
            dataIndex: "branch",
            fixed: "left",
            render: (text, record, index) => index + 1,
        },
        {
            title: "客户编码",
            width: 160,
            dataIndex: "code",
            fixed: "left",
        },
        {
            title: "客户名称",
            width: 200,
            dataIndex: "customerName",
            fixed: "left",
            render: (text, record) => (
                <Tooltip placement="topLeft" title={text}>
                    <p className="one-line">{text}</p>
                </Tooltip>
            ),
        },
        {
            title: "车辆牌照",
            width: 120,
            dataIndex: "licensePlate",
        },
        {
            title: "所属机构",
            width: 120,
            dataIndex: "shopName",
        },
        {
            title: "车辆品牌",
            width: 160,
            dataIndex: "brandName",
        },
        {
            title: "车系",
            width: 120,
            dataIndex: "series",
        },
        {
            title: "车辆型号",
            width: 160,
            dataIndex: "model",
        },
        {
            title: "VIN码",
            width: 160,
            dataIndex: "vin",
        },
        {
            title: "登记日期",
            width: 200,
            dataIndex: "createTime",
            render: (text) => momentFormat(text),
        },
        {
            title: "操作",
            align: "120",
            dataIndex: "enable",
            render: (text, record) => (
                <Space size="middle">
                    <Switch
                        checked={text === 1}
                        disabled={searchParam.shopId}
                        onChange={(checked) => {
                            onDisabled(record.id, checked)
                        }}
                    />
                </Space>
            ),
        },
    ]

    //点击行选高亮
    const onRow = (id) => {
        let findIndex = selectedRowKeys.indexOf(id)
        let newSelectedRowKeys = [...selectedRowKeys]
        if (findIndex === -1) {
            newSelectedRowKeys.push(id)
            setSelectedRowKeys(newSelectedRowKeys)
        } else {
            newSelectedRowKeys.splice(findIndex, 1)
            setSelectedRowKeys(newSelectedRowKeys)
        }
    }

    return (
        <Table
            rowKey={(record) => record.id}
            loading={{ tip: "列表加载中...", spinning: loading }}
            rowSelection={{
                //全部选中时  (存string[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    let changeRowId = changeRows.map((item) => item.id)
                    selected
                        ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                        : setSelectedRowKeys((origin) => origin.filter((item) => changeRowId.indexOf(item) < 0))
                },
                onSelect: (record) => onRow(record.id),
                selectedRowKeys: selectedRowKeys,
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                },
            }}
            onRow={(record) => ({
                onClick: () => onRow(record.id),
                onContextMenu: (e) => {
                    e.preventDefault()
                    setSelectedRowKeys([record.id])
                },
            })}
            scroll={dataSource.length ? { x: "max-content", y: "calc(100vh - 430px)" } : { x: "max-content" }}
        />
    )
}
