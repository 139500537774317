import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";

// "id":"80"
// "code":"80"
// "userCode":"yanghuixiang"
// "name":"杨慧祥"
// "team":"大兴团队"
// "org":"云豪科技"
// "score":"90"
// "status":"通过"
// "createTime":"2022-4-8 11:39:51"
class RecordService {
    async page(searchVo) {
        const apiUrl = "/exam/paper/page";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async orgPage(searchVo) {
        const apiUrl = "/ci-platform/organ/page";
        return await httpRequest.post(apiUrl, searchVo);
    }
}

export const recordService = new RecordService();