import React from "react";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { Description } from "@/erp_subpackage/components/YhBox/Description";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { usePurchaseDrDetailsModel } from "./purchaseDrDetailsModel";

export const TopButs = ({ id, mode }) => {
    const { getTreeNodeName } = useDictModel(() => []);
    const { editFormVal: { code, status, transferInCode } } = usePurchaseDrDetailsModel();

    const orderStatus = [
        {
            label: "调拨单号",
            value: code ?? "",
            color: "blue",
            status: true
        },
        {
            label: "状态",
            value: getTreeNodeName(status ?? ""),
            color: "green",
            status: true
        },
    ]

    return (
        <YhBox title={orderStatus.map((item, index) => <Description key={index} {...item} />)}
            style={{ padding: "0 20px", marginBottom: "10px" }}
        />
    )
}