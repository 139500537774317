import { UploadOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, message, Upload, Input, Modal } from "antd"
import React, { Component, useEffect, useState } from "react"
import { fileUploadUrl, fileLookUrl } from "./config/app"
// import DocumentTitle from "react-document-title"
import "./styles/uploadFile.less"

export const UploadFile = () => {
    const [title, setTitle] = useState("")
    const [previewImage, setpreviewImage] = useState({
        //预览展示头像MD5
        visible: false,
        md5: "",
    })
    const [arr, setArr] = useState([])
    const wd = window
    const props = {
        listType: "picture",
        // className: "upload-btn",
        name: "file",
        action: `${fileUploadUrl}upload/image_old`,
        // action: "https://file-server.laoniuche.cn/file/uploadFile",
        headers: {
            authorization: "authorization-text",
        },
    }

    useEffect(() => {
        const jsonp = document.querySelector("#jsonp")
        jsonp && jsonp.parentNode.removeChild(jsonp)
        const script = document.createElement("script")
        script.src = "https://res.wx.qq.com/open/js/jweixin-1.3.2.js"
        script.type = "text/javascript"
        script.id = "jsonp"
        document.body.appendChild(script)
        return () => {
            const jsonp = document.querySelector("#jsonp")
            jsonp && jsonp.parentNode.removeChild(jsonp)
        }
    }, [])

    return (
        <>
            <div className="upload">
                <div className="upload-box">
                    <div className="upload-title">
                        <i>*</i>
                        <input
                            type="text"
                            placeholder="请输入文章标题"
                            value={title}
                            onChange={(e) => {
                                // console.log(e.target.value)
                                setTitle(e.target.value)
                            }}
                            className="title-ipt"
                        />
                    </div>
                    <div className="upload-img">
                        <Upload
                            onPreview={(file) => {
                                const md5 = file.response.retData
                                setpreviewImage({ visible: true, md5 })
                            }}
                            {...props}
                            beforeUpload={() => {
                                return new Promise((resolve, reject) => {
                                    let isTitle = title === ""
                                    if (isTitle) {
                                        message.error("请先填写文章标题!!!")
                                        return reject(false)
                                    }
                                    return resolve(true)
                                })
                            }}
                            onChange={(info) => {
                                if (info.file.status !== "uploading") {
                                    console.log(info.file, info.fileList)
                                }
                                if (info.file.status === "done" || info.file.status === "removed") {
                                    message.success(`${info.file.name} 上传成功`)
                                    const wx = wd.wx
                                    console.log(wx, title)
                                    wx.miniProgram.postMessage({
                                        data: info.fileList.map((item) => ({
                                            fileName: item.name,
                                            fileMd5: item.response.retData,
                                            fileType: item.type,
                                            fileSize: item.size,
                                            title: title,
                                        })),
                                    })
                                } else if (info.file.status === "error") {
                                    message.error(`${info.file.name} file upload failed.`)
                                }
                            }}
                        >
                            {/* {uploadButton} */}
                            <Button icon={<UploadOutlined />}>点击上传</Button>
                        </Upload>
                    </div>
                    <p className="upload-msg">*请上传格式为PDF/Jpg</p>
                </div>
                <div
                    className="save-btn"
                    onClick={() => {
                        wd.wx.miniProgram.navigateBack({
                            delta: 1,
                        })
                    }}
                >
                    确认上传
                </div>
            </div>
            <Modal visible={previewImage.visible} title="图片查看" footer={null} onCancel={() => setpreviewImage({ ...previewImage, visible: false })}>
                <img alt="" style={{ width: "300px" }} src={`https://file-server.yunhaochefu.com/image/${previewImage.md5}`} />
            </Modal>
        </>
    )
}
