import React, { useEffect, useState, useRef } from 'react'
import { Form, Input, Col, Row, Select, message, Modal, Spin, Button } from 'antd'
import { getOrderOne, orderThreeGuaranteesDetail, orderThreeGuaranteesInsert, orderThreeGuaranteesUpdateOne, lookMaintainer } from '@/service/ordersService'
import { getPcodeDict } from '@/utils/dataStorage'
import Maintenance from './maintenance' //维修项目
import Material from './material' //物料
import { getBussinessEntityList } from '@/service/dataService'
import debounce from 'lodash/debounce'
import moment from 'moment'
import Loading from '@/components/Loading'
const { Option } = Select
export default (props) => {
    const { mode, rowInfo, visible, setVisible, onSuccess, orderId } = props
    const [orderDetail, setOrderDetail] = useState(null)
    const [sanbaoDetail, setSanbaoDetail] = useState({ materials: [], items: [] })
    const [form] = Form.useForm()
    const maintenanceRef = useRef() //维修项目 子组件
    const materialRef = useRef() //维修物料  子组件
    const [loading, setLoading] = useState(false)

    const [entityData, setEntityData] = useState({
        //所属实体信息
        data: [],
        fetching: false,
    })

    // useEffect(() => {
    //     if (visible) {
    //         setLoading(true)
    //         queryMotorcade()
    //         if (mode === 'insert') {
    //             getOrderDetail()
    //             getLookMaintainer()
    //         }
    //     }
    // }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        init()
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    const init = async() => {
        setLoading(true)
        await queryMotorcade()
        await getOrderDetail()
        if (mode === 'insert') {
            getLookMaintainer()
        }
    }

    const onReceivables = (obj) => {
        //配件总金额变动
        if (obj.material) {
            form.setFieldsValue({
                outboundAmount: Math.round(obj.material * 100) / 100,
            })
        }
    }

    //获取维修人员
    const getLookMaintainer = () => {
        lookMaintainer(orderId).then((res) => {
            const list = res.retData.map((item) => {
                return item.userName
            })
            form.setFieldsValue({
                maintenanceStaff: list.join(','),
            })
        })
    }

    const getOrderDetail = async () => {
        try {
            const { retData: results } = await getOrderOne(orderId)
            setOrderDetail(results)
            const { orderCompletedTime, faultDesc } = results
            if (mode === 'insert') {
                form.setFieldsValue({
                    repairTime: orderCompletedTime ? orderCompletedTime : moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                    faultHandle: faultDesc,
                })
            }
            setTimeout(() => {
                maintenanceRef.current.upData()
                materialRef.current.upData()
                setLoading(false)
            }, 500)
        } catch {
            message.error('获取订单详情失败')
        }
    }

    //查看编辑回显
    const queryMotorcade = async () => {
        if (mode === 'look' || mode === 'edit') {
            try {
                const { retData: results } = await orderThreeGuaranteesDetail(rowInfo.id)
                setSanbaoDetail(results)
                const { ciBusinessEntityId, ciBusinessEntityName } = results
                setEntityData({
                    data: [{ text: ciBusinessEntityName, value: ciBusinessEntityId }],
                    fetching: false,
                })
                form.setFieldsValue({
                    ...results,
                })
                setTimeout(() => {
                    materialRef.current.upData()
                }, 500)
            } catch {
                message.error('编辑回显查询失败')
            }
        } else if (mode === 'insert') {
            form.setFieldsValue({})
        }
    }

    //表单提交
    const onUserSubmit = async () => {
        let maintainMaterialsMap, maintenanceMap
        // try {
        let materialData = await materialRef.current.upMaterialData()
        maintainMaterialsMap = await materialData.map((item, index) => {
            return {
                id: item.id || null,
                maintainOrderId: item.maintainOrderId,
                maintainOrderMaterialId: item.maintainOrderMaterialId,
                // threeGuaranteesUnit: item.threeGuaranteesUnit, //三包状态
                // claimNumber: item.claimNumber,
                serviceNature: item.serviceNature, //服务性质
                preferentialAmount: item.preferentialAmount, //优惠
                partUnit: item.partUnit, //单位
                materialDiscount: item.materialDiscount ? item.materialDiscount : null, //折扣
                unitPrice: item.unitPrice,
                materialNumber: item.materialNumber, //物料数量
                giftFlag: item.giftFlag, //是否赠送
                maintainOrderCode: item.maintainOrderCode,
                serialNumber: index + 1,
                materialName: item.materialName,
            }
        })

        //项目数据
        let maintenanceData = await maintenanceRef.current.upMaintenancelData()
        maintenanceMap = await maintenanceData.map((item) => {
            return {
                id: item.id || null, //编辑带过来的id
                itemId: item.itemId, //维修项目id
                preferentialAmount: item.preferentialAmount, //维修项优惠
                itemName: item.itemName, //项目名称
                itemCode: item.itemCode, //项目编码
                itemWorkHours: item.workHour, //项目工时
                workHoursPrice: item.hourPrice, //项目单价
                itemDiscount: item.discount ? item.discount : null, //折扣
                serviceNature: item.serviceNature, //服务性质
                remark: item.remark, //备注
                // threeGuaranteesUnit: item.threeGuaranteesUnit, //三包状态
                // claimNumber: item.claimNumber, //索赔单号
                giftFlag: item.giftFlag, //是否赠送
            }
        })

        form.validateFields().then((values) => {
            let data = {
                ...values,
                maintainMaterials: maintainMaterialsMap,
                items: maintenanceMap,
                orderId,
            }
            if (mode === 'edit') {
                data.id = rowInfo.id
                orderThreeGuaranteesUpdateOne(data).then((res) => {
                    message.success('编辑成功')
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                orderThreeGuaranteesInsert(data).then((res) => {
                    message.success('新增成功')
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
        // } catch (error) {
        //     console.log(error)
        //     return
        // }
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    const handleCancel = () => {
        setVisible('')
    }

    const fetchEntity = (value) => {
        setEntityData({
            ...entityData,
            fetching: true,
        })
        let data = {
            searchItem: {
                entityName: value,
            },
            pageNum: 1,
            pageSize: 10,
            orders: [
                {
                    column: 'createTime',
                    rule: 'DESC',
                },
            ],
        }
        getBussinessEntityList(data).then((res) => {
            let results = res.retData.list
            const dataMap = results.map((team) => ({
                text: team.entityName,
                value: team.id,
            }))
            setEntityData({
                data: dataMap,
                fetching: false,
            })
        })
    }
    //防抖
    const fetchEntitys = debounce(fetchEntity, 1000)

    const hanldePrice = async () => {
        const values = await form.validateFields()
        let materialAmount = 0,
            hourlyWage = 0,
            travelExpenses = 0,
            mealSupplement = 0
        if (values.materialAmount && /^[0-9]+(.[0-9]{0,2})?$/.test(values.materialAmount)) {
            materialAmount = +values.materialAmount
        }
        if (values.hourlyWage && /^[0-9]+(.[0-9]{0,2})?$/.test(values.hourlyWage)) {
            hourlyWage = +values.hourlyWage
        }
        if (values.travelExpenses && /^[0-9]+(.[0-9]{0,2})?$/.test(values.travelExpenses)) {
            travelExpenses = +values.travelExpenses
        }
        if (values.mealSupplement && /^[0-9]+(.[0-9]{0,2})?$/.test(values.mealSupplement)) {
            mealSupplement = +values.mealSupplement
        }
        let totalClaim = (materialAmount + hourlyWage + travelExpenses + mealSupplement).toFixed(2),
            totalHourlyWage = (hourlyWage + travelExpenses + mealSupplement).toFixed(2)
        form.setFieldsValue({
            materialAmount,
            hourlyWage,
            travelExpenses,
            mealSupplement,
            totalClaim,
            totalHourlyWage,
        })
    }

    const handleEntityChange = (val, data) => {
        form.setFieldsValue({ ciBusinessEntityName: data.children })
    }

    return (
        <Modal
            forceRender
            visible={visible}
            title="三包信息"
            centered={true}
            footer={
                !loading
                    ? [
                          <Button onClick={handleCancel} key="back">
                              取消
                          </Button>,
                          <Button type="primary" onClick={onUserSubmit} key="submit">
                              保存
                          </Button>,
                      ]
                    : [ <Button onClick={handleCancel} key="back">
                    取消
                </Button>]
            }
            onOk={() => onUserSubmit()}
            onCancel={() => handleCancel()}
            width={1200}
        >
            <div className="form-details-warp">
                <Form form={form} {...formItemLayout}>
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                label="三包品牌"
                                name="threeGuaranteesBrand"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择三包品牌',
                                    },
                                ]}
                            >
                                <Select placeholder="三包品牌" style={{ width: '100%' }}>
                                    {getPcodeDict('106').children.map((item) => (
                                        <Option value={item.dictCode} key={item.id}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="所属实体" name="ciBusinessEntityId">
                                <Select
                                    allowClear
                                    placeholder="请选择所属实体"
                                    style={{ width: '100%' }}
                                    notFoundContent={entityData.fetching ? <Spin size="small" /> : null}
                                    optionFilterProp="children"
                                    onFocus={() => fetchEntity()}
                                    onSearch={fetchEntitys}
                                    filterOption={false}
                                    onChange={handleEntityChange}
                                >
                                    {entityData.data.map((item) => (
                                        <Select.Option value={item.value} key={item.value}>
                                            {item.text}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="索赔单号"
                                name="claimNumber"
                                getValueFromEvent={(event) => {
                                    return event.target.value.replace(/[\u4E00-\u9FA5]/g, '')
                                }}
                                rules={
                                    [
                                        // {
                                        //     required: true,
                                        //     message: "请输入索赔单号",
                                        // },
                                    ]
                                }
                            >
                                <Input placeholder="可自动生成索赔单号" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="结算单号" name="statementNumber">
                                <Input placeholder="结算单号" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="材料费"
                                name="materialAmount"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: '请输入数字且可保留两位小数',
                                    },
                                ]}
                            >
                                <Input placeholder="材料费" onBlur={hanldePrice} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="工时费"
                                name="hourlyWage"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: '请输入数字且可保留两位小数',
                                    },
                                ]}
                            >
                                <Input placeholder="工时费" onBlur={hanldePrice} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="外出费"
                                name="travelExpenses"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: '请输入数字且可保留两位小数',
                                    },
                                ]}
                            >
                                <Input placeholder="外出费" onBlur={hanldePrice} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="餐补费"
                                name="mealSupplement"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: '请输入数字且可保留两位小数',
                                    },
                                ]}
                            >
                                <Input placeholder="餐补费" onBlur={hanldePrice} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="其他费用"
                                name="otherAmount"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: '请输入数字且可保留两位小数',
                                    },
                                ]}
                            >
                                <Input placeholder="其他费用" onBlur={hanldePrice} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="出库金额" name="outboundAmount">
                                <Input placeholder="出库金额" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="索赔总额" name="totalClaim">
                                <Input placeholder="索赔总额" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="故障处理" name="faultHandle">
                                <Input placeholder="故障处理" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="工时费汇总" name="totalHourlyWage">
                                <Input placeholder="三包工时费汇总" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="维修时间" name="repairTime">
                                <Input placeholder="维修时间" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="维修人员" name="maintenanceStaff">
                                <Input placeholder="维修人员" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="工时定额"
                                name="workHour"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: '请输入数字且可保留两位小数',
                                    },
                                ]}
                            >
                                <Input placeholder="工时定额" />
                            </Form.Item>
                        </Col>

                        <Form.Item label="" name="ciBusinessEntityName" hidden>
                            <Input placeholder="" disabled />
                        </Form.Item>
                    </Row>
                </Form>
                {orderDetail && (
                    <>
                     <Maintenance
                        maintenanceRef={maintenanceRef}
                        orderId={orderId}
                        mode={mode}
                        onReceivables={onReceivables}
                        parentOrderType={orderDetail.orderType}
                        sanbaoData={sanbaoDetail.items}
                        dataType="sanbao"
                        orderStatus={orderDetail.orderStatus}
                    /> 
                    <Material
                        materialRef={materialRef}
                        orderId={orderId}
                        mode={mode}
                        onReceivables={onReceivables}
                        parentOrderType={orderDetail.orderType}
                        teamId={orderDetail.teamId}
                        sanbaoData={sanbaoDetail.materials}
                        dataType="sanbao"
                        orderStatus={orderDetail.orderStatus}
                    />
                    </>
                )}

            </div>
        </Modal>
    )
}
