import React, { useState } from "react";
import { Button, Tooltip, Table, message, Upload, Input } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useStockCheckDetailsModel } from "./stockCheckDetailModel";
import { formatInt, formatNum, formatIntPoint } from "@/erp_subpackage/utils/util";
import { EditableCell, EditableRow } from '@/erp_subpackage/components/YHEditTable/editTable';
import { importPropsConfig } from "@/erp_subpackage/utils/importFile";
import { useGlobalModel } from "@/erp_subpackage/model/globalModel";
import { ExclamationCircleOutlined } from '@ant-design/icons'

export const TableBox = ({ modeKey, mode, rowId, dispatch }) => {
    const {
        editList, addList, lookList, editFormVal, addFormVal, shopName, shopId, updateList, setMaterialDialog, insertMaterial, setStockRecordDialog, removeMaterial, downloadTpl, getResult
    } = useStockCheckDetailsModel();
    const { setMaterialInfo } = useGlobalModel(() => []);
    const tableList = mode === "edit" ? editList : mode === "look" ? lookList : addList;
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchParms, setSearchParms] = useState("");
    const [searchVal, setSearchVal] = useState({ data: [], isSearch: false });   //用于搜索展示的数据
    const formVal = mode === "edit" ? editFormVal : addFormVal;
    //导入成功
    const importCallback = async (list) => {
        if (!formVal.warehouseCode) {
            message.warning("请选择盘点仓库后进行商品操作！",4);
            return false
        }
        if (list.list && list.list.length) {
            insertMaterial(mode, list.list)
        }
    }

    //搜索  前端自己保存搜索的值， 搜索值为空还是用缓存
    const goodsFilter = (dataSource = null) => {
        let goodsList = dataSource ? dataSource : [...tableList];
        let param = searchParms.trim()
        if (param) {
            let reg = new RegExp(param);
            let arr = [];
            goodsList.forEach(item => {
                if (item.materialCode?.match(reg) || item.materialName?.match(reg)) {
                    arr.push(item);
                }
            })
            setSearchVal({ data: arr, isSearch: true });
            if (!arr.length) {
                message.warning("列表没有该商品");
            }
        } else {
            setSearchVal({ data: [], isSearch: false });
        }
    }

    const action = mode === "look" ? null : <>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (!formVal.warehouseCode) {
                    message.warning("请选择仓库后进行商品操作！");
                    return;
                }
                setMaterialDialog(true)
            }}
        >新增</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (!selectedRowKeys.length) {
                    message.warning("请选择需要删除的数据")
                    return;
                }
                removeMaterial(mode, selectedRowKeys,);
                setSelectedRowKeys([])
            }}
        >删除</Button>
        <Upload showUploadList={false} {...importPropsConfig(`/ci-storage/stock/check/details/import`, importCallback, { shopName, shopId,warehouseCode:formVal.warehouseCode,warehouseName:formVal.warehouseName })}>
            <Button className="btn-item" shape="round">导入</Button>
        </Upload>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => downloadTpl()}
        >下载模板</Button>
    </>

    const rightChildren = <div className="flex">
        <Input
            allowClear
            size="small"
            className="border-r-16 mr-10"
            style={{ width: "150px" }}
            placeholder="商品编号/商品名称"
            onChange={(e) => setSearchParms(e.target.value)}
            onPressEnter={() => goodsFilter()}
        />
        <Button shape="round" onClick={() => goodsFilter()}>检索</Button>
    </div>

    const columns = [
        {
            title: '序号',
            width: 60,
            render: (t, r, i) => <>{i + 1}</>
        },
        {
            title: '提示',
            width: 60,
            align: "center",
            dataIndex: '',
            render: (t, r) => 
                r.inventoryOccupied>0  ? 
                <Tooltip placement="topLeft" title={!r.isHave ? r?.msg ?? `该商品已有占用库存` : ''} arrowPointAtCenter>
                    <ExclamationCircleOutlined style={{ color: '#FF4500', fontSize: '20px' }} />
                </Tooltip> : ''
             
        },  
        {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            render: (text, record) => <Tooltip placement="topLeft" title={text}><p className="one-line click-span" onClick={(e) => { e.stopPropagation(); setMaterialInfo(true, { materialCode: text, materialName: record.materialName }) }}>{text}</p></Tooltip>,
        }, {
            title: '商品名称',
            dataIndex: 'materialName',
            width: 120,
            render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '仓库',
            dataIndex: 'warehouseName',
            width: 120,
        }, {
            title: '货位',
            dataIndex: 'shelfCode',
            width: 120,
        }, {
            title: '系统库存',
            dataIndex: 'inventoryNum',
            width: 120,
        }, {
            title: '实际库存',
            dataIndex: 'realNum',
            editable: mode === "look" ? false : true,
            width: 120,
        }, {
            title: '盘点结果',
            dataIndex: 'result',
            width: 120,
        }, {
            title: '差异量',
            dataIndex: 'differenceNum',
            width: 120,
        }, {
            title: "差异金额",
            dataIndex: 'differenceAmount',
            width: 120,
        }, {
            title: '报废数量',
            dataIndex: 'scrapNum',
            editable: mode === "look" ? false : true,
            width: 120,
        }, {
            title: '报废金额',
            dataIndex: 'scrapAmount',
            width: 120,
        }, {
            title: '差异原因',
            width: 160,
            dataIndex: 'note',
            editable: mode === "look" ? false : true,
        }, {
            title: '报废原因',
            width: 160,
            dataIndex: 'scrapReason',
            editable: mode === "look" ? false : true,
        }, {
            title: '盘点人',
            width: 160,
            dataIndex: 'checkUser',
            editable: mode === "look" ? false : true,
        }, 
        // {
        //     title: '操作',
        //     width: 100,
        //     fixed: "right",
        //     align: "center",
        //     // render:mode !=='look'?(text, record) => <span className="click-span" onClick={() => { setStockRecordDialog({ visible: true, materialCode: record.materialCode }) }}>出入库记录</span>:''
        // }
    ];

    const newColumn = columns.map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                editable: col?.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    });

    //内联编辑
    const handleSave = (row) => {
        //实际库存
        // row.realNum = +formatInt(row.realNum); 
        row.realNum=+formatNum( row.realNum)
        //系统库存
        // row.inventoryNum = +formatInt(row.inventoryNum); 
        row.inventoryNum =(row.inventoryNum == undefined || row.inventoryNum  === null || isNaN(+row.inventoryNum)) ? 0 :  +(row.inventoryNum)
        
        //报废数量
        row.scrapNum = +formatInt(row.scrapNum);
        //最新金价
        row.averagePurchasePrice = +formatNum(row.averagePurchasePrice);
        //差异量
        row.differenceNum = (+formatIntPoint(row.realNum - row.inventoryNum)).toFixed(2);
        //结果
        row.result = getResult(row.differenceNum);
        //差异金额 = 实际库存 - 系统库存 * 最新金价
        row.differenceAmount = formatNum((row.realNum - row.inventoryNum) * row.averagePurchasePrice);
        //报废金额 = 报废数量 * 最新金价
        row.scrapAmount = formatNum(row.scrapNum * row.averagePurchasePrice);
 

        let dataSource = [...tableList];
        const index = dataSource.findIndex((item) => row.key === item.key);
        const item = dataSource[index];
        dataSource.splice(index, 1, { ...item, ...row });
        updateList(mode, dataSource);
        if (searchVal.isSearch) {
            goodsFilter(dataSource)
        }
    }

    return (
        <YhBox title={action} rightChildren={rightChildren}>
            <Table
                rowClassName={(record) => {
                    if (record?.inventoryOccupied>0) return "editable-row yh-edit-table-tr background-FFB4B4";
                    return "editable-row yh-edit-table-tr"
                }}
                rowSelection={{
                    selectedRowKeys: selectedRowKeys, //记录选中
                    onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRowKeys(selectedRowKeys);
                    }
                }}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newColumn}
                dataSource={searchVal.isSearch ? searchVal.data : tableList}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}

