import React, { useContext, useEffect } from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { ScmDataBox } from "./scmStatistic/DataBox";
import { MyContext } from "@/store/myContext";
import { useStockStatisticModel } from "../stockStatisticModel";

export default () => {
    const { state } = useContext(MyContext);
    const { searchParam, getMdShopList } = useStockStatisticModel(({ searchParam }) => [searchParam]);

    useEffect(() => {
        getMdShopList()
    }, [])

    return (
        <>
            {state.activeMenu === "stockStatistic" && <>
                <PageHeader />
                {/**是否查看的是供应链的库存统计 */}
                {
                    // searchParam.outerShopId ? <ScmDataBox /> : <DataBox /> 
                    searchParam.diffField === "机构" ? <DataBox /> : <ScmDataBox />
                }
            </>}
        </>
    )
}