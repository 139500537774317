import React, { useRef, useEffect, useState } from 'react'
import { message, Modal, Upload, Button } from 'antd'
import YHDrawer from '@/erp_subpackage/components/YhDrawer'
import { YhForm } from '@/erp_subpackage/components/YhFormBox'
import { useSettleModel } from './settleModel'
import { EditableRow, EditableCell } from '@/erp_subpackage/components/YHEditTable/editTable'
import { DictCodeEnmu } from '@/erp_subpackage/utils/DictCodeEnum'
import { useDictModel } from '@/erp_subpackage/pages/base/dict/dictModel'
import { getFormValidatorErrText, repairFormatNum } from '@/erp_subpackage/utils/util'
import Table from 'antd/lib/table'
import useGlobalModel from '@/erp_subpackage/model/globalModel'
import { getOne } from '@/service/customerService'
import { getCustomerListNew } from '@/service/customerService'
import FontIcon from '@/erp_subpackage/components/YhIcon'
import { fileUploadUrl, fileLookUrl } from '@/config/app'
import { CloseCircleOutlined, CloudUploadOutlined } from '@ant-design/icons'
import { getToken } from '@/utils/dataStorage'
import { getBaseImgUploadUrl, getBaseUploadData, getBaseImgLookUrl } from '@/config/app'
import { YhFmsAddAccount } from '@/erp_subpackage/components/YhFmsAddAccount'
import { YhFmsAddAdjust } from '@/erp_subpackage/components/YhFmsAddAdjust'
import { YhFmsAddRecharge } from '@/erp_subpackage/components/YhFmsAddRecharge'
import { commonService } from '@/erp_subpackage/pages/common/commonService'
import { bignumber } from '../../utils/util'
import { IsFranchisees } from '@/utils/common'
//结算
export const SettleDrawer = ({ id, customerVehicleId, mode, loadData }, rowInfo) => {
    const {
        settleVisible: visible,
        detailEditForm,
        detailLookForm,
        setSettleVisible,
        onSettle,
        account,
        updateEditFormVal,
        setDetailEditForm,
    } = useSettleModel()
    const {
        user: { userName, shopCode, shopName, shopId },
    } = useGlobalModel()
    const { getSelectedDictOne, getTreeNodeName, getTreeNodeOne } = useDictModel()
    const formRef = useRef()
    const typeForm = {
        edit: detailEditForm,
        look: detailLookForm,
    }
    const [settleType, setSettleType] = useState(DictCodeEnmu.SETTLE_TYPE_CASH) //结算类型，  默认现金结账
    const [settlementPart, setSettlementPart] = useState(DictCodeEnmu.SETTLE_PARTY_INTERNAL) //结算方 默认客户自费
    const initDataSource = {
        key: new Date().getTime().toString(),
        payMethodCode: '',
        payMethodName: '',
        available: 0,
        amount: 0,
        payee: '',
        note: '',
    }
    const [payDataSource, setPayDataSource] = useState([]) //表格默认值
    const [accountTypeDs, setAccountTypeDs] = useState([]) //实时存取收款账户类型值
    const [editPayMethodOptions, setEditPayMethodOptions] = useState(getSelectedDictOne(DictCodeEnmu.PAY_METHOD)) //编辑根据结算类型展示结算方式
    const [cash, setCash] = useState(false) //是否展示现金类得支付方式
    const [payTypeChange, setPayTypeChange] = useState(false)

    useEffect(() => {
        if (visible) {
            console.log(111)
            console.log( typeForm[mode])
            formRef.current.setFieldsValue({ ...typeForm[mode] })
            setPayDataSource(mode === 'edit' ? [{ ...initDataSource, amount: detailEditForm.receivableAmount }] : [{ ...initDataSource, ...detailLookForm }])
            setSettleType(typeForm[mode].payType)
            setPayMethodOptions(typeForm[mode].payType)
            if (!detailEditForm.payType) {
                getOne(customerVehicleId).then(async (res) => {
                    //查询是否为签约大包/月结账户 是则默认带出客户自费/大包/大包账户
                    let { retData } = res
                    if (retData?.signType === '046001') {
                        await setDetailEditForm({ ...detailEditForm, payType: 'settle-type.tco', settlementPartyCode: 'settle-party.self-pay' })
                        console.log(detailEditForm)
                        setPayTypeChange(true)
                        queryAccount(detailEditForm.customerCode, 1, 'settle-type.tco', detailEditForm.customerName, 'settle-party.self-pay')
                    }
                    if (retData?.signType === '046002') {
                        setDetailEditForm({ ...detailEditForm, payType: 'settle-type.monthly', settlementPartyCode: 'settle-party.self-pay' })
                        setPayTypeChange(true)
                        queryAccount(detailEditForm.customerCode, 1, 'settle-type.monthly', detailEditForm.customerName, 'settle-party.self-pay')
                    }
                    if (!retData || retData?.signType === '') {
                        setPayTypeChange(false)
                    }
                })
            }
        }
    }, [detailEditForm, detailLookForm])

    const setPayMethodOptions = (payType) => {
        let newPayMethodOptions = getSelectedDictOne(DictCodeEnmu.PAY_METHOD).filter((item) => {
            if (item.relationvalue === payType) {
                return item
            }
        })
        setEditPayMethodOptions(newPayMethodOptions)
    }
    // 查询客户账户类型及可用额度 后期补
    const queryAccount = async (partnerCode, creditFlag, payType, customerName, settlementPartyCode) => {
        setQueryAccountCustomer({ code: partnerCode, name: customerName })
        // console.log(partnerCode, creditFlag, payType)
        if (typeForm[mode].customerCode == null || typeForm[mode].customerCode == '') {
            message.warning(`该${customerName}客户没有对应的客户编码`)
            return
        }
        let params = {
            partnerCode,
            //partnerCode: typeForm[mode].customerCode,  //客户/供应商编码
            // beCode: 'bentity.bj-yh-auto-service',
            beCode: typeForm[mode].bentityCode,
            status: 0,
            creditFlag, //为记账类型的  1挂账 2现结
            sourceApp: 'ci',
            shopCode,
        }

        account(params).then((res) => {
            const retData = res.retData
            //根据优惠类型， 过滤授信账户的 （返利|授信）
            let filterResult = retData.filter((item) => {
                return item
            })
            //现金账户 或者 挂账账户。
            let newAccTypeDs = [] // filterResult.map(item => ({ label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }));
            if (payType === DictCodeEnmu.SETTLE_TYPE_CASH) {
                newAccTypeDs = filterResult.map((item) => ({
                    ...item,
                    label: item.name ?? '',
                    value: item.code ?? '',
                    available: item.availableAmount ?? 0,
                    accountTypeCode: item.accountTypeCode,
                    key: item.key ?? '',
                }))
            } else if (payType === DictCodeEnmu.SETTLE_TYPE_CREDIT) {
                //挂账  account-type.credit
                newAccTypeDs = filterResult
                    .filter((item) => {
                        //如果结算方为部门自费，账户类型只能为临时授信
                        if (
                            (detailEditForm.settlementPartyCode === undefined ? settlementPartyCode : detailEditForm.settlementPartyCode) !==
                            'settle-party.self-pay'
                        ) {
                            if (item.accountTypeCode == 'account-type.tco' || item.accountTypeCode !== 'account-type.credit-month')
                                return { label: item.name ?? '', value: item.code ?? '', available: item.availableAmount ?? 0 }
                        } else {
                            if (item.accountTypeCode == 'account-type.credit')
                                return { label: item.name ?? '', value: item.code ?? '', available: item.availableAmount ?? 0 }
                        }
                    })
                    .map((item1) => ({
                        ...item1,
                        label: item1.name ?? '',
                        value: item1.code ?? '',
                        available: item1.availableAmount ?? 0,
                        accountTypeCode: item1.accountTypeCode,
                        key: item1.key ?? '',
                    }))
            } else if (payType === DictCodeEnmu.SETTLE_TYPE_BALANCE) {
                //月结  account-type.credit-month
                newAccTypeDs = filterResult
                    .filter((item) => {
                        //如果结算方为部门自费，账户类型只能为月结
                        if (
                            (detailEditForm.settlementPartyCode === undefined ? settlementPartyCode : detailEditForm.settlementPartyCode) !==
                            'settle-party.self-pay'
                        ) {
                            if (item.accountTypeCode == 'account-type.tco' || item.accountTypeCode !== 'account-type.credit')
                                return { label: item.name ?? '', value: item.code ?? '', available: item.availableAmount ?? 0 }
                        } else {
                            if (item.accountTypeCode == 'account-type.credit-month')
                                return { label: item.name ?? '', value: item.code ?? '', available: item.availableAmount ?? 0 }
                        }
                    })
                    .map((item1) => ({
                        ...item1,
                        label: item1.name ?? '',
                        value: item1.code ?? '',
                        available: item1.availableAmount ?? 0,
                        accountTypeCode: item1.accountTypeCode,
                        key: item1.key ?? '',
                    }))
            } else if (payType === DictCodeEnmu.SETTLE_TYPE_TCO) {
                //  大包account-type.credit
                newAccTypeDs = filterResult
                    .filter((item) => {
                        //如果结算方为部门自费，账户类型只能为大包
                        if (
                            (detailEditForm.settlementPartyCode === undefined ? settlementPartyCode : detailEditForm.settlementPartyCode) !==
                            'settle-party.self-pay'
                        ) {
                            //不是客户自费 时其他类型
                            if (item.accountTypeCode == 'account-type.tco' || item.accountTypeCode !== 'account-type.credit-month')
                                return { label: item.name ?? '', value: item.code ?? '', available: item.availableAmount ?? 0 }
                        } else {
                            if (item.accountTypeCode == 'account-type.tco')
                                return { label: item.name ?? '', value: item.code ?? '', available: item.availableAmount ?? 0 }
                        }
                    })
                    .map((item1) => ({
                        ...item1,
                        label: item1.name ?? '',
                        value: item1.code ?? '',
                        available: item1.availableAmount ?? 0,
                        accountTypeCode: item1.accountTypeCode,
                        key: item1.key ?? '',
                    }))
            }
            setAccountTypeDs(newAccTypeDs)
            //选择挂账， 自动带出挂账的账户及授信额度s
            if (mode === 'edit') {
                if (newAccTypeDs.length > 0) {
                    setCash(newAccTypeDs[0].accountTypeCode === 'account-type.cash')
                    setPayDataSource(() => {
                        let available = +(newAccTypeDs[0]?.available ?? 0) //可用额度
                        let payAmount = +typeForm[mode].receivableAmount //需要支付的额度
                        let obj = [
                            {
                                ...initDataSource,
                                available: available,
                                payMethodName: newAccTypeDs[0].label,
                                payMethodCode: newAccTypeDs[0].value,
                                payMethodValue: newAccTypeDs[0].accountTypeCode,
                                amount: payAmount, // +`${payAmount <= available ? payAmount : 0}`,
                            },
                        ]
                        return obj
                    })
                } else {
                    setCash(false)
                    setPayDataSource([{ ...initDataSource, amount: typeForm[mode].receivableAmount }])
                     {/* 开户额度修改 */}
                    message.warning(`该${customerName}客户没有对应的账户`)
                    // if (payType === 'settle-type.balance') {
                    //     message.warning(`该${customerName}客户没有对应的账户`)
                    // } else {
                    //     Modal.confirm({
                    //         title: '提示',
                    //         content: '该类型没有账户是否新增？',
                    //         okText: '确定',
                    //         cancelText: '取消',
                    //         onOk: () => {
                    //             setAddAccountVisible(true)
                    //         },
                    //         onCancel: () => {
                    //             setSettleVisible(false)
                    //         },
                    //     })
                    // }
                }
            }
        })
    }

    //每次类型选择重置表格数据  为挂账时，请求到该账户的授信列表
    const settleTypeChange = (value, option) => {
        if (option && (!IsFranchisees() ||  typeForm[mode].orderType === "014006")) {
            //如果是加盟商不用查询
            setSettleType(option.value)
            setPayDataSource([initDataSource])
            setPayDataSource([{ ...initDataSource, amount: detailEditForm.receivedAmount }])
            setCash(false)
            setDetailEditForm({ ...detailEditForm, payType: value })
            // queryAccount(
            //     typeForm[mode].customerCode,
            //     value === DictCodeEnmu.SETTLE_TYPE_CASH ? 0 : typeForm[mode].payType === 'settle-type.balance' ? 2 : 1,
            //     value,
            //     typeForm[mode].customerName
            // )
            if (typeForm[mode].settlementPartyCode === 'settle-party.internal') {
                //这里为部门内部结算时，查询销售实体为客户的接口换取id
                const searchData = {
                    pageSize: 15,
                    pageNum: 1,
                    shopId: shopId,
                    searchItem: { customerNameEq: shopName },
                    customerStatus: 0,
                }
                getCustomerListNew(searchData).then((res) => {
                    const {
                        retData: { list: records },
                    } = res
                    if (records) {
                        let { customerCode: code } = records[0]
                        queryAccount(code, value === 'settle-type.cash' ? 0 : value === 'settle-type.balance' ? 2 : 1, value, shopName)
                    } else {
                        message.warning(`该结算方为部门内部费用时，无维护对应为${shopName}客户`)
                    }
                })
            } else if (
                !(
                    typeForm[mode].settlementPartyCode === ' settle-party.internal' ||
                    typeForm[mode].settlementPartyCode === 'settle-party.self-pay' ||
                    typeForm[mode].settlementPartyCode === 'settle-party.dabaomaintenance'
                )
            ) {
                let { value: customerCode, name } = getTreeNodeOne(typeForm[mode].settlementPartyCode)[0]
                if (customerCode) {
                    queryAccount(customerCode, value === 'settle-type.cash' ? 0 : value === 'settle-type.balance' ? 2 : 1, value, name)
                } else {
                    message.warning(`该结算方数据字典无维护对应的客户编码`)
                }
            } else if (mode !== 'look') {
                queryAccount(
                    typeForm[mode].customerCode,
                    value === 'settle-type.cash' ? 0 : value === 'settle-type.balance' ? 2 : 1,
                    value,
                    typeForm[mode].customerName
                )
            }
            setPayMethodOptions(value)
        }
    }

    //结算方  //每次类型选择重置表格数据
    const settlementPartyChange = (value, option) => {
        console.log(111)
        console.log(option.label)
        formRef.current.setFieldsValue({ settlementPartyName: option.label, settlementPartyCode: option.value })
        if (option) {
            setSettlementPart(option.value)
            setPayDataSource([initDataSource])
            setPayDataSource([{ ...initDataSource, amount: detailEditForm.receivableAmount }])
            setDetailEditForm({ ...detailEditForm, settlementPartyCode: option.value, payType: [] })
            setAccountTypeDs([])
            setCash(false)
        }
    }

    //监听批量录入字段
    const onChange = (changedValues) => {}

    const onCancel = () => {
        setSettleVisible(false)
        loadData && loadData()
    }

    const [okLoading, setOkLoading] = useState(false)
    //加盟商结算
    const franchiseesSettle = () => {
        formRef.current
        .validateFields()
        .then(async (res) => {
            if (res.payType === DictCodeEnmu.SETTLE_TYPE_BALANCE) {
                res.payType = 'settle-type.credit'
            }
            let data = {
                orderId: typeForm[mode].id,
                settleInfo: {
                    settlementPartyCode: res.settlementPartyCode,
                    payType: res.payType == 'settle-type.tco' ? 'settle-type.credit' : res.payType,
                    remark: res.note,
                },
            }
            try {
                // onSettle(data, typeForm[mode]).then((res) => {
                //     console.log(111)
                //     console.log(res)
                //     let retData = await onSettle(data)
                //     if (res && res.retCode === 200) {
                //         message.success('结算成功')
                //     } else {
                //         message.error('结算失败' + res.retData)
                //     }
                // })
                // onCancel()
                setOkLoading(true)
                let retData = await onSettle(data, typeForm[mode])
                message.success('结算成功')
                onCancel()
                setTimeout(() => {
                    setOkLoading(false)
                }, 1000);
            } catch (error) {
                console.log(getFormValidatorErrText(error))
                onCancel()
                setTimeout(() => {
                    setOkLoading(false)
                }, 1000);
            }
        })
        .catch((err) => {
            console.log(getFormValidatorErrText(err))
        })
    }

    

    //表单提交
    const onSubmit = async () => {
        if(IsFranchisees() &&  typeForm[mode].orderType !== '014006'){
            //加盟商结算
            return franchiseesSettle()
        }
        //验证额度 通过更改的表格保存的方法
        handleSave(payDataSource[0])

        let filterPayData = payDataSource.filter((item) => item.payMethodCode)
        if (filterPayData.length !== payDataSource.length) {
            message.warning('请完善收款信息')
            return
        }
        let totalAmount = filterPayData.reduce((con, next) => {
            con += +(next.amount ?? 0)
            return con
        }, 0)
        if (totalAmount !== +typeForm[mode].receivableAmount) {
            message.warning('您结算的金额与应收金额有差异')
            return
        }
        if (settleType === DictCodeEnmu.SETTLE_TYPE_CASH && accountTypeDs.length === 0) {
            message.warning('您的现金账户没有开户')
            return
        }
        if (filterPayData.filter((item) => item.payMethodValue === DictCodeEnmu.ACC_TYPE_CASH && !!!item.cashCode).length !== 0) {
            message.warning('请补全你的现金类支付方式')
            return false
        }
        // return console.log(accountTypeDs)
        formRef.current
            .validateFields()
            .then(async (res) => {
                let newAccountCode = ''
                let accountType = ''
                let accountName = ''

                if (res.payType === DictCodeEnmu.SETTLE_TYPE_CREDIT) {
                    newAccountCode = filterPayData[0]?.['payMethodCode'] ?? ''
                    accountType = filterPayData[0]?.['payMethodValue'] ?? ''
                    accountName = filterPayData[0]?.['payMethodName'] ?? ''
                } else {
                    let { value, accountTypeCode, label } = accountTypeDs.find((item) => filterPayData[0]?.payMethodValue === item.accountTypeCode)
                    newAccountCode = value
                    accountType = accountTypeCode
                    accountName = label
                }
                if (!newAccountCode && res.payType === DictCodeEnmu.SETTLE_TYPE_CASH) {
                    message.warning(`您的现金${filterPayData[0]?.payMethodName}没有开户`)
                    return false
                }
                /**
                 * 判断结算类型类现结，现金账户的可用额度是否满足支付
                 */
                if (
                    res.payType === DictCodeEnmu.SETTLE_TYPE_CASH &&
                    accountType !== DictCodeEnmu.ACC_TYPE_CASH &&
                    payDataSource.reduce((pre, cur) => pre + (cur.available ? cur.available : 0), 0) < totalAmount
                ) {
                     // 开户额度修改 
                    message.warning("您的现结账户可用额度不够");
                    let rechargeData = accountTypeDs.find((i) => i.value === payDataSource[0].payMethodCode)
                    setAddRechargeData({
                        ...rechargeData,
                        rechargeAmount: bignumber.minus(payDataSource[0].amount, payDataSource[0].available),
                        partnerCode: queryAccountCustomer.code,
                        partnerName: queryAccountCustomer.name,
                    })
                    return
                    // 开户额度修改 
                    const { code, partnerCode, shopCode } = rechargeData
                    let data1 = {
                        accountCode: code,
                        statusList: 0,
                        partnerCode,
                        shopCode,
                        page: {
                            pageSize: 10,
                            pageNum: 1,
                        },
                        sourceApp: 'fms',
                    }
                    let fmsrechargeList = await commonService.fmsRechargeList(data1)
                    
                    let waringmsg = '您的现结账户可用额度不够, 是否调额?'
                    if (fmsrechargeList && fmsrechargeList.retData && fmsrechargeList.retData.records && fmsrechargeList.retData.records.length > 0) {
                        //有调额申请记录未审核
                        waringmsg = '您的现结账户可用额度不够, 有充值申请未审核，是否再次调额?'
                    }
                    Modal.confirm({
                        title: '提示',
                        content: waringmsg,
                        okText: '确定',
                        cancelText: '取消',
                        onOk: () => {
                            setAddRechargeVisible(true)
                        },
                        onCancel: () => {
                            // setSettleVisible(false)
                        },
                    })
                    return
                }

                if (
                    res.payType !== DictCodeEnmu.SETTLE_TYPE_CASH &&
                    payDataSource.reduce((pre, cur) => pre + (cur.available ? cur.available : 0), 0) < totalAmount
                ) {
                    /* 开户额度修改 */
                    // message.warning("您的账户可用额度不够");
                    let adjustData = accountTypeDs.find((i) => i.value === payDataSource[0].payMethodCode)
                    setAddAdjustData({
                        ...adjustData,
                        adjustAmount: bignumber.minus(payDataSource[0].amount, payDataSource[0].available),
                        partnerCode: queryAccountCustomer.code,
                        partnerName: queryAccountCustomer.name,
                    })
                     {/* 开户额度修改 */}
                     return
                    const { code, partnerCode, shopCode } = adjustData
                    let data1 = {
                        accountCode: code,
                        status: 1,
                        statusList: 1,
                        partnerCode,
                        shopCode,
                        page: {
                            pageSize: 10,
                            pageNum: 1,
                        },
                        sourceApp: 'fms',
                    }
                    let fmsAdjustList = await commonService.fmsAdjustList(data1)
                    let waringmsg = '您的账户可用额度不够, 是否调额?'
                    if (fmsAdjustList && fmsAdjustList.retData && fmsAdjustList.retData.records && fmsAdjustList.retData.records.length > 0) {
                        //有调额申请记录未审核
                        waringmsg = '您的账户可用额度不够, 有调额申请未审核，是否再次调额?'
                    }
                    Modal.confirm({
                        title: '提示',
                        content: waringmsg,
                        okText: '确定',
                        cancelText: '取消',
                        onOk: () => {
                            setAddAdjustVisible(true)
                        },
                        onCancel: () => {
                            // setSettleVisible(false)
                        },
                    })
                    return
                }

                if (res.payType === DictCodeEnmu.SETTLE_TYPE_BALANCE) {
                    res.payType = 'settle-type.credit'
                }
                let data = {
                    orderId: typeForm[mode].id,
                    settleInfo: {
                        beCode: typeForm[mode].bentityCode,
                        beName: typeForm[mode].bentityName,
                        partnerCode: res.customerCode,
                        partnerName: res.customerName,
                        settlementPartyName: res.settlementPartyName,
                        settlementPartyCode: res.settlementPartyCode,
                        tradeCode: typeForm[mode].orderCode,
                        tradeOperator: userName,
                        tradeTypeCode: 'trade-type.maintenance',
                        partnerTypeCode: 'user-type.customer',
                        accountCode: newAccountCode,
                        accountName: accountName,
                        shopCode: shopCode,
                        shopName: shopName,
                        billAmount: res.receivableAmount,
                        payType: res.payType == 'settle-type.tco' ? 'settle-type.credit' : res.payType,
                        note: res.note,
                        payee: filterPayData[0].payee,
                        billCashList:
                            filterPayData[0].payMethodValue !== 'account-type.cash' ||
                            settleType == DictCodeEnmu.SETTLE_TYPE_CREDIT ||
                            settleType == 'settle-type.balance'
                                ? []
                                : filterPayData.map((item) => {
                                      let { key, available, payMethodValue, cashCode, cashName, ...params } = item
                                      let findCash = getSelectedDictOne(DictCodeEnmu.PAY_METHOD_ACCT).find((item) => item?.type === payMethodValue)
                                      return {
                                          ...params,
                                          accountCode: newAccountCode,
                                          accountName: accountName,
                                          payMethodCode: cashCode ?? findCash?.value,
                                          payMethodName: cashName ?? findCash?.label,
                                          payAmount: res.receivableAmount,
                                          payee: filterPayData[0].payee,
                                          note: res.note,
                                      }
                                  }),
                    },
                }
                try {
                    // onSettle(data, typeForm[mode]).then((res) => {
                    //     if (res && res.retCode === 200) {
                    //         message.success('结算成功')
                    //     } else {
                    //         message.error('结算失败' + res.retData)
                    //     }
                    // })
                    // onCancel()
                    setOkLoading(true)
                    let retData = await onSettle(data, typeForm[mode])
                    message.success('结算成功')
                    setTimeout(() => {
                        setOkLoading(false)
                    }, 1000);
                    onCancel()
                } catch (error) {
                    setTimeout(() => {
                        setOkLoading(false)
                    }, 1000);
                    console.log(getFormValidatorErrText(error))
                    onCancel()
                }
            })
            .catch((err) => {
                console.log(getFormValidatorErrText(err))
            })
    }

    const onFinish = (val) => {}
    const onCashAttachChange = (info, record) => {
        if (info.file.status === 'done' && info.file?.response?.retCode === 0) {
            let dataSource = [...payDataSource]
            const index = dataSource.findIndex((item) => record.key === item.key)
            const item = dataSource[index]
            dataSource.splice(index, 1, { ...item, ...{ ...record, cashAttach: info?.file?.response?.retData, cashAttachFileName: info.file.name } })
            setPayDataSource(dataSource)
        } else if (info.file.status === 'error' && info.file.response && info?.file?.response?.retData) {
            message.error('导入异常，请尝试重新导入')
        } else if (info.file.status === 'error') {
            message.error('导入错误')
        }
    }

    const onCashAttachDelete = (record) => {
        let dataSource = [...payDataSource]
        const index = dataSource.findIndex((item) => record.key === item.key)
        const item = dataSource[index]
        dataSource.splice(index, 1, { ...item, ...{ ...record, cashAttach: '', cashAttachFileName: '' } })
        setPayDataSource(dataSource)
    }

    const onPreview = (record) => {
        const cashAttach = record.cashAttach
        const cashAttachFileName = record.cashAttachFileName
        const type = cashAttach.split('.')[1]
        if (['jpg', 'png', 'jpeg', 'gif'].includes(type)) {
            Modal.info({
                title: '图片查看',
                // eslint-disable-next-line react/jsx-no-undef
                content: <img style={{ width: '100%' }} src={getBaseImgLookUrl(cashAttach)} />,
                okText: '确定',
                cancelText: '取消',
                centered: true,
                width: 800,
            })
        } else {
            // window.open(getBaseImgLookUrl(uid))
            window.open(getBaseImgLookUrl(cashAttach))
        }
    }
    const [payTypeList, setPayTypeList] = useState([])
    useEffect(() => {
        if (shopCode == 'SHOP-M-00000016' || shopCode == 'SHOP-M-00000017' || shopCode == 'SHOP-M-00000018') {
            setPayTypeList(getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE))
        } else {
            setPayTypeList(
                getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE).map((item) => {
                    return { ...item, disabled: item.value == 'settle-type.credit' ? true : false }
                })
            )
        }
    }, [])
    const formConfig = {
        formRef,
        onFinish,
        labelCol: { span: 6 },
        disabled: mode === 'look',
        onChange,
        items: [
            {
                type: 'Input',
                fieldLabel: '工单单号',
                fieldName: 'maintenanceCode',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '制单日期',
                fieldName: 'createTime',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '客户编码',
                fieldName: 'customerCode',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '客户名称',
                fieldName: 'customerName',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '销售实体',
                fieldName: 'bentityName',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '所属门店',
                fieldName: 'shopName',
                span: 12,
                disable: true,
            },
            {
                type: 'Select',
                allowClear: true,
                showSearch: true,
                fieldLabel: '结算方',
                span: 12,
                fieldName: 'settlementPartyCode',
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_PARTY),
                onSelectChange: settlementPartyChange,
                rules: [{ required: true, message: '请选择结算方' }],
                hidden: mode === 'look',
            },
            {
                type: 'Input',
                fieldLabel: '结算方',
                fieldName: 'settlementPartyName',
                span: 12,
                hidden: mode !== 'look',
            },
            {
                type: 'Input',
                fieldLabel: '应收金额',
                fieldName: 'receivableTotalAmount',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '已收金额',
                fieldName: 'receivableAmount',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '优惠金额',
                fieldName: 'discountTotalAmount',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '结算日期',
                fieldName: 'settleTime',
                span: 12,
                disable: true,
            },
            {
                type: 'Select',
                fieldLabel: '结算类型',
                fieldName: 'payType',
                onSelectChange: settleTypeChange,
                rules: [{ required: true, message: '该项为必选项' }],
                // options: payTypeChange?getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE):getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE).filter(item=>!(item.label=='大包')),
                options: payTypeChange ? payTypeList : payTypeList.filter((item) => !(item.label == '大包')),
                //disable:payTypeChange,
                span: 12,
                // disable: true
            },
            {
                type: 'Input',
                fieldLabel: '结算备注',
                fieldName: 'note',
                span: 12,
                // disable: true
            },
        ],
    }
    const columns = [
        {
            title: '支付方式',
            dataIndex: 'payMethodCode',
            editable: true,
            editType: 'select',
            options: accountTypeDs,
            width: 260,
            render: (text) => accountTypeDs.find((item) => item.value === text)?.label,
        },
        // cash ? {
        //     title: '现金类支付方式',
        //     dataIndex: 'cashCode',
        //     editable: true,
        //     editType: "select",
        //     options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD_CASHS),
        //     width: 120,
        //     render: (text) => getTreeNodeName(text)
        // } : {
        //     title: '可用额度',
        //     dataIndex: 'available',
        //     width: 100,
        //     render: (text) => <span className="statu-red">{`￥${text}`}</span>
        // },
        ...((str) => {
            if (str) {
                return [
                    {
                        title: '现金类支付方式',
                        dataIndex: 'cashCode',
                        editable: true,
                        editType: 'select',
                        options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD_CASHS),
                        width: 120,
                        render: (text) => getTreeNodeName(text),
                    },
                    {
                        title: '附件',
                        dataIndex: 'cashAttach',
                        width: 120,
                        render: (text, record) => {
                            if (text) {
                                return (
                                    <div className="flex justify-around" style={{ position: 'relative', width: '32px', height: ' 32px' }}>
                                        <FontIcon
                                            onClick={() => {
                                                onPreview(record)
                                            }}
                                            style={{ fontSize: '32px' }}
                                            type="icon-tupian"
                                        />
                                        <CloseCircleOutlined
                                            onClick={() => {
                                                onCashAttachDelete(record)
                                            }}
                                            style={{ fontSize: '12px', color: 'red', position: 'absolute', top: '-6px', right: '-6px' }}
                                        />
                                    </div>
                                )
                            } else {
                                return (
                                    <Upload
                                        // action={`${fileUploadUrl}upload/image_old`}
                                        action={getBaseImgUploadUrl()}
                                        data={getBaseUploadData()}
                                        headers={{ authorization: getToken() }}
                                        showUploadList={false}
                                        beforeUpload={(file ) => {
                                            const isLt20M = file.size / 1024 / 1024 < 20
                                            if (!isLt20M) {
                                                message.error('上传到文件不能超过20M')
                                    file.status='done'
                                            }
                                            return isLt20M
                                        }}
                                        onChange={(info) => {
                                            onCashAttachChange(info, record)
                                        }}
                                    >
                                        <CloudUploadOutlined style={{ fontSize: '24px', cursor: 'pointer', color: '#6C72F3' }} />
                                    </Upload>
                                )
                            }
                        },
                    },
                ]
            } else {
                return [
                    {
                        title: '可用额度',
                        dataIndex: 'available',
                        width: 100,
                        render: (text) => <span className="statu-red">{`￥${text}`}</span>,
                    },
                ]
            }
        })(cash),
        {
            title: '结算金额',
            width: 120,
            dataIndex: 'amount',
            editable: mode === 'look' ? false : true,
            render: (text) => `￥${text}`,
        },
        {
            title: '收款人',
            editable: mode === 'look' ? false : true,
            dataIndex: 'payee',
            editType: 'select',
            options: getSelectedDictOne(DictCodeEnmu.PAYEE),
            width: 150,
            render: (text) => getTreeNodeName(text),
        },
    ]
    const lookColumns = [
        {
            title: '支付方式',
            dataIndex: 'accountName',
            width: 260,
        },
        detailLookForm.payType === DictCodeEnmu.SETTLE_TYPE_CASH
            ? {
                  title: '现金类支付方式',
                  dataIndex: 'payMethodName',
                  width: 120,
              }
            : { width: 0 },
        {
            title: '结算金额',
            width: 120,
            dataIndex: 'amount',
        },
        {
            title: '收款人',
            dataIndex: 'payee',
            width: 150,
            render: (text) => getTreeNodeName(text),
        },
    ]

    const newPayColumns = columns.map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                editable: col?.editable,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑
    const handleSave = (row) => {
        row.amount = +repairFormatNum(row.amount)

        if (settleType === DictCodeEnmu.SETTLE_TYPE_CREDIT || settleType === DictCodeEnmu.SETTLE_TYPE_CASH) {
            if (row.payMethodCode) {
                let find = accountTypeDs.find((item) => item.value === row.payMethodCode)
                row.payMethodName = find?.label ?? ''
                row.payMethodValue = find?.accountTypeCode ?? ''
                row.payMethodCode = find?.value ?? ''
                row.available = +(find?.available ?? 0)
            }
        }

        if (row.payMethodValue === DictCodeEnmu.ACC_TYPE_CASH) {
            setCash(true)
            row.cashName = getTreeNodeName(row.cashCode) ?? ''
        } else {
            setCash(false)
            row.cashName = null
            row.cashCode = null
            if (+row.amount > +(row.available ?? 0)) {
                row.amount = 0
                message.warning('结算金额≤可用额度')
            }
        }
        let dataSource = [...payDataSource]
        const index = dataSource.findIndex((item) => row.key === item.key)
        const item = dataSource[index]
        dataSource.splice(index, 1, { ...item, ...row })
        setPayDataSource(dataSource)
    }

    //开户调额
    const [addAccountVisible, setAddAccountVisible] = useState(false)
    const [addAdjustVisible, setAddAdjustVisible] = useState(false)
    const [addAdjustData, setAddAdjustData] = useState(null)
    const [addRechargeVisible, setAddRechargeVisible] = useState(false)
    const [addRechargeData, setAddRechargeData] = useState(null)
    const [queryAccountCustomer, setQueryAccountCustomer] = useState(null)

    const onAddAccountSucess = () => {
        const val = formRef.current.getFieldsValue().payType
        let option
        payTypeList.forEach((item) => {
            if (item.value === val) {
                option = item
            }
        })
        settleTypeChange(val, option)
    }

    const addAdjustSucess = () => {
        setSettleVisible(false)
    }

    const addRechargeSucess = () => {
        setSettleVisible(false)
    }

    //手动调额
    const onAddAdjust = () => {
        if (!queryAccountCustomer || !queryAccountCustomer.code || !queryAccountCustomer.name) {
            return message.error('请先选择结算方和结算方式！')
        }
        if (settleType !== DictCodeEnmu.SETTLE_TYPE_CASH) {
            let adjustData = accountTypeDs.find((i) => i.value === payDataSource[0].payMethodCode)
            setAddAdjustData({
                ...adjustData,
                adjustAmount: bignumber.minus(payDataSource[0].amount, payDataSource[0].available),
                partnerCode: queryAccountCustomer.code,
                partnerName: queryAccountCustomer.name,
            })
            setAddAdjustVisible(true)
        } else {
            message.error('现结账户不能调额')
        }
    }
    return (
        <YHDrawer
            {...{
                title: '工单结算',
                visible,
                onClose: onCancel,
                okText: '保存',
                onOk: onSubmit,
                width: 900,
                showFooter: detailEditForm.status === 'maintenance.settle' || mode === 'look' ? false : true,
                okLoading
            }}
        >
            <br />
            <YhForm {...formConfig} />
            <br />
            {(!IsFranchisees() ||  typeForm[mode].orderType === "014006") && (
                <><div className="">
                    {/* 开户额度修改 */}
                    {/* <Button
                        style={{ margin: ' 0 0 20px 20px' }}
                        onClick={() => {
                            if (!queryAccountCustomer || !queryAccountCustomer.code || !queryAccountCustomer.name) {
                                return message.error('请先选择结算方和结算方式！')
                            }
                            setAddAccountVisible(true)
                        }}
                    >
                        开户
                    </Button>
                    <Button style={{ margin: ' 0 0 20px 20px' }} onClick={onAddAdjust}>
                        调额
                    </Button> */}
                </div>
                <Table
                rowClassName="editable-row yh-edit-table-tr"
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={mode === 'edit' ? newPayColumns : lookColumns}
                dataSource={payDataSource}
                pagination={false}
                scroll={{ x: 'max-content', y: 400 }}
            />
            <div className="table-bottom-total" onClick={() => {}}>
                合计:
                {
                    <span className="ml-15 statu-red">{`￥${repairFormatNum(
                        payDataSource.reduce((con, next) => {
                            con = +(next.amount ?? 0)
                            return con
                        }, 0)
                    )}`}</span>
                }
            </div></>
            )}

            

            {addAccountVisible && (
                <YhFmsAddAccount
                    visible={addAccountVisible}
                    setVisible={setAddAccountVisible}
                    customerList={[queryAccountCustomer]}
                    beCode={typeForm[mode].bentityCode}
                    beName={typeForm[mode].bentityName}
                    formRef={formRef}
                    onSucess={onAddAccountSucess}
                />
            )}

            {addAdjustVisible && (
                <YhFmsAddAdjust visible={addAdjustVisible} setVisible={setAddAdjustVisible} addAdjustData={addAdjustData} onSucess={addAdjustSucess} />
            )}

            {addRechargeVisible && (
                <YhFmsAddRecharge
                    visible={addRechargeVisible}
                    setVisible={setAddRechargeVisible}
                    addRechargeData={addRechargeData}
                    onSucess={addRechargeSucess}
                />
            )}
        </YHDrawer>
    )
}
