import { useState, useEffect } from "react";
import { createModel } from "hox";
import { jxcService } from "./jxcService";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";


export const useJxcModel = createModel(function () {
    const initStatistics = {
        inventoryNum: 0,
        lastestPriceAmount: 0,
        putStorageNum: 0,
        putStorageAmount: 0,
        outStorageNum: 0,
        outStorageAmount: 0,
    }
    const { whDs, user: { shopId } } = useGlobalModel();

    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    //表格选中行row
    const [selectedRow, setSelectedRow] = useState([]);
    const [loading, setLoading] = useState({ spinning: false, tip: "加载中..." });

    const initPgCfg = { pageSize: 15, current: 1 }
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    //进销存报表 和 商品明细账 (默认进销存)
    const [jxcOrGoodsDetail, setJxcOrGoodsDetail] = useState("jxcRecord");
    const [descTotal, setDescTotal] = useState(initStatistics);
    const [rowsDescTotal, setRowDescTotal] = useState(initStatistics);

    //商品明细
    const [purchasePinData, setPurchasePinData] = useState([]);
    const [pinPgCfg, setPinPgCfg] = useState(initPgCfg);

    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    useEffect(() => {
        if (selectedRow.length) {
            let newRowsDescTotal = { ...initStatistics };
            selectedRow.forEach((item, index) => {
                let { inventory, inventoryAmount, putStorageNum, putStorageAmount, outStorageNum, outStorageAmount } = item;
                newRowsDescTotal.inventoryNum += +(inventory ?? 0)
                newRowsDescTotal.lastestPriceAmount += +(inventoryAmount ?? 0)
                newRowsDescTotal.putStorageNum += +(putStorageNum ?? 0)
                newRowsDescTotal.putStorageAmount += +(putStorageAmount ?? 0)
                newRowsDescTotal.outStorageNum += +(outStorageNum ?? 0)
                newRowsDescTotal.outStorageAmount += +(outStorageAmount ?? 0)
            })
            setRowDescTotal(newRowsDescTotal)
        }
    }, [selectedRow])

    //重置
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRow([]);
        loadData(initPgCfg, {});
        setJxcOrGoodsDetail("jxcRecord");
    }

    const loadData = async (page, searchValue = searchParam) => {
        setLoading({ spinning: true, tip: "列表加载中..." });
        const searchParams = {
            shopId,
            ...searchValue,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? initPgCfg.current,
        }
        let { retData } = await jxcService.page(searchParams);

        const { records, ...pg } = retData;
        setDataSource(records.map((item, index) => ({
            ...item,
            key: index
        })));
        setPgCfg(transPgToPagination(pg));
        setLoading({ spinning: false });
        let { retData: Data } = await jxcService.statistics({
            shopId,
            ...searchParams,
            pageSize: -1,
            warehouseCode: searchParam.warehouseCode ?? whDs.map(item => item.value).toString()
        });
        setDescTotal(Data ?? {});
    }

    const loadMaterialData = async (page, searchValue = searchParam) => {
        let { materialCode, materialName } = selectedRow[0] ?? {}
        const searchParams = {
            shopId,
            materialCode,
        }
        let { retData } = await jxcService.purchasePin(searchParams);
        setPurchasePinData(retData);
    }
    const exportJxcPin = async (searchValue = searchParam) => {
        setLoading({ spinning: true, tip: "快速导出中..." });
        try {
            const searchParams = {
                shopId,
                materialCode: selectedRow[0]?.materialCode,
            }
            let result = await jxcService.purchasePinExport(searchParams);
            result && setLoading({ spinning: false });;
            convertRes2Blob(result);
        } catch { setLoading({ spinning: false }); }
    }

    //导出
    const exportJxc = async (searchValue = searchParam) => {
        setLoading({ spinning: true, tip: "快速导出中..." });
        try {
            const searchParams = {
                shopId,
                ...searchValue,
                pageSize: -1,
                pageNum: 1,
            }
            let result = await jxcService.export(searchParams);
            result && setLoading({ spinning: false });
            convertRes2Blob(result);
        } catch { setLoading({ spinning: false }); }
    }

    return {
        /***field***/
        loading,
        searchParam,
        dataSource,
        pgCfg, rowsDescTotal,
        jxcOrGoodsDetail, setJxcOrGoodsDetail,
        /***method***/
        setDataSource,
        resetSearchParam,
        updateSearchParam,
        loadData, exportJxc,
        descTotal, setDescTotal,
        purchasePinData, setPurchasePinData,
        pinPgCfg, setPinPgCfg,
        loadMaterialData,
        selectedRow, setSelectedRow,
        exportJxcPin
    }
});


