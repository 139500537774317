import React, { useState, useEffect, useImperativeHandle, useContext } from 'react'
import { Table, message, Upload } from 'antd'
import YhIcon from '@/components/pubIcon'
import ItemDrawer from '@/components/pubPartDrawer/itemDrawer' //项目弹框
import { EditableRow, EditableCell } from './pubEditble'
import { getMaintanMaterialList, maintainOrderMaterialList } from '@/service/ordersService'
import { getPcodeDict } from '@/utils/dataStorage'
import zeng from '@/assets/images/zeng.png'
import { download, convertRes2Blob } from '@/utils/exportExcel'
import Loading from '@/components/Loading'
// import MaterialHistory from "./materialHistory"
import { MaterialHistory } from '@/erp_subpackage/pages/common/materialHistory/index'
import { getUserOrgan } from '@/utils/dataStorage'
import { orderContext } from './context'
import RepairPickingListDetail from './repairPickingList/detail'
import useGlobalModel from '@/erp_subpackage/model/globalModel'
import YhTooltip from '@/components/YhTooltip'
import {useOrderModel} from "./orderModal"
//物料
export default ({ orderId, mode, materialRef, onReceivables, parentOrderType, teamId, dataType, sanbaoData, dabaoData, orderStatus }) => {
    const {apiCount} = useOrderModel()
    const [visible, setVisible] = useState(false) //弹框
    const [data, setData] = useState([]) //表格数据
    const [totalPrice, setTotalPrice] = useState(0) //维修物料的总价
    const [dictObj, setDictObj] = useState({
        serviceNature: {},
        threeGuaranteesUnit: {},
    })
    const [orderSelectThreeGuaranteesUnit, setOrderSelectThreeGuaranteesUnit] = useState('014003') //记录整个订单的工单类型，看是否是三包， 收费，默认是  三包/收费
    const {
        user: { shopId },
    } = useGlobalModel()

    const [loading, setLoading] = useState(false)

    const [historyData, setHistoryData] = useState({ visible: false, materialCode: '', materialName: '', shopId: '' })

    const [isOperation, setIsOperation] = useState(true)

    const { mode: orderMode } = useContext(orderContext)

    const [maintainOrderId, setMaintainOrderId] = useState('')
    const [maintainOrderDetailVisible, setMaintainOrderDetailVisible] = useState(false)

    //点开弹框初始下页面需要的字典数据
    const initDict = () => {
        let serviceNature = {}
        let threeGuaranteesUnit = {}
        //服务性质
        getPcodeDict('016').children.forEach((item) => {
            serviceNature[item.dictCode] = item.title
        })
        //三包单位
        getPcodeDict('106').children.forEach((item) => {
            threeGuaranteesUnit[item.dictCode] = item.title
        })
        setDictObj({
            serviceNature: serviceNature,
            threeGuaranteesUnit: threeGuaranteesUnit,
        })
    }

    useEffect(() => {
        initDict()
        parentOrderType && setOrderSelectThreeGuaranteesUnit(parentOrderType)
        if (mode !== 'insert') {
            echoData()
        }
    }, [])

    //计算总价
    useEffect(() => {
        let num = 0
        data.forEach((item) => {
            num += Number(item.actualMoney)
        })
        onReceivables({ itemAccount: null, material: num })
        setTotalPrice(num.toFixed(2))
    }, [data])

    //暴露给父组件的方法
    useImperativeHandle(materialRef, () => ({
        //upData 就是暴露给父组件刷新保存后的的方法
        upData: (parentOrderId) => {
            echoData(parentOrderId)
        },
        //父组件保存的时候 获取所有编辑完后的数据
        upMaterialData: () => {
            return data
        },
        orderTypeChange: async (orderType, isThree) => {
            await setOrderSelectThreeGuaranteesUnit(orderType)
            //是三包    项目和配件的工时 单价 工时费 优惠 折扣 总金额 都置0，  并且服务性质全部都是三包并且不可编辑。  三包单位默认选择的是第一个必选。
            // if (orderType === "014002") {
            //     let newData = data.map((item) => {
            //         return {
            //             ...item,
            //             unitPrice: item.price,
            //             materialDiscount: "",
            //             actualMoney: 0,
            //             totalCount: 0,
            //             serviceNature: "016001",
            //             threeGuaranteesUnit: "10601",
            //         }
            //     })
            //     await setData(newData)
            //     //是收费  项目和配件的服务性质是收费不可编辑，三包不可编辑，  在上次是三包的时候：数量和单价带回原来的单价。
            // } else if (isThree) {
            //     resetPrice()
            // }
            if (dataType !== 'sanbao' && orderType === '014002') {
                await setData([])
            }
        },
        setPartListForHistory,
        setPartListForTemplate,
    }))
    const setPartListForHistory = (setPartList, status) => {
        message.error('开发中')
        //     let newArr = []
        //     setPartList.forEach((item) => {
        //         let unselect = item
        //         if (status === "import") {
        //             //导入的要计算总价
        //             newArr.push({
        //                 ...unselect,
        //temporaryId: new Date().getTime() + parseInt(Math.random() * 100000),
        //                 totalCount: unselect.partNumber * unselect.unitPrice, //总计
        //                 discount: 0, //折扣
        //                 actualMoney: unselect.partNumber * unselect.unitPrice, //总金额
        //             })
        //         } else {
        //             //维修历史添加的
        //             if (unselect.partDeleteStatus) {
        //                 message.error(`${unselect.materialName}已被删除,不能添加！`)
        //             } else {
        //                 newArr.push({
        //                     ...unselect,
        //temporaryId: new Date().getTime() + parseInt(Math.random() * 100000),
        //                     totalCount: unselect.partAmount, //总计
        //                     discount: unselect.partDiscount, //折扣
        //                     actualMoney: unselect.actualAmount, //总金额
        //                 })
        //             }
        //         }
        //     })
        //     setData([...data, ...newArr])
    }

    const setPartListForTemplate = (setPartList) => {
        message.error('开发中')
        //     let newArr = []
        //     setPartList.forEach((item) => {
        //         let unselect = item
        //             //维修历史添加的
        //             if (unselect.partDeleteStatus) {
        //                 message.error(`${unselect.materialName}已被删除,不能添加！`)
        //             } else {
        //                 newArr.push({
        //                     ...unselect,
        //                     temporaryId: new Date().getTime() + parseInt(Math.random() * 100000),
        //                     totalCount: unselect.partAmount, //总计
        //                     unitPrice: unselect.partRetailPrice,
        //                     discount: 0, //折扣
        //                     actualMoney: unselect.partAmount, //总金额
        //                     preferentialAmount: 0, //优惠
        //                 })
        //             }
        //     })
        //     setData([...data, ...newArr])
    }

    //重置单价
    const resetPrice = async () => {
        let originData = [...data]
        //表格里面所有物料的id
        let partIdAll = originData.reduce((c, n) => {
            c.push(n.maintainOrderMaterialId)
            return c
        }, [])
        if (partIdAll.length < 1) {
            return
        }
        const snedData = {
            pageSize: 10000,
            pageNum: 1,
            searchItem: {
                ids: partIdAll,
            },
        }
        await maintainOrderMaterialList(snedData).then((res) => {
            let result = res.retData.list
            result.forEach((item) => {
                //拿到的最新每项物料
                let newPartItem = item
                originData.forEach((originItem) => {
                    if (newPartItem.id === originItem.maintainOrderMaterialId) {
                        originItem.unitPrice = newPartItem.lateSalePrice || newPartItem.retailPrice
                        originItem.totalCount = newPartItem.lateSalePrice || newPartItem.retailPrice * originItem.materialNumber
                        originItem.actualMoney = newPartItem.lateSalePrice || newPartItem.retailPrice * originItem.materialNumber //总金额
                        originItem.serviceNature = '016002' //每次重置 服务性质都改为收费。
                        originItem.threeGuaranteesUnit = '' //每次重置单价必定不是三包， 给置空。
                        originItem.claimNumber = ''
                    }
                })
            })
        })
        await setData([])
        await setData(originData)
    }
    //物料回显
    const echoData = async (parentOrderId = '') => {
        console.log('part')
        if (dataType === 'sanbao') {
            setData(
                sanbaoData.map((item) => {
                    return {
                        ...item,
                        totalCount: item.materialAmount, //总计
                        materialDiscount: item.materialDiscount, //折扣
                        actualMoney: item.actualAmount, //总金额
                        remainingNum: item.remainingNum + item.materialNumber,
                        availableUseNum: item.remainingNum + item.materialNumber,
                    }
                })
            )
        } else if (dataType === 'dabao') {
            setData(
                dabaoData.map((item) => {
                    return {
                        ...item,
                        totalCount: item.materialAmount, //总计
                        materialDiscount: item.materialDiscount, //折扣
                        actualMoney: item.actualAmount, //总金额
                        remainingNum: item.remainingNum + item.materialNumber,
                        availableUseNum: item.remainingNum + item.materialNumber,
                    }
                })
            )
        } else {
            apiCount.current =  apiCount.current + 1
            let data = {
                pageSize: 1000,
                searchItem: { orderId: parentOrderId ? parentOrderId : orderId, serviceNature: ['016002', '016003', '016004'] },
            }
            await getMaintanMaterialList(data).then((res) => {
                let result = res.retData.list
                let editArr = result.map((item) => {
                    return {
                        ...item,
                        // id: item.maintainOrderMaterialId,
                        // maintainOrderMaterialId: item.id,
                        totalCount: item.materialAmount, //总计
                        materialDiscount: item.materialDiscount, //折扣
                        actualMoney: item.actualAmount, //总金额
                        remainingNum: item.remainingNum + item.materialNumber,
                        availableUseNum: item.remainingNum + item.materialNumber,
                    }
                })
                setData(editArr)
            }).finally(() => {
                apiCount.current =  apiCount.current - 1
            })
        }
    }

    //新增确定
    const setPartList = (selectPart) => {
        if (selectPart.length) {
            let newArr = []
            selectPart.forEach((item) => {
                let unselect = item
                let isSelect = data.find((item) => item.maintainOrderMaterialId === unselect.id)
                if (isSelect === undefined) {
                    let itemData = {
                        temporaryId: new Date().getTime() + parseInt(Math.random() * 100000),
                        maintainOrderId: unselect.maintainOrderId,
                        maintainOrderMaterialId: unselect.id,
                        materialCode: unselect.materialCode,
                        materialName: unselect.materialName,
                        serviceNature: orderSelectThreeGuaranteesUnit === '014002' ? '016001' : '016002', //服务性质 默认收费,订单时三包的话， 新增的只能是三包。
                        threeGuaranteesUnit: orderSelectThreeGuaranteesUnit === '014002' ? '10601' : '', //三包
                        claimNumber: '',
                        unitName: unselect.unitName, //单位
                        // unitPrice: orderSelectThreeGuaranteesUnit === "014002" ? 0 : unselect.lateSalePrice, //单价
                        unitPrice: unselect.lateSalePrice || unselect.retailPrice, //单价
                        // materialNumber: orderSelectThreeGuaranteesUnit === "014002" ? 0 : 1, //数量
                        materialNumber: 1, //数量
                        remainingNum: unselect.remainingNum,
                        availableUseNum: unselect.remainingNum,
                        totalCount: unselect.lateSalePrice || unselect.retailPrice * 1, //总计
                        preferentialAmount: 0, //优惠
                        materialDiscount: '', //折扣
                        // partClassificationCode: unselect.partClassificationCode, //分类代码
                        actualMoney: orderSelectThreeGuaranteesUnit === '014002' ? 0 : unselect.lateSalePrice || unselect.retailPrice * 1, //总金额
                        remark: unselect.remark, //备注
                        giftFlag: 'not_gift',
                        price: unselect.price,
                        maintainOrderCode: unselect.maintainOrderCode,
                    }
                    if (itemData.serviceNature === '016001') {
                        itemData.unitPrice = unselect.price
                        itemData.totalCount = unselect.price * 1
                    }
                    newArr.push(itemData)
                }
            })
            setData([...data, ...newArr])
        }
        setVisible(false)
    }
    /**
     * 以下是页面展示
     */
    const [MaterialKeys, setMaterialKeys] = useState([]) // 批量删除的key
    const [deleteAll, setDeleteAll] = useState(false) //删除多选的控制按钮
    //批量删除多选的成员id
    const MaterialSelection = {
        columnWidth: '30px',
        onChange: (selectedRowKeys, selectedRows) => {
            setMaterialKeys(selectedRowKeys)
        },
    }
    //批量删除
    const deleteTemaAll = () => {
        if (MaterialKeys.length === 0) {
            message.warning('请选择你要删除的维修项目')
            return
        }
        let arr = data.filter((item) => MaterialKeys.indexOf(item.id) < 0 && MaterialKeys.indexOf(item.temporaryId) < 0)
        setDeleteAll(false)
        setData(arr)
        setMaterialKeys([])
        message.success('批量删除成功')
    }
    //维修物料删除一条数据
    const deleteMaterialOne = (row) => {
        //删除已选的
        let newData = [...data]
        let delIndex = newData.findIndex((item) => item.id === row.id && item.temporaryId === row.temporaryId)
        newData.splice(delIndex, 1)
        setData(newData)
        message.success('删除成功')
    }

    //是否是查看
    const isMode = () => {
        return orderMode === 'look' ? false : true
    }
    //table配置
    const columns = [
        {
            title: '序号',
            align: 'center',
            width: 40,
            fixed: 'left',
            render: (t, r, i) => i + 1,
        },
        {
            title: '商品编号',
            align: 'center',
            width: 200,
            fixed: 'left',
            dataIndex: 'materialCode',
            //defaultSortOrder: 'ascend',
            sorter: (a, b) => a.materialCode.localeCompare(b.materialCode),
            render: (text, record) => (
                <YhTooltip
                    style={{ color: '#2e3aeb', cursor: 'pointer' }}
                    onClick={() => {
                        setHistoryData({ visible: true, materialCode: record.materialCode, materialName: record.materialName, shopId: shopId })
                    }}
                    text={text}
                />
            ),
        },
        {
            title: '商品名称',
            align: 'center',
            width: 140,
            fixed: 'left',
            dataIndex: 'materialName',
            render: (text) => <YhTooltip text={text} />,
            //defaultSortOrder: 'ascend',
            sorter: (a, b) => a.materialName.localeCompare(b.materialName),
        },
        // {
        //     title: "服务性质",
        //     align: "center",
        //     width: "120px",
        //     dataIndex: "serviceNature",
        //     render: (text) => <span>{dictObj.serviceNature[text]}</span>,
        //     editable: orderSelectThreeGuaranteesUnit === "014001" || orderSelectThreeGuaranteesUnit === "014002" || mode === "look" ? false : true,
        // },
        {
            title: '领料单号',
            align: 'center',
            width: 120,
            dataIndex: 'maintainOrderCode',
            render: (text, record) => (
                <div
                    style={{ color: '#2e3aeb', cursor: 'pointer' }}
                    onClick={() => {
                        setMaintainOrderId(record.maintainOrderId)
                        setMaintainOrderDetailVisible(true)
                    }}
                >
                    {text}
                </div>
            ),
        },
        {
            title: '品牌',
            align: 'center',
            width: 120,
            dataIndex: 'brandName',
        },
        // {
        //     title: "三包单位",
        //     align: "center",
        //     width: "120px",
        //     dataIndex: "threeGuaranteesUnit",
        //     render: (text) => <span>{dictObj.threeGuaranteesUnit[text]}</span>,
        //     editable: orderSelectThreeGuaranteesUnit === "014001" || mode === "look" ? false : true, //订单收费时 不可编辑。
        // },
        // {
        //     title: "索赔单号",
        //     align: "center",
        //     width: "120px",
        //     dataIndex: "claimNumber",
        //     editable: orderSelectThreeGuaranteesUnit === "014001" || mode === "look" ? false : true, //订单收费时 不可编辑。
        // },
        {
            title: '单位',
            align: 'center',
            width: 120,
            dataIndex: 'unitName',
        },
        {
            title: '可用数量',
            align: 'center',
            width: 120,
            dataIndex: 'availableUseNum',
        },
        {
            title: '已选数量',
            align: 'center',
            width: 120,
            dataIndex: 'materialNumber',
            // editable: orderSelectThreeGuaranteesUnit === "014002" || mode === "look" ? false : true,
            // editable: true,
            editable: isMode(),
        },
        {
            title: '单价',
            align: 'center',
            width: 120,
            dataIndex: 'unitPrice',
            // editable: orderSelectThreeGuaranteesUnit === "014002" || mode === "look" ? false : true,
            editable: isMode(),
        },
        {
            title: '总计',
            align: 'center',
            width: 120,
            dataIndex: 'totalCount',
        },
        {
            title: '优惠',
            align: 'center',
            width: 120,
            dataIndex: 'preferentialAmount',
            // editable: orderSelectThreeGuaranteesUnit === "014002" || mode === "look" ? false : true,
            editable: isMode(),
        },
        {
            title: '折扣',
            align: 'center',
            width: 120,
            dataIndex: 'materialDiscount',
            // editable: orderSelectThreeGuaranteesUnit === "014002" || mode === "look" ? false : true,
            editable: isMode(),
        },
        // {
        //     title: "分类代码",
        //     align: "center",
        //     width: "120px",
        //     dataIndex: "partClassificationCode",
        // },
        {
            title: '总金额',
            align: 'center',
            width: 120,
            dataIndex: 'actualMoney',
        },
        {
            title: "是否平台",
            align: "center",
            dataIndex: "isPlatform",
            render: (text) => (text ? "是" : "否"),
        },
        {
            title: '是否赠送',
            align: 'center',
            width: 100,
            dataIndex: 'giftFlag',
            render: (text) => (text === 'gift' ? <img src={zeng} style={{ width: '35px' }} /> : ''),
        },
        {
            title: '备注',
            align: 'center',
            width: 120,
            dataIndex: 'remark',
            editable: isMode(),
        },
        {
            title: '操作',
            align: 'center',
            width: '80px',
            dataIndex: 'operation',
            render: (_, record) => {
                return !isMode() ? '' : <YhIcon type="icon-guanbi" style={{ color: '#D81E06', cursor: 'pointer' }} onClick={() => deleteMaterialOne(record)} />
            },
        },
    ]

    //单元格编辑保存
    const materialHandleSave = (row, status) => {
        //查看是否是三包
        console.log(status)
        //每次服务性质不是三包的话，把之前选的三包给清空掉。
        if (row.serviceNature === '016001') {
            // row.threeGuaranteesUnit = "10601";
        } else {
            row.threeGuaranteesUnit = ''
            row.claimNumber = ''
        }
        if (status === 'serviceNature') {
            row.unitPrice = row.serviceNature === '016001' ? row.price : row.serviceNature === '016002' ? row.lateSalePrice || row.retailPrice : row.unitPrice
        }
        // 单价必须大于采购价
        if (row.price > row.unitPrice) {
            row.unitPrice = row.price
            message.info('单价不能小于采购价')
        }
        //每次编辑计算总计
        row.totalCount = (row.materialNumber * row.unitPrice).toFixed(2)

        // if (row.serviceNature !== "016001") {
        //有折扣   数量 * 单价 *折扣 - 优惠
        if (+row.materialDiscount > 0) {
            row.actualMoney = ((row.totalCount * row.materialDiscount) / 10 - row.preferentialAmount).toFixed(2)
        } else {
            row.actualMoney = (row.totalCount - row.preferentialAmount).toFixed(2)
        }
        // } else {
        // row.actualMoney = 0
        // row.preferentialAmount = 0
        // row.materialDiscount = null
        // row.totalCount = 0 //总计
        // }
        if (row.giftFlag === 'gift') {
            row.actualMoney = '0.00'
        }
        const newData = [...data]
        const index = newData.findIndex((item) => row.id === item.id && item.temporaryId === row.temporaryId)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }
    const MaterialColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: materialHandleSave,
            }),
        }
    })

    //导出模版
    const exportTemplete = async () => {
        let data = {
            type: 'get',
        }
        let resFlie = await download('/ci/order/part/export_template', data)
        if (resFlie && resFlie.status === 200) {
            message.success('已导出')
            convertRes2Blob(resFlie)
        } else {
            message.warning('导出异常，请重新尝试')
        }
    }

    //导入数据
    const onChangeUpload = (info) => {
        setLoading(true)
        if (info.file.status === 'done') {
            const { retCode, retData, retMsg } = info.file.response
            if (retCode === 200) {
                console.log(retData)
                if (retData.message) {
                    message.success(retData.message)
                }
                setPartListForHistory(retData.items, 'import')
            } else {
                message.error(retMsg)
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name}导入异常，请尝试重新导入`)
        }
        setLoading(false)
    }

    // useEffect(() => {
    //     comIsOperation(orderSelectThreeGuaranteesUnit)
    // }, [orderSelectThreeGuaranteesUnit])

    // const comIsOperation = (aa) => {
    //     if (dataType === "sanbao" && orderSelectThreeGuaranteesUnit === "014001") {
    //         return setIsOperation(false)
    //     }
    //     if (dataType === "shoufei" && orderSelectThreeGuaranteesUnit === "014002") {
    //         return setIsOperation(false)
    //     }

    //     setIsOperation(true)
    // }

    useEffect(() => {
        if (orderStatus === 'completed' || orderStatus === 'cancelled') {
            setIsOperation(false)
        }
    }, [orderStatus])
    return (
        <div className="list-container">
            <div className="list-header">
                <div className="list-name">
                    <span>维修物料</span>
                    <span className="total-price">
                        总金额&nbsp;¥&nbsp;<span>{totalPrice}</span>
                        &nbsp;元&nbsp;({data.length}项){' '}
                    </span>
                    {isMode() && (
                        <div className="list-name-but">
                            <YhIcon
                                type="icon-zengjia"
                                style={{ color: '#37B36F', fontSize: '26px' }}
                                onClick={() => {
                                    if (!teamId) {
                                        return message.error('请先派单后再添加物料')
                                    }
                                    setVisible(true)
                                }}
                            />

                            {mode !== 'insert' && (
                                <>
                                    <span className="list-delete" onClick={() => echoData(orderId)}>
                                        刷新
                                    </span>
                                    {/* <span className="list-delete" onClick={() => exportTemplete()}>
                                        导出模版
                                    </span>
                                    <Upload
                                        name="file"
                                        method="post"
                                        showUploadList={false}
                                        headers={{ authorization: getToken() }}
                                        action={`${API_SERVER_HOST}/ci/order/part/import_conversion`}
                                        onChange={onChangeUpload}
                                    >
                                        <span className="list-delete">导入明细</span>
                                    </Upload> */}
                                </>
                            )}
                            {!deleteAll ? (
                                <span className="list-delete" onClick={() => setDeleteAll(true)}>
                                    批量删除
                                </span>
                            ) : (
                                <>
                                    <span className="list-delete" onClick={deleteTemaAll}>
                                        确认删除
                                    </span>
                                    <span className="list-delete" onClick={() => setDeleteAll(false)}>
                                        取消
                                    </span>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Table
                size="small"
                rowKey={(record) => record.id || record.temporaryId}
                components={components}
                columns={MaterialColumns}
                dataSource={data}
                pagination={false}
                scroll={{ x: 2000, y: 260 }}
                rowSelection={deleteAll ? MaterialSelection : false}
            />
            {isMode() && (
                <ItemDrawer visible={visible} onClose={(bool) => setVisible(bool)} onItemOk={setPartList} drawerMode="part" orderId={orderId} data={data} />
            )}
            {loading && (
                <Loading
                    style={{
                        background: 'rgba(0,0,0,0.5)',
                        height: '100vh',
                        with: '100vh',
                        position: 'fixed',
                        left: '0',
                        top: '0',
                        zIndex: 9999,
                    }}
                />
            )}
            {historyData.visible && (
                <MaterialHistory {...historyData} onCancel={() => setHistoryData({ visible: false, materialCode: '', materialName: '', shopId: '' })} />
            )}
            {maintainOrderDetailVisible && (
                <RepairPickingListDetail
                    rowInfo={{ id: maintainOrderId }}
                    visible={maintainOrderDetailVisible}
                    setVisible={setMaintainOrderDetailVisible}
                    handleSuc={() => {
                        setMaintainOrderDetailVisible(false)
                    }}
                    mode={'look'}
                />
            )}
        </div>
    )
}
