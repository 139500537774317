
import { post, get } from "../utils/request"

//最后一条通知消息
export const internalMessageCurrent = () => {
    return get('/ci-message/internal_message/current', {}, {}, true)
}

//已读
export const internalMessageRead = (messageId) => {
    return get(`ci-message/internal_message/read/${messageId}`)
}


