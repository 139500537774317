import { post, get } from "../utils/request"

/**
 * 用户积分管理
 */
//积分信息列表
function getPointList(data) {
    return post("/ci/points/list", data)
}
//批量修改
function pointEdit(data) {
    return post(`/ci/points/batch_edit`, data)
}
//新增
function pointInsert(data) {
    return post(`/ci/points/add`, data)
}
//用户积分详情使用列表
function pointChangeList(data) {
    return post("/ci/points/change/list", data)
}
//禁用，启用
function pointSwitch(id, operate) {
    return get(`ci/points/operating/${id}/${operate}`)
}

export { getPointList, pointEdit, pointInsert, pointChangeList, pointSwitch }
