import React, { useEffect, useState, useRef, useContext } from "react"
import { Button, message } from "antd"
import { YhFormBox } from "@/erp_subpackage/components/YhFormBox"
import { useSaleRoDetailModel } from "./saleRoDetailModel"
import useGlobalModel from "@/erp_subpackage/model/globalModel"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { DetailType, IPayTypeDs } from "@/erp_subpackage/utils/ErpEnum"
import { debounce } from "lodash"
import { useSaleRoModel } from "../../saleRoModel"
import { MyContext } from "@/store/myContext"
import { getPcodeDict } from "@/utils/dataStorage"
import { vehicleService } from "@/erp_subpackage/pages/base/vehicle/vehicleService"
import { getVehicleList } from "@/service/customerService"
import { saleSoService } from "@/erp_subpackage/pages/sale/so/saleSoService";
import { formatNum } from "@/erp_subpackage/utils/util"
import { getBentityListOption } from '@/utils/dataStorage'
export const FormBox = ({ modeKey, mode, rowId, dispatch, form, detailsList, type }) => {
    const { getSelectedDictOne } = useDictModel()
    const {
        fetchYCCustomerList,
        user: { shopId,shopCode},
    } = useGlobalModel()
    const { detailsPage } = useContext(MyContext)
    const { setSelectedRowKeys, setDescRows, setSelectedRow } = useSaleRoModel()
    const formRef = useRef()
    const {
        detailEditForm,
        detailAddForm,
        detailLookForm,
        initForm,
        vehicleEditAll,
        vehicleAddAll,
        vinEditAll,
        vinAddAll,
        detailAddList,
        detailDataSource,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        resetAddCatch,
        insertDetail,
        editDetail,
        setVehicleAddAll,
        setVinEditAll,
        setVinAddAll,
        setVehicleEditAll,
        setDetailAddForm,
        setDetailAddList,
        setTopButsControl,
        setDetailDataSource,saveBtnLoading,
        setSaveBtnLoading
    } = useSaleRoDetailModel()
    const [customerType, setCustomerType] = useState({})
    const [more, setMore] = useState(true) //控制更多按钮
    const updateFormVal = mode === "add" ? updateAddFormVal : updatEditFormVal
    const formVal = mode === "add" ? detailAddForm : mode === "look" ? detailLookForm : detailEditForm
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (form) {
            let newDetailsList = detailsList.map((item) => {
                //单向优惠金额
                let rdecreaseAmount = +formatNum(item.decreaseAmount);
                //退货折扣金额
                let discountAmount = +formatNum(item.discountedAmount);
                //退货金额 = (销售单)折后金额 - 单项优惠
                let amount = +formatNum(discountAmount - rdecreaseAmount)
                return {
                    ...item,
                    detailsId: item.id,
                    rdecreaseAmount, discountAmount, amount
                    // discountAmount: +formatNum(item.discountedAmount) ?? +formatNum(Number(item.num) * Number(item.price) * (Number(item.discountRate) / 100)),
                    // rdecreaseAmount: +formatNum(Number(item.num) * Number(item.price) * (Number(item.discountRate) / 100)) > Number(item.leftDecreaseAmount) ? item.leftDecreaseAmount : +formatNum(Number(item.num) * Number(item.price) * (Number(item.discountRate) / 100)),
                    // amount: +formatNum(+formatNum(Number(item.num) * Number(item.price) * (Number(item.discountRate) / 100)) - +formatNum(+formatNum(Number(item.num) * Number(item.price) * (Number(item.discountRate) / 100)) > Number(item.leftDecreaseAmount) ? item.leftDecreaseAmount : +formatNum(Number(item.num) * Number(item.price) * (Number(item.discountRate) / 100)))),
                }
            })
            setDetailAddList(newDetailsList ?? [])
            setDetailAddForm(form)
        }
    }, [detailsList, form])

    useEffect(() => {
        if (mode === "edit" || mode === "look") {
            getDetailEditOne(rowId, mode).then(res => {
                if (res.orderSourceCode === "order-source.add") {
                    getVehicleAllList(res.customerName)
                } else {
                    getYCVehicleAllList({ customerName: res.customerName })
                }
            })
        }

        let obj = {}
        getPcodeDict("017").children.forEach((item) => {
            obj[item.dictCode] = item.title
        })
        setCustomerType(obj)

        return () => {
            updateFormVal(null)
            mode === 'add' ? setDetailAddList([]) : setDetailDataSource([])
        }
    }, [rowId, mode])


    const save = () => {
        formRef.current.validateFields().then(async (res) => {
            let goods = mode === 'add' ? detailAddList : detailDataSource
            if(goods.length==0){
                message.warning(`未添加商品明细不可生成销售退货单`)
                return
            }
            setSaveBtnLoading(true)        
            if (mode === "add") {
                // if (form) {
                //     //整单退货时修改销售单状态
                //     let { retData } = await saleSoService.updateStatus({
                //         id: form.id,
                //         status: DictCodeEnmu.SALE_REVERSED
                //     })
                // }

                await insertSave({ ...res, type })
            } else if (mode === "edit") {
                try {
                    let { id, returnCode } = detailEditForm
                    const resData = await editDetail({ ...res, id, returnCode })
                    if (resData) {
                        message.success("编辑成功")
                        getDetailEditOne(returnCode, mode)
                    }
                } catch (error) {
                    debugger
                    setSaveBtnLoading(false)
                }
            }
            setTimeout(() => {
                setSaveBtnLoading(false)
            }, 500);
            
        })
    }
    const insertSave = async (res) => {
        try {
            const retData = await insertDetail(res)
            if (retData) {
                message.success("新增成功")
                setSelectedRowKeys([retData.id])
                setDescRows([retData])
                setSelectedRow(retData)
                resetAddCatch(mode)
                goBack()
            }
        } catch (error) {
            setSaveBtnLoading(false)
        }
    }
    const goBack = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "saleRo",
        })
        mode === 'add' ? setDetailAddList([]) : setDetailDataSource([])
    }
    const onRefresh = async () => {
        if (mode === "add") {
            formRef.current.resetFields()
            setDetailAddForm(initForm)
            setDetailAddList([])
        } else if (mode === "edit") {
            getDetailEditOne(rowId, mode)
        }
        message.success("刷新成功")
    }
    const rightChildren = (
        <>
            {mode !== "look" && (
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={debounce(() => {
                        save()
                    }, 900)}
                    loading={saveBtnLoading}
                >
                   {saveBtnLoading ? '保存中' : '保存'}
                </Button>
            )}
            {mode !== "look" && (
                <Button className="btn-item" type="link" shape="round" onClick={onRefresh}>
                    刷新
                </Button>
            )}
            <Button className="btn-item" type="link" shape="round" onClick={goBack}>
                返回
            </Button>
        </>
    )

    // 来源为新增时车辆数据为云修
    const getVehicleAllList = async (param) => {
        let params = {
            ...param,
            shopId,
            pageSize: -1,
            pageNum: 1,
        }
        const { retData } = await vehicleService.page(params)
        const { records } = retData
        let newRecords = records.map((item) => {
            return {
                // ...item,
                label: item.licensePlate,
                value: item.licensePlate,
                vehicle_type_code: item.categoryCode,
                vehicle_type_name: item.categoryName,
                customer_code: item.code,
                customer_name: item.customerName,
                vin: item.vin,
                vehicle_model: item.model,
                license_plate: item.licensePlate,
                phone: item.phone,
                contact: item.contact,
                address: item.address,
                customer_type_code: item.typeCode,
                customer_type_name: item.typeName,
            }
        })

        let vinRecords = records.map((item) => {
            return {
                label: item.vin,
                value: item.vin,
                license_plate: item.licensePlate,
                vehicle_model: item.model,
                vehicle_type_code: item.categoryCode,
                vehicle_type_name: item.categoryName,
                customer_code: item.code,
                customer_name: item.customerName,
                vin: item.vin,
                phone: item.phone,
                contact: item.contact,
                address: item.address,
                customer_type_code: item.typeCode,
                customer_type_name: item.typeName,
            }
        })
        if (mode === "add") {
            setVehicleAddAll(newRecords)
            setVinAddAll(vinRecords)
        } else {
            setVehicleEditAll(newRecords)
            setVinEditAll(vinRecords)
        }
    }

    // 来源为小程序或者App时  车辆来源为云创本身的数据
    const getYCVehicleAllList = async (param) => {
        let data = {
            searchItem: {
                ...param,
            },
            pageSize: -1,
            pageNum: 1,
        }
        getVehicleList(data).then((res) => {
            const {
                retData: { list },
            } = res
            let newRecords = list?.map((item) => {
                return {
                    label: item.vehicleCode,
                    value: item.vehicleCode,
                    vehicle_type_name: item.vehicleType,
                    customer_name: item.customerName,
                    customer_code: item.customerId,
                    vin: item.vehicleVin,
                    license_plate: item.vehicleCode,
                }
            })
            let vinRecords = list.map((item) => {
                return {
                    label: item.vehicleVin,
                    value: item.vehicleVin,
                    vehicle_type_name: item.vehicleType,
                    customer_name: item.customerName,
                    customer_code: item.customerId,
                    license_plate: item.vehicleCode,
                    vin: item.vehicleVin,
                }
            })
            if (mode === "add") {
                setVehicleAddAll(newRecords)
                setVinAddAll(vinRecords)
            } else {
                setVehicleEditAll(newRecords)
                setVinEditAll(vinRecords)
            }
        })
    }

    //客户名称
    const customerChange = (value, option) => {
        console.log(option)
        if (value && option) {
            let { contact, phone, address, category_name, category_code } = option
            option &&
                updateFormVal({
                    customerName: option.label,
                    customerCode: option.value,
                    contact,
                    phone,
                    address,
                    customerTypeCode: category_code,
                    customerTypeName: category_name ?? customerType[category_code],
                    licensePlate: null,
                    vehicleTypeCode: null,
                    vehicleTypeName: null,
                    vin: null,
                })
        }
        // formVal.orderSourceCode === DictCodeEnmu.SOURCE_ADD
        //     ? getVehicleAllList({ customerName: option?.label })
        //     : getYCVehicleAllList({ customerName: option?.label })
            getYCVehicleAllList({ customerName: option?.label })
    }
    //车辆牌照
    const vehicleChange = (value, option) => {
        console.log(option)
        if (value && option) {
            let {
                customer_code,
                customer_name,
                vehicle_model,
                vehicle_type_name,
                vehicle_type_code,
                vin,
                phone,
                address,
                contact,
                customer_type_code,
                customer_type_Name,
            } = option
            option &&
                updateFormVal({
                    customerName: customer_name,
                    customerCode: customer_code,
                    vin,
                    licensePlate: option.value,
                    vehicleTypeCode: vehicle_type_code ?? "",
                    vehicleTypeName: vehicle_type_name ?? "",
                    vehicleModel: vehicle_model,
                    phone: phone ?? formVal.phone,
                    address: address ?? formVal.address,
                    contact: contact ?? formVal.contact,
                    customerTypeCode: customer_type_code ?? formVal.customerTypeCode,
                    customerTypeName: customer_type_Name ?? formVal.customerTypeName,
                })
        }
    }

    //vin码
    const vinChange = (value, option) => {
        if (value && option) {
            let {
                customer_code,
                customer_name,
                vehicle_model,
                license_plate,
                vehicle_type_name,
                vehicle_type_code,
                vin,
                phone,
                address,
                contact,
                customer_type_code,
                customer_type_Name,
            } = option
            option &&
                onChange({
                    customerName: customer_name,
                    customerCode: customer_code,
                    vin,
                    licensePlate: license_plate,
                    vehicleTypeCode: vehicle_type_code ?? "",
                    vehicleTypeName: vehicle_type_name ?? "",
                    vehicleModel: vehicle_model,
                    phone: phone ?? formVal.phone,
                    address: address ?? formVal.address,
                    contact: contact ?? formVal.contact,
                    customerTypeCode: customer_type_code ?? formVal.customerTypeCode,
                    customerTypeName: customer_type_Name ?? formVal.customerTypeName,
                })
        }
    }
    //车辆类型
    const vehicleTypeChange = (value, option) => {
        option && onChange({ vehicleTypeName: option.label, vehicleTypeCode: option.value })
    }
    //结算方
    const settlementPartyChange = (value, option) => {
        option && onChange({ settlementPartyName: option.label, settlementPartyCode: option.value })
    }
    //客户类型
    const customerTypeChange = (value, option) => {
        option && onChange({ customerTypeName: option.label, customerTypeCode: option.value })
    }
    //发票类型
    const invoiceTypeChange = (value, option) => {
        option && onChange({ invoiceTypeName: option.label, invoiceTypeCode: option.value })
    }
    //销售实体
    const bentityChange = (value, option) => {
        option && onChange({ bentityName: option.label, bentityCode: option.value })
    }
    const typeForm = {
        add: detailAddForm,
        edit: detailEditForm,
        look: detailLookForm,
    }
    const goCustomer = () => {
        // if (formVal.orderSourceCode === DictCodeEnmu.SOURCE_ADD) {
        //     dispatch({
        //         type: "changeTabs",
        //         name: "客户新增",
        //         activeMenu: "baseCustomer/add",
        //         content: detailsPage.BaseCustomerDetails({
        //             dispatch,
        //             modeKey,
        //             mode,
        //         }),
        //     })
        // } else {
            dispatch({
                type: "changeTabs",
                name: "客户新增",
                activeMenu: "clientRecord/insert",
                content: detailsPage.ClientDetails({
                    dispatch,
                    modeKey,
                    mode: "insert",
                    rowInfo: {},
                }),
            })
        // }
    }

    const goVehicle = () => {
        // if (formVal.orderSourceCode === DictCodeEnmu.SOURCE_Add) {
        //     dispatch({
        //         type: "changeTabs",
        //         name: "车辆新增",
        //         activeMenu: "baseVehicle/add",
        //         content: detailsPage.BaseVehicleDetails({
        //             dispatch,
        //             modeKey,
        //             mode,
        //             customerCode: formVal.customerCode,
        //             customerName: formVal.customerName,
        //         }),
        //     })
        // } else {
            dispatch({
                type: "changeTabs",
                name: "车辆新增",
                activeMenu: "vehicleRecord/insert",
                content: detailsPage.VehicleDetails({
                    dispatch,
                    modeKey,
                    mode: "insert",
                    rowInfo: {},
                }),
            })
        // }
    }

    const onChange = (changedValues) => {
        mode === "add" ? updateAddFormVal(changedValues) : updatEditFormVal(changedValues)
        setTopButsControl(true)
    }
    const [payTypeList, setPayTypeList] = useState([])
    useEffect(() => {
        if( shopCode=='SHOP-M-00000016'||shopCode=='SHOP-M-00000017'||shopCode=='SHOP-M-00000018'||shopCode=='SHOP-M-00000037'){
            setPayTypeList(getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE))
        }else{
            setPayTypeList(getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE).map((item)=>{
                return{...item,disabled:item.value=='settle-type.credit'?true:false}}))         }
    }, [])
    const baseFormItem = {
        title: '基础信息',
        style: { padding: "0 20px", marginBottom: "10px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: "Input",
                fieldLabel: "退货单号",
                fieldName: "returnCode",
                disable: true,
                placeholder: "自动生成",
            },
            {
                type: "AsyncSelect",
                fieldLabel: (
                    <span className="click-span" onClick={goCustomer}>
                        客户名称
                    </span>
                ),
                fieldName: "customerName",
                onSelectChange: customerChange,
                selectfetchList: fetchYCCustomerList,
                defaultQuery: formVal.customerName,
                allowClear: true,
                showSearch: true,
                rules: [{ required: true, message: "请选择客户名称" }],
            },
            {
                type: "Input",
                fieldLabel: "联系人",
                fieldName: "contact",
                rules: [{ required: true, message: "请输入联系人" }],
            },
            {
                type: "Input",
                fieldLabel: "联系电话",
                fieldName: "phone",
                rules: [{ required: true, message: "请输入联系电话" }],
            },
            {
                type: 'Select',
                fieldLabel: '销售实体',
                fieldName: 'bentityCode',
                options: getBentityListOption(),
                onSelectChange: bentityChange,
                rules: [{ required: true, message: "请选择销售实体" }],
            },
            {
                type: "Select",
                allowClear: true,
                showSearch: true,
                fieldLabel: "结算方",
                fieldName: "settlementPartyCode",
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_PARTY),
                onSelectChange: settlementPartyChange,
                rules: [{ required: true, message: "请选择结算方" }],
                hidden: mode === "look",
            },
            {
                type: "Input",
                fieldLabel: "结算方",
                fieldName: "settlementPartyName",
                hidden: mode !== "look",
            },
            {
                type: "Select",
                allowClear: true,
                showSearch: true,
                fieldLabel: "结算类型",
                fieldName: "payType",
                // disable: true,
                // options: formVal.orderSourceCode === DictCodeEnmu.SOURCE_ADD ? getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE) : IPayTypeDs,
                // options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                options:payTypeList,
                // onSelectChange: paymentMethodChange,
                rules: [{ required: true, message: "请选择结算类型" }],
            },
            {
                type: "Input",
                fieldLabel: "退货原因",
                fieldName: "returnReason",
                span: 5,
                labelCol: { offset: 1 },
                // rules: [{ required: true, message: "请输入退货原因" }]
            },
            {
                type: "Button",
                fieldLabel: (
                    <a className="color-blue text-line" onClick={() => setMore(!more)}>
                        {more ? "更多>>" : "隐藏"}
                    </a>
                ),
                fieldName: "none",
                span: 1,
                labelCol: { offset: 5 },
            },
            {
                type: 'FormUpload',
                fieldLabel: '附件',
                fieldName: 'fileName',
                fieldMd5: 'md5',
                defaultFileName: formVal.fileName,
                defaultFileMd5: formVal.md5,
                onSetFieldsValue: onChange,
                hidden: more,
            },
            {
                type: "Input",
                fieldLabel: "客户地址",
                fieldName: "address",
                hidden: more,
            },
            {
                type: "Input",
                fieldLabel: "财务备注",
                fieldName: "fmsNote",
                hidden: more,
            },
            {
                type: "Input",
                fieldLabel: "备注",
                fieldName: "note",
                hidden: more,
            },
            {
                type: "Select",
                allowClear: true,
                showSearch: true,
                fieldLabel: "客户类型",
                fieldName: "customerTypeName",
                hidden: more,
                // options: getSelectedDictOne(DictCodeEnmu.CUSTOMER_TYPE),
                // onSelectChange: customerTypeChange,
                disable: true,
                // rules: [{ required: true, message: "请选择客户类型" }]
            },
            {
                type: "Select",
                allowClear: true,
                showSearch: true,
                fieldLabel: "发票类型",
                fieldName: "invoiceTypeCode",
                hidden: more,
                options: getSelectedDictOne(DictCodeEnmu.INVOICE_TYPE),
                onSelectChange: invoiceTypeChange,
            },
            {
                type: "Select",
                allowClear: true,
                showSearch: true,
                fieldLabel: "车辆类型",
                fieldName: "vehicleTypeName",
                disable: true,
                // fieldName: "vehicleTypeCode",
                // options: getSelectedDictOne(DictCodeEnmu.VEHICLE_TYPE),
                onSelectChange: vehicleTypeChange,
                hidden: more,
            },
            {
                type: "Select",
                fieldLabel: (
                    <span className="click-span" onClick={goVehicle}>
                        车辆牌照
                    </span>
                ),
                fieldName: "licensePlate",
                allowClear: true,
                showSearch: true,
                options: mode === "add" ? vehicleAddAll : vehicleEditAll,
                onSelectChange: vehicleChange,
                hidden: more,
            },
            {
                type: "Select",
                fieldLabel: "VIN码",
                fieldName: "vin",
                allowClear: true,
                showSearch: true,
                onSelectChange: vinChange,
                options: mode === "add" ? vinAddAll : vinEditAll,
                hidden: more,
            },
            {
                type: "Input",
                fieldLabel: "所属机构",
                fieldName: "shopName",
                disable: true,
                hidden: more,
            },
            {
                type: "Input",
                fieldLabel: "制单人",
                fieldName: "createUser",
                hidden: more,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "制单时间",
                fieldName: "createTime",
                hidden: more,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "库管员",
                fieldName: "storekeeper",
                hidden: more,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "入库时间",
                fieldName: "stockIoTime",
                hidden: more,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "结算员",
                fieldName: "reckoner",
                hidden: more,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "结算时间",
                fieldName: "settleTime",
                hidden: more,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "核销时间",
                fieldName: "writeOffTime",
                hidden: more,
                disable: true,
            },
            // {
            //     type: "Select",
            //     fieldLabel: "订单来源",
            //     fieldName: "orderSourceCode",
            //     options: getSelectedDictOne(DictCodeEnmu.ORDER_SOURCE),
            //     hidden: more,
            //     disable: true,
            // },
            {
                type: "Input",
                fieldLabel: "收货人",
                fieldName: "consignee",
                disable: mode != 'edit' || formVal.status === DictCodeEnmu.SALE_RETURN_STATUS_CREATE,
                hidden: more,
            },
            {
                type: "Input",
                fieldLabel: "收货电话",
                fieldName: "consigneePhone",
                disable: mode != 'edit' || formVal.status === DictCodeEnmu.SALE_RETURN_STATUS_CREATE,
                hidden: more,
            },
            {
                type: "Input",
                fieldLabel: "收货地址",
                fieldName: "consigneeAddress",
                disable: mode != 'edit' || formVal.status === DictCodeEnmu.SALE_RETURN_STATUS_CREATE,
                hidden: more,
            },
            {
                type: "Input",
                fieldLabel: "单据编号",
                fieldName: "orderCode",
                disable: true,
                hidden: more,
            },
            {
                type: "Input",
                fieldLabel: "单据类型",
                fieldName: "orderTypeName",
                disable: true,
                hidden: more,
            },
            // {
            //     type: "Select",
            //     fieldLabel: "单据类型",
            //     fieldName: "orderTypeCode",
            //     disable: true,
            //     options: getSelectedDictOne(DictCodeEnmu.TRADE_TYPE),
            //     hidden: more,
            // },
            {
                type: "Input",
                fieldLabel: "付款时间",
                fieldName: "payTime",
                disable: true,
                hidden: more,
            },

            //带出字段
            {
                type: "Input",
                fieldLabel: "销售实体名称",
                fieldName: "bentityName",
                hidden: true,
            },
            {
                type: "Input",
                fieldLabel: "客户名称",
                fieldName: "customerCode",
                hidden: true,
            },
            {
                type: "Input",
                fieldLabel: "车辆类型Name",
                fieldName: "vehicleTypeName",
                hidden: true,
            },
            {
                type: "Input",
                fieldLabel: "维修类型Name",
                fieldName: "maintenanceTypeName",
                hidden: true,
            },
            {
                type: "Input",
                fieldLabel: "结算方Name",
                fieldName: "settlementPartyName",
                hidden: true,
            },
            // {
            //     type: "Input",
            //     fieldLabel: "结算方式Name",
            //     fieldName: "paymentMethodName",
            //     hidden: true,
            // },
            {
                type: "Input",
                fieldLabel: "客户类型Code",
                fieldName: "customerTypeCode",
                hidden: true,
            },
            {
                type: "Input",
                fieldLabel: "发票类型Name",
                fieldName: "invoiceTypeName",
                hidden: true,
            },
            {
                type: "Input",
                fieldLabel: "订单来源Name",
                fieldName: "orderSourceName",
                hidden: true,
            },
            {
                type: "Input",
                fieldLabel: "支付方式Name",
                fieldName: "payMethodName",
                hidden: true,
            },
            {
                type: "Input",
                fieldLabel: "单据类型Name",
                fieldName: "orderTypeName",
                hidden: true,
            },
            {
                type: "Input",
                fieldLabel: "单据类型Name",
                fieldName: "orderTypeName",
                hidden: true,
            },
        ],
    }

    const disabledFromBtn = () => {
        let status = detailEditForm.status;
        if (mode === "look") return false;
        if (mode === "add") return true;

        if (mode === "edit" &&
            status === DictCodeEnmu.SALE_RETURN_STATUS_SETTLE ||
            status === DictCodeEnmu.SALE_RETURN_STATUS_STOCK_IO || status === "sale-return-status.paying"
        ) {
            return false
        }
        return true;
    }

    
    const poFromConfig = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        // disabled: mode === DetailType.look || (detailEditForm.status !== DictCodeEnmu.SALE_RETURN_STATUS_CREATE && mode === 'edit') || formVal.orderSourceCode != DictCodeEnmu.SOURCE_ADD,
        disable: !disabledFromBtn(),
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem],
    }

    return <YhFormBox {...poFromConfig} />
}
