import React, { useState, useEffect, useImperativeHandle } from "react"
import { Table, message } from "antd"
import YhIcon from "@/components/pubIcon"
import ItemDrawer from "@/components/pubPartDrawer/itemDrawer" //项目弹框
import { EditableRow, EditableCell } from "./pubEditble"
import { ItemAttr } from "@/utils/enum"

//项目
export default ({ mode, maintenanceRef, initData }) => {
    const [visible, setVisible] = useState(false) //弹框
    const [data, setData] = useState([]) //表格数据
    const [totalPrice, setTotalPrice] = useState(0) //维修项目的总价

    useEffect(() => {
        if (Object.keys(initData).length > 0) {
            const {items,itemAmount} = initData
            setData(items ? items : [])
            setTotalPrice(itemAmount ? itemAmount : "0.00")
        }
    }, [initData])

    //计算总价
    useEffect(() => {
        let num = 0
        data.forEach((item) => {
            num += Number(item.itemAmount)
        })
        setTotalPrice(num.toFixed(2))
    }, [data])

    //暴露给子组件的方法
    useImperativeHandle(maintenanceRef, () => ({
        //父组件保存的时候 获取所有编辑完后的数据
        upMaintenancelData: () => {
            return data
        },
    }))
   

    //新增确定。 有选中的，过滤掉列表里面有的，在合并到一起。
    const setItemList = (setItemList) => {
        if (setItemList.length) {
            let newArr = []
            setItemList.forEach((item) => {
                let unselect = item
                let isSelect = data.find((item) => item.itemId === unselect.id)
                if (isSelect === undefined) {
                    newArr.push({
                        itemId: unselect.id,
                        itemCode: unselect.itemCode,
                        itemName: unselect.itemName,
                        itemAttr: unselect.itemAttr,
                        itemWorkHours: unselect.itemWorkHours, //工时
                        workHoursPrice: unselect.workHoursPrice, //单价
                        itemAmount: unselect.itemAmount, //工时费
                        remark: unselect.remark, //备注
                    })
                }
            })
            setData([...data, ...newArr])
        }
        setVisible(false)
    }
    /**
     * 以下是页面展示
     */
    const [maintenanceKeys, setMaintenanceKeys] = useState([]) // 批量删除的key
    const [deleteAll, setDeleteAll] = useState(false) //删除多选的控制按钮
    //批量删除多选的成员id
    const maintenanceSelection = {
        columnWidth: "30px",
        onChange: (selectedRowKeys, selectedRows) => {
            setMaintenanceKeys(selectedRowKeys)
        },
    }

    //批量删除
    const deleteTemaAll = () => {
        if (maintenanceKeys.length === 0) {
            message.warning("请选择你要删除的维修项目")
            return
        }
        let arr = data.filter((item) => maintenanceKeys.indexOf(item.itemId) < 0)
        setDeleteAll(false)
        setData(arr)
        setMaintenanceKeys([])
        message.success("批量删除成功")
    }
    //维修项目删除一条数据
    const deleteMaintenanceOne = (row) => {
        //删除已选的
        let newData = [...data]
        let delIndex = newData.findIndex((item) => item.itemId === row.itemId)
        newData.splice(delIndex, 1)
        setData(newData)
        message.success("删除成功")
    }

    //是否是查看
    const isMode = () => {
        return mode === "look" ? false : true
    }

    //选中展示table配置
    const columns = [
        {
            title: "项目编码",
            align: "center",
            width: 120,
            fixed: "left",
            dataIndex: "itemCode",
        },
        {
            title: "项目名称",
            align: "center",
            width: 400,
            fixed: "left",
            dataIndex: "itemName",
            render: (text, record) =>
                record.state === "refuse_repair" ? (
                    <span>
                        {text}
                        <span style={{ color: "#f00" }}>(拒修)</span>
                    </span>
                ) : (
                    <>{text}</>
                ),
        },
        {
            title: "项目属性",
            align: "center",
            dataIndex: "itemAttr",
            render: (text) => <p>{ItemAttr[text]}</p>,
        },
        {
            title: "工时",
            align: "center",
            width: 100,
            dataIndex: "itemWorkHours",
            editable: mode === "look" ? false : true,
        },
        {
            title: "单价",
            align: "center",
            width: 185,
            dataIndex: "workHoursPrice",
            editable: mode === "look" ? false : true,
        },
        {
            title: "工时费",
            align: "center",
            dataIndex: "itemAmount",
        },

        {
            title: "备注",
            align: "center",
            width: "120px",
            dataIndex: "remark",
            editable: isMode(),
        },
        !isMode()
            ? {}
            : {
                  title: "操作",
                  align: "center",
                  width: "80px",
                  dataIndex: "operation",
                  render: (_, record) => {
                      return <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => deleteMaintenanceOne(record)} />
                  },
              },
    ]

    //单元格编辑保存
    const handleSave = (row) => {
        row.itemAmount = (row.itemWorkHours * row.workHoursPrice).toFixed(2)
        const newData = [...data]
        const index = newData.findIndex((item) => row.itemId === item.itemId)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }
    const maintenanceColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    return (
        <div className="list-container">
            <div className="list-header">
                <div className="list-name">
                    <span>维修项目</span>
                    <span className="total-price">
                        总金额&nbsp;¥&nbsp;<span>{totalPrice}</span>
                        &nbsp;元&nbsp;({data.length}项){" "}
                    </span>
                    {isMode() && (
                        <div className="list-name-but">
                            <YhIcon type="icon-zengjia" style={{ color: "#37B36F", fontSize: "26px" }} onClick={() => setVisible(true)} />
                            {!deleteAll ? (
                                <span className="list-delete" onClick={() => setDeleteAll(true)}>
                                    批量删除
                                </span>
                            ) : (
                                <>
                                    <span className="list-delete" onClick={deleteTemaAll}>
                                        确认删除
                                    </span>
                                    <span className="list-delete" onClick={() => setDeleteAll(false)}>
                                        取消
                                    </span>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Table
                size="small"
                rowKey={(record) => record.itemId}
                components={components}
                columns={maintenanceColumns}
                dataSource={data}
                pagination={false}
                scroll={{ x: 1800, y: 260 }}
                rowSelection={deleteAll ? maintenanceSelection : false}
            />
            {isMode(mode) && <ItemDrawer visible={visible} onClose={(bool) => setVisible(bool)} onItemOk={setItemList} drawerMode="item" />}
        </div>
    )
}
