import React, { useEffect, useState, useRef } from 'react'
import { Form, Input, Col, Row, Select, Modal, Spin, message } from 'antd'
import { getPcodeDict } from '@/utils/dataStorage'
import { getBussinessEntityList } from '@/service/dataService'
import { usePrebookModel } from "../prebookModel";
import Item2 from './Item2' //维修项目
import Material2 from './Material2' //物料
import debounce from 'lodash/debounce'
import moment from 'moment'

const { Option } = Select
export default (props) => {
    const { mode, popupMode, rowInfo, visible, setVisible, onSuccess } = props
    const {
        getThreeGuaranteesList, setThreeGuaranteesList,
        getMaterialList,
        threeGuaranteeId, setThreeGuaranteeId,
        getInfo, guid
    } = usePrebookModel();
    const [form] = Form.useForm()
    const maintenanceRef = useRef() //维修项目 子组件
    const materialRef = useRef() //维修物料  子组件
    const [entityData, setEntityData] = useState({ data: [], fetching: false, })

    //计算出库金额
    useEffect(() => {
        let materialAmount = 0;
        getMaterialList(mode) && getMaterialList(mode).filter((item) => item.threeGuaranteeId == threeGuaranteeId).forEach(e => {
            materialAmount = Number(materialAmount) + Number(e.actualAmount);
            materialAmount = materialAmount.toFixed(2);
        });
        form.setFieldsValue({ outboundAmount: materialAmount })
    }, [threeGuaranteeId, getMaterialList(mode)])

    useEffect(() => {
        if (visible) {
            fetchEntity();
            if (popupMode === "add") {
                form.resetFields();
                form.setFieldsValue({
                    repairTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                })
                setThreeGuaranteeId(guid().replaceAll("-", ""));
            }
            if (popupMode === 'edit') {
                form.setFieldsValue({ ...rowInfo })
                setThreeGuaranteeId(rowInfo.id);
            }
        }
    }, [visible])

    const onSubmit = async () => {
        let flag = true;
        //维修物料-销售单价不能为0
        getMaterialList(mode) && getMaterialList(mode).filter((item) => item.threeGuaranteeId == threeGuaranteeId).forEach(e => {
            if (Number(e.salePrice) <= 0) {
                message.error(e.materialName + "销售单价不能小于或等于0");
                flag = false;
                return
            }
        });
        if (flag) {
            form.validateFields().then((val) => {
                if (popupMode === "add") {
                    let data = {
                        ...val,
                        id: threeGuaranteeId.replace("-", ""),
                    }
                    if (getThreeGuaranteesList(mode) != null) {
                        setThreeGuaranteesList(mode, getThreeGuaranteesList(mode).concat(data));
                    }
                    else {
                        setThreeGuaranteesList(mode, [data]);
                    }
                }
                if (popupMode === 'edit') {
                    let newData = getThreeGuaranteesList(mode).map(item => {
                        if (item.id == val.id) {
                            return { ...item, ...val };
                        }
                        return item;
                    })
                    setThreeGuaranteesList(mode, newData);
                }
                setVisible(false)
            })
        }
    }

    const handleCancel = () => {
        setVisible('')
    }

    const fetchEntity = (value) => {
        setEntityData({
            ...entityData,
            fetching: true,
        })
        let data = {
            searchItem: {
                entityName: value,
            },
            pageNum: 1,
            pageSize: 10,
            orders: [
                {
                    column: 'createTime',
                    rule: 'DESC',
                },
            ],
        }
        getBussinessEntityList(data).then((res) => {
            let results = res.retData.list
            const dataMap = results.map((team) => ({
                text: team.entityName,
                value: team.id,
            }))
            setEntityData({
                data: dataMap,
                fetching: false,
            })
        })
    }
    //防抖
    const fetchEntitys = debounce(fetchEntity, 1000)

    const hanldePrice = async () => {
        const values = await form.validateFields()
        let materialAmount = 0,
            hourlyWage = 0,
            travelExpenses = 0,
            mealSupplement = 0
        if (values.materialAmount && /^[0-9]+(.[0-9]{0,2})?$/.test(values.materialAmount)) {
            materialAmount = +values.materialAmount
        }
        if (values.hourlyWage && /^[0-9]+(.[0-9]{0,2})?$/.test(values.hourlyWage)) {
            hourlyWage = +values.hourlyWage
        }
        if (values.travelExpenses && /^[0-9]+(.[0-9]{0,2})?$/.test(values.travelExpenses)) {
            travelExpenses = +values.travelExpenses
        }
        if (values.mealSupplement && /^[0-9]+(.[0-9]{0,2})?$/.test(values.mealSupplement)) {
            mealSupplement = +values.mealSupplement
        }
        let totalClaim = (materialAmount + hourlyWage + travelExpenses + mealSupplement).toFixed(2),
            totalHourlyWage = (hourlyWage + travelExpenses + mealSupplement).toFixed(2)
        form.setFieldsValue({
            materialAmount,
            hourlyWage,
            travelExpenses,
            mealSupplement,
            totalClaim,
            totalHourlyWage,
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    return (
        <Modal
            forceRender
            visible={visible}
            maskClosable={false}
            title="三包信息"
            centered={true}
            onOk={() => onSubmit()}
            onCancel={() => handleCancel()}
            width={1200}
        >
            <div className="form-details-warp">
                <Form
                    form={form}
                    disabled={getInfo(mode) && (getInfo(mode).prebookStatus == null || getInfo(mode).prebookStatus == 0 ? false : true)}
                    {...formItemLayout}
                >
                    <Form.Item hidden name="id">
                        <Input />
                    </Form.Item>
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                label="三包品牌"
                                name="threeGuaranteesBrand"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择三包品牌',
                                    },
                                ]}
                            >
                                <Select placeholder="三包品牌" style={{ width: '100%' }}>
                                    {getPcodeDict('106').children.map((item) => (
                                        <Option value={item.dictCode} key={item.id}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="所属实体" name="ciBusinessEntityId">
                                <Select
                                    allowClear
                                    placeholder="请选择所属实体"
                                    style={{ width: '100%' }}
                                    notFoundContent={entityData.fetching ? <Spin size="small" /> : null}
                                    optionFilterProp="children"
                                    showSearch
                                    onSearch={fetchEntitys}
                                    filterOption={false}
                                >
                                    {entityData.data.map((item) => (
                                        <Select.Option value={item.value} key={item.value}>
                                            {item.text}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="索赔单号"
                                name="claimNumber"
                                getValueFromEvent={(event) => {
                                    return event.target.value.replace(/[\u4E00-\u9FA5]/g, '')
                                }}
                            >
                                <Input placeholder="可自动生成索赔单号" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="结算单号" name="statementNumber">
                                <Input placeholder="结算单号" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="材料费"
                                name="materialAmount"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: '请输入数字且可保留两位小数',
                                    },
                                ]}
                            >
                                <Input placeholder="材料费" onBlur={hanldePrice} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="工时费"
                                name="hourlyWage"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: '请输入数字且可保留两位小数',
                                    },
                                ]}
                            >
                                <Input placeholder="工时费" onBlur={hanldePrice} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="外出费"
                                name="travelExpenses"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: '请输入数字且可保留两位小数',
                                    },
                                ]}
                            >
                                <Input placeholder="外出费" onBlur={hanldePrice} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="餐补费"
                                name="mealSupplement"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: '请输入数字且可保留两位小数',
                                    },
                                ]}
                            >
                                <Input placeholder="餐补费" onBlur={hanldePrice} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="其他费用"
                                name="otherAmount"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: '请输入数字且可保留两位小数',
                                    },
                                ]}
                            >
                                <Input placeholder="其他费用" onBlur={hanldePrice} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="出库金额" name="outboundAmount">
                                <Input placeholder="出库金额" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="索赔总额" name="totalClaim">
                                <Input placeholder="索赔总额" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="故障处理" name="faultHandle">
                                <Input placeholder="故障处理" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="工时费汇总" name="totalHourlyWage">
                                <Input placeholder="三包工时费汇总" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="维修时间" name="repairTime">
                                <Input placeholder="维修时间" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="维修人员" name="maintenanceStaff">
                                <Input placeholder="维修人员" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="工时定额"
                                name="workHour"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: '请输入数字且可保留两位小数',
                                    },
                                ]}
                            >
                                <Input placeholder="工时定额" />
                            </Form.Item>
                        </Col>

                        <Form.Item label="" name="ciBusinessEntityName" hidden>
                            <Input placeholder="" />
                        </Form.Item>
                    </Row>
                </Form>
                <Item2 maintenanceRef={maintenanceRef} mode={mode} popupMode={popupMode} threeGuaranteeId={threeGuaranteeId} />
                <Material2 materialRef={materialRef} mode={mode} popupMode={popupMode} threeGuaranteeId={threeGuaranteeId} />
            </div>
        </Modal>
    )
}
