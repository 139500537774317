import { post, get } from "../utils/request"

/**
 * 同步公安网，车管所
 */

//同步数据
function syncOrderBjsgl(data) {
    return post(`/ci/bjsgl/sync`, data)
}
//质保参数查询
function getBjsglQalInfo() {
    return get(`/ci/bjsgl/qal_info`)
}
//读取维修人员信息
function repairBjsglInfo(permitNo) {
    return get(`/ci/bjsgl/repair_main/${permitNo}`)
}
//查询上报时需要的订单信息
function getBisglOrderInfo(orderId) {
    return post(`/ci/bjsgl/query_order_info/${orderId}`)
}
export { syncOrderBjsgl, getBjsglQalInfo, repairBjsglInfo, getBisglOrderInfo }
