import { post, get } from "../utils/request"

//车辆列表
function getMoveCarList(data) {
    return post("/ci/vehicle/list", data)
}
//获取一条数据
function getMoveCarOne(id) {
    return get(`/ci/vehicle/one/${id}`)
}
//车辆新增
function addMoveCar(data) {
    return post("/ci/vehicle/insert", data)
}
//车辆编辑
function editMoveCar(data) {
    return post(`/ci/vehicle/update`, data)
}
//车辆禁用
function deleteMoveCar(data) {
    return post("/ci/vehicle/disable", data)
}
//启用禁用
function disableMoveCar(data) {
    return post(`/ci/vehicle/disable`, data)
}

export { getMoveCarList, disableMoveCar, addMoveCar, editMoveCar, getMoveCarOne, deleteMoveCar }
