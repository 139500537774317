import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import TopSearch from "@/components/TopSearch"
import { getPackageRuleList, deleteRuleOne } from "@/service/setMealService"
import { MyContext } from "@/store/myContext"
import CarTypeDrawer from "./carTypeDrawer" //详情弹框
import { getPcodeDict } from "@/utils/dataStorage"

//车型匹配
export default ({ modeKey }) => {
    const { dispatch, message } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [visible, setVisible] = useState({ visible: false, mode: "" }) //详情弹框
    const [dictValue, setDictValue] = useState(null) //多选框：从字典获取到的数据

    useEffect(() => {
        let obj = {
            "022001": {}, //适用品牌
            "022002": {}, //车型
            "022003": {}, //排放
            "022004": {}, //发动机型号
            "022005": {}, //发动机
            "022006": {}, //发动机
        }
        Object.keys(obj).forEach((code) => {
            getPcodeDict(code).children.forEach((item) => {
                obj[code][item.dictCode] = item.title
            })
        })
        setDictValue(obj)
        loadData(pagination.current)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //列表数据
    const loadData = (current = 1) => {
        let data = {
            searchItem: {},
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        getPackageRuleList(data)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                    setpagination({
                        current: current,
                        total: res.retData.total,
                        pageSize: 10,
                    })
                    setList(res.retData.list)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            .catch((err) => message.error(err.retMsg))
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }

    //删除
    const handledeleteOne = () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的维保套餐")
            return
        }
        deleteRuleOne(selectInfo.rowInfo.id)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    loadData()
                    message.success(`${selectInfo.rowInfo.ruleName}删除成功`)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            .catch((err) => message.error(err.retMsg))
    }

    //匹配字典值 返回title
    const returnValue = (text, parentCode) => {
        let arr = text.split(",")
        let newStr = dictValue
            ? arr.reduce((str, next) => {
                  str += `${dictValue[parentCode][next]}，`
                  return str
              }, "")
            : ""
        return newStr.substring(0, newStr.length - 1)
    }
    //table配置
    const columns = [
        {
            title: "规则名称",
            align: "center",
            dataIndex: "ruleName",
        },
        {
            title: "适用品牌",
            align: "center",
            dataIndex: "ruleBrand",
            render: (text) => text && returnValue(text, "022001"),
        },
        {
            title: "车型",
            align: "center",
            dataIndex: "ruleModel",
            render: (text) => text && returnValue(text, "022002"),
        },
        {
            title: "排放",
            align: "center",
            dataIndex: "ruleEmission",
            render: (text) => text && returnValue(text, "022003"),
        },
        {
            title: "机型",
            align: "center",
            dataIndex: "ruleEngineModel",
            render: (text) => text && returnValue(text, "022004"),
        },
        {
            title: "发动机",
            align: "center",
            dataIndex: "ruleEngine",
            render: (text) => text && returnValue(text, "022005"),
        },
        {
            title: "三包/收费",
            align: "center",
            dataIndex: "ruleThreeGuarantees",
            render: (text) => text && returnValue(text, "022006"),
        },
    ]
    //列表button
    const topButtons = [
        {
            click: () =>
                dispatch({
                    type: "removeTabs",
                    targetKey: modeKey,
                    activeMenu: "setMealManage",
                }),
            content: "返回",
        },
        {
            click: () => setVisible({ visible: true, mode: "insert" }),
            content: "新增",
        },
        {
            click: () => {
                if (!Object.keys(selectInfo.rowInfo).length) {
                    message.warning("请选择你要编辑的规则")
                    return
                }
                setVisible({ visible: true, mode: "edit" })
            },
            content: "编辑",
        },
        {
            click: () => handledeleteOne(),
            content: "删除",
        },
    ]
    return (
        <>
            <TopSearch title="车型匹配" />
            <ListComponent
                title="车型匹配列表"
                buttons={topButtons}
                columns={columns}
                dataSource={list}
                pagination={pagination}
                onTableChange={onTableChange}
                selectInfo={selectInfo}
                setSelectInfo={(obj) => setSelectInfo(obj)}
            />
            {visible.visible && (
                <CarTypeDrawer
                    visible={visible.visible}
                    mode={visible.mode}
                    onClose={() => setVisible({ visible: false, mode: "" })}
                    ruleId={selectInfo.rowInfo.id}
                    loadDataApi={() => loadData()}
                />
            )}
        </>
    )
}
