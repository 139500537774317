import { useState } from "react";
import { message } from "antd";
import { createModel } from "hox";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { stockCheckService } from "./stockCheckService";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";

export const useStockCheckModel = createModel(function () {
    const { user: { shopId, shopName, shopCode, orgName, orgCode, organType } } = useGlobalModel()
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState({ spinning: false, tip: "加载中..." });
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //分页参数
    const initPgCfg = { pageSize: 15, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //列表查询
    const loadData = async (page, searchValue = searchParam) => {
        setLoading({ spinning: true, tip: "列表加载中..." });
        let status = Array.isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status;
        const searchParams = {
            ...searchValue, status,
            shopId: searchValue.shopId ?? shopId,
            shopName, shopCode, orgName, orgCode,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
        }
        let { retData } = await stockCheckService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
        setLoading({ spinning: false });
    }
    //设置搜索value
    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    //导出
    const exportList = async (searchValue = searchParam) => {
        // setLoading({ tip: "文件导出中", spinning: true });
        let status = Array.isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status;
        const searchParams = {
            searchItem:{
                ...searchValue, status,
                shopId: searchValue.shopId ?? shopId,
                shopName, shopCode, orgName, orgCode,
                id: selectedRowKeys.join()
            }
        }
        let {retData} = await stockCheckService.export(searchParams);
        message.success(retData)
        // convertRes2Blob(result);
        // setLoading({ spinning: false });
        // return result;
    }
    //作废
    const invalidateData = async () => {
        let { retData } = await stockCheckService.invalidate({
            id: dataSource.find(item => item.id === selectedRowKeys[0])?.id,
            stocktakingCode: selectedRowKeys[0],
        });
        message.success("作废成功");
        loadData();
    }
    return {
        /***列表***/
        searchParam, organType, shopId,
        dataSource,
        selectedRowKeys,
        pgCfg,
        loading,
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        exportList,
        invalidateData
    }
});