import React, { useEffect, useState, useContext } from "react"
import { Button, Form, Input, Col, Row, message, Select, Card, Upload, Modal } from "antd"
import {
    getPartMaintenList,
    getDiagnoseSpokenList,
    diagnoseSpokenSave,
    diagnoseTermInsert,
    getDiagnoseTermOne,
    diagnoseTermEdit,
} from "@/service/aiDiagnoseService"
import BraftEditor from "braft-editor"
import { ContentUtils } from "braft-utils"
import "braft-editor/dist/index.css"
import { MyContext } from "@/store/myContext"
import { getToken } from "@/utils/dataStorage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"

//套餐详情
export default ({ modeKey, mode, rowInfo }) => {
    const { dispatch, Option } = useContext(MyContext)
    const [form] = Form.useForm()
    const [editorState1, setEditorState1] = useState(BraftEditor.createEditorState(null)) //富文本
    const [editorState2, setEditorState2] = useState(BraftEditor.createEditorState(null)) //富文本
    const [editorState3, setEditorState3] = useState(BraftEditor.createEditorState(null)) //富文本
    const [partList, setPartList] = useState([]) // 部件列表
    const [saveLoading, setSaveLoading] = useState(false) //保存按钮

    //编辑回显
    useEffect(() => {
        if (mode === "edit") {
            handlePart()
            handleSpoken()
            getDiagnoseTermOne(rowInfo.id).then((res) => {
                // if (res && res.retCode === 200) {
                let result = res.retData
                let spokenList = result.spokenItems
                    ? result.spokenItems.reduce((car, next) => {
                          let spokenId = next.split(",")[0]
                          car.push(spokenId)
                          return car
                      }, [])
                    : []
                form.setFieldsValue({
                    termName: result.termName,
                    componentId: result.componentId,
                    spokenList: spokenList,
                })
                result.possibleCauses && setEditorState1(BraftEditor.createEditorState(result.possibleCauses))
                result.troubleshootStep && setEditorState2(BraftEditor.createEditorState(result.troubleshootStep))
                result.solution && setEditorState3(BraftEditor.createEditorState(result.solution))
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    //获取模块列表
    const handlePart = () => {
        let data = {
            pageSize: 1000,
            pageNum: 1,
            searchItem: {},
        }
        getPartMaintenList(data).then((res) => {
            // if (res && res.retCode === 200) {
            setPartList(res.retData.list)
            // } else {
            //     message.warning(res.retMsg)
            // }
        })
        // .catch((err) => message.error(err.retMsg))
    }

    //口语多选
    const [secordText, setSecordText] = useState("")
    const [spokenList, setSpokenList] = useState([])
    const handleInsertSpoken = () => {
        let spokenName = secordText //记录口语名称
        let remark = "" //备注
        Modal.confirm({
            icon: null,
            title: "故障口语新增",
            content: (
                <>
                    <Input
                        placeholder="请输入口语名称"
                        defaultValue={spokenName}
                        onChange={(e) => {
                            spokenName = e.target.value
                        }}
                    />
                    ,
                    <Input
                        placeholder="备注"
                        onChange={(e) => {
                            remark = e.target.value
                        }}
                    />
                </>
            ),
            okText: "确定",
            cancelText: "取消",
            onOk: () => {
                return new Promise((resolve, reject) => {
                    if (spokenName) {
                        resolve(spokenName)
                    } else {
                        message.warning("请输入故障口语")
                        reject("请输入故障口语")
                    }
                }).then((res) => {
                    diagnoseSpokenSave({ spokenName, remark }).then((res) => {
                        // if (res && res.retCode === 200) {
                        let selectedSpoken = form.getFieldValue("spokenList")
                        handleSpoken()
                        if (selectedSpoken && Array.isArray(selectedSpoken)) {
                            selectedSpoken.push(res.retData.id)
                            form.setFieldsValue({
                                spokenList: selectedSpoken,
                            })
                        } else {
                            form.setFieldsValue({
                                spokenList: [res.retData.id],
                            })
                        }
                        message.success("新增成功")
                        // } else {
                        //     message.warning(res.retMsg)
                        // }
                    })
                    // .catch((err) => message.error(err.retMsg))
                })
            },
            onCancel: () => {
                setSecordText("")
            },
        })
    }

    const handleSpoken = () => {
        let data = {
            pageSize: 1000,
            pageNum: 1,
            searchItem: {},
        }
        getDiagnoseSpokenList(data).then((res) => {
            // if (res && res.retCode === 200) {
            let children = []
            res.retData.list.forEach((item) => {
                children.push(<Option key={item.id}>{item.spokenName}</Option>)
            })
            setSpokenList(children)
            // } else {
            //     message.warning(res.retMsg)
            // }
        })
        // .catch((err) => message.error(err.retMsg))
    }

    //富文本上传图片1
    const uploadHandler1 = (param) => {
        if (param.file.status === "done") {
            setEditorState1(
                ContentUtils.insertMedias(editorState1, [
                    {
                        type: "IMAGE",
                        url: `${fileLookUrl().image}${param?.file?.response?.retData}`,
                    },
                ])
            )
        }
    }
    const extendControls1 = [
        {
            key: "antd-uploader",
            type: "component",
            component: (
                <Upload
                    accept="image/*"
                    action={`${fileUploadUrl}upload/image_old`}
                    // action={`${fileUrl}/file/uploadFile`}
                    headers={{ authorization: getToken() }}
                    showUploadList={false}
                    onChange={uploadHandler1}
                >
                    {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
                    <button type="button" className="control-item button upload-button" data-title="插入图片">
                        {/* <Icon type="picture" theme="filled" /> */}上传图片
                    </button>
                </Upload>
            ),
        },
    ]
    //富文本上传图片2
    const uploadHandler2 = (param) => {
        if (param.file.status === "done") {
            setEditorState2(
                ContentUtils.insertMedias(editorState2, [
                    {
                        type: "IMAGE",
                        url: `${fileLookUrl().image}${param?.file?.response?.retData}`,
                    },
                ])
            )
        }
    }
    const extendControls2 = [
        {
            key: "antd-uploader",
            type: "component",
            component: (
                <Upload
                    accept="image/*"
                    action={`${fileUploadUrl}upload/image_old`}
                    headers={{ authorization: getToken() }}
                    showUploadList={false}
                    onChange={uploadHandler2}
                >
                    {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
                    <button type="button" className="control-item button upload-button" data-title="插入图片">
                        {/* <Icon type="picture" theme="filled" /> */}上传图片
                    </button>
                </Upload>
            ),
        },
    ]
    //富文本上传图片3
    const uploadHandler3 = (param) => {
        if (param.file.status === "done") {
            setEditorState3(
                ContentUtils.insertMedias(editorState3, [
                    {
                        type: "IMAGE",
                        url: `${fileLookUrl().image}${param?.file?.response?.retData}`,
                    },
                ])
            )
        }
    }
    const extendControls3 = [
        {
            key: "antd-uploader",
            type: "component",
            component: (
                <Upload
                    accept="image/*"
                    action={`${fileUploadUrl}upload/image_old`}
                    headers={{ authorization: getToken() }}
                    showUploadList={false}
                    onChange={uploadHandler3}
                >
                    {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
                    <button type="button" className="control-item button upload-button" data-title="插入图片">
                        {/* <Icon type="picture" theme="filled" /> */}上传图片
                    </button>
                </Upload>
            ),
        },
    ]
    //富文本配置
    const controls = [
        "undo",
        "redo",
        "separator",
        "font-size",
        "line-height",
        "letter-spacing",
        "separator",
        "text-color",
        "bold",
        "italic",
        "underline",
        "strike-through",
        "separator",
        "superscript",
        "subscript",
        "remove-styles",
        "emoji",
        "separator",
        "text-indent",
        "text-align",
        "separator",
        "headings",
        "list-ul",
        "list-ol",
        "blockquote",
        "code",
        "separator",
        "link",
        "separator",
        "hr",
        "separator",
        // 'media', 'separator',
        "clear",
    ]

    //表单提交
    const onsubmit = () => {
        setSaveLoading(true)
        form.validateFields()
            .then((res) => {
                let data = {
                    ...res,
                    possibleCauses: editorState1.toHTML(),
                    troubleshootStep: editorState2.toHTML(),
                    solution: editorState3.toHTML(),
                }
                if (mode === "insert") {
                    diagnoseTermInsert(data)
                        .then((res) => {
                            // if (res && res.retCode === 200) {
                            handleCancel()
                            message.success("新增成功")
                            // } else {
                            //     message.warning(res.retMsg)
                            //     setSaveLoading(false)
                            // }
                        })
                        .catch((err) => {
                            // message.error(err.retMsg)
                            setSaveLoading(false)
                        })
                } else if (mode === "edit") {
                    diagnoseTermEdit({ id: rowInfo.id, ...data })
                        .then((res) => {
                            // if (res && res.retCode === 200) {
                            handleCancel()
                            message.success("编辑成功")
                            // } else {
                            //     message.warning(res.retMsg)
                            //     setSaveLoading(false)
                            // }
                        })
                        .catch((err) => {
                            // message.error(err.retMsg)
                            setSaveLoading(false)
                        })
                }
            })
            .catch((err) => {
                message.warning("请检查必填项")
                setSaveLoading(false)
            })
    }

    //取消
    const handleCancel = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "aiDiagnose",
        })
    }
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    return (
        <>
            <div className="toolbar">
                <span className="modules-name">智能诊断</span>
            </div>
            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>故障术语详情</span>
                    </div>
                    <div className="list-but">
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={onsubmit} loading={saveLoading}>
                            保存
                        </Button>
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={handleCancel}>
                            取消
                        </Button>
                    </div>
                </div>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row>
                            <Col span={6} xxl={4}>
                                <Form.Item
                                    label="故障名称"
                                    name="termName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入故障名称",
                                        },
                                    ]}
                                >
                                    <Input placeholder="故障名称" />
                                </Form.Item>
                            </Col>
                            <Col span={6} xxl={4}>
                                <Form.Item
                                    label="所属部件"
                                    name="componentId"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择所属部件",
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="请选所属部件"
                                        optionFilterProp="children"
                                        onFocus={() => handlePart()}
                                        style={{ width: "100%" }}
                                    >
                                        {partList.map((item) => (
                                            <Option value={item.id} key={item.id}>
                                                {item.componentName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12} xxl={8}>
                                <Form.Item
                                    label={
                                        <span
                                            style={{
                                                color: "#00f",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => handleInsertSpoken()}
                                        >
                                            故障口语描述
                                        </span>
                                    }
                                    name="spokenList"
                                    labelCol={{ span: 5 }}
                                    wrapperCol={{ span: 19 }}
                                >
                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        optionFilterProp="children"
                                        notFoundContent={
                                            <p
                                                style={{
                                                    textAlign: "center",
                                                    color: "#00f",
                                                }}
                                                onClick={() => handleInsertSpoken()}
                                            >
                                                没有该口语，可点击左侧名称或本行文字新增
                                            </p>
                                        }
                                        placeholder="请选择故障口语"
                                        onFocus={() => handleSpoken()}
                                        onSearch={(val) => setSecordText(val)}
                                    >
                                        {spokenList}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Card title="可能原因:" size="small">
                                    <BraftEditor
                                        contentStyle={{ height: 200 }}
                                        value={editorState1}
                                        controls={controls}
                                        onChange={(e) => setEditorState1(e)}
                                        extendControls={extendControls1}
                                    />
                                </Card>
                            </Col>
                            <Col span={24} style={{ marginTop: "10px" }}>
                                <Card title="排查步骤:" size="small">
                                    <BraftEditor
                                        contentStyle={{ height: 200 }}
                                        value={editorState2}
                                        controls={controls}
                                        onChange={(e) => setEditorState2(e)}
                                        extendControls={extendControls2}
                                    />
                                </Card>
                            </Col>
                            <Col span={24} style={{ marginTop: "10px" }}>
                                <Card title="维修指南:" size="small">
                                    <BraftEditor
                                        contentStyle={{ height: 200 }}
                                        value={editorState3}
                                        controls={controls}
                                        onChange={(e) => setEditorState3(e)}
                                        extendControls={extendControls3}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}
