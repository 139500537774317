import React, { useContext } from "react";
import { Button, message, Modal } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { usePurchaseDrModel } from "../purchaseDrModel";
import { MyContext } from "@/store/myContext";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum";
import { formatNum } from '@/erp_subpackage/utils/util';

export const DataBox = () => {
    const { selectedRowKeys, statistics, searchParam, exportPurchaseDr, exportPDrdetail,rowsDescTotal } = usePurchaseDrModel();
    const { state, dispatch, detailsPage } = useContext(MyContext);

    //新增 查看 编辑
    const goPageNav = (mode, rowCode) => {
        let tabName = {
            add: "调入单新增",
            edit: "调入单编辑",
            look: "调入单查看",
        }
        let modeKey = "purchaseDr/" + mode;

        //动态传入要删除的nav key
        const handleDispatch = (obj = {}) => {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.PurchaseDrDetails({
                    modeKey,
                    mode,
                    dispatch,
                    rowCode: rowCode ?? selectedRowKeys[0]?.code
                }),
            })
        }

        if (mode === "edit") {
            const findNav = state.panes.find(item => item.key === modeKey);
            if (findNav) {
                Modal.confirm({
                    title: '温馨提醒！',
                    content: "您还有调入单详情未保存，是否跳转",
                    okText: '跳转未保存页',
                    cancelText: '打开新页签',
                    onOk: () => {
                        handleDispatch()
                    },
                    //打开新页签重新生成
                    onCancel: () => {
                        handleDispatch({ deleteKey: modeKey })
                    }
                });
                return;
            }
            handleDispatch()
        }
        else if (mode === "add") {
            handleDispatch()
        }
        else if (mode === "look") {
            handleDispatch({ deleteKey: modeKey })
        }
    }

    const action = <>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                goPageNav("add")
            }}
        >新增</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (selectedRowKeys.length !== 1) {
                    message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！")
                    return;
                }
                if (selectedRowKeys[0].status === DictCodeEnmu.PO_CHANGE) {
                    message.warning("已作废的单据不可以进行编辑");
                    return;
                }

                goPageNav("edit")
            }}
        // disabled={searchParam.shopId === "" || (searchParam.shopId && searchParam.shopId !== shopId)}
        >编辑</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => exportPurchaseDr()}
        >导出</Button>
        {/* <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                // if (selectedRowKeys.length !== 1) {
                //     message.warning("请选择单条数据进行明细导出，点击鼠标右键可一键选中哦！")
                //     return;
                // }
                // exportPDrdetail(selectedRowKeys[0].code)
                exportPDrdetail()
            }}
        >导出明细</Button> */}
    </>;

    const desc = [
        {
            label: "调入数量",
            value: !selectedRowKeys.length ? statistics?.totalNum: rowsDescTotal.totalNum,
            color: "red"
        },
        {
            label: "应付金额",
            value: !selectedRowKeys.length ? `￥${formatNum(statistics?.totalAmount)}`: `￥${formatNum(rowsDescTotal.totalAmount)}`,
            color: "red"
        },
        // {
        //     label: "已付金额",
        //     value: `￥${statistics.paidAmount}`,
        //     color: "red"
        // }
    ]

    return (
        <YhBox title="调入单列表" descriptions={desc} action={action}>
            <DataTable goPageNav={goPageNav} />
        </YhBox>
    )
}