import React, { useEffect, useState, useRef } from "react"
import { Form, Input, Col, Row, Select, message, Modal } from "antd"
import { vehicleAttrTemplateInsert, vehicleAttrTemplateUpdate, vehicleAttrTemplateOne } from "@/service/dataService"
import { organPage } from "@/service/orgService"
import Details from "./details"
import {  defFlagObj, templateTypeObj } from "./emun"
export default (props) => {
    const { mode, rowInfo, visible, setVisible, onSuccess, } = props
    const [form] = Form.useForm()
    const [org, setOrg] = useState([])
    const [defaultData, setDefaultData] = useState({})
    const childRef = useRef(null);

    

    useEffect(() => {
        queryMotorcade()
        organPage({
            searchItem: { orderFlag: 0, showPlatformOrgan: true },
            pageNum: 1,
            pageSize: 100,
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            setOrg(res.retData.list)
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            vehicleAttrTemplateOne(rowInfo.id).then((res) => {
                let results = res.retData
                form.setFieldsValue({
                    ...results,
                })
                setDefaultData(results)
            })
        }
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let details = childRef.current.getData()
            if(!details){
                return
            }
            let data = {
                ...values,
                details
            }

            if (mode === "edit") {
                data.id = rowInfo.id
                vehicleAttrTemplateUpdate(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                vehicleAttrTemplateInsert(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleCancel = () => {
        setVisible("")
    }

    return (
        <>
            <Modal forceRender visible={visible} title="车辆附件模版" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={1200}>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={6}>
                                <Form.Item label="模板名称" name="templateName" rules={[{ required: true, message: "请输入模板名称" }]}>
                                    <Input placeholder="模板名称" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="模板类型" name="templateType" rules={[{ required: true, message: "请选择模板类型" }]}>
                                    <Select placeholder="请选择模板类型" style={{ width: "100%" }}>
                                        {Object.entries(templateTypeObj).map((item) => (
                                            <Select.Option value={item[0]} key={item[0]}>
                                                {item[1]}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="模版标记" name="defFlag">
                                    <Select placeholder="请选择模版标记" style={{ width: "100%" }}>
                                        {Object.entries(defFlagObj).map((item) => (
                                            <Select.Option value={Number(item[0])} key={Number(item[0])}>
                                                {item[1]}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="机构" name="organId">
                                    <Select showSearch allowClear placeholder="请选择机构" style={{ width: "100%" }}>
                                        {org.map((item) => (
                                            <Select.Option value={item.id} key={item.id}>
                                                {item.organName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="描述信息" name="templateDesc" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
                                    <Input placeholder="描述信息" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="备注" name="remark" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
                                    <Input placeholder="备注" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Details defaultData={defaultData.details || []}  ref={childRef}/>
                </div>
            </Modal>
        </>
    )
}
