import React, { useState, useEffect } from "react"
import { Modal, message } from "antd"
import { customerPage } from "@/service/statistics"
import ListComponent from "@/components/listComponent"
import { download, convertRes2Blob } from "@/utils/exportExcel"
import Loading from "@/components/Loading"
const CustomerList = (props) => {
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const { analyzeType, setAnalyzeType } = props
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [loading, setLoading] = useState(false) //表格 loading
    const [data, setData] = useState([]) //默认展示数据
    useEffect(() => {
        if (analyzeType) {
            loadData()
        }
    }, [analyzeType])
    //分页事件 置空当前工单
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }
    const handleExport = async() => {
        setExportLoading(true)
        let data = {
            searchItem: {
                analyzeType,
            },
            pageNum: pagination.current,
            pageSize: 10,
            orders: [
                {
                    // column: "updateTime", rule: "DESC"
                },
            ],
        }
        let resFlie = await download("/ci-customer/analyze/customer/statistics/export", {data})
        if (resFlie && resFlie.status === 200) {
            message.success("已导出")
            convertRes2Blob(resFlie)
        } else {
            message.warning("导出异常，请重新尝试")
        }
        setExportLoading(false)
    }
    //默认加载数据列表
    const loadData = (current, search = {}) => {
        setLoading(true)
        let data = {
            searchItem: {
                analyzeType,
            },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    // column: "updateTime", rule: "DESC"
                },
            ],
        }
        customerPage(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setData(res.retData.list)
            setLoading(false)
        })
    }

    //团队列表table配置
    const columns = [
        {
            title: "客户名称",
            align: "center",
            dataIndex: "customerName",
        },
        {
            title: "客户类型",
            align: "center",
            dataIndex: "customerType",
        },
        {
            title: "客户联系人",
            align: "center",
            dataIndex: "customerCorporation",
            with: 200,
        },
        {
            title: "客户联系人电话",
            align: "center",
            dataIndex: "customerCorporationPhone",
            with: 200,
        },
        {
            title: "客户地址",
            align: "center",
            dataIndex: "customerAddress",
            with: 200,
        },
        {
            title: "所属机构",
            align: "center",
            dataIndex: "organName",
        },
        {
            title: "车辆总数",
            align: "center",
            dataIndex: "vehicleNum",
        },
        {
            title: "工单总数",
            align: "center",
            dataIndex: "orderTotalNum",
        },

        {
            title: "三包工单",
            align: "center",
            dataIndex: "threeGuaranteesOrderNum",
        },

        {
            title: "收费工单",
            align: "center",
            dataIndex: "chargeOrderNum",
        },
        {
            title: "最新下单时间",
            align: "center",
            dataIndex: "latestOrderTime",
        },
        {
            title: "上线时间",
            align: "center",
            dataIndex: "onlineTime",
        },
    ]

    return (
        <Modal visible={analyzeType} title="数据统计" centered={true} width={1360} okText="导出" onCancel={() => {setAnalyzeType("")}} onOk={() => handleExport()}>
            <ListComponent loading={loading} onTableChange={onTableChange} columns={columns} dataSource={data} pagination={pagination} scroll={{ x: 1200 }} />
            {
                        exportLoading && <Loading />
                    }
        </Modal>
    )
}
export default CustomerList
