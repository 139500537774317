import React, { useEffect } from "react";
import { Table } from "antd";
import { useInternalModel } from "../internalModel";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";

export const internalStatusOption = [
    { label: "待申请", value: 0 },
    { label: "待对方确认", value: 1 },
    { label: "对方不认可", value: 2 },
    { label: "待财务确认", value: 3 },
    { label: "对方已驳回", value: 4 },
    { label: "待财务审核", value: 5 },
    { label: "财务已驳回", value: 6 },
    { label: "待最终审核", value: 7 },
    { label: "最终审核通过", value: 8 },
    { label: "最终已驳回", value: 9 },
]

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useInternalModel();

    useEffect(() => {
        loadData()
    }, [])

    const columns = [
        {
            title: '序号',
            width: 40,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '对账单号',
            width: 180,
            dataIndex: 'code',
            fixed: "left",
        }, {
            title: '对账金额',
            width: 120,
            dataIndex: 'writeoffAmount',
            fixed: "left",
        }, {
            title: '状态',
            width: 120,
            dataIndex: 'status',
            render: (text) => internalStatusOption.find(item => item.value === text)?.label ?? text
        }, {
            title: '交易机构',
            width: 160,
            dataIndex: 'adverseUnitName',
        }, {
            title: '申请人',
            width: 120,
            dataIndex: 'createUser',
        }, {
            title: '申请时间',
            width: 120,
            dataIndex: 'createTime',
        }, {
            title: '申请说明',
            width: 120,
            dataIndex: 'note',
        }, {
            title: '对方确认人',
            width: 120,
            dataIndex: 'adverseAffirmUser',
        }, {
            title: '对方确认时间',
            width: 120,
            dataIndex: 'adverseAffirmTime',
        }, {
            title: '财务确认人',
            width: 120,
            dataIndex: 'financeAffirmUser',
        }, {
            title: '财务确认时间',
            width: 120,
            dataIndex: 'financeAffirmTime',
        }, {
            title: '财务审核人',
            width: 120,
            dataIndex: 'financeAuditUser',
        }, {
            title: '最终审核人',
            width: 120,
            dataIndex: 'endAuditUser',
        }, {
            title: '最终审核时间',
            width: 120,
            dataIndex: 'endAuditTime',
        }
    ];

    //点击行选高亮
    const onRow = (code) => {
        if (selectedRowKeys.length > 0 && selectedRowKeys[0] === code) {
            setSelectedRowKeys([]);
        } else {
            setSelectedRowKeys([code]);
        }
    };

    return (
        <Table
            rowKey={record => record.code}
            rowSelection={{
                //全部选中时  (存string[])
                type: "radio",
                onSelect: (record) => onRow(record.code),
                selectedRowKeys: selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.code),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.code]);
                }
            })}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}
