import React, { useEffect, useState } from "react";
import { Table, Drawer, Form, Input, Row, Col, Button, message, Spin } from "antd";
import { useContractModel } from '../contractParticularsModel'
import { usecontractDetailsModel } from './detailsModel'
import { EditableRow, EditableCell } from "@/erp_subpackage/components/YHEditTable/editTable";
import { contractService } from '../contractParticularsServer'
import { throttle } from "lodash";

export const CreateBillDrawer = ({ mode, rowCode }) => {
    const { searchParam, setSearchParam, newfilterData, mileageOrrder, createBill, setCreateBill, createvisible, setCreatevisible, setNewfilterData, getdedtailsOne } = usecontractDetailsModel()
    const [upLoading, setUpLoading] = useState(false)

    //车辆列表上查询
    const handleSearch = (value) => {
        // 进行模糊检索
        const filteredResults = newfilterData.filter((item) =>
            item.vehicleCode.includes(value)
        );
        setCreateBill(filteredResults);

    }

    const throttlefn = throttle(() => {
        onsubmit();
    }, 10000);

    const onsubmit = () => {
        setUpLoading(true);
        if (createBill.length === 1) {
            let data = {
                signId: rowCode,
                vehicleId: createBill[0].vehicleId,
                billPrice: createBill[0].billPrice,
                mileageCurrent: createBill[0].mileageCurrent,
                signMileage: createBill[0].signMileage
            }
            contractService.createBill(data).then(res => {
                message.success('修改成功')
                getdedtailsOne(rowCode)
                setCreatevisible(false)
                setUpLoading(false);
            })
        } else {
            const filteredData = createBill.map(({ vehicleId, billPrice, mileageCurrent, signMileage }) => ({ vehicleId, billPrice, mileageCurrent, signId: rowCode, signMileage }));
            contractService.createBilladd(filteredData).then(res => {
                message.success('修改成功')
                getdedtailsOne(rowCode)
                setCreatevisible(false)
                setUpLoading(false);
            })
        }
    }

    const columns = [
        {
            title: '车辆牌照',
            width: 80,
            dataIndex: 'vehicleCode',
            fixed: "left",
        },

        {
            title: '上个月账单里程',
            width: 100,
            dataIndex: 'mileageLast',

        },
        {
            title: '签约里程',
            width: 100,
            dataIndex: 'signMileage',
            editable: true
        },
        {
            title: '当前里程',
            width: 100,
            dataIndex: 'mileageCurrent',
            editable: true
        },
        {
            title: '计费价格',
            width: 100,
            dataIndex: 'billPrice',
            editable: true,
            render: (text) => parseFloat(text).toFixed(3)


        },
        {
            title: '账单金额',
            width: 100,
            dataIndex: 'totalAmount',

        },
    ]

    const onCancel = () => {
        setCreatevisible(false)
    }
    const newSelectColumns = columns.map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                editType: col?.editType,
                editable: col?.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑   (计算价格)
    const handleSave = (row) => {
        const updatedsignMileage = Number(row.mileageCurrent).toFixed(2);
        const updatebillPrice = Number(row.billPrice).toFixed(3);
        const updatesignMileage = Number(row.signMileage).toFixed(2);
        let newtotalAmount = 0
        //计算账单金额
        if (row.mileageLast !== 0) {
            newtotalAmount = ((row.mileageCurrent - row.mileageLast) * row.billPrice).toFixed(2)
        } else {
            newtotalAmount = (row.signMileage * row.billPrice).toFixed(2)
        }
        // 在这里处理保存逻辑，将修改后的值存储到列表中的相应位置
        const updatedList = createBill.map(item => {
            if (item.vehicleId === row.vehicleId) {
                return {
                    ...item,
                    mileageCurrent: updatedsignMileage,
                    billPrice: updatebillPrice,
                    signMileage: updatesignMileage,
                    totalAmount: newtotalAmount
                };
            }
            return item;
        });
        setCreateBill(updatedList)
    }
    return (
        <Drawer
            title={<>
                <span>生成账单</span>
                {/* <span style={{float:"right"}}>{vehicleData.length}/{choose}</span> */}
            </>}
            open={createvisible}
            onClose={onCancel}
            width={700}
            maskClosable={false}
        >
            <div>
                <Row>
                    <Col span={10}>
                        <Form >
                            <Form.Item >
                                <Input allowClear placeholder="请输入车辆牌照回车检索" shape="round" value={searchParam} onChange={(e) => { setSearchParam(e.target.value); handleSearch(e.target.value) }} />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
            <div style={{ marginTop: "10px" }}>
                <Table
                    rowKey={record => record.vehicleId}
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        },
                    }}
                    columns={newSelectColumns}
                    dataSource={createBill}
                    scroll={{ y: 600 }}
                />
            </div>
            <Spin spinning={upLoading} tip="快速生成中">
                <div style={{ display: 'flex', justifyContent: 'center', gap: '30px', marginTop: '30px' }}>
                    <Button type="primary" shape="round" onClick={() => { throttlefn() }}>保存</Button>
                    <Button type="primary" shape="round" onClick={() => { onCancel() }}>返回</Button>
                </div>
            </Spin>
        </Drawer >

    )
}