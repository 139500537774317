import React, { useEffect, useState, useContext } from "react";
import { Table, Button } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import { usecontractDetailsModel } from './detailsModel'
import { formatNum } from '@/erp_subpackage/utils/util';
import { MyContext } from "@/store/myContext";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import { BillDetailTable } from './detailModal'
import { DetaillModal } from './detailorderModal'
import { useContractModel } from '../contractParticularsModel'


export const MileorderTable = ({ mode, rowCode }) => {
    const {
        resetSearchParamom, setVisible, setDetailVisible, openOrder, loading, searchParamo, updateSearchParamo, resetSearchParamo, mileageOrderdetaile, onExportMeilage,
        descTotal, billmileageData, mileageOrrder, addFormVal, lookFormVal, editFormVal, billData, contractPage, onExportOrder
    } = usecontractDetailsModel()
    const formval = mode === 'edit' ? editFormVal : mode === 'look' ? lookFormVal : addFormVal
    const { state, dispatch, detailsPage } = useContext(MyContext)
    const { setOpen } = useContractModel()
    const goPageNavm = (mode, billCode) => {
        let modeKey = "MileageOneTable/"
        //动态传入要删除的nav key
        const handleDispatch = (obj = {}) => {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: '对账单里程明细',
                activeMenu: modeKey,
                content: detailsPage.MileageOneTable({
                    modeKey,
                    mode,
                    dispatch,
                    billCode: billCode
                }),
            })
        }
        handleDispatch()

    }

    // const goPageNav = (mode,billCode) => {
    //     let modeKey = "BillTable/" 
    //     //动态传入要删除的nav key
    //     const handleDispatch = (obj = {}) => {
    //         dispatch({
    //             ...obj,
    //             type: "changeTabs",
    //             name: '对账单明细',
    //             activeMenu: modeKey,
    //             content: detailsPage.BillTable({
    //                 modeKey,
    //                 mode,
    //                 dispatch,
    //                 billCode:billCode
    //             }),
    //         })
    //     }
    //         handleDispatch()
    // }



    const column = [
        {
            title: '序号',
            width: 40,
            render: (t, r, i) => `${i + 1}`,
            fixed: 'left'
        },
        {
            title: '对账单单号',
            width: 120,
            dataIndex: 'billCode',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => {

                setDetailVisible(true); openOrder(record.billCode)
            }
            }
            />,
        },
        {
            title: '维修单数',
            width: 80,
            dataIndex: 'orderNum',
        },
        {
            title: '费用金额',
            width: 80,
            dataIndex: 'totalAmount',

            render: (text) => parseFloat(text).toFixed(2)
        },
        {
            title: '已收金额',
            width: 80,
            dataIndex: 'paidAmount',
            render: (text) => parseFloat(text).toFixed(2),

        },
        {
            title: '应收金额',
            width: 80,
            dataIndex: 'payableAmount',

            render: (text) => parseFloat(text).toFixed(2)
        },
        {
            title: '出账日期',
            width: 160,
            dataIndex: 'billStartTime',

        },
        {
            title: '出账范围',
            width: 220,
            dataIndex: 'billEndTime',

            render: (text, record) => { return text = record.billStartTime + '—' + record.billEndTime }
        },

    ];
    const columns = [
        {
            title: '序号',
            width: 40,
            render: (t, r, i) => `${i + 1}`,
            fixed: 'left'
        },
        {
            title: '对账单单号',
            width: 120,
            dataIndex: 'billCode',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => {

                setVisible(true); mileageOrderdetaile(record.id)
            }
            }
            />,
        },
        {
            title: '费用金额',
            width: 80,
            dataIndex: 'totalAmount',

            render: (text) => parseFloat(text).toFixed(2)
        },
        {
            title: '已收金额',
            width: 80,
            dataIndex: 'paidAmount',
            render: (text) => parseFloat(text).toFixed(2)

        },
        {
            title: '应收金额',
            width: 80,
            dataIndex: 'payableAmount',

            render: (text) => parseFloat(text).toFixed(2)
        },
        {
            title: '出账日期',
            width: 160,
            dataIndex: 'createTime',

        },
        {
            title: '出账范围',
            width: 220,
            dataIndex: 'billEndTime',

            render: (text, record) => { return text = record.billStartTime + '—' + record.billEndTime }
        },

    ];
    const searchFields = [
        {
            type: "Input",
            placeholder: "对账单号",
            fieldName: "billCode",
        },

        {
            type: "Datepicker",
            placeholder: "出账日期",
            fieldName: "billDate"
        },
        // {
        //     type: "Input",
        //     placeholder: "单据编号",
        //     fieldName: "orderCode"
        // },
    ]
    const searchFields2 = [
        {
            type: "Input",
            placeholder: "对账单号",
            fieldName: "billCode",
        },

        {
            type: "Datepicker",
            placeholder: "出账日期",
            fieldName: "billDate"
        },
        {
            type: "Input",
            placeholder: "单据编号",
            fieldName: "orderCode"
        },
    ]

    const desc = [
        {
            label: "费用金额",
            value: "￥" + (descTotal.totalAmount ?? 0).toFixed(2),
            color: "red"
        },
        {
            label: "已收金额",
            value: "￥" + (descTotal.paidAmount ?? 0).toFixed(2),
            color: "red"
        },
        {
            label: "  应收金额",
            value: "￥" + (descTotal.payableAmount ?? 0).toFixed(2),
            color: "red"
        }

    ]

    return (
        <>

            {formval.billMethod === '048001' &&
                <YhBox descriptions={desc}>
                    <div style={{ marginLeft: '28px', marginTop: '-44px' }}>
                        <YhPageHeader
                            size="small"
                            justify="end"
                            triggerSearch
                            searchValues={searchParamo}
                            searchFields={searchFields}
                            onChange={updateSearchParamo}
                            handleSearch={(params) => {
                                mileageOrrder({ pageNum: 1 }, { ...searchParamo, ...params })
                            }}
                            handleReset={resetSearchParamom}
                        />

                    </div>
                    <Table
                        rowKey={record => record.id}
                        columns={columns}
                        loading={loading}
                        scroll={{ x: 'max-content' }}
                        dataSource={mode === 'add' ? [] : billmileageData}
                    />

                </YhBox>}
            {formval.billMethod !== '048001' && <YhBox descriptions={desc}>
                <div style={{ marginLeft: '28px', marginTop: '-44px' }}>
                    <YhPageHeader
                        size="small"
                        justify="end"
                        triggerSearch
                        searchValues={searchParamo}
                        searchFields={searchFields2}
                        onChange={updateSearchParamo}
                        handleSearch={(params) => {
                            contractPage({ pageNum: 1 }, { ...searchParamo, ...params })
                        }}
                        handleReset={resetSearchParamo}
                    />

                </div>
                <Table
                    rowKey={record => record.id}
                    columns={column}
                    loading={loading}
                    scroll={{ x: 'max-content' }}
                    dataSource={mode === 'add' ? [] : billData}
                />



            </YhBox>}
            <BillDetailTable></BillDetailTable>
            <DetaillModal />
        </>

    )
}