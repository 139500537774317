import React, { useContext } from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { MyContext } from "@/store/myContext";
import { DetailDialog } from "./detailDialog";

export default () => {
    const { state } = useContext(MyContext);

    return (
        <>
            {state.activeMenu === "paperQuestion" && <>
                <PageHeader />
                <DataBox />
                <DetailDialog />
            </>}
        </>
    )
}