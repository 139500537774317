import React, { useRef, useEffect, useState } from 'react'
import { message } from 'antd'
import YhDrawer from '@/erp_subpackage/components/YhDrawer'
import { YhForm } from '@/erp_subpackage/components/YhFormBox'
import { useSaleRoDetailModel } from './saleRoDetailModel'
import { DictCodeEnmu } from '@/erp_subpackage/utils/DictCodeEnum'
import { useDictModel } from '@/erp_subpackage/pages/base/dict/dictModel'
import { ISettleTypeEnum, IPayMethodDs, IPayMethodCreditDs } from '@/erp_subpackage/utils/ErpEnum'
import { formatNum } from '@/erp_subpackage/utils/util'
import { useGlobalModel } from '@/erp_subpackage/model/globalModel'
import { saleRoService } from '../../saleRoService'
import { debounce } from 'lodash'
import { IsFranchisees } from '@/utils/common'
import { getCustomerListNew } from '@/service/customerService'
import { IVRMSettleTypeEnum } from '@/erp_subpackage/utils/enum'

//结算
export const SettleDrawer = ({ modeKey, mode, rowId, dispatch }) => {
    const {
        settleVisible: visible,
        detailAddForm,
        setDetailLookForm,
        setDetailEditForm,
        detailEditForm,
        setDetailAddForm,
        detailLookForm,
        lookSettleDetails,
        setSettleVisible,
        onSettle,
        getDetailEditOne,
    } = useSaleRoDetailModel()
    const { getSelectedDictOne, getTreeNodeOne } = useDictModel()
    const {
        user: { userName, shopCode, shopName,shopId },
    } = useGlobalModel()
    const formRef = useRef()
    const typeForm = {
        add: detailAddForm,
        edit: detailEditForm,
        look: detailLookForm,
    }
    const setTypeForm = {
        add: setDetailAddForm,
        edit: setDetailEditForm,
        look: setDetailLookForm,
    }
    const [cash, setCash] = useState(false)
    // const formVal = mode === 'add' ? detailAddForm : detailEditForm
    const formVal = typeForm[mode]
    const [settleType, setSettleType] = useState(ISettleTypeEnum.SETTLE_CASH) //结算类型，  默认现金结账
    const [payMethodDs, setPayMethodDs] = useState(getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE)) // 支付方式
    const [accountTypeDs, setAccountTypeDs] = useState([]) //实时存取收款账户类型值
    const [customerCode, setCustomerCode] = useState('')

    const initDataSource = {
        key: new Date().getTime().toString(),
        payMethodCode: '',
        payMethodName: '',
        available: 0,
        amount: 0,
        payee: '',
        fmsNote: '',
    }
    const [payDataSource, setPayDataSource] = useState([]) //表格默认值

    useEffect(() => {
        if (visible) {
            formRef.current.setFieldsValue({ ...typeForm[mode], note: '' })
            //(mode === "edit" ? [{ ...initDataSource, amount: detailEditForm.receivedAmount, }] : lookSettleDetails)
            setPayDataSource(
                mode === 'edit'
                    ? [
                          {
                              ...initDataSource,
                              payMethodCode: typeForm[mode].paymentMethodCode,
                              amount: detailEditForm.receivedAmount,
                              payMethodName: typeForm[mode].paymentMethodName,
                          },
                      ]
                    : lookSettleDetails
            )
            setSettleType(typeForm[mode].payType)
            setPayMethodOptions(typeForm[mode].payType)
            if (typeForm[mode].payType === DictCodeEnmu.SETTLE_TYPE_CASH) {
                setPayMethodDs(IPayMethodDs)
            } else if (typeForm[mode].payType === DictCodeEnmu.SETTLE_TYPE_CREDIT) {
                setPayMethodDs(IPayMethodCreditDs)
            }

            let { settlementPartyCode } = typeForm[mode]
            if (settlementPartyCode === DictCodeEnmu.SETTLE_PARTY_INTERNAL) {
                //这里为部门内部结算时，查询销售实体为客户的接口换取id
                const searchData = {
                    pageSize: 15,
                    pageNum: 1,
                    orders: [
                        {
                            column: 'createTime',
                            rule: 'DESC',
                        },
                    ],
                    shopId: shopId,
                    searchItem: { customerNameEq: shopName },
                    customerStatus: 0,
                }
                getCustomerListNew(searchData).then((res) => {
                    // let { records } = res.retData
                    const {
                        retData: { list: records },
                    } = res
                    if (records) {
                        let { customerCode: code } = records[0]
                        setCustomerCode(code)
                        queryAccount(
                            code,
                            typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH
                                ? 0
                                : typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_BALANCE
                                ? 2
                                : 1,
                            typeForm[mode].payType,
                            shopName
                        )
                    } else {
                        message.warning(`该结算方为部门内部费用时，无维护对应为${shopName}客户`)
                    }
                })
            } else if (
                !(
                    settlementPartyCode === DictCodeEnmu.SETTLE_PARTY_INTERNAL ||
                    settlementPartyCode === DictCodeEnmu.SETTLE_PARTY_SELF_PAY ||
                    settlementPartyCode === DictCodeEnmu.SETTLE_PARTY_DABAOMAINTENANCE
                )&&getTreeNodeOne(settlementPartyCode).length>0
            ) {
                let { value: customerCode, name } = getTreeNodeOne(settlementPartyCode).length>0?getTreeNodeOne(settlementPartyCode)[0]:[]
                if (customerCode) {
                    queryAccount(
                        customerCode,
                        typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1,
                        typeForm[mode].payType,
                        name
                    )
                } else {
                    message.warning(`该结算方数据字典无维护对应的客户编码`)
                }
            } else if (mode !== 'look') {
                queryAccount(
                    typeForm[mode].customerCode,
                    typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1,
                    typeForm[mode].payType,
                    typeForm[mode].customerName
                )
            }
            // queryAccount(
            //     typeForm[mode].customerCode,
            //     typeForm[mode].payType === DictCodeEnmu.SETTLE_TYPE_CASH ? 0 : typeForm[mode].payType === 'settle-type.balance' ? 2 : 1,
            //     typeForm[mode].payType,
            //     typeForm[mode].customerName
            // )
        }
    }, [visible])


    const setPayMethodOptions = (payType) => {}

    // 查询客户账户类型及可用额度 后期补
    const queryAccount = async (partnerCode, creditFlag, payType, customerName) => {
        //加盟商不需要查询账户
        if (IsFranchisees()) {
            return
        }
        let partner = partnerCode
        let partnerName = customerName
        if (!(typeForm[mode].settlementPartyCode == 'settle-party.self-pay' || typeForm[mode].settlementPartyCode == 'settle-party.internal')) {
            partner = getTreeNodeOne(typeForm[mode].settlementPartyCode)[0]?.value
            partnerName = getTreeNodeOne(typeForm[mode].settlementPartyCode)[0]?.name
        }

        let params = {
            partnerCode: partner,
            // partnerCode,
            beCode: typeForm[mode].bentityCode,
            status: 0,
            creditFlag, //为记账类型的  1挂账 2现结
            sourceApp: 'ci',
            shopCode,
        }
        const { retData } = await saleRoService.accountFind(params)
        //根据优惠类型， 过滤授信账户的 （返利|授信）
        let filterResult = retData.filter((item) => {
            return item
        })
        //现金账户 或者 挂账账户。
        let newAccTypeDs = [] // filterResult.map(item => ({ label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }));
        console.log(payType)
        if (payType === DictCodeEnmu.SETTLE_TYPE_CASH) {
            newAccTypeDs = filterResult.map((item) => ({
                label: item.name ?? '',
                value: item.code ?? '',
                available: item.availableAmount ?? 0,
                accountTypeCode: item.accountTypeCode,
                key: item.key ?? '',
            }))
        } else if (payType === DictCodeEnmu.SETTLE_TYPE_CREDIT) {
            //挂账  account-type.credit
            newAccTypeDs = filterResult
                .filter((item) => {
                    if (item.accountTypeCode == 'account-type.credit')
                        return { label: item.name ?? '', value: item.code ?? '', available: item.availableAmount ?? 0 }
                })
                .map((item1) => ({
                    label: item1.name ?? '',
                    value: item1.code ?? '',
                    available: item1.availableAmount ?? 0,
                    accountTypeCode: item1.accountTypeCode,
                    key: item1.key ?? '',
                }))
        } else if (payType === DictCodeEnmu.SETTLE_TYPE_BALANCE) {
            //月结  account-type.credit-month
            newAccTypeDs = filterResult
                .filter((item) => {
                    if (item.accountTypeCode == 'account-type.credit-month')
                        return { label: item.name ?? '', value: item.code ?? '', available: item.availableAmount ?? 0 }
                })
                .map((item1) => ({
                    label: item1.name ?? '',
                    value: item1.code ?? '',
                    available: item1.availableAmount ?? 0,
                    accountTypeCode: item1.accountTypeCode,
                    key: item1.key ?? '',
                }))
        }
        if (newAccTypeDs && newAccTypeDs.length > 0) {
            // formRef.current.setFieldsValue({ payMethodCode:newAccTypeDs[0].value })
            payMethodChange(newAccTypeDs[0].value, newAccTypeDs[0])
        }
        setAccountTypeDs(newAccTypeDs)

        //选择挂账， 自动带出挂账的账户及授信额度
        if (mode === 'edit') {
            if (newAccTypeDs.length > 0) {
                //setCash(newAccTypeDs[0].accountTypeCode === 'account-type.cash')
                setPayDataSource(() => {
                    let available = +(newAccTypeDs[0]?.available ?? 0) //可用额度
                    let payAmount = +typeForm[mode].receivedAmount //需要支付的额度
                    // if (payAmount > available) { message.warning("该账户可用额度不足") };
                    let obj = [
                        {
                            ...initDataSource,
                            available: available,
                            // accountName: newAccTypeDs[0].label,
                            // accountCode: newAccTypeDs[0].value,
                            payMethodName: newAccTypeDs[0].label,
                            payMethodCode: newAccTypeDs[0].value,
                            payMethodValue: newAccTypeDs[0].accountTypeCode,
                            payment: typeForm[mode].paymentMethodName,
                            amount: payAmount, //+`${payAmount <= available ? payAmount : 0}`,
                        },
                    ]
                    return obj
                })
            } else {
                setCash(false)
                setAccountTypeDs(null)
                setPayDataSource([{ ...initDataSource, amount: typeForm[mode] }])
                message.warning(`该${partnerName}没有对应的账户`)
            }
        }
    }
    //每次类型选择重置表格数据  为挂账时，请求到该账户的授信列表
    const settleTypeChange = (value, option) => {
        //结算类型改变 首先置空支付方式
        formRef.current.setFieldsValue({ ...typeForm[mode], payMethodCode: null, payType: value })
        let { settlementPartyCode } = typeForm[mode]
       //加盟商不需要查询账户
       if (option && !IsFranchisees()) {
            setSettleType(option.value)
            setPayDataSource([initDataSource])
            setPayDataSource([{ ...initDataSource, amount: detailEditForm.receivedAmount }])
            setCash(false)
            if (settlementPartyCode === DictCodeEnmu.SETTLE_PARTY_INTERNAL) {
                //这里为部门内部结算时，查询销售实体为客户的接口换取id
                queryAccount(
                    typeForm[mode].customerCode,
                    value === DictCodeEnmu.SETTLE_TYPE_CASH ? 0 : typeForm[mode].payType === 'settle-type.balance' ? 2 : 1,
                    value,
                    shopName
                )
            } else if (
                !(
                    settlementPartyCode === DictCodeEnmu.SETTLE_PARTY_INTERNAL ||
                    settlementPartyCode === DictCodeEnmu.SETTLE_PARTY_SELF_PAY ||
                    settlementPartyCode === DictCodeEnmu.SETTLE_PARTY_DABAOMAINTENANCE
                )
            ) {
                let { value: customerCode, name } = getTreeNodeOne(settlementPartyCode)[0]
                if (customerCode) {
                    // queryAccount(customerCode, value === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : value === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, typeForm[mode].payType, name);
                    queryAccount(
                        customerCode,
                        value === DictCodeEnmu.SETTLE_TYPE_CASH ? 0 : typeForm[mode].payType === 'settle-type.balance' ? 2 : 1,
                        value,
                        name
                    )
                } else {
                    message.warning(`该结算方数据字典无维护对应的客户编码`)
                }
            } else {
                // queryAccount(typeForm[mode].customerCode, value === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : value === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, value, typeForm[mode].customerName);
                queryAccount(
                    typeForm[mode].customerCode,
                    value === DictCodeEnmu.SETTLE_TYPE_CASH ? 0 : typeForm[mode].payType === 'settle-type.balance' ? 2 : 1,
                    value,
                    typeForm[mode].customerName
                )
            }
            // queryAccount(typeForm[mode].customerCode, typeForm[mode].payType === DictCodeEnmu.SETTLE_TYPE_CASH ? 0 : typeForm[mode].payType === "settle-type.balance" ? 2 : 1, typeForm[mode].payType, typeForm[mode].customerName);
            setPayMethodOptions(value)
        }
    }

    //监听批量录入字段
    const onChange = (changedValues) => {}

    const onCancel = () => {
        setSettleVisible(false)
    }

    const [okLoading, setOkLoading] = useState(false)
        //加盟商结算
        const franchiseesSettle = () => {
            formRef.current
                .validateFields()
                .then(async (res) => {
                    let data = {
                        id: typeForm[mode].id,
                        fmsNote: res.fmsNote,
                        reckoner: userName,
                        payType: res.payType,
                        payableAmount: res.receivedAmount,
                    }
    
                    try {
                        setOkLoading(true)
                        let retData = await onSettle(data)
                        message.success('结算成功')
                        onCancel()
                        getDetailEditOne(typeForm[mode].returnCode, 'edit')
                        setTimeout(() => {
                            setOkLoading(false)
                        }, 1000);
                    } catch (error) {
                        onCancel()
                        setTimeout(() => {
                            setOkLoading(false)
                        }, 1000);
                    }
                })
                .catch((err) => {})
        }

   
    //表单提交
    const onSubmit = async () => {
        if (IsFranchisees()) {
            //加盟商结算
            return franchiseesSettle()
        }
    
        formRef.current
            .validateFields()
            .then(async (res) => {
                let data = {
                    ...res,
                    id: typeForm[mode].id,
                    returnCode: res.returnCode,
                    fmsNote: res.fmsNote,
                    reckoner: userName,
                    payType: res.payType,
                    accountCode: res.accountCode,
                    accountName: res.accountName,
                    accountType: res.payMethodValue,
                    payableAmount: res.receivedAmount,
                    payMethodName: res.payMethodName,
                    payMethodCode: res.payMethodCode,
                    details:
                        res.payMethodValue !== 'account-type.cash'
                            ? []
                            : [
                                  {
                                      payMethodCode: res?.cashCode,
                                      payMethodName: res.cashName,
                                      accountCode: res.accountCode,
                                      accountName: res.accountName,
                                      amount: res.receivedAmount,
                                      payee: '',
                                      cashAttach: res.cashAttach,
                                      cashAttachFileName: res.cashAttachFileName,
                                  },
                              ],
                }
                try {
                    setOkLoading(true)
                    let retData = await onSettle(data)
                    message.success('结算成功')
                    onCancel()
                    getDetailEditOne(typeForm[mode].returnCode, 'edit')
                    setTimeout(() => {
                        setOkLoading(false)
                    }, 1000);
                } catch (error) {
                    onCancel()
                    setTimeout(() => {
                        setOkLoading(false)
                    }, 1000);
                }
            })
            .catch((err) => {})
    }

    const onFinish = (val) => {}

    const payMethodChange = (value, option) => {
        if (option.accountTypeCode === DictCodeEnmu.ACC_TYPE_CASH) {
            setCash(true)
        } else {
            setCash(false)
        }
        if(formRef.current){
            option &&
            formRef.current.setFieldsValue({
                accountName: option.label,
                accountCode: value,
                payMethodName: option.label,
                payMethodCode: value,
                payMethodValue: option.accountTypeCode,
                available: formatNum(option?.available),
                cashName: undefined,
                cashCode: undefined,
            })
        }

    }

    const cashSelectChange = (value, option) => {
        option && formRef.current.setFieldsValue({ cashName: option.label, cashCode: option.value })
    }
    //图片变更缓存保留  保留缓存及回显表单
    const onSetFieldsValue = (formValues) => {
        // updateFormVal(mode, formValues)
        formRef.current.setFieldsValue({ ...typeForm[mode], ...formValues })
    }
    const [payTypeList, setPayTypeList] = useState([])
    useEffect(() => {
        if( shopCode=='SHOP-M-00000016'||shopCode=='SHOP-M-00000017'||shopCode=='SHOP-M-00000018'){
            setPayTypeList(getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE))
        }else{
            setPayTypeList(getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE).map((item)=>{
                return{...item,disabled:item.value=='settle-type.credit'?true:false}}))         }
    }, [])
    const formConfig = {
        formRef,
        onFinish,
        labelCol: { span: 6 },
        disabled: mode === 'look',
        onChange,
        items: [
            {
                type: 'Input',
                fieldLabel: '退货单号',
                fieldName: 'returnCode',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '制单日期',
                fieldName: 'createTime',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '客户编码',
                fieldName: 'customerCode',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '客户名称',
                fieldName: 'customerName',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '结算日期',
                fieldName: 'settleTime',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '已付金额',
                fieldName: 'receivableAmount',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '应付金额',
                fieldName: 'receivedAmount',
                span: 12,
                disable: true,
            },
            {
                type: 'Select',
                fieldLabel: '结算类型',
                fieldName: 'payType',
                onSelectChange: settleTypeChange,
                // options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                options:payTypeList,
                span: 12,
                // disable: true
            },
            ...((str) => {
                if (str) {
                    return [
                        {
                            type: 'Select',
                            fieldLabel: '支付方式',
                            fieldName: 'payMethodCode',
                            onSelectChange: payMethodChange,
                            options: accountTypeDs,
                            rules: [{ required: true, message: '请选择支付方式' }],
                            span: 12,
                            hidden: mode === 'look',
                        },
                        {
                            type: 'Input',
                            fieldLabel: '支付方式Code',
                            fieldName: 'accountCode',
                            span: 12,
                            hidden: true,
                        },
                        {
                            type: 'Input',
                            fieldLabel: '支付方式',
                            fieldName: 'accountName',
                            span: 12,
                            hidden: !(mode === 'look'),
                        },
                        {
                            type: 'Input',
                            fieldLabel: '现金类支付',
                            fieldName: 'cashName',
                            hidden: !(mode === 'look') || !(settleType === ISettleTypeEnum.SETTLE_CASH),
                            rules: [{ required: !(!(mode === 'look') || !(settleType === ISettleTypeEnum.SETTLE_CASH)), message: '请完善现金类支付' }],
                            span: 12,
                        },
                    ]
                } else {
                    return []
                }
            })(!IsFranchisees()),
           
            // ...((str) => {
            //     if (str) {
            //         return [
            //             {
            //                 type: "Select",
            //                 fieldLabel: "现金类支付",
            //                 fieldName: "cashCode",
            //                 span: 12,
            //                 options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD_CASHS),
            //                 onSelectChange: cashSelectChange,
            //                 hidden: mode === "look" || !cash,
            //                 rules: [{ required: true, message: "请完善现金类支付" }],
            //             }
            //         ]
            //     } else {
            //         return [
            //             {
            //                 type: "Input",
            //                 fieldLabel: "可用额度",
            //                 fieldName: "available",
            //                 span: 12,
            //                 hidden: mode === "look",
            //                 disable: true,
            //             },
            //         ]
            //     }
            // })(cash),
            ...((str) => {
                if( IsFranchisees()) {
                    return []
                }
                if (str) {
                    let arr = []
                    if (mode == 'look') {
                        arr = [
                            {
                                type: 'Input',
                                fieldLabel: '现金类支付',
                                fieldName: 'cashname1',
                                span: 12,
                            },
                        ]
                    } else {
                        arr = [
                            {
                                type: 'Select',
                                fieldLabel: '现金类支付',
                                fieldName: 'cashCode',
                                span: 12,
                                options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD_CASHS),
                                onSelectChange: cashSelectChange,
                                // hidden: mode === "look"
                            },
                        ]
                    }
                    return [
                        ...arr,
                        {
                            type: 'FormUpload',
                            fieldLabel: '附件',
                            fieldName: 'cashAttachFileName',
                            fieldMd5: 'cashAttach',
                            defaultFileName: formVal.cashAttachFileName,
                            defaultFileMd5: formVal.cashAttach,
                            onSetFieldsValue,
                            span: 12,
                            isUsePortalApi: true
                        },
                    ]
                } else {
                    return [
                        {
                            type: 'Input',
                            fieldLabel: '可用额度',
                            fieldName: 'available',
                            span: 12,
                            hidden: mode === 'look',
                            disable: true,
                        },
                    ]
                }
            })(cash),
            {
                type: 'Input',
                fieldLabel: '结算备注',
                fieldName: 'fmsNote',
                span: 12,
            },
            {
                type: 'Input',
                fieldLabel: '支付方式对应得账户类型',
                fieldName: 'payMethodValue',
                hidden: true,
            },
        ],
    }
    return (
        <YhDrawer
            title="销售退货单结算"
            visible={visible}
            onClose={onCancel}
            okText="保存"
            width={800}
            onOk={debounce(() => {
                onSubmit()
            }, 900)}
            showFooter={detailEditForm.status === DictCodeEnmu.SALE_RETURN_STATUS_REFUNDED || mode === 'look' ? false : true}
            okLoading={okLoading}
        >
            <div style={{ padding: '20px 36px' }}>
                <YhForm {...formConfig} />
            </div>
        </YhDrawer>
    )
}
