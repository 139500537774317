import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { commonService } from "../commonService";

export const ShopStock = ({
    materialCode,
    materialName
}) => {
    const [dataSource, setDataSource] = useState([]);
    const [shopType, setShopType] = useState("VRM");

    useEffect(() => {
        setShopDataSource("VRM")
    }, [])

    const setShopDataSource = async (shopTypes) => {
        setShopType(shopTypes)
        let data = [];
        switch (shopTypes) {
            case "SCM":
                const { retData: scmRetData } = await commonService.scm2Inventory(materialCode);
                data = scmRetData;
                break;
            case "VRM":
                const { retData: vrmRetData } = await commonService.vrm2Inventory(materialCode);
                data = vrmRetData;
                break;
            case "BCMS":
                const { retData: bcmRetData } = await commonService.bcmInventory(materialCode);
                data = bcmRetData;
                break;
            case "AI":
                const { retData: aiRetData } = await commonService.aiInventory(materialCode);
                data = aiRetData;
                break;
            default:
                break;
        }
        setDataSource(data)
    }

    const action = <>
        <Button
            className={`mr-5 btn-item ${shopType === "SCM" ? "tab-buts-active" : ""}`}
            shape="round"
            onClick={() => setShopDataSource("SCM")}
        >供应链系统</Button>
        <Button
            className={`mr-5 btn-item ${shopType === "VRM" ? "tab-buts-active" : ""}`}
            shape="round"
            onClick={() => setShopDataSource("VRM")}
        >云修系统</Button>
        <Button
            className={`mr-5 btn-item ${shopType === "AI" ? "tab-buts-active" : ""}`}
            shape="round"
            onClick={() => setShopDataSource("AI")}
        >AI系统</Button>
        <Button
            className={`mr-5 btn-item ${shopType === "BCMS" ? "tab-buts-active" : ""}`}
            shape="round"
            onClick={() => setShopDataSource("BCMS")}
        >客车系统</Button>
    </>
    const columns = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t, r, i) => i + 1
        },{
            title: '从属门店',
            width: 120,
            dataIndex: 'shopName',
        }, {
            title: '库存',
            width: 80,
            dataIndex: shopType!=="SCM"?'inventoryNum':'totalInventoryQuantity',
        },{
            title: '占用库存',
            width: 80,
            dataIndex:  shopType!=="SCM"?'inventoryOccupied':'totalOccupyNum',
        },{
            title: '可用库存',
            width: 80,
            dataIndex:  shopType!=="SCM"?'availableStock':'availableNum',
        },
    ]
    return <YhBox title={action} style={{ margin: "0" }}>
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id}
            columns={columns}
            dataSource={dataSource}
            scroll={{ x: 'max-content', y: 550 }}
            pagination={false}
        />
    </YhBox>
}