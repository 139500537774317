import React, { useState, useEffect, useImperativeHandle } from "react"
import { Table, message } from "antd"
import YhIcon from "@/components/pubIcon"
import ItemDrawer from "@/components/pubPartDrawer/itemDrawer" //项目弹框
import { EditableRow, EditableCell } from "./pubEditble"
import { getOrderItem } from "@/service/ordersService"
import { getItemIdAll, getItemOne } from "@/service/maintenanceService"
import { getPcodeDict } from "@/utils/dataStorage"
import zeng from "@/assets/images/zeng.png"
import { ItemAttr } from "@/utils/enum"
import {useOrderModel} from "./orderModal"
import { IsFranchisees } from '@/utils/common'

//项目
export default ({ orderId, mode, maintenanceRef, onReceivables, parentOrderType, dataType, sanbaoData, dabaoData }) => {
    const {apiCount} = useOrderModel()
    const [visible, setVisible] = useState(false) //弹框
    const [data, setData] = useState([]) //表格数据
    const [totalPrice, setTotalPrice] = useState(0) //维修项目的总价
    const [dictObj, setDictObj] = useState({
        serviceNature: {},
        threeGuaranteesUnit: {},
    })
    const [orderSelectThreeGuaranteesUnit, setOrderSelectThreeGuaranteesUnit] = useState("014003") //记录整个订单的工单类型，看是否是三包， 收费，默认是  三包/收费
    const [isOperation, setIsOperation] = useState(true)

    //点开弹框初始下页面需要的字典数据
    const initDict = () => {
        let serviceNature = {}
        let threeGuaranteesUnit = {}
        //服务性质
        getPcodeDict("016").children.forEach((item) => {
            serviceNature[item.dictCode] = item.title
        })
        //三包单位
        getPcodeDict("106").children.forEach((item) => {
            threeGuaranteesUnit[item.dictCode] = item.title
        })
        setDictObj({
            serviceNature: serviceNature,
            threeGuaranteesUnit: threeGuaranteesUnit,
        })
    }

    useEffect(() => {
        initDict()
        if (mode !== "insert") {
            parentOrderType && setOrderSelectThreeGuaranteesUnit(parentOrderType)
            echoData()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //计算总价
    useEffect(() => {
        let num = 0
        data.forEach((item) => {
            num += Number(item.actualMoney)
        })
        onReceivables({ itemAccount: num, material: null })
        setTotalPrice(num.toFixed(2))
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    //暴露给子组件的方法
    useImperativeHandle(maintenanceRef, () => ({
        //upData 就是暴露给父组件刷新保存后的的方法
        upData: (parentOrderId) => {
            echoData(parentOrderId)
        },
        //父组件保存的时候 获取所有编辑完后的数据
        upMaintenancelData: () => {
            return data
        },
        orderTypeChange: async (orderType, isThree) => {
            await setOrderSelectThreeGuaranteesUnit(orderType)
            //是三包    项目和配件的工时 单价 工时费 优惠 折扣 总金额 都置0，  并且服务性质全部都是三包并且不可编辑。  三包单位默认选择的是第一个必选。
            // if (orderType === "014002") {
            //     // let newData = data.map((item) => {
            //     //     return {
            //     //         ...item,
            //     //         totalAll: 0,
            //     //         discount: "",
            //     //         actualMoney: 0,
            //     //         preferentialAmount: 0,
            //     //         serviceNature: "016001",
            //     //         threeGuaranteesUnit: "10601",
            //     //     }
            //     // })
            //     // await setData(newData)
            //     //是收费  项目和配件的服务性质是收费不可编辑，三包不可编辑， 在上次是三包的时候:数量和单价带回原来的单价。
            // } else if (isThree) {
            //     resetPrice()
            // }
            if (dataType !== "sanbao" && orderType === "014002") {
                await setData([])
            }
        },
        setItemListHistory(setItemList) {
            let newArr = []
            setItemList.forEach((item) => {
                let unselect = item
                if (unselect.itemDeleteStatus) {
                    message.error(`${unselect.itemName}已被删除,不能添加！`)
                } else {
                    newArr.push({
                        ...unselect,
                        temporaryId: new Date().getTime() + parseInt(Math.random() * 100000),
                        workHour: unselect.itemWorkHours, //工时
                        hourPrice: unselect.workHoursPrice, //单价
                        totalAll: unselect.itemAmount, //工时费
                        discount: unselect.itemDiscount, //折扣
                        actualMoney: unselect.state === "refuse_repair" ? 0 : unselect.actualAmount, //总金额
                    })
                }
            })
            setData([...data, ...newArr])
        },
        // 通过模版添加
        setItemListTemplate(setItemList) {
            let newArr = []
            setItemList.forEach((item) => {
                let unselect = item
                if (unselect.itemDeleteStatus) {
                    message.error(`${unselect.itemName}已被删除,不能添加！`)
                } else {
                    newArr.push({
                        ...unselect,
                        temporaryId: new Date().getTime() + parseInt(Math.random() * 100000),
                        workHour: unselect.itemWorkHours, //工时
                        hourPrice: unselect.workHoursPrice, //单价
                        totalAll: unselect.itemAmount, //工时费
                        discount: 0, //折扣
                        actualMoney: unselect.itemAmount, //总金额===工时费
                        preferentialAmount: 0, //优惠
                    })
                }
            })
            setData([...data, ...newArr])
        },
    }))
    //重置单价
    const resetPrice = async () => {
        let originData = [...data]
        //表格里面所有项目的id
        let itemIdAll = originData.reduce((c, n) => {
            c.push(n.itemId)
            return c
        }, [])
        if (itemIdAll.length < 1) {
            return
        }
        await getItemIdAll(itemIdAll).then((res) => {
            // if (res && res.retCode === 200 && res.retData) {
            let result = res.retData
            result.forEach((item) => {
                //拿到的最新维修项目
                let newItemItem = item
                originData.forEach((originItem) => {
                    if (newItemItem.id === originItem.itemId) {
                        originItem.workHour = newItemItem.itemWorkHours //工时
                        originItem.hourPrice = newItemItem.workHoursPrice //单价
                        originItem.totalAll = newItemItem.itemAmount //工时费
                        originItem.actualMoney = newItemItem.itemAmount //总金额
                        originItem.serviceNature = "016002" //每次重置 服务性质都改为收费。
                        originItem.threeGuaranteesUnit = "" //每次重置单价必定不是三包， 给置空。
                    }
                })
            })
            // }
        })
        await setData([])
        await setData(originData)
    }
    //反显的数据查询
    const echoData = async (parentOrderId = "") => {
        if (dataType === "sanbao") {
            setData(
                sanbaoData.map((item) => {
                    return {
                        ...item,
                        workHour: item.itemWorkHours, //工时
                        hourPrice: item.workHoursPrice, //单价
                        totalAll: item.itemAmount, //工时费
                        discount: item.itemDiscount, //折扣
                        actualMoney: item.state === "refuse_repair" ? 0 : item.actualAmount, //总金额
                    }
                })
            )
        } else if (dataType === "dabao") {
            setData(
                dabaoData.map((item) => {
                    return {
                        ...item,
                        workHour: item.itemWorkHours, //工时
                        hourPrice: item.workHoursPrice, //单价
                        totalAll: item.itemAmount, //工时费
                        discount: item.itemDiscount, //折扣
                        actualMoney: item.state === "refuse_repair" ? 0 : item.actualAmount, //总金额
                    }
                })
            )
        } else {
            apiCount.current =  apiCount.current + 1
            let data = {
                pageSize: 1000,
                searchItem: { orderId: parentOrderId ? parentOrderId : orderId, serviceNature: ["016002", "016003", '016004'] },
            }
            await getOrderItem(data).then((res) => {
                let result = res.retData.list
                let editArr = result.map((item) => {
                    return {
                        ...item,
                        workHour: item.itemWorkHours, //工时
                        hourPrice: item.workHoursPrice, //单价
                        totalAll: item.itemAmount, //工时费
                        discount: item.itemDiscount, //折扣
                        actualMoney: item.state === "refuse_repair" ? 0 : item.actualAmount, //总金额
                    }
                })
                setData(editArr)
            }).finally(() => {
                apiCount.current =  apiCount.current - 1
            })
        }
    }

    //新增确定。 有选中的，过滤掉列表里面有的，在合并到一起。
    const setItemList = (setItemList) => {
        if (setItemList.length) {
            let newArr = []
            setItemList.forEach((item) => {
                let unselect = item
                let itemData = {
                    temporaryId: new Date().getTime() + parseInt(Math.random() * 100000),
                    itemId: unselect.id,
                    itemCode: unselect.itemCode,
                    itemName: unselect.itemName,
                    itemAttr: unselect.itemAttr,
                    serviceNature: orderSelectThreeGuaranteesUnit === "014002" ? "016001" : "016002", //服务性质 默认收费,订单时三包的话， 新增的只能是三包。
                    threeGuaranteesUnit: orderSelectThreeGuaranteesUnit === "014002" ? "10601" : "", //三包 ,   订单是三包的话，新增的必须有个默认值
                    claimNumber: "", //索赔单号
                    workHour: unselect.itemWorkHours, //工时
                    hourPrice: unselect.workHoursPrice, //单价
                    totalAll: unselect.itemAmount, //工时费
                    preferentialAmount: 0, //优惠
                    discount: "", //折扣
                    // actualMoney: orderSelectThreeGuaranteesUnit === "014002" ? 0 : unselect.itemAmount, //总金额
                    actualMoney: unselect.itemAmount, //总金额
                    remark: unselect.remark, //备注
                    giftFlag: "not_gift",
                    prepayFlag: 0,
                }
                newArr.push(itemData)
            })
            setData([...data, ...newArr])
        }
        setVisible(false)
    }
    /**
     * 以下是页面展示
     */
    const [maintenanceKeys, setMaintenanceKeys] = useState([]) // 批量删除的key
    const [deleteAll, setDeleteAll] = useState(false) //删除多选的控制按钮
    //批量删除多选的成员id
    const maintenanceSelection = {
        columnWidth: "30px",
        onChange: (selectedRowKeys, selectedRows) => {
            setMaintenanceKeys(selectedRowKeys)
        },
    }

    //批量删除
    const deleteTemaAll = () => {
        if (maintenanceKeys.length === 0) {
            message.warning("请选择你要删除的维修项目")
            return
        }
        let arr = data.filter((item) => maintenanceKeys.indexOf(item.id) < 0 && maintenanceKeys.indexOf(item.temporaryId))
        setDeleteAll(false)
        setData(arr)
        setMaintenanceKeys([])
        message.success("批量删除成功")
    }
    //维修项目删除一条数据
    const deleteMaintenanceOne = (row) => {
        //删除已选的
        let newData = [...data]
        let delIndex = newData.findIndex((item) => item.id === row.id && item.temporaryId === row.temporaryId)
        newData.splice(delIndex, 1)
        setData(newData)
        message.success("删除成功")
    }

    //是否是查看
    const isMode = () => {
        return mode === "look" ? false : true
    }

    //选中展示table配置
    const columns = [
        {
            title: "项目编码",
            align: "center",
            width: 120,
            fixed: "left",
            dataIndex: "itemCode",
        },
        {
            title: "项目名称",
            align: "center",
            width: 400,
            fixed: "left",
            dataIndex: "itemName",
            render: (text, record) =>
                record.state === "refuse_repair" ? (
                    <span>
                        {text}
                        <span style={{ color: "#f00" }}>(拒修)</span>
                    </span>
                ) : (
                    <>{text}</>
                ),
        },
        {
            title: "预付款",
            align: "center",
            width: "120px",
            dataIndex: "prepayFlag",
            editable: orderSelectThreeGuaranteesUnit === "014002" || mode === "look" || IsFranchisees() ? false : true,
            render: (text) => (text == 0 ? "否" : "是"),
        },
        {
            title: "项目属性",
            align: "center",
            width: "120px",
            dataIndex: "itemAttr",
            render: (text) => <p>{ItemAttr[text]}</p>,
        },
        // {
        //     title: "服务性质",
        //     align: "center",
        //     width: "120px",
        //     dataIndex: "serviceNature",
        //     render: (text) => <span>{dictObj.serviceNature[text]}</span>,
        //     editable: orderSelectThreeGuaranteesUnit === "014001" || orderSelectThreeGuaranteesUnit === "014002" || mode === "look" ? false : true,
        // },
        // {
        //     title: "三包单位",
        //     align: "center",
        //     width: "120px",
        //     dataIndex: "threeGuaranteesUnit",
        //     render: (text) => <span>{dictObj.threeGuaranteesUnit[text]}</span>,
        //     editable: orderSelectThreeGuaranteesUnit === "014001" || mode === "look" ? false : true, //订单收费时 不可编辑。
        // },
        // {
        //     title: "索赔单号",
        //     align: "center",
        //     width: "120px",
        //     dataIndex: "claimNumber",
        //     editable: orderSelectThreeGuaranteesUnit === "014001" || mode === "look" ? false : true, //收费时  不可编辑
        // },
        {
            title: "工时",
            align: "center",
            width: 100,
            dataIndex: "workHour",
            // editable: orderSelectThreeGuaranteesUnit === "014002" || mode === "look" ? false : true,
            editable: isMode() && !IsFranchisees(),
        },
        {
            title: "单价",
            align: "center",
            width: 185,
            dataIndex: "hourPrice",
            editable: isMode() && !IsFranchisees(),
            // editable: orderSelectThreeGuaranteesUnit === "014002" || mode === "look" ? false : true,
        },
        {
            title: "工时费",
            align: "center",
            width: 200,
            dataIndex: "totalAll",
        },
        {
            title: "优惠",
            align: "center",
            width: 185,
            dataIndex: "preferentialAmount",
            // editable: orderSelectThreeGuaranteesUnit === "014002" || mode === "look" ? false : true,
            editable: isMode() && !IsFranchisees(),
        },
        {
            title: "折扣",
            align: "center",
            width: 100,
            dataIndex: "discount",
            // editable: orderSelectThreeGuaranteesUnit === "014002" || mode === "look" ? false : true,
            editable: isMode() && !IsFranchisees(),
        },
        {
            title: "总金额",
            align: "center",
            width: "120px",
            dataIndex: "actualMoney",
        },
        {
            title: "是否赠送",
            align: "center",
            width: 100,
            dataIndex: "giftFlag",
            render: (text) => (text === "gift" ? <img src={zeng} style={{ width: "35px" }} alt="" /> : ""),
        },
        {
            title: "备注",
            align: "center",
            width: "120px",
            dataIndex: "remark",
            editable: isMode() && !IsFranchisees(),
        },
        {
            title: "操作",
            align: "center",
            width: "80px",
            dataIndex: "operation",
            render: (_, record) => {
                return  !isMode() ? '': <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => deleteMaintenanceOne(record)} />
            },
        },
    ]

    //单元格编辑保存
    const handleSave = async (row) => {
        //每次服务性质不是三包的话，把之前选的三包给清空掉。 是三包给个默认值
        if (row.serviceNature === "016001") {
            // row.threeGuaranteesUnit = "10601";
        } else {
            row.threeGuaranteesUnit = ""
            row.claimNumber = ""
        }
        // const itemData = await getItemOne(row.id)
        // const {itemWorkHours, workHoursPrice} = itemData.retData
        //查看是否有三包
        // if (!row.threeGuaranteesUnit) {
        // if (row.serviceNature !== "016001") {
            // row.workHour = itemWorkHours //工时
            // row.hourPrice = workHoursPrice //单价
            //每次编辑 重新计算工时费
            row.totalAll = (row.workHour * row.hourPrice).toFixed(2)
            //有折扣   工时费 *折扣 - 优惠
            if (+row.discount > 0) {
                let count = (row.totalAll * row.discount) / 10 - row.preferentialAmount
                row.actualMoney = count.toFixed(2)
            } else {
                let count = row.totalAll - row.preferentialAmount
                row.actualMoney = count.toFixed(2)
            }
        // } else {
        //     row.actualMoney = 0 //总金额
        //     row.preferentialAmount = 0
        //     row.discount = null //折扣
        //     // row.workHour = 0 //工时
        //     // row.hourPrice = 0 //单价
        //     // row.workHour = itemWorkHours //工时
        //     // row.hourPrice = workHoursPrice //单价
        //     row.totalAll = 0 //工时费
        // }
        // console.log(row)
        //服务性质据修改成 团队传来的状态据修
        if (row.state === "refuse_repair") {
            row.actualMoney = 0
            row.preferentialAmount = 0
            row.discount = null
        }
        if (row.giftFlag === "gift") {
            row.actualMoney = "0.00"
        }
        const newData = [...data]
        const index = newData.findIndex((item) => row.id === item.id && row.temporaryId === item.temporaryId)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }
    const maintenanceColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    // useEffect(() => {
    //     comIsOperation(orderSelectThreeGuaranteesUnit)
    // },[orderSelectThreeGuaranteesUnit])

    // const comIsOperation = () => {
    //     if (dataType === "sanbao" && orderSelectThreeGuaranteesUnit === "014001") {
    //         return setIsOperation(false)
    //     }
    //     if (dataType === "shoufei" && orderSelectThreeGuaranteesUnit === "014002") {
    //         return setIsOperation(false)
    //     }

    //     setIsOperation(true)
    // }

    return (
        <div className="list-container">
            <div className="list-header">
                <div className="list-name">
                    <span>维修项目</span>
                    <span className="total-price">
                        总金额&nbsp;¥&nbsp;<span>{totalPrice}</span>
                        &nbsp;元&nbsp;({data.length}项){" "}
                    </span>
                    {isMode() && isOperation && (
                        <div className="list-name-but">
                            <YhIcon type="icon-zengjia" style={{ color: "#37B36F", fontSize: "26px" }} onClick={() => setVisible(true)} />
                            {mode !== "insert" && (
                                <span className="list-delete" onClick={() => echoData(orderId)}>
                                    刷新
                                </span>
                            )}
                            {!deleteAll ? (
                                <span className="list-delete" onClick={() => setDeleteAll(true)}>
                                    批量删除
                                </span>
                            ) : (
                                <>
                                    <span className="list-delete" onClick={deleteTemaAll}>
                                        确认删除
                                    </span>
                                    <span className="list-delete" onClick={() => setDeleteAll(false)}>
                                        取消
                                    </span>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Table
                size="small"
                rowKey={(record) => record.id || record.temporaryId}
                components={components}
                columns={maintenanceColumns}
                dataSource={data}
                pagination={false}
                scroll={{ x: 1800, y: 260 }}
                rowSelection={deleteAll ? maintenanceSelection : false}
            />
            {isMode(mode) && <ItemDrawer visible={visible} onClose={(bool) => setVisible(bool)} onItemOk={setItemList} drawerMode="item" />}
        </div>
    )
}
