import React, { useState, useEffect } from 'react'
import { createModel } from 'hox'
import useGlobalModel, { defaultMdCmParam } from '@/erp_subpackage/model/globalModel'
import { saleDcService } from '../../saleDcService'
import { convertRes2Blob } from '@/erp_subpackage/utils/exportFile'
import { message } from 'antd'
import { useLockFn } from 'ahooks'
import { formatNum, getRandomStr } from '@/erp_subpackage/utils/util'
import { bigNumber } from "../../../../../utils/util";

const initDesc = {
    actualAmount: 0, //实际总价合计 （数量*单价）
    //对应展示字段
    numAll: 0, //调拨数量
    amountAll: 0, //调拨总计   （去除下浮）
    totalPreAmount: 0, //实际总价合计 （数量*单价）
}

export const useSaleDcDetailsModel = createModel(function () {
    const {
        user: { shopId, shopCode, shopName, userName, orgShopCode },
    } = useGlobalModel()

    const initFormVal = {
        outShopCode: orgShopCode ?? shopCode,
        outShopName: shopName,
        outShopId: shopId,
        createUser: userName,
        applier: userName,
        branchOut: defaultMdCmParam.branch,
        discountTotalAmount: '0.00', //优惠总额
        favorableAmount: '0', //整单优惠
        aggregateAmount: '0.00', //应付金额
        discountRate: '0', //整单下浮
    }

    //编辑缓存
    const [editFormVal, setEditFormVal] = useState(initFormVal)
    const [editGoodsList, setEditGoodsList] = useState([])
    const [editDesc, setEditDesc] = useState(initDesc)

    //新增缓存
    const [addFormVal, setAddFormVal] = useState(initFormVal)
    const [addGoodsList, setAddGoodsList] = useState([])
    const [addDesc, setAddDesc] = useState(initDesc)

    //查看缓存
    const [lookFormVal, setLookFormVal] = useState(initFormVal)
    const [lookList, setLookList] = useState([])
    const [lookDesc, setLookDesc] = useState(initDesc)
	// 控制先保存
	const [saveBtn, setSaveBtn] = useState(false);
    const [saveBtnLoading, setSaveBtnLoading] = useState(false); //  保存按钮

    useEffect(() => {
        let newDesc = { ...initDesc }
        editGoodsList.forEach((item) => {
			// newDesc.numAll += Number(item.num);
			newDesc.numAll = +bigNumber.add(item.num,newDesc.numAll);
			// newDesc.amountAll += Number(item.amount);
			newDesc.amountAll = +bigNumber.add(item.amount,newDesc.amountAll);
			// newDesc.actualAmount += Number(item.num) * Number(item.price);
			newDesc.actualAmount = +bigNumber.add(bigNumber.times(item.num,item.price),newDesc.actualAmount);
			// newDesc.totalPreAmount += Number(item.num) * Number(item.prePrice);
			newDesc.totalPreAmount = +bigNumber.add(bigNumber.times(item.num,item.prePrice),newDesc.totalPreAmount);
		
        })
        setEditDesc(newDesc)
    }, [editGoodsList])

    useEffect(() => {
        let newDesc = { ...initDesc }
        addGoodsList.forEach((item) => {
			// newDesc.numAll += Number(item.num);
			newDesc.numAll = +bigNumber.add(item.num,newDesc.numAll);
			// newDesc.amountAll += Number(item.amount);
			newDesc.amountAll = +bigNumber.add(item.amount,newDesc.amountAll);
			// newDesc.actualAmount += Number(item.num) * Number(item.price);
			newDesc.actualAmount = +bigNumber.add(bigNumber.times(item.num,item.price),newDesc.actualAmount);
			// newDesc.totalPreAmount += Number(item.num) * Number(item.prePrice);
			newDesc.totalPreAmount = +bigNumber.add(bigNumber.times(item.num,item.prePrice),newDesc.totalPreAmount);
        })
        setAddDesc(newDesc)
    }, [addGoodsList])

    useEffect(() => {
        setAddFormVal({ ...initFormVal, createUser: userName })
    }, [shopId])

    //表单修改
    const updateFormVal = (mode, obj) => {
        const setFormVal = mode === 'edit' ? setEditFormVal : mode === 'look' ? setLookFormVal : setAddFormVal
        setFormVal((origin) => (obj ? { ...origin, ...obj } : initFormVal))
    }

    //商品子表修改
    const updateList = (mode, arr = [], isMerge = false) => {
        const setList = mode === 'edit' ? setEditGoodsList : mode === 'look' ? setLookList : setAddGoodsList
        setList((origin) => (isMerge ? [...origin, ...arr] : [...arr]))
    }

    //获取一条数据
    const getSDcDetailOne = async (id) => {
        let { retData } = await saleDcService.one(id)
        let { details, ...formVal } = retData
        if (retData) {
            setEditGoodsList(() => details.map((item) => ({ ...item, key: item.id })))
            setEditFormVal({ ...formVal, shopIdShow: `${formVal.shopId}--${formVal.branchIn}` })
        }
        return retData
    }

    //表单商品校验 (开单数量不能为0)
    const verifyGoods = (mode) => {
        let goods = mode === 'edit' ? editGoodsList : addGoodsList
        if (!goods.length) {
            message.warning('请添加商品明细')
            return false
        }

        let filterArr = goods.filter((item) => !item.outWarehouseCode || !item.outShelfCode)
        if (filterArr.length) {
            message.warning(`【${filterArr[0].materialName}】请选择调入仓库和调入货位`)
            return false
        }

        let zeroNum = goods.filter((item) => !+item.num)
        if (zeroNum.length) {
            message.warning(`【${zeroNum.map((item) => item.materialName).join()}】商品的开单数量不能为0`)
            return false
        }
        return true
    }
    //表单计算校验 (计算主单信息与商品每一项)
    const formComputeRule = (mode) => {
        let goods = mode === 'edit' ? editGoodsList : addGoodsList
        let { actualAmount } = mode === 'edit' ? editDesc : addDesc
        actualAmount = +formatNum(actualAmount) //应付金额
        let goodsTotal = 0
        goods.forEach((item) => {
            let { amount } = item
            goodsTotal += +amount
        })
        if (+formatNum(goodsTotal) !== actualAmount) {
            message.warning(`详情调拨金额${+formatNum(goodsTotal)}与表单调拨金额不符`)
            return false
        }
        return true
    }
    //新增 Api
    const insertSDc = useLockFn(async (val) => {
        if (val.shopCode === val.outShopCode) {
            message.warning('调入门店和调出门店不能为同一个')
            return false
        }
        setSaveBtnLoading(true)
        let data = {
            ...val,
            details: addGoodsList.map((item) => {
                let { key, ...params } = item
                return params
            }),
            inAndOut: '1',
            transferOut: userName,
            discountRate: 0,
            favorableAmount: 0,
            totalPreAmount:formatNum(addDesc['totalPreAmount'])
            // outShopCode: orgShopCode ?? undefined
        }
        let { retData, retMsg } = await saleDcService.insert(data)
        retData ? message.success('调出单新增成功', 3) : message.error('调出单新增失败')
        setSaveBtnLoading(false)
        setSaveBtn(false)        
        return retData
    })

    //编辑 Api
    const updateSDc = useLockFn(async (val) => {
        if (val.shopCode === val.outShopCode) {
            message.warning('调入门店和调出门店不能为同一个')
            return false
        }
        setSaveBtnLoading(true)
        let data = {
            ...val,
            updateUser: userName,
            id: editFormVal.id,
            details: editGoodsList.map((item) => {
                let { key, ...params } = item
                return params
            }),
            inAndOut: '1',
            discountRate: 0,
            favorableAmount: 0,
            totalPreAmount:formatNum(editDesc['totalPreAmount'])
        }
        let { retData } = await saleDcService.update(data)
        setSaveBtnLoading(false)
        if (retData) {
            message.success('调出单编辑成功')
            getSDcDetailOne(editFormVal.id)
            setSaveBtn(false);
        } else {
            message.error('调出单编辑失败')
        }
    })

    /**
     * 商品新增
     */
    const [addGoodsModal, setAddGoodsModal] = useState(false) //商品弹框
    const [editSelectGoods, setEditSelectGoods] = useState([]) //已选的商品
    const [addSelectGoods, setAddSelectGoods] = useState([]) //已选的商品

    //添加商品  默认数量字段为1
    const add = (record, mode) => {
        const isEdit = mode === 'edit'
        isEdit ? setEditSelectGoods((origin) => [...origin, { ...record, sDcNum: 1 }]) : setAddSelectGoods((origin) => [...origin, { ...record, sDcNum: 1 }])
    }

    //删除商品 选中的
    const remove = (record, mode) => {
        mode === 'edit'
            ? setEditSelectGoods((origin) => origin.filter((item) => item.id !== record.id))
            : setAddSelectGoods((origin) => origin.filter((item) => item.id !== record.id))
    }

    //选中的商品转商品
    const transformsToSDcGoods = useLockFn(async (selectList, mode) => {
        const isEdit = mode === 'edit'
        const newDetailData = []
        let formVal = mode === 'add' ? addFormVal : editFormVal
        if (mode === 'edit') {
			setSaveBtn(true);
		}
        // let branchIn =
        // 	formVal.branchIn === 'scm' ? 'scm2' : formVal.branchIn === 'vrm' ? 'vrm2' : 'bcm';
        let branchIn = ''
        switch (formVal.branchIn) {
            case 'scm':
                branchIn = 'scm2'
                break
            case 'vrm':
                branchIn = 'vrm2'
                break
            case 'ci':
                branchIn = 'ci-storage'
                break
            default:
                branchIn = 'bcm'
                break
        }
        let inShopId = formVal.shopId ?? shopId
        let codeList = []
        let goodsList = mode === 'add' ? addSelectGoods : editSelectGoods
        goodsList.forEach((item) => {
            codeList.push(item.materialCode)
        })
        if (codeList.length === 0) {
            setAddGoodsModal(false)
            return
        }
        let data = {
            shopId: inShopId,
            checkoutMaterialCodeList: codeList,
            existMaterialCodeList: [],
            notExistMaterialCodeList: [],
        }
        let { retData } = branchIn === 'scm2' ? await saleDcService.checkScm(branchIn, data) : await saleDcService.check(branchIn, data)
        let { existMaterialCodeList } = retData
        selectList.forEach((item) => {
            newDetailData.push({
                isHave: existMaterialCodeList.indexOf(item.materialCode) > -1,
                id: '',
                key: item.id ?? getRandomStr(), //
                purchaseDetailsId: item.id,
                materialCode: item.materialCode,
                materialName: item.materialName,
                replaceCode: item.replaceCode,
                typeCode: item.typeCode,
                typeName: item.typeName,
                unitCode: item.unitCode,
                unitName: item.unitName,
                num: item.sDcNum,
                price: item.price,
                prePrice:item.price ?? 0, //折前单价
                preAmount: +item.sDcNum * +(item.price ?? 0), //总计
                costPrice: item.price ?? 0, //进货价
                favorableAmount: '0', //单品优惠
                discountRate: '0', //下浮
                amount: +item.sDcNum * +(item.price ?? 0),
                outShelfId: item.shelfId,
                outShelfCode: item.shelfCode,
                outWarehouseName: item.warehouseName,
                outWarehouseCode: item.warehouseCode,
                inventoryNum: item.inventoryQuantity,
                purchaseCode: item.purchaseCode,
                note: '',
            })
        })
        isEdit ? setEditGoodsList((origin) => [...origin, ...newDetailData]) : setAddGoodsList((origin) => [...origin, ...newDetailData])
        isEdit ? setEditSelectGoods([]) : setAddSelectGoods([])
        setAddGoodsModal(false)
    })

    //驳回
    const rejectDc = useLockFn(async () => {
        let { retData } = await saleDcService.reject(editFormVal.id)
        if (retData) {
            message.success('调出单驳回成功')
            getSDcDetailOne(editFormVal.id)
        } else {
            message.error('调出单驳回失败')
        }
    })

    //调出
    const outWhConfirm = useLockFn(async () => {
        let obj = {
            inAndOut: '1',
            id: editFormVal.id,
            status: editFormVal.status,
            transferOut: userName,
            transferOutTime: editFormVal.transferOutTime, //调出时间
            transferIn: editFormVal.transferIn, //调入员
            transferInTime: editFormVal.transferInTime, //调入时间
            createUser: userName,
            transferOutCode: editFormVal.transferOutCode,
            transferInCode: editFormVal.transferInCode,
        }
        let data = {
            ...editFormVal,
            id: editFormVal.id,
            transferOut: userName,
            details: editGoodsList.map((item) => {
                let { key, ...params } = item
                return params
            }),
            inAndOut: '1',
        }
        let params = {
            ...editFormVal,
            updateUser: userName,
            id: editFormVal.id,
            details: editGoodsList.map((item) => {
                let { key, ...params } = item
                return params
            }),
            inAndOut: '1',
            discountRate: 0,
            favorableAmount: 0,
        }
        let { retData:form } = await saleDcService.update(params)
        delete data.createTime
        delete data.updateTime
        let { retData } = await saleDcService.whConfirmCi(data)
        if (retData) {
            message.success('调出成功')
            getSDcDetailOne(editFormVal.id)
        }
    })

    //明细下载模板
    const sDcDownload = async () => {
        let result = await saleDcService.downloadTpl()
        convertRes2Blob(result)
    }

    //导出明细
    const exportSDcdetail = async () => {
        let result = await saleDcService.exportDetail(editFormVal?.code ?? '')
        convertRes2Blob(result)
    }

    /**
     * 打印
     */
    const [print, setPrint] = useState(false)

    return {
        editFormVal,
        addFormVal,
        addGoodsModal,
        setAddGoodsModal,
        updateFormVal,
        /**api */
        insertSDc,
        updateSDc,
        getSDcDetailOne,
        sDcDownload,
        exportSDcdetail,
        /**商品详情 */
        editSelectGoods,
        setEditSelectGoods,
        addSelectGoods,
        setAddSelectGoods,
        addGoodsList,
        setAddGoodsList,
        editGoodsList,
        setEditGoodsList,
        add,
        remove,
        verifyGoods,
        transformsToSDcGoods,
        editDesc,
        addDesc,
        print,
        setPrint,
        rejectDc,
        outWhConfirm,
        formComputeRule,saveBtnLoading,setSaveBtnLoading,
        saveBtn, setSaveBtn
    }
})
