import React, { useContext } from "react";
import { MyContext } from "@/store/myContext"
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { SalePrint } from "./SalePrint"
import { useSaleSoModel } from "../saleSoModel";
import { SettleScaniaPrints } from "./settleScaniaPrints"

export default () => {
    const { print,settleScaniaPrint } = useSaleSoModel();
    const { state } = useContext(MyContext)
    return (
        <>
            {state.activeMenu === "saleSo" && (
                <>
                    <PageHeader />
                    <DataBox />
                    {print && <SalePrint />}
                    {settleScaniaPrint && <SettleScaniaPrints />}
                </>
            )}
        </>
    )
}