import React, { useEffect, useState, useRef, useContext } from "react"
import { Button, Form, Col, Row, message, Select } from "antd"
import { getPackageJoinOne, packageRuleJoinSave, getPackageRuleList } from "@/service/setMealService"
import Maintenance from "./item" //维修项目
import Material from "./part" //物料
import { MyContext } from "@/store/myContext"
import CarTypeDrawer from "./carTypeDrawer" //详情弹框

//套餐详情
export default ({
    modeKey, //本组件的key
    parentKey, //套餐详情的key
    mode, //模式
    rowInfo, //规则行信息
    parentRowInfo, //规则的套餐行信息
    packDetailsRuleListApi, //套餐详情下列表的加载
    ruleList, //套餐详情下已添加的规则列表，  用于新增筛选已添加过的。
}) => {
    const { dispatch, state, detailsPage, Option } = useContext(MyContext)
    const [form] = Form.useForm()
    const itemRef = useRef() //维修项目 子组件
    const partRef = useRef() //维修物料  子组件
    const [saveLoading, setSaveLoading] = useState(false) //保存按钮
    const [visible, setVisible] = useState({
        visible: false,
        mode: "",
        ruleId: "",
    }) //详情弹框
    //编辑回显
    useEffect(() => {
        if (mode === "edit") {
            handleRule()
            getPackageJoinOne(rowInfo.id)
                .then((res) => {
                    // if (res && res.retCode === 200) {
                        form.setFieldsValue({
                            ruleId: res.retData?.ruleId,
                        })
                    // } else {
                    //     message.warning(res.retMsg)
                    // }
                })
                // .catch((err) => message.error(err.retMsg))
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    //表单提交
    const onsubmit = () => {
        setSaveLoading(true)
        let items = itemRef.current.upItemData()
        let parts = partRef.current.upPartData()
        let findParentNode = state.panes.find((item) => item.key === parentKey) //父组件tabs没有被关闭
        form.validateFields()
            .then((res) => {
                let data = {
                    ...res,
                    id: mode === "insert" ? "" : rowInfo.id,
                    packageId: parentRowInfo.id,
                    parts: parts.map((item) => {
                        let { partName, partCode, ...parms } = item
                        return {
                            ...parms,
                            id: item.partId ? item.partId : null,
                            partId: item.id,
                        }
                    }),
                    items: items.map((item) => {
                        let { itemName, itemCode, ...parms } = item
                        return {
                            ...parms,
                            id: item.itemId ? item.itemId : null,
                            itemId: item.id,
                        }
                    }),
                }
                // if (mode === "insert") {
                packageRuleJoinSave(data)
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            findParentNode && packDetailsRuleListApi && packDetailsRuleListApi()
                            handleCancel()
                            message.success(`${mode === "insert" ? "新增" : "编辑"}成功`)
                        // } else {
                        //     message.warning(res.retMsg)
                        //     setSaveLoading(false)
                        // }
                    })
                    .catch((err) => {
                        // message.error(err.retMsg)
                        setSaveLoading(false)
                    })
                // } else if (mode === "edit") {
                //     maintenancePackageEdit({ id: rowInfo.id, ...data }).then(res => {
                //         if (res && res.retCode === 200) {
                //             findParentNode && packDetailsRuleListApi && packDetailsRuleListApi();
                //             handleCancel();
                //             message.success("编辑成功");
                //         } else { message.warning(res.retMsg); setSaveLoading(false); }
                //     }).catch(err => { message.error(err.retMsg); setSaveLoading(false); })
                // }
            })
            .catch((err) => {
                message.warning("请检查必填项")
                setSaveLoading(false)
            })
    }

    /**
     * 取消
     * 1.先查看套餐详情是否被关闭
     *   被关闭 才拿套餐详情的id创建一个
     *   没有关闭  回到套餐详情
     */
    const handleCancel = async () => {
        let findParentNode = state.panes.find((item) => item.key === parentKey)
        //没有关闭
        if (findParentNode) {
            dispatch({
                type: "removeTabs",
                targetKey: modeKey,
                activeMenu: parentKey,
            })
        } else {
            await dispatch({
                type: "changeTabs",
                name: "维修套餐编辑",
                activeMenu: parentKey,
                deleteKey: modeKey,
                content: detailsPage.SetMealDetail({
                    modeKey: parentKey,
                    dispatch,
                    mode: "edit",
                    rowInfo: parentRowInfo,
                }),
            })
        }
    }
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    const [moduleList, setModuleList] = useState([]) // 模块列表
    //获取模块列表
    const handleRule = () => {
        let data = {
            pageSize: 1000,
            pageNum: 1,
            searchItem: {},
        }

        getPackageRuleList(data)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    let result = res.retData?.list || []
                    if (mode === "insert") {
                        let ruleIdAll = ruleList.map((item) => item.ruleId)
                        let filterData = result.filter((item) => ruleIdAll.indexOf(item.id) < 0)
                        setModuleList(filterData)
                        filterData.length < 1 && message.warning("没有可新增的规则，请添加规则或对已有规则进行编辑更新")
                    } else {
                        setModuleList(result)
                    }
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }
    return (
        <>
            <div className="toolbar">
                <span className="modules-name">规则匹配</span>
            </div>
            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>规则匹配详情</span>
                    </div>
                    <div className="list-but">
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={onsubmit} loading={saveLoading}>
                            保存
                        </Button>
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={handleCancel}>
                            取消
                        </Button>
                    </div>
                </div>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row>
                            <Col span={6} xxl={4}>
                                <Form.Item
                                    label="规则名称"
                                    name="ruleId"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择规则",
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="请选择规则"
                                        optionFilterProp="children"
                                        onFocus={() => handleRule()}
                                        style={{ width: "100%" }}
                                    >
                                        {moduleList.map((item) => (
                                            <Option value={item.id} key={item.id}>
                                                {item.ruleName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <span
                                    className="added-but"
                                    style={{
                                        lineHeight: "30px",
                                        fontSize: "16px",
                                    }}
                                    onClick={() => {
                                        let ruleId = form.getFieldValue("ruleId")
                                        if (!ruleId) {
                                            message.warning("请选择你查看的规则")
                                            return
                                        }
                                        setVisible({
                                            visible: true,
                                            mode: "look",
                                            ruleId: ruleId,
                                        })
                                    }}
                                >
                                    查看
                                </span>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <Maintenance
                itemRef={itemRef}
                packageJoinId={rowInfo.id}
                mode={mode}
                // onReceivables={onReceivables}
            />
            <Material
                partRef={partRef}
                packageJoinId={rowInfo.id}
                mode={mode}
                // onReceivables={onReceivables}
            />
            {visible.visible && (
                <CarTypeDrawer
                    visible={visible.visible}
                    mode={visible.mode}
                    onClose={() => setVisible({ visible: false, mode: "", ruleId: "" })}
                    ruleId={visible.ruleId}
                />
            )}
        </>
    )
}
