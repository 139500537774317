import { post, get } from "../utils/request";
import { axiosInst } from "../utils/AxiosInst";
import { PORTAL_API_SERVER_HOST } from "../config/app";
import useGlobalModel from "../erp_subpackage/model/globalModel";

/**
 * 配件接口
 */
//分页
function getMaintenanceList(data) {
  return post("/ci/maintenance_part/list", data);
}

//统计
function getPartStatistical() {
  return get("/ci/maintenance_part/statistical");
}

//新增
function insertMaintenance(data) {
  return post("/ci/maintenance_part/insert", data);
}

//编辑
function editMaintenance(data) {
  return post("/ci/maintenance_part/update", data);
}

//获取一条数据
function getMaintenanceOne(id) {
  return get(`/ci/maintenance_part/one/${id}`);
}

//配件 禁用启用
function disableMaintenance(data) {
  return post("/ci/maintenance_part/disable", data);
}

//根据多个id获取物料
function getMaintenanceIdAll(data) {
  return post("/ci/maintenance_part/list_by_ids", data);
}

/**
 * 维修项目接口
 */
//分页
function getItemList(data) {
  return post("/ci/maintenance_item/list", data);
}

//统计
function getItemStatistical() {
  return get("/ci/maintenance_item/statistical");
}

//新增
function insertItem(data) {
  return saveItem(data);
}

//编辑
function editItem(data) {
  return saveItem(data);
}

function saveItem(data) {
  // 改为新增到主数据
  data.sourceApp = "ci";
  data.branch = "ci";

  return axiosInst.post("/md/si/save", data, {
    baseURI: PORTAL_API_SERVER_HOST,
    headers: { ...{ "Content-Type": "application/json" } }
  });

}


//获取一条数据
function getItemOne(id) {
  return get(`/ci/maintenance_item/one/${id}`);
}

//根据多个id获取维修项目
function getItemIdAll(data) {
  return post("/ci/maintenance_item/list_by_ids", data);
}

/**
 * 系统配置接口
 */
//分页
function getSystemList(data) {
  return post("/ci/sys/conf/list", data);
}

//新增
function insertSystem(data) {
  return post("/ci/sys/conf/insert", data);
}

//编辑
function editSystem(data) {
  return post("/ci/sys/conf/update", data);
}

//获取一条数据
function getSystemOne(id) {
  return get(`/ci/sys/conf/one/${id}`);
}
//获取一条数据
function getSystemOneByKey(key) {
  return get(`/ci/sys/conf/one_ket/${key}`);
}

// 禁用启用
function disableSystem(data) {
  return post("/ci/sys/conf/disable", data);
}

/**
 * 订单图片分类配置接口
 */

//分页
function getImageClassifyList(data) {
  return post("/ci/order/image_classification/list", data);
}

//新增
function insertImageClassify(data) {
  return post("/ci/order/image_classification/save", data);
}

//编辑
function editImageClassify(data) {
  return post("/ci/order/image_classification/save", data);
}

//获取一条数据
function getImageClassifyOne(id) {
  return get(`/ci/order/image_classification/one/${id}`);
}

// 禁用启用
function disableImageClassify(data) {
  return post("/ci/order/image_classification/disable", data);
}

export {
  getMaintenanceList,
  insertMaintenance,
  editMaintenance,
  getMaintenanceOne,
  getItemList,
  getItemStatistical,
  insertItem,
  editItem,
  getItemOne,
  disableMaintenance,
  getSystemList,
  insertSystem,
  editSystem,
  getSystemOne,
  disableSystem,
  getMaintenanceIdAll,
  getItemIdAll,
  getImageClassifyList,
  insertImageClassify,
  editImageClassify,
  getImageClassifyOne,
  disableImageClassify,
  getPartStatistical,
  getSystemOneByKey
};
