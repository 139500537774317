import React from "react";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import { useStockStatisticModel } from "../stockStatisticModel";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"

export function PageHeader() {
    const { whDs, orgDs } = useGlobalModel(({ whDs, orgDs }) => [whDs, orgDs])
    const { getSelectedDictOne } = useDictModel(() => [])
    const {
        searchParam, organType, mdShopList, allowTransfer,
        updateSearchParam,
        resetSearchParam,
        loadData, loadScmData
    } = useStockStatisticModel();

    const searchFields = [
        {
            type: "Radio",
            plainOptions: ["机构", "门店"],
            fieldName: "diffField",
        },
        ...(organType && allowTransfer ? [searchParam.diffField === "机构" ? ({
            type: "Select",
            placeholder: "所属机构",
            fieldName: "shopId",
            options: orgDs
        },{
            type: "Input",
            placeholder: "品牌",
            fieldName: "brandName",
        } ): {
            type: "Select",
            placeholder: "所属门店",
            fieldName: "outerShopId",
            options: mdShopList
        }] : []),
        {
            type: "Select",
            placeholder: "是否有占用",
            fieldName: "occupy",
            options: [{ label: "是", value: 0 }, { label: "否", value: null }],
        },
        {
            type: "Select",
            placeholder: "仓库",
            fieldName: "warehouseCodeList",
            options: whDs,
            mode: "multiple",
        },
        {
            type: "Input",
            placeholder: "商品编号",
            fieldName: "materialCode",
        },
        {
            type: "Input",
            placeholder: "商品名称",
            fieldName: "materialName",
        },
        {
            type: "Input",
            placeholder: "货位",
            fieldName: "shelfCode",
        },
    ]

    return (
        <YhPageHeader
            title="库存统计"
            size="small"
            triggerSearch
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={(params) => {
                let searchValue = { ...searchParam, ...params };
                searchValue.diffField === "机构" ?
                    loadData({ pageNum: 1 }, searchValue)
                    : loadScmData({ pageNum: 1 }, searchValue)
            }}
            handleReset={resetSearchParam}
        />
    );
}