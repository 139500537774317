import React, { useEffect, useRef, useState } from "react"
import { Button, message } from "antd"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { YhFormBox } from "@/erp_subpackage/components/YhFormBox"
import { useGoodsModel } from "../goodsModel";
import { getFormValidatorErrText } from "@/erp_subpackage/utils/util"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { debounce } from "lodash"

export default ({ modeKey, mode, rowId, dispatch }) => {
    const {
        addFormVal,
        editFormVal,
        updateFormVal,
        insertGoods,
        editGoods,
        getGoodsOne,
        setSaveBtnLoading, saveBtnLoading
    } = useGoodsModel()

    const { getSelectedDictOne, getTreeNodeName, getTreeSelectOne } = useDictModel(() => [])
    const formRef = useRef()
    const formVal = mode === "edit" ? editFormVal : addFormVal;
    const [newlist, setnewlist] = useState([]);
    //编辑时， 没有缓存调用详情接口
    useEffect(() => {
        let list = []
        let a = getTreeSelectOne(DictCodeEnmu.MATERIAL_TYPE)
        let b = a.map((item) => {
            if (list.includes(item.value)) { return }
            else {
                list.push(item.value)
                return item
            }
        }).filter((element) => element)
        setnewlist(b)
        if (mode === "edit") {
            getGoodsOne(rowId)
        }
        return () => {
            updateFormVal(mode)
        }
    }, [rowId])

    //关闭当前页
    const onClose = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "baseGoods",
        })
    }

    const onChange = (changedValues) => {
        updateFormVal(mode, changedValues);
    }

    const reset = () => {
        formRef.current.resetFields()
        if (mode === "add") {
            updateFormVal(mode)
            message.success("重置成功")
        }
        else if (mode === "edit") {
            getGoodsOne(rowId).then(res => {
                message.success("重置成功")
            })
        }
    }

    //表单提交
    const onSubmit = debounce(async () => {
        formRef.current.validateFields().then((val) => {
            let data = {
                ...val,
                enable: val.enable ? 1 : 0,
                photo: typeof val.photo === "string" ? val.photo : val?.photo?.join(),
                typeName: getTreeNodeName(val.typeCode),
                brandName: getTreeNodeName(val.brandCode),
                unitName: getTreeNodeName(val.unitCode),
                categoryName: getTreeNodeName(val.categoryCode),
            }
            if (mode === "edit") {
                editGoods({ ...data, id: rowId, code: editFormVal.code }).then((res) => {
                    res && onClose()
                })
            } else {
                insertGoods(data).then((res) => {
                    res && onClose()
                })
            }
        }).catch((err) => {
            setSaveBtnLoading(false)
            message.warning(getFormValidatorErrText(err))
        })
    }, 900)

    //图片变更缓存保留  保留缓存及回显表单
    const onSetFieldsValue = (formValues) => {
        updateFormVal(mode, formValues);
    }

    const baseFormItem = {
        title: "基础信息",
        style: { padding: "0 20px", marginBottom: "10px" },
        items: [
            {
                type: "Input",
                fieldLabel: "商品编号",
                fieldName: "code",
                disable: mode !== "add",
                rules: [{ required: true, message: "请输入商品编号" }]
            }, {
                type: "Input",
                fieldLabel: "商品名称",
                fieldName: "name",
                rules: [{ required: true, message: "请输入商品名称" }]
            }, {
                type: "Input",
                fieldLabel: "所属机构",
                disable: true,
                fieldName: "shopName",
            }, {
                type: "Input",
                fieldLabel: "发动机型号",
                fieldName: "engineType",
            }, {
                type: "TreeSelect",
                fieldLabel: "商品类型",
                fieldName: "typeCode",
                treeData: newlist,
                rules: [{ required: true, message: "请选择商品类型" }]
            }, {
                type: "Input",
                fieldLabel: "替换编号",
                fieldName: "replaceCode",
            }, {
                type: "Input",
                fieldLabel: "规格型号",
                fieldName: "model"
            }, {
                type: "Select",
                fieldLabel: "单位",
                fieldName: "unitCode",
                options: getSelectedDictOne(DictCodeEnmu.MATERIAL_UNIT),
            }, {
                type: "Input",
                fieldLabel: "产地",
                fieldName: "madeFrom",
            }, {
                type: "Select",
                fieldLabel: "品牌",
                fieldName: "brandCode",
                options: getSelectedDictOne(DictCodeEnmu.MATERIAL_BRAND),
                rules: [{ required: true, message: "请选择品牌" }]
            }, {
                type: "Select",
                fieldLabel: "所属分类",
                fieldName: "categoryCode",
                options: getSelectedDictOne(DictCodeEnmu.PRODUCT_TYPE),
            }, {
                type: "Input",
                fieldLabel: "库存下限",
                fieldName: "limitLower",
            }, {
                type: "Input",
                fieldLabel: "库存上限",
                fieldName: "limitUpper",
            }, {
                type: "Input",
                fieldLabel: "商品名称",
                fieldName: "itemName",
                hidden: true
            }, {
                type: "Input",
                fieldLabel: "商品编码",
                fieldName: "itemCode",
                hidden: true
            }, {
                type: "Input",
                fieldLabel: "VIN",
                fieldName: "vin",
            }, {
                type: "Input",
                fieldLabel: "备注",
                fieldName: "note",
            }
        ]
    };

    const priceFormItem = {
        title: "商品价格",
        style: { padding: "0 20px", marginBottom: "10px" },
        items: [
            {
                type: "Input",
                fieldLabel: "进货价格",
                fieldName: "purchasePrice",
                rules: [{ required: true, message: "请输入进货价" }, { pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/, message: '请输入数值类型,可保留二位位小数' }]
            }, {
                type: "Input",
                fieldLabel: "批发价格",
                fieldName: "tradePrice",
            }, {
                type: "Input",
                fieldLabel: "优惠价格",
                fieldName: "discountPrice",
            }, {
                type: "Input",
                fieldLabel: "零售价格",
                fieldName: "retailPrice",
                rules: [{ required: true, message: "请输入零售价" }, { pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/, message: '请输入数值类型,可保留二位位小数' }]
            }, {
                type: "Input",
                fieldLabel: "商城价格",
                fieldName: "shopPrice",
            }, {
                type: "Input",
                fieldLabel: "加盟商价格",
                fieldName: "franchiseePrice",
            },
            {
                type: "FileImage",
                fieldLabel: "商品图片",
                fieldName: "photo",
                colClassName: "yh-file-image-box",
                span: 24,
                md5List: formVal.photo ?? [],
                // md5List: ["d63b72ebfcf54b2e89fe9fe6a120ec48"],
                onSetFieldsValue,
                photoNum: 50
            },

        ]
    }

    const otherFormItem = {
        title: "其它信息",
        style: { padding: "0 20px", marginBottom: "10px" },
        items: [
            {
                type: "Input",
                fieldLabel: "创建人",
                fieldName: "createUser",
                disable: true,
            }, {
                type: "Input",
                fieldLabel: "创建时间",
                fieldName: "createTime",
                disable: true,
            }, {
                type: "Input",
                fieldLabel: "修改人",
                fieldName: "updateUser",
                disable: true,
            }, {
                type: "Input",
                fieldLabel: "修改时间",
                fieldName: "updateTime",
                disable: true,
            }, {
                type: "Switch",
                fieldLabel: "商品状态",
                fieldName: "enable"
            }
        ]
    }


    const GoodsFromConfig = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        formValues: formVal,
        onChange,
        boxs: [baseFormItem, priceFormItem, otherFormItem],
    }

    const action = (
        <>
            <Button
                className="btn-item"
                shape="round"
                loading={saveBtnLoading}
                onClick={onSubmit}
            >{saveBtnLoading ? '保存中' : '保存'}</Button>
            <Button className="btn-item" shape="round" onClick={() => reset()}>
                重置
            </Button>
            <Button className="btn-item" shape="round" onClick={onClose}>
                取消
            </Button>
        </>
    )

    return (
        <>
            <YhBox title={`商品${mode === "edit" ? "编辑" : "新增"}`} action={action} style={{ padding: "0 20px", marginBottom: "10px" }}></YhBox>
            <YhFormBox {...GoodsFromConfig} />
        </>
    )
}
