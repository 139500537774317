import { useState, useEffect } from "react";
import { createModel } from "hox";
import { useLockFn } from "ahooks";
import { purchasePoService } from "../../purchasePoService"
import useGlobalModel, { defaultMdCmParam } from "@/erp_subpackage/model/globalModel";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import { formatInt, formatNum, getRandomStr } from "@/erp_subpackage/utils/util";
import { message } from "antd";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { bigNumber } from "../../../../../utils/util";
import { IsFranchisees } from '@/utils/common'

const initDesc = {
    numAll: 0,  //数量
    itemNumAll: 0, //项数
    totalAll: 0 //总计
}

export const usePurchasePoDetailsModel = createModel(function () {
    const { sourceApp, branch } = defaultMdCmParam;
    const { user: { shopId, shopName, shopCode, userName, orgName, orgCode, bentityName, bentityCode, organType } } = useGlobalModel();

    const initFormVal = {
        shopName,
        createUser: userName,
        bentityName: bentityName,
        status: true
    }

    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState(initFormVal);
    const [editList, setEditList] = useState([]);
    const [editDesc, setEditDesc] = useState(initDesc);
    //新增表单缓存
    const [addFormVal, setAddFormVal] = useState(initFormVal);
    const [addList, setAddList] = useState([]);
    const [addDesc, setAddDesc] = useState(initDesc);
    //查看表单缓存
    const [lookFormVal, setLookFormVal] = useState(initFormVal);
    const [lookList, setLookList] = useState([]);
    const [lookDesc, setLookDesc] = useState(initDesc);

    //添加商品弹框
    const [materialDialog, setMaterialDialog] = useState(false);

    //转销售单弹框
    const [transferSaleDrawer, setTransferSaleDrawer] = useState(false);

    //结算弹框
    const [settleVisible, setSettleVisible] = useState(false);

    //库房确认
    const [wsConfirmVisible, setWsConfirmVisible] = useState(false);

    //单据变化、按钮可操作控制
    const [controlled, setControlled] = useState(false);

    const [saveBtnLoading, setSaveBtnLoading] = useState(false); //  保存按钮

    //切换机构需重置的缓存
    useEffect(() => {
        setAddFormVal(initFormVal)
    }, [shopId])

    useEffect(() => {
        let newDesc = { ...initDesc };
        editList.forEach(item => {
            newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.price) * Number(item.num);
            newDesc.totalAll += +bigNumber.times(Number(item.price),Number(item.num)).toFixed(2);
        });
        newDesc.itemNumAll = editList.length;
        setEditDesc(newDesc);
    }, [editList])

    useEffect(() => {
        let newDesc = { ...initDesc };
        addList.forEach(item => {
            newDesc.numAll += Number(item.num);
            newDesc.totalAll += +bigNumber.times(Number(item.price),Number(item.num)).toFixed(2);
        });
        newDesc.itemNumAll = addList.length;
        setAddDesc(newDesc);
    }, [addList])

    useEffect(() => {
        let newDesc = { ...initDesc };
        lookList.forEach(item => {
            newDesc.numAll += Number(item.num);
            newDesc.totalAll += +bigNumber.times(Number(item.price),Number(item.num)).toFixed(2);
        });
        newDesc.itemNumAll = lookList.length;
        setLookDesc(newDesc);
    }, [lookList])

    //表单修改
    const updateFormVal = (mode, obj) => {
        const setFormVal = mode === "edit" ? setEditFormVal : mode === "look" ? setLookFormVal : setAddFormVal;
        setFormVal(origin => obj ? { ...origin, ...obj } : initFormVal);
    }

    //商品子表修改
    const updateList = (mode, arr = []) => {
        const setList = mode === 'edit' ? setEditList : mode === "look" ? setLookList : setAddList;
        setList([...arr]);
        if (mode === "edit") { setControlled(true) }
    };

    //获取一条数据
    const getPurchasePoOne = useLockFn(async (code, mode = "edit") => {
        let { retData } = await purchasePoService.one(code);
        if (retData) {
            let { details, ...from } = retData;
            const settleDetails = retData.settleDetails
            if (settleDetails && settleDetails.length > 0) {
                const { cashAttach, cashAttachFileName } = settleDetails[0]
                if(cashAttach && cashAttachFileName){
                    from.cashAttach = cashAttach
                    from.cashAttachFileName = cashAttachFileName
                }
            }
            updateFormVal(mode, from ?? {});
            updateList(mode, (details ?? []).map(item=>({
                ...item,
                key:getRandomStr()
            })));
            setControlled(false);
        }
        return retData;
    })

    //校验商品信息的仓库和货位是否选中
    const verifyWhOrShelf = (list = editList) => {
        if (!list || !list.length) {
            message.warning("请选择商品");
            return false;
        }
        let filterArr = list.filter(item => !item.warehouseCode || !item.shelfCode);
        if (filterArr.length) {
            message.warning(`【${filterArr[0].materialName}】请选择仓库和货位`);
            return false;
        }
        return true;
    }

    //新增采购单
    const insertDetail = useLockFn(async (params) => {
        // if (!verifyWhOrShelf(addList)) return false;
        setSaveBtnLoading(true)
        if(addList.length==0){
            message.warning(`未添加商品明细不可生成采购单`)
            return
        } 
        let list =addList.map(item => { let { key, ...params } = item; return params; })
        let lists  =list.map((obj)=>{ 
            delete obj.createUser
            delete obj.createTime
            delete obj.deleteFlag
            delete obj.updateUser
            delete obj.updateTime
            return obj
        }).filter(obj => Object.keys(obj).length !== 0);
        let newParams = {
            ...params,
            note: ((params.note ?? "")),
            details:lists,
            createUser: userName,
            // bentityName, bentityCode,
            sourceApp, branch,
            shopId, shopName, shopCode, userName, orgName, orgCode,
        }
       
        let { retData } = await purchasePoService.insert(newParams);
        setSaveBtnLoading(false)
        message.success("新增成功")
        return retData;
       
    })

    //编辑采购单
    const editDetail = useLockFn(async (params) => {
        setSaveBtnLoading(true)
        // if (!verifyWhOrShelf(editList)) return false;
        if(editList.length==0){
            message.warning(`未添加商品明细不可生成采购单`)
            return
        } 
        let newParams = {
            ...params,
            note: (params.note ?? ""),
            id: editFormVal.id,
            details: editList.map(item => { let { key, ...params } = item; return params; }),
            updateUser: userName,
            // bentityName, bentityCode,
            sourceApp, branch,
            shopId, shopName, shopCode, userName, orgName, orgCode,
        }
        let { retData } = await purchasePoService.update(newParams);
        getPurchasePoOne(editFormVal.purchaseCode);
        setSaveBtnLoading(false)
        message.success("编辑成功")
        return retData;
    })

    //商品子表添加
    const insertMaterial = (mode, materialArr) => {
        const setList = mode === 'edit' ? setEditList : mode === "look" ? setLookList : setAddList;
        const repetition = [];
        setList((origin) => {
            const newArr = [...origin];
            materialArr.forEach((material) => {
                //选中的是否存在列表
                let isExist = newArr.find((item) => item.materialCode === (material.code ?? material.materialCode));
                if (!isExist) {
                    newArr.push({
                        key: getRandomStr(),
                        ...material,
                        confirmNum: 0, //到货数量
                        damageNum: 0,  //损坏数量
                        materialCode: material.code ?? material.materialCode,
                        materialName: material.name ?? material.materialName,
                        // materialNameEn:Item.nameEn ?? Item.materialNameEn,
                        price: material.purchasePrice ?? material.price,
                        id: "",
                        note: ""
                    });
                } else {
                    repetition.push(material.name ?? material.materialName);
                }
            });
            return newArr;
        });
        setMaterialDialog(false);
         !!repetition.length && message.warning(`商品名称：【${repetition.join(",")}】，重复的将不在添加。`);
         //message.success(`导入成功！`);
        if (mode === "edit") { setControlled(true) }
    };

    //商品子表删除、编辑模式下，已有id的需要进行数据库删除 (单条及批量删除)
    const removeMaterial = useLockFn(async (mode, selectedRowKeys) => {
        if (mode === 'edit') {
            let ids = editList.filter(item => selectedRowKeys.indexOf(item.key ?? '') >= 0 && item.id).map(item => ({ id: item.id }));
            //存在id在跑接口进行数据库删除
            if (ids.length) {
                let { retData } = await purchasePoService.materialDelete({ ids, purchaseCode: editFormVal.purchaseCode });
                if (!retData) return retData;
            }
        }
        const setList = mode === 'edit' ? setEditList : setAddList;
        setList((origin) => origin.filter((item) => selectedRowKeys.indexOf(item?.key ?? '') < 0));
        message.success("删除成功");
        if (mode === "edit") { setControlled(true) }
    });

    //下载模板
    const downloadTpl = useLockFn(async () => {
        let result = await purchasePoService.downloadTpl();
        convertRes2Blob(result)
    })

    //结算
    const onSettle = useLockFn(async (params) => {

        let { retData } = !IsFranchisees() ? await purchasePoService.settle(params) : await purchasePoService.settleJoin(params);
        getPurchasePoOne(editFormVal.purchaseCode);
        message.success("结算成功");
        return retData
    })

    //库房确认
    const onWarehous = useLockFn(async () => {
        let { id, purchaseCode } = editFormVal;
        let paramas = {
            id,
            purchaseCode,
            storekeeper: userName
        }
        let { retData } = await purchasePoService.warehousing(paramas);
        setWsConfirmVisible(false);
        getPurchasePoOne(purchaseCode);
        message.success("入库成功");
        return retData
    })

    //转销售单
    const insertSaleDetail = useLockFn(async (params) => {
        let newParams = {
            ...params,
            createUser: userName,
            sourceApp, branch,
            shopId, shopName, shopCode, userName, orgName, orgCode,
            orderCode: editFormVal.purchaseCode,
            orderTypeCode: "purchase-type.transfer",
            orderTypeName: "采购单转接"
        }
        let { retData } = await purchasePoService.transfer(newParams);
        message.success("转接成功");
        return retData;
    });


    //各门店库存
    const [stockVisible, setStockVisible] = useState(false);

    return {
        controlled, organType,
        editFormVal,
        editList,
        addFormVal,
        addList,
        lookFormVal,
        lookList,
        addDesc, editDesc, lookDesc,
        materialDialog, setMaterialDialog,
        transferSaleDrawer, setTransferSaleDrawer,
        settleVisible, setSettleVisible,
        wsConfirmVisible, setWsConfirmVisible,
        updateFormVal,
        updateList,
        getPurchasePoOne,
        insertMaterial, removeMaterial,
        insertDetail,
        editDetail,
        downloadTpl,
        onSettle,
        onWarehous,
        insertSaleDetail,
        verifyWhOrShelf,setSaveBtnLoading,saveBtnLoading,
        stockVisible, setStockVisible,setLookFormVal
    }
})