import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useStockStatisticModel } from "../../stockStatisticModel";
import { formatNum } from "@/erp_subpackage/utils/util";

export const ScmDataBox = () => {
    const { statistics, exportStockStatistic } = useStockStatisticModel();

    const desc = [
        {
            label: "期初数量",
            value: statistics?.startNum ?? 0,
            color: "red"
        },
        {
            label: "期初金额",
            value: `￥${formatNum(statistics?.startAmount)}`,
            color: "red"

        },
        {
            label: "库存数量",
            value: statistics?.inventoryNum ?? 0,
            color: "red"
        },
    ]

    const action = <>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => exportStockStatistic()}
        >导出</Button>
    </>;

    return (
        // <YhBox title="库存统计列表" descriptions={desc} action={action}>
        <YhBox title="库存统计列表 (供应链系统)" action={action}>
            <DataTable />
        </YhBox>
    )
}