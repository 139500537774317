import React, { useState } from "react"
import { Select, Input, Col, Row, DatePicker, Checkbox, TreeSelect, Spin } from "antd"
import moment from "moment"
import "./style.less"
import debounce from "lodash/debounce"
import { useEffect } from "react"

const { RangePicker } = DatePicker
const { Option } = Select

export default (props) => {
    const {
        title, // 标题
        searchListData, // 搜索列表
        searchValue = {}, // 搜索初值
        setSearchValue, //搜索方法
        handleSearch, // 搜索方法
        justify = "end", //排列方向
        defaultSearch = {}, //默认有的搜索条件
    } = props
    //设置搜索请求数据
    const [searchData, setSearchData] = useState({})
    //设置默认时间
    const setDefaultTime = (item) => {
        if (!searchValue[item.valueName[0]] && !searchValue[item.valueName[1]]) {
            setSearchValue({
                ...searchValue,
                [item.valueName[0]]: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
                [item.valueName[1]]: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
            })
        }
    }
    const setDefaultDataTime = (item) => {
        if (!searchValue[item.valueName[0]] && !searchValue[item.valueName[1]]) {
            setSearchValue({
                ...searchValue,
                [item.valueName[0]]: moment().startOf("day").format("YYYY-MM-DD"),
                [item.valueName[1]]: moment().endOf("day").format("YYYY-MM-DD"),
            })
        }
    }
    //搜索列表查询
    const searchQuery = (value, item) => {
        setSearchData({
            ...searchData,
            [item.valueName]: {
                data: [],
                fetching: true,
            },
        })
        let sendData = { pageSize: 15, currentPage: 1 }
        if (item.searchItemName) {
            sendData.searchItem = {
                [item.searchItemName]: value,
            }
            if(item.searchItemName === 'customerName'){//如果查询的是客户 需要添加queryTemporaryCustomer字段
                sendData.searchItem.queryTemporaryCustomer = 1
            }
        } else {
            sendData.customerName = value
        }

        item.searchApi(item.searchParam || sendData).then((res) => {
            let results = res.retData.list ?? res.retData
            const data = []
            results.forEach((I) => {
                data.push({
                    text: I[item.optionName] || I.name,
                    value: I[item.optionId] || I.id,
                })
            })
            setSearchData({
                ...searchData,
                [item.valueName]: {
                    data: data,
                    fetching: false,
                },
            })
        })
    }

    //
    const searchChange = (val, option, item) => {
        setSearchValue({
            ...searchValue,
            [item.valueName]: val,
        })
    }
    const [addSearch, setAddSearch] = useState(false) //搜索隐藏 显示
    /**
     * 检索项
     * @param {Object} item 每一项的内容
     * @param {String} item.type 类型 1. input 2. select 3. datepicker
     */
    useEffect(() => {
        searchData &&
            searchData.normalList &&
            searchData.normalList.forEach((item) => {
                if (item.type === "searchSelect") {
                    setSearchData({
                        ...searchData,
                        [item.valueName]: {
                            data: [],
                            fetching: false,
                        },
                    })
                }
            })

        searchData &&
            searchData.hideList &&
            searchData.hideList.forEach((item) => {
                if (item.type === "searchSelect") {
                    setSearchData({
                        ...searchData,
                        [item.valueName]: {
                            data: [],
                            fetching: false,
                        },
                    })
                }
            })
    }, [searchListData])
    //防抖
    const searchQuerys = debounce(searchQuery, 1000)
    const itemCol = (item) => {
        let component = <></>
        switch (item.type) {
            case "searchSelect":
                component = (
                    <Select
                        className="pub-select"
                        showSearch
                        placeholder={item.placeholder}
                        notFoundContent={searchData[item.valueName] && searchData[item.valueName].fetching ? <Spin size="small" /> : null}
                        filterOption={false}
                        onFocus={() => searchQuery("", item)}
                        onSearch={(val) => searchQuerys(val, item)}
                        onChange={(val, option) => searchChange(val, option, item)}
                        style={{ width: "100%" }}
                        value={searchValue[item.valueName]}
                        allowClear
                    >
                        {searchData[item.valueName] &&
                            searchData[item.valueName].data.map((item) => (
                                <Option value={item.value} key={item.value}>
                                    {item.text}
                                </Option>
                            ))}
                    </Select>
                )
                break
            case "input":
                component = (
                    <Input
                        className="search-input"
                        allowClear
                        placeholder={item.placeholder}
                        value={searchValue[item.valueName]}
                        onChange={(e) =>
                            setSearchValue({
                                ...searchValue,
                                [item.valueName]: e.target.value.trim(),
                            })
                        }
                        onPressEnter={() => handleSearch(1, searchValue)}
                    />
                )
                break
            case "select":
                component = (
                    <Select
                        className="pub-select"
                        //disabledClear 禁止清楚传true allowClear取反
                        allowClear={!item.disabledClear}
                        size="small"
                        showSearch={item.showSearch ? true : false}
                        optionFilterProp={item.optionFilterProp || "value"}
                        value={searchValue[item.valueName]}
                        placeholder={item.placeholder}
                        onFocus={item.onFocus || null}
                        onChange={(value) => {
                            setSearchValue({
                                ...searchValue,
                                [item.valueName]: value,
                            })
                            item.selectChange && item.selectChange(item, value)
                            handleSearch(1, {
                                ...searchValue,
                                [item.valueName]: value,
                            })
                        }}
                    >
                        {item.optionList()}
                    </Select>
                )
                break
            case "treeSelect":
                component = (
                    <TreeSelect
                        className="pub-select"
                        allowClear
                        size="small"
                        showSearch={item.showSearch ? true : false}
                        optionFilterProp={item.optionFilterProp || "value"}
                        value={searchValue[item.valueName]}
                        placeholder={item.placeholder}
                        onFocus={item.onFocus || null}
                        onChange={(value) => {
                            console.log(value)
                            setSearchValue({
                                ...searchValue,
                                [item.valueName]: value,
                            })
                            item.selectChange && item.selectChange(item, value)
                        }}
                        treeData={item.data ?? []}
                        treeDataSimpleMode
                    >
                        {/* {item.optionList()} */}
                    </TreeSelect>
                )
                break
            case "RangePicker":
                component = (
                    <RangePicker
                        className="search-input"
                        showTime
                        value={
                            !searchValue[item.valueName[0]] || !searchValue[item.valueName[1]]
                                ? null
                                : [moment(searchValue[item.valueName[0]]), moment(searchValue[item.valueName[1]])]
                        }
                        placeholder={item.placeholder}
                        onChange={(a, arr) =>
                            setSearchValue({
                                ...searchValue,
                                [item.valueName[0]]: arr[0].replace(/\s+/g, "T"),
                                [item.valueName[1]]: arr[1].replace(/\s+/g, "T"),
                            })
                        }
                        onFocus={() => {
                            setDefaultTime(item)
                        }}
                    />
                )
                break
            case "DatePicker":
                component = (
                    <RangePicker
                        className="search-input"
                        format={props.format || "YYYY-MM-DD"}
                        value={
                            !searchValue[item.valueName[0]] || !searchValue[item.valueName[1]]
                                ? null
                                : [moment(searchValue[item.valueName[0]]), moment(searchValue[item.valueName[1]])]
                        }
                        placeholder={item.placeholder}
                        onChange={(a, arr) =>
                            setSearchValue({
                                ...searchValue,
                                [item.valueName[0]]: arr[0],
                                [item.valueName[1]]: arr[1],
                            })
                        }
                        onFocus={() => {
                            setDefaultDataTime(item)
                        }}
                    />
                )
                break
            case "Checkbox":
                component = (
                    <Checkbox
                        checked={item.defaultChecked}
                        onChange={(e) =>
                            setSearchValue({
                                ...searchValue,
                                [item.valueName]: e.target.checked ? 1 : 0,
                            })
                        }
                    >
                        {item.placeholder}
                    </Checkbox>
                )
                break
            default:
                break
        }
        return (
            <Col span={item.span || 8} xxl={item.xxl || 4} key={item.valueName}>
                {component}
            </Col>
        )
    }
    return (
        <div className="toolbar">
            <span className="modules-name">{title}</span>
            {searchListData && (
                <>
                    <div className="pub-search-box">
                        <Row gutter={[16, 10]} justify={justify}>
                            {searchListData.normalList &&
                                searchListData.normalList.map((item) => {
                                    if (!item.hidden) {
                                        return itemCol(item)
                                    }
                                })}
                            {addSearch && searchListData.hideList && searchListData.hideList.map((item) => itemCol(item))}
                        </Row>
                    </div>
                    <div className="pub-search-right" style={{ width: searchListData.hideList ? 270 : 220 }}>
                        <span className="common" onClick={() => handleSearch(1, searchValue)}>
                            搜索
                        </span>
                        <span
                            className="common"
                            onClick={() => {
                                setSearchValue({ ...defaultSearch })
                                handleSearch(1, { ...defaultSearch })
                            }}
                        >
                            重置
                        </span>
                        {searchListData.hideList && (
                            <span className="added-but" onClick={() => setAddSearch(!addSearch)}>
                                {addSearch ? "隐藏" : "更多.."}
                            </span>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}
