import React, { useState, useEffect } from "react";
import { Table, Button, Input, Tooltip } from "antd";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { EditableCell, EditableRow } from "@/erp_subpackage/components/YHEditTable/editTable";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useGlobalModel, transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { YhBut } from "@/erp_subpackage/components/YhButton";
import { goodsService } from "@/erp_subpackage/pages/base/goods/goodsService";
import { formatInt, formatNum } from "@/erp_subpackage/utils/util";
import { usePurchasePoDetailsModel } from './purchasePoDetailModel';
import { warehouseService } from "@/erp_subpackage/pages/base/warehouse/warehouseService";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

const initPgCfg = { pageSize: 10, current: 1 };

export const MaterialDialog = ({ modeKey, mode, rowCode, dispatch }) => {
    const { user: { shopId, shopName, shopCode }, whDs, setMaterialInfo } = useGlobalModel(({ user, whDs }) => [user, whDs]);
    const { materialDialog: visible, setMaterialDialog, insertMaterial } = usePurchasePoDetailsModel(({ materialDialog }) => [materialDialog])
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    const [selectMaterial, setSelectMaterial] = useState([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    const [loading, setLoadding] = useState(false);

    //货位实时数据
    const [shelfDs, setShelfDs] = useState([]);

    useEffect(() => {
        if (visible) {
            loadData()
        } else {
            setSelectMaterial([]);
        };
    }, [visible])

    const onCancel = () => {
        setMaterialDialog(false);
    };

    //加载商品数据
    const loadData = async (page, searchValue = searchParam) => {
        setLoadding(true);
        try {
            let params = {...searchParam,code:searchParam.code?.trim(),name:searchParam.name?.trim()};
            setSearchParam(params)
            const searchParams = {
                ...params,
                shopId, shopName, shopCode,
                enable: 1,
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
                pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
            }
            let { retData } = await goodsService.page(searchParams); 
            const { records, ...pg } = retData;
            setDataSource(records.map(item => ({
                ...item,
                ...(item.materialShelfVo ?? {}),
                num: 1,
                amount: (item.purchasePrice ?? 0) * 1
            })));
            setPgCfg(transPgToPagination(pg));
            setLoadding(false);
        } catch (err) { setLoadding(false) }
    }

    //仓库选择
    const whChange = (value, options) => {
        if (options && options.id) {
            queryShelf(options.value)
        }
    };

    //货位根据仓库code查询
    const queryShelf = async (whCode) => {
        const searchParams = {
            shopId, shopName, shopCode,
            warehouseCode: whCode,
            pageSize: -1,
            pageNum: 1,
        }
        let { retData } = await warehouseService.shelfPage(searchParams);
        const { records, ...pg } = retData;
        setShelfDs(records.filter(item => item.status).map(item => ({
            id: item.id,
            label: item.code,
            value: item.code
        })))
    }

    //货位触焦 
    const shelfFocus = (record) => {
        if (record["warehouseCode"]) {
            queryShelf(record["warehouseCode"])
        } else {
            setShelfDs([]);
        }
    }

    const column = (optype) => [
        {
            title: '商品编号',
            width: 120,
            dataIndex: 'code',
            align: "left",
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.name })} />,
            //render: (text, record, index) => <YhTooltip placement="top" title={text}><p className="one-line click-span" onClick={(e) => { e.stopPropagation(); setMaterialInfo(true, { materialCode: text, materialName: record.name }) }}>{text}</p></YhTooltip>,
        }, {
            title: '商品名称',
            width: 120,
            fixed: "left",
            dataIndex: 'name',
            align: "left",
            render: (text) => <YhTooltip text={text} />,
            //render: (text, record) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text} {record.icon === 1 && <span style={{ background: "orange", color: "white" }}>多</span>} </p></Tooltip>,
        }, {
            title: '单位',
            width: 50,
            dataIndex: 'unitName',
        }, {
            title: '采购数量',
            width: 100,
            dataIndex: 'num',
            align: "left",
            editable: true,
        }, {
            title: '采购单价',
            width: 100,
            dataIndex: 'purchasePrice',
            align: "left",
            editable: true,
        }, {
            title: '采购金额',
            width: 100,
            dataIndex: 'amount',
            align: "left",
        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseCode',
            align: "left",
            // editable: true,
            editType: "select",
            onSelectChange: whChange,
            options: whDs,
            render: text => whDs.find(item => item.value === text)?.label,
            // render: text => whDs.find(item => item.value === text)?.label ?? "请选择仓库",
        }, {
            title: '货位',
            width: 100,
            dataIndex: 'shelfCode',
            align: "left",
            // editable: true,
            editType: "select",
            onSelectFocus: shelfFocus,
            options: shelfDs,
        }, {
            title: '操作',
            width: 60,
            fixed: "right",
            align: "center",
            className: "td-wrap",
            render: (text, record) => optype === "add" ? (
                <YhBut
                    type="add"
                    txt="添加"
                    disabled={!!selectMaterial.find(item => item.id === record.id)}
                    click={() => {
                        //添加商品
                        setSelectMaterial(origin => [...origin, record])
                    }}
                />
            ) : (
                <YhBut
                    type="delete"
                    txt="删除"
                    click={() => {
                        //删除对应商品
                        setSelectMaterial(origin => origin.filter(item => item.id !== record.id))
                    }}
                />
            )
        }
    ];

    const newSelectColumns = (type) => column(type).map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index,
                rowIndex,
                record,
                rules: col.rules,
                options: col.options,
                editType: col.editType,
                editable: col?.editable,
                dataIndex: col.dataIndex,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                title: col.title,
                handleSave: (row) => handleSave(row, type),
            }),
        }
    })

    //内联编辑
    const handleSave = (row, type) => {
        row.num = +formatNum(row.num);
        row.purchasePrice = +formatNum(row.purchasePrice);
        //采购金额 = 采购数量 * 单价
        row.amount = +formatNum(row.num * row.purchasePrice);

        //选中货位的话， 赋值货位code
        if (row.shelfCode) {
            row.shelfId = shelfDs?.find(item => item.value === row.shelfCode)?.id;
        }
        //设置仓库名称
        if (row.warehouseCode) {
            row.warehouseName = whDs.find(item => item.value === row.warehouseCode)?.label
        }

        (type === "add" ? setDataSource : setSelectMaterial)(origin => {
            const newDataSource = [...origin];

            const index = newDataSource.findIndex((item) => row.id === item.id);
            const item = newDataSource[index];
            //判断仓库变化时，  重置货位
            if (row.warehouseCode !== newDataSource[index].warehouseCode) {
                row.shelfCode = undefined
            }
            newDataSource.splice(index, 1, { ...item, ...row });
            return newDataSource;
        });
    }


    const rightChildren = <div className="flex">
        <Input
            allowClear
            size="small"
            className="border-r-16 mr-10"
            value={searchParam.code}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchParam({ ...searchParam, code: e.target.value }) }}
            placeholder="商品编号"
            onPressEnter={() => loadData(initPgCfg)}
        />
        <Input
            allowClear
            size="small"
            className="border-r-16 mr-10"
            value={searchParam.name}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchParam({ ...searchParam, name: e.target.value }) }}
            placeholder="商品名称"
            onPressEnter={() => loadData(initPgCfg)}
        />
        <Button onClick={() => loadData()} style={{ margin: "0px 5px 0px 10px" }} shape="round">检索</Button>
        <Button onClick={() => {
            loadData(initPgCfg, {});
            setSearchParam({})
        }} shape="round">重置</Button>
    </div>

    return <YHModal
        visible={visible}
        okText="确认添加"
        onOk={() => insertMaterial(mode, selectMaterial)}
        onCancel={onCancel}
        title="采购单--选择商品"
        width={1050}
        bodyHeight={730}
        showFooter={true}
    >
        <YhBox title="待选商品列表" rightChildren={rightChildren} style={{ margin: "0" }}>
            <Table
                loading={loading}
                rowKey={record => record.id}
                rowClassName='editable-row yh-edit-table-tr'
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns("add")}
                dataSource={dataSource}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize })
                    }
                }}
                scroll={{ x: 'max-content', y: 210 }}
            />
        </YhBox>
        <YhBox title="已选商品列表" style={{ margin: "5px 0 0" }}>
            <Table
                rowKey={record => record.id}
                rowClassName='editable-row yh-edit-table-tr'
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns("delete")}
                dataSource={selectMaterial}
                pagination={false}
                scroll={{ x: 'max-content', y: 210 }}
            />
        </YhBox>
    </YHModal>
}