import React from "react";
import { Button, Input, Table, message, Upload, Tooltip, Space, Switch } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useWarehouseModel } from "../../warehouseModel";
import { importPropsConfig } from "@/erp_subpackage/utils/importFile";

//货位
export const ShelfTable = () => {
    const {
        shelfPgCfg, shelfDataSource, setSelectedShelfRows, shopId,
        selectedShelfRows, shelfLoadData, setIShelfDrawer,
        shelfSearch, setShelfSearch, downloadTplShelf, onDisabled
    } = useWarehouseModel();


    const action = <div className="flex items-center">
        <Tooltip placement="topLeft" title="回车进行检索">
            <Input
                allowClear
                datatype="round"
                placeholder="货位编码"
                className="mr-10"
                value={shelfSearch?.["code"]}
                onChange={(e) => setShelfSearch({ ...shelfSearch, "code": e.target.value })}
                onPressEnter={shelfLoadData}
            />
        </Tooltip>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => setIShelfDrawer("add")}
        >新增</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (selectedShelfRows.length !== 1) {
                    message.warning("请选择单条数据进行编辑");
                    return;
                }
                setIShelfDrawer("edit");
            }}
        >编辑</Button>
        <Upload showUploadList={false}  {...importPropsConfig(`/ci-storage/base/warehouse/shelf/import/${shopId}`, shelfLoadData, { shopId })}>
            <Button className="btn-item" shape="round">导入</Button>
        </Upload>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => downloadTplShelf()}
        >下载模板</Button>
    </div>


    const columns = [
        {
            title: '序号',
            align: "center",
            render: (t, r, i) => <>{i + 1}</>
        },
        {
            title: '货位编号',
            dataIndex: 'code',
        },
        {
            title: '所属仓库',
            dataIndex: 'warehouseName',
        },
        {
            title: '创建人',
            align: "center",
            dataIndex: 'createUser',
        },
        {
            title: '创建时间',
            align: "center",
            dataIndex: 'createTime',
        },
        {
            title: '备注信息',
            align: "center",
            dataIndex: 'note',
        }, {
            title: "操作",
            align: "center",
            dataIndex: 'status',
            render: (text, record) => (
                <Space size="middle">
                    <Switch checked={text === 1} onChange={(checked) => {
                        onDisabled(record, checked);
                    }} />
                </Space>
            ),
        },
    ];

    const onRow = (record) => {
        let findArr = selectedShelfRows.find(item => item.id === record.id);
        if (findArr) {
            let filterArr = selectedShelfRows.filter(item => item.id !== record.id);
            setSelectedShelfRows(filterArr);
        } else {
            setSelectedShelfRows(origin => [...origin, record]);
        }
    };

    return (
        <YhBox title={<span style={{ paddingLeft: "20px" }}>货位信息</span>} rightChildren={action} style={{ padding: "0", marginBottom: "10px" }}>
            <Table
                rowKey={record => record.id}
                rowSelection={{
                    selectedRowKeys: selectedShelfRows.map(item => item.id), //记录选中
                    onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedShelfRows(selectedRows);
                    }
                }}
                columns={columns}
                dataSource={shelfDataSource}
                pagination={{
                    ...shelfPgCfg,
                    position: ["bottomCenter"],
                    showTotal: () => `共${shelfPgCfg.total}条`,
                    onChange: (pageNum, pageSize) => {
                        shelfLoadData({ pageNum, pageSize })
                    }
                }}
                onRow={record => ({
                    onClick: () => onRow(record)
                })}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}

