import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import TopSearch from "@/components/TopSearch"
import { tdmsMsgSearch, tdmsPictureDelete, tdmsMsgChange } from "@/service/tdmsService"
import { MyContext } from "@/store/myContext"
import { fileLookUrl } from "@/config/app"
import { Button, Image, Modal } from "antd"
import moment from "moment"

export default () => {
    const { dispatch, state, message, detailsPage, Switch } = useContext(MyContext)
    const [dataSource, setDataSource] = useState([]) //列表
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [searchValue, setSearchValue] = useState({}) //搜索
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })

    useEffect(() => {
        if (state.activeMenu === "tdmsMsg") {
            loadData()
        }
        return () => setDataSource([])
    }, [state.activeMenu])

    //列表数据
    const loadData = (current, searchValue = {}) => {
        let data = {
            typeId: 1,
            page: {
                pageNum: current,
                pageSize: 10,
            },
        }
        tdmsMsgSearch(data).then((res) => {
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setDataSource(res.retData.records)
        })
    }
    //分页事件
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }
    //新增 编辑
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "品牌新增",
            edit: "品牌编辑",
        }
        let modeKey = "tdmsMsg/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的信息")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.BrandManageDetails({
                modeKey,
                dispatch,
                mode,
                rowInfo: selectInfo.rowInfo,
            }),
        })
    }
    //删除
    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的品牌信息")
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除品牌 - ${selectInfo.rowInfo.name}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                // vehicleBrandDelete(selectInfo.rowInfo.id).then((res) => {
                //     message.success("删除成功")
                //     loadData(pagination.current)
                // })
            },
        })
    }
    //配置开关
    const sweitchChange = (checked, e, id) => {
        e.stopPropagation()
        // vehicleBrandDisable({ id: id, disableFlag: checked ? 0 : 1 }).then((res) => {
        //     message.success("操作成功")
        // })
        loadData(pagination.current, searchValue)
    }
    const goApproval = (id, dataId) => {
        console.log(dataId)
        if (!id) return message.error("信息错误")
        tdmsMsgChange(id).then((res) => {
            const modeKey = `tdmsApproval/edit/${dataId}`
            dispatch({
                type: "changeTabs",
                name: "资料审核-审核",
                activeMenu: modeKey,
                content: detailsPage.ApprovalDetails({
                    modeKey,
                    dispatch,
                    mode: "approval",
                    rowInfo: { dataId },
                }),
            })
        })
    }

    //table配置
    const columns = [
        {
            title: "状态",
            align: "center",
            dataIndex: "status",
            width: "100px",
            render: (status) => (status === 1 ? <span style={{ color: "red" }}>未读</span> : <span>已读</span>),
        },
        {
            title: "标题",
            align: "center",
            dataIndex: "title",
            with: 100,
        },
        {
            title: "时间",
            align: "center",
            dataIndex: "createTime",
            width: "200px",
            render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            title: "操作",
            align: "center",
            width: "200px",
            render: (text, record) =>
                record.approvalStatus === 2 ? (
                    <Button style={{ backgroundColor: "green", color: "#ffffff" }}>审核通过</Button>
                ) : record.approvalStatus === 3 ? (
                    <Button style={{ backgroundColor: "red", color: "#ffffff" }}>审核驳回</Button>
                ) : (
                    <Button style={{ backgroundColor: "orange", color: "#ffffff" }} onClick={() => goApproval(record.id, record.dataId)}>
                        去审核
                    </Button>
                ),
        },
    ]
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleDelete(),
            content: "删除",
        },
    ]

    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "品牌名称",
                valueName: "name",
            },
            {
                type: "Checkbox",
                valueName: "disableFlag",
                placeholder: "禁用状态",
                defaultChecked: searchValue.disableFlag ? true : false,
            },
        ],
    }
    return (
        <>
            {state.activeMenu === "tdmsMsg" && (
                <>
                    {/* <TopSearch
                        title="品牌管理"
                        justify="end"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    /> */}
                    <ListComponent
                        title="审核消息"
                        // buttons={topButtons}
                        columns={columns}
                        dataSource={dataSource}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        scroll={{ x: "100%" }}
                    />
                </>
            )}
        </>
    )
}
