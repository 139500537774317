import { post, get } from "../utils/request"

/**
 * 维保套餐。
 */
//列表
function getMaintenancePackageList(data) {
    return post("/ci/maintenance_package/list", data)
}
//复制
function copyMaintenancePackageOne(id) {
    return get(`/ci/maintenance_package/copy/${id}`)
}
//删除
function deleteMaintenancePackageOne(id) {
    return post(`/ci/maintenance_package/delete/${id}`)
}
//获取一条数据
function getMaintenancePackageOne(id) {
    return get(`/ci/maintenance_package/one/${id}`)
}
//新增
function maintenancePackageInsert(data) {
    return post("/ci/maintenance_package/insert", data)
}
//编辑
function maintenancePackageEdit(data) {
    return post("/ci/maintenance_package/update", data)
}
//禁用
function disableMaintenancePackage() {
    return post("/ci/maintenance_package/disable")
}
//套餐下维修物料分页
function getPackagePart(data) {
    return post("/ci/maintenance_package/part/list", data)
}
//套餐下维修物料分页
function getPackageItem(data) {
    return post("/ci/maintenance_package/item/list", data)
}

/**
 * 套餐规则管理(车型匹配)
 */
//列表
function getPackageRuleList(data) {
    return post("/ci/maintenance_package_rule/list", data)
}
//新增和修改
function packageRuleSave(data) {
    return post("/ci/maintenance_package_rule/save_rule", data)
}
//获取一条数据
function getRuleOne(id) {
    return get(`/ci/maintenance_package_rule/one/${id}`)
}
//规则删除
function deleteRuleOne(id) {
    return post(`/ci/maintenance_package_rule/delete/${id}`)
}

/**
 * 套餐详情下面的规则关联信息管理
 */
//列表
function getRuleJoinList(data) {
    return post("/ci/maintenance_package_rule_join/list", data)
}
//新增和修改
function packageRuleJoinSave(data) {
    return post("/ci/maintenance_package_rule_join/save", data)
}
//删除
function deleteRuleJoinOne(id) {
    return post(`/ci/maintenance_package_rule_join/delete/${id}`)
}
//复制
function copyRuleJoinOne(packageRuleJoinId) {
    return get(`/ci/maintenance_package_rule_join/copy/${packageRuleJoinId}`)
}
//套餐规则下维修物料分页
function getPackageJoinPart(data) {
    return post("/ci/maintenance_package_rule_join/part/list", data)
}
//套餐规则下维修项目分页
function getPackageJoinItem(data) {
    return post("/ci/maintenance_package_rule_join/item/list", data)
}
//获取一条数据
function getPackageJoinOne(packageRuleJoinId) {
    return get(`/ci/maintenance_package_rule_join/one/${packageRuleJoinId}`)
}

/**
 * 模块维护
 */
//列表
function getModuleList(data) {
    return post("/ci/maintenance_model/list", data)
}
//获取一条数据
function getModuleOne(id) {
    return get(`/ci/maintenance_model/one/${id}`)
}
//新增
function moduleInsert(data) {
    return post("/ci/maintenance_model/insert", data)
}
//编辑
function moduleEdit(data) {
    return post("/ci/maintenance_model/update", data)
}

export {
    getMaintenancePackageList,
    getMaintenancePackageOne,
    maintenancePackageInsert,
    maintenancePackageEdit,
    deleteMaintenancePackageOne,
    disableMaintenancePackage,
    getModuleList,
    getModuleOne,
    moduleInsert,
    moduleEdit,
    getPackagePart,
    getPackageItem,
    getPackageRuleList,
    copyMaintenancePackageOne,
    packageRuleSave,
    getRuleOne,
    deleteRuleOne,
    packageRuleJoinSave,
    getRuleJoinList,
    deleteRuleJoinOne,
    getPackageJoinPart,
    getPackageJoinItem,
    getPackageJoinOne,
    copyRuleJoinOne,
}
