import React, { useContext, useState, useEffect, useRef } from "react"
import ListComponent from "@/components/listComponent"
import { MyContext } from "@/store/myContext"
import { fileLookUrl } from "@/config/app"
import { Image, Modal, Carousel, Tabs, Button } from "antd"
import { LeftOutlined, RightOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { tdmsPictureSearch, tdmsPictureDelete } from "@/service/tdmsService"
import EditModal from "./editModal"

export default () => {
    const { dispatch, state, message, detailsPage, Switch } = useContext(MyContext)
    const [dataSource, setDataSource] = useState([]) //列表
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [actived, setActived] = useState("focus") // focus 焦点图 tile 瓷片
    const [mode, setMode] = useState("")
    const [editVisible, setEditVisible] = useState(false)
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [editModalMode, setEditModalMode] = useState("")
    const [editModelFlag, setEditModelFlag] = useState(false)

    const CarouselRef = useRef()

    useEffect(() => {
        if (state.activeMenu === "tdmsFocus") {
            loadData()
        }
        return () => setDataSource([])
    }, [state.activeMenu, actived])

    //列表数据
    const loadData = (current = 1, searchValue = {}) => {
        let data = {
            classify: actived === "focus" ? 2 : 3,
            page: {
                pageNum: current,
                pageSize: 10,
            },
            // orders: [{ column: "updateTime", rule: "DESC" }],
        }
        tdmsPictureSearch(data).then((res) => {
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setDataSource(res.retData.records)
        })
    }
    //分页事件
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }
    //新增 编辑
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "品牌新增",
            edit: "品牌编辑",
        }
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的信息")
                return
            }
            setMode("edit")
        } else {
            setMode("add")
        }
        setEditVisible(true)
    }
    //删除
    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的信息")
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除 - ${selectInfo.rowInfo.name}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                tdmsPictureDelete(selectInfo.rowInfo.id).then((res) => {
                    message.success("删除成功")
                    loadData(pagination.current)
                })
            },
        })
    }

    //table配置
    const columns = [
        {
            title: "缩略图",
            align: "center",
            dataIndex: "fileMd5",
            render: (text) => (text ? <Image src={`${fileLookUrl().image}${text}`} width={68} height={68} /> : null),
        },
        {
            title: "名称",
            align: "center",
            dataIndex: "name",
            with: 100,
        },
        {
            title: "排序",
            align: "center",
            dataIndex: "orderNum",
            with: 100,
        },
        {
            title: "URL",
            align: "center",
            dataIndex: "url",
            with: 100,
        },
    ]
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("add"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleDelete(),
            content: "删除",
        },
    ]

    const contentStyle = {
        width: "100%",
        height: "160px",
        color: "#fff",
        lineHeight: "160px",
        textAlign: "center",
        background: "#364d79",
    }

    const onClickButton = (type) => {
        if (actived === type) return
        setActived(type)
        setSelectInfo({ rowInfo: {}, rowIndex: -1 })
        setEditModalVisible(false)
    }

    const onItemEvent = (type) => {
        const index = CarouselRef.current.innerSlider.state.currentSlide
        const item = dataSource[index]
        setSelectInfo({ rowInfo: item, rowIndex: index })
        setEditModalMode(type)
        setEditModelFlag(!editModelFlag)
    }

    useEffect(() => {
        if (editModalMode === "edit") {
            handleDetails("edit")
        } else if (editModalMode === "delete") {
            handleDelete()
        }
    }, [editModelFlag])

    return (
        <>
            {state.activeMenu === "tdmsFocus" && (
                <>
                    <Button style={{ margin: "15px" }} type={actived === "focus" ? "primary" : "default"} onClick={() => onClickButton("focus")}>
                        焦点图
                    </Button>
                    <Button style={{ margin: "15px" }} type={actived === "tile" ? "primary" : "default"} onClick={() => onClickButton("tile")}>
                        瓷片区
                    </Button>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Button shape="circle" icon={<LeftOutlined />} onClick={() => CarouselRef.current.prev()}></Button>
                        <div
                            style={{ display: "block", position: "relative", width: "720px", margin: "10px" }}
                            onClick={() => setEditModalVisible(!editModalVisible)}
                        >
                            <Carousel autoplay ref={CarouselRef}>
                                {dataSource.map((item) => (
                                    <div key={item.id}>
                                        <img src={`${fileLookUrl().image}${item.fileMd5}`} width="720px" alt="" />
                                    </div>
                                ))}
                            </Carousel>
                            {editModalVisible ? (
                                <div
                                    style={{
                                        position: "absolute",
                                        display: "flex",
                                        flexFlow: "row ",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0,
                                        background: "rgba(0,0,0,.5)",
                                    }}
                                >
                                    <Button size="large" shape="circle" icon={<EditOutlined />} onClick={() => onItemEvent("edit")} />
                                    <Button size="large" shape="circle" icon={<DeleteOutlined />} onClick={() => onItemEvent("delete")} />
                                </div>
                            ) : null}
                        </div>
                        <Button shape="circle" icon={<RightOutlined />} onClick={() => CarouselRef.current.next()}></Button>
                    </div>
                    <ListComponent
                        buttons={topButtons}
                        columns={columns}
                        dataSource={dataSource}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        scroll={{ x: "100%" }}
                    />
                </>
            )}
            <EditModal
                mode={mode}
                classify={actived === "focus" ? 2 : 3}
                row={selectInfo.rowInfo}
                visible={editVisible}
                onLoadData={loadData}
                onCancel={(bool) => setEditVisible(bool)}
            />
        </>
    )
}
