import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react"
import { Table, message } from "antd"
import { EditableRow, EditableCell } from "./editable"
import { Button } from "antd"
import YhIcon from "@/components/pubIcon"
import { attrFileTypeObj, requiredObj, disableFlagObj } from "./emun"
//项目
export default forwardRef(({ defaultData },ref) => {
    const [data, setData] = useState([]) //表格数据

    useEffect(() => {
        setData(defaultData)
    }, [defaultData])

    useImperativeHandle(ref, () => ({
        getData,
    }))

    const getData = () => {
        if (!isEmpty()) return false
        return data
    }
    //选中展示table配置
    const columns = [
        {
            title: "附件名称",
            dataIndex: "attrName",
            editable: true,
        },
        {
            title: "描述信息",
            dataIndex: "attrDesc",
            editable: true,
        },
        {
            title: "文件类型",
            dataIndex: "attrFileType",
            editable: true,
            render: (text) => <span>{attrFileTypeObj[text]}</span>,
        },
        {
            title: "是否必填",
            dataIndex: "required",
            editable: true,
            render: (text) => <span>{requiredObj[text]}</span>,
        },

        {
            title: "排序",
            dataIndex: "componentSort",
            editable: true,
        },
        {
            title: "禁用状态",
            dataIndex: "disableFlag",
            render: (text) => <span>{disableFlagObj[text]}</span>,
            editable: true,
        },
        {
            title: "操作",
            align: "center",
            width: "80px",
            dataIndex: "operation",
            render: (_, record) => {
                return (
                    <YhIcon
                        type="icon-guanbi"
                        style={{ color: "#D81E06", cursor: "pointer" }}
                        onClick={() => handleDeleteOne(record.id || record.temporaryId)}
                    />
                )
            },
        },
    ]

    //单元格编辑保存
    const handleSave = (row) => {
        const newData = [...data]
        let index
        if (row.id) {
            index = newData.findIndex((item) => row.id === item.id)
        } else {
            index = newData.findIndex((item) => row.temporaryId === item.temporaryId)
        }
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }
    const maintenanceColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //判断数据是否可以保存或者新增
    const isEmpty = () => {
        let isEmpty = true
        if (data.length === 0) return isEmpty
        for (let i = 0; i < data.length; i++) {
            if (!data[i].attrName) {
                message.error(`第${i + 1}项的附件名称未填`)
                return false
            }
            if (!data[i].attrFileType) {
                message.error(`第${i + 1}项的文件类型未填`)
                return false
            }
        }
        return isEmpty
    }

    //新增一条数据
    const handleAdd = () => {
        if (isEmpty()) {
            setData([
                ...data,
                {
                    temporaryId: new Date().getTime(),
                    required:  0,
                    disableFlag: 0,
                    componentSort: 1,
                    attrFileType: "img"
                },
            ])
        }
    }

    /**
     * 以下是页面展示
     */
    const [choosedItem, setChoosedItem] = useState([]) // 批量删除的key
    //批量删除多选的成员id
    const maintenanceSelection = {
        columnWidth: "30px",
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRowKeys)
            setChoosedItem(selectedRowKeys)
        },
    }

    //批量删除
    const deleteTemaAll = () => {
        if (choosedItem.length === 0) {
            message.warning("请选择你要删除的维修项目")
            return
        }
        let arr = data.filter((item) => choosedItem.indexOf(item.id || item.temporaryId) < 0)
        setData(arr)
        setChoosedItem([])
    }

    //删除
    const handleDeleteOne = (keys) => {
        const newData = []
        data.forEach((item) => {
            if (keys != item.id && keys != item.temporaryId) {
                newData.push(item)
            }
        })
        setData(newData)
    }

    return (
        <>
            <div className="list-but" style={{ marginBottom: "20px" }}>
                <Button shape="round" className="but-left" style={{ marginRight: "20px" }} onClick={handleAdd}>
                    新增
                </Button>
                <Button shape="round" className="but-left" onClick={deleteTemaAll}>
                    批量删除
                </Button>
            </div>
            <Table
                size="small"
                rowKey={(record) => (record.id ? record.id : record.temporaryId)}
                components={components}
                columns={maintenanceColumns}
                dataSource={data}
                pagination={false}
                scroll={{ y: 660 }}
                rowSelection={maintenanceSelection}
            />
        </>
    )
}
)