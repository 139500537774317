import { useState, useEffect } from "react";
import { createModel } from "hox";
import { internalService } from "./internalService";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";

export const useInternalModel = createModel(function () {
    const { user: { shopId, orgShopCode }, accUnitDs } = useGlobalModel();
    const initPgCfg = { pageSize: 15, current: 1 }
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedData, setSelectedData] = useState();

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.code === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedData(selectedData)
    }, [selectedRowKeys]);

    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    const loadData = async (page, searchValue = searchParam) => {
        const searchParams = {
            shopId,
            ...searchValue,
            unitCode: accUnitDs.find(item => item.shopCode == orgShopCode)?.code,
            adverseUnitCode: accUnitDs.find(item => item.shopCode == orgShopCode)?.code,
            page: {
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
                pageNum: page?.pageNum ?? pgCfg.current ?? initPgCfg.current,
            }
        }
        let { retData } = await internalService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
    }
    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        /***method***/
        setSearchParam,
        setDataSource,
        setSelectedRowKeys,
        setPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData,
        selectedData, setSelectedData
    }
})