import React from "react";
import { FormBox } from "./FormBox";
import { ShelfTable } from "./ShelfTable";
import { ShelfDrawer } from "./ShelfDrawer";

export default (props) => {
    return (
        <div className="yh-detail-box">
            <FormBox {...props} />
            <ShelfTable {...props} />
            <ShelfDrawer {...props} />
        </div>
    )
}