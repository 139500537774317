import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";
import { PORTAL_API_SERVER_HOST, API_SERVER_HOST } from "@/config/app";
import { getPortalToken } from "@/utils/dataStorage";
class SupplierService {
    async page(searchVo) {
        const apiUrl = "/ci-storage/base/supplier/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async one(searchVo) {
        const apiUrl = `/ci-storage/base/supplier/findById`;
        return await httpRequest.post(apiUrl,searchVo);
    }
    async insert(insertVo) {
        const apiUrl = "/ci-storage/base/supplier/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async update(updateVo) {
        const apiUrl = "/ci-storage/base/supplier/update";
        return await httpRequest.post(apiUrl, updateVo);
    }

    //启用禁用
    async disable(params) {
        const apiUrl = `/ci-storage/base/supplier/change`;
        return await httpRequest.post(apiUrl, params);
    }
    //导入
    async import(file) {
        const appPageUrl = "/ci-storage/base/supplier/import";
        return await httpRequest.post(appPageUrl, file);
    }
    //导出
    async export(searchVo) {
        const appPageUrl = "/ci-analyze/export/supplier";
        return await httpRequest.post(appPageUrl, searchVo);
    }
    //供应商转客户
    async transfer(id) {
        const appPageUrl = `/ci-storage/base/supplier/transfer/${id}`;
        return await httpRequest.post(appPageUrl);
    }
    //同步
    async sync(data) {
        const appPageUrl = `/ci-storage/base/supplier/sync`;
        return await httpRequest.post(appPageUrl, data, { timeout: 300000 });
    }
    //下载模板
    async downloadTpl() {
        const appPageUrl = "/ci-storage/base/supplier/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }
    //财务供应商同步
    async  supplierSync(syncVo) {
        const appPageUrl = "/fms/supplier/sync";
        syncVo.sourceApp = "fms";
        return await httpRequest.post(appPageUrl, syncVo,{ baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken()});
    }
}

export const supplierService = new SupplierService()
