import React, { useEffect, useState } from "react"
import { Button, Form, Input, Col, Row, message, Switch, Upload, Modal } from "antd"
import { insertInstrumentLightLlassify, updateInstrumentLightLlassify, getInstrumentLightLlassifyOne } from "@/service/instrumentLamp"
import { fileUrl } from "@/config/app.js"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"
import {  getToken } from "@/utils/dataStorage"

const SystemDetails = (props) => {
    const { modeKey, dispatch, mode, rowInfo } = props
    const [form] = Form.useForm()
    const [previewVisible, setpreviewVisible] = useState(false) //预览图片使用的dialog
    const [previewImage, setpreviewImage] = useState("") //预览展示的行驶证
    const [licenseFileList, setLicenseFileList] = useState([]) //图片list

    useEffect(() => {
        resetFormItem()
    }, [])
    const systemSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                classifyShow: values.classifyShow ? 1 : 0,
                classifyImage: previewImage,
            }
            //新增
            if (mode === "insert") {
                insertInstrumentLightLlassify(data)
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            handleCancel()
                            message.success("新增成功")
                        // } else {
                        //     message.error(res.retMsg)
                        // }
                    })
                    // .catch((err) => {
                    //     message.error(err.retMsg)
                    // })
                //编辑
            } else if (mode === "edit") {
                updateInstrumentLightLlassify({ ...data, id: rowInfo.id })
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            handleCancel()
                            message.success("编辑成功")
                        // } else {
                        //     message.error(res.retMsg)
                        // }
                    })
                    // .catch((err) => {
                    //     message.error(err.retMsg)
                    // })
            }
        })
    }

    //行驶证图片查看
    const handlePreview = async (file) => {
        setpreviewVisible(true)
    }
    // 图片上传
    const handleImgChange = async (info) => {
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setpreviewImage(md5)
            console.log(info.fileList)
            setLicenseFileList(info.fileList)
        } else if (info.file.status === "removed") {
            setLicenseFileList([])
            setpreviewImage("")
        }
    }
    //回显表单项
    const resetFormItem = () => {
        if (mode === "edit") {
            getInstrumentLightLlassifyOne(rowInfo.id).then((res) => {
                // if (res && res.retCode === 200) {
                    let result = res.retData
                    let imgFileList = []
                    if (result.classifyImage) {
                        //回显图片， 有的话在做回显
                        imgFileList = [
                            {
                                uid: "-1",
                                status: "done",
                                url: `${fileLookUrl().image}${result.classifyImage}`,
                            },
                        ]
                        //回显图片
                        setLicenseFileList(imgFileList)
                        setpreviewImage(result.classifyImage)
                    }
                    form.setFieldsValue({
                        ...res.retData,
                        classifyImage: imgFileList,
                        classifyShow: res.retData.classifyShow === 0 ? true : false,
                    })
                // }
            })
        } else {
            //新增默认推荐展示
            form.setFieldsValue({
                classifyShow: true,
            })
        }
    }

    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }


    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    //取消
    const handleCancel = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "instrumentLampType",
        })
    }
    return (
        <>
            <div className="toolbar">
                <span className="modules-name">仪表灯类型</span>
            </div>
            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>仪表灯类型</span>
                    </div>
                    <div className="list-but">
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={systemSubmit}>
                            保存
                        </Button>
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={handleCancel}>
                            取消
                        </Button>
                    </div>
                </div>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={100}>
                            <Col offset={3} span={9}>
                                <Form.Item
                                    label="分类名称"
                                    name="classifyName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "分类名称不可为空",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入分类名称" />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item
                                    label="分类描述"
                                    name="classifyDescribe"
                                    rules={[
                                        {
                                            required: true,
                                            message: "分类描述不可为空",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入分类描述" />
                                </Form.Item>
                            </Col>
                            <Col offset={3} span={9}>
                                <Form.Item
                                    label="排序"
                                    name="classifySort"
                                >
                                    <Input placeholder="请输入排序" />
                                </Form.Item>
                            </Col>
                            <Col  span={9}>
                                    <Form.Item label="图片" name="classifyImage" valuePropName="fileList" getValueFromEvent={normFile}>
                                        <Upload
                                            // disabled={isLook(mode)}
                                            accept="image/*"
                                            headers={{ Authorization: getToken() }}
                                            action={`${fileUploadUrl}upload/image_old`}
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            fileList={licenseFileList}
                                            onPreview={handlePreview}
                                            onChange={handleImgChange}
                                        >
                                            {licenseFileList.length >= 1 ? null : <Button>上传图片</Button>}
                                        </Upload>
                                    </Form.Item>
                                </Col>
                        </Row>
                        <Row gutter={100}>
                            <Col offset={3} span={9}>
                                <Form.Item name="classifyShow" label="是否展示" valuePropName="checked">
                                    <Switch checkedChildren="是" unCheckedChildren="否" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <Modal
                visible={previewVisible}
                title="图片查看"
                onCancel={() => setpreviewVisible(false)}
                onOk={() => setpreviewVisible(false)}
            >
                <img alt="" style={{ width: "100%" }} src={`${fileLookUrl().image}${previewImage}`} />
            </Modal>
        </>
    )
}
export default SystemDetails
