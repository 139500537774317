import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";

//discountPrice 优惠价  shopPrice 商场价 tradePrice 批发价 itemCode 商品编码  itemName 商品名称  
//categoryCode 所属分类  categoryName 所属分类名称

//详情
// export interface detailOne {
//     "id": "80"
//     "code": "80"
//     "typeCode": "80"
//     "typeName": "积改做老"
//     "attrCode": "69"
//     "attrName": "行情产克"
//     "name": "影消三再"
//     "answer": "答案"
//     "status": "状态"
//     "details": [
//         {
//             "id": "80"
//             "code": "80"
//             "key": "in in et Lorem"
//             "value": "minim"
//             "status": "in ea anim ipsum aliquip"
//         }
//     ]
// }

class QuestionService {
    async page(searchVo) {
        const apiUrl = "/exam/questionBank/page";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async one(id) {
        const apiUrl = `/exam/questionBank/findByIdOrCode/${id}`;
        return await httpRequest.get(apiUrl);
    }
    async insert(insertVo) {
        const apiUrl = "/exam/questionBank/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async update(updateVo) {
        const apiUrl = "/exam/questionBank/update";
        return await httpRequest.post(apiUrl, updateVo);
    }
    //启用禁用
    async disable(id, status) {
        const apiUrl = `/exam/questionBank/enableOrDisable/${id}/${status}`;
        return await httpRequest.get(apiUrl);
    }
    //删除
    async remove(id) {
        const apiUrl = `/exam/questionBank/delete/${id}`;
        return await httpRequest.get(apiUrl);
    }
    //导入
    async import(file) {
        const appPageUrl = "/exam/questionBank/import";
        return await httpRequest.post(appPageUrl, file);
    }
    //导出
    async export(searchVo) {
        const appPageUrl = "/exam/questionBank/export";
        return await httpRequest.postFile(appPageUrl, searchVo);
    }
    //下载模板
    async downloadTpl() {
        const apiUrl = "/exam/questionBank/downloadTpl";
        return await httpRequest.getFile(apiUrl);
    }
    /**配置信息 */
    async settingOne(code) {
        const apiUrl = `/exam/setting/findByCode/${code}`;
        return await httpRequest.get(apiUrl);
    }
    async settingInsert(insertVo) {
        const apiUrl = "/exam/setting/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async settingUpdate(updateVo) {
        const apiUrl = "/exam/setting/update";
        return await httpRequest.post(apiUrl, updateVo);
    }
}

export const questionService = new QuestionService();