import React, { useState, useEffect } from 'react'
import { SaleScaniaPrint } from "../../../../components/SaleScaniaPrint"
import { formatNum, numberParseChina, momentFormat, mergeRepeatArr } from '../../../../utils/util';
import { saleSoService } from '../saleSoService';
import { useSaleSoModel } from '../saleSoModel';
import moment from 'moment';
// import useGlobalModel from '../../../../model/globalModel';
export const SettleScaniaPrints = ({
}) => {
    // // settleScaniaPrint, setSettleScaniaPrint
    // const { shopAndOrg: { shopCode } } = useGlobalModel()

    const { settleScaniaPrint: visible, selectedRow, setSettleScaniaPrint } = useSaleSoModel()
    const onClose = () => {
        setSettleScaniaPrint(false)
    }
    const print = () => {

    }
    const [formVal, setFormVal] = useState({});
    const [maintenanceList, setMaintenanceList] = useState([]);
    // const [itemList, setItemList] = useState<IItemDetailList[]>([]);
    useEffect(() => {
        visible && getDetailOne()
    }, [visible])
    const getDetailOne = async () => {
        let { retData } = await saleSoService.one(selectedRow.saleCode);
        let { details,createTime, receivedAmount, settleTime, address,...from } = retData;
        receivedAmount = +formatNum(receivedAmount);
        let mergeDetails =await mergeRepeatArr(details);

        const newMaterialDetails = mergeDetails?.map((item, index) => {
            return {
                ...item,
                No: index + 1
            }
        })
        if (retData) {
            setMaintenanceList(newMaterialDetails?? []);
            // setItemList(newItemDetails as IItemDetailList[] ?? []);
            setFormVal({
                ...from,
                settleTime:momentFormat(settleTime),
                maintenanceCode:from.saleCode,
                receivableTotalAmount: receivedAmount,
                receivableTotalAmountCN: numberParseChina(receivedAmount),
                enterTime: momentFormat(createTime),
                materialScienceAmount:receivedAmount,
                customerAddress:address
                // discountTotalAmount:discountTotalAmount
            })
        }

    }
    const itemClumns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '项目编号',
            dataIndex: 'itemCode',
        },
        {
            title: '项目名称',
            dataIndex: 'itemName',
        },
        {
            title: '工时',
            dataIndex: 'workHour',
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        {
            title: '折扣',
            dataIndex: 'discountRate',
            render: (text) => text + "%"
        },
        {
            title: '工时费',
            dataIndex: 'receivableAmount',
        },
        {
            title: '备注',
            dataIndex: 'note',
        },
    ]
    const columns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '材料编号',
            dataIndex: 'materialCode',
        },
        {
            title: '材料名称',
            dataIndex: 'materialName',
        },
        {
            title: '数量',
            dataIndex: 'num',
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        {
            title: '折扣',
            dataIndex: 'discountRate',
            render: (text) => text + "%"
        },
        {
            title: '金额',
            dataIndex: 'amount',
        },
        {
            title: '备注',
            dataIndex: 'note',
        },
    ]
    let IHeader = [
        {
            fieldLabel: "工号：",
            fieldValue: formVal.maintenanceCode
        }, {
            fieldLabel: "支付方式：",
            fieldValue: formVal.paymentMethodName
        }, {
            fieldLabel: "打印日期：",
            fieldValue: moment(new Date()).format("YYYY-MM-DD HH:mm")
        },
    ]
    return <SaleScaniaPrint
        title={`${formVal.bentityName}`}
        visible={visible}
        onClose={onClose}
        columns={columns}
        itemClumns={itemClumns}
        itemDataSource={[]}
        dataSource={maintenanceList.filter(item => item.num !== 0)}
        formVal={formVal}
        // shopCode={shopCode}

    />
}