import React, { useContext, useState, useEffect } from "react"
import {Table,Button} from 'antd'
import TopSearch from "@/components/TopSearch"
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
//import ListComponent from "@/components/listComponent"
import { poStatistics, poStatisticsExport,poStatisticsTotal } from "@/service/statistics"
import { MyContext } from "@/store/myContext"
import moment from "moment"
import { message } from "antd"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { formatNum } from '@/erp_subpackage/utils/util';
import useGlobalModel,{ transPgToPagination } from "@/erp_subpackage/model/globalModel";
import {YhBox} from '@//erp_subpackage/components/YhBox'


export default () => {
    const { state,Option } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const { user: { shopId, shopCode, shopName, organType, orgCode, orgName },fetchFmsShopList,setMaterialInfo} = useGlobalModel()
    const [searchValue, setSearchValue] = useState({
        // startTime: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
        // endTime: moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss"),
    })
    const [shopList, setShopList] = useState([])
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => []);
    const initPgCfg = { pageSize: 10, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg)
    const initdesc = {
        gifsAmount:0,
        giftsNum:0,
        purchaseAmount:0,
        purchaseNum:0
    }
    const [order, setOrder] = useState({})
    const [descTotal,setDescTotal,] =useState(initdesc)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (state.activeMenu === "poStatistics") {
            loadData()
        }

    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchFmsShopList('').then(arr => {
            setShopList(arr)  
        })
    }, [])

    //列表数据
    const loadData = async(page,search = searchValue) => {
        setLoading(true)
        let data = {
             ...search,
             pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
             pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
            shopCode,
            shopId
        }
        let {retData} =  await poStatistics(data)
        let {records,...pg} =retData
        setList(records)
        setPgCfg(transPgToPagination(pg));
        setLoading(false)
        poStatisticsTotal(data).then(({retData:totalVo})=>{
            setDescTotal(totalVo)  
        })
    }
    //导出
    const handleExport = (search=searchValue) => {
        const searchParams = {
            searchItem:{
                ...search,
                shopId: search.shopId ?? shopId,
                pageSize: 1,
                pageNum:15
            }
        }
        poStatisticsExport(searchParams).then((res) => {
            message.success(res.retData)
        })
    }

   
     //列表button
         const topButtons = <>
         <Button
             className="btn-item"
             shape="round"
             onClick={() => handleExport()}
         >导出</Button>
     </>

    //table配置
    const columns = [
        {
            align: "center",
            width: 60,
            ellipsis: true,
            title: '序号',
            render: (v, r, i) => i + 1
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '供应商名称',
            dataIndex: 'supplierName',
            render: (text) => <YhTooltip text={text} />,
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '单据编号',
            dataIndex: 'code',
            render: (text) => <YhTooltip text={text} />,
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '单据状态',
            dataIndex: 'status',
            render: (text) => <YhTooltip text={getTreeNodeName(text)} />,
            //render: text => getTreeNodeName(text)
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '采购类型',
            dataIndex:'orderTypeName',
            render: (text) => <YhTooltip text={text} />,
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '结算类型',
            dataIndex:'payTypeName',
            render: (text) => <YhTooltip text={text} />,
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '商品编号',
            dataIndex: 'materialCode',
            render: (text,record) => <YhTooltip text={text} onClick={(e) => { e.stopPropagation(); setMaterialInfo(true, { materialCode: text, materialName: record.materialName }) }}/>,
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '商品名称',
            dataIndex: 'materialName',
            render: (text) => <YhTooltip text={text} />,
           
        },
        {
            align: "center",
            width: 140,
            ellipsis: true,
            title: '商品类型',
            dataIndex: 'typeName',
            render: (text) => <YhTooltip text={text} />,
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '配件采购频次',
            dataIndex: 'purchaseCount',
            
           
        },

        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '配件采退频次',
            dataIndex: 'preturnCount',
            //render: text => getTreeNodeName(text)
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '采购价格',
            dataIndex: 'price',
            render: text =>'￥'+ Math.abs(text ?? 0)
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '采购数量',
            dataIndex: 'num',
            
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '采购金额',
            dataIndex: 'amount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '制单人',
            dataIndex: 'createUser',
            render: (text) => <YhTooltip text={text} />,
           
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '制单时间',
            dataIndex: 'createTime',
        },
        {
            title: '从属门店',
            width: 160,
            dataIndex: 'shopName',
            align: "center",
            ellipsis: true,
            render: (text) => <YhTooltip text={text} />,
       
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'note',
            ellipsis: true,
        }
    ]
       //搜索JSON
       const searchList = {
        normalList: [
            
            {
                type: 'input',
                placeholder: '供应商名称',
                valueName: 'supplierName',
            },
            {
                type: 'input',
                placeholder: '采购单号',
                valueName: 'code',
            },
            {
                type: 'input',
                placeholder: '业务员',
                valueName: 'createUser',
            },
            {
                type: 'RangePicker',
                valueName: ['settlementStartTime', 'settlementEndTime'],
                placeholder: ['结算起始日期', '结算结束日期'],
                format: "YYYY-MM-DD",
                span: 16,
                xxl: 8,
            },
        ],
        hideList: [
            {
                type: 'input',
                placeholder: '商品编号',
                valueName: 'materialCode',
            },
            {
                type: 'select',
                placeholder: '商品类型',
                valueName: 'typeCode',
                optionList: () =>
                getSelectedDictOne(DictCodeEnmu.MATERIAL_TYPE).map((item) =>
                ( 
                <Option key={item.key} value={item.value}>
                    {item.label}
                </Option>
            )),
            },
            {
                type: 'select',
                placeholder: '采购类型',
                valueName: 'orderTypeCode',
                optionList: () =>
                getSelectedDictOne(DictCodeEnmu.PURCHASE_TEPE).map((item) =>
                ( 
                <Option key={item.key} value={item.value}>
                    {item.label}
                </Option>
            )),
            },
            {
                type: 'select',
                placeholder: '结算类型',
                valueName: 'payType',
                optionList: () =>
                getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE).map((item) =>
                ( 
                <Option key={item.key} value={item.value}>
                    {item.label}
                </Option>
            )),
            },
            {
                type: 'select',
                placeholder: '供应商类型',
                valueName: 'supplierTypeCode',
                optionList: () =>
                getSelectedDictOne(DictCodeEnmu.SUPPLIER_TYPE).map((item) =>
                ( 
                <Option key={item.key} value={item.value}>
                    {item.label}
                </Option>
            )),
            },
            {
                type: 'select',
                placeholder: '单据类型',
                valueName: 'tradeTypeCode',
                optionList: () =>
                getSelectedDictOne(DictCodeEnmu.TRADE_TYPE).map((item) =>
                    ( 
                    <Option key={item.key} value={item.value}>
                        {item.label}
                    </Option>
                )),
                
            },
            {
                type: 'RangePicker',
                valueName: ['startTime', 'endTime'],
                placeholder: ['开单起始日期', '开单结束日期'],
                format: "YYYY-MM-DD",
                span: 16,
                xxl: 8,
            },
            
        ],
    }
    const desc = [
        {
            label: "采购数量",
            value: descTotal.purchaseNum,
            color: "red"
        },
        {
            label: "采购金额",
            value:  "￥" + formatNum(descTotal.purchaseAmount?? 0),
            color: "red"
        },
        {
            label: "赠品数量",
            // value: `￥${statistics.paidAmount}`,
            value: "￥" + formatNum(descTotal.giftsNum?? 0),
            color: "red"
        },
        {
            label: "赠品金额",
            // value: `￥${statistics.paidAmount}`,
            value: "￥" + formatNum(descTotal.gifsAmount?? 0),
            color: "red"
        }
        
    ]

    return (
        <>
            {state.activeMenu === "poStatistics" && (
                <>
                    <TopSearch
                        title="采购单明细报表"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                        // defaultSearch={{
                        // }}
                    />
                    <YhBox title="采购单明细报表" descriptions={desc}   style={{marginTop:20}} action={topButtons}>
                    <Table
                  
                        columns={columns}
                        dataSource={list}
                        pagination={ 
                            
                            {   ...pgCfg,
                                showSizeChanger:true,
                                position: ["bottomCenter"],
                                showTotal: () => `共${pgCfg.total}条`,
                                onChange: (pageNum,pageSize) => {
                                    loadData( {pageNum,pageSize})
                                }
                            }
                        }
                        // onTableChange={onTableChange}
                        scroll={{ x: "max-content" }}
                        loading={loading}
                    />
                    </YhBox>
                </>
            )}
        </>
    )
}