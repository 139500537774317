import React, { useEffect, useState, useContext } from "react"
import { Button, Form, Input, Col, Row, Select, Switch, message, Spin, Tabs, Radio,Table } from "antd"
import AccountInfo from "./account"
import { insertCustomer, getCustomerOne, editCustomerNewNew, platformUserList,customerSync,customerContract } from "@/service/customerService"
import { getBussinessEntityList } from "@/service/dataService"
import { getOrganCurrentUser } from "@/service/orgService"
import { getTeamList } from "@/service/teamService"
import { getPcodeDict, getUserBtn } from "@/utils/dataStorage"
import debounce from "lodash/debounce"
import { MyContext } from "@/store/myContext"
import { organPage } from "@/service/orgService"
import UserList from "./userList"
import VehicleList from "./vehicleList"
import { OperatorUserType } from "@/utils/enum.js"
import { fileUploadUrl, fileLookUrl } from '@/config/app'
import { forFind } from "@/erp_subpackage/utils/util";
import { SnippetsOutlined } from '@ant-design/icons';
import "./clientDetails.less"
const { Option } = Select
const { TabPane } = Tabs
const ClientDetails = (props) => {
    const { dispatch, state, showAllOrgan } = useContext(MyContext)
    const { modeKey, mode, rowInfo } = props
    const [form] = Form.useForm()
    const [org, setOrg] = useState([])
    const [userNum, setUserNum] = useState(0)
    const [vehicleNum, setVehicleNum] = useState(0)
    const [teamData, setTeamData] = useState({
        //团队信息
        data: [],
        fetching: false,
    })
    const [entityData, setEntityData] = useState({
        //所属实体信息
        data: [],
        fetching: false,
    })
    //所属经理
    const [businessManager, setBusinessManager] = useState({
        data: [],
        fetching: false,
    })

    //所属统计
    const [statistician, setStatistician] = useState({
        data: [],
        fetching: false,
    })
    //签约经理
    const [signsManagerList,setSignManagerList]=useState([])


    //合约列表
    const [condata,setConData]=useState([])
    useEffect(() => {
        // resetFormItem()
        organPage({
            searchItem: { orderFlag: 0, showPlatformOrgan: true },
            pageNum: 1,
            pageSize: 100,
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            setOrg(res.retData.list)
        })
        contractPage()
    }, [])
    //回显表单
    const resetFormItem = async (list) => {
        let defaultbusiness = await setDefultbusinessManager()
        if (mode === "edit" || mode === "look") {
            getCustomerOne(rowInfo.id).then((res) => {
                let retData = res.retData
                let { businessManager } = retData
                if (!businessManager) {
                    setBusinessManager({
                        data: defaultbusiness ? [defaultbusiness] : [],
                        fetching: false,
                    })
                    businessManager = defaultbusiness ? defaultbusiness.value : null
                } else {
                    setBusinessManager({
                        data: [{ text: retData.businessManagerName, value: retData.businessManager }],
                        fetching: false,
                    })
                }
                form.setFieldsValue({
                    ...retData,
                    businessManager,
                    customerStatus: retData.customerStatus == 0 ? true : false,
                    // createTime: timeShift(retData.createTime),
                    bigBagFlag:list?.find(item=>item.signType=='046001')? 1:0
                })
                setTeamData({
                    data: [{ text: retData.teamName, value: retData.teamId }],
                    fetching: false,
                })

                setEntityData({
                    data:
                        retData.ciBusinessEntityName && retData.ciBusinessEntityId
                            ? [{ text: retData.ciBusinessEntityName, value: retData.ciBusinessEntityId }]
                            : [],
                    fetching: false,
                })

                setStatistician({
                    data: [{ text: retData.statisticianName, value: retData.statistician }],
                    fetching: false,
                })
            })
        } else {
            setBusinessManager({
                data: defaultbusiness ? [defaultbusiness] : [],
                fetching: false,
            })
            form.setFieldsValue({
                businessManager: defaultbusiness ? defaultbusiness.value : null,
                customerStatus: true,
                bigBagFlag:0
            })
        }
    }
    // 设置默认业务经理
    const setDefultbusinessManager = async () => {
        const data = await getOrganCurrentUser()
        const { defBusinessManager, defBusinessManagerName } = data.retData
        if (defBusinessManager) {
            return { text: defBusinessManagerName, value: defBusinessManager }
        } else {
            return null
        }
    }
    //团队派单信息查询
    const fetchTeam = (value) => {
        setTeamData({
            ...teamData,
            fetching: true,
        })
        let data = {
            searchItem: {},
            pageNum: 1,
            pageSize: 1000,
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },
            ],
        }
        getTeamList(data).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            const dataMap = results.map((team) => ({
                text: team.teamName,
                value: team.id,
            }))
            setTeamData({
                data: dataMap,
                fetching: false,
            })
            // }
        })
    }
    //防抖
    // const fetchTeams = debounce(fetchTeam, 1000);
    //团队派单信息查询
    const fetchEntity = (value) => {
        setEntityData({
            ...entityData,
            fetching: true,
        })
        let data = {
            searchItem: {
                entityName: value,
            },
            pageNum: 1,
            pageSize: 10,
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },
            ],
        }
        getBussinessEntityList(data).then((res) => {
            let results = res.retData.list
            const dataMap = results.map((team) => ({
                text: team.entityName,
                value: team.id,
            }))
            setEntityData({
                data: dataMap,
                fetching: false,
            })
        })
    }
    //防抖
    const fetchEntitys = debounce(fetchEntity, 1000)
    //选择实体
    const handleChangeEntity = (value, option) => {
        form.setFieldsValue({
            ciBusinessEntityName: value ? option.children : undefined,
        })
    }
    //选择的团队
    const handleChangeTeam = (value, option) => {
        form.setFieldsValue({
            teamName: value ? option.children : undefined,
        })
    }

    //表单提交
    const onCustomerSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                customerStatus: values.customerStatus ? 0 : 1,
            }
            //新增
            if (mode === "insert") {
                insertCustomer(data).then((res) => {
                    //查找带过的工单要是被关闭， 就回到列表
                    let isOrderKey = state.panes.find((i) => i.key === rowInfo.orderEditModeKey)
                    if (isOrderKey && rowInfo.orderEditModeKey) {
                        dispatch({
                            type: "changeTabs",
                            activeMenu: rowInfo.orderEditModeKey,
                            name: "订单新增",
                        })
                        dispatch({
                            type: "removeTabs",
                            targetKey: modeKey,
                        })
                    } else {
                        dispatch({
                            type: "removeTabs",
                            targetKey: modeKey,
                            activeMenu: "ordersManage",
                        })
                    }
                    !rowInfo.orderEditModeKey && handleCancel()
                    message.success("客户新增成功")
                    if (res && res.retCode === 200) {
                        customerSync({ name: values.customerName }).then(res => {
                            res ? message.success("客户账户信息同步成功...") : message.error("同步失败");
                        })
                    }
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
                // .catch((err) => {
                //     if (err && err.retCode === 500) {
                //         message.error(err.retMsg)
                //         return
                //     }
                // })
                //编辑
            } else if (mode === "edit") {
                editCustomerNewNew({
                    ...data,
                    id: rowInfo.id,
                    teamId: data.teamId || null,
                    teamName: data.teamName || null,
                }).then((res) => {
                    handleCancel()
                    message.success("客户编辑成功")
                    if (res && res.retCode === 200) {
                        customerSync({ name: values.customerName }).then(res => {
                            res ? message.success("客户账户信息同步成功...") : message.error("同步失败");
                        })
                    }
                })
            }
        })
    }
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    //取消
    const handleCancel = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "clientRecord",
        })
    }
    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }

    //资源权限
    const butResourceApply = (str) => {
        let resource = getUserBtn("clientRecord") || {}
        resource = resource.children || []
        return resource.find((item) => item.path === str) ? true : false
    }

    //客户列表查询
    const businessManagerQuery = (value, key) => {
        setBusinessManager({
            ...businessManager,
            fetching: true,
        })

        platformUserList({
            searchItem: {
                nickname: value,
            },
            pageNum: 1,
            pageSize: 15,
        }).then((res) => {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.reduce((arr, item) => {
                    arr.push({
                        text: item.nickname,
                        value: item.id,
                    })
                    return arr
                }, [])
                setBusinessManager({
                    data: data,
                    fetching: false,
                })
            } else {
                console.log("没有查询到")
            }
        })
    }
    //防抖
    const businessManagerQuerys = debounce(businessManagerQuery, 1000)

    //客户列表查询
    const setStatisticianQuery = (value, key) => {
        setStatistician({
            ...statistician,
            fetching: true,
        })

        platformUserList({
            searchItem: {
                nickname: value,
            },
            pageNum: 1,
            pageSize: 15,
        }).then((res) => {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.reduce((arr, item) => {
                    arr.push({
                        text: item.nickname,
                        value: item.id,
                    })
                    return arr
                }, [])

                setStatistician({
                    data: data,
                    fetching: true,
                })
            } else {
                // message.warning("没有查询到")
                console.log("没有查询到")
            }
        })
    }

    //合约列表查询
    const contractPage=()=>{
        let data={
            pageNum: 1,
            pageSize: 15,
            orders:[{customerId:rowInfo.id}],
            searchItem:{customerId:rowInfo.id}
        }
        let data2={ pageNum: 1, pageSize: 15, searchItem: {nickname:''}}
        customerContract(data).then(res=>{
            let{retData}=res
            let{list:concat}=retData
            platformUserList(data2).then((res)=>{
                let {retData:{list:name}}=res
                let newData = concat.map(item => {
                    const matchedData = name.find(dataItem => dataItem.id === item.signManager);
                    if (matchedData) {
                      return {
                        ...item,
                        signManager: matchedData.nickname
                      };
                    }
                    return item;
                  });
                  setConData(newData)
                  resetFormItem(newData)
            })
        }) 
    }

    //防抖
    const setStatisticianQuerys = debounce(setStatisticianQuery, 1000)

    const columns = [
        {
            title: "合约单号",
            dataIndex: "signCode",
        },
        {
            title: "签约类型",
            dataIndex: "signType",
            render:(text)=>{if(text==='046001'){return text = '大包'}else{return text ='月结'}  }
        },
        {
            title: "大包模式",
            dataIndex: "bigBagMode",
            render:(text)=>{
                let list=getPcodeDict('047').children.map((item)=>{
                return({label:item.title,value:item.dictCode,key:item.id})
                })
                const matchingItem = list.find(item => item.value === text);
                return matchingItem ? matchingItem.label :text;
            }
        },
        // {
        //     title: "签约里程",
        //     dataIndex: "signMileage",
        // },
        // {
        //     title: "计费价格",
        //     dataIndex: "billPrice",
        // },
        {
            title: "计费方式",
            dataIndex: "billMethod",
            render:(text)=>{
                let list=getPcodeDict('048').children.map((item)=>{
                return({label:item.title,value:item.dictCode,key:item.id})
                })
                const matchingItem = list.find(item => item.value === text);
                return matchingItem ? matchingItem.label :text;
            }
        },
        {
            title: "签约日期",
            dataIndex: "signStartTime",
        },
        {
            title: "截止日期",
            dataIndex: "signEndTime",
        },
        {
            title: "签约经理",
            dataIndex: "signManager",
        },
        {
            title: "附件",
            dataIndex: "attachment",
            render: (text) => {
                const attachments = text===''||text===null?[]:text.split(',');
                return attachments.map((attachment) => (
                  <a key={attachment} href={`${fileLookUrl().image}${attachment}`} target="_blank" rel="noopener noreferrer">
                    <SnippetsOutlined/>
                  </a>
                ));
              },
        },
    ]

    return (
        <>
            <div className="list-container container-fixed">
                <div className="list-header">
                    <div className="list-name">
                        <span>客户信息</span>
                    </div>
                    <div className="list-but">
                        {!isLook(mode) && (
                            <Button shape="round" className="but-left" onClick={onCustomerSubmit}>
                                保存
                            </Button>
                        )}
                        <Button shape="round" className="but-left" onClick={handleCancel}>
                            取消{" "}
                        </Button>
                        {/* {mode !== "insert" && (
                            <Button
                                shape="round"
                                size="small"
                                className="but-right"
                                onClick={() =>
                                    dispatch({
                                        type: "changeTabs",
                                        activeMenu: "vehicleRecord",
                                        name: "车辆管理",
                                        vehicleQuery: rowInfo.id,
                                    })
                                }
                            >
                                下属车辆
                            </Button>
                        )} */}
                    </div>
                </div>
            </div>
            <div className="list-container" style={{ marginTop: "100px" }}>
                <div className="form-details-warp" style={{ padding: "20px 20px 5px 20px" }}>
                    <Form form={form} {...formItemLayout}>
                        <Row>
                            <Col span={24}>{/* <span className="form-title">客户详情1</span> */}</Col>
                            <Col span={9}>
                                <Form.Item
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                    label="客户名称"
                                    name="customerName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入客户名称",
                                        },
                                        {
                                            pattern: /^[\u4e00-\u9fa5（）]+$/,
                                            message: "请输中文或者中文括号",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入客户名称" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            
                            {/* <Col span={6} xxl={4}>
                                <Form.Item label="传真" name="customerFax">
                                    <Input placeholder="请输入客户传真" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col> */}
                            <Col span={5}>
                                <Form.Item
                                    label="客户类型"
                                    name="customerType"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择客户类型",
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择客户类型" disabled={isLook(mode)}>
                                        {getPcodeDict("017").children.map((item) => (
                                            <Option value={item.dictCode} key={item.id}>
                                                {item.title}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                  
                                    label="客户编码"
                                    name="customerCode"
                                   
                                >
                                    <Input placeholder="自动生成" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item label="业务经理" name="businessManager">
                                    <Select
                                        disabled={isLook(mode)}
                                        showSearch
                                        placeholder="请选择业务经理"
                                        notFoundContent={businessManager.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onFocus={() => businessManagerQuerys("")}
                                        onSearch={businessManagerQuerys}
                                        style={{ width: "100%" }}
                                    >
                                        {businessManager.data.map((item) => (
                                            <Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* <Col span={6} xxl={4}>
                                <Form.Item
                                    label="电子邮箱"
                                    name="customerEmail"
                                    rules={[
                                        {
                                            pattern: /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/,
                                            message: "请输入正确的电子邮箱格式",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入电子邮箱" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col> */}
                           

                            <Col span={9}>
                                <Form.Item
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                    label="联系地址"
                                    name="customerAddress"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入联系地址",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入联系地址" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>

                            <Col span={5}>
                                <Form.Item
                                    label="联系人"
                                    name="customerCorporation"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入客户联系人",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入客户联系人" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    label="联系电话"
                                    name="customerCorporationPhone"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入联系电话",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入联系电话" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>

                            <Col span={5}>
                                <Form.Item label="所属机构" name="organId">
                                    <Select
                                        disabled={isLook(mode) || !butResourceApply("clientRecordChangeOrgan")}
                                        showSearch
                                        allowClear
                                        placeholder="请选择所属机构"
                                        style={{ width: "100%" }}
                                    >
                                        {org.map((item) => (
                                            <Option value={item.id} key={item.id}>
                                                {item.organName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            {/* <Col span={6} xxl={4}>
                                <Form.Item label="办公电话" name="customerPhone">
                                    <Input placeholder="请输入办公电话" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col> */}

                            <Col span={9}>
                                <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} label="备注" name="customerDesc">
                                    <Input placeholder="请输入备注" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>

                            <Col span={5}>
                                <Form.Item label="所属团队" name="teamId">
                                    <Select
                                        disabled={isLook(mode)}
                                        showSearch
                                        allowClear
                                        placeholder="请选择创业团队"
                                        notFoundContent={teamData.fetching ? <Spin size="small" /> : null}
                                        optionFilterProp="children"
                                        onChange={handleChangeTeam}
                                        onFocus={() => fetchTeam()}
                                        style={{ width: "100%" }}
                                        // onSearch={fetchTeams}
                                        // filterOption={false}
                                    >
                                        {teamData.data.map((item) => (
                                            <Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item label="所属实体" name="ciBusinessEntityId">
                                    <Select
                                        disabled={isLook(mode)}
                                        showSearch
                                        allowClear
                                        placeholder="请选择所属实体队"
                                        notFoundContent={entityData.fetching ? <Spin size="small" /> : null}
                                        optionFilterProp="children"
                                        onChange={handleChangeEntity}
                                        onFocus={() => fetchEntity()}
                                        style={{ width: "100%" }}
                                        onSearch={fetchEntitys}
                                        filterOption={false}
                                    >
                                        {entityData.data.map((item) => (
                                            <Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item label="所属统计" name="statistician">
                                    <Select
                                        disabled={isLook(mode)}
                                        showSearch
                                        placeholder="请选择所属统计"
                                        notFoundContent={statistician.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onFocus={() => setStatisticianQuery("")}
                                        onSearch={setStatisticianQuerys}
                                        style={{ width: "100%" }}
                                    >
                                        {statistician.data.map((item) => (
                                            <Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="是否大包" name="bigBagFlag" >
                                    <Radio.Group disabled>
                                        <Radio value={0}>否</Radio>
                                        <Radio value={1}>是</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {/* <Col span={6} xxl={4}>
                                <Form.Item label="邮编" name="customerZipcode">
                                    <Input placeholder="请输入邮编" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col> */}

                            <Col span={5}>
                                <Form.Item label="推荐人类型" name="referrerType">
                                    <Select disabled placeholder="推荐人类型" style={{ width: "100%" }}>
                                        {Object.entries(OperatorUserType).map((item) => (
                                            <Option value={Number(item[0])} key={item[0]}>
                                                {item[1]}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item label="推荐人" name="referrerName">
                                    <Input placeholder="推荐人" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item label="登记日期" name="createTime">
                                    <Input placeholder="自动生成" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item label="客户状态" name="customerStatus" valuePropName="checked">
                                    <Switch checkedChildren="开启" unCheckedChildren="关闭" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>

                            <Form.Item hidden name="teamName">
                                <Input />
                            </Form.Item>
                            <Form.Item hidden name="ciBusinessEntityName">
                                <Input />
                            </Form.Item>
                        </Row>
                    </Form>
                </div>
            </div>
            {mode !== "insert" && (
                
                <>
                <div className="list-container">
                    <Table 
                    columns={columns}
                    dataSource={condata}
                    />
                </div>
                <div className="list-container">
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={userNum ? `用户管理(${userNum})` : "用户管理"} key="1">
                                <UserList customerInfo={rowInfo} setUserNum={setUserNum} mode={mode} />
                            </TabPane>
                            <TabPane tab={vehicleNum ? `下属车辆(${vehicleNum})` : "下属车辆"} key="2">
                                <VehicleList customerInfo={rowInfo} setVehicleNum={setVehicleNum} mode={mode} />
                            </TabPane>
                            <TabPane tab="账户管理" key="3">
                                <AccountInfo customerInfo={rowInfo} mode={mode} />
                            </TabPane>
                        </Tabs>
                    </div></>
            )}
        </>
    )
}
export default ClientDetails
