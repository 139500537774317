import React, { useState, useEffect } from "react"
import { Input, Modal, Form, Col, Row, message } from "antd"
import { editUserName } from "@/service/customerService"
const ModifyContactName = (props) => {
    const { visible, onCancel, orderId, customerUserId, repairContactName, handleSuccess } = props
    const [form] = Form.useForm()

    //保存
    const onCustomerSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                userId: customerUserId,
                userName: values.customerName,
            }

            editUserName(data)
                .then((res) => {
                    if (res && res.retCode === 200) {
                        onCancel()
                        message.success("修改成功")
                        handleSuccess(values.customerName, customerUserId)
                    } else {
                        message.error(res.retMsg)
                    }
                })
                .catch((err) => {
                    if (err && err.retCode === 500) {
                        message.error(err.retMsg)
                        return
                    }
                })
        })
    }
    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    return visible ? (
        <Modal visible={visible} title="联系人名字修改" centered={true} onOk={() => onCustomerSubmit()} onCancel={() => onCancel(false)} width={300}>
            <div>
                <Form form={form} {...formItemLayout}>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item
                                label="联系人"
                                name="customerName"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入联系人名字",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入联系人名字" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    ) : null
}
export default ModifyContactName
