import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { formatNum } from "@/erp_subpackage/utils/util";
import { usePurchaseDrModel } from "../purchaseDrModel";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

export function DataTable({ goPageNav }) {
    const { getTreeNodeName } = useDictModel(() => []);
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys
    } = usePurchaseDrModel();

    useEffect(() => {
        loadData()
    }, [])

    const columns = [
        {
            title: '序号',
            width: 50,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '调拨单号',
            width: 200,
            dataIndex: 'code',
            fixed: "left",
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line click-span" onClick={(e) => goPageNav("edit", text)}>{text}</p></Tooltip>,
            render: (text) => <YhTooltip text={text} onClick={() => goPageNav("edit", text)} />,
        }, {
            title: '调入单号',
            width: 180,
            dataIndex: 'transferInCode',
            fixed: "left",
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '调拨类型',
            width: 90,
            dataIndex: 'transferTypeName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '单据状态',
            width: 80,
            dataIndex: 'status',
            render: (text) => <YhTooltip text={getTreeNodeName(text)} />,
            //render: text => getTreeNodeName(text)
        }, {
            title: '调入机构',
            width: 160,
            dataIndex: 'shopName',
            render: (text) => <YhTooltip text={text} />,
            // render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '调入实体',
            width: 160,
            dataIndex: 'bentityName',
            render: (text) => <YhTooltip text={text} />,
            // render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '调出机构',
            width: 140,
            dataIndex: 'outShopName',
            render: (text) => <YhTooltip text={text} />,
            // render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '调出实体',
            width: 160,
            dataIndex: 'outBentityName',
            render: (text) => <YhTooltip text={text} />,
            // render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '调拨数量',
            width: 100,
            align: "center",
            dataIndex: 'totalNum',
        },
        // {
        //     title: '运费',
        //     width: 100,
        //     dataIndex: 'freight',
        //     render: text => `￥${formatNum(text)}`
        // }, {
        //     title: '其他费用',
        //     width: 100,
        //     dataIndex: 'otherFees',
        //     render: text => `￥${formatNum(text)}`
        // }, 
        {
            title: '成本总金额',
            width: 100,
            dataIndex: 'costAmount',
            render: text => `￥${formatNum(text)}`
        }, {
            title: '毛利',
            width: 90,
            dataIndex: 'grossProfit',
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '应付金额',
            width: 100,
            dataIndex: 'totalAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            title: '优惠总金额',
            width: 100,
            dataIndex: 'discountTotalAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            title: '已付金额',
            width: 100,
            dataIndex: 'paidAmount',
            render: text => `￥${formatNum(text)}`
        }, {
            title: '制单员',
            width: 80,
            dataIndex: 'applier',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '调出员',
            width: 80,
            dataIndex: 'transferOut',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '调入员',
            width: 80,
            dataIndex: 'transferIn',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '制单日期',
            width: 170,
            dataIndex: 'applyTime',
        }, {
            title: '调出日期',
            width: 170,
            dataIndex: 'transferOutTime',
        }, {
            title: '调入日期',
            width: 170,
            dataIndex: 'transferInTime',
        }, {
            title: '备注',
            width: 180,
            dataIndex: 'inNote',
            render: (text,record) => record.inNote?record.inNote:record.note
        }, {
            title: '打印次数',
            width: 80,
            align: "center",
            fixed: "right",
            dataIndex: 'printNum',
        }
    ];

    //点击行
    const onRow = (record) => {
        let findObj = selectedRowKeys.find(item => item.id === record.id)
        if (findObj) {
            let newSelectedRowKeys = selectedRowKeys.filter(item => item.id !== record.id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            setSelectedRowKeys(origin => [...origin, record]);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            loading={loading}
            rowSelection={{
                //全部选中时 (存row[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRows])
                        : setSelectedRowKeys((origin) => {
                            let changeRowId = changeRows.map(item => item.id);
                            return origin.filter(item => changeRowId.indexOf(item.id) < 0)
                        });
                },
                onSelect: (record, selected, selectedRows) => onRow(record),
                selectedRowKeys: selectedRowKeys.map(item => item.id)
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: (total) => `共${total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record]);
                }
            })}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}
