import React, { useEffect, useState } from 'react'
import { Form, Input, Col, Row, DatePicker, message, Modal, Select } from 'antd'
import { appointmentOne, appointmentUpdate, appointmentInsert } from '@/service/bookingManagement'
import InsertAddressMap from '@/components/TMapChoose' //地图
import moment from 'moment'
import { appointmentSourceType } from '@/utils/enum'
const { Option } = Select
export default (props) => {
    const { mode, rowInfo, visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()
    const [mapVisible, setMapVisible] = useState(false) //地图
    const [isZf, setIsZf] = useState(false)

    useEffect(() => {
        queryMotorcade()
    }, [])

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === 'look' || mode === 'edit') {
            appointmentOne(rowInfo.id).then((res) => {
                let results = res.retData
                form.setFieldsValue({
                    ...results,
                    appointmentTime: results.appointmentTime ? moment(results.appointmentTime) : results.appointmentTime,
                })
                setIsZf(results.sourceType === 'zf')
            })
        } else {
            // form.setFieldsValue({ sourceType: 'zf', sourceTypeCn: appointmentSourceType.zf })
        }
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                appointmentTime: values.appointmentTime ? values.appointmentTime.format('YYYY-MM-DDTHH:mm:ss') : null,
            }

            if (mode === 'edit') {
                data.id = rowInfo.id
                appointmentUpdate(data).then((res) => {
                    message.success('编辑成功')
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                appointmentInsert(data).then((res) => {
                    message.success('新增成功')
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleCancel = () => {
        setVisible('')
    }

    //地图选择保存
    const saveAddressDetail = (results) => {
        const { addr: repairAddress, longitude: repairLng, latitude: repairLat } = results
        form.setFieldsValue({
            repairAddress,
            repairLng,
            repairLat,
        })
        setMapVisible(false)
    }

    const appointmentSourceTypeChange = (val, i) => {
        form.setFieldsValue({ sourceTypeCn: i.children })
        setIsZf(val === 'zf')
    }

    //查看模式
    const isLook = (str) => {
        return str === 'look' ? true : false
    }

    return (
        <>
            <Modal forceRender visible={visible} title="预约详情" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={1200}>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={8}>
                                <Form.Item label="预约单号" name="appointmentOrderCode">
                                    <Input placeholder="请输入自动生成" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="单据类型" name="sourceType">
                                    <Select
                                        placeholder="请选择单据类型"
                                        optionFilterProp="children"
                                        allowClear
                                        disabled={isLook(mode)}
                                        onChange={appointmentSourceTypeChange}
                                    >
                                        {Object.entries(appointmentSourceType).map((item) => (
                                            <Option value={item[0]} key={item[0]}>
                                                {item[1]}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {isZf && (
                                <>
                                    <Col span={8}>
                                        <Form.Item label="采埃孚单号" name="sourceCode">
                                            <Input placeholder="请输入采埃孚单号" disabled={isLook(mode)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="采埃孚ID" name="sourceId">
                                            <Input placeholder="请输入采埃孚ID" disabled={isLook(mode)} />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}

                            <Col span={8}>
                                <Form.Item label="报修地址" name="repairAddress" rules={[{ required: true, message: '请选择报修地址' }]}>
                                    <Input
                                        placeholder="通过地图实时位置获取地址"
                                        disabled
                                        addonAfter={
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    !isLook(mode) && setMapVisible(true)
                                                }}
                                            >
                                                获取位置
                                            </span>
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="车辆牌照" name="vehicleCode" rules={[{ required: true, message: '请输入车辆牌照' }]}>
                                    <Input placeholder="请输入车辆牌照" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="联系人" name="contactName" rules={[{ required: true, message: '请输入车辆牌照' }]}>
                                    <Input placeholder="请输入联系人" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="故障描述" name="faultDesc" rules={[{ required: true, message: '请输入故障描述' }]}>
                                    <Input placeholder="请输入故障描述" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="VIN码" name="vehicleVin" rules={[{ required: true, message: '请输入VIN码' }]}>
                                    <Input placeholder="请输入VIN码" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="联系电话" name="contactPhone" rules={[{ required: true, message: '请输入联系电话' }]}>
                                    <Input placeholder="请输入联系电话" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="备注" name="remark">
                                    <Input placeholder="请输入备注" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="创建时间" name="createTime">
                                    <Input placeholder="自动生成" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="预约时间" name="appointmentTime" rules={[{ required: true, message: '请选择预约时间' }]}>
                                    <DatePicker
                                        className="pub-select"
                                        format="YYYY-MM-DD HH:mm:ss"
                                        showTime={{
                                            defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                        }}
                                        style={{ width: '100%' }}
                                        disabled={isLook(mode)}
                                    />
                                </Form.Item>
                            </Col>
                            <Form.Item hidden name="repairLat">
                                <Input />
                            </Form.Item>
                            <Form.Item hidden name="repairLng">
                                <Input />
                            </Form.Item>
                            <Form.Item hidden name="sourceTypeCn">
                                <Input />
                            </Form.Item>
                        </Row>
                    </Form>
                </div>
            </Modal>
            {
                mapVisible && <InsertAddressMap
                visible={mapVisible}
                onCloseMap={() => setMapVisible(false)}
                saveAddressDetail={saveAddressDetail}
                defaultInfo={{
                    lat: form.getFieldsValue().repairLat,
                    lng: form.getFieldsValue().repairLng
                }}
            />
            }
            
        </>
    )
}
