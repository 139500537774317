import React, { useState, useEffect } from "react"
import { Button, Table, message, Modal, Input, Select, Switch } from "antd"
import { getAccountList, rechargeAccount } from "@/service/fleetService"
import { getBillList } from "@/service/customerService"
import AccountDetails from "./accountDetails"
import PubDrawer from "@/components/Drawer"
import { getUserInfo, getPcodeDict } from "@/utils/dataStorage"
import { timeShift } from "@/utils/util"

const { Option } = Select
const AccountInfo = (props) => {
    const { customerInfo } = props
    const [customerData, setCustomerData] = useState(null) //列表
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [paginationHis, setPaginationHis] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [accountVisible, setAccountVisible] = useState({
        //详情页开关
        visible: false,
        mode: "",
    })
    const [customerType, setCustomerType] = useState({})
    const [searchType, setSearchType] = useState("")
    useEffect(() => {
        let obj = {}
        getPcodeDict("017").children.forEach((item) => {
            obj[item.dictCode] = item.title
        })
        setCustomerType(obj)
        loadData(1)
    }, [])
    //table配置

    //账户详情关闭
    const onDetailsClose = () => {
        setAccountVisible({
            visible: false,
            mode: "",
        })
        loadData(1)
    }
    //新增，编辑
    const handleDetails = (detailsmode) => {
        if (detailsmode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看或编辑的账户信息")
                return
            }
        }
        setAccountVisible({
            visible: true,
            mode: detailsmode,
        })
    }

    //默认加载
    const loadData = (current) => {
        let data = {
            customerId: customerInfo.id,
            currentPage: current,
            pageSize: 10,
        }
        getAccountList(data)
            .then((res) => {
                // if (res && res.retCode === 200) {
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setCustomerData(res.retData.list)
                // }
            })
            .catch((err) => {
                setCustomerData([])
            })
    }
    //分页事件
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }

    const onTableChangeHis = async (page) => {
        await setPaginationHis({
            ...pagination,
            current: page.current,
        })
        lookRechargeRecord(page.current)
    }

    //账户重置
    const accountRecharge = () => {
        let recharge = "a" //充值金额
        let accountType = -1 //账户类型
        let paymentType = -1 //支付方式
        Modal.confirm({
            title: `${customerInfo.customerName}的账户充值`,
            content: (
                <>
                    <span>充值金额：</span>
                    <Input
                        placeholder="请输入充值金额"
                        onChange={(e) => {
                            recharge = e.target.value
                        }}
                    />
                    <span>账户类型：</span>
                    <Select
                        showSearch
                        allowClear
                        onChange={(value) => {
                            accountType = value
                        }}
                        placeholder="请选择账户类型"
                        style={{ width: "100%" }}
                    >
                        <Option value={0}>授信账户</Option>
                        <Option value={1}>返利账户</Option>
                        <Option value={2}>预存账户</Option>
                        <Option value={3}>临时授信账户</Option>
                        <Option value={4}>服务费账户</Option>
                        <Option value={5}>代金券账户</Option>
                        <Option value={6}>预付款账户</Option>
                        <Option value={7}>订金账户</Option>
                    </Select>
                    <span>支付方式：</span>
                    <Select
                        showSearch
                        allowClear
                        onChange={(value) => {
                            paymentType = value
                        }}
                        placeholder="请选择账户类型"
                        style={{ width: "100%" }}
                    >
                        <Option value={0}>现金</Option>
                        <Option value={1}>支付宝</Option>
                        <Option value={2}>微信</Option>
                    </Select>
                </>
            ),
            okText: "确定",
            cancelText: "取消",
            onOk: () => {
                return new Promise((resolve, reject) => {
                    if (!/^[0-9]+(.[0-9]{0,2})?$/.test(recharge)) {
                        // console.log(recharge, accountType, paymentType);
                        message.warning("请输入正确数值的金额")
                        return reject("请输入正确数值的金额")
                    }
                    if (accountType < 0) {
                        message.warning("请选择账户类型")
                        return reject("请选择账户类型")
                    }
                    if (paymentType < 0) {
                        message.warning("请选择支付方式")
                        return reject("请选择支付方式")
                    }
                    return resolve({
                        recharge: Number(recharge),
                        accountType,
                        paymentType,
                    })
                }).then((res) => {
                    let data = {
                        ...res,
                        updateUser: getUserInfo() ? getUserInfo().nickname : "未获取到", //获取系统业务员
                        customerId: customerInfo.id,
                    }
                    rechargeAccount(data).then((res) => {
                        // if (res && res.retCode === 200) {
                        loadData(1)
                        message.success(`${customerInfo.customerName}的账户充值成功`)
                        // } else {
                        //     message.warning(res.retMsg)
                        // }
                    })
                    // .catch((err) => {
                    //     if (err && err.retCode === 500) {
                    //         message.error(err.retMsg)
                    //         return
                    //     }
                    // })
                })
            },
            onCancel: () => {},
        })
    }
    let obj = {
        0: "授信账户",
        1: "返利账户",
        2: "预存账户",
        3: "临时授信账户",
        4: "服务费账户",
        5: "代金券账户",
        6: "预付款账户",
        7: "订金账户",
    }
    //账户列表授信额度禁用启用
    const accountSweitchChange = () => {}
    const columns = [
        {
            title: "账户编号",
            align: "center",
            dataIndex: "accountCode",
        },
        // {
        //     title: "客户类型",
        //     align: "center",
        //     dataIndex: "customerType",
        //     render: (text) => <>{customerType[text]}</>,
        // },
        // {
        //     title: "客户名称",
        //     align: "center",
        //     dataIndex: "customerName",
        // },
        // {
        //     title: "所属公司",
        //     align: "center",
        //     dataIndex: "companyId",
        //     render: (t) => <>{customerInfo.customerAgency}</>,
        // },
        {
            title: "账户类型",
            align: "center",
            dataIndex: "accountType",
            render: (text) => <>{obj[text]}</>,
        },
        {
            title: "账户金额",
            align: "center",
            dataIndex: "totalAmount",
        },
        {
            title: "账户余额",
            align: "center",
            dataIndex: "remainingAmount",
        },
        {
            title: "登记日期",
            align: "center",
            dataIndex: "createTime",
            // render: (text) => <>{timeShift(text)}</>,
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "accountDesc",
        },
        {
            title: "业务员",
            align: "center",
            dataIndex: "createUser",
        },
        {
            title: "账户状态",
            align: "center",
            width: "12%",
            dataIndex: "accountStatus",
            render: (text) => <Switch size="small" checked={text == 0 ? true : false} checkedChildren="启用" unCheckedChildren="禁用" />,
        },
    ]

    const [recordMoney, setRecordMoney] = useState(false) //充值记录
    const [moneyData, setMoneyData] = useState(null) //记录列表,
    const [moneyMode, setMoneyMode] = useState("") //查看消费记录， 还有充值记录
    //充值记录
    const lookRechargeRecord = (current, str) => {
        // if (!Object.keys(selectInfo.rowInfo).length) {
        //     message.warning("请选择你要查看的账户信息");
        //     return;
        // };
        let loanType = searchType
        if (str) {
            setSearchType(str === "consumption" ? 1 : 0)
            loanType = str === "consumption" ? 1 : 0
        }
        setRecordMoney(true)
        setMoneyMode(str)
        let data = {
            pageNum: current,
            pageSize: 10,
            loanType,
            customerId: customerInfo.id,
        }
        getBillList(data).then((res) => {
            setMoneyData(res.retData.list)
            setPaginationHis({
                //分页配置
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
        })
    }
    const typeObj = {
        0: "云创（维修单）",
        1: "云修（维修单）",
        2: "供应链（销售单）",
    }
    const consumptionColumns = [
        {
            title: "单据号码",
            align: "center",
            dataIndex: "relationId",
        },
        {
            title: "单据类型",
            align: "center",
            dataIndex: "busType",
            render: (text) => <>{typeObj[text]}</>,
        },
        {
            title: "付款金额",
            align: "center",
            dataIndex: "billAmount",
            render: (text) => <>{`${text}元`}</>,
        },
        {
            title: "付款日期",
            align: "center",
            dataIndex: "createTime",
        },
    ]
    const rechargeColumns = [
        {
            title: "账户类型",
            align: "center",
            dataIndex: "accountType",
            render: (text) => <>{obj[selectInfo.rowInfo.accountType]}</>,
        },
        {
            title: "充值金额",
            align: "center",
            dataIndex: "billAmount",
        },
        {
            title: "操作员",
            align: "center",
            dataIndex: "createUser",
        },
        {
            title: "充值日期",
            align: "center",
            dataIndex: "createTime",
        },
    ]
    return (
        <>
            <div>
                <div className="list-header" style={{ marginTop: "-10px" }}>
                    {/* <div className="list-name">
                        <span>账户信息</span>
                    </div> */}
                    <div className="list-but">
                        <Button shape="round" className="but-left" onClick={() => handleDetails("insert")}>
                            新增
                        </Button>
                        <Button shape="round" className="but-left" onClick={() => handleDetails("edit")}>
                            {" "}
                            编辑
                        </Button>
                        {/* <Button shape="round" className="but-left"> 导入</Button>
                        <Button shape="round" className="but-left">导出</Button> */}
                        <Button shape="round" className="but-left" onClick={accountRecharge}>
                            {" "}
                            账户充值
                        </Button>
                        <Button shape="round" className="but-left" onClick={() => lookRechargeRecord(1, "recharge")}>
                            {" "}
                            充值记录
                        </Button>
                        <Button shape="round" className="but-left" onClick={() => lookRechargeRecord(1, "consumption")}>
                            消费记录
                        </Button>
                    </div>
                </div>
                <Table
                    size="small"
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={customerData}
                    // loading={loading}
                    onChange={onTableChange}
                    pagination={{
                        showQuickJumper: true,
                        showSizeChanger: false,
                        showTotal: () => `共${pagination.total}条`,
                        position: ["bottomCenter"],
                        ...pagination,
                    }}
                    onRow={(record, index) => ({
                        onClick: () => setSelectInfo({ rowInfo: record, rowIndex: index }),
                    })}
                    rowClassName={(record, index) => (index === selectInfo.rowIndex ? "dark-row" : "")}
                />
            </div>
            <AccountDetails customerInfo={customerInfo} rowInfo={selectInfo.rowInfo} accountVisible={accountVisible} onClose={onDetailsClose} />
            <PubDrawer visible={recordMoney} onClose={() => setRecordMoney(false)}>
                <div className="team-drawer-title">
                    <span>{moneyMode === "consumption" ? "消费记录" : "充值记录"}</span>
                </div>
                <div className="team-drawer-content">
                    <Table
                        size="small"
                        rowKey={(record) => record.id}
                        columns={moneyMode === "consumption" ? consumptionColumns : rechargeColumns}
                        dataSource={moneyData}
                        pagination={paginationHis}
                        onChange={onTableChangeHis}
                    />
                    <div className="team-form-footer">
                        <Button onClick={() => setRecordMoney(false)} className="team-but-one">
                            取消
                        </Button>
                        <Button className="team-but-two" onClick={() => setRecordMoney(false)}>
                            确认
                        </Button>
                    </div>
                </div>
            </PubDrawer>
        </>
    )
}
export default AccountInfo
