import axios from "axios";
import { message } from "antd";
import { getToken, getPortalToken } from "../../utils/dataStorage"
import { API_SERVER_HOST } from "../../config/app";
import storage from "good-storage"
import moment from 'moment';

class HttpRequest {

    inst;

    constructor(config = {}) {
        let defaultConfig = {
            baseURL: API_SERVER_HOST,
            // baseURL: "http://ci.peijian88.cn:9527",
            timeout: 1000 * 300,
        }
        this.inst = axios.create({ ...defaultConfig, ...config })
        this.interceptRequest();
        this.interceptResponse();
    }

    interceptRequest() {
        this.inst.interceptors.request.use(
            config => {
                // if (!config.baseURL && config.url) {
                //     config.baseURL = config.url.indexOf("/ci-storage/") > -1 ? "http://82.156.12.88:9527" : API_SERVER_HOST;
                // }
                config.headers.Authorization = config.token ?? getToken();
                return config;
            },
            error => Promise.reject(error)
        )
    }

    // interceptResponse() {
    //     this.inst.interceptors.response.use(
    //         response => response,
    //         error => {
    //             let res = error.response
    //             if (res && res.status && (res.status === 400 || res.status === 500)) {
    //                 //处理自定义业务异常
    //                 //....
    //                 let retData = res.data
    //                 message.error(`${retData.retMsg}[${retData.retData}]`)
    //             } else {
    //                 message.error("请求超时")
    //             }
    //             return Promise.reject(error);
    //         }
    //     )
    // }

    interceptResponse() {
        this.inst.interceptors.response.use(
            (res) => {
                if (res.status === 200) {
                    let data = res.data
                    if (data.retCode === 200 || !data.retCode) { //导出没有retCode
                        return Promise.resolve(res)
                    } else {
                        message.error(data.retMsg)
                        return Promise.reject(data)
                    }
                } else {
                    this.errorHandle(res)
                    return Promise.reject(res.data)
                }
            },
            error => {
                let res = error.response
                let addErr = { url: this.url, time: moment().format('YYYY-MM-DD HH:mm:ss'), }
                if (res) {
                	addErr.data = res.config.data
					addErr.code = res.status
                    let retData = res.data 
                    message.error(`code:${res.status} msg:${retData.retMsg} retData:${retData.retData}`)
                } else {
                    message.error('服务器响应超时，请尝试重新请求')
                    addErr.code = 'not response'
                }
                let requestErrData = storage.get('requestErrData') || []
                requestErrData.unshift(addErr)
                storage.set('requestErrData', requestErrData.slice(0, 50))
            }
        )
    }

    errorHandle(res) {
        // 状态码判断
        switch (res.status) {
            case 401:
                console.log("认证错误")
                message.error("token认证过期，请重新登陆")
                break
            case 403:
                console.log("没有权限访问资源")
                break
            case 404:
                console.log("请求的资源不存在")
                break
            case 500:
                console.log("请求的资源不存在")
                // message.error(res.retMsg)
                break
            default:
                console.log("连接错误")
        }
    }

    // responseTypeCheck(res) {
    //     const resType = res.headers["content-type"];
    //     if (resType !== "application/json") {
    //         console.log("只支持json返回数据!");
    //         //.....
    //     }
    // }

    async post(url, data, config) {
        let ret = await this.inst.post(url, data, config);
        // this.responseTypeCheck(ret);
        return ret?.data;
    }

    async postFile(url, data, config) {
        let ret = await this.inst.post(url, data, { ...config, responseType: "blob" });
        return ret;
    }

    async get(url, config) {
        let ret = await this.inst.get(url, config);
        // this.responseTypeCheck(ret);
        return ret?.data;
    }
    async getFile(url, config) {
        let ret = await this.inst.get(url, { ...config, responseType: "blob" });
        return ret;
    }
}

export const httpRequest = new HttpRequest();