import React from "react"
import YhIcon from "@/components/pubIcon"
import { Button } from "antd";
import "./yh-drawer.less"

const YhDrawer = ({
    visible,
    title = "弹框",
    maskClosable = true,
    mask = true,
    zIndex = 1000,
    children,
    width = 900,
    okText = "确定",
    onOk,
    closeText = "取消",
    onClose,
    showFooter = true,
    style,
    okLoading = false
}) => {

    const handleClose = () => {
        onClose && onClose()
    }

    return (
        <div
            className="yh-drawer"
            style={{
                transform: visible ? "translateX(0)" : "translateX(100%)",
                zIndex,
                ...style
            }}
        >
            {!!mask && <div className="yh-drawer-mask" onClick={maskClosable ? handleClose : null}></div>}
            <div className="yh-drawer-body" style={{ width: width + "px" }}>
                <div className="yh-drawer-title"><span>{title}</span></div>
                <div className="yh-drawer-content">
                    {!visible ? null : children}
                </div>
                <div className="yh-drawer-footer" hidden={!showFooter}>
                    <Button className="btn-item" onClick={handleClose} >
                        {closeText}
                    </Button>
                    <Button className="btn-item" onClick={() => {
                        onOk && onOk();
                    }} loading={okLoading}>
                        {okText}
                    </Button>
                </div>
                <span className="close-btn" onClick={handleClose}>
                    <YhIcon type="icon-guanbi" />
                </span>
            </div>
        </div>
    )
}
export default YhDrawer;