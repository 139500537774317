import React, { useEffect, useState } from "react"
import { taskBookModel } from "./taskBookModel"
import { ItemBox } from './itemBox'
import { JobBox } from './jobBox'
import { OnemaintainBox } from './onemaintainBox'
import { Tabs, Row, Col, TreeSelect, Button, Modal, Checkbox, message } from "antd"
import PrintCheckDetail from './printCheckDetail'

export default ({ orderId, vehicleCode }) => {

    const {
        bookId, formVal, tabslist, treeData,
        loadTcoDict,
        onTreeSelect,
        getTaskBookDetail,
    } = taskBookModel();

    const [tabActives, steTabActives] = useState('维保任务书总表')
    const [isPrintOpen, setIsPrintOpen] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const [printVisible, setPrintVisible] = useState({ visible: false, orderId: '' })

    useEffect(() => {
        loadTcoDict();
        getTaskBookDetail(vehicleCode);
    }, [])

    const printCheckbox = tabslist.map((item) => {
        return { label: item, value: item };
    }).concat({ label: "更换项目", value: "changeItems" });

    const handlePrintOk = () => {
        if (checkedList.length == 0) {
            message.warning('请选择需要打印的项目')
            return;
        }
        let levels = checkedList.filter(e => e != "changeItems");
        if (levels.length == 0) {
            message.warning('请选择保养项目')
            return;
        }

        setIsPrintOpen(false);
        setPrintVisible({
            visible: true,
            orderId: orderId,
        })
    };

    return (
        <>
            <Row>
                <Col span={24}>
                    <Tabs
                        defaultActiveKey={tabActives}
                        onChange={(e) => {
                            steTabActives(e)
                        }}
                    >
                        <Tabs.TabPane tab="维保检查项目排程" key="all">
                            <Tabs
                                defaultActiveKey={tabActives}
                                onChange={(e) => {
                                    steTabActives(e)
                                }}
                                style={{ position: 'relative', marginTop: '10px' }}
                            >
                                <Tabs.TabPane tab="维保任务书总表" key="allJob">
                                    <ItemBox value={'维保任务书总表'} />
                                </Tabs.TabPane>

                                {tabslist.map((item) => {
                                    return (
                                        <Tabs.TabPane key={item} tab={item}>
                                            <JobBox value={item} />
                                        </Tabs.TabPane>
                                    )
                                })}
                            </Tabs>
                            {/* <TreeSelect
                                style={{ width: 240, position: 'absolute', right: '150px', top: '10px', zIndex: 99 }}
                                onSelect={(e, i) => {
                                    onTreeSelect(e, i)
                                }}
                                value={formVal.firstMaintainLevel}
                                placeholder="请选择首次保养级别"
                                treeData={treeData}
                                treeDefaultExpandAll={true}
                            /> */}
                            <Button
                                type="primary"
                                shape="round"
                                onClick={() => { setIsPrintOpen(true); }}
                                style={{ position: 'absolute', right: '20px', top: '10px', zIndex: 99 }}
                            >打印</Button>
                            <Modal title="请选择需要打印的项目" open={isPrintOpen} onOk={handlePrintOk} onCancel={() => { setIsPrintOpen(false); }}>
                                <Checkbox.Group options={printCheckbox} value={checkedList} onChange={(list) => { setCheckedList(list) }} />
                            </Modal>
                            {printVisible.visible && <PrintCheckDetail visible={printVisible} bookId={bookId} items={checkedList} onClose={(bool) => setPrintVisible(bool)} />}
                        </Tabs.TabPane>

                        <Tabs.TabPane tab="维保更换项目排程" key="item">
                            <OnemaintainBox value={'item'} />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
        </>
    )
}
