import React, { useContext, useState, useEffect } from "react"
import {Table,Button} from 'antd'
import TopSearch from "@/components/TopSearch"
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
//import ListComponent from "@/components/listComponent"
import {stockInAndOutExport,stockInAndOutStatistics,stockInAndOutPage } from "@/service/statistics"
import { MyContext } from "@/store/myContext"
import moment from "moment"
import { message } from "antd"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { formatNum } from '@/erp_subpackage/utils/util';
import useGlobalModel,{ transPgToPagination } from "@/erp_subpackage/model/globalModel";
import {YhBox} from '@//erp_subpackage/components/YhBox'
import { momentFormat } from "@/erp_subpackage/utils/util"

export default () => {
    const { state,Option } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const { user: { shopId, shopCode, shopName, organType,sourceApp, branch, orgCode, orgName },fetchFmsShopList } = useGlobalModel()
    const [searchValue, setSearchValue] = useState({
        // startTime: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
        // endTime: moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss"),
    })
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => []);
    const initPgCfg = { pageSize: 10, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg)
    //出入库类型
    const [ioFlag, setIoFlag] = useState(0);
    const initdesc = {
        amount:0,
        num:0,
       
    }
    const [order, setOrder] = useState({})
    const [descTotal,setDescTotal,] =useState(initdesc)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (state.activeMenu === "stockInAndOut") {
            loadData()
        }

    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    //列表数据
    const loadData = async( page,search = searchValue, ) => {
        // setLoading(true)
        let data = {
             ...search,
             pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
             pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
            shopCode,
            shopId,
            shopName,
            orgCode, 
            orgName,
            ioFlag:search.ioFlag??ioFlag
        }
        let {retData} =  await stockInAndOutPage(data)
        let {records,...pg} =retData
        setList(records)
        setLoading(false)
        setPgCfg(transPgToPagination(pg));
        // stockInAndOutStatistics(data).then(({retData:totalVo})=>{
        //     setDescTotal(totalVo)  
        // })
    }
    //导出
    const handleExport = (search=searchValue) => {
 
        const searchParams = {
            searchItem:{
                ...search,
                shopId: search.shopId ?? shopId,
                shopCode,
                shopName,
                ioFlag:search.ioFlag??ioFlag
            },
            pageSize: 1,
            pageNum:15,
        }
        stockInAndOutExport(searchParams).then((res) => {
            message.success(res.retData)
        })
        
    }



     //列表button
    const topButtons = <>
    <Button
        className="btn-item"
        shape="round"
        onClick={() => handleExport()}
    >导出</Button>
     </>

    //table配置
    const columns = [
        {
            title: '序号',
            width: 40,
            align: "center",
            ellipsis: true,
            render: (t, r, i) => i + 1,
        }, {
            title: '单据编号',
            width: 120,
            dataIndex: 'code',
            align: "center",
            ellipsis: true,
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '配件编码',
            width: 120,
            dataIndex: 'materialCode',
            ellipsis: true,
            align: "center",
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '配件名称',
            width: 160,
            ellipsis: true,
            align: "center",
            dataIndex: 'materialName',
        }, {
            title: '所属仓库',
            width: 160,
            ellipsis: true,
            align: "center",
            dataIndex: 'warehouseName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '所属货位',
            width: 140,
            align: "center",
            ellipsis: true,
            dataIndex: 'shelfCode',
        }, {
            title: ioFlag ? '入库数量' : '出库数量',
            width: 100,
            ellipsis: true,
            align: "center",
            dataIndex: 'num',
        }, {
            title: '单价',
            width: 100,
            ellipsis: true,
            align: "center",
            dataIndex: 'price',
        }, {
            title: ioFlag ? '入库金额' : '出库金额',
            width: 100,
            ellipsis: true,
            align: "center",
            dataIndex: 'amount',
        }, {
            title: '单据类型',
            width: 120,
            ellipsis: true,
            align: "center",
            dataIndex: 'tradeTypeName',
        }, {
            title: '制单人',
            width: 100,
            ellipsis: true,
            align: "center",
            dataIndex: 'createUser',
        }, {
            title: '制单时间',
            width: 160,
            ellipsis: true,
            align: "center",
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '库管员',
            width: 100,
            ellipsis: true,
            align: "center",
            dataIndex: 'storekeeper',
        }, {
            title: ioFlag ? '入库时间' : '出库时间',
            width: 160,
            ellipsis: true,
            align: "center",
            dataIndex: 'stockIoTime',
            render: (text) => momentFormat(text)
        }, {
            title: '单位名称',
            width: 180,
            ellipsis: true,
            align: "center",
            dataIndex: 'userName',
            ellipsis: true,
        }
    ]
       //搜索JSON
       const searchList = {
        normalList: [
            
            {
                type: 'input',
                placeholder: '单据编号',
                valueName: 'code',
            },
            {
                type: 'input',
                placeholder: '配件编码',
                valueName: 'materialCode',
            },
            {
                type: 'input',
                placeholder: '配件名称',
                valueName: 'materialName',
            },
            {
                type: 'input',
                placeholder: '所属仓库',
                valueName: 'warehouseName',
            },
            {
                type: 'select',
                placeholder: ioFlag ? "入库类型" : "出库类型",
                valueName: 'tradeTypeCode',
                optionList: () =>
                getSelectedDictOne(DictCodeEnmu.TRADE_TYPE).map((item) =>
                ( 
                <Option key={item.key} value={item.value}>
                    {item.label}
                </Option>
            )),
            },
        ], 
        hideList: [
            {
                type: 'RangePicker',
                valueName: ['startTime', 'endTime'],
                placeholder: ioFlag ? ["入库开始", "入库结束"] : ["出库开始", "出库结束"],
                format: "YYYY-MM-DD",
                span: 16,
                xxl: 8,
            },
        ]
    }

    const desc = [
        {
            label: ioFlag ? "入库项数" : "出库项数",
            value: descTotal.num ?? 0 ,
            color:'red'
        },
        {
            label: ioFlag ? "入库总计" : "出库总计",
            value: descTotal.amount ?? 0,
            color: 'green'
        },
    ]
    const ioFlagRecord = (io) => {
        setIoFlag(io);
        loadData(initPgCfg, { ...searchValue, ioFlag: io });
    }
    const title = <>
        <Button
            className={`mr-5 ${ioFlag ?"btn-item" : "tab-buts-active" }`}
            shape="round"
            type="link"
            onClick={() => ioFlagRecord(0)}
        >出库记录</Button>
        <Button
            className={`mr-5 ${ioFlag ? "tab-buts-active" : "btn-item"}`}
            shape="round"
            type="link"
            onClick={() => ioFlagRecord(1)}
        >入库记录</Button>
    </>
    return (
        <>
            {state.activeMenu === "stockInAndOut" && (
                <>
                    <TopSearch
                        title="出入库记录明细"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <YhBox style={{ padding: "10px 15px"}}>{title}</YhBox>
                    <YhBox  title="出入库记录明细" descriptions={desc}   style={{marginTop:20}} action={topButtons}>
                    <Table
                  
                        columns={columns}
                        dataSource={list}
                        pagination={ 
                            
                            {   ...pgCfg,
                                showSizeChanger:true,
                                position: ["bottomCenter"],
                                showTotal: () => `共${pgCfg.total}条`,
                                onChange: (pageNum,pageSize) => {
                                    loadData( {pageNum,pageSize})
                                }
                            }
                        }
                       
                        scroll={{ x: "max-content" }}
                        loading={loading}
                    />
                    </YhBox>
                </>
            )}
        </>
    )
}
