import React, { useEffect, useState } from "react"
import { Button, Table, Upload, Tooltip, message } from "antd"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { useSaleOrderDetailModel } from "./saleOrderDetailModel"
import { importPropsConfig } from "@/erp_subpackage/utils/importFile"
import { DetailType, ISalesTypeEnum, IPriceTypeEnum } from "@/erp_subpackage/utils/ErpEnum"
import { EditableRow, EditableCell } from "@/erp_subpackage/components/YHEditTable/editTable"
import { formatNum, formatInt } from "@/erp_subpackage/utils/util"
import useGlobalModel from "@/erp_subpackage/model/globalModel"
import { YhBut } from "@/erp_subpackage/components/YhButton"
import { stockDetailedService } from "@/erp_subpackage/pages/stock/detailed/stockDetailedService"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import { useSaleSoDetailModel } from "../../../so/view/details/saleSoDetailModal";

export const TableBox = ({ mode }) => {
    const {
        user: { shopId },
        setMaterialInfo,
    } = useGlobalModel()
    // const {
    //     modelScreenMethod,
    // } = useSaleSoDetailModel();
    const {
        setAddModalVisible,
        detailAddForm,
        detailEditForm,
        detailLookForm,
        detailAddList,
        detailDataSource,
        detailLookList,
        detaiAddDataRowKeys,
        detailDataRowKeys,
        setDetaiAddDataRow,
        setDetaiAddDataRowKeys,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailAddList,
        setDetailDataSource,
        removeDetailList,
        downloadTpl, transformsToPoDetailList
    } = useSaleOrderDetailModel();
    const detailForm= mode === "add" ? detailAddForm :  mode === "edit" ?detailEditForm:detailLookForm
    const typeList = {
        add: detailAddList,
        edit: detailDataSource,
        look: detailLookList,
    }

    let { priceTypeCode, salesTypeCode, customerCode, customerName } = mode === "add" ? detailAddForm : detailEditForm
    const modelScreenMethod = async (modelList, detailList) => {
        const repetition = []
        const newDetailData = []
        console.log(modelList)
        modelList.forEach((Item) => {
            let isSelect = detailList && detailList.find((item) => item.detailId === Item.id)
            if (isSelect === undefined) {
                newDetailData.push({
                    ...Item,
                    detailId: Item.id,
                    id: "",
                    // amount: +formatNum(Number(Item.amount) - Number(Item.decreaseAmount)),  //退货金额
                    // discountAmount: +formatNum(Number(Item.num) * Number(Item.price) * (Number(Item.discountRate) / 100)),  //退货折扣金额
                    // rdecreaseAmount: +formatNum(Item.decreaseAmount) ?? 0  //单向优惠金额
                })
            } else {
                repetition.push(Item.materialName)
            }
        })
        return { newDetailData, repetition }
    }


    const importCallback = async (list) => {
        const { repetition, newDetailData } = await modelScreenMethod(list.list, detailAddList)
        console.log(newDetailData)
        if (mode === "add") {
            setDetailAddList([...detailAddList, ...newDetailData])
            repetition.length !== 0 && message.warning(`商品名称：${repetition.join(",")}，重复的将不在添加。`)
        } else if (mode === "edit") {
            setDetailDataSource([...detailDataSource, ...newDetailData])
            repetition.length !== 0 && message.warning(`商品名称：${repetition.join(",")}，重复的将不在添加。`)
        }
        // if (list && list.list.length) {
        let newImportList = await Promise.all(list.list?.map(async (item) => {
            let { stockId, latestPurPrice, materialCode, lateSalePrice, discountRate, purchasePrice, retailPrice, num, ...params } = item;
            let newPrice = latestPurPrice ?? purchasePrice;
            if (salesTypeCode === ISalesTypeEnum.NORMAL_SALES) {
                switch (priceTypeCode) {
                    //售价选择 客户最近销价 (需要通过接口查询，客户最近一次的销售价)
                    case IPriceTypeEnum.CUSTOMER_LATEST:
                        let { retData: { price } } = await stockDetailedService.recentSalesPrice(shopId, materialCode, customerCode);
                        newPrice = +price;
                        break;
                    //售价选择 最近销价 
                    case IPriceTypeEnum.RECENT:
                        newPrice = lateSalePrice ?? retailPrice;
                        break;
                    //售价选择 零售价
                    case IPriceTypeEnum.RETAIL:
                        newPrice = retailPrice;
                        break;
                    //售价选择 采购成本价
                    case IPriceTypeEnum.PUR_COST:
                        newPrice = latestPurPrice ?? purchasePrice;
                        break;
                    //售价选择 档案进价
                    case IPriceTypeEnum.BUYING_PRICE:
                        newPrice = purchasePrice ?? latestPurPrice;
                        break;
                }
            }
            return {
                ...item,
                id: stockId,
                num,
                price: newPrice,
            }
        }))
        transformsToPoDetailList(mode, newImportList)

       
    // }
    }

    const addModal = () => {
        if (!salesTypeCode) {
            message.warning("请选择销售类型")
            return
        }
        if (!customerName) {
            message.warning("请选择客户")
            return
        }
        setAddModalVisible(true)
    }
    const action = (
        <>
            {(mode !== "look" ? mode === "add" || !(detailEditForm.orderSourceCode !== DictCodeEnmu.SOURCE_ADD && mode === "edit") : false) && (
                <>
                    <Button className="btn-item" type="link" shape="round" onClick={() => addModal()}>
                        新增商品
                    </Button>
                    <Button
                        className="btn-item"
                        type="link"
                        shape="round"
                        onClick={() => {
                            removeDetailList(mode)
                        }}
                    >
                        批量删除
                    </Button>
                    <Upload
                        beforeUpload={() => {
                            if (!salesTypeCode) {
                                message.warning("请选择销售类型")
                                return false
                            }
                            if (!customerName) {
                                message.warning("请选择客户")
                                return false
                            }
                            return true
                        }}
                        showUploadList={false}
                        className="mr-10 ml-10"
                        {...importPropsConfig(`/ci-storage/sale/order/details/import/${shopId}`, importCallback)}
                    >
                        <Button disabled={!(salesTypeCode && customerName)} className="btn-item" type="link" shape="round">
                            导入
                        </Button>
                    </Upload>

                    <Button className="btn-item" type="link" shape="round" onClick={downloadTpl}>
                        下载模板
                    </Button>
                </>
            )}
        </>
    )

    const columns = [
        {
            title: "序号",
            width: 80,
            fixed: "left",
            render: (t, r, i) => i + 1,
        },
        {
            title: "商品编号",
            width: 120,
            dataIndex: "materialCode",
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName,customerName:detailForm.customerName,customerCode:detailForm.customerCode})} />,
        },
        {
            title: "商品名称",
            width: 120,
            dataIndex: "materialName",
            fixed: "left",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "单位",
            width: 50,
            dataIndex: "unitName",
        },
        {
            title: "订单数量",
            width: 100,
            dataIndex: "num",
            editable: mode != DetailType.look || detailEditForm.orderSourceCode === DictCodeEnmu.SOURCE_ADD,
        },
        {
            title: "单价",
            width: 100,
            dataIndex: "price",
            editable: mode != DetailType.look || detailEditForm.orderSourceCode === DictCodeEnmu.SOURCE_ADD,
            render: text => formatNum(text)
        },
        {
            title: "金额",
            width: 100,
            dataIndex: "notAmount",
            render: text => formatNum(text)
        },
        {
            title: "折扣",
            width: 80,
            dataIndex: "discountRate",
            editable: mode != DetailType.look || detailEditForm.orderSourceCode === DictCodeEnmu.SOURCE_ADD,
            suffix: "%",
            render: (text) => text + "%",
        },
        {
            title: "零件金额",
            width: 100,
            dataIndex: "amount",
            render: text => formatNum(text)
        },
        {
            title: '品牌',
            width: 100,
            dataIndex: 'brandName',
        },
        {
            title: "仓库",
            width: 140,
            dataIndex: "warehouseName",
        },
        {
            title: "货位",
            width: 120,
            dataIndex: "shelfCode",
        },
        {
            title: "库存数量",
            width: 100,
            dataIndex: "inventoryNum",
        },
        {
            title: "采购单号",
            width: 160,
            dataIndex: "purchaseCode",
        },
        {
            title: "备注",
            width: 160,
            dataIndex: "note",
            editable: mode != DetailType.look || detailEditForm.orderSourceCode === DictCodeEnmu.SOURCE_ADD,
        },
        {
            title: "操作",
            width: 60,
            fixed: "right",
            align: "center",
            render: (text, record) =>
                (mode !== "look"
                    ? mode === "add" || !(detailEditForm.status === DictCodeEnmu.SALE_ORDER_SETTLE || detailEditForm.status === DictCodeEnmu.SALE_STOCK_IO)
                    : false) && (
                    <YhBut
                        type="delete"
                        txt="删除"
                        disabled={mode === "look" || (detailEditForm.orderSourceCode !== DictCodeEnmu.SOURCE_ADD && mode === "edit")}
                        click={() => removeDetailList(mode, record)}
                    />
                ),
        },
    ]

    const newSelectColumns = () =>
        columns.map((col, index) => {
            return {
                ...col,
                onCell: (record, rowIndex) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col?.rules,
                    options: col?.options,
                    editType: col?.editType,
                    editable: col?.editable,
                    onSelectChange: col?.onSelectChange,
                    onSelectFocus: col?.onSelectFocus,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                }),
            }
        })
    const handleSave = (row) => {
        let { num, price, discountRate, purchasePrice, latestPurPrice } = row
        let newPurPrice = +formatNum(latestPurPrice !== 0 ? latestPurPrice : purchasePrice)
        discountRate = +formatNum(discountRate)
        num = +formatInt(num)
        price = +formatNum(price)
        if (price < newPurPrice) {
            price = newPurPrice;
            message.warning(`修改价格不应小于采购价格${newPurPrice}`)
        }
        let newRow = {
            ...row,
            price,
            discountRate,
            num,
            notAmount: +formatNum(num * price),
            amount: +formatNum(num * price * discountRate / 100),
        }

        let newData = mode === "edit" ? detailDataSource : detailAddList
        const index = newData.findIndex((item) => row.detailsId === item.detailsId)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...newRow })
        mode === "edit" ? setDetailDataSource([...newData]) : setDetailAddList([...newData])
    }

    const setTypeDetailDataRowKeysAndRow = async (selectedRowKeys, mode) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeDetailDataRow = async (selectedRows, mode) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailDataRow(selectedRows)
        }
    }
    return (
        <YhBox title="商品信息" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowClassName={(record) => {
                    if (+Number(record.num) > +(record.inventoryNum ?? 0)) return "editable-row yh-edit-table-tr background-FFB4B4"
                    return "editable-row yh-edit-table-tr"
                }}
                rowKey={(record) => record.detailsId}
                rowSelection={
                    mode === DetailType.look
                        ? undefined
                        : {
                            selectedRowKeys: mode == DetailType.add ? detaiAddDataRowKeys : detailDataRowKeys, //记录选中
                            onChange: (selectedRowKeys, selectedRows) => {
                                setTypeDetailDataRowKeysAndRow(selectedRowKeys, mode)
                                setTypeDetailDataRow(selectedRows, mode)
                            },
                            onSelectAll: (selected, selectedRows) => {
                                setTypeDetailDataRow(selectedRows, mode)
                            },
                        }
                }
                columns={newSelectColumns()}
                dataSource={typeList[mode]}
                pagination={false}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                scroll={{ x: "max-content" }}
            />
        </YhBox>
    )
}
