import React, { useContext, useEffect, useState } from "react"
import { Button, message, Modal, Upload, Spin } from "antd"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { useVehicleModel } from "../vehicleModel"
import { DataTable } from "./DataTable"
import { MyContext } from "@/store/myContext"
import { importPropsConfig } from "@/erp_subpackage/utils/importFile";
import useGlobalModel from "@/erp_subpackage/model/globalModel"

export const DataBox = ({ customerName }) => {
    const { selectedRowKeys, initPgCfg, searchParam, exportVehicle, downloadTpl, updateSearchParam, loadData, setSearchParam, resetSearchParam } = useVehicleModel()
    const { state, dispatch, detailsPage } = useContext(MyContext)
    const { user: { shopId, shopName } } = useGlobalModel()
    const [uploading, setUploading] = useState({ spinning: false, tip: "加速上传中..." });

    useEffect(() => {
        if (state.activeMenu === "baseVehicle") {
            if (customerName) {
                loadData(initPgCfg, { customerName })
                updateSearchParam({ customerName })
            } else {
                loadData()
            }
        }
    }, [state.activeMenu])

    //新增 查看 编辑
    const goPageNav = (mode) => {
        let tabName = {
            add: "车辆新增",
            edit: "车辆编辑",
            look: "车辆查看",
        }
        let modeKey = "baseVehicle/" + mode

        //动态传入要删除的nav key
        function handleDispatch(obj = {}) {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.BaseVehicleDetails({
                    dispatch,
                    modeKey,
                    mode,
                    rowId: selectedRowKeys[0],
                }),
            })
        }

        if (mode === "edit") {
            const findNav = state.panes.find((item) => item.key === modeKey)
            if (findNav) {
                Modal.confirm({
                    title: "温馨提醒！",
                    content: "您还有车辆详情未保存，是否跳转",
                    okText: "跳转未保存页",
                    cancelText: "打开新页签",
                    onOk: () => {
                        handleDispatch()
                    },
                    //打开新页签重新生成
                    onCancel: () => {
                        handleDispatch({ deleteKey: modeKey })
                    },
                })
                return
            }
            handleDispatch()
        } else if (mode === "add") {
            handleDispatch()
        } else if (mode === "look") {
            handleDispatch({ deleteKey: modeKey })
        }
    }

    const uploadCallback = (params) => {
        setUploading({ spinning: false })
        params && loadData()
    }

    // 返回客户
    const backCustomer = () => {
        const modeKey = "baseCustomer"
        dispatch({
            change: true,
            type: "changeTabs",
            name: "客户档案",
            activeMenu: modeKey,
            targetKey: modeKey,
        })
        setSearchParam({})
        resetSearchParam()
    }
    const action = (
        <>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    goPageNav("add")
                }}
            >
                新增
            </Button>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    if (selectedRowKeys.length !== 1) {
                        message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！")
                        return
                    }
                    goPageNav("edit")
                }}
                disabled={searchParam.shopId}
            >
                编辑
            </Button>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    if (selectedRowKeys.length !== 1) {
                        message.warning("请选择单条数据进行查看，点击鼠标右键可一键选中哦！")
                        return
                    }
                    goPageNav("look")
                }}
            >
                查看
            </Button>
            <Upload showUploadList={false}  {...importPropsConfig(`/ci-storage/base/vehicle/import`, uploadCallback, { shopId, shopName }, () => setUploading({ spinning: true, tip: "加速上传中..." }))}>
                <Button className="btn-item" type="link" shape="round" disabled={searchParam.shopId}>导入</Button>
            </Upload>
            <Button className="btn-item" shape="round" onClick={() => exportVehicle()}>
                导出
            </Button>
            <Button className="btn-item" shape="round" onClick={() => downloadTpl()}>
                下载模板
            </Button>
            <Button className="btn-item" shape="round" onClick={() => { backCustomer() }}>
                返回客户
            </Button>
        </>
    )
    return (
        <Spin {...uploading}>
            <YhBox title="车辆列表" action={action}>
                <DataTable />
            </YhBox>
        </Spin>
    )
}
