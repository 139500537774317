import React, { useEffect, useState } from "react"
import { Button, Form, Input, Col, Row, message, Select, DatePicker, Table, Image } from "antd"
import { shopGetOrderDetail, shopOrdersAddress, shopOrdersDelivery } from "@/service/shopb"
import moment from "moment"

const { Option } = Select
export default (props) => {
    const { modeKey, dispatch, mode, rowInfo, companies } = props
    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    const [customerInfo, setCustomerInfo] = useState({
        //客户列表
        data: [],
        fetching: false,
    })

    // 是否允许修改
    const [allow_edit_consignee, setAllow_edit_consignee] = useState(false)
    // 是否允许发货
    const [allow_ship, setAllow_ship] = useState(false)

    const [orderDetail, setOrderDetail] = useState([])
    useEffect(() => {
        queryMotorcade()
    }, [])

    //查看编辑回显
    const queryMotorcade = () => {
        shopGetOrderDetail(rowInfo.id).then((res) => {
            setOrderDetail(res)
            setAllow_edit_consignee(res.order_operate_allowable_vo.allow_edit_consignee)
            setAllow_ship(res.order_operate_allowable_vo.allow_ship)
            form.setFieldsValue({
                ...res,
                ...{
                    create_time: res.create_time ? moment.unix(res.create_time) : res.create_time,
                    paymentType: res.payment_type ? "在线支付" : "货到付款",
                },
            })
            form2.setFieldsValue(res)
        })
    }

    //取消
    const handleCancel = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "shopOrderb",
        })
    }
    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }
    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    //修改发货人地址
    const handleSave = (cbk) => {
        form.validateFields().then((values) => {
            let { ship_mobile, ship_addr, remark, ship_name, receive_time } = values
            let data = {
                ship_mobile,
                ship_addr,
                remark,
                ship_name,
                receive_time,
            }
            shopOrdersAddress(rowInfo.sn, data)
                .then((res) => {
                    if (cbk) {
                        cbk()
                    } else {
                        message.success("保存成功")
                        queryMotorcade()
                    }
                })
                .catch((err) => {
                    if (err === 403) {
                        shopOrdersAddress(rowInfo.sn, data)
                    }
                })
        })
    }

    //发货
    const handleFahuo = () => {
        form2.validateFields().then((values) => {
            const logi_name = companies.find((item) => item.logistics_company_do.id === values.logi_id).logistics_company_do.name
            const data = {
                ...values,
                logi_name,
            }
            const sn = rowInfo.sn
            shopOrdersDelivery(sn, data)
                .then((res) => {
                    message.success("发货成功")
                    queryMotorcade()
                })
                .catch((err) => {
                    if (err === 403) {
                        shopOrdersDelivery(sn, data)
                    }
                })
        })
    }

    const columns = [
        {
            title: "商品列表",
            align: "center",
            dataIndex: "goods_image",
            render: (text) => (text && text.length > 0 ? <Image src={text} width={34} height={34} key={text[0]} /> : ""),
        },
        {
            title: "商品名称",
            align: "center",
            dataIndex: "name",
        },
        {
            title: "商品编号",
            align: "center",
            dataIndex: "goods_sn",
        },
        {
            title: "单价",
            align: "center",
            dataIndex: "original_price",
        },
        {
            title: "数量",
            align: "center",
            dataIndex: "num",
        },
        {
            title: "合计",
            align: "center",
            dataIndex: "actual_pay_total",
        },
    ]
    return (
        <>
            <div className="list-container container-fixed">
                <div className="list-header">
                    <div className="list-name">
                        <span>订单信息</span>
                    </div>
                    <div className="list-but">
                        {allow_edit_consignee && (
                            <Button shape="round" className="but-left" onClick={handleSave}>
                                保存
                            </Button>
                        )}

                        {allow_ship && (
                            <Button
                                shape="round"
                                className="but-left"
                                onClick={() => {
                                    handleSave(handleFahuo)
                                }}
                            >
                                保存并发货
                            </Button>
                        )}

                        <Button shape="round" className="but-left" onClick={handleCancel}>
                            取消
                        </Button>
                    </div>
                </div>
            </div>
            <div className="list-container" style={{ marginTop: "100px" }}>
                <div className="list-header">
                    <div className="list-name">
                        <span>订单信息</span>
                    </div>
                </div>
                <div className="form-details-warp" style={{ padding: "20px 20px 5px 20px" }}>
                    <Form form={form} {...formItemLayout}>
                        <Row>
                            <Col span={6}>
                                <Form.Item label="客户名称" name="ci_customer_name">
                                    <Input placeholder="客户名称" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="收货人"
                                    name="ship_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入收货人",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入收货人" disabled={isLook(mode) || !allow_edit_consignee} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="联系电话"
                                    name="ship_mobile"
                                    rules={[
                                        {
                                            required: true,
                                            message: `请输入联系电话!`,
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入联系电话" disabled={isLook(mode) || !allow_edit_consignee} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="收货地址"
                                    name="ship_addr"
                                    rules={[
                                        {
                                            required: true,
                                            message: `请输入收货地址`,
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入收货地址" disabled={isLook(mode) || !allow_edit_consignee} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="订单编号" name="sn">
                                    <Input placeholder="请输入订单编号" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="付款方式" name="paymentType">
                                    <Input placeholder="付款方式" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="付款渠道" name="payment_method_name">
                                    <Input placeholder="付款渠道" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="下单时间" name="create_time">
                                    <DatePicker
                                        disabled
                                        placeholder="自动生成"
                                        format="YYYY-MM-DD HH:mm:ss"
                                        showTime={{
                                            defaultValue: moment("00:00:00", "HH:mm:ss"),
                                        }}
                                        className="pub-select"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="商品总价" name="goods_original_total_price">
                                    <Input placeholder="商品总价" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="运费" name="shipping_price">
                                    <Input placeholder="运费" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="订单总价" name="order_price">
                                    <Input placeholder="订单总价" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="送货时间" name="receive_time">
                                    <Select placeholder="请选择送货时间" style={{ width: "100%" }} disabled={isLook(mode) || !allow_edit_consignee}>
                                        <Option value="任意时间">任意时间</Option>
                                        <Option value="仅工作日">仅工作日</Option>
                                        <Option value="仅休息日">仅休息日</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={18}>
                                <Form.Item labelCol={{ span: 2 }} wrapperCol={{ span: 22 }} label="备注" name="remark">
                                    <Input placeholder="备注信息" disabled={isLook(mode) || !allow_edit_consignee} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>

            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>物流信息</span>
                    </div>
                </div>
                <div className="form-details-warp" style={{ padding: "20px 20px 5px 20px" }}>
                    <Form form={form2} {...formItemLayout}>
                        <Row>
                            <Col span={6}>
                                <Form.Item
                                    label="配送公司"
                                    name="logi_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择配送公司",
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择配送公司" style={{ width: "100%" }} disabled={isLook(mode) || !allow_ship}>
                                        {companies.map((item) => (
                                            <Option value={item.logistics_company_do.id} item={item} key={item.logistics_company_do.id}>
                                                {item.logistics_company_do.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="物流单号"
                                    name="ship_no"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入物流单号",
                                        },
                                    ]}
                                >
                                    <Input disabled={isLook(mode) || !allow_ship} placeholder="请输入物流单号" />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item
                                    label="销售单号"
                                    name="sales_order_no"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入怀远销售单号",
                                        },
                                    ]}
                                    disabled={!allow_ship}
                                >
                                    <Input disabled={isLook(mode) || !allow_ship} placeholder="请输入怀远销售单号" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>

            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>商品信息</span>
                    </div>
                </div>
                <div className="form-details-warp" style={{ padding: "20px 20px 5px 20px" }}>
                    <Table size="small" rowKey={(record) => record.id} columns={columns} dataSource={orderDetail.order_sku_list} />
                </div>
            </div>
        </>
    )
}
