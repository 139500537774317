import React from "react";
import { Checkbox } from "antd";

export const SearchCheckBox = (props) => {
    return <Checkbox
        checked={Number(props.searchValues?.[props.fieldName]) ? true : false}
        onChange={(e) => {
            props.onChange && props.onChange({ [props.fieldName]: e.target.checked ? "1" : "0" })
            if (props.triggerSearch) {
                props.handleSearch && props.handleSearch({ [props.fieldName]: e.target.checked ? "1" : "0" });
            }
        }}
        disabled={props.disabled}><span className="ant-checkbox-inner-text" style={{ color: props.color }}>{props.placeholder}</span></Checkbox>
}