import React, { useEffect, useState, useRef, useContext } from 'react'
import { Button, message, Input, Modal } from 'antd'
import { YhFormBox } from '@/erp_subpackage/components/YhFormBox'
import { formatNum, repairFormatNum } from '@/erp_subpackage/utils/util'
import { useSaleSoDetailModel } from './saleSoDetailModal'
import useGlobalModel from '@/erp_subpackage/model/globalModel'
import { useDictModel } from '@/erp_subpackage/pages/base/dict/dictModel'
import { DictCodeEnmu } from '@/erp_subpackage/utils/DictCodeEnum'
import { DetailType, ISalesTypeEnum, IPriceTypeEnum, IPayTypeDs } from '@/erp_subpackage/utils/ErpEnum'
import { debounce } from 'lodash'
import { useSaleSoModel } from '../../saleSoModel'
import { MyContext } from '@/store/myContext'
import { getPcodeDict } from '@/utils/dataStorage'
import { stockDetailedService } from '@/erp_subpackage/pages/stock/detailed/stockDetailedService'
import { stockStatisticService } from '@/erp_subpackage/pages/stock/statistic/stockStatisticService'
import { vehicleService } from '@/erp_subpackage/pages/base/vehicle/vehicleService'
import { getVehicleList } from '@/service/customerService'
import { getBentityListOption } from '@/utils/dataStorage'
import TextPriviewImage from "../../TextPriviewImage";
import { invoiceModel } from "../../InvoiceModel";
export const FormBox = ({ modeKey, mode, rowId, dispatch, formRef }) => {
    const { getSelectedDictOne, getTreeNodeName } = useDictModel()
    const {
        fetchYCCustomerList,
        user: { userName, shopCode, shopName,shopId } 
    } = useGlobalModel()
    const { imageUrl } = invoiceModel();
    const { setSelectedRowKeys, setDescRows, setSelectedRow } = useSaleSoModel()
    const { detailsPage } = useContext(MyContext)
    const {
        addDesc,
        detailEditForm,
        detailAddForm,
        detailLookForm,
        initForm,
        detailAddList,
        detailDataSource,
        vehicleEditAll,
        vehicleAddAll,
        vinEditAll,
        vinAddAll,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        setVinEditAll,
        setVinAddAll,
        insertDetail,
        editDetail,
        setVehicleEditAll,
        setVehicleAddAll,
        setDetailAddForm,
        setDetailAddList,
        setTopButsControl,
        setDetailDataSource,
        isAutoSave, setIsAutoSave,saveBtnLoading
    } = useSaleSoDetailModel()
    const [customerType, setCustomerType] = useState({})
    const [more, setMore] = useState(true) //控制更多按钮
    let { priceTypeCode, salesTypeCode, customerCode } = mode === 'add' ? detailAddForm : detailEditForm
    const updateFormVal = mode === 'add' ? updateAddFormVal : updatEditFormVal
    const formVal = mode === 'add' ? detailAddForm : mode === 'look' ? detailLookForm : detailEditForm
    useEffect(() => {
        if (mode === 'edit' || mode === 'look') {
            getDetailEditOne(rowId, mode).then((res) => {
                if (res.orderSourceCode === 'order-source.add') {
                    getVehicleAllList({ customerName: res.customerName })
                } else {
                    getYCVehicleAllList({ customerName: res.customerName })
                }
            })
        }
        //获取云创字典
        let obj = {}
        getPcodeDict('017').children.forEach((item) => {
            obj[item.dictCode] = item.title
        })
        setCustomerType(obj)

        return () => {
            updateFormVal(null)
            mode === 'add' ? setDetailAddList([]) : setDetailDataSource([])
        }

    }, [rowId, mode])

    useEffect(() => {
        if (isAutoSave) {
            save()
            setIsAutoSave(false)
        }
    }, [isAutoSave])

    const save = () => {
        // if (!verifyGoods(mode)) return;
        let goods = mode === 'add' ? detailAddList : detailDataSource
        let purcharseNum = goods.filter((item) => item.num == 0)
        let totalDecreaseAmount = 0
        if (purcharseNum.length > 0) {
            message.warning(`【${purcharseNum.map((item) => item.materialName).join()}】的销售数量不能为0`)
            return
        }
        if(goods.length==0){
            message.warning(`未添加商品明细不可生成销售单`)
            return
        }
        // 单项优惠金额总数不能大于1
        goods.forEach((item) => {
            totalDecreaseAmount += Number(item.decreaseAmount)
        })
        if (totalDecreaseAmount >= 1) {
            message.warning('单项优惠的总金额不能大于1')
            return
        }
        formRef.current.validateFields().then(async (res) => {
            console.log(111)
            console.log(111)
            console.log(res.settlementPartyCode)
            console.log(res)
            
            if (res.salesTypeCode === ISalesTypeEnum.GIVE && !!!res.note) {
                message.warning('销售类型为赠送服务时，备注为必填项，请补齐表单里的备注项')
                return false    
            }
            if (res.settlementPartyCode === 'settle-party.internal' && !!!res.note) {
                message.warning('结算方为部门内部费用时，备注为必填项，请补齐表单里的备注项')
                return false
            }
            let { ...params } = res
            if (mode === 'add') {
                let { receivableAll } = addDesc
                receivableAll = +repairFormatNum(receivableAll)
                let { customerCode, customerName, payType, settlementPartyCode } = detailAddForm
                if (!(customerCode && customerName && payType && settlementPartyCode)) {
                    message.warning('请补全客户名称或结算类型或结算方')
                    return false
                }
                await insertSave({ ...params, invoiceTypeName: getTreeNodeName(res.invoiceTypeCode) })
            } else if (mode === 'edit') {
                let { id, saleCode } = detailEditForm
                const resData = await editDetail({ ...params, id, saleCode })
                if (resData) {
                    message.success('编辑成功！')
                    getDetailEditOne(saleCode, mode)
                }
            }
        })
    }
    const insertSave = async (res) => {
        const resData = await insertDetail(res)
        if (resData) {
            message.success('新增成功!')
            setSelectedRowKeys([resData.id])
            setDescRows([resData])
            setSelectedRow(resData)
            goBack()
        }
    }
    const goBack = () => {
        dispatch({
            type: 'removeTabs',
            targetKey: modeKey,
            activeMenu: 'saleSo',
        })
        mode === 'add' ? setDetailAddList([]) : setDetailDataSource([])
    }

    // 来源为新增时车辆数据为云修
    const getVehicleAllList = async (param) => {
        let params = {
            ...param,
            shopId,
            pageSize: -1,
            pageNum: 1,
        }
        const { retData } = await vehicleService.page(params)
        const { records } = retData
        let newRecords = records.map((item) => {
            return {
                // ...item,
                label: item.licensePlate,
                value: item.licensePlate,
                vehicle_type_code: item.categoryCode,
                vehicle_type_name: item.categoryName,
                customer_code: item.code,
                customer_name: item.customerName,
                vin: item.vin,
                vehicle_model: item.model,
                license_plate: item.licensePlate,
                phone: item.phone,
                contact: item.contact,
                address: item.address,
                customer_type_code: item.typeCode,
                customer_type_name: item.typeName,
            }
        })

        let vinRecords = records.map((item) => {
            return {
                label: item.vin,
                value: item.vin,
                license_plate: item.licensePlate,
                vehicle_model: item.model,
                vehicle_type_code: item.categoryCode,
                vehicle_type_name: item.categoryName,
                customer_code: item.code,
                customer_name: item.customerName,
                vin: item.vin,
                phone: item.phone,
                contact: item.contact,
                address: item.address,
                customer_type_code: item.typeCode,
                customer_type_name: item.typeName,
            }
        })
        if (mode === 'add') {
            setVehicleAddAll(newRecords)
            setVinAddAll(vinRecords)
        } else {
            setVehicleEditAll(newRecords)
            setVinEditAll(vinRecords)
        }
    }

    // 来源为小程序或者App时  车辆来源为云创本身的数据
    const getYCVehicleAllList = async (param) => {
        let data = {
            searchItem: {
                ...param,
            },
            pageSize: -1,
            pageNum: 1,
        }
        getVehicleList(data).then((res) => {
            const {
                retData: { list },
            } = res
            let newRecords = list?.map((item) => {
                return {
                    label: item.vehicleCode,
                    value: item.vehicleCode,
                    vehicle_type_name: item.vehicleType,
                    customer_name: item.customerName,
                    customer_code: item.customerId,
                    vin: item.vehicleVin,
                    license_plate: item.vehicleCode,
                }
            })
            let vinRecords = list.map((item) => {
                return {
                    label: item.vehicleVin,
                    value: item.vehicleVin,
                    vehicle_type_name: item.vehicleType,
                    customer_name: item.customerName,
                    customer_code: item.customerId,
                    license_plate: item.vehicleCode,
                    vin: item.vehicleVin,
                }
            })
            if (mode === 'add') {
                setVehicleAddAll(newRecords)
                setVinAddAll(vinRecords)
            } else {
                setVehicleEditAll(newRecords)
                setVinEditAll(vinRecords)
            }
        })
    }

    const onRefresh = async () => {
        if (mode === 'add') {
            formRef.current.resetFields()
            setDetailAddForm(initForm)
            setDetailAddList([])
        } else if (mode === 'edit') {
            getDetailEditOne(rowId, mode)
        }
        message.success('刷新成功')
    }

    const onDiscount = () => {
        let discountVal = ''
        Modal.confirm({
            width: 380,
            title: '折扣',
            // text: "",
            content: (
                <>
                    <span>请输入折扣系数</span>
                    <Input
                        onChange={(e) => {
                            discountVal = e.target.value
                        }}
                        suffix="%"
                    />
                </>
            ),
            okText: '确定',
            onOk: () => {
                var re = /^[0-9]+.?[0-9]*$/
                return new Promise((resolve, reject) => {
                    if (!re.test(discountVal)) {
                        reject('输入的值不是数字，请重新输入')
                        message.error('输入的值不是数字，请重新输入')
                    } else {
                        resolve(discountVal)
                    }
                }).then((res) => {
                    onSetDetailsTableDiscount(+discountVal)
                })
            },
            cancelText: '取消',
            onCancel: () => { },
        })
    }
    const onSetDetailsTableDiscount = async (discount) => {
        let detailsList = mode === 'add' ? detailAddList : detailDataSource
        let newDetailsList = detailsList.map((item) => {
            return {
                ...item,
                discountRate: discount,
                amount: +formatNum((Number(item.notAmount) * discount) / 100),
            }
        })
        mode === 'add' ? setDetailAddList(newDetailsList) : setDetailDataSource(newDetailsList)
    }
    const disabledFromBtn = () => {
        if (mode === 'look') {
            return false
        } else if (mode === 'edit' && detailEditForm.status === DictCodeEnmu.SALE_STOCK_IO) {
            return false
        } else {
            return true
        }
    }
    const rightChildren = (
        <>
            {disabledFromBtn() && (
                <>
                    {/* <Button
                        className="btn-item"
                        type="link"
                        shape="round"
                        onClick={() =>
                            dispatch({
                                type: 'changeTabs',
                                name: '库存统计',
                                activeMenu: 'stockStatistic',
                            })
                        }
                    >
                        各门店库存查询
                    </Button> */}
                    <Button className="btn-item" type="link" shape="round" onClick={onDiscount} disabled={formVal.orderSourceCode != DictCodeEnmu.SOURCE_ADD}>
                        快捷折扣
                    </Button>
                    <Button
                        className="btn-item"
                        type="link"
                        shape="round"
                        onloading={saveBtnLoading}
                        onClick={debounce(() => {
                            save()
                        }, 900)}
                    >
                      {saveBtnLoading ? '保存中' : '保存'}
                    </Button>
                </>
            )}
            {mode !== 'look' && (
                <Button className="btn-item" type="link" shape="round" onClick={onRefresh}>
                    刷新
                </Button>
            )}
            <Button className="btn-item" type="link" shape="round" onClick={goBack}>
                返回
            </Button>
        </>
    )
    //车辆类型
    const vehicleTypeChange = (value, option) => {
        option && onChange({ vehicleTypeName: option.label, vehicleTypeCode: option.value })
    }
    //结算方
    const settlementPartyChange = (value, option) => {
        option && onChange({ settlementPartyName: option.label, settlementPartyCode: option.value })
    }

    //发票类型
    const invoiceTypeChange = (value, option) => {
        option && onChange({ invoiceTypeName: option.label, invoiceTypeCode: option.value })
    }
    //销售实体
    const bentityChange = (value, option) => {
        option && onChange({ bentityName: option.label, bentityCode: option.value })
    }
    //销售类型
    const salesTypeChange = (value, option) => {
        option && onChange({ salesTypeName: option.label, salesTypeCode: option.value, priceTypeName: undefined, priceTypeCode: undefined })
        if (value === ISalesTypeEnum.NORMAL_SALES) {
            option && onChange({ salesTypeName: option.label, salesTypeCode: option.value, priceTypeName: '零售价', priceTypeCode: 'price-selection.retail' })
        }
        if (!value) {
            onChange({ salesTypeName: undefined, salesTypeCode: undefined, priceTypeName: undefined, priceTypeCode: undefined })
        }
        if (value) {
            alterListPrice(value === ISalesTypeEnum.NORMAL_SALES ? 'price-selection.retail' : undefined, value, customerCode)
        }
    }
    //售价选择
    const priceTypeChange = (value, option) => {
        option && onChange({ priceTypeName: option.label, priceTypeCode: option.value })
        if (value) {
            alterListPrice(value, salesTypeCode, customerCode)
        }
    }
    //from销售类型，售价选择时更改 详情表格的价格信息
    const alterListPrice = async (priceTypeCode, salesTypeCode, customerCode) => {
        let detailsList = mode == 'add' ? detailAddList : detailDataSource
        if (!detailsList.length) return
        if (!salesTypeCode || !customerCode) {
            message.warning('请补全客户名称，销售类型才可以，对下列表格的价格数据进行更改')
            return false
        }
        let newDetailsList = await Promise.all(
            detailsList.map(async (item) => {
                let { stockId, latestPurPrice, materialCode, lateSalePrice, discountRate, purchasePrice, decreaseAmount, retailPrice, num, ...params } = item
                let newPrice = 0
                if (salesTypeCode === ISalesTypeEnum.NORMAL_SALES) {
                    switch (priceTypeCode) {
                        //售价选择 客户最近销价 (需要通过接口查询，客户最近一次的销售价)
                        case IPriceTypeEnum.CUSTOMER_LATEST:
                            let {
                                retData: { price },
                            } = await stockDetailedService.recentSalesPrice(shopId, materialCode, customerCode)
                            newPrice = +price
                            break
                        //售价选择 最近销价
                        case IPriceTypeEnum.RECENT:
                            newPrice = await findPoDetailPrice(stockId, ['lateSalePrice', 'retailPrice'], true)
                            break
                        //售价选择 零售价
                        case IPriceTypeEnum.RETAIL:
                            newPrice = await findPoDetailPrice(stockId, ['retailPrice'])
                            break
                        //售价选择 采购成本价
                        case IPriceTypeEnum.PUR_COST:
                            newPrice = await findPoDetailPrice(stockId, ['latestPurPrice', 'purchasePrice'], true)
                            break
                        //售价选择 档案进价
                        case IPriceTypeEnum.BUYING_PRICE:
                            newPrice = await findPoDetailPrice(stockId, ['purchasePrice'])
                            break
                        //售价选择 石油价格
                        case IPriceTypeEnum.PETROLEUM:
                            newPrice = await findPoDetailPrice(stockId, ['petroleumPrice', 'purchasePrice'], true)
                            break
                    }
                } else {
                    newPrice = await findPoDetailPrice(stockId, ['latestPurPrice', 'purchasePrice'], true) // latestPurPrice ?? purchasePrice
                }
                return {
                    ...item,
                    num,
                    price: newPrice,
                    totalAmount: +formatNum(num * newPrice),
                    discountedAmount: +formatNum((num * newPrice * discountRate) / 100),
                    receivableAmount: +formatNum((num * newPrice * discountRate) / 100 - decreaseAmount),
                }
            })
        )
        console.log(newDetailsList)
        mode === 'add' ? setDetailAddList(newDetailsList) : setDetailDataSource(newDetailsList)
    }
    //根据销售类型，售价选择去查询采购明细对应的单价
    const findPoDetailPrice = async (stockId, priceType, priceTypeLen) => {
        // debugger
        let resultPrice = 0
        await stockStatisticService.findId(stockId).then(async (res) => {
            let retData = await res.retData
            resultPrice = priceTypeLen ? retData?.[priceType[0]] ?? retData?.[priceType[1]] : retData?.[priceType[0]]
        })
        return resultPrice
    }
    //客户名称
    const customerChange = (value, option) => {
        if (value && option) {
            let { contact, phone, address, category_name, category_code } = option
            option &&
                updateFormVal({
                    customerName: option.label,
                    customerCode: option.value,
                    contact,
                    phone,
                    address,
                    customerTypeCode: category_code,
                    customerTypeName: category_name ?? customerType[category_code],
                    licensePlate: null,
                    vehicleTypeCode: null,
                    vehicleTypeName: null,
                    vin: null,
                })
        }
        formVal.orderSourceCode === DictCodeEnmu.SOURCE_ADD
            ? getVehicleAllList({ customerName: option?.label })
            : getYCVehicleAllList({ customerName: option?.label })
    }

    //车辆牌照
    const vehicleChange = (value, option) => {
        console.log(option)
        if (value && option) {
            let {
                customer_code,
                customer_name,
                vehicle_model,
                vehicle_type_name,
                vehicle_type_code,
                vin,
                phone,
                address,
                contact,
                customer_type_code,
                customer_type_Name,
            } = option
            option &&
                updateFormVal({
                    customerName: customer_name,
                    // customerCode: customer_code,
                    vin,
                    licensePlate: option.value,
                    vehicleTypeCode: vehicle_type_code ?? '',
                    vehicleTypeName: vehicle_type_name ?? '',
                    vehicleModel: vehicle_model,
                    phone: phone ?? formVal.phone,
                    address: address ?? formVal.address,
                    contact: contact ?? formVal.contact,
                    customerTypeCode: customer_type_code ?? formVal.customerTypeCode,
                    customerTypeName: customer_type_Name ?? formVal.customerTypeName,
                })
        }
    }
    //vin码
    const vinChange = (value, option) => {
        if (value && option) {
            let {
                customer_code,
                customer_name,
                vehicle_model,
                license_plate,
                vehicle_type_name,
                vehicle_type_code,
                vin,
                phone,
                address,
                contact,
                customer_type_code,
                customer_type_Name,
            } = option
            option &&
                onChange({
                    customerName: customer_name,
                    customerCode: customer_code,
                    vin,
                    licensePlate: license_plate,
                    vehicleTypeCode: vehicle_type_code ?? '',
                    vehicleTypeName: vehicle_type_name ?? '',
                    vehicleModel: vehicle_model,
                    phone: phone ?? formVal.phone,
                    address: address ?? formVal.address,
                    contact: contact ?? formVal.contact,
                    customerTypeCode: customer_type_code ?? formVal.customerTypeCode,
                    customerTypeName: customer_type_Name ?? formVal.customerTypeName,
                })
        }
    }

    const typeForm = {
        add: detailAddForm,
        edit: detailEditForm,
        look: detailLookForm,
    }
    const goCustomer = () => {
        // if (formVal.orderSourceCode === DictCodeEnmu.SOURCE_ADD) {
        //     dispatch({
        //         type: 'changeTabs',
        //         name: '客户新增',
        //         activeMenu: 'baseCustomer/add',
        //         content: detailsPage.BaseCustomerDetails({
        //             dispatch,
        //             modeKey,
        //             mode,
        //         }),
        //     })
        // } else {
            dispatch({
                type: 'changeTabs',
                name: '客户新增',
                activeMenu: 'clientRecord/insert',
                content: detailsPage.ClientDetails({
                    dispatch,
                    modeKey,
                    mode: 'insert',
                    rowInfo: {},
                }),
            })
        // }
    }

    const goVehicle = () => {
        if (formVal.orderSourceCode === DictCodeEnmu.SOURCE_Add) {
            dispatch({
                type: 'changeTabs',
                name: '车辆新增',
                activeMenu: 'baseVehicle/add',
                content: detailsPage.BaseVehicleDetails({
                    dispatch,
                    modeKey,
                    mode,
                    customerCode: formVal.customerCode,
                    customerName: formVal.customerName,
                }),
            })
        } else {
            dispatch({
                type: 'changeTabs',
                name: '车辆新增',
                activeMenu: 'vehicleRecord/insert',
                content: detailsPage.VehicleDetails({
                    dispatch,
                    modeKey,
                    mode: 'insert',
                    rowInfo: {},
                }),
            })
        }
    }

    const onChange = (changedValues) => {
        mode === 'add' ? updateAddFormVal(changedValues) : updatEditFormVal(changedValues)
        setTopButsControl(true)
    }
    const [payTypeList, setPayTypeList] = useState([])
    useEffect(() => {
        if( shopCode=='SHOP-M-00000016'||shopCode=='SHOP-M-00000017'||shopCode=='SHOP-M-00000018'){
            setPayTypeList(getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE))
        }else{
            setPayTypeList(getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE).map((item)=>{
                return{...item,disabled:item.value=='settle-type.credit'?true:false}}))         }
    }, [])
    const baseFormItem = {
        title: '基础信息',
        style: { padding: '0 20px', marginBottom: '10px' },
        contentClass: 'pr-77',
        rightChildren,
        items: [
            {
                type: 'Input',
                fieldLabel: '销售单号',
                fieldName: 'saleCode',
                disable: true,
                placeholder: '自动生成',
            },
            {
                type: 'AsyncSelect',
                fieldLabel: (
                    <span className={mode === 'add' ? 'click-span' : ''} onClick={goCustomer}>
                        客户名称
                    </span>
                ),
                fieldName: 'customerName',
                onSelectChange: customerChange,
                selectfetchList: fetchYCCustomerList,
                defaultQuery: formVal.customerName,
                allowClear: true,
                showSearch: true,
                rules: [{ required: true, message: '请选择客户名称' }],
            },
            {
                type: 'Input',
                fieldLabel: '联系人',
                fieldName: 'contact',
                rules: [{ required: true, message: '请输入联系人' }],
            },
            {
                type: 'Input',
                fieldLabel: '联系电话',
                fieldName: 'phone',
                rules: [{ required: true, message: '请输入联系电话' }],
            },
            {
                type: 'Select',
                allowClear: true,
                showSearch: true,
                fieldLabel: '销售类型',
                fieldName: 'salesTypeCode',
                options: getSelectedDictOne(DictCodeEnmu.SALE_TYPE),
                onSelectChange: salesTypeChange,
                rules: [{ required: true, message: '请选择销售类型' }],
            },
            {
                type: 'Select',
                allowClear: true,
                showSearch: true,
                fieldLabel: '售价选择',
                fieldName: 'priceTypeCode',
                disable: salesTypeCode !== ISalesTypeEnum.NORMAL_SALES || formVal.status === DictCodeEnmu.SALE_SETTLE,
                options: getSelectedDictOne(DictCodeEnmu.PRICE_SELECTION),
                onSelectChange: priceTypeChange,
            },
            {
                type: 'Select',
                fieldLabel: '结算方',
                fieldName: 'settlementPartyCode',
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_PARTY),
                onSelectChange: settlementPartyChange,
                rules: [{ required: true, message: '请选择结算方' }],
                hidden: mode === 'look',
            },
            {
                type: 'Input',
                fieldLabel: '结算方',
                fieldName: 'settlementPartyName',
                hidden: mode !== 'look',
            },
            {
                type: 'Select',
                fieldLabel: '结算类型',
                fieldName: 'payType',
                allowClear: true,
                showSearch: true,
                // options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                options: payTypeList,
                // onSelectChange: paymentMethodChange,
                rules: [{ required: true, message: '请选择结算类型' }],
            },
            {
                type: 'Select',
                fieldLabel: '销售实体',
                fieldName: 'bentityCode',
                options: getBentityListOption(),
                onSelectChange: bentityChange,
                rules: [{ required: true, message: '请选择销售实体' }],
            },
            {
                type: 'Input',
                fieldLabel: '备注',
                fieldName: 'note',
                span: 12,
                labelCol: { span: 3 },
            },
            {
                type: 'Input',
                fieldLabel: '客户地址',
                fieldName: 'address',
                span: 5,
                labelCol: { offset: 1 },
            },
            {
                type: 'Button',
                fieldLabel: (
                    <a className="color-blue text-line" onClick={() => setMore(!more)}>
                        {more ? '更多>>' : '隐藏'}
                    </a>
                ),
                fieldName: 'none',
                span: 1,
                labelCol: { offset: 5 },
            },
            {
                type: 'FormUpload',
                fieldLabel: '附件',
                fieldName: 'fileName',
                fieldMd5: 'md5',
                defaultFileName: formVal.fileName,
                defaultFileMd5: formVal.md5,
                onSetFieldsValue: onChange,
                hidden: more,
            },
            {
                type: 'Input',
                allowClear: true,
                fieldLabel: '销售人员',
                fieldName: 'saleUser',
                hidden: more,
            },
            {
                type: 'Input',
                fieldLabel: '财务备注',
                fieldName: 'fmsNote',
                hidden: more,
            },
            {
                type: 'Select',
                allowClear: true,
                showSearch: true,
                fieldLabel: '客户类型',
                fieldName: 'customerTypeName',
                hidden: more,
                // options: getSelectedDictOne(DictCodeEnmu.CUSTOMER_TYPE),
                // onSelectChange: customerTypeChange,
                disable: true,
            },
            {
                type: 'Select',
                allowClear: true,
                showSearch: true,
                fieldLabel: '车辆类型',
                fieldName: 'vehicleTypeName',
                disable: true,
                // fieldName: "vehicleTypeCode",
                // options: getSelectedDictOne(DictCodeEnmu.VEHICLE_TYPE),
                onSelectChange: vehicleTypeChange,
                hidden: more,
            },
            {
                type: 'Select',
                fieldLabel: (
                    <span className="click-span" onClick={goVehicle}>
                        车辆牌照
                    </span>
                ),
                fieldName: 'licensePlate',
                allowClear: true,
                showSearch: true,
                options: mode === 'add' ? vehicleAddAll : vehicleEditAll,
                onSelectChange: vehicleChange,
                hidden: more,
            },
            {
                type: 'Select',
                fieldLabel: 'VIN码',
                fieldName: 'vin',
                allowClear: true,
                showSearch: true,
                onSelectChange: vinChange,
                options: mode === 'add' ? vinAddAll : vinEditAll,
                hidden: more,
            },
            {
                type: 'Input',
                fieldLabel: '所属机构',
                fieldName: 'shopName',
                disable: true,
                hidden: more,
            },
            ...(typeForm[mode].invoiceNum ? [{
                type: 'Button',
                fieldLabel:<a style={{color:'black'}}>发票号码：</a>,
                fieldName: "none",
                hidden: more,
                span: 2,
                labelCol: { offset:10},
            },
            {
                type: 'Button',
                fieldLabel:typeForm[mode].invoiceNum?<TextPriviewImage text={typeForm[mode].invoiceNum} previewList={typeForm[mode].invoiceMd5?.split(",")} imageUrl={imageUrl} />:'自动带出',
                fieldName: "invoiceNum",
                hidden: more,
                span: 4,
                labelCol: { offset: 1 },
            }] : [
                {
                    type: 'Input',
                    fieldLabel: "发票号码",
                    fieldName: "invoiceNum",
                    disable: true,
                    hidden: more,
                    placeholder: "自动带出",
                }
            ]),
            {
                type: 'Select',
                fieldLabel: '发票类型',
                fieldName: 'invoiceTypeCode',
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.INVOICE_TYPE),
                hidden: more,
                onSelectChange: invoiceTypeChange,
            },
            {
                type: 'Input',
                fieldLabel: "开票人",
                fieldName: "invoiceOperator",
                hidden: more,
                disable: true,
                placeholder: "自动带出",
            }, {
                type: 'Input',
                fieldLabel: "发票上传时间",
                fieldName: "invoiceUploadTime",
                hidden: more,
                disable: true,
                placeholder: "自动带出",
            }, 
            {
                type: 'Input',
                fieldLabel: '制单人',
                fieldName: 'createUser',
                hidden: more,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '制单时间',
                fieldName: 'createTime',
                hidden: more,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '库管员',
                fieldName: 'storekeeper',
                hidden: more,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '出库时间',
                fieldName: 'stockIoTime',
                hidden: more,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '结算员',
                fieldName: 'reckoner',
                hidden: more,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '结算时间',
                fieldName: 'settleTime',
                hidden: more,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '核销时间',
                fieldName: 'writeOffTime',
                hidden: more,
                disable: true,
            },
            {
                type: 'Datepicker',
                fieldLabel: '销售时间',
                showTime: true,
                fieldName: 'saleTime',
                hidden: more,
            },
            {
                type: 'Select',
                fieldLabel: '订单来源',
                fieldName: 'orderSourceCode',
                options: getSelectedDictOne(DictCodeEnmu.ORDER_SOURCE),
                disable: true,
                hidden: true,
            },
            {
                type: "Input",
                fieldLabel: "订单来源",
                fieldName: "orderSourceName",
                // hidden: true,
                hidden: more,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '收货人',
                fieldName: 'consignee',
                hidden: more,
                disable: mode != 'edit' || formVal.status === DictCodeEnmu.SALE_SETTLE,
            },
            {
                type: 'Input',
                fieldLabel: '收货电话',
                fieldName: 'consigneePhone',
                disable: mode != 'edit' || formVal.status === DictCodeEnmu.SALE_SETTLE,
                hidden: more,
            },
            {
                type: 'Input',
                fieldLabel: '收货地址',
                fieldName: 'consigneeAddress',
                disable: mode != 'edit' || formVal.status === DictCodeEnmu.SALE_SETTLE,
                hidden: more,
            },
            {
                type: 'Input',
                fieldLabel: '单据编号',
                fieldName: 'orderCode',
                disable: true,
                hidden: more,
            },
            {
                type: 'Select',
                fieldLabel: '单据类型',
                fieldName: 'orderTypeCode',
                options: getSelectedDictOne(DictCodeEnmu.TRADE_TYPE),
                disable: true,
                hidden: more,
            },
            {
                type: 'Input',
                fieldLabel: '付款时间',
                fieldName: 'payTime',
                hidden: more,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '整单抹零',
                fieldName: 'favorableAmount',
                rules: [{ type: 'number', transform: (value) => Math.abs(Number(value)), message: `当前不是一个有效数字` }],
                span: 5,
                labelCol: { offset: 1 },
                hidden: true,
            },
            //带出字段
            {
                type: 'Input',
                fieldLabel: '销售实体名称',
                fieldName: 'bentityName',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '客户名称',
                fieldName: 'customerCode',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '车辆类型Name',
                fieldName: 'vehicleTypeName',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '维修类型Name',
                fieldName: 'maintenanceTypeName',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '结算方Name',
                fieldName: 'settlementPartyName',
                hidden: true,
            },
            // {
            //     type: "Input",
            //     fieldLabel: "结算方式Name",
            //     fieldName: "paymentMethodName",
            //     hidden: true,
            // },
            {
                type: 'Input',
                fieldLabel: '客户类型Name',
                fieldName: 'customerTypeName',
                hidden: true,
            },
            {
                type: Input,
                fieldLabel: '发票类型Name',
                fieldName: 'invoiceTypeName',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '车辆里程',
                fieldName: 'mileage',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '售价选择Name',
                fieldName: 'priceTypeName',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '销售类型',
                fieldName: 'salesTypeName',
                hidden: true,
            },
            // {
            //     type: 'Input',
            //     fieldLabel: '订单来源name',
            //     fieldName: 'orderSourceName',
            //     hidden: true,
            // },
            {
                type: 'Input',
                fieldLabel: '支付方式name',
                fieldName: 'paymentMethodName',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '单据类型Name',
                fieldName: 'orderTypeName',
                hidden: true,
            },
        ],
    }


    const poFromConfig = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: 'right',
        disabled:
            mode === DetailType.look || (formVal.status === DictCodeEnmu.SALE_SETTLE && mode === 'edit') || formVal.orderSourceCode != DictCodeEnmu.SOURCE_ADD,
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem],
    }

    return <YhFormBox {...poFromConfig} />
}
