import React from 'react'
import { Button } from 'antd'
import { DataTable } from './DataTable'
import { YhBox } from '@/erp_subpackage/components/YhBox'
import { useAllocModel } from "../inventoryStatisticsModel";

export const DataBox = () => {
    const { exportOut } = useAllocModel()

    const action = (
        <>
            <Button
                className="btn-item"
                type="link"
                shape="round"
                onClick={() => {
                    exportOut()
                }}
            >
                导出
            </Button>
        </>
    )

    return (
        <YhBox title={action}>
            <DataTable />
        </YhBox>
    )
}
