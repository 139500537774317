import React, { useState, useEffect, useRef, useContext } from "react"
import { Form, InputNumber, Input } from "antd"
const EditableContext = React.createContext()
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm()
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    )
}

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef()
    const form = useContext(EditableContext)
    useEffect(() => {
        if (editing) {
            inputRef.current.focus()
        }
    }, [editing])

    const toggleEdit = () => {
        setEditing(!editing)
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        })
    }

    const save = async (e, status) => {
        try {
            const values = await form.validateFields()
            toggleEdit()
            handleSave({ ...record, ...values }, status)
        } catch (errInfo) {
            console.log("数据保存失败", errInfo)
        }
    }

    const inputTypes = (str, record) => {
        if (str === "materialNumber") {
            return <InputNumber ref={inputRef} step={1} onBlur={save} />
        }else if (str === "remark") {
            return <Input ref={inputRef} step={1} onBlur={save} />
        }
    }
    const inputRules = (str) => {
        if (str === "materialNumber") {
            return [{ required: true, message: "请输入数量" }]
        }
    }
    let childNode = children
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={inputRules(dataIndex)}
            >
                {inputTypes(dataIndex, record)}
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                {children}
            </div>
        )
    }

    return <td {...restProps}>{childNode}</td>
}

export { EditableRow, EditableCell }
