import React from 'react'
import { useState } from 'react';
import { Image, Tooltip } from "antd";

// interface TextPriviewImagePropsType {
//   text: string;
//   previewList?: string[];
//   imageUrl: (url: string) => string
// }

function TextPriviewImage(props) {
  const { text, previewList, imageUrl } = props;
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Tooltip placement="topLeft" title={text} arrowPointAtCenter>
        <p className="ellipsis click-span" onClick={() => setVisible(true)}>{text}</p>
      </Tooltip>
      {previewList ? (
        <div style={{ display: 'none' }}>
          <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => { setVisible(vis) } }}>
            {previewList.map(e =>
              <Image src={imageUrl} />
            )}
          </Image.PreviewGroup>
        </div>
      ) : ''}
    </>
  );
}

export default TextPriviewImage;