import React, { useState, useEffect, useImperativeHandle } from "react"
import { Table, message } from "antd"
import YhIcon from "@/components/pubIcon"
import { EditableRow, EditableCell } from "./otherProjectTable"

//其他项目
export default ({ otherProjectRef, returnOtherItemList, setIsChange, returnOrderInfo }) => {
    const [data, setData] = useState([]) //表格数据
    const [totalPrice, setTotalPrice] = useState(0) //总价
    useEffect(() => {
        setData(returnOtherItemList)
    }, [returnOtherItemList])

    //是否可以修改
    const [isUpdate, setIsUpdate] = useState(false)
    useEffect(() => {
        if (returnOrderInfo.returnStatus === "billed") {
            setIsUpdate(true)
        } else {
            setIsUpdate(false)
        }
    }, [returnOrderInfo])

    //计算总价
    useEffect(() => {
        let num = 0
        data.forEach((item) => {
            num += Number(item.actualAmount)
        })
        setTotalPrice(num.toFixed(2))
    }, [data])

    //暴露给父组件的方法
    useImperativeHandle(otherProjectRef, () => ({
        //父组件保存的时候 获取所有编辑完后的数据
        upData: () => {
            return data
        },
    }))

    /**
     * 以下是页面展示
     */
    const [MaterialKeys, setMaterialKeys] = useState([]) // 批量删除的key
    const [deleteAll, setDeleteAll] = useState(false) //删除多选的控制按钮
    //批量删除多选的成员id
    const MaterialSelection = {
        columnWidth: "30px",
        onChange: (selectedRowKeys, selectedRows) => {
            setMaterialKeys(selectedRowKeys)
        },
    }
    //批量删除
    const deleteTemaAll = () => {
        if (MaterialKeys.length === 0) {
            message.warning("请选择你要删除的项目")
            return
        }
        let arr = data.filter((item) => MaterialKeys.indexOf(item.id) < 0)
        setDeleteAll(false)
        setData(arr)
        setMaterialKeys([])
        message.success("批量删除成功")
        setIsChange(true)
    }
    //维修物料删除一条数据
    const deleteMaterialOne = (row) => {
        //删除已选的
        let newData = [...data]
        let delIndex
        if (row.id) {
            delIndex = newData.findIndex((item) => item.id === row.id)
        } else {
            delIndex = newData.findIndex((item) => item.time === row.time)
        }
        newData.splice(delIndex, 1)
        setData(newData)
        message.success("删除成功")
        setIsChange(true)
    }

    //table配置
    const columns = [
        {
            title: "项目编码",
            align: "center",
            width: 120,
            fixed: "left",
            dataIndex: "itemCode",
            // editable: true,
        },
        {
            title: "项目名称",
            align: "center",
            width: 200,
            fixed: "left",
            dataIndex: "itemName",
            // editable: true,
        },
        {
            title: "项目属性",
            align: "center",
            width: "120px",
            dataIndex: "itemAttr",
            // editable: true,
        },
        {
            title: "项目性质",
            align: "center",
            width: 200,
            dataIndex: "serviceNature",
            // editable: true,
        },
        {
            title: "单价",
            align: "center",
            width: "120px",
            dataIndex: "unitPrice",
            // editable: true,
        },
        {
            title: "数量",
            align: "center",
            width: "120px",
            dataIndex: "itemNumber",
            // editable: true,
        },
        {
            title: "总计",
            align: "center",
            width: "120px",
            dataIndex: "itemAmount",
        },
        {
            title: "总金额",
            align: "center",
            width: 160,
            dataIndex: "actualAmount",
        },
        {
            title: "备注",
            align: "center",
            width: 160,
            dataIndex: "remark",
            // editable: true,
        },
        {
            title: "操作",
            align: "center",
            width: "80px",
            dataIndex: "operation",
            render: (_, record) => {
                return <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => deleteMaterialOne(record)} />
            },
        },
    ]

    //单元格编辑保存
    const materialHandleSave = (row) => {
        //每次编辑计算总计
        row.itemAmount = (row.unitPrice * row.itemNumber).toFixed(2)
        if (row.itemDiscount) {
            row.actualAmount = ((row.unitPrice * row.itemNumber * row.itemDiscount) / 10 - row.preferentialAmount).toFixed(2)
        } else {
            row.actualAmount = (row.unitPrice * row.itemNumber - row.preferentialAmount).toFixed(2)
        }
        const newData = [...data]
        let index
        if (row.id) {
            index = newData.findIndex((item) => row.id === item.id)
        } else {
            index = newData.findIndex((item) => row.time === item.time)
        }
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }
    const MaterialColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: materialHandleSave,
            }),
        }
    })

    return (
        <div className="list-container" style={{ marginBottom: "100px" }}>
            <div className="list-header">
                <div className="list-name">
                    <span>其他项目</span>
                    <span className="total-price">
                        总金额&nbsp;¥&nbsp;<span>{totalPrice}</span>
                        &nbsp;元&nbsp;({data.length}项){" "}
                    </span>
                    {isUpdate && (
                        <div className="list-name-but">
                            {!deleteAll ? (
                                <span className="list-delete" onClick={() => setDeleteAll(true)}>
                                    批量删除
                                </span>
                            ) : (
                                <>
                                    <span className="list-delete" onClick={deleteTemaAll}>
                                        确认删除
                                    </span>
                                    <span className="list-delete" onClick={() => setDeleteAll(false)}>
                                        取消
                                    </span>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Table
                size="small"
                rowKey={(record) => record.id || record.time}
                components={components}
                columns={MaterialColumns}
                dataSource={data}
                pagination={false}
                scroll={{ x: 2000, y: 260 }}
                rowSelection={deleteAll ? MaterialSelection : false}
            />
        </div>
    )
}
