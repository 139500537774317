import React from "react";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import { usePurchaseAdvancedModel } from "../purchaseAdvancedModel";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import {advancedStatusDs } from "@/erp_subpackage/utils/ErpEnum"

export function PageHeader() {
    const { getSelectedDictOne } = useDictModel();
    const {
        searchParam, organTypeCode,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = usePurchaseAdvancedModel();
    const { orgDs } = useGlobalModel(({ orgDs }) => [orgDs]);

    const searchFields = [
        {
            type: "Input",
            placeholder: "采购单号",
            fieldName: "advancedCode",
        },
        ...(organTypeCode === 3 ? [{
            type: "Select",
            placeholder: "所属机构",
            fieldName: "shopId",
            options: orgDs
        }] : []),
        {
            type: "Select",
            placeholder: "采购类型",
            fieldName: "purchaseTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.PURCHASE_TEPE, ["vrm", "ci","main"]),
        },
        {
            type: "Select",
            placeholder: "单据状态",
            fieldName: "status",
            mode: "multiple",
            options: advancedStatusDs,
        },
        {
            type: "RangePicker",
            fieldName: "",
            placeholder: ["制单日期起始", "制单日期结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        }
    ]

    const searchMoreFields = [
        // {
        //     type: "Input",
        //     placeholder: "调入单号",
        //     fieldName: "allocationCode"
        // },
        // {
        //     type: "Input",
        //     placeholder: "调拨单号",
        //     fieldName: "transferOrder"
        // },
        // {
        //     type: "Select",
        //     placeholder: "商品类型",
        //     fieldName: "materialTypeCode",
        //     options: getSelectedDictOne(DictCodeEnmu.MATERIAL_TYPE),
        // },
        {
            type: "Input",
            placeholder: "供应商",
            fieldName: "supplierName",
        },
        // {
        //     type: "Select",
        //     placeholder: "结算类型",
        //     fieldName: "payType",
        //     options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
        // },
        {
            type: "Input",
            placeholder: "制单人",
            fieldName: "createUser"
        },
        // {
        //     type: "Select",
        //     placeholder: "结算方式",
        //     fieldName: "payMethodCode",
        //     options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD),
        // },
        // {
        //     type: "Select",
        //     placeholder: "支付状态",
        //     fieldName: "payStatus",
        //     options: getSelectedDictOne(DictCodeEnmu.PAY_STATUS)
        // },
        // {
        //     type: "RangePicker",
        //     fieldName: "",
        //     placeholder: ["结算开始", "结算结束"],
        //     rangePickerFieldName: ["settlementStartTime", "settlementEndTime"],
        //     format: "YYYY-MM-DD",
        //     span: 16,
        //     xxl: 8,
        // },

    ]

    return (
        <YhPageHeader
            title="采购平台"
            size="small"
            triggerSearch
            searchValues={searchParam}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData({ pageNum: 1 }, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}