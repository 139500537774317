import React from "react"
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { useProductModel } from "../productModel"
export function PageHeader() {
   const { searchParam, loadData, updateSearchParam, resetSearchParam } = useProductModel()
   const { getSelectedDictOne } = useDictModel()
   const searchFields = [
            {
                type: "Input",
                placeholder: "商品名称",
                fieldName: "name",
            },
            {
                type: "Input",
                placeholder: "商品编号",
                fieldName: "code",
            },
            {
                type: "Select",
                placeholder: "品牌",
                fieldName: "brandCode",
                options: getSelectedDictOne(DictCodeEnmu.MATERIAL_BRAND),
            },
            {
                type: "Select",
                placeholder: "上架状态",
                fieldName: "upStatus",
                options: getSelectedDictOne(DictCodeEnmu.DISPLAY_STATUS),
            },
        ]

    return (
        <YhPageHeader
            title="商品管理"
            size="small"
            justify="end"
            triggerSearch={true}
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData({ pageNum: 1 }, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    )
}
