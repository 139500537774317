import React, { useEffect, useState, useRef } from "react"
import { Form, Input, Col, Row, Select, message, Modal, Spin } from "antd"
import { returnOrderThreeGuaranteesMaterialOne, returnOrderThreeGuaranteesMaterialEdit } from "@/service/ordersService"
import { getPcodeDict } from "@/utils/dataStorage"
import Material from "./material"
const { Option } = Select
export default (props) => {
    const { rowInfo, visible, setVisible, returnOrderInfo } = props
    const [sanbaoDetail, setSanbaoDetail] = useState({ returnMaintainMaterialList: [] })
    const [form] = Form.useForm()

    const materialRef = useRef() //维修物料  子组件

    const [entityData, setEntityData] = useState({
        //所属实体信息
        data: [],
        fetching: false,
    })

    useEffect(() => {
        if (visible) {
            queryMotorcade()
        }
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    //查看编辑回显
    const queryMotorcade = () => {
        returnOrderThreeGuaranteesMaterialOne({ returnOrderId: returnOrderInfo.id, threeGuaranteesId: rowInfo.id }).then((res) => {
            let results = res.retData
            console.log(res)
            setSanbaoDetail(results)
            const { ciBusinessEntityId, ciBusinessEntityName } = results
            setEntityData({
                data: [{ text: ciBusinessEntityName, value: ciBusinessEntityId }],
                fetching: false,
            })
            form.setFieldsValue({
                ...results,
            })
        })
    }

    //表单提交
    const onUserSubmit = async () => {
        let returnOrderMaintainMaterialList = materialRef.current
            .upData()
            .map((item) => ({
                id: item.id,
                unitPrice: item.unitPrice,
                materialNumber: item.materialNumber,
                materialDiscount: item.materialDiscount,
                preferentialAmount: item.preferentialAmount,
            }))
        returnOrderThreeGuaranteesMaterialEdit({ returnOrderId: returnOrderInfo.id, orderThreeGuaranteesId: rowInfo.id, returnOrderMaintainMaterialList }).then(
            (res) => {
                setVisible(false)
            }
        )
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    const handleCancel = () => {
        setVisible("")
    }

    return (
        <Modal forceRender visible={visible} title="三包信息" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={1200}>
            <div className="form-details-warp">
                <Form form={form} {...formItemLayout}>
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                label="三包品牌"
                                name="threeGuaranteesBrand"
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择三包品牌",
                                    },
                                ]}
                            >
                                <Select placeholder="三包品牌" style={{ width: "100%" }} disabled>
                                    {getPcodeDict("106").children.map((item) => (
                                        <Option value={item.dictCode} key={item.id}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="所属实体" name="ciBusinessEntityId">
                                <Select
                                    allowClear
                                    placeholder="请选择所属实体"
                                    style={{ width: "100%" }}
                                    notFoundContent={entityData.fetching ? <Spin size="small" /> : null}
                                    optionFilterProp="children"
                                    filterOption={false}
                                    disabled
                                >
                                    {entityData.data.map((item) => (
                                        <Select.Option value={item.value} key={item.value}>
                                            {item.text}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="索赔单号"
                                name="claimNumber"
                                getValueFromEvent={(event) => {
                                    return event.target.value.replace(/[\u4E00-\u9FA5]/g, "")
                                }}
                                rules={
                                    [
                                        // {
                                        //     required: true,
                                        //     message: "请输入索赔单号",
                                        // },
                                    ]
                                }
                            >
                                <Input placeholder="可自动生成索赔单号" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="结算单号" name="statementNumber">
                                <Input placeholder="结算单号" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="材料费"
                                name="materialAmount"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: "请输入数字且可保留两位小数",
                                    },
                                ]}
                            >
                                <Input placeholder="材料费" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="工时费"
                                name="hourlyWage"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: "请输入数字且可保留两位小数",
                                    },
                                ]}
                            >
                                <Input placeholder="工时费" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="外出费"
                                name="travelExpenses"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: "请输入数字且可保留两位小数",
                                    },
                                ]}
                            >
                                <Input placeholder="外出费" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="餐补费"
                                name="mealSupplement"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: "请输入数字且可保留两位小数",
                                    },
                                ]}
                            >
                                <Input placeholder="餐补费" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="其他费用"
                                name="otherAmount"
                                rules={[
                                    {
                                        pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                        message: "请输入数字且可保留两位小数",
                                    },
                                ]}
                            >
                                <Input placeholder="其他费用" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="出库金额" name="outboundAmount">
                                <Input placeholder="出库金额" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="索赔总额" name="totalClaim">
                                <Input placeholder="索赔总额" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="故障处理" name="faultHandle">
                                <Input placeholder="故障处理" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="工时费汇总" name="totalHourlyWage">
                                <Input placeholder="三包工时费汇总" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="维修时间" name="repairTime">
                                <Input placeholder="维修时间" disabled />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="维修人员" name="maintenanceStaff">
                                <Input placeholder="维修人员" disabled />
                            </Form.Item>
                        </Col>

                        <Form.Item label="" name="ciBusinessEntityName" hidden>
                            <Input placeholder="" disabled />
                        </Form.Item>
                    </Row>
                </Form>

                <Material returnMaintainMaterialList={sanbaoDetail.returnMaintainMaterialList} materialRef={materialRef} returnOrderInfo={returnOrderInfo} />
            </div>
        </Modal>
    )
}
