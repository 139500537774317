import {
    httpRequest
} from "@/erp_subpackage/utils/HttpRequest";
class SaleRoService {
    async page(searchVo) {
        const apiUrl = "/ci-storage/sreturn/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async total(searchVo) {
        const apiUrl = "/ci-storage/sreturn/total";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async one(id) {
        const apiUrl = `/ci-storage/sreturn/findById/${id}`;
        return await httpRequest.get(apiUrl);
    }

    async insert(insertVo) {
        const apiUrl = "/ci-storage/sreturn/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async update(updateVo) {
        const apiUrl = "/ci-storage/sreturn/update";
        return await httpRequest.post(apiUrl, updateVo);
    }
    //明细删除
    async materialDelete(params) {
        const apiUrl = "/ci-storage/sreturn/materialDelete";
        return await httpRequest.post(apiUrl, params);
    }
    //导出
    async export (params) {
        const apiUrl = "/ci-analyze/sreturn/export";
        return await httpRequest.post(apiUrl, params);
    }
    //转销售单
    async transfer(planCode) {
        const apiUrl = `/ci-storage/sreturn/transfer/${planCode}`;
        return await httpRequest.get(apiUrl);
    }
    // //详情导入
    // async invalidate(){
    //     const apiUrl = "/ci-storage/sreturn/detail/downloadTpl";
    //     return await httpRequest.getFile<boolean>(apiUrl);
    // }
    //下载模板
    async downloadTpl() {
        const apiUrl = "/ci-storage/sreturn/detail/downloadTpl";
        return await httpRequest.getFile(apiUrl);
    }
    //作废
    async invalidate(params) {
        const apiUrl = "/ci-storage/sreturn/invalidate";
        return await httpRequest.post(apiUrl, params);
    }
    //库房确认
    async warehousing(params) {
        const apiUrl = "/ci-storage/sreturn/warehousing";
        return await httpRequest.post(apiUrl, params);
    }
    //销售退货结算
    async settle(params) {
        const apiUrl = "/ci-storage/sreturn/settlePro";
        return await httpRequest.post(apiUrl, params);
    }
    //加盟商销售退货结算
    async settleJoin(params) {
        const apiUrl = "/ci-storage/sreturn/settleJoin";
        return await httpRequest.post(apiUrl, params);
    }
    //销售退货查询详情
    async saleDetails(params) {
        const apiUrl = "/ci-storage/sale/details/findPage";
        return await httpRequest.post(apiUrl, params);
    }
    //详情导出
    async detailsExport(params) {
        const apiUrl = "/ci-storage/sreturn/detailsExport";
        return await httpRequest.postFile(apiUrl, params);
    }
    //打印次数
    async printNum(id) {
        const apiUrl = `/ci-storage/sreturn/print/${id}`;
        return await httpRequest.get(apiUrl);
    }

    //查询特定得收款账户
    accountFind(params) {
        const apiUrl = "/fms/account/find";
        return httpRequest.post(apiUrl, params);
    }

    // 验收(1 驳回 0 通过)
    examine(params) {
        const apiUrl = '/ci-storage/sreturn/examine'
        return httpRequest.post(apiUrl, params)
    }

}

export const saleRoService = new SaleRoService();