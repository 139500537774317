import React, { useEffect, useState } from "react"
import { Modal, Button, Input, message, Radio } from "antd"
import { orderPrintInfo, printNum, getOrderOne } from "@/service/ordersService"
import "./orderPrint.css"
import { getUserInfo, getPcodeDict } from "@/utils/dataStorage"
import { numberParseChina } from "@/utils/util"
import moment from "moment"
import PrintOrderSet from "./printOrderSet"
import {SettleScaniaPrint} from "../../erp_subpackage/components/SettleScaniaPrint"
const SettleScaniaPrints = (props) => {
    const { visible, onClose } = props
    // const [protList, setProtList] = useState([])
    // const [protListSB, setProtListSB] = useState([])
    // const [protListSF, setProtListSF] = useState([])
    // const [itemListSB, setItemListSB] = useState([])
    // const [itemListSF, setItemListSF] = useState([])
    const [otherList, setOtherList] = useState([])
    const [baseInfo, setBaseInfo] = useState({})
    const [dictObj, setDictObj] = useState({ serviceType: {}, orderSource: {}, orderTypes: {} })
    // const [setVisible, setSetVisible] = useState(false)
    // const [orderType, setOrderType] = useState("")
    const [serviceNature, setServiceNature] = useState("")
    const [titles, setTieles] = useState("")
    const [price, setPrice] = useState({})
    // const [orderCode, setOrderCode] = useState('')
    // const [payType, setPayType] = useState("");//结算方
    const [formVal, setFormVal] = useState({});
    const [maintenanceList, setMaintenanceList] = useState([]);
    const [itemList, setItemList] = useState([]);
    useEffect(() => {
        initDict()
    }, [])
    useEffect(() => {
        if(dictObj.threeGuaranteesUnit){
        loadData()
    }
    }, [dictObj])
    const loadData = () => {
        let data = {
            pageSize: 1000,
            searchItem: { orderId: visible.orderId },
        }
        getOrderOne(visible.orderId).then((res) => {
            let results = res.retData;
        });

        orderPrintInfo({ orderId: visible.orderId,printType:'settlement' }).then((res) => {
            console.log(res)
            const {
                orderInfo,
                orderOtherItemBills,
                orderMaintainMaterialThreeGuaranteesBills,
                orderMaintainMaterialBills,
                orderItemBills,
                orderItemThreeGuaranteesBills,
                orderItemActualAmount,
                orderItemThreeGuaranteesAmount,
                orderMaintainMaterialAmount,
                orderMaintainMaterialThreeGuaranteesAmount,
                otherItemActualAmount,
                orderTotalAmount,
                orderActualAmount,
                orderDispatches
            } = res.retData
            const list = orderDispatches.map((item) => {
                return item.userName
            })
            setFormVal({
                ciBusinessEntityName:orderInfo?.ciBusinessEntityName,//服务厂
                maintenanceCode:orderInfo?.orderCode,//工单号
                settlementPartyName:orderInfo?.settlementInfo?.settlementPartyName,//结算方
                customerName:orderInfo?.customerName,//托修单位
                mileage:orderInfo?.customerVehicleMileage,//行驶里程
                operatingHours:orderInfo?.operatingHours,//运行小时
                contact:orderInfo?.senderRepairUserName,//联系人
                phone:orderInfo?.senderRepairUserPhone,//联系电话
                payType:orderInfo?.settlementInfo?.payType,//结算方式
                vehicleTypeName:orderInfo?.customerVehicleType,//车辆类型
                licensePlate:orderInfo?.customerVehicleCode,//车牌号
                customerAddress:orderInfo?.customerAddress,//客户地址
                technicianNames:list.join(','),//维修技师
                faultDesc:orderInfo?.faultDesc,//故障描述
                faultResult:orderInfo?.processResult,//处理结果
                remark:orderInfo?.remark,//备注
                createUserName:orderInfo?.createUserName,//制单
                orderStatus:orderInfo?.orderStatus,//状态
                vin:orderInfo?.customerVehicleVin,//底盘号
                serviceTypeName:dictObj.serviceType[orderInfo?.serviceType],//故障类型
                enterTime:orderInfo?.entryFactoryTime?moment(orderInfo?.entryFactoryTime).format("YYYY-MM-DD HH:mm"):'' ,//进厂时间
                deliveryTime:orderInfo?.outFactoryTime?moment(orderInfo?.outFactoryTime).format("YYYY-MM-DD HH:mm"):'' ,//出厂时间

                });
            // let { orderCode: orderId } = orderInfo
            // setOrderCode(orderId)
            setBaseInfo(orderInfo)
            const { ciBusinessEntityName, orderType: orderTypes } = orderInfo
            // setOrderType(orderTypes)
            // if (orderTypes && orderTypes === "014003") {
            //     setSetVisible(true)
            // } else 
            if (orderTypes && orderTypes === "014001") {
                //收费
                setServiceNature("016002")
            } else if (orderTypes && orderTypes === "014002") {
                // 三包
                setServiceNature("016001")
            }else if (orderTypes && orderTypes === "014006") {
                // 云豪三包
                setServiceNature("016004")
            }
            setTieles(ciBusinessEntityName)
            // 016001 三包
            // 016002 收费
            setItemList(orderTypes === "014001" || orderTypes === "014006"?orderItemBills.map((list,index)=>{return{
                ...list,
                No: index + 1,
                itemDiscount:list.itemDiscount?`${list.itemDiscount}折`:''
            }}):orderItemThreeGuaranteesBills.map((list,index)=>{return{
                ...list,
                No: index + 1
            }}))
            let allMaintainSB=[]
            let newMaintainListSB=[]
            orderMaintainMaterialThreeGuaranteesBills.map((item)=>{
                if(allMaintainSB.includes(item.materialCode)){
                    let data=newMaintainListSB.find((list)=>list.materialCode==item.materialCode)
                    let dataIndex=newMaintainListSB.findIndex((list)=>list.materialCode==item.materialCode)
                    if(data.unitPrice==item.unitPrice){
                        newMaintainListSB[dataIndex]={...newMaintainListSB[dataIndex],materialNumber:(+newMaintainListSB[dataIndex].materialNumber)+(+item.materialNumber)}
                    }
                    else{
                        newMaintainListSB.push(item) 
                    }
                }else{
                    allMaintainSB.push(item.materialCode)
                    newMaintainListSB.push(item) 
                }
            })
            let allMaintainSF=[]
            let newMaintainListSF=[]
            orderMaintainMaterialBills.map((item)=>{
                if(allMaintainSF.includes(item.materialCode)){
                    let data=newMaintainListSF.find((list)=>list.materialCode==item.materialCode)
                    let dataIndex=newMaintainListSF.findIndex((list)=>list.materialCode==item.materialCode)
                    if(data.unitPrice==item.unitPrice){
                        newMaintainListSF[dataIndex]={...newMaintainListSF[dataIndex],materialNumber:(+newMaintainListSF[dataIndex].materialNumber)+(+item.materialNumber),receivableAmount:(+newMaintainListSF[dataIndex].receivableAmount)+(+item.receivableAmount)}
                    }
                    else{
                        newMaintainListSF.push(item) 
                    }

                   
                }else{
                    allMaintainSF.push(item.materialCode)
                    newMaintainListSF.push(item) 
                }
            })
            setMaintenanceList(orderTypes === "014001" || orderTypes === "014006"?newMaintainListSF.map((list,index)=>{return{
                ...list,
                materialDiscount:list.materialDiscount?`${list.materialDiscount}折`:'',
                receivableAmount:Number(list.receivableAmount).toFixed(2),
                No: index + 1
            }}):newMaintainListSB.map((list,index)=>{return{
                ...list,
                No: index + 1,
                threeGuaranteesUnit:dictObj.threeGuaranteesUnit[list.threeGuaranteesUnit]
            }}))
            // setProtListSB(orderMaintainMaterialThreeGuaranteesBills)
            // setProtListSF(orderMaintainMaterialBills)
            // setItemListSB(orderItemThreeGuaranteesBills)
            // setItemListSF(orderItemBills)
            setOtherList(orderOtherItemBills)
            setPrice(orderTypes === "014001" || orderTypes === "014006"?{
                workingHoursAmount:orderItemActualAmount,//工时费
                // orderItemThreeGuaranteesAmount,//三包工时费
                materialScienceAmount:orderInfo.orderMaintainMaterialAmount,//材料费
                // orderMaintainMaterialThreeGuaranteesAmount,//三包材料费
                otherItemActualAmount:orderInfo.otherItemActualAmount,//其他费用
                orderTotalAmount:orderInfo.orderTotalAmount,//合计金额,含税金额
                totalAmount:orderInfo.orderActualAmount,//实际金额
                orderPreferentialAmount:res.retData.orderDiscountSummaryAmount,//优惠金额
                orderSummaryAmount:res.retData.orderSummaryAmount,
                receivableTotalAmountCN:numberParseChina(orderInfo.orderActualAmount)//大写人民币
            }:{
                workingHoursAmount:orderItemThreeGuaranteesAmount,//工时费
                // orderItemThreeGuaranteesAmount,//三包工时费
                materialScienceAmount:orderMaintainMaterialThreeGuaranteesAmount,//材料费
                // orderMaintainMaterialThreeGuaranteesAmount,//三包材料费
                otherItemActualAmount:otherItemActualAmount,//其他费用
                orderTotalAmount:switchNum(orderItemThreeGuaranteesAmount + orderMaintainMaterialThreeGuaranteesAmount),//合计金额,含税金额
                totalAmount:switchNum(orderItemThreeGuaranteesAmount + orderMaintainMaterialThreeGuaranteesAmount),//实际金额
                orderPreferentialAmount:0,//优惠金额
                orderSummaryAmount:switchNum(orderItemThreeGuaranteesAmount + orderMaintainMaterialThreeGuaranteesAmount),//实际金额
                receivableTotalAmountCN:numberParseChina(orderItemThreeGuaranteesAmount + orderMaintainMaterialThreeGuaranteesAmount)//大写人民币
            })
        })
    }

    //初始下订单用到的字典
    const initDict = () => {
        let obj = {
            serviceType: {}, //服务类型
            orderSource: {}, //支付方式
            orderTypes: {},
            threeGuaranteesUnit: {},
        }
        getPcodeDict("011").children.forEach((item) => {
            obj.serviceType[item.dictCode] = item.title
        })
        getPcodeDict("018").children.forEach((item) => {
            obj.orderSource[item.dictCode] = item.title
        })
        getPcodeDict("014").children.forEach((item) => {
            obj.orderTypes[item.dictCode] = item.title
        })
        getPcodeDict("106").children.forEach((item) => {
            obj.threeGuaranteesUnit[item.dictCode] = item.title
        })

        setDictObj(obj)
    }
    const itemClumns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '项目编号',
            dataIndex: 'itemCode',
        },
        {
            title: '项目名称',
            dataIndex: 'itemName',
        },
        {
            title: '工时',
            dataIndex: 'itemWorkHours',
        },
        {
            title: '单价',
            dataIndex: 'workHoursPrice',
        },
        {
            title: '折扣',
            dataIndex: 'itemDiscount',
        },
        {
            title: '工时费',
            dataIndex: 'receivableAmount',
        },
        {
            title: '备注',
            dataIndex: 'remark',
            colSpan:2
        },
    ]
    const columns =serviceNature=== "016002"  || serviceNature === "016004" ? [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '商品编号',
            dataIndex: 'materialCode',
        },
        {
            title: '商品名称',
            dataIndex: 'materialName',
        },
        {
            title: '数量',
            dataIndex: 'materialNumber',
        },
        {
            title: '单价',
            dataIndex: 'unitPrice',
        },
        // {
        //     title: '总计',
        //     dataIndex: 'materialAmount',
        // },
        {
            title: '折扣',
            dataIndex: 'materialDiscount',
            // render: (text) => formVal.materialDiscount?`${formVal.materialDiscount}折`:''
        },
        {
            title: '金额',
            dataIndex: 'receivableAmount',
        },
        {
            title: '单位',
            dataIndex: 'unitName',
        },
        // {
        //     title: '品牌',
        //     dataIndex: 'brandName',
        // },
        {
            title: '备注',
            dataIndex: 'remark',
        },
    ]:[ {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '商品编号',
            dataIndex: 'materialCode',
        },
        {
            title: '商品名称',
            dataIndex: 'materialName',
        },
        {
            title: '单位',
            dataIndex: 'unitName',
        },
        {
            title: '数量',
            dataIndex: 'materialNumber',
        },
        {
            title: '单价',
            dataIndex: 'unitPrice',
        },
        {
            title: '三包单位',
            dataIndex: 'threeGuaranteesUnit',
        },
        {
            title: '索赔单号',
            dataIndex: 'claimNumber',
        },]
    const otherClumns = [
            {
                title: '序号',
                dataIndex: 'No',
            },
            {
                title: '项目编码',
                dataIndex: 'itemCode',
            },
            {
                title: '项目名称',
                dataIndex: 'itemName',
            },
            {
                title: '数量',
                dataIndex: 'itemNumber',
            },
            {
                title: '单价',
                dataIndex: 'unitPrice',
            },
            {
                title: '金额',
                dataIndex: 'itemAmount',
            },
            {
                title: '备注',
                dataIndex: 'remark',
                colSpan:2
            },
        ]
    //转换number，保留两个0
    const switchNum = (num, n = 2) => {
        return Number(num).toFixed(n)
    }
    return (
        <SettleScaniaPrint
        title={titles}
        visible={visible}
        onClose={onClose}
        columns={columns}
        itemClumns={itemClumns}
        otherClumns={otherClumns}
        itemDataSource={itemList}
        dataSource={maintenanceList.filter(item => item.num !== 0)}
        otherDataSource={otherList}
        formVal={formVal}
        isTechnician={true}
        baseInfo={baseInfo}
        price={price}
        // printNum={visible.printNum}
    />
    )
}

export default SettleScaniaPrints
