import React from "react";
import { TopButs } from "./TopButs";
import { FormBox } from "./FormBox";
import { TableBox } from "./TableBox";
import { AllotDetailModel } from "./AllotDetailModel";

export const InternalDetails = (props) => {
    return (
        <>
            {props && <div className="yh-detail-box">
                <TopButs {...props} />
                <FormBox {...props} />
                <TableBox {...props} />
                <AllotDetailModel {...props} />
            </div>
            }
        </>
    )
}