import React, { useState, useEffect } from "react"
import { Form, Col, Row, Input, message, Button, Select, Switch, Upload, Modal } from "antd"
import PubDrawer from "@/components/Drawer"
import { addTeamMember, getTeamMember, EditTeamMember, addTeam, editTeam, getTeamOne } from "@/service/teamService"
import { getPcodeDict } from "@/utils/dataStorage"
import { getToken } from "@/utils/dataStorage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"
const { Option } = Select

const TeamDetails = (props) => {
    //模式  父级信息  显示隐藏  隐藏回调 成员列表加载
    const { mode, rowInfo, teamVisible, onShow, onMemberLoadData, onTeamLoadData, memberData } = props
    const [form] = Form.useForm()
    const [isTeam, setIsTeam] = useState(false)

    const [memberOne, setMemberOne] = useState({}) //成员编辑时获取的一条数据。
    const [teamOne, setTeamOne] = useState({}) //团队编辑时获取的一条数据。
    const [headFileList, setheadFileList] = useState([]) //图片list
    const [previewImage, setpreviewImage] = useState({
        //预览展示头像MD5
        visible: false,
        md5: "",
    })
    useEffect(() => {
        if (teamVisible) {
            isMode()
        } else {
            setpreviewImage({ visible: false, md5: "" })
        }
    }, [teamVisible])

    // 图片上传
    const handleImgChange = async (info) => {
        setheadFileList([])
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setpreviewImage({ visible: false, md5: md5 })
            setheadFileList(info.fileList)
        }
    }
    //判断是团队，还是成员的新增或者编辑
    const isMode = () => {
        //成员新增模式 ,回显团队名称
        if (mode === "memberInsert") {
            setIsTeam(false)
            setheadFileList([])
            form.setFieldsValue({
                teamName: rowInfo.teamName,
                disableFlag: true,
            })
        } else if (mode === "memberEdit") {
            setIsTeam(false)
            getTeamMemberOne(rowInfo.id)
        } else if (mode === "teamInsert") {
            form.setFieldsValue({
                disableFlag: true,
            })
            setIsTeam(true)
        } else if (mode === "teamEdit") {
            setIsTeam(true)
            getTeamOnes(rowInfo.id)
        }
    }
    //获取一条团队数据
    const getTeamOnes = (id) => {
        getTeamOne(id).then((res) => {
            // if (res && res.retCode === 200) {
                let results = res.retData
                setTeamOne(results)
                form.setFieldsValue({
                    spyunSn: results.spyunSn,
                    feieyunSn: results.feieyunSn,
                    wxQrCode: results.wxQrCode,
                    aliPayQrCode: results.aliPayQrCode,
                    teamName: results.teamName,
                    contactName: results.contactName,
                    contactPhone: results.contactPhone,
                    disableFlag: results.disableFlag === 0 ? true : false,
                })
            // } else {
            //     message.error(res.retMsg)
            // }
        })
    }
    // 获取一条成员数据
    const getTeamMemberOne = (id) => {
        getTeamMember(id).then((res) => {
            // debugger;
            // if (res && res.retCode === 200) {
                let results = res.retData
                setMemberOne(results)
                let imgFileList = results.headImg
                    ? [
                          {
                              uid: "-1",
                              status: "done",
                              url: `${fileLookUrl().image}${results.headImg}`,
                          },
                      ]
                    : []
                //回显图片
                setheadFileList(imgFileList)
                setpreviewImage({ visible: false, md5: results.headImg })
                form.setFieldsValue({
                    ...results,
                    userPassword: "",
                    disableFlag: results.disableFlag === 0 ? true : false,
                    headImg: imgFileList,
                })
            // } else {
            //     message.error(res.retMsg)
            // }
        })
    }
    //关闭调用的父级， 父级改变布尔值，从而重新渲染弹框是否展示
    const onClose = () => {
        onShow(false)
        form.resetFields()
    }

    //成员列表表单提交
    const onFinish = (fieldsValue) => {
        if (mode === "memberInsert") {
            let bool = false
            if (fieldsValue.userJobTitle !== "10101") {
                bool = true
            } else {
                let findMember = memberData.find((item) => item.userJobTitle === "10101")
                bool = findMember ? false : true
            }
            if (!bool) {
                message.warning("只能存在一个班长")
                return
            }
            let data = {
                ...fieldsValue,
                teamId: rowInfo.id,
                disableFlag: fieldsValue.disableFlag ? 0 : 1,

                headImg: headFileList.length > 0 ? (previewImage.md5 ? previewImage.md5 : null) : null,
            }
            addTeamMember(data)
                .then((res) => {
                    // if (res && res.retCode === 200) {
                        onMemberLoadData(rowInfo.id)
                        onClose()
                        message.success("成员新增成功")
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
                // .catch((err) => {
                //     message.error(err.retMsg)
                // })
        } else if (mode === "memberEdit") {
            let data = {
                ...fieldsValue,
                id: memberOne.id,
                teamId: memberOne.teamId,
                disableFlag: fieldsValue.disableFlag ? 0 : 1,
                headImg: headFileList.length > 0 ? (previewImage.md5 ? previewImage.md5 : null) : null,
            }
            EditTeamMember(data)
                .then((res) => {
                    // if (res && res.retCode === 200) {
                        onMemberLoadData()
                        onClose()
                        message.success("成员编辑成功")
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
                // .catch((err) => {
                //     message.error(err.retMsg)
                // })
        } else if (mode === "teamInsert") {
            addTeam({
                ...fieldsValue,
                disableFlag: fieldsValue.disableFlag ? 0 : 1,
            })
                .then((res) => {
                    // if (res && res.retCode === 200) {
                        onTeamLoadData(1)
                        onClose()
                        message.success(res.retMsg)
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
                // .catch((err) => {
                //     message.error(err.retMsg)
                // })
        } else if (mode === "teamEdit") {
            let data = {
                ...fieldsValue,
                id: teamOne.id,
                disableFlag: fieldsValue.disableFlag ? 0 : 1,
            }
            editTeam(data)
                .then((res) => {
                    // if (res && res.retCode === 200) {
                        onTeamLoadData(1)
                        onClose()
                        message.success(res.retMsg)
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
                .catch((err) => {
                    message.error(err.retMsg)
                })
        }
    }
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    const titleObj = {
        memberInsert: { t: "团队成员新增", x: "成员详情" },
        memberEdit: { t: "团队成员编辑", x: "成员详情" },
        teamInsert: { t: "团队新增", x: "团队详情" },
        teamEdit: { t: "团队编辑", x: "团队详情" },
    }
    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }
    return (
        <PubDrawer visible={teamVisible} onClose={onClose}>
            <div className="team-drawer-title">
                <span>{mode && titleObj[mode].t}</span>
            </div>
            <div className="team-drawer-content">
                <div className="team-drawer-header">{mode && titleObj[mode].x}</div>
                <div className="team-drawer-form">
                    <Form form={form} {...formItemLayout} onFinish={onFinish}>
                        {isTeam ? (
                            <>
                                <Row gutter={20}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="团队名称"
                                            name="teamName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入团队名称",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入团队名称" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="团队联系人"
                                            name="contactName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入团队联系人",
                                                },
                                                {
                                                    pattern: /^[\u4E00-\u9FA5]{2,5}$/,
                                                    message: "请输入正确的姓名",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入团队联系人" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="联系电话"
                                            name="contactPhone"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `请输入手机号码!`,
                                                },
                                                {
                                                    pattern: /^1\d{10}$/,
                                                    message: "请输入正确手机号格式",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入联系电话" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="disableFlag" label="团队状态" valuePropName="checked">
                                            <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={12}>
                                        <Form.Item label="商鹏云打印机" name="spyunSn">
                                            <Input placeholder="请输入商鹏云打印机编码" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="微信二维码" name="wxQrCode">
                                            <Input placeholder="请输入微信收款二维码" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={12}>
                                        <Form.Item label="飞鹅云打印机" name="feieyunSn">
                                            <Input placeholder="请输入飞鹅云打印机编码" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="支付宝二维码" name="aliPayQrCode">
                                            <Input placeholder="请输入支付宝收款二维码" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <>
                                <Row gutter={20}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="姓名"
                                            name="userName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入姓名",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入姓名" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="手机号"
                                            name="userPhone"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入手机号",
                                                },
                                                {
                                                    pattern: /^1\d{10}$/,
                                                    message: "请输入正确手机号格式",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入手机号" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="职务"
                                            name="userJobTitle"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请选择职务",
                                                },
                                            ]}
                                        >
                                            <Select showSearch allowClear placeholder="请选择职务" style={{ width: "100%" }}>
                                                {getPcodeDict("101").children.map((item) => (
                                                    <Option value={item.dictCode} key={item.id}>
                                                        {item.title}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="分配点数"
                                            name="userDistributionPoints"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入分配点数",
                                                },
                                            ]}
                                        >
                                            <Input disabled={mode === "memberLook" ? true : false} placeholder="请输入分配点数" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="账户"
                                            name="userAccount"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入账户",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入账户" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label="密码"
                                            name="userPassword"
                                            rules={[
                                                {
                                                    required: mode === "memberEdit" ? false : true,
                                                    message: "请输入密码",
                                                },
                                            ]}
                                        >
                                            <Input disabled={mode === "memberLook" ? true : false} placeholder="请输入密码" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={12}>
                                        <Form.Item name="disableFlag" label="成员状态" valuePropName="checked">
                                            <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="成员头像" name="headImg" valuePropName="fileList" getValueFromEvent={normFile}>
                                            <Upload
                                                
 action={`${fileUploadUrl}upload/image_old`}
 headers={{ authorization: getToken() }}
                                                listType="picture-card"
                                                className="avatar-uploader"
                                                fileList={headFileList}
                                                onPreview={() =>
                                                    setpreviewImage({
                                                        ...previewImage,
                                                        visible: true,
                                                    })
                                                }
                                                onChange={handleImgChange}
                                            >
                                                {headFileList.length >= 1 ? null : <Button size="small">上传成员头像</Button>}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Form.Item className="team-form-footer" wrapperCol={24}>
                            <Button onClick={onClose} className="team-but-one">
                                取消
                            </Button>
                            <Button htmlType="submit" className="team-but-two">
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <Modal visible={previewImage.visible} title="头像查看" footer={null} onCancel={() => setpreviewImage({ ...previewImage, visible: false })}>
                <img alt="" style={{ width: "100%" }} src={`${fileLookUrl().image}${previewImage.md5}`} />
            </Modal>
        </PubDrawer>
    )
}

export default TeamDetails
