import React from "react";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { DataTable } from "./DataTable";

export const DataBox = () => {
    return (
        <YhBox title="记录列表">
            <DataTable />
        </YhBox>
    )
}

