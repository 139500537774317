import React, { useEffect, useState } from "react"
import { Button, Form, Input, Col, Row, Checkbox, Table, message, Popconfirm } from "antd"
import { getDiagnoseSpokenList, diagnoseSpokenSave } from "@/service/aiDiagnoseService"

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    // console.log(dataIndex)
    const inputNode = inputType === "checkbox" ? <Checkbox defaultChecked={record.selectBoxType} /> : <Input />
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={dataIndex === "spokenName" ? [{ required: true, message: `请输入${title}!` }] : []}
                    // valuePropName={dataIndex === "selectBoxType" ? "checked" : "value"}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    )
}
//口语维护
export default ({ modeKey, dispatch }) => {
    const [form] = Form.useForm()
    const [data, setData] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        let data = {
            pageSize: 1000,
            pageNum: 1,
            searchItem: {},
            orders: [{ column: "updateTime", rule: "DESC" }],
        }
        getDiagnoseSpokenList(data)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    setData(res.retData.list)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }

    //表单提交
    const onSubmit = () => {
        form.validateFields().then((val) => {
            diagnoseSpokenSave(val)
                .then((res) => {
                    // if (res && res.retCode === 200) {
                        form.resetFields()
                        loadData()
                        message.success("故障口语新增成功")
                    // } else {
                    //     message.warning(res.retMsg)
                    // }
                })
                // .catch((err) => message.error(err.retMsg))
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    //取消
    const handleCancel = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "aiDiagnose",
        })
    }

    //编辑table 行
    const [editForm] = Form.useForm()
    const [editingKey, setEditingKey] = useState("")
    const isEditing = (record) => record.id === editingKey

    //编辑当前行
    const edit = (record) => {
        editForm.setFieldsValue({
            spokenName: "",
            remark: "",
            ...record,
        })
        setEditingKey(record.id)
    }

    const save = async (id) => {
        try {
            const row = await editForm.validateFields()
            const newData = [...data]
            const index = newData.findIndex((item) => id === item.id)
            if (index > -1) {
                // const item = newData[index];
                // newData.splice(index, 1, { ...item, ...row });
                // console.log(newData);
                diagnoseSpokenSave({ ...row, id })
                    .then((res) => {
                        if (res && res.retCode === 200) {
                            loadData()
                            setEditingKey("")
                            message.success("故障口语修改成功")
                        } else {
                            message.warning(res.retMsg)
                        }
                    })
                    .catch((err) => message.error(err.retMsg))
            } else {
                newData.push(row)
                setData(newData)
                setEditingKey("")
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo)
        }
    }

    const columns = [
        {
            title: "口语名称",
            align: "center",
            width: 300,
            dataIndex: "spokenName",
            editable: true,
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
            editable: true,
        },
        {
            title: "操作",
            width: 200,
            align: "center",
            render: (_, record) => {
                const editable = isEditing(record)
                return editable ? (
                    <>
                        <Button shape="round" className="yc-public-but" onClick={() => save(record.id)}>
                            保存
                        </Button>
                        <Popconfirm okText="确认" cancelText="关闭" title="取消将恢复编辑前的值！！" onConfirm={() => setEditingKey("")}>
                            <Button shape="round" className="yc-public-but">
                                取消
                            </Button>
                        </Popconfirm>
                    </>
                ) : (
                    <Button shape="round" className="yc-public-but" disabled={editingKey !== ""} onClick={() => edit(record)}>
                        编辑
                    </Button>
                )
            },
        },
    ]
    const spokenColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === "selectBoxType" ? "checkbox" : "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        }
    })
    return (
        <>
            <div className="toolbar">
                <span className="modules-name">智能诊断</span>
            </div>
            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>故障口语详情</span>
                    </div>
                    <div className="list-but">
                        <Button shape="round" className="but-left" onClick={handleCancel}>
                            返回
                        </Button>
                        <Button shape="round" className="but-left" onClick={onSubmit}>
                            添加
                        </Button>
                    </div>
                </div>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row>
                            <Col span={6} xxl={4}>
                                <Form.Item
                                    label="口语名称"
                                    name="spokenName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "该项为必填",
                                        },
                                    ]}
                                >
                                    <Input placeholder="口语名称" />
                                </Form.Item>
                            </Col>
                            <Col span={12} xxl={8}>
                                <Form.Item label="备注" name="remark" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                                    <Input placeholder="备注" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>故障口语列表</span>
                    </div>
                </div>
                <Form form={editForm} component={false}>
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        size="small"
                        scroll={{ y: 500 }}
                        rowKey={(record) => record.id}
                        dataSource={data}
                        columns={spokenColumns}
                        rowClassName="editable-row"
                        pagination={false}
                    />
                </Form>
            </div>
        </>
    )
}
