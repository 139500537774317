import {
    post,
    get
} from '../utils/request';

function organPage(data) {
    return post(`/ci-platform/organ/page`, data);
}

//新增
function organInsert(data) {
    return post(`/ci-platform/organ/insert`, data);
}

//新增
function organUpdate(data) {
    return post(`/ci-platform/organ/update`, data);
}
//删除
function getOrganOne(id) {
    return get(`/ci-platform/organ/one/${id}`, );
}

//删除
function organDelete(id) {
    return get(`/ci-platform/organ/delete/${id}`, );
}
//删除
function userPage(data) {
    return post(`/ci-platform/user/page`, data);
}


//当前用户机构信息
function getOrganCurrentUser(id) {
    return get(`/ci-platform/organ/currentUser`, );
}

//机构账户信息
function getOrganAccount(data) {
    return post(`/ci-platform/organ_account/page`, data);
}

//账户信息新增
function organAccountAdd(data) {
    return post(`/ci-platform/organ_account/insert`, data);
}

//账户信息编辑
function organAccountUpdate(data) {
    return post(`/ci-platform/organ_account/update`, data);
}

//账户信息一条
function organAccountOne(id) {
    return get(`/ci-platform/organ_account/one/${id}`);
}


//账户信息一条
function organAccountRecharge(data) {
    return post(`/ci-platform/organ_account/recharge`, data);
}

//账户信息一条
function organAccountLog(data) {
    return post(`/ci-platform/organ_account/log`);
}


export {
    organPage,
    organInsert,
    organUpdate,
    organDelete,
    userPage,
    getOrganOne,
    getOrganCurrentUser,
    getOrganAccount,
    organAccountAdd,
    organAccountUpdate,
    organAccountOne,
    organAccountRecharge,
    organAccountLog
}