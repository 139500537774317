import React from "react";
import { TopButs } from "./TopButs";
import { FormBox } from "./FormBox";
import { TableBox } from "./TableBox";
import { FixedBottom } from "./FixedBottom";
import { AddGoodsModal } from "./AddGoodsModal";
import { SettleDrawer } from "./SettleDrawer"
import { CheckModel } from "./chenckModel"


export const SaleOrderDetails = (props) => {

    return (
        <div className="yh-detail-box">
            <TopButs {...props} />
            <FormBox {...props} />
            <TableBox {...props} />
            <FixedBottom {...props} />
            <AddGoodsModal {...props} />
            <CheckModel {...props} />
            <SettleDrawer {...props} />
        </div>
    )
}