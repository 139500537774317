import React,{ useState, useEffec,createContext, } from "react";
import { createModel } from "hox";
import {contractService} from '../contractParticularsServer'
import { message,Modal } from "antd";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import {getVehicleList2,getCustomerListNew} from '@/service/customerService'
import moment from "moment";
import { numberParseChina, formatNum, fmoney, momentFormat } from '@/erp_subpackage/utils/util';
export const usecontractDetailsModel = createModel(function () {
    const { user: { organId } } = useGlobalModel();
    const initFormVal={organId}
    const [loading, setLoading] = useState({ spinning: false, tip: "加载中..." });
    //分页参数
    const initPgCfg = { pageSize: 10, current: 1 }
    //合约分页参数
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    //账单列表分页参数（非合约）
    const [pgCfgo, setPgCfgo] = useState(initPgCfg);
    //账单列表明细分页参数（非合约）
    const [pgCfgt, setPgCfgt] = useState(initPgCfg);

    const [pgCfgwork, setPgCfgwork] = useState(initPgCfg);

    const [saveBtnLoading, setSaveBtnLoading] = useState(false); //  保存按钮

    const initdesc = {
        payableAmount:0,paidAmount:0,totalAmount:0
    }
    const initdesct = {
        hourlyWage:0,
        materialAmount:0,
        travelExpenses:0,
        mealSupplement:0,
        threeGuaranteesTotalHourlyWage:0,
        totalAmount:0,
        prepayments:0,
        paidAmount:0,
        payableAmount:0
    }

    //初始胡工单信息统计
    const initdescwork = {
        hourlyWage:0,
        materialAmount:0,
        travelExpenses:0,
        mealSupplement:0,
        threeGuaranteesTotalHourlyWage:0,
        totalAmount:0,
        prepayments:0,
        paidAmount:0,
        payableAmount:0
    }
     //查询条件
     const [searchParam, setSearchParam] = useState();
    //新增表单缓存
    const [addFormVal, setAddFormVal] = useState(initFormVal);
    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState(initFormVal);
    //查看表单缓存
    const [lookFormVal, setLookFormVal] = useState(initFormVal);
    //车辆信息数据源
    const [vehicleData,setVehicleData]=useState([])
    const [newVehicleData,setNewVehicleData]=useState([])
    const [customerid,setCustomerid]=useState('')
    

    //车辆新增
    const [editSelect, setEditSelect] = useState([]) //已选的车辆
    const [addSelect, setAddSelect] = useState([]) //已选的车辆
    const [lookSelect, setLookSelect] = useState([]) //已选的车辆
    const [dataSource, setDataSource] = useState([]);
    const [choose, setChoose] = useState(0) //选择数量
    const [selectedVehicle, setSelectedVehicle] = useState([])

    //查询对账单、查询对账单明细、查询工单信息
    const [searchParamo, setSearchParamo] = useState();
    const [searchParamt, setSearchParamt] = useState();
    const [serachworke,setSerachworke]=useState();
    const [serachmdetail,setSerachmdetail]=useState();

    //对账单列表（非里程）
    const [billData,setBillData]=useState([])
    //对账单里程列表
    const [billmileageData,setBillmileageData]=useState([])
     //对账单里程列表
     const [billdetailData,setBilldetailData]=useState([])
     //对账单列表明细
     const [billTwo,setBillTwo]=useState([])

     //筛选里程
     const [newfilterData,setNewfilterData]=useState([])
    //工单信息
    const [workOrderData,setworkOrderData]=useState([])
    //里程单条账单信息
    const [billoneData,setBilloneData]=useState([])

    const [code,setCode]=useState('')
    const [descTotal,setDescTotal,] =useState(initdesc)   
    const [descTotalt,setDescTotalt,] =useState(initdesct)
    const [descTotalwork,setDescTotalwork,] =useState(initdescwork)
    const [show,setShow]=useState(false)
    //里程多条明细
    const [meilagedetialTotal,setMeilagedetialTotal] =useState(initdesc) 
    const [meilageoneTotal,setMeilageoneTotal] =useState(initdesc) 
    //操作日志列表
    const [logdatasource,setLogdatasource]=useState([])
    //里程维护
    const [mileagedata,setMileagedata]=useState([])
    //手动生成账单
    const [createBill,setCreateBill]=useState([])
    const [createvisible,setCreatevisible]=useState(false)
    //表格多选row
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    //合约id
    const [signid,setSignid]=useState('')
    //parentid
    const [parentid,setParentid]=useState('')

    const [detailVisible,setDetailVisible]=useState(false)
    const [meailagedetailVisible, setMeailagedetailVisible]=useState(false)
    const [visible,setVisible]=useState(false)

    //删除提示
    const ReachableContext = createContext(null);
    //跳转查询对账单明细(非里程)
    const openOrder=async(value)=>{
        let data={
            pageSize:10,
            pageNum:1,
            orders:[{
                customerId:editFormVal.customerId??lookFormVal.customerId??"",
                billCode:value
            }],
            searchItem:{billCode:value,customerId:editFormVal.customerId??lookFormVal.customerId??"",
            billCode:value}
        }
        contractService.conpageT(data).then((res)=>{ 
            let {retData}= res
            let{list,...pg}=retData
            setBillTwo(list)
            setCode(list[0].billCode)
            setPgCfgt( {
                total: pg.total,
                pageSize: pg.pageSize,
                current: pg.pageNum 
            })
           })
           contractService.totalDetails(data).then((res)=>{
            let {retData}=res
            let {payableAmount,paidAmount,prepayments,totalAmount, threeGuaranteesTotalHourlyWage, mealSupplement,travelExpenses, materialAmount,hourlyWage}=retData
           const obj = {payableAmount,paidAmount,prepayments,totalAmount, threeGuaranteesTotalHourlyWage, mealSupplement,travelExpenses, materialAmount,hourlyWage}
           setDescTotalt(obj)
            })
    }

     
    //根据客户id车辆列表
    const getVehicleList=async(page,searvhVal=searchParam)=>{
        setLoading({ spinning: true });
        try { 
        contractService.custmerVechiel(editFormVal.customerId??customerid).then((res)=>{
            let {retData}=res
            setVehicleData(retData)
            setNewVehicleData(retData)
            setLoading({ spinning: false });
        })}catch(error){
            console.log(error)
        }
    }

    //对账单列表（非里程）
    const contractPage=async(page,serachVal=searchParamo)=>{
        setLoading({ spinning: true });
        try { 
        let data={
            pageSize: initPgCfg.pageSize,
            pageNum: (page?.pageNum || page?.current) ?? pgCfgo.current ?? initPgCfg.current,
            orders:[{
                customerId:editFormVal.customerId??lookFormVal.customerId??"",
                signCode:editFormVal.signCode??lookFormVal.signCode??'', 
            }],
            searchItem:{...serachVal,customerId:editFormVal.customerId??lookFormVal.customerId??"",
            signCode:editFormVal.signCode??lookFormVal.signCode??''}
        }

       contractService.conpage(data).then((res)=>{
        let {retData}= res
        let{list,...pg}=retData
        setBillData(list)
        setPgCfgo({
            total: pg.total,
            pageSize: pg.pageSize,
            current: pg.pageNum 
        })
        contractService.total(data).then((res)=>{
           let {retData}=res
           let {payableAmount,paidAmount,totalAmount}=retData
           const obj = {payableAmount,paidAmount,totalAmount}
           setDescTotal(obj)
        })
        setLoading({ spinning: false });
       })}catch(error){
        console.log(error)
    }
      
       
    }
      //对账单列表明细（非里程）
      const contractPageT=async(page,serachVal=searchParamt)=>{
        setLoading({ spinning: true });
        try{
        let data={
            pageSize: initPgCfg.pageSize,
            pageNum: (page?.pageNum || page?.current) ?? pgCfgt.current ?? initPgCfg.current,
            orders:[{
                customerId:editFormVal.customerId??lookFormVal.customerId??"",
                ...serachVal
            }],
            searchItem:{...serachVal,customerId:editFormVal.customerId??lookFormVal.customerId??""}
        }
       contractService.conpageT(data).then((res)=>{
        let {retData}= res
        let{list,...pg}=retData
        setBillTwo(list)
        setPgCfgt( {
            total: pg.total,
            pageSize: pg.pageSize,
            current: pg.pageNum 
        })
        contractService.totalDetails(data).then((res)=>{
            let {retData}=res
            let {payableAmount,paidAmount,prepayments,totalAmount, threeGuaranteesTotalHourlyWage, mealSupplement,travelExpenses, materialAmount,hourlyWage}=retData
           const obj = {payableAmount,paidAmount,prepayments,totalAmount, threeGuaranteesTotalHourlyWage, mealSupplement,travelExpenses, materialAmount,hourlyWage}
           setDescTotalt(obj)
            })
       })}catch(error){
        console.log(error)
    }
       setLoading({ spinning: false });  
    }

     //里程-工单列表
     const openWorkerOrder=async(page,serachVal=serachworke)=>{
        try{ 
            let data = {
                pageSize: initPgCfg.pageSize,
                pageNum: (page?.pageNum || page?.current) ?? pgCfgt.current ?? initPgCfg.current,
            endTime:'',
            startTime:'',
            orders:[{
                 signId:signid,
                 customerId:editFormVal.id ??lookFormVal.id,
            }],
            searchItem:{
                signId:signid,
                customerId:editFormVal.id ??lookFormVal.id,
              ...serachVal
            }
           } 
         contractService.workOrder(data).then(res=>{
            let {retData:{list,...pg}} = res
            setworkOrderData(list)
            setPgCfgwork({
                total: pg.total,
                pageSize: pg.pageSize,
                current: pg.pageNum 
            })
         })
         contractService.workOrderTotal(data).then(res=>{
           let {retData} = res
           setDescTotalwork(retData)
           
         })
        } catch(error){
            console.log(error)
        }
     }

     //里程对账单

     const  mileageOrrder=async(page,serachVal=searchParamo,)=>{
        try{
            let data = {
                pageSize: initPgCfg.pageSize,
                pageNum: (page?.pageNum || page?.current) ?? pgCfgt.current ?? initPgCfg.current,
                endTime:'',
                startTime:'',
                orders:[{
                     signId:signid, 
                     billType:1
                }],
                searchItem:{
                    signId:signid,
                    billType:1,
                    ...searchParamo  
                }
               }  
               contractService.mileagetotal(data).then(res=>{
               let {retData}=res
               let {payableAmount,paidAmount,totalAmount}=retData
               const obj = {payableAmount,paidAmount,totalAmount}
               setDescTotal(obj)
               
              })
              let {retData}=await contractService.mileageorder(data)
              let {list}=retData
              setBillmileageData(list) 
        }catch(error){
            console.log(error)
        }
    } 
    //跳转账单明细(里程)
    const  mileageOrderdetaile=async(val)=>{  
        setParentid(val)
        try{
            let data = {
                pageSize: 15,
                pageNum: 1,
                endTime:'',
                startTime:'',
                orders:[{
                     signId:signid, 
                     billType:2
                }],
                searchItem:{
                    signId:signid,
                    billType:2,
                    parentId:val,
                }
               }  
               contractService.mileagetotal(data).then(res=>{
               let {retData}=res
               let {payableAmount,paidAmount,totalAmount}=retData
               const obj = {payableAmount,paidAmount,totalAmount}
              setMeilagedetialTotal(obj)
               
              })
              let {retData}=await contractService.mileageorder(data)
              let {list}=retData
              const updatedData = list.map(obj => ({ ...obj, mileageDiff: obj.mileageCurrent - obj.mileageLast }));
              setBilldetailData(updatedData) 
        }catch(error){
            console.log(error)
        }
    } 

    const  mileageOrderdetailes=async(page,serachVal=serachmdetail)=>{   
        try{
            let data = {
                pageSize: initPgCfg.pageSize,
                pageNum: (page?.pageNum || page?.current) ?? pgCfgt.current ?? initPgCfg.current,
                endTime:'',
                startTime:'',
                orders:[{
                     signId:signid, 
                     billType:2
                }],
                searchItem:{
                    signId:signid,
                    billType:2,
                    parentId:parentid,
                    ...serachVal
                }
               }  
               contractService.mileagetotal(data).then(res=>{
               let {retData}=res
               let {payableAmount,paidAmount,totalAmount}=retData
               const obj = {payableAmount,paidAmount,totalAmount}
               setMeilagedetialTotal(obj)
               
              })
              let {retData}=await contractService.mileageorder(data)
              let {list}=retData
              const updatedData = list.map(obj => ({ ...obj, mileageDiff: obj.mileageCurrent - obj.mileageLast }));
              
              setBilldetailData(updatedData) 
        }catch(error){
            console.log(error)
        }
    } 
   

    const updateSearchParamo = (updateParam) => {
        setSearchParamo({ ...serachmdetail, ...updateParam })
    }

    const resetSearchParamm = () => {
        setSerachmdetail({});
        contractPage(initPgCfg,{});
    }

    const updateSearchParamm = (updateParam) => {
        setSerachmdetail({ ...serachmdetail, ...updateParam })
    }

    const resetSearchParamo = () => {
        setSearchParamo({});
        contractPage(initPgCfg,{});
    }
    const resetSearchParamom = () => {
        setSearchParamo({});
        mileageOrrder(initPgCfg,{});
    }

    const updateSearchParamt = (updateParam) => {
        setSearchParamt({ ...searchParamt, ...updateParam })
    }

    const resetSearchParamt = () => {
        setSearchParamt({});
        contractPageT(initPgCfg,{customerId:editFormVal.customerId??lookFormVal.customerId??"",billCode:code});
    }
   
    
     //表单修改
     const updateFormVal = (mode, obj) => {
        const setFormVal = mode === "edit" ? setEditFormVal : mode === "look" ? setLookFormVal : setAddFormVal;
        setFormVal(origin => obj ? { ...origin, ...obj } : initFormVal);
    }

    //一键添加所有车辆
    // const handleAddSelectedItems=(mode)=>{
    //     const isEdit = mode === 'edit'
    //     const list=isEdit?editSelect.map((item)=>item.vehicleId):addSelect.map((item)=>item.vehicleId)
    //     let filterData=vehicleData.map((element)=>{
    //         if( list.includes(element.vehicleId)){return}
    //         else{return element}
    //     }).filter(item=>item)
    //     filterData.map(item=>{
    //         return (isEdit ? setEditSelect((origin) => [...origin, {...item}]) : setAddSelect((origin) => [...origin, {...item}]) , isEdit ? setChoose(filterData.length):setChoose(filterData.length))
    //     }) 
    // }


     //添加车辆  
    //  const addVehicle = (record,mode) => {
    //     //车辆签约日期默认主单日期
    //     let formVal = mode === 'add' ? addFormVal : mode === 'edit' ? editFormVal : lookFormVal;
    //     record = { ...record, "signStartTime": formVal.signStartTime};

    //     const isEdit = mode === 'edit'
    //     isEdit ? setEditSelect((origin) => [...origin, { ...record}]) : setAddSelect((origin) => [...origin, { ...record}])
    //     isEdit ? setChoose(editSelect.length+1):setChoose(addSelect.length+1)
    // }

    //删除车辆 选中的
    const removeVehicle = (record, mode) => {
        if(mode==='edit'){
        let type=record.signType==='046001'?'大包':'月结'
        const config = {
            title: '警告提醒',
            content: (
              <>
                <ReachableContext.Consumer>{(name) => `该客户车辆已签约${type}，并在工单使用中，禁止删除！！！!`}</ReachableContext.Consumer>
              </>
            ),
          };
        contractService.deleteVechiel(record.vehicleId).then(res=>{  
           let {retData}=res
           if(retData===false){
            Modal.error(config)
            return false
           }else if(retData===true){
            if(editFormVal.vehicleList.filter(item=>item.vehicleId ===record.vehicleId).length){
                Modal.confirm({
                    title:'提示信息',
                    content:`该客户车辆已签约${type}，未到合约终止日期，确认是否现在解约？`,
                    onOk() {
                        setEditSelect((origin) => origin.filter((item) => item.vehicleId !== record.vehicleId))
                        setChoose(dataSource.length-1)
                        }
                     })
               
            }else{
               setEditSelect((origin) => origin.filter((item) => item.vehicleId !== record.vehicleId))
                setChoose(dataSource.length-1)
            } 
            }}) }else if(mode==='add'){
                setAddSelect((origin) => origin.filter((item) => item.vehicleId !== record.vehicleId))
                setChoose(dataSource.length-1)
            }
    }

    //添加选择的车辆
    const addSelectedVehicles = (mode) => {
        selectedVehicle.forEach(item => {
            //车辆签约日期默认主单日期
            let formVal = mode === 'add' ? addFormVal : mode === 'edit' ? editFormVal : lookFormVal;
            item = { ...item, "signStartTime": formVal.signStartTime};

            const isEdit = mode === 'edit'
            isEdit ? setEditSelect((origin) => [...origin, { ...item}]) : setAddSelect((origin) => [...origin, { ...item}])
            isEdit ? setChoose(editSelect.length+1):setChoose(addSelect.length+1)
        });
        setSelectedVehicle([]);
    }

    //选择全部车辆
    const selectAllVehicles = (mode) => {
        // const list = selectedVehicle.map((item)=>item.vehicleId);
        // const addVechielSelect = mode === 'add' ? addSelect : editSelect
        // let filterData = addVechielSelect.map((element)=>{
        //     if(list.includes(element.vehicleId) || element.signFlag === 1){return}
        //     else{return element}
        // }).filter(item=>item);

        // disabled={record.signFlag === 1 ||
        //     !selectedVehicle.every(item => item.vehicleId !== record.vehicleId) ? true : false ||
        //         !addVechielSelect.every(item => item.vehicleId !== record.vehicleId) ? true : false

        const addVechielSelect = mode === 'add' ? addSelect : editSelect;
        let filterData = vehicleData.filter((e) => {
            return e.signFlag === 0 &&
            selectedVehicle.every(item => item.vehicleId !== e.vehicleId) &&
            addVechielSelect.every(item => item.vehicleId !== e.vehicleId)
        });

        setSelectedVehicle([...selectedVehicle, ...filterData]);
    }

    //获取一条数据
    const getdedtailsOne = async (id, mode) => {
        setSignid(id)
        let { retData } = await contractService.one(id);
        const { customerInfo, attachment,signEndTime,signStartTime,vehicleList,billDate,...from } = retData;
        let newAttachment = attachment===''|| attachment===null?[]:attachment.split(',')
        const transformedData = newAttachment.map(item => {
            return { md5: item };
          });
        const newsignEndTime=moment(signEndTime, "YYYY-MM-DD HH:mm:ss")
        const newsignStartTime=moment(signStartTime, "YYYY-MM-DD HH:mm:ss")
        const newRetData={...from,attachment:transformedData,signEndTime:newsignEndTime,signStartTime:newsignStartTime,
            ...customerInfo??{},vehicleList,businessManager:customerInfo?customerInfo.businessManagerName:"",teamId:customerInfo?customerInfo.teamName:"",
        billDate:'每月25号'
        }
        console.log(111,vehicleList)
        let list = vehicleList.map(obj=>{
            return {
                ...obj,
                // signStartTime:moment(obj.signEndTime, "YYYY-MM-DD HH:mm:ss")
                // signStartTime:momentFormat(obj.signStartTime)
                signStartTime:moment(obj.signStartTime, "YYYY-MM-DD HH:mm:ss")
            }
        })
        console.log(11,list)
        updateFormVal(mode, newRetData);
        if(mode==='look'){        
            setLookSelect(list)
        }else{
            console.log(list)
            setEditSelect(list)  
        }
        }

     //对账单导出(非里程)
    const onExportOrder =(searchValue = searchParamo)=>{
         setLoading({ spinning: true, tip: "列表加载中..." });
         const data = {
                 customerId:editFormVal.customerId??'',
                 signId:signid,
                 ...searchValue
         }
        contractService.exportOrder(data).then((res)=>{  
         message.success('导出进行中。请稍后去导出记录列表查看')
     })
         setLoading({ spinning: false });
    }
     //对账单导出(里程)
     const onExportMeilage =(searchValue = searchParamo)=>{
        setLoading({ spinning: true, tip: "列表加载中..." });
        const data = {
                customerId:editFormVal.customerId??'',
                signId:signid,
                ...searchValue
        }
       contractService.exportmeilage(data).then((res)=>{  
        message.success('导出进行中。请稍后去导出记录列表查看')
    })
        setLoading({ spinning: false });
   }
     //工单信息导出
     const onExportWorker =(searchValue = searchParamo)=>{
        setLoading({ spinning: true, tip: "列表加载中..." });
        const data = {
                customerId:editFormVal.customerId??'',
                signId:signid,
                ...searchValue
        }
       contractService.exportWorker(data).then((res)=>{  
        message.success('导出进行中。请稍后去导出记录列表查看')
    })
        setLoading({ spinning: false });
   }
    return {
        pgCfg,
        code,
        billData,
        billTwo,
        loading,
        editFormVal,
        addFormVal,
        lookFormVal,
        vehicleData,
        searchParamt,
        searchParamt,
        searchParamo,
        newVehicleData,
        dataSource,
        addSelect,
        editSelect,
        choose,
        selectedVehicle,
        descTotal,
        descTotalt,
        pgCfgt,pgCfgo,
        selectedRowKeys,
        lookSelect,show,
        logdatasource,
        workOrderData,
        serachworke,
        mileagedata,
        newfilterData,
        descTotalwork,
        billoneData,
        billmileageData,
        createBill,
        createvisible,billdetailData,
        serachmdetail,
        meailagedetailVisible,
        visible,setVisible,
        meilagedetialTotal,meilagedetialTotal,
        meilageoneTotal,setMeilageoneTotal,initFormVal,
        saveBtnLoading,setSaveBtnLoading,


        updateFormVal,
        getdedtailsOne,
        setVehicleData,
        getVehicleList,
        setSearchParam,
        setSearchParamt,
        setSearchParamo,
        setAddFormVal,
        setNewVehicleData,
        setCode,
        setCustomerid,setSelectedRowKeys,
        setLookSelect,openOrder,
        setPgCfgt,setPgCfgo,
        setDescTotal,setDescTotalt,onExportOrder,
        updateSearchParamo,updateSearchParamt,
        resetSearchParamt,resetSearchParamo,
        setChoose,
        setAddSelect,
        setEditSelect,
        setSelectedVehicle,
        removeVehicle,setDataSource,
        addSelectedVehicles,
        selectAllVehicles,
        contractPage,
        setBillTwo,contractPageT,
        setLogdatasource,
        setSerachworke,
        setworkOrderData,
        setMileagedata,
        setNewfilterData,
        setDescTotalwork,
        setBilloneData,
        setBillmileageData,
        openWorkerOrder,setSignid,
        mileageOrrder,
        mileageOrderdetaile,
        setBillData,setCreateBill,
        setCreatevisible,onExportWorker,
        onExportMeilage,setBilldetailData,
        mileageOrderdetailes,
        setSerachmdetail,updateSearchParamm,resetSearchParamm,
        detailVisible,setDetailVisible,
        setMeailagedetailVisible,resetSearchParamom,setPgCfgwork,pgCfgwork

    }
})