import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";

// /**
//  * 入库单
//  */
// export interface ISCMStockDetailedDetail {
//     shopId: string, // null,
//     code: string, // null,
//     shopName: string, // null,
//     materialCode: string, // "511-434-01-01",
//     materialName: string, // "排气制动电磁阀",
//     originalWarehouseCode: string, // "wc",
//     originalWarehouseName: string, // "潍柴库",
//     originalShelfId: string, // null,
//     originalShelfCode: string, // "A1-20",
//     originalInventoryNum: number, // 1,
//     moveNum: number, // null,
//     warehouseCode: string, // "wc",
//     warehouseName: string, // "潍柴库",
//     shelfId: string, // null,
//     shelfCode: string, // "A1-11",
//     restoreNum: number, // null,
//     restoreTime: string, // null,
//     restorePerson: string, // null,
//     isRestore: string, // null,
//     id: string, // "0c6d5ff7e2aab68486e59ce664c99017",
//     createTime: string, // null,
//     updateTime: string, // null,
//     createUser: string, // null,
//     updateUser: string, // null,
//     deleteFlag: string, // null
//     warehouseCode1?: string
//     warehouseName1?: string
//     shelfId1?: string
//     shelfCode1?: string
//     // 入库数量：
//     inventoryQuantity: string
//     // 入库单价：
//     price: string
//     // 入库金额：
//     amount: string
//     // 供应商编号：
//     supplierCode: string
//     // 供应商名称：
//     supplierName: string
//     // 采购单号：
//     purchaseCode: string
//     // 入库日期：
//     settleTime: string
//     // 替换编号：
//     replaceCode: string
//     // 商品编号：
//     insideCode: string
//     // 商品名称：
//     insideName: string
//     inventoryNum: number
//     [key: string]: any
// }

// //商品详情
// export interface ISCMStockDetailedGoods {
//     id: string,                           // "111",
//     code: string,                           // "1",
//     orderNum: string,                           // 1,
//     deleteFlag: string,                           // 0,
//     createTime: string,                           //,
//     updateTime: string,                           // ,
//     createUser: string,                           // null,
//     updateUser: string,                           // null,
//     detailsId: string,                           // "1",
//     ioId: string,                           // "1",
//     materialCode: string,                           // "1",
//     materialName: string,                           // "1",
//     replaceCode: string,                           // "1",
//     typeCode: string,                           // "1",
//     typeName: string,                           // "1",
//     unitCode: string,                           // "1",
//     unitName: string,                           // "1",
//     engineType: string,                           // "1",
//     ioFlag: string,                           // "1",
//     num: string,                           // 1,
//     price: string,                           // 1,
//     amount: string,                           // 1,
//     warehouseCode: string,                           // "1",
//     warehouseName: string,                           // "1",
//     shelfId: string,                           // "1",
//     shelfCode: string,                           // "1",
//     openingNum: string,                           // 1,
//     openingPurAmount: string,                           // 1,
//     openingAvgAmount: string,                           // 1,
//     endingNum: string,                           // 1,
//     endingPurAmount: string,                           // 1,
//     endingAvgAmount: string,                           // 1
// }
// //采购明细转移货位
// export interface ISCMPoShift {
//     id: string,// "3562a0997394fe648fbaff0debbc50bf", 采购单明细id
//     warehouseCode: string,// "wc", 仓库编号
//     warehouseName: string,// "潍柴库", 仓库名称
//     shelfId: string,// "bcd65b9cad280b5f7a9089d7433ce303", 货位ID
//     shelfCode: string,// "ABC123", 货位编号
//     moveNum: number,// 10                                                                移库数量
// }
// export interface ISCMPoShiftImportDetail {
//     shopId: string, // null,
//     code: string, // null,
//     shopName: string, // null,
//     materialCode: string, // "511-434-01-01",
//     materialName: string, // "排气制动电磁阀",
//     originalWarehouseCode: string, // "wc",
//     originalWarehouseName: string, // "潍柴库",
//     originalShelfId: string, // null,
//     originalShelfCode: string, // "A1-20",
//     originalInventoryNum: number, // 1,
//     moveNum: number, // null,
//     warehouseCode: string, // "wc",
//     warehouseName: string, // "潍柴库",
//     shelfId: string, // null,
//     shelfCode: string, // "A1-11",
//     restoreNum: number, // null,
//     restoreTime: string, // null,
//     restorePerson: string, // null,
//     isRestore: string, // null,
//     id: string, // "0c6d5ff7e2aab68486e59ce664c99017",
//     createTime: string, // null,
//     updateTime: string, // null,
//     createUser: string, // null,
//     updateUser: string, // null,
//     deleteFlag: string, // null
//     warehouseCode1?: string
//     warehouseName1?: string
//     shelfId1?: string
//     shelfCode1?: string
//     // 入库数量：
//     inventoryQuantity: string
//     // 入库单价：
//     price: string
//     // 入库金额：
//     amount: string
//     // 供应商编号：
//     supplierCode: string
//     // 供应商名称：
//     supplierName: string
//     // 采购单号：
//     purchaseCode: string
//     // 入库日期：
//     settleTime: string
//     // 替换编号：
//     replaceCode: string
//     // 商品编号：
//     insideCode: string
//     // 商品名称：
//     insideName: string
//     inventoryNum: number
//     [key: string]: any
// }

class StockDetailedService {
    //采购明细
    async page(searchVo) {
        const apiUrl = "/ci-storage/stock/details/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    //采购明细统计接口
    async amount(searchVo) {
        const apiUrl = "/ci-storage/stock/details/total/amount";
        return await httpRequest.post(apiUrl, searchVo);
    }
    //移库
    async shift(arr) {
        const apiUrl = `/ci-storage/stock/details/move/shift`;
        // const apiUrl = `/ci-storage/stock/details/shift`;
        return await httpRequest.post(apiUrl, arr);
    }

    //移库
    async shiftOld(arr) {
        const apiUrl = `/ci-storage/purchase/shift`;
        return await httpRequest.post(apiUrl, arr);
    }
    //导出
    async export(searchVo) {
        const apiUrl = `/ci-analyze/stock/export`;
        return await httpRequest.post(apiUrl, searchVo, { timeout: 300000 });
    }

    //移库下载模板
    async downloadTpl() {
        const appPageUrl = "/ci-storage/stock/details/move/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }
    //查询客户最近一次销售价
    async recentSalesPrice(shopId, materialCode, customerCode) {
        const apiUrl = `/ci-storage/sale/recentSalesPrice/${shopId}/${customerCode}/${materialCode}`;
        return await httpRequest.get(apiUrl);
    }
}

export const stockDetailedService = new StockDetailedService();