import React from "react";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import { useRecordModel } from "../recordModel";
import useGlobalModel from "@/erp_subpackage/model/globalModel";


export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useRecordModel();
    const { orgDs } = useGlobalModel(({ orgDs }) => [orgDs]);
    const searchFields = [
        {
            type: "Select",
            placeholder: "所属机构",
            fieldName: "org",
            options: orgDs
        },
        {
            type: "Input",
            placeholder: "创业班组",
            fieldName: "team"
        },
        {
            type: "RangePicker",
            fieldName: "",
            placeholder: ["起始事件", "结束时间"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
    ]


    return (
        <YhPageHeader
            title="答题记录"
            size="small"
            triggerSearch
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData({ pageNum: 1 }, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}