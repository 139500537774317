import React, { useContext, useEffect } from "react";
import { Button, message, Modal } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useWarehouseModel } from "../warehouseModel";
import { DataTable } from "./DataTable";
import { MyContext } from "@/store/myContext";

export const DataBox = () => {
    const { dataSource, selectedRowKeys, searchParam, shopId, setAddWaerVisible } = useWarehouseModel();
    const { state, dispatch, detailsPage } = useContext(MyContext);

    //新增 查看 编辑
    const goPageNav = (mode) => {
        let tabName = {
            edit: "仓库编辑",
        }
        let modeKey = "baseWarehouse/" + mode;

        //动态传入要删除的nav key
        const handleDispatch = (obj = {}) => {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.BaseWarehouseDetails({
                    modeKey,
                    mode,
                    dispatch,
                    rowId: selectedRowKeys[0]
                }),
            })
        }

        if (mode === "edit") {
            const findNav = state.panes.find(item => item.key === modeKey);
            if (findNav) {
                Modal.confirm({
                    title: '温馨提醒！',
                    content: "您还有仓库详情未保存，是否跳转",
                    okText: '跳转未保存页',
                    cancelText: '打开新页签',
                    onOk: () => {
                        handleDispatch()
                    },
                    //打开新页签重新生成
                    onCancel: () => {
                        handleDispatch({ deleteKey: modeKey })
                    }
                });
                return;
            }
            handleDispatch()
        }

    }

    const action = <>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => setAddWaerVisible(true)}
        >新增</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (selectedRowKeys.length !== 1) {
                    message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！")
                    return;
                }
                if (dataSource.find(item => item.id === selectedRowKeys[0]).shopId !== shopId) {
                    message.warning("不可以其它机构的操作");
                    return;
                }
                goPageNav("edit")
            }}
            disabled={searchParam.shopId === "" || (searchParam.shopId && searchParam.shopId !== shopId)}
        >编辑</Button>
    </>;

    return (
        <YhBox title="仓库列表" action={action}>
            <DataTable />
        </YhBox>
    )
}

