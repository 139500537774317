import React from "react";
import { Form, Radio } from "antd";

export const __FormRadio = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            hidden={props.hidden}
            labelCol={props.labelCol}
            valuePropName="checked"
        >
            <Radio disabled={props.disable}>{props.radioName}</Radio>
        </Form.Item>
    )
}