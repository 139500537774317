import React, { useState } from "react";
import { Button, Tooltip, Table, message, Upload } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { YhBut } from "@/erp_subpackage/components/YhButton";
import { usePurchaseRoDetailsModel } from "./purchaseRoDetailModel";
import { formatInt, formatNum,formatIntPoint1 } from "@/erp_subpackage/utils/util";
import { EditableCell, EditableRow } from '@/erp_subpackage/components/YHEditTable/editTable';
import { useGlobalModel } from "@/erp_subpackage/model/globalModel";
import { warehouseService } from "@/erp_subpackage/pages/base/warehouse/warehouseService";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import { bignumber } from '@/utils/util'
import { bigNumber } from "../../../../../utils/util";

export const TableBox = ({ modeKey, mode, rowCode, dispatch }) => {
    const {
        editList, addList, lookList, editFormVal, addFormVal, updateList, setMaterialDialog, removeMaterial
    } = usePurchaseRoDetailsModel();
    const { user: { shopId, shopName, shopCode }, whDs, setMaterialInfo } = useGlobalModel(({ user, whDs }) => [user, whDs]);
    const tableList = mode === "edit" ? editList : mode === "look" ? lookList : addList;
    const formVal = mode === "edit" ? editFormVal : addFormVal;
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //货位实时数据
    const [shelfDs, setShelfDs] = useState([]);

    //仓库选择
    const whChange = (value, options) => {
        if (options && options.id) {
            queryShelf(options.value)
        }
    };

    //货位根据仓库code查询
    const queryShelf = async (whCode) => {
        const searchParams = {
            shopId, shopName, shopCode,
            warehouseCode: whCode,
            pageSize: -1,
            pageNum: 1,
        }
        let { retData } = await warehouseService.shelfPage(searchParams);
        const { records, ...pg } = retData;
        setShelfDs(records.filter(item => item.status).map(item => ({
            id: item.id,
            label: item.code,
            value: item.code
        })))
    }

    //货位触焦 
    const shelfFocus = (record) => {
        if (record["warehouseCode"]) {
            queryShelf(record["warehouseCode"])
        } else {
            setShelfDs([]);
        }
    }

    //根据状态控制页面是否可操作
    const controlEditable = () => {
        if (mode === "add") return true;
        if (mode === "look") return false;
        if (mode === "edit" && (editFormVal.status === DictCodeEnmu.PRO_SETTLE || editFormVal.status === DictCodeEnmu.PRO_BILL || editFormVal.status === DictCodeEnmu.PRO_STOCK_IO)) return false;
        return true;
    }

    const action = controlEditable() ? <>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (!formVal.supplierCode) {
                    message.warning("请选择退货的供应商后进行商品操作");
                    return;
                }
                if (!formVal.bentityCode) {
                    message.warning("请选择采购实体后进行商品操作");
                    return;
                }
                setMaterialDialog(true)
            }}
        >新增商品</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (!selectedRowKeys.length) {
                    message.warning("请选择需要批量的数据")
                    return;
                }
                removeMaterial(mode, selectedRowKeys);
                setSelectedRowKeys([])
            }}
        >批量删除</Button>
    </> : null



    const columns = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text,record) => <YhTooltip text={text} onClick={(e) => { e.stopPropagation(); setMaterialInfo(true, { materialCode: text, materialName: record.materialName })}}/>,
           // render: (text, record, index) => <Tooltip placement="topLeft" title={text}><p className="one-line click-span" onClick={(e) => { e.stopPropagation(); setMaterialInfo(true, { materialCode: text, materialName: record.materialName }) }}>{text}</p></Tooltip>,
        }, {
            title: '商品名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text) => <YhTooltip text={text} />,
            //render: (text, record) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text} {record.icon === 1 && <span style={{ background: "orange", color: "white" }}>多</span>} </p></Tooltip>,
        }, {
            title: '单位',
            width: 50,
            dataIndex: 'unitName',
        }, {
            title: '采购数量',
            width: 100,
            dataIndex: 'purchaseNum',
        }, {
            title: '采购单价',
            width: 100,
            dataIndex: 'purchasePrice',
            render: text => formatNum(text)
        }, {
            title: '退货数量',
            width: 100,
            dataIndex: 'num',
            editable: controlEditable(),
        }, {
            title: '退货单价',
            width: 100,
            dataIndex: 'price',
            editable: controlEditable(),
            render: text => formatNum(text)
        }, {
            title: '退货金额',
            width: 100,
            dataIndex: 'amount',
            render: text => formatNum(text)
        }, {
            title: '已退货数量',
            width: 100,
            dataIndex: 'preturnNum',
        }, {
            title: '品牌',
            width: 100,
            dataIndex: 'brandName',
        }, {
            title: '可用库存',
            width: 100,
            dataIndex: 'inventoryNum',
        }, {
            title: '采购单号',
            width: 100,
            dataIndex: 'purchaseCode',
        }, {
            title: '仓库',
            width: 150,
            dataIndex: 'warehouseName',
            // dataIndex: 'warehouseCode',
            // editable: controlEditable(),
            // editable: true,
            // editType: "select",
            // onSelectChange: whChange,
            // options: whDs,
            // render: text => whDs.find(item => item.value === text)?.label ?? "请选择仓库",
        }, {
            title: '货位',
            width: 150,
            dataIndex: 'shelfCode',
            // editable: true,
            // editType: "select",
            // onSelectFocus: shelfFocus,
            // options: shelfDs,
            // render: (text) => text ?? "请选择货位",
        }, {
            title: '备注',
            width: 120,
            dataIndex: 'note',
            editable: controlEditable(),
        }, {
            title: '操作',
            width: 50,
            fixed: "right",
            align: "center",
            render: (text, record) =>
                controlEditable() && <YhBut
                    type="delete"
                    txt="删除"
                    click={() => removeMaterial(mode, [record.key])}
                />
        }
    ];

    const newColumn = columns.map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                editable: col?.editable,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    });

    //内联编辑
    const handleSave = (row) => {
        row.preturnNum = +formatInt(row.preturnNum);
        row.purchaseNum = +formatNum(row.purchaseNum);
        // row.num = +formatInt(row.num, (+row.purchaseNum - +row.preturnNum), "退货数量>=采购数量-已退货数量");
        row.num = +formatNum(row.num);
        if(row.num>(+row.purchaseNum - +row.preturnNum))
        {
            return message.warning("退货数量不能大于【采购数量-已退数量】");

        }
        if(row.num>row.inventoryNum)
        {
            return message.warning("退货数量不能大于【可用库存】");

        }
        //采购单价
        row.purchasePrice = +formatNum(row.purchasePrice);
        //退货单价
        let price = +formatNum(row.price);
        if (price > row.purchasePrice) {
            message.warning("退货价格不能高于采购价格");
            row.price = row.purchasePrice;
        } else {
            row.price = price;
        }
        //退货金额 = 退货数量 * 退货单价
        // row.amount = formatNum(row.num * row.price);
        row.amount = +bigNumber.times(Number(row.num),Number(row.price)).toFixed(2)

        let dataSource = [...tableList];
        const index = dataSource.findIndex((item) => row.key === item.key);
        const item = dataSource[index];
        dataSource.splice(index, 1, { ...item, ...row });
        updateList(mode, dataSource);
    }

    return (
        <YhBox title={<span style={{ paddingLeft: "20px" }}>商品信息</span>} rightChildren={action}>
            <Table
                rowClassName='yh-editable-row yh-edit-table-tr'
                rowSelection={{
                    selectedRowKeys: selectedRowKeys, //记录选中
                    onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRowKeys(selectedRowKeys);
                    }
                }}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newColumn}
                dataSource={tableList}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}

