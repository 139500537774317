import React, { useContext, useEffect } from "react"
import { Table, Input, Modal, Image } from "antd"
import { useProductModel } from "../productModel"
import { YhTag } from "@/erp_subpackage/components/YhTag"
import { MyContext } from "@/store/myContext"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
const { TextArea } = Input

export function DataTable() {
    const { selectedRowKeys, dataSource, pgCfg, loading, productDown, loadData, setSelectedRowKeys, getOrg } = useProductModel()
    const {state, dispatch, detailsPage} = useContext(MyContext)
    const { getTreeNodeName } = useDictModel()

    useEffect(() => {
        loadData()
        getOrg()
    },[])
    // 下架
    const onDown = (record) => {
        let downList = []
        downList.push(record)
        let reason = ""
        Modal.confirm({
            title: "商品下架",
            content: <><span>请输入下架原因</span>
                <TextArea onChange={(e) => { reason = e.target.value }} /></>,
            okText: "确定",
            onOk: () => {
                let newData = downList.map((item) =>{
                    return {
                        ...item,
                        upStatus:DictCodeEnmu.DISPLAY_STATUS_WAITING,
                        reason,
                        typeCode:''
                    }
                })
                productDown(newData)
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }

    // 上架
    const onUp = (mode, list) =>{
        let goodsData = []
        goodsData.push(list)
        let tabName = {
            one: "商品上架",
        }
        let modeKey = "product/" + mode

        //动态传入要删除的nav key
        function handleDispatch(obj = {}) {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.ProductDetails({
                    dispatch,
                    modeKey,
                    mode,
                    goodList: goodsData
                }),
            })
        }
        handleDispatch()
    }

    const onLook = (mode, code) => {
        let tabName = {
            look: "明细查看",
        }
        let modeKey = "product/" + mode

        //动态传入要删除的nav key
        function handleDispatch(obj = {}) {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.ProductGoodsDetails({
                    dispatch,
                    modeKey,
                    mode,
                    rowId: code,
                    type:'on'
                }),
            })
        }
        if (mode === "look") {
            handleDispatch({ deleteKey: modeKey })
        }
    }
    const columns = [
        {
            title: "序号",
            width: 80,
            align: "center",
            dataIndex: "branch",
            fixed: "left",
            render: (text, record, index) => index + 1,
        },
        {
            title: "上架状态",
            width: 100,
            dataIndex: "upStatus",
            render:(text) => <YhTag status={ text } text={getTreeNodeName(text)} />,
        },
        {
            title: "商品图片",
            width: 160,
            dataIndex: "photo",
            render: text => text.length ? <Image src={text[0]?.photoMd5} style={{ width: "40px", height: "40px", marginRight: "10px" }} /> : ""
        },
        {
            title: "商品编号",
            width: 160,
            dataIndex: "code",
            render: (text, record) => <p className="one-line click-span">{text}</p>
        },
        {
            title: "商品名称",
            width: 160,
            dataIndex: "name",
        },
        {
            title: "品牌",
            width: 120,
            dataIndex: "brandName",
        },
        {
            title: "所属分类",
            width: 120,
            dataIndex: "typeName",
            render: (text, record) => <p className="one-line">{text}</p>
        },
        {
            title: "上架价格",
            width: 120,
            dataIndex: "upPrice",
        },
        {
            title: "上架库存",
            width: 120,
            dataIndex: "upNum",
        },
        {
            title: "可用库存",
            width: 120,
            dataIndex: "availableStock",
        },
        {
            title: "商城价格",
            width: 120,
            dataIndex: "shopPrice",
        },
        {
            title: "最新进价",
            width: 120,
            dataIndex: "latestPrice",
        },
        {
            title: "操作",
            align: "160",
            dataIndex: "enable",
            render: (text, record) => (
                <div>
                    <YhTag
                        onClick={record.upStatus === DictCodeEnmu.DISPLAY_STATUS_ALREADY ? () => onDown(record) : () => onUp('one',record)}
                        color={record.upStatus === DictCodeEnmu.DISPLAY_STATUS_ALREADY ? "blue" : "green"}
                        text={record.upStatus === DictCodeEnmu.DISPLAY_STATUS_ALREADY ? "下架" : "上架"}
                    ></YhTag>
                    <YhTag
                        onClick={() => onLook('look', record.code)}
                        color="red"
                        text="查看明细"
                    ></YhTag>
                </div>
            ),
        },
    ]

    //点击行选高亮
    const onRow = (record) => {
        let findObj = selectedRowKeys.find(item => item.id === record.id)
        if (findObj) {
            let newSelectedRowKeys = selectedRowKeys.filter(item => item.id !== record.id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            setSelectedRowKeys(origin => [...origin, record]);
        }
    }

    return (
        <Table
            rowKey={(record) => record.id}
            loading={loading}
            rowSelection={{
                type: "checkbox",
                //全部选中时  (存string[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRows])
                        : setSelectedRowKeys((origin) => {
                            let changeRowId = changeRows.map(item => item.id);
                            return origin.filter(item => changeRowId.indexOf(item.id) < 0)
                        });
                },
                onSelect: (record, selected, selectedRows) => onRow(record),
                selectedRowKeys: selectedRowKeys.map(item => item.id)
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                },
            }}
            onRow={(record) => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault()
                    setSelectedRowKeys([record])
                },
            })}
            scroll={dataSource.length ? { x: "max-content", y: "calc(100vh - 430px)" } : { x: "max-content" }}
        />
    )
}
