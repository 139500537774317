import React, { useState, useEffect, useImperativeHandle } from "react"
import { Table, message } from "antd"
import { getPcodeDict } from "@/utils/dataStorage"
import SanbaoPopup from "./sanbaoPopup"


//三包项目
export default ({ sanBaoList , returnOrderInfo }) => {
    const [data, setData] = useState([]) //表格数据
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [dictObj, setDictObj] = useState({
        threeGuaranteesUnit: {},
    })
    useEffect(() => {
        setData(sanBaoList)
    }, [sanBaoList])
    useEffect(() => {
        initDict()
    },[])
     //点开弹框初始下页面需要的字典数据
     const initDict = () => {
        let threeGuaranteesUnit = {}
        //三包品牌
        getPcodeDict("106").children.forEach((item) => {
            threeGuaranteesUnit[item.dictCode] = item.title
        })
        setDictObj({
            threeGuaranteesUnit: threeGuaranteesUnit,
        })
    }

    const [popupMode, setPopupMode] = useState("")
    const [visible, setVisible] = useState(false) //弹框

    //table配置
    const columns = [
        {
            title: "三包品牌",
            dataIndex: "threeGuaranteesBrand",
            editable: true,
            render: (text) => (
                <span
                    style={{ color: "#2e3aeb", cursor: "pointer" }}
                    onClick={() => {
                        setPopupMode("edit")
                        setVisible(true)
                    }}
                >
                    {dictObj.threeGuaranteesUnit[text]}
                </span>
            ),
        },
        {
            title: "维修人员",
            dataIndex: "maintenanceStaff",
            editable: true,
        },
        {
            title: "维修时间",
            dataIndex: "repairTime",
            width: 220,
            editable: true,
        },

        {
            title: "索赔单号",
            dataIndex: "claimNumber",
            editable: true,
        },
        {
            title: "故障处理",
            dataIndex: "faultHandle",
            editable: true,
        },
        {
            title: "材料费",
            dataIndex: "materialAmount",
            editable: true,
        },
        {
            title: "出库金额",
            dataIndex: "outboundAmount",
        },
        {
            title: "工时费",
            dataIndex: "hourlyWage",
            editable: true,
        },
        {
            title: "外出费",
            dataIndex: "travelExpenses",
            editable: true,
        },
        {
            title: "餐补费",
            dataIndex: "mealSupplement",
            editable: true,
        },
        {
            title: "其他费用",
            dataIndex: "otherAmount",
            editable: true,
        },
        {
            title: "索赔总额",
            dataIndex: "totalClaim",
        },
        {
            title: "结算单号",
            dataIndex: "statementNumber",
            editable: true,
        },
        {
            title: "三包工时费汇总",
            dataIndex: "totalHourlyWage",
        },
    ]



    return (
        <div className="list-container" style={{ marginBottom: "100px" }}>
            <div className="list-header">
                <div className="list-name">
                    <span>三包信息</span>
                    <span className="total-price">
                       ({data.length}项){" "}
                    </span>
                </div>
            </div>
            <Table
                size="small"
                rowKey={(record) => record.id || record.time}
                dataSource={data}
                pagination={false}
                scroll={{ x: 2000, y: 260 }}
                columns={columns}
                onRow={(record, index) => ({
                    onClick: () =>
                        setSelectInfo({
                            rowInfo: record,
                            rowIndex: index,
                        }),
                })}
            />
             {visible && (
                <SanbaoPopup
                    rowInfo={selectInfo.rowInfo}
                    visible={visible}
                    returnOrderInfo={returnOrderInfo}
                    setVisible={setVisible}
                />
            )}
        </div>
    )
}
