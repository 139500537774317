import React from "react";
import { useSaleSoDetailModel } from "./saleSoDetailModal";
import { Table, message } from "antd";
import YHDrawer from "@/erp_subpackage/components/YhDrawer";
import { useGlobalModel, transPgToPagination } from "@/erp_subpackage/model/globalModel";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

export const WhConfirmDrawer= ({ modeKey, mode, rowId, dispatch}) => {
    const {detailLookForm,detailAddForm,detailEditForm, wsConfirmModal: visible, setWsConfirmModal, detailDataSource, detailLookList, warehousing, getDetailEditOne } = useSaleSoDetailModel();

    const onCancel = () => {
        setWsConfirmModal(false);
    };
    const { user: { shopId, shopName, shopCode }, whDs, setMaterialInfo } = useGlobalModel(({ user, whDs }) => [user, whDs]);
    const detailForm= mode === "add" ? detailAddForm :  mode === "edit" ?detailEditForm:detailLookForm
    const columns= [
        {
            title: '序号',
            width: 80,
            dataIndex: "index",
            render: (t, r, i) => <>{i + 1}</>
        },
        {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName,customerName:detailForm.customerName,customerCode:detailForm.customerCode})} />,
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
        }, {
            title: '出库数量',
            width: 160,
            dataIndex: 'num',
        }
    ];

    const onSubmit = async () => {
        let retData = await warehousing()
        if (retData) {
            await onCancel();
            await getDetailEditOne(rowId, mode);
            await message.success("出库成功")
        } else {
            await onCancel()
            await message.success("出库失败")
        }
    }
    return (
        <YHDrawer
            {...{
                title: '库房确认',
                visible,
                onClose: onCancel,
                width: 800,
                okText: "保存",
                onOk: onSubmit,
                showFooter: mode === "look" ? false : true

            }}
        >
            <Table
                // size="small"
                rowKey={record => record.id}
                columns={columns}
                dataSource={mode == "edit" ? detailDataSource : detailLookList}
                pagination={false}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 400 }}
            />
        </YHDrawer>
    )
}