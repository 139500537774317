import React, { FC } from "react";
import { Form, Select, Tooltip } from "antd";

export const __FormSelect = ({ placeholder, fieldLabel, fieldName, selectMode, initWidth, ...props }) => {
    const title = <span style={{ cursor: "pointer" }} onClick={(e) => {
        e.stopPropagation();
        if (props.copyText) {
            props.copyText(fieldName, props.options)
        }
    }}>复制</span>
    return (
        <Form.Item
            label={<Tooltip title={title}>{fieldLabel}</Tooltip>}
            name={fieldName}
            rules={props.rules}
            labelCol={props.labelCol}
        >
            <Select
                mode={selectMode}
                // maxTagCount={1}
                placeholder={placeholder ? placeholder : typeof fieldLabel === "string" ? fieldLabel : ""}
                showSearch={props.showSearch ?? true}
                optionFilterProp="label"
                style={{ width: initWidth ?? "100%" }}
                allowClear={props.allowClear}
                options={props.options}
                disabled={props.disable}
                onChange={(value, option) => props.onSelectChange?.(value, option)}
                onFocus={(value, option) => props.onSelectFocus?.(value, option)}
                onSearch={(value) => props.onSelectSearch?.(value)}
            />
        </Form.Item>
    )
}