import React, { useEffect, useState } from "react"
import { Modal, Button, Input, message, Radio } from "antd"
import { orderPrintInfo, printNum, getOrderOne } from "@/service/ordersService"
import "./orderPrint.css"
import { getUserInfo, getPcodeDict } from "@/utils/dataStorage"
import { numberParseChina } from "@/utils/util"
import moment from "moment"
import {ScaniaPrint} from "../../../../erp_subpackage/components/ScaniaPrint"
import { prebookService } from "../prebookService";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"

const ScaniaPrints = (props) => {
    const { visible,type, onClose } = props
    const [otherList, setOtherList] = useState([])
    const [baseInfo, setBaseInfo] = useState({})
    const [dictObj, setDictObj] = useState({ serviceType: {}, orderSource: {}, orderTypes: {} })
    const [serviceNature, setServiceNature] = useState("")
    const [titles, setTieles] = useState("")
    const [price, setPrice] = useState({})
    const [formVal, setFormVal] = useState({});
    const [maintenanceList, setMaintenanceList] = useState([]);
    const [itemList, setItemList] = useState([]);
    useEffect(() => {
        initDict()
    }, [])
    useEffect(() => {
        if(dictObj.threeGuaranteesUnit){
        loadData()
    }
    }, [dictObj])
    const loadData = () => {
        prebookService.one(visible.orderId).then((res) => {
            setFormVal({...res.retData,
                customerName:res.retData.customerName,
                licensePlate:res.retData.vehicleCode,
                vin:res.retData.vehicleVin,
                contact:res.retData.senderRepairUserName,
                phone:res.retData.senderRepairUserPhone,
                serviceTypeName:dictObj.serviceType[res.retData.serviceType],
                vehicleTypeName:res.retData.vehicleType,
                customerAddress:res.retData.repairAddress,
                maintenanceCode:res.retData.prebookCode,
                enterTime:res.retData.entryFactoryTime,
                deliveryTime:res.retData.outFactoryTime,
                createUserName:res.retData.createUser,
                mileage:res.retData.mileage,
                faultDesc:res.retData.faultDesc
            })
            const { entityName, orderType: orderTypes } = res.retData
            if (orderTypes && orderTypes === "014001") {
                //收费
                setServiceNature("016002")
            } else if (orderTypes && orderTypes === "014002") {
                // 三包
                setServiceNature("016001")
            }else if (orderTypes && orderTypes === "014006") {
                // 云豪三包
                setServiceNature("016004")
            }
            setTieles(entityName)
            // 016001 三包
            // 016002 收费
            setMaintenanceList(res.retData.materials)
            setItemList(res.retData.items)

            let preferentialAmount = 0;
            let receivableAmount = 0;
            let itemAmount = 0;
            res.retData.items.forEach(e => {
                itemAmount = (Number(itemAmount) + Number(e.actualAmount)).toFixed(2);
                preferentialAmount = (Number(preferentialAmount) + Number(e.preferentialAmount)).toFixed(2);
                receivableAmount = (Number(receivableAmount) + Number(e.actualAmount)).toFixed(2);
            });
            let materialAmount = 0;
            res.retData.materials.forEach(e => {
                materialAmount = (Number(materialAmount) + Number(e.actualAmount)).toFixed(2);
                preferentialAmount = (Number(preferentialAmount) + Number(e.preferentialAmount)).toFixed(2);
                receivableAmount = (Number(receivableAmount) + Number(e.actualAmount)).toFixed(2);
            });
            setPrice({
                workingHoursAmount:itemAmount,
                materialScienceAmount:materialAmount,
                orderTotalAmount:(Number(itemAmount) + Number(materialAmount)).toFixed(2),
                orderPreferentialAmount:preferentialAmount,
                totalAmount:receivableAmount,
            })
        })
    }

    //初始下订单用到的字典
    const initDict = () => {
        let obj = {
            serviceType: {}, //服务类型
            orderSource: {}, //支付方式
            orderTypes: {},
            threeGuaranteesUnit: {},
        }
        getPcodeDict("011").children.forEach((item) => {
            obj.serviceType[item.dictCode] = item.title
        })
        getPcodeDict("018").children.forEach((item) => {
            obj.orderSource[item.dictCode] = item.title
        })
        getPcodeDict("014").children.forEach((item) => {
            obj.orderTypes[item.dictCode] = item.title
        })
        getPcodeDict("106").children.forEach((item) => {
            obj.threeGuaranteesUnit[item.dictCode] = item.title
        })

        setDictObj(obj)
    }
    const itemClumns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '项目编号',
            dataIndex: 'itemCode',
        },
        {
            title: '项目名称',
            dataIndex: 'itemName',
        },
        {
            title: '工时',
            dataIndex: 'itemWorkHours',
        },
        type === 1 ? 
        {
            title: '单价',
            dataIndex: 'workHoursPrice',
        }: {
            title: '单价',
            dataIndex: 'workHoursPrice',
            render: (text) => 0
        },
        type === 1 ?
        {
            title: '优惠',
            dataIndex: 'preferentialAmount',
        }: {
            title: '优惠',
            dataIndex: 'preferentialAmount',
            render: (text) => 0
        },
        type === 1 ?
        {
            title: '折扣',
            dataIndex: 'itemDiscount',
        }: {
            title: '折扣',
            dataIndex: 'itemDiscount',
            render: (text) => 0
        },
        type === 1 ?
        {
            title: '总金额',
            dataIndex: 'actualAmount',
        }: {
            title: '总金额',
            dataIndex: 'actualAmount',
            render: (text) => 0
        },
        {
            title: '备注',
            dataIndex: 'remark',
            colSpan:2
        },
    ]
    const columns =[
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '商品编号',
            dataIndex: 'materialCode',
        },
        {
            title: '商品名称',
            dataIndex: 'materialName',
        },
        {
            title: '数量',
            dataIndex: 'materialNumber',
        },
        type === 1 ?
        {
            title: '单价',
            dataIndex: 'salePrice',
        }: {
            title: '单价',
            dataIndex: 'salePrice',
            render: (text) => 0
        },
        type === 1 ?
        {
            title: '优惠',
            dataIndex: 'preferentialAmount',
        }: {
            title: '优惠',
            dataIndex: 'preferentialAmount',
            render: (text) => 0
        },
        type === 1 ?
        {
            title: '折扣',
            dataIndex: 'materialDiscount',
        }: {
            title: '折扣',
            dataIndex: 'materialDiscount',
            render: (text) => 0
        },
        type === 1 ?
        {
            title: '总金额',
            dataIndex: 'actualAmount',
        }: {
            title: '总金额',
            dataIndex: 'actualAmount',
            render: (text) => 0
        },
        {
            title: '备注',
            dataIndex: 'remark',
        },
    ]
    const otherClumns = [
            {
                title: '序号',
                dataIndex: 'No',
            },
            {
                title: '项目编码',
                dataIndex: 'itemCode',
            },
            {
                title: '项目名称',
                dataIndex: 'itemName',
            },
            {
                title: '数量',
                dataIndex: 'itemNumber',
            },
            type === 1 ?
            {
                title: '单价',
                dataIndex: 'unitPrice',
            }: {
                title: '单价',
                dataIndex: 'unitPrice',
                render: (text) => 0
            },
            type === 1 ?
            {
                title: '金额',
                dataIndex: 'itemAmount',
            }: {
                title: '金额',
                dataIndex: 'itemAmount',
                render: (text) => 0
            },
            {
                title: '备注',
                dataIndex: 'remark',
                colSpan:2
            },
        ]
    //转换number，保留两个0
    const switchNum = (num, n = 2) => {
        return Number(num).toFixed(n)
    }
    return (
        <ScaniaPrint
        title={titles}
        visible={visible}
        onClose={onClose}
        columns={columns}
        itemClumns={itemClumns}
        otherClumns={otherClumns}
        itemDataSource={itemList}
        dataSource={maintenanceList.filter(item => item.num !== 0)}
        otherDataSource={otherList}
        formVal={formVal}
        isTechnician={true}
        baseInfo={baseInfo}
        price={price}
        // printNum={visible.printNum}
        type={visible.type}
    />
    )
}

export default ScaniaPrints
