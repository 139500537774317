import React, { useState, useEffect, useRef, useContext } from "react"
import { Form, Input, Select, InputNumber } from "antd"
import moment from "moment"
import { attrFileTypeObj, requiredObj, disableFlagObj } from "./emun"
const { Option } = Select
const EditableContext = React.createContext()
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm()
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    )
}

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
    const [editing, setEditing] = useState(false)
    const [entityData, setEntityData] = useState({
        //所属实体信息
        data: [],
        fetching: false,
    })
    const inputRef = useRef()
    const form = useContext(EditableContext)
    useEffect(() => {
        if (editing) {
            if (inputRef && inputRef.current) {
                inputRef.current.focus()
            }
        }
    }, [editing])

    const toggleEdit = () => {
        setEditing(!editing)
        if (dataIndex === "repairTime") {
            form.setFieldsValue({
                [dataIndex]: moment(record[dataIndex] || new Date()),
            })
        } else {
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            })
        }
    }

    const save = async (e, option, str) => {
        try {
            const values = await form.validateFields()
            toggleEdit()
            handleSave({ ...record, ...values })
        } catch (errInfo) {
            console.log("数据保存失败", errInfo)
        }
    }

    const inputTypes = (str, record) => {
        if (str === "attrFileType") {
            return (
                <Select
                    ref={inputRef}
                    onChange={(e, option) => {
                        save(e, option, str)
                    }}
                    onBlur={save}
                >
                    {Object.entries(attrFileTypeObj).map((item) => (
                        <Option value={item[0]} key={item[0]}>
                            {item[1]}
                        </Option>
                    ))}
                </Select>
            )
        } else if (str === "required") {
            return (
                <Select
                    ref={inputRef}
                    onChange={(e, option) => {
                        save(e, option, str)
                    }}
                    onBlur={save}
                >
                    {Object.entries(requiredObj).map((item) => (
                        <Option value={Number(item[0])} key={Number(item[0])}>
                            {item[1]}
                        </Option>
                    ))}
                </Select>
            )
        } else if (str === "disableFlag") {
            return (
                <Select
                    ref={inputRef}
                    onChange={(e, option) => {
                        save(e, option, str)
                    }}
                    onBlur={save}
                >
                    {Object.entries(disableFlagObj).map((item) => (
                        <Option value={Number(item[0])} key={Number(item[0])}>
                            {item[1]}
                        </Option>
                    ))}
                </Select>
            )
        } else if (str === "componentSort") {
            return <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />
        } else {
            return <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        }
    }
    const inputRules = (str) => {
        if (str === "attrName") {
            return [
                {
                    required: true,
                    message: `数值不可为空`,
                },
            ]
        }
    }
    let childNode = children
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={inputRules(dataIndex)}
            >
                {inputTypes(dataIndex, record)}
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                {children}
            </div>
        )
    }

    return <td {...restProps}>{childNode}</td>
}

export { EditableRow, EditableCell }
