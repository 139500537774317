import React, { useEffect } from "react"
import Drawer from "@/components/Drawer"
import { Form, Input, Radio, Button, message, Modal, Table } from "antd"
import { pointEdit } from "@/service/pointService.js"

//用户积分修改
export default ({
    visible,
    onClose,
    userPointList, //要修改积分的用户列表
    pointLoadData,
    setSelectPoint, //修改成功后置空选中
}) => {
    const [form] = Form.useForm()

    useEffect(() => {
        !visible && form.resetFields()
    }, [visible])

    const onSubmit = () => {
        form.validateFields().then((res) => {
            let data = {
                ...res,
                users: userPointList.map((item) => ({
                    userId: item.userId,
                    pointsId: item.id,
                })),
            }
            pointEdit(data)
                .then((res) => {
                    // if (res && res.retCode === 200) {
                        message.success("修改成功")
                        onClose()
                        pointLoadData(1)
                        setSelectPoint()
                    // } else {
                    //     message.warning(res.retMsg)
                    // }
                })
                // .catch((err) => message.error(err.retMsg))
        })
    }
    const columns = [
        {
            title: "用户昵称",
            align: "center",
            dataIndex: "userName",
        },
        {
            title: "手机号",
            align: "center",
            dataIndex: "userPhone",
        },
        {
            title: "剩余积分",
            align: "center",
            dataIndex: "remainingPoints",
        },
    ]
    //作废工单
    const lookUserInfo = () => {
        Modal.info({
            icon: null,
            title: "已选的用户积分信息",
            content: (
                <div
                    style={{
                        borderRadius: "10px",
                        boxShadow: " 0px 5px 12px 3px rgba(33, 33, 33, 0.1)",
                    }}
                >
                    <Table size="small" rowKey={(record) => record.id} columns={columns} dataSource={userPointList} pagination={false} scroll={{ y: 350 }} />
                </div>
            ),
            okText: "确定",
            cancelText: "取消",
            onOk: () => {},
            onCancel: () => {},
        })
    }
    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
    }
    return (
        <Drawer visible={visible} onClose={() => onClose(false)}>
            <div className="team-drawer-title">
                <span>修改积分</span>
            </div>
            <div className="team-drawer-content" style={{ padding: "10px" }}>
                <Form form={form} {...formItemLayout} initialValues={{ ["changeType"]: 0 }}>
                    {userPointList && userPointList.length === 1 ? (
                        <>
                            <Form.Item label="用户昵称">
                                <span className="ant-form-text">{userPointList[0]?.userName}</span>
                            </Form.Item>
                            <Form.Item label="手机号">
                                <span className="ant-form-text">{userPointList[0]?.userPhone}</span>
                                <span
                                    className="ant-form-text"
                                    style={{
                                        color: "#f00",
                                        marginLeft: "100px",
                                    }}
                                >
                                    积分剩余:{userPointList[0]?.remainingPoints}
                                </span>
                            </Form.Item>
                        </>
                    ) : (
                        <Form.Item label="用户昵称">
                            <span className="ant-form-text">
                                已选择
                                <span style={{ color: "00f" }}>{userPointList.length}</span>
                                用户
                            </span>
                            <span className="ant-form-text" style={{ color: "#00f", marginLeft: "100px" }} onClick={lookUserInfo}>
                                查看
                            </span>
                        </Form.Item>
                    )}
                    <Form.Item name="changeType" label="选择">
                        <Radio.Group>
                            <Radio value={0}>加分</Radio>
                            <Radio value={1}>减分</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="changePoints"
                        label="积分数值"
                        rules={[
                            { required: true, message: "请输入积分数值" },
                            { pattern: /^[0-9]*$/, message: "请输入数值类型" },
                        ]}
                    >
                        <Input placeholder="积分数值" />
                    </Form.Item>
                    <Form.Item name="changeDesc" label="变更原因">
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 10 }} placeholder="请输入变更原因" />
                    </Form.Item>
                </Form>
                <div className="team-form-footer">
                    <Button onClick={() => onClose()} className="team-but-one">
                        取消
                    </Button>
                    <Button className="team-but-two" onClick={onSubmit}>
                        提交
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}
