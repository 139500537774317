import React from "react";
import { Form, Tooltip } from "antd";
import YhDebounceSelect from "../../YhDounceSelect/index";

export const __FormAsyncSelect = ({ placeholder, fieldLabel, fieldName, selectMode, initWidth, defaultQuery, ...props }) => {

	function fn() {
		let arr = [];
		return Promise.resolve(arr)
	}
	const title = <span style={{ cursor: "pointer" }} onClick={(e) => {
		e.stopPropagation();
		if (props.copyText) {
			props.copyText(fieldName)
		}
	}}>复制</span>
	return (
		<Form.Item
			label={<Tooltip title={title}>{fieldLabel}</Tooltip>}
			name={fieldName}
			rules={props.rules}
			labelCol={props.labelCol}
		>
			<YhDebounceSelect
				showSearch
				disabled={props.disable}
				placeholder={placeholder ? placeholder : typeof fieldLabel === "string" ? fieldLabel : ""}
				onChange={(value, option) => props.onSelectChange?.(value, option)}
				style={{ width: '100%' }}
				fetchOptions={props?.selectfetchList ?? fn}
				defaultQuery={defaultQuery}
				isFmsShop={props.isFmsShop}
			/>
		</Form.Item>
	)
}