import React, { useState, useEffect } from 'react'
import { Input, Modal, Form, Col, Row, Select, Switch, Spin, message } from 'antd'
import { getCustomerOneNew, getCustomerListNew, platformUserList } from '@/service/customerService'
import { getOrganCurrentUser } from '@/service/orgService'
import { getTeamList } from '@/service/teamService'
import { orderEditCustomerUser } from '@/service/ordersService'
import { getPcodeDict } from '@/utils/dataStorage'
import debounce from 'lodash/debounce'
import { organPage } from '@/service/orgService'
const { Option } = Select
const OrderCustomerEdit = (props) => {
    const { visible, onCancel, orderDetailsInfo, orderVehicleInfo, handleSuccess } = props
    const [organList, setOrganList] = useState({ data: [], fetching: false })
    const [form] = Form.useForm()
    const [teamData, setTeamData] = useState({
        //团队信息
        data: [],
        fetching: false,
    })
    const [customerInfo, setCustomerInfo] = useState({
        //客户列表
        data: [],
        fetching: false,
    })

    //所属经理
    const [businessManager, setBusinessManager] = useState({
        data: [],
        fetching: false,
    })

    const [names, setNames] = useState('')
    useEffect(() => {
        // console.log(orderDetailsInfo, orderVehicleInfo);
        resetFormItem()
    }, [])

    // 设置默认业务经理
    const setDefultbusinessManager = async () => {
        const data = await getOrganCurrentUser()
        const { defBusinessManager, defBusinessManagerName } = data.retData
        if (defBusinessManager) {
            return { text: defBusinessManagerName, value: defBusinessManager }
        } else {
            return null
        }
    }

    //回显表单
    const resetFormItem = async (handleChangeCustomerId = '') => {
        // console.log(orderDetailsInfo)
        let defaultbusiness = await setDefultbusinessManager()
        getCustomerOneNew(handleChangeCustomerId ? handleChangeCustomerId : orderDetailsInfo.customerId).then((res) => {
            // if (res && res.retCode === 200) {
            let retData = res.retData
            let { businessManager } = retData
            if (!businessManager) {
                setBusinessManager({
                    data: defaultbusiness ? [defaultbusiness] : [],
                    fetching: false,
                })
                businessManager = defaultbusiness ? defaultbusiness.value : null
            } else {
                setBusinessManager({
                    data: [{ text: retData.businessManagerName, value: retData.businessManager }],
                    fetching: false,
                })
            }
            form.setFieldsValue({
                ...retData,
                businessManager,
                customerId: retData.id,
                customerStatus: retData.customerStatus == 0 ? true : false,
                customerType: retData.customerType === '017008' ? '017001' : retData.customerType,
            })
            setTeamData({
                data: [{ text: retData.teamName, value: retData.teamId }],
                fetching: false,
            }) //团队列表回显
            setCustomerInfo({
                data: [{ text: retData.customerName, value: retData.id }],
                fetching: false,
            }) //客户列表回显
            setNames(retData.customerName)
            setOrganList({
                data: [
                    {
                        text: retData.organName,
                        value: retData.organId,
                    },
                ],
                fetching: false,
            })
            // }
        })
    }
    //团队派单信息查询
    const fetchTeam = (value) => {
        setTeamData({
            data: [],
            fetching: true,
        })
        let data = {
            teamName: value ? value : null,
            searchItem: {},
            pageSize: 50,
            pageNum: 1,
        }
        getTeamList(data).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            const dataMap = results.map((team) => ({
                text: team.teamName,
                value: team.id,
            }))
            setTeamData({
                data: dataMap,
                fetching: false,
            })
            // }
        })
    }
    //防抖
    const fetchTeams = debounce(fetchTeam, 1000)
    const handleChangeTeam = (value, option) => {
        form.setFieldsValue({
            teamName: option.children,
        })
    }
    //客户列表查询
    const customerQuery = (value) => {
        setCustomerInfo({
            ...customerInfo,
            fetching: true,
        })
        getCustomerListNew({
            pageSize: 20,
            currentPage: 1,
            searchItem:{
                queryTemporaryCustomer: 1,
                customerName: value,
            }
        }).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                if (item.customerStatus === 0) {
                    arr.push({
                        text: item.customerName,
                        value: item.id,
                    })
                }
                return arr
            }, [])
            setCustomerInfo({
                data: data,
                fetching: false,
            })
            // }
        })
    }

    //每次选取获取客户信息
    const handleChangeName = (value) => {
        value && resetFormItem(value)
    }
    //保存
    const saveEditCustomer = () => {
        form.validateFields().then((value) => {
            let data = {
                ...value,
                orderId: orderDetailsInfo.id,
                // customerName: names,
                customerStatus: value.customerStatus ? 0 : 1,
                customerVehicleId: orderVehicleInfo.key,
                customerUserId: orderDetailsInfo.customerUserId,
            }
            orderEditCustomerUser(data).then((res) => {
                // if (res && res.retCode === 200) {
                handleSuccess({
                    customerName: data.customerName,
                    customerId: data.customerId,
                    customerType: data.customerType,
                })
                onCancel(false)
                message.success('该工单的客户修改完成')
                // } else {
                //     message.error("修改错误，请重新尝试修改")
                // }
            })
            // .catch((err) => {
            //     if (err && err.retCode === 500) {
            //         message.error(err.retMsg)
            //     }
            // })
        })
    }
    //防抖
    const customerQuerys = debounce(customerQuery, 1000)
    //查询机构
    const organQuery = (value) => {
        setOrganList({
            data: [],
            fetching: true,
        })
        let data = {
            searchItem: { organName: value, orderFlag: 0 },
            pageNum: 1,
            pageSize: 10,
            orders: [{ column: 'createTime', rule: 'DESC' }],
        }
        organPage(data).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.map((item) => ({
                    text: item.organName,
                    value: item.id,
                }))
                setOrganList({
                    data: data,
                    fetching: false,
                })
            } else {
                message.warning('没有查询到机构')
            }
            // }
        })
        // .catch((err) => {
        //     if (err) {
        //         message.warning("没有查询到机构")
        //     }
        // })
    }
    //防抖
    const organQuerys = debounce(organQuery, 600)
    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }
    const infoStyle = {
        display: 'flex',
        justifyContent: 'space-evenly',
        fontSize: '16px',
        fontWeight: 'bold',
    }
    //回显
    const handleChangeOrgan = (value, option) => {
        if (option) {
            form.setFieldsValue({
                organId: value,
                organName: option.children,
            })
        }
    }

    const businessManagerQuery = (value, key) => {
        setBusinessManager({
            ...businessManager,
            fetching: true,
        })

        platformUserList({
            searchItem: {
                nickname: value,
            },
            pageNum: 1,
            pageSize: 15,
        }).then((res) => {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.reduce((arr, item) => {
                    arr.push({
                        text: item.nickname,
                        value: item.id,
                    })
                    return arr
                }, [])
                setBusinessManager({
                    data: data,
                    fetching: false,
                })
            } else {
                console.log('没有查询到')
            }
        })
    }
    //防抖
    const businessManagerQuerys = debounce(businessManagerQuery, 1000)
    return (
        <Modal visible={visible} title="客户信息修改" centered={true} onOk={() => saveEditCustomer()} onCancel={() => onCancel(false)} width={1260}>
            <div>
                <Form form={form} {...formItemLayout}>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item
                                label="原有客户"
                                name="customerId"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择客户名称',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="请选择客户"
                                    notFoundContent={customerInfo.fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onChange={handleChangeName}
                                    onFocus={() => customerQuery()}
                                    onSearch={customerQuerys}
                                    style={{ width: '100%' }}
                                >
                                    {customerInfo.data.map((item) => (
                                        <Option value={item.value} key={item.value}>
                                            {item.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="客户修改"
                                name="customerName"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入客户名称',
                                    },
                                ]}
                            >
                                <Input placeholder="请输入客户名称" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="联系人" name="customerCorporation">
                                <Input placeholder="请输入联系人" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="联系电话" name="customerCorporationPhone">
                                <Input placeholder="请输入联系电话" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="传真" name="customerFax">
                                <Input placeholder="请输入传真" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="客户类型"
                                name="customerType"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择客户类型',
                                    },
                                ]}
                            >
                                <Select placeholder="请选择客户类型">
                                    {getPcodeDict('017').children.map((item) => (
                                        <Option value={item.dictCode} key={item.id}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="电子邮箱" name="customerEmail">
                                <Input placeholder="请输入电子邮箱" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="联系地址" name="customerAddress">
                                <Input placeholder="请输入联系地址" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="办公电话" name="customerPhone">
                                <Input placeholder="请输入办公电话" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="邮编" name="customerZipcode">
                                <Input placeholder="请输入邮编" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            {/* <Form.Item label="所属机构" name="customerAgency">
                                <Select showSearch allowClear placeholder="请选择所属机构" style={{ width: "100%" }}>
                                    {getPcodeDict("023").children.map((item) => (
                                        <Option value={item.dictCode} key={item.id}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item> */}
                            <Form.Item label="所属机构" name="organId">
                                <Select
                                    showSearch
                                    placeholder="请选择所属机构"
                                    notFoundContent={organList.fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onFocus={() => organQuery('')}
                                    onSearch={organQuerys}
                                    onSelect={handleChangeOrgan}
                                    style={{ width: '100%' }}
                                >
                                    {organList.data.map((item) => (
                                        <Option value={item.value} key={item.value}>
                                            {item.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="所属团队" name="teamId">
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="请选择创业团队"
                                    notFoundContent={teamData.fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onChange={handleChangeTeam}
                                    onFocus={() => fetchTeam()}
                                    onSearch={fetchTeams}
                                    style={{ width: '100%' }}
                                >
                                    {teamData.data.map((item) => (
                                        <Option value={item.value} key={item.value}>
                                            {item.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户状态" name="customerStatus" valuePropName="checked">
                                <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务经理" name="businessManager">
                                <Select
                                    showSearch
                                    placeholder="请选择业务经理"
                                    notFoundContent={businessManager.fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onFocus={() => businessManagerQuerys('')}
                                    onSearch={businessManagerQuerys}
                                    style={{ width: '100%' }}
                                >
                                    {businessManager.data.map((item) => (
                                        <Option value={item.value} key={item.value}>
                                            {item.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="备注" name="customerDesc" labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                                <Input placeholder="请输入备注" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div style={infoStyle}>
                    <div>用户名：{orderDetailsInfo.customerUserName}</div>
                    <div>
                        车辆牌照：
                        {orderVehicleInfo && orderVehicleInfo.children}
                    </div>
                    <div>电话：{orderDetailsInfo.repairContactPhone}</div>
                </div>
            </div>
        </Modal>
    )
}
export default OrderCustomerEdit
