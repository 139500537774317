import request from "../utils/shopAxios"

/**
 *
 * @param {*} params
 * @returns
 */
export function shopGettoken() {
    return request({
        url: "seller/login/escapeLogin",
        method: "get",
    })
}

/**
 * 获取订单列表
 * @param params
 * @returns {Promise<any>}
 */
export function shopGetOrderList(params) {
    return request({
        url: "seller/trade/orders",
        method: "get",
        params,
    })
}

/**
 * 获取订单统计
 * @param params
 * @returns {Promise<any>}
 */
export function shopGetOrderStatic(params) {
    return request({
        url: "seller/trade/orders/orderStatic",
        method: "get",
        params,
    })
}

/**
 * 获取订单详情
 * @param id
 * @returns {Promise<any>}
 */
export function shopGetOrderDetail(id) {
    return request({
        url: `seller/trade/orders/${id}`,
        method: "get",
    })
}

/**
 * 获取订单详情
 * @returns {Promise<any>}
 */
export function shopLogiCompanies() {
    return request({
        url: `seller/shops/logi-companies `,
        method: "get",
    })
}

/**
 * 订单发货
 * @returns {Promise<any>}
 */
export function shopOrdersDelivery(sn, params) {
    return request({
        url: `seller/trade/orders/${sn}/delivery`,
        method: "post",
        data: params,
    })
}

/**
 * 修改发货人地址
 * @returns {Promise<any>}
 */
export function shopOrdersAddress(sn, params) {
    return request({
        url: `seller/trade/orders/${sn}/address`,
        method: "put",
        data: params,
    })
}

/**
 * 获取商城分类
 */

 export function shopCatsList() {
    return request({
        url: `seller/shops/cats`,
        method: "get",
    })
}

export function shopCatsDatas(id) {
    return request({
        url: `seller/goods/category/${id}/children`,
        method: "get",
    })
}
