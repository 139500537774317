import { post, get } from "../utils/request"

//分页查询
function appointmentList(data) {
    return post(`/ci/appointment/list`, data)
}
//新增
function appointmentInsert(data) {
    return post(`/ci/appointment/insert`, data)
}
//修改
function appointmentUpdate(data) {
    return post(`/ci/appointment/update`, data)
}
//取消
function appointmentCancel(data) {
    return post(`/ci/appointment/cancel`, data)
}
//转接
function appointmentTransfer(data) {
    return post(`/ci/appointment/transfer`, data)
}
//获取一条数据
function appointmentOne(id) {
    return get(`/ci/appointment/one/${id}`)
}
export { appointmentList, appointmentInsert, appointmentUpdate, appointmentCancel, appointmentTransfer, appointmentOne}
