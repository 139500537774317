import React, { useEffect } from "react"
import { Form, Input, Col, Row, Select, message, Modal } from "antd"
import { bussinessEntityInsert, bussinessEntityUpdate, bussinessEntityOne } from "@/service/dataService"
import { getPcodeDict } from "@/utils/dataStorage"

export default (props) => {
    const { mode, rowInfo, visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()

    useEffect(() => {
        queryMotorcade()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            bussinessEntityOne(rowInfo.id).then((res) => {
                let results = res.retData
                form.setFieldsValue({
                    ...results,
                    threeGuarantees: results.threeGuarantees && results.threeGuarantees.split(",")
                })
            })
        }
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                threeGuarantees: values.threeGuarantees.join(",")
            }

            if (mode === "edit") {
                data.id = rowInfo.id
                bussinessEntityUpdate(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                bussinessEntityInsert(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleCancel = () => {
        setVisible("")
    }

    return (
        <>
            <Modal forceRender visible={visible} title="所属实体" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={600}>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Form.Item label="实体名称" name="entityName" rules={[{ required: true, message: "请输入实体名称" }]}>
                                    <Input placeholder="实体名称" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="三包" name="threeGuarantees" rules={[{ required: true, message: "请选择三包" }]}>
                                    <Select placeholder="请选择三包" style={{ width: "100%" }}  mode="multiple">
                                        {getPcodeDict("106").children.map((item) => (
                                            <Select.Option value={item.dictCode} key={item.id}>
                                                {item.title}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="统一信用编码" name="entityCreditCode">
                                    <Input placeholder="统一信用编码" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="法人" name="legalPerson">
                                    <Input placeholder="法人" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="联系电话" name="contactPhone">
                                    <Input placeholder="联系电话" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="开户银行" name="bankName">
                                    <Input placeholder="开户银行" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="银行账号" name="bankAccount">
                                    <Input placeholder="银行账号" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="注册地址" name="licenseAddress">
                                    <Input placeholder="注册地址" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="注册电话" name="licensePhone">
                                    <Input placeholder="注册电话" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="备注" name="remark">
                                    <Input placeholder="备注" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
