import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { useStockCheckModel } from "../stockCheckModel";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { momentFormat, formatNum } from "@/erp_subpackage/utils/util";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
export function DataTable({ goPageNav }) {
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys,
    } = useStockCheckModel();

    const { getTreeNodeName } = useDictModel(() => []);

    useEffect(() => {
        loadData()
    }, [])
    const columns = [
        {
            title: '序号',
            width: 40,
            render: (t, r, i) => i + 1,
            fixed: "left",
        }, {
            title: '盘库单号',
            width: 120,
            dataIndex: 'stocktakingCode',
            render: (text) => <YhTooltip text={text} onClick={() => goPageNav("look", text)} />,
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line click-span" onClick={() => goPageNav("look", text)}>{text}</p></Tooltip>,
            fixed: "left",
        }, {
            title: '盘点状态',
            width: 80,
            dataIndex: 'status',
            fixed: "left",
            render: (text) => getTreeNodeName(text)
        },{
            title: '盘点类型',
            width: 80,
            dataIndex: 'type',
            fixed: "left",
            render: (text) => text === 'trade-type.stocktaking.stt' ? '盘点单' : text === 'trade-type.stocktaking.init' ? '期初单' : ''
        }, {
            title: '盘点结果',
            width: 80,
            dataIndex: 'result',
        }, {
            title: '系统库存',
            width: 80,
            dataIndex: 'bookTotalNum',
        }, {
            title: '实际库存',
            width: 80,
            dataIndex: 'realTotalNum',
        }, {
            title: '盘盈总数',
            width: 80,
            dataIndex: 'surplusItem',
        }, {
            title: '盘亏总数',
            width: 80,
            dataIndex: 'lossItem',
        }, {
            title: '差异数量',
            width: 80,
            dataIndex: 'differenceTotalNum',
        }, {
            title: '差异金额',
            width: 80,
            dataIndex: 'differenceTotalAmount',
        }, {
            title: '差异率',
            width: 80,
            dataIndex: 'differenceRateAll',
            render: (text) => text ? text + "%" : "",
            // render: (text,record) => (record.differenceTotalAmount / record.totalAll) * 100
        }, {
            title: '开始时间',
            width: 130,
            dataIndex: 'startTime',
            render: (text) => momentFormat(text)
        }, {
            title: '结束时间',
            width: 130,
            dataIndex: 'endTime',
            render: (text) => momentFormat(text)
        }, {
            title: '制单人',
            width: 140,
            dataIndex: 'createUser',
        }, {
            title: '制单时间',
            width: 130,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '盘点仓库',
            width: 120,
            dataIndex: 'warehouseName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '所属机构',
            width: 130,
            dataIndex: 'shopName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '备注',
            width: 120,
            dataIndex: 'note',
        }
    ];

    //点击行选高亮
    const onRow = (code) => {
        let findIndex = selectedRowKeys.indexOf(code);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(code);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            rowKey={record => record.stocktakingCode}
            loading={loading}
            rowSelection={{
                //全部选中时  (存string[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    let changeRowId = changeRows.map(item => item.stocktakingCode);
                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                        : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
                },
                onSelect: (record) => onRow(record.stocktakingCode),
                selectedRowKeys: selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.stocktakingCode),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.stocktakingCode]);
                }
            })}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}
