import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";
class CustomerService {
    async page(searchVo) {
        const apiUrl = "/ci-storage/base/customer/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async one(id) {
        const apiUrl = `/ci-storage/base/customer/findById/${id}`;
        return await httpRequest.get(apiUrl);
    }
    async insert(insertVo) {
        const apiUrl = "/ci-storage/base/customer/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async update(updateVo) {
        const apiUrl = "/ci-storage/base/customer/update";
        return await httpRequest.post(apiUrl, updateVo);
    }

    //启用禁用
    async disable(params) {
        const apiUrl = `/ci-storage/base/customer/change`;
        return await httpRequest.post(apiUrl,params);
    }
    //导入
    async import(file) {
        const appPageUrl = "/ci-storage/base/customer/import";
        return await httpRequest.post(appPageUrl, file);
    }
    //导出
    async export(searchVo) {
        const appPageUrl = "/ci-storage/base/customer/export";
        return await httpRequest.postFile(appPageUrl, searchVo);
    }
    //供应商转客户
    async transfer(id) {
        const appPageUrl = `/ci-storage/base/customer/transfer/${id}`;
        return await httpRequest.post(appPageUrl);
    }
    //下载模板
    async downloadTpl() {
        const appPageUrl = "/ci-storage/base/customer/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }
    //同步
    async sync(data) {
        const appPageUrl = `/ci-storage/base/customer/sync`;
        return await httpRequest.post(appPageUrl, data, { timeout: 300000 });
    }
}

export  const customerService = new CustomerService()