import React, { useState, useEffect } from "react"
import { Input, Button, Table, message, Select } from "antd"
import Drawer from "@/components/Drawer"
import { lookMaintainer } from "@/service/ordersService"
import { getItemList } from "@/service/maintenanceService"
import { maintainOrderMaterialList } from "@/service/ordersService"
import { objectValueAllEmpty } from "@/utils/util"
import { ItemAttr } from "@/utils/enum"
const ItemAttraArr = Object.entries(ItemAttr)
export default ({ visible, onClose, onItemOk, drawerMode, orderId, data: dataList }) => {
    const [searchValue, setSearchValue] = useState({})
    const [addData, setAddData] = useState([]) //搜索数据
    const [selectItem, setSelectItem] = useState([]) //选中的项目 或者 物料

    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    //区别模式返回提示
    const returnModeStr = (mode) => {
        return mode === "part" ? "维修配件" : "维修项目"
    }
    //回车事件
    const handEnter = (e) => {
        if (e.nativeEvent.keyCode === 13) {
            if (objectValueAllEmpty(searchValue)) {
                message.warning(`请输入你要搜索的${returnModeStr(drawerMode)}`)
                return
            }
            loadData(100)
        }
    }

    //弹框关闭 清空数据
    useEffect(() => {
        if (!visible) {
            setSelectItem([])
            setAddData([])
            setSearchValue({})
        } else {
            loadData(100)
        }
    }, [visible])

    useEffect(() => {
        setSelectedRowKeys(selectItem.map((item) => item.id))
    }, [selectItem])

    //项目 新增数据列表
    const loadData = async (pageSize = 20, search = searchValue) => {
        let api = drawerMode === "part" ? maintainOrderMaterialList : getItemList
        let data = {
            pageSize: pageSize,
            pageNum: 1,
            searchItem: {
                ...search,
            },
        }
        if (drawerMode === "part") {
            let userList = await lookMaintainer(orderId), userId = "", teamId = ""
            userList.retData.forEach((item) => {
                if (item.majorFlag === 1) {
                    userId = item.userId
                    // teamId = item.teamId
                }
            })

            data.searchItem.userId = userId
            // data.searchItem.teamId = teamId
            data.searchItem.status = "picked"
            data.searchItem.remainingState = "have-leftovers"
            data.searchItem.excludeIds = dataList.map(item => item.maintainOrderMaterialId)
        }
        api(data).then((res) => {
            if (res && res.retCode === 200) {
                let results = res.retData
                if (results?.list?.length > 0) {
                    setAddData(results.list)
                } else {
                    message.warning(`没有匹配您要搜索的${returnModeStr(drawerMode)}`)
                }
            }
        })
    }

    //新增多选
    const rowSelection = {
        columnTitle: " ", //去掉多选
        selectedRowKeys: selectItem.reduce((c, n) => {
            c.push(n.id)
            return c
        }, []), //是否选中
        onSelect: (record, selected, selectedRows) => {
            //当为选中的时候
            if (selected) {
                let arr = [...selectItem, ...selectedRows]
                let obj = {}
                let newArr = arr.reduce((cur, next) => {
                    if (next) {
                        obj[next.id] ? (obj[next.id] = "") : (obj[next.id] = true && cur.push(next))
                    }
                    return cur
                }, [])
                setSelectItem(newArr)
            } else {
                deleteItem(record.id)
            }
        },
    }
    //不为选中或者删除的时候
    const deleteItem = (id) => {
        let arr = [...selectItem]
        let newArr = arr.filter((item) => item.id !== id)
        setSelectItem(newArr)
    }

    const rowSelectionNew = {
        columnWidth: "30px",
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectItem(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
        },
        // getCheckboxProps: (record) => ({
        //     disabled: drawerMode === "part" ? data.find((item) => item.maintainOrderMaterialId === record.id) : false,
        // }),
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
            {
                key: "odd",
                text: "Select Odd Row",
                onSelect: (changableRowKeys) => {
                    let newSelectedRowKeys = []
                    newSelectedRowKeys = changableRowKeys.filter((_, index) => {
                        if (index % 2 !== 0) {
                            return false
                        }

                        return true
                    })
                    setSelectedRowKeys(newSelectedRowKeys)
                },
            },
            {
                key: "even",
                text: "Select Even Row",
                onSelect: (changableRowKeys) => {
                    let newSelectedRowKeys = []
                    newSelectedRowKeys = changableRowKeys.filter((_, index) => {
                        if (index % 2 !== 0) {
                            return true
                        }

                        return false
                    })
                    setSelectedRowKeys(newSelectedRowKeys)
                },
            },
        ],
    }

    //新增项目的table配置
    const addColumns =
        drawerMode === "part"
            ? [
                  {
                      title: "序号",
                      width: 40,
                      align: "center",
                      fixed: "left",
                      render: (t, r, i) => i + 1,
                  },
                  {
                      title: "商品编号",
                      algin: "center",
                      dataIndex: "materialCode",
                  },
                  {
                      title: "商品名称",
                      algin: "center",
                      dataIndex: "materialName",
                  },
                  {
                      title: "单号",
                      width: 120,
                      algin: "center",
                      dataIndex: "maintainOrderCode",
                  },
                  //   {
                  //       title: "分类代码",
                  //       algin: "center",
                  //       dataIndex: "partClassificationCode",
                  //   },
                  {
                      title: "单位",
                      algin: "center",
                      width: 60,
                      dataIndex: "unitName",
                  },
                  {
                      title: "单价",
                      algin: "center",
                      width: 100,
                      dataIndex: "lateSalePrice",
                  },
                  {
                      title: "领取人",
                      algin: "center",
                      width: 100,
                      dataIndex: "userName",
                  },
                  {
                      title: "剩余数量",
                      algin: "center",
                      width: 70,
                      dataIndex: "remainingNum",
                  },
              ]
            : [
                  {
                      title: "序号",
                      width: 40,
                      align: "center",
                      fixed: "left",
                      render: (t, r, i) => i + 1,
                  },
                  {
                      title: "项目编码",
                      align: "center",
                      dataIndex: "itemCode",
                  },
                  {
                      title: "项目名称",
                      align: "center",
                      dataIndex: "itemName",
                  },
                  {
                      title: "项目属性",
                      align: "center",
                      width: 80,
                      dataIndex: "itemAttr",
                      render: (text) => <p>{ItemAttr[text]}</p>,
                  },

                  {
                      title: "工时",
                      align: "center",
                      width: 60,
                      dataIndex: "itemWorkHours",
                  },
                  {
                      title: "单价",
                      align: "center",
                      width: 120,
                      dataIndex: "workHoursPrice",
                  },
                  {
                    title: "系统分类",
                    align: "center",
                    width: 120,
                    dataIndex: "itemClassificationName",
                },
                  {
                      title: "维修项目金额",
                      align: "center",
                      width: 120,
                      dataIndex: "itemAmount",
                  },
              ]
    return (
        <Drawer visible={visible} onClose={() => onClose(false)}>
            <div className="team-drawer-title">
                <span>{`${returnModeStr(drawerMode)}`}新增</span>
            </div>
            <div className="team-drawer-content">
                <div className="drawer-search-box">
                    {drawerMode === "part" ? (
                        <>
                            {/* <span>品牌:</span>
                            <Input
                                className="search-input1"
                                allowClear
                                placeholder="请输入品牌"
                                value={searchValue.partBrand}
                                onChange={(e) =>
                                    setSearchValue({
                                        ...searchValue,
                                        partBrand: e.target.value,
                                    })
                                }
                                onKeyPress={handEnter}
                            /> */}
                            <span>商品编号:</span>
                            <Input
                                className="search-input1"
                                allowClear
                                placeholder="请输入商品编码"
                                value={searchValue.materialCode}
                                onChange={(e) =>
                                    setSearchValue({
                                        ...searchValue,
                                        materialCode: e.target.value,
                                    })
                                }
                                onKeyPress={handEnter}
                            />
                            <span>商品名称:</span>
                            <Input
                                className="search-input1"
                                allowClear
                                placeholder="请输入商品名称"
                                value={searchValue.materialName}
                                onChange={(e) =>
                                    setSearchValue({
                                        ...searchValue,
                                        materialName: e.target.value,
                                    })
                                }
                                onKeyPress={handEnter}
                            />
                        </>
                    ) : (
                        <>
                            <span>项目属性:</span>
                            <Select
                                allowClear
                                placeholder="请选择项目属性"
                                style={{ width: "160px", textAlign: "left" }}
                                onChange={(attr) =>
                                    setSearchValue({
                                        ...searchValue,
                                        itemAttr: attr,
                                    })
                                }
                            >
                                {ItemAttraArr.map((item) => (
                                    <Select.Option value={item[0]} key={item[0]}>
                                        {item[1]}
                                    </Select.Option>
                                ))}
                            </Select>
                            <span>项目编码:</span>
                            <Input
                                className="search-input1"
                                allowClear
                                placeholder="请输入项目编码"
                                value={searchValue.itemCode}
                                onChange={(e) =>
                                    setSearchValue({
                                        ...searchValue,
                                        itemCode: e.target.value,
                                    })
                                }
                                onKeyPress={handEnter}
                            />
                            <span>项目名称:</span>
                            <Input
                                className="search-input1"
                                allowClear
                                placeholder="请输入项目名称"
                                value={searchValue.itemName}
                                onChange={(e) =>
                                    setSearchValue({
                                        ...searchValue,
                                        itemName: e.target.value,
                                    })
                                }
                                onKeyPress={handEnter}
                            />
                        </>
                    )}
                    <Button className="search-but1" onClick={() => loadData(100)}>
                        查询
                    </Button>
                </div>
                <Table
                    className="maintainOrderChooseTable"
                    size="small"
                    rowKey={(record) => record.id}
                    columns={addColumns}
                    dataSource={addData}
                    rowSelection={rowSelectionNew}
                    pagination={false}
                    onRow={(record, index) => ({
                        onClick: () => {
                            let findArr = selectItem.find((item) => item.id === record.id)
                            if (findArr) {
                                let filterArr = selectItem.filter((item) => item.id !== findArr.id)
                                setSelectItem(filterArr)
                            } else {
                                setSelectItem((origin) => [...origin, record])
                            }
                        },
                    })}
                    scroll={{ y: 360 }}
                />
                <div className="team-form-footer">
                    <Button onClick={() => onClose(false)} className="team-but-one">
                        取消
                    </Button>
                    <Button className="team-but-two" onClick={() => onItemOk(selectItem)}>
                        提交
                    </Button>
                </div>
                <div className="select-item-box">
                    <h4>已添加{`${returnModeStr(drawerMode)}`}</h4>
                    <ul>
                        {selectItem &&
                            selectItem.map((item) => {
                                return (
                                    <li key={item.id}>
                                        <span>{drawerMode === "part" ? item.materialName : item.itemName}</span>
                                        <span onClick={() => deleteItem(item.id)}>删除</span>
                                    </li>
                                )
                            })}
                    </ul>
                </div>
            </div>
        </Drawer>
    )
}
