import React,{useContext} from "react";
import TopSearch from '@/components/TopSearch'
import {useContractModel} from '../contractParticularsModel'
import { getPcodeDict } from "@/utils/dataStorage"
import { MyContext } from '@/store/myContext'
import { commonService } from "@/erp_subpackage/pages/common/commonService";
import { getBussinessEntityList } from '@/service/dataService'
export function PageHeader(){
    const {searchParam,setSearchParam,loadData,} =useContractModel() 
    const {  Option, } = useContext(MyContext)
    const searchList = {
        normalList: [
            {
                type: 'input',
                placeholder: '车辆牌照',
                valueName: 'vehicleCode',
            },
            {
                type: "input",
                placeholder: "客户名称",
                valueName: 'customerName',
            },
            {
                type: "input",
                placeholder: "单据编号",
                valueName: 'orderCode',
            },
            {
                type: "select",
                placeholder: "签约类型",
                valueName: 'signType',
                optionList: () =>
                getPcodeDict('046').children.map((item) => (
                    <Option value={item.dictCode} key={item.id}>
                        {item.title}
                    </Option>
                )),
            },
            {
                type: "select",
                placeholder: "大包模式",
                valueName: 'bigBagMode',
                optionList: () =>
                getPcodeDict('047').children.map((item) => (
                    <Option value={item.dictCode} key={item.id}>
                        {item.title}
                    </Option>
                )),
            },
            {
                type: "searchSelect",
                placeholder: "签约实体",
                valueName: 'signBusinessEntityId',
                optionName: "entityName",
                searchItemName: "entityName",
                optionId: "id",
                searchApi:getBussinessEntityList,
                
                
            },
            
           
        ],
        hideList: [
            {
                type: "searchSelect",
                placeholder: "签约机构",
                valueName: 'signOrganId',
                optionName: "organName",
                searchItemName: "organName",
                optionId: "id",
                searchApi: commonService.orgPageList
            },
        ]
    }
       
       
    

    return (
        <TopSearch
            title="合约管理"
            searchValue={searchParam}
            justify="start"
            searchListData={searchList}
            setSearchValue={(obj) => setSearchParam(obj)}
            handleSearch={loadData}
            
            
        />
    )
}