import React, { useContext } from "react";
import { Button, Input, message, Modal } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useSaleSoModel } from "../saleSoModel";
import { formatNum } from "@/erp_subpackage/utils/util";
import { MyContext } from "@/store/myContext"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"

export const DataBox = () => {
    const {
        selectedRowKeys,
        selectedRow,
        descTotal,
        rowsDescTotal,
        searchParam,
        invalidate,
        exportSaleSo,
        setPrint,
        detailsExport,
        setSelectedRowKeys,
        setSettleScaniaPrint
    } = useSaleSoModel();
    const { state, dispatch, detailsPage } = useContext(MyContext)
    const desc = [
        {
            label: "销售数量",
            value: selectedRowKeys.length === 0 ? descTotal.totalNum : (+formatNum(rowsDescTotal.totalNum)),// "1232",
            color: 'red'
        },
        {
            label: "销售金额",
            value: selectedRowKeys.length === 0 ? formatNum(descTotal.totalAmount) : formatNum(rowsDescTotal.totalAmount),// "￥33.23",
            color: 'red'
        },
        {
            label: "优惠总金额",
            value: selectedRowKeys.length === 0 ? formatNum(descTotal.discountTotalAmount) : formatNum(rowsDescTotal.discountTotalAmount),// "￥1232",
            color: 'red'
        },
        {
            label: "营业额",
            value: selectedRowKeys.length === 0 ? formatNum(descTotal.receivedAmount) : formatNum(rowsDescTotal.receivedAmount),// "￥1232",
            color: 'red'
        },
        {
            label: "已收金额",
            value: selectedRowKeys.length === 0 ? formatNum(descTotal.receivableAmount) : formatNum(rowsDescTotal.receivableAmount),// "￥1232",
            color: 'red'
        },
        {
            label: "销售成本",
            value: selectedRowKeys.length === 0 ? formatNum(descTotal.latestPurPrice) : formatNum(rowsDescTotal.latestPurPrice),// "￥1232",
            color: 'red'
        },
        {
            label: "销售毛利",
            value: (selectedRowKeys.length === 0 ? descTotal.grossProfit : rowsDescTotal.grossProfit).toFixed(2),// "￥1232",
            color: 'red'
        },
    ]

    //新增 查看 编辑
    const goPageNav = (mode) => {
        let tabName = {
            add: "销售单新增",
            edit: "销售单编辑",
            look: "销售单查看",
        }
        let modeKey = "saleSo/" + mode

        //动态传入要删除的nav key
        function handleDispatch(obj = {}) {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.SaleSoDetails({
                    dispatch,
                    modeKey,
                    mode,
                    rowId: selectedRowKeys[0],
                }),
            })
        }

        if (mode === "edit") {
            const findNav = state.panes.find((item) => item.key === modeKey)
            if (findNav) {
                Modal.confirm({
                    title: "温馨提醒！",
                    content: "您还有销售单详情未保存，是否跳转",
                    okText: "跳转未保存页",
                    cancelText: "打开新页签",
                    onOk: () => {
                        handleDispatch()
                    },
                    //打开新页签重新生成
                    onCancel: () => {
                        handleDispatch({ deleteKey: modeKey })
                    },
                })
                return
            }
            handleDispatch()
        } else if (mode === "add") {
            handleDispatch()
        } else if (mode === "look") {
            handleDispatch({ deleteKey: modeKey })
        }
    }

    const onPrint = () => {
        if (selectedRowKeys.length !== 1) {
            message.warning('请选择单条数据进行编辑，点击鼠标右键可一键选中哦！')
            return;
        }
        setPrint(true)
    }

    const onSupplement = () => {
        // addNav("5-2-1", {
        //     id: "", mode: "add", parentInfo: {
        //         Supplement: "Supplement"
        //     }
        // })
    }
    const onSettleSknyPrint = () => {
        if (selectedRowKeys.length !== 1) {
            message.warning('请选择单条数据进行编辑，点击鼠标右键可一键选中哦！')
            return;
        }
        setSettleScaniaPrint(true)
    }
    const cancellation = () => {
        if (selectedRowKeys.length !== 1) {
            message.warning('请选择单条数据进行编辑，点击鼠标右键可一键选中哦！')
            return;
        }
        let cancelReason = ""
        Modal.confirm({
            title: "销售单作废",
            // type: "error",
            content: <><span>请输入作废原因</span>
                <Input onChange={(e) => { cancelReason = e.target.value }} /></>,
            okText: "确定",
            onOk: () => {
                invalidate(cancelReason)
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const action = <>
        <Button
            className="btn-item"
            type="link"
            shape="round"
            onClick={() => goPageNav('add')}
        >新增</Button>
        <Button
            className="btn-item"
            type="link"
            shape="round"
            disabled={searchParam.shopId}
            onClick={() => {
                if (selectedRowKeys.length !== 1) {
                    message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！")
                    return
                }
                if (selectedRow.status === DictCodeEnmu.SALE_CHANGE) {
                    message.warning("该单据已作废,无法进行编辑")
                    return
                }
                if (selectedRow.status === DictCodeEnmu.SALE_CANCEL) {
                    message.warning("该单据已取消,无法进行编辑")
                    return
                }
                if (selectedRow.status === DictCodeEnmu.SALE_REVERSED) {
                    message.warning("该单据已撤销,无法进行编辑")
                    return
                }
                if (selectedRow.transferOrder) {
                    message.warning("调拨单据不可以编辑")
                    return
                }
                goPageNav("edit")
            }}
        >编辑</Button>
        <Button
            className="btn-item"
            type="link"
            shape="round"
            onClick={() => {
                if (selectedRowKeys.length !== 1) {
                    message.warning("请选择单条数据进行查看，点击鼠标右键可一键选中哦！")
                    return
                }
                goPageNav("look")
            }}
        >查看</Button>
        <Button
            className="btn-item"
            type="link"
            shape="round"
            disabled={searchParam.shopId}
            onClick={() => {
                if (selectedRowKeys.length !== 1) {
                    message.warning("请选择单条数据进行作废，点击鼠标右键可一键选中哦！")
                    return;
                }
                if (selectedRow.status != DictCodeEnmu.SALE_CREATE) {
                    message.warning("该状态下不可进行作废")
                    return;
                }
                cancellation()
            }}
        >作废</Button>
        <Button
            className="btn-item"
            type="link"
            shape="round"
            onClick={() => exportSaleSo()}
        >导出</Button>
        {/* <Button
            className="btn-item"
            type="link"
            shape="round"
            onClick={() => detailsExport()}
        >导出明细</Button> */}
        <Button
            className="btn-item"
            type="link"
            shape="round"
            onClick={onPrint}
        >打印</Button>
        <Button
                className="btn-item"
                type="link"
                shape="round"
                onClick={onPrint}
            >财务打印</Button>
                    <Button
                className="btn-item"
                type="link"
                shape="round"
                onClick={onSettleSknyPrint}
            >结算打印</Button>
        {/* <Button
                className="btn-item"
                type="link"
                shape="round"
                onClick={onSupplement}
            >补单</Button> */}
    </>;

    return (
        <YhBox title={"销售单列表"} descriptions={desc} action={action}>
            <DataTable />
        </YhBox>
    )
}