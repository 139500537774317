import React, { useEffect, useRef, useState, useContext } from 'react'
import { Button, message, Modal, Radio } from 'antd'
import { YhFormBox } from '@/erp_subpackage/components/YhFormBox'
import { getFormValidatorErrText } from '@/erp_subpackage/utils/util'
import { useDictModel } from '@/erp_subpackage/pages/base/dict/dictModel'
import { DictCodeEnmu } from '@/erp_subpackage/utils/DictCodeEnum'
import { usePurchasePlanDetailsModel } from './purchasePlanDetailModel'
import useGlobalModel from '@/erp_subpackage/model/globalModel'
import { MyContext } from '@/store/myContext'
import { getBentityListOption } from '@/utils/dataStorage'
import debounce from "lodash/debounce"

export const FormBox = ({ modeKey, mode, rowCode, dispatch }) => {
    const { detailsPage } = useContext(MyContext)
    const { editFormVal, addFormVal, lookFormVal,saveBtnLoading, setSaveBtnLoading, organType, updateFormVal, updateList, getPurchasePlanOne, insertDetail, editDetail } =
        usePurchasePlanDetailsModel()
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => [])
    const { fetchSupplierList } = useGlobalModel(() => [])
    const formRef = useRef()
    const [more, setMore] = useState(true) //控制更多按钮
    const formVal =
        mode === 'edit'
            ? { ...editFormVal, updateTime: editFormVal.status === DictCodeEnmu.PPLAN_CREATE ? null : editFormVal.updateTime }
            : mode === 'look'
            ? { ...lookFormVal, updateTime: lookFormVal.status === DictCodeEnmu.PPLAN_CREATE ? null : lookFormVal.updateTime }
            : addFormVal

    //编辑时， 没有缓存调用详情接口
    useEffect(() => {
        if (mode === 'edit' || mode === 'look') {
            getPurchasePlanOne(rowCode, mode)
        }
        //新增 机构默认展示云豪科技平台为供应商
        if (mode === 'add') {
            !organType && getSupplier('平台管理机构')
        }
        return () => {
            updateFormVal(mode)
            updateList(mode)
        }
    }, [rowCode])

    //查询供应商
    const getSupplier = async (value) => {
        if (!value) return
        let retData = await fetchSupplierList(value)
        if (retData && retData.length) {
            let { label, value, type_code, type_name, contact, phone } = retData[0]
            updateFormVal(mode, {
                contact,
                phone,
                supplierName: label,
                supplierCode: value,
                supplierTypeCode: type_code,
                supplierTypeName: type_name,
            })
        }
    }

    //关闭当前页
    const onClose = () => {
        dispatch({
            type: 'removeTabs',
            targetKey: modeKey,
            activeMenu: 'purchasePlan',
        })
    }

    //跳转供应商详情页
    const goSupplier = () => {
        dispatch({
            type: 'changeTabs',
            name: '供应商新增',
            activeMenu: 'baseSupplier/add',
            content: detailsPage.BaseSupplierDetails({
                dispatch,
                modeKey,
                mode: 'add',
            }),
        })
    }

    const bentityChange = (value, option) => {
        option && onChange({ bentityName: option.label, bentityCode: option.value })
    }

    //供应商选中  回显供应商类型、联系人、电话
    const supplierChange = (value, option) => {
        if (option && option.id) {
            let { label, value, type_code, type_name, contact, phone } = option
            updateFormVal(mode, {
                contact,
                phone,
                supplierName: label,
                supplierCode: value,
                supplierTypeCode: type_code,
                supplierTypeName: type_name,
            })
        }
    }

    //选择联系人
    const handleContact = async () => {
        if (!formVal.supplierCode) {
            return message.warning('请选择联系人后进行操作！')
        }
        let ret = await fetchSupplierList(formVal.supplierName)
        if (ret && ret.length) {
            let options = []
            if (ret[0].contact) {
                options.push({ contact: ret[0].contact, phone: ret[0].phone })
            }
            if (ret[0].name2) {
                options.push({ contact: ret[0].name2, phone: ret[0].phone2 })
            }
            let activeIndex = -1
            Modal.confirm({
                title: `联系人选择【${formVal.supplierName}】`,
                content: (
                    <Radio.Group
                        onChange={(e) => {
                            activeIndex = e.target.value
                        }}
                    >
                        {options.map((item, index) => (
                            <Radio value={index} key={index}>
                                {item.contact}
                            </Radio>
                        ))}
                    </Radio.Group>
                ),
                okText: '确定',
                cancelText: '取消',
                onOk: () =>
                    new Promise((resolve, reject) => {
                        if (activeIndex > -1) {
                            resolve(activeIndex)
                        } else {
                            message.warning('请选择联系人')
                            reject('请选择联系人')
                        }
                    }).then((res) => {
                        updateFormVal(mode, {
                            contact: options[activeIndex].contact,
                            phone: options[activeIndex].phone,
                        })
                    }),
            })
        }
    }
    const onChange = (changedValues) => {
        updateFormVal(mode, changedValues)
    }

    const reset = () => {
        getPurchasePlanOne(rowCode, mode).then((res) => {
            message.success('刷新成功')
        })
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current
            .validateFields()
            .then((val) => {
                let data = {
                    ...val,
                    materialTypeName: getTreeNodeName(val.materialTypeCode),
                    purchaseTypeName: getTreeNodeName(val.purchaseTypeCode),
                    deliveryMethodName: getTreeNodeName(val.deliveryMethod),
                }
                if (mode === 'edit') {
                    editDetail(data).then((res) => {
                        res && getPurchasePlanOne(rowCode, mode)
                    })
                } else {
                    insertDetail(data).then((res) => {
                        res && onClose()
                    })
                }
                console.log(data)
            })
            .catch((err) => {
                setSaveBtnLoading(false)
                message.warning(getFormValidatorErrText(err))
            })
    }

    const rightChildren = (
        <>
            <Button className="btn-item" shape="round" onClick={debounce(onSubmit,900)} hidden={mode === 'look'} loading={saveBtnLoading}>
            {saveBtnLoading ? '保存中' : '保存'}
            </Button>
            <Button className="btn-item" shape="round" onClick={() => reset()} hidden={mode === 'add'}>
                刷新
            </Button>
            <Button className="btn-item" shape="round" onClick={onClose}>
                返回
            </Button>
        </>
    )

    const baseFormItem = {
        title: '基本信息',
        style: { padding: '0 20px', marginBottom: '10px' },
        disable: mode === 'look',
        rightChildren,
        items: [
            {
                type: 'Input',
                fieldLabel: '采购计划号',
                fieldName: 'planCode',
                disable: true,
                placeholder: '自动生成',
            },
            {
                type: 'AsyncSelect',
                fieldLabel: (
                    <span className="click-span" onClick={goSupplier}>
                        供应商名称
                    </span>
                ),
                fieldName: 'supplierName',
                onSelectChange: supplierChange,
                placeholder: '供应商',
                rules: [{ required: true, message: '请选择供应商' }],
                selectfetchList: fetchSupplierList,
                defaultQuery: formVal.supplierName,
            },
            {
                type: 'Input',
                fieldLabel: (
                    <span className="click-span" onClick={handleContact}>
                        联系人
                    </span>
                ),
                fieldName: 'contact',
                rules: [{ required: true, message: '请输入联系人' }],
            },
            {
                type: 'Input',
                fieldLabel: '联系电话',
                fieldName: 'phone',
                rules: [{ required: true, message: '请输入联系电话' }],
            },
            {
                type: 'Input',
                fieldLabel: '供应商类型',
                fieldName: 'supplierTypeName',
                disable: true,
            },
            {
                type: 'Select',
                fieldLabel: '商品类型',
                fieldName: 'materialTypeCode',
                options: getSelectedDictOne(DictCodeEnmu.MATERIAL_TYPE),
            },
            {
                type: 'Select',
                fieldLabel: '运输方式',
                fieldName: 'deliveryMethod',
                options: getSelectedDictOne(DictCodeEnmu.LOGISTIC_MODE),
            },
            {
                type: 'Select',
                fieldLabel: '采购类型',
                fieldName: 'purchaseTypeCode',
                options: getSelectedDictOne(DictCodeEnmu.PURCHASE_TEPE, ['vrm', 'ci', 'main']),
                rules: [{ required: true, message: '请选择采购类型' }],
            },
            {
                type: 'Select',
                fieldLabel: '结算类型',
                fieldName: 'payType',
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                rules: [{ required: true, message: '请选择结算类型' }],
            },
            {
                type: 'Select',
                fieldLabel: '是否直发',
                fieldName: 'isDirect',
                options: [
                    { label: '是', value: 1 },
                    { label: '否', value: 0 },
                ],
            },
            {
                type: 'Select',
                fieldLabel: '采购实体',
                fieldName: 'bentityCode',
                options: getBentityListOption(),
                onSelectChange: bentityChange,
                rules: [{ required: true, message: '请选择采购实体' }],
            },
            {
                type: 'Button',
                fieldLabel: (
                    <a className="color-blue text-line" onClick={() => setMore(!more)}>
                        {more ? '更多>>' : '隐藏'}
                    </a>
                ),
            },
            {
                type: 'Input',
                fieldLabel: '所属机构',
                fieldName: 'shopName',
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '直发地址',
                fieldName: 'detailedAddress',
                span: 12,
                labelCol: { span: 3 },
                hidden: more,
            },
            {
                type: 'Input',
                fieldLabel: '收货单位',
                fieldName: 'receivingUnit',
                hidden: more,
            },
            {
                type: 'Input',
                fieldLabel: '备注',
                fieldName: 'note',
                hidden: more,
            },
            {
                type: 'Input',
                fieldLabel: '制单人',
                fieldName: 'createUser',
                disable: true,
                hidden: more,
            },
            {
                type: 'Input',
                fieldLabel: '制单时间',
                fieldName: 'createTime',
                disable: true,
                hidden: more,
            },
            {
                type: 'Input',
                fieldLabel: '到货日期',
                fieldName: 'updateTime',
                disable: true,
                hidden: more,
            },
            //带出字段
            {
                type: 'Input',
                fieldLabel: '供应商名称',
                fieldName: 'supplierCode',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '供应商类型code',
                fieldName: 'supplierTypeCode',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '采购实体名称',
                fieldName: 'bentityName',
                hidden: true,
            },
        ],
    }

    const fromConfig = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: 'right',
        formValues: formVal,
        disabled: mode === 'look',
        onChange,
        boxs: [baseFormItem],
    }

    return <YhFormBox {...fromConfig} />
}
