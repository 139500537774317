import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import { MyContext } from "@/store/myContext"
import Detail from "./detail"
import { getMaintainOrderList } from "@/service/ordersService"
import { getTeamList } from "@/service/teamService"
import TopSearch from "@/components/TopSearch"
import { message, Select } from "antd"
import { MaintainOrdeReturnListStatus } from "@/utils/enum"
import YhTooltip from "@/components/YhTooltip"
const { Option } = Select
export default () => {
    const { state } = useContext(MyContext)
    const [visible, setVisible] = useState(false)
    const [mode, setMode] = useState("")
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({ status: Object.keys(MaintainOrdeReturnListStatus)[0] })
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息

    useEffect(() => {
        if (state.activeMenu === "repairPickingList") {
            loadData(pagination.current, searchValue)
        }
    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    //列表数据
    const loadData = (current, search = {}, refresh = false) => {
        let data = {
            searchItem: { ...searchValue, ...search },
            startTime: search.startTime ? search.startTime : null,
            endTime: search.endTime ? search.endTime : null,
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        getMaintainOrderList(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
            if (refresh) {
                message.success("刷新成功")
            }
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //列表button
    const topButtons = [
        {
            click: () => {
                setMode("insert")
                setVisible(true)
            },
            content: "新增",
        },
        {
            click: () => {
                if (!Object.keys(selectInfo.rowInfo).length) {
                    message.warning("请选择你要编辑的工单")
                    return
                }
                setMode("edit")
                setVisible(true)
            },
            content: "编辑",
        },
        {
            click: () => {
                loadData(pagination.current, searchValue, true)
            },
            content: "刷新",
        },
    ]

    //table配置
    const columns = [
        {
            title: "单号",
            align: "center",
            dataIndex: "orderCode",
            render: (text, record, index) => (
                <div style={{ color: "#2e3aeb", cursor: "pointer" }}>
                    <YhTooltip
                        text={text}
                        maxWidth={350}
                        onClick={() => {
                            setSelectInfo({rowInfo: record, rowIndex: index})
                            setMode("edit")
                            setVisible(true)
                        }}
                    />
                </div>
            ),
        },
        // {
        //     title: "所属团队",
        //     align: "center",
        //     dataIndex: "teamName",
        // },
        {
            title: "姓名(领料人)",
            align: "center",
            dataIndex: "userName",
            render: (text) => <YhTooltip  maxWidth={350}text={text} />,
        },
        {
            title: "联系电话",
            align: "center",
            dataIndex: "userPhone",
            render: (text) => <YhTooltip text={text} maxWidth={350}/>,
        },
        {
            title: "领料总数",
            align: "center",
            dataIndex: "totalNum",
        },
        {
            title: "剩料总数",
            align: "center",
            dataIndex: "remainingNum",
        },
        {
            title: "未领取数量",
            align: "center",
            dataIndex: "tobePickedNum",
            render: (text) => (text ? text : 0),
        },
        {
            title: "是否平台",
            align: "center",
            dataIndex: "isPlatform",
            render: (text) => (text ? "是" : "否"),
        },
        {
            title: "登记日期",
            align: "center",
            dataIndex: "updateTime",
        },
        {
            title: "创建人",
            align: "center",
            dataIndex: "createUserName",
            render: (text) => <YhTooltip text={text} maxwidth={400} />,
        },
    ]
    // 搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入物料编码",
                valueName: "materialCode",
            },
            {
                type: "input",
                placeholder: "请输入物料名称",
                valueName: "materialName",
            },
            {
                type: "input",
                placeholder: "请输入领料单号",
                valueName: "orderCode",
            },
            // {
            //     type: "searchSelect",
            //     placeholder: "请选择团队",
            //     valueName: "teamId",
            //     optionName: "teamName",
            //     optionId: "id",
            //     searchItemName: "teamName",
            //     searchApi: getTeamList,
            // },
            {
                type: "input",
                placeholder: "请输入领料人姓名",
                valueName: "userName",
            },
            {
                type: "input",
                placeholder: "请输入联系电话",
                valueName: "userPhone",
            },
            {
                type: "select",
                placeholder: "请选择物料单状态",
                valueName: "status",
                optionList: () =>
                    Object.entries(MaintainOrdeReturnListStatus).map((item) => (
                        <Option value={item[0]} key={item[0]}>
                            {item[1]}
                        </Option>
                    )),
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "repairPickingList" && (
                <>
                    <TopSearch
                        title="维修领料单"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                        defaultSearch={{ status: Object.keys(MaintainOrdeReturnListStatus)[0] }}
                    />
                    <ListComponent
                        title="维修领料单"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        scroll={{ x: "max-content" }}
                    />

                    {visible && <Detail rowInfo={selectInfo.rowInfo} visible={visible} setVisible={setVisible} handleSuc={loadData} mode={mode} />}
                </>
            )}
        </>
    )
}
