import { post, get } from "../utils/request"

/**
 * 门店管理
 */
//分页查询
export const storePage = (data) => {
    return post("/ci-platform/store/page", data)
}

//新增
export const storeUpdate = (data) => {
    return post("/ci-platform/store/update", data)
}

//编辑
export const storeInsert = (data) => {
    return post("/ci-platform/store/insert", data)
}

//获取一条
export const storeOne = (id) => {
    return get(`/ci-platform/store/one/${id}`)
}

//删除一条
export const storeDelete = (id) => {
    return get(`/ci-platform/store/delete/${id}`)
}
