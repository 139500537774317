// components/ResizableTableModel/index.jsx
// 可拖拽表格组件
import React,{ useState } from 'react';
import { Table } from 'antd';
import { Resizable } from 'react-resizable';
import './index.less';


// 表头拖拽组件
const ResizableTitle = ({ onResize, width, ...restProps }) => {
    if (!width) { return (<th {...restProps} />) };

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={e => { e.stopPropagation() }}
                />
            }
            onResize={onResize}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            <th {...restProps} style={{ ...restProps?.style, userSelect: 'none' }} />
        </Resizable>
    );
};


// 带拖拽表格组件
const YhTable = ({ columns = [], ...props }) => {
  
    // * 列数据
    const [cols, setCols] = useState(columns);
    const colsArray = cols.map((col, index) => {
        return {
            ...col,
            onHeaderCell: column => ({ width: column.width, onResize: handleResize(index) })
        };
    });

    // todo 调整列宽
    const handleResize = index => {
        return (_, { size }) => {
            const temp = [...cols];
            temp[index] = { ...temp[index], width: size.width };
            setCols(temp);
        };
    };

    return (
        <Table
            components={{ header: { cell: ResizableTitle } }}
            columns={colsArray}
            {...props}
        />
    );
};

export default YhTable;

