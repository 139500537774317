import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { usePurchasePoModel } from "../purchasePoModel";
import { YhTag } from "@/erp_subpackage/components/YhTag";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { momentFormat, formatNum } from "@/erp_subpackage/utils/util";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"


export function DataTable({ goPageNav }) {
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys,
    } = usePurchasePoModel();

    const { getTreeNodeName } = useDictModel(() => []);

    useEffect(() => {
        loadData()
    }, [])

    const columns = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '采购单号',
            width: 120,
            fixed: "left",
            dataIndex: 'purchaseCode',
            render: (text) => <YhTooltip text={text} onClick={() => goPageNav("look", text)} />,
            // render: (text, record) => <Tooltip placement="topLeft" title={text}><p className="one-line click-span" onClick={() => goPageNav("look", text)}>{text}</p></Tooltip>,
        }, {
            title: '调拨单号',
            width: 180,
            fixed: "left",
            dataIndex: 'transferOrder',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '调入单号',
            width: 180,
            fixed: "left",
            dataIndex: 'allocationCode',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '采购计划号',
            width: 120,
            dataIndex: 'planCode',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '单据状态',
            width: 80,
            dataIndex: 'status',
            render: (text) => <YhTag status={text} text={getTreeNodeName(text)} />,
        }, {
            title: '所属机构',
            width: 150,
            dataIndex: 'shopName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '支付状态',
            width: 70,
            dataIndex: 'payStatus',
            render: (text) => <YhTooltip text={ getTreeNodeName(text)} />,
            //render: (text) => getTreeNodeName(text)
        }, {
            title: '采购类型',
            width: 120,
            dataIndex: 'purchaseTypeName',
            
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '结算类型',
            width: 80,
            dataIndex: 'payType',
            render: (text) => <YhTooltip text={ getTreeNodeName(text)} />,
            //render: (text) => getTreeNodeName(text)
        }, {
            title: '结算方式',
            width: 150,
            dataIndex: 'payMethodName',
            render: (text,record) => <YhTooltip text={record?.settleDetails?.[0]?.payMethodName ?? record?.settleDetails?.[0]?.accountName} />,
            // render: (text, record) => <Tooltip placement="topLeft" title={record?.settleDetails?.[0]?.payMethodName ?? record?.settleDetails?.[0]?.accountName}><p className="one-line">{record?.settleDetails?.[0]?.payMethodName ?? record?.settleDetails?.[0]?.accountName}</p></Tooltip>,
        }, {
            title: '采购数量',
            width: 80,
            dataIndex: 'totalNum',
        }, {
            title: '到货数量',
            width: 80,
            dataIndex: 'arrivedNum',
        }, {
            title: '损坏数量',
            width: 80,
            dataIndex: 'damageTotalNum',
        }, {
            title: '入库数量',
            width: 80,
            dataIndex: 'stockIoNum',
        }, {
            title: '已付金额',
            width: 80,
            dataIndex: 'paidAmount',
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '退货数量',
            width: 80,
            dataIndex: 'returnTotalNum',
        }, {
            title: '发票类型',
            width: 120,
            dataIndex: 'invoiceTypeName',
            render: (text) => <YhTooltip text={ text} />,
        }, {
            title: '联系人',
            width: 150,
            dataIndex: 'contact',
            render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '电话',
            width: 95,
            dataIndex: 'phone',
        }, {
            title: '制单人',
            width: 140,
            dataIndex: 'createUser',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '制单日期',
            width: 130,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '库管员',
            width: 140,
            dataIndex: 'storekeeper',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '入库日期',
            width: 130,
            dataIndex: 'stockIoTime',
            render: (text) => momentFormat(text)
        }, {
            title: '结算员',
            width: 140,
            dataIndex: 'reckoner',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '结算日期',
            width: 130,
            dataIndex: 'settleTime',
            render: (text) => momentFormat(text)
        }, {
            title: '核销日期',
            width: 130,
            dataIndex: 'writeOffTime',
            render: (text) => momentFormat(text)
        }, {
            title: '作废原因',
            width: 120,
            dataIndex: 'cancelReason',
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
        }, {
            title: '商品类型',
            width: 80,
            dataIndex: 'materialTypeName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '供应商',
            width: 160,
            dataIndex: 'supplierName',
            render: (text) => <YhTooltip text={text} />,
            fixed: "right"
        }, {
            title: '采购金额',
            width: 100,
            dataIndex: 'payableAmount',
            fixed: "right",
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '打印次数',
            width: 70,
            fixed: "right",
            align: "center",
            dataIndex: 'printingTimes',
        }
    ];


    //点击行
    const onRow = (record) => {
        let findObj = selectedRowKeys.find(item => item.id === record.id)
        if (findObj) {
            let newSelectedRowKeys = selectedRowKeys.filter(item => item.id !== record.id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            setSelectedRowKeys(origin => [...origin, record]);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            loading={loading}
            rowSelection={{
                //全部选中时 (存row[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRows])
                        : setSelectedRowKeys((origin) => {
                            let changeRowId = changeRows.map(item => item.id);
                            return origin.filter(item => changeRowId.indexOf(item.id) < 0)
                        });
                },
                onSelect: (record, selected, selectedRows) => onRow(record),
                selectedRowKeys: selectedRowKeys.map(item => item.id)
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record]);
                }
            })}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}
