import React, {useEffect, useContext } from "react";
import { Table, Tooltip } from "antd";
import { useSaleRoModel } from "../saleRoModel";
import { MyContext } from "@/store/myContext"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { YhTag } from "@/erp_subpackage/components/YhTag";
import { momentFormat, formatNum } from "@/erp_subpackage/utils/util";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, descRows, loading,
        loadData, setSelectedRowKeys, setDescRows
    } = useSaleRoModel();
    const { getTreeNodeName } = useDictModel();
    const { dispatch, detailsPage} = useContext(MyContext)
    const onLook = (mode, code) => {
        let tabName = {
            add: "销售退货单新增",
            edit: "销售退货单编辑",
            look: "销售退货单查看",
        }
        let modeKey = "saleRo/" + mode

        //动态传入要删除的nav key
        function handleDispatch(obj = {}) {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.SaleRoDetails({
                    dispatch,
                    modeKey,
                    mode,
                    rowId: code,
                }),
            })
        }
        if (mode === "look") {
            handleDispatch({ deleteKey: modeKey })
        }
    }
    useEffect(() => {
        loadData()
    }, [])
    const columns = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '退货单号',
            width: 120,
            dataIndex: 'returnCode',
            fixed: "left",
            render: (text) => <YhTooltip text={text} onClick={() => onLook("look", text)} />,
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line click-span" onClick={(e) => {e.stopPropagation();onLook('look', text)}}>{text}</p></Tooltip>,
        }, 
        {
            title: "原销售单号",
            width: 120,
            dataIndex: "saleCode",
            fixed: "left",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: '单据状态',
            width: 80,
            dataIndex: 'status',
            fixed: "left",
            render: (text) => <YhTag status={ text } text={getTreeNodeName(text)} />,
        }, 
        // {
        //     title: "订单来源",
        //     width: 120,
        //     dataIndex: "orderSourceName",
        //     render: (text) => <YhTooltip text={text} />,
        // },{
        //     title: "单据编号",
        //     width: 120,
        //     dataIndex: "orderCode",
        //     render: (text) => <YhTooltip text={text} />,
        // },
        // {
        //     title: "单据类型",
        //     width: 120,
        //     dataIndex: "orderTypeCode",
        //     render: (text) => <YhTooltip text={getTreeNodeName(text)} />,
           
        // },
        {
            title: '支付状态',
            width: 120,
            dataIndex: 'payStatus',
            render: (text) => <YhTooltip text={getTreeNodeName(text)} />,
        },{
            title: '客户名称',
            width: 150,
            dataIndex: 'customerName',
            render: (text) => <YhTooltip text={text} />,
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '联系人',
            width: 100,
            dataIndex: 'contact',
            render: (text) => <YhTooltip text={text} />,
           //render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '联系电话',
            width: 95,
            dataIndex: 'phone',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '退货数量',
            width: 80,
            dataIndex: 'totalNum',
        }, {
            title: '退货金额',
            width: 80,
            dataIndex: 'receivedAmount',
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '已付金额',
            width: 80,
            dataIndex: 'receivableAmount',
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '退货成本',
            width: 80,
            dataIndex: 'totalCost',
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '结算方',
            width: 80,
            dataIndex: 'settlementPartyName',
            render: (text) => <YhTooltip text={text} />,
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '结算类型',
            width: 80,
            dataIndex: 'payType',
            render: (text) => <YhTooltip text={getTreeNodeName(text)} />,
           
        }, 
        // {
        //     title: '结算方式',
        //     width: 160,
        //     dataIndex: 'payMethodName',
        //     // render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        //     render: (text,record) => <Tooltip placement="topLeft" title={record?.settleDetails?.[0]?.payMethodName ?? record?.settleDetails?.[0]?.accountName}><p className="one-line">{record?.settleDetails?.[0]?.payMethodName ??record?.settleDetails?.[0]?.accountName}</p></Tooltip>,
        // }, 
        {
            title: '开票类型',
            width: 90,
            dataIndex: 'invoiceTypeCode',
            render: (text) => <YhTooltip text={getTreeNodeName(text)} />,
            //render:(text) => <p className="one-line">{getTreeNodeName(text)}</p>
        }, {
            title: '车辆类型',
            width: 90,
            dataIndex: 'vehicleTypeName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '车牌号',
            width: 80,
            dataIndex: 'licensePlate',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: 'VIN码',
            width: 80,
            dataIndex: 'vin',
            render: (text) => <YhTooltip text={text} />,
            //render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        },{
            title: '开票状态',
            width: 80,
            dataIndex: 'invoiceStatus',
            render: (text) => <YhTooltip text={text == 1 ? "已开票" : "未开票"} />,
            //render: (text) => text == 1 ? "已开票" : "未开票"
        }, {
            title: '制单人',
            width: 60,
            dataIndex: 'createUser',
            render: (text) => <YhTooltip text={text} />,
            //render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '制单时间',
            width: 130,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '库管员',
            width: 80,
            dataIndex: 'storekeeper',
            render: (text) => <YhTooltip text={text} />,
            //render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '入库时间',
            width: 130,
            dataIndex: 'stockIoTime',
            render: (text) => momentFormat(text)
        }, {
            title: '退货日期',
            width: 130,
            dataIndex: 'stockIoTime',
            render: (text) => momentFormat(text)
        }, {
            title: '结算日期',
            width: 130,
            dataIndex: 'settleTime',
            render: (text) => momentFormat(text)
        }, {
            title: '结算员',
            width: 80,
            dataIndex: 'reckoner',
            render: (text) => <YhTooltip text={text} />,
            //render: (text) => <p className="one-line">{text}</p>,
        }, 
        // {
        //     title: "付款时间",
        //     width: 120,
        //     dataIndex: "payTime",
        //     render: (text) => momentFormat(text)
        // },
        {
            title: "支付方式",
            width: 120,
            // 缺字段
            // dataIndex: "payMethodName",
            // render:(text) => getTreeNodeName(text)
            render: (text,record) => <YhTooltip text={record?.settleDetails[0]?.payMethodName ?? ''} />,
            //render: (text,record) => record?.settleDetails[0]?.payMethodName ?? '',
        }, 
        // {
        //     title: '核销时间',
        //     width: 130,
        //     dataIndex: 'writeOffTime',
        //     render: (text) => momentFormat(text)
        // }, 
        {
            title: '作废原因',
            width: 120,
            dataIndex: 'cancelReason',
            render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '财务备注',
            width: 120,
            dataIndex: 'fmsNote',
            render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        },{
            title: "收货人",
            width: 120,
            dataIndex: "consignee",
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: "收货电话",
            width: 120,
            dataIndex: "consigneePhone",
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: "收货地址",
            width: 120,
            dataIndex: "consigneeAddress",
            render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '备注',
            width: 120,
            dataIndex: 'note',
            render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, 
        {
          
            title: "所属机构",
            width: 120,
            dataIndex: "shopName",
            render: (text) => <YhTooltip text={text} />,
            //render: (text) => <p className="one-line">{text}</p>,
        },
        {
            title: '打印次数',
            width: 70,
            fixed: "right",
            align: "center",
            dataIndex: 'printingTimes',
        },
    ];


    //点击行选高亮
    const onRow = (record) => {
        let { returnCode } = record
        let findIndexDesc = descRows.findIndex((item) => (item.returnCode === returnCode))
        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(returnCode);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    const onRightClick = (record)=>{
        setDescRows([record])
        setSelectedRowKeys([record.returnCode])
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            loading={loading}
            rowKey={record => record.returnCode}
            rowSelection={{
                onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedRowKeys(selectedRowKeys);
                    setDescRows(selectedRows)
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                // onClick: () => onRow(record.id as string),
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    onRightClick(record)
                }
            })}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}
