import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import TopSearch from "@/components/TopSearch"
import { getImageClassifyList, disableImageClassify } from "@/service/maintenanceService"
import { MyContext } from "@/store/myContext"

//系统配置
const SystemConfig = () => {
    const { dispatch, state, message, detailsPage, Switch } = useContext(MyContext)
    const [stystemList, setStystemList] = useState([]) //列表
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [searchValue, setSearchValue] = useState({
        imageGroup: "",
    })

    useEffect(() => {
        if (state.activeMenu === "imageClassify") {
            loadData()
        }
        return () => setStystemList([])
    }, [state.activeMenu])

    //列表数据
    const loadData = (current) => {
        let data = {
            searchItem: searchValue,
            pageNum: current,
            pageSize: 10,
            orders: [{ column: "updateTime", rule: "DESC" }],
        }
        getImageClassifyList(data).then((res) => {
            // if (res && res.retCode === 200) {
                setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setStystemList(res.retData.list)
            // }
        })
    }
    //分页事件
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }
    //新增 编辑
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "图片分类新增",
            edit: "图片分类编辑",
        }
        let modeKey = "imageClassify/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的图片分类信息")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.ImageClassifyDetail({
                modeKey,
                dispatch,
                mode,
                rowInfo: selectInfo.rowInfo,
            }),
        })
    }
    //配置开关
    const systemSweitchChange = (checked, e, id) => {
        e.stopPropagation()
        disableImageClassify({ id: id, disableFlag: checked ? 0 : 1 }).then((res) => {
            // if (res && res.retCode === 200) {
                message.success("操作成功")
            // } else {
            //     message.error(res.retMsg)
            // }
        })
    }
    //table配置
    const columns = [
        {
            title: "图片名称",
            align: "center",
            dataIndex: "imageName",
        },
        {
            title: "图片编码",
            align: "center",
            dataIndex: "imageCode",
        },
        {
            title: "分组",
            align: "center",
            dataIndex: "imageGroup",
        },
        {
            title: "修改人id",
            align: "center",
            dataIndex: "updateUser",
        },
        {
            title: "图片说明",
            align: "center",
            dataIndex: "imageDesc",
        },
        {
            title: "是否必填",
            align: "center",
            dataIndex: "required",
            render: (text, record) => <span>{text === 0 ? "非必填" : "必填项"}</span>,
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
        },
        {
            title: "操作",
            align: "center",
            width: "12%",
            dataIndex: "disableFlag",
            render: (text, record) => (
                <Switch
                    size="small"
                    defaultChecked={text === 0 ? true : false}
                    checkedChildren="开启"
                    unCheckedChildren="关闭"
                    onChange={(checked, e) => systemSweitchChange(checked, e, record.id)}
                />
            ),
        },
    ]
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入图片名称",
                valueName: "imageName",
            },
            {
                type: "input",
                placeholder: "请输入图片编码",
                valueName: "imageCode",
            },
            {
                type: "input",
                placeholder: "请输入图片分组",
                valueName: "imageGroup",
            },
            {
                type: "Checkbox",
                valueName: "disableFlag",
                placeholder: "禁用状态",
                defaultChecked: searchValue.disableFlag ? true : false,
            },
            {
                type: "Checkbox",
                valueName: "required",
                placeholder: "是否必填",
                defaultChecked: searchValue.required ? true : false,
            },
        ],
    }
    return (
        <>
            {state.activeMenu === "imageClassify" && (
                <>
                    <TopSearch
                        title="订单图片分类"
                        justify="start"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="图片分类列表"
                        buttons={topButtons}
                        columns={columns}
                        dataSource={stystemList}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />
                </>
            )}
        </>
    )
}
export default SystemConfig
