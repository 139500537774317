import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import TopSearch from "@/components/TopSearch"
import { instrumentLightLlassifyPage, updateInstrumentLightLlassify } from "@/service/instrumentLamp"
import { MyContext } from "@/store/myContext"
import { fileLookUrl } from "@/config/app"
import { Image } from "antd"

//系统配置
const InstrumentLampType = () => {
    const { dispatch, state, message, detailsPage, Switch } = useContext(MyContext)
    const [instrumentLampTypeList, setInstrumentLampTypeList] = useState([]) //列表
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })

    useEffect(() => {
        if (state.activeMenu === "instrumentLampType") {
            loadData()
        }
        return () => setInstrumentLampTypeList([])
    }, [state.activeMenu])

    //列表数据
    const loadData = (current) => {
        let data = {
            searchItem: {},
            pageNum: current,
            pageSize: 10,
            orders: [{ column: "updateTime", rule: "DESC" }],
        }
        instrumentLightLlassifyPage(data).then((res) => {
            // if (res && res.retCode === 200) {
                setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setInstrumentLampTypeList(res.retData.list)
            // }
        })
    }
    //分页事件
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }
    //新增 编辑
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "仪表灯类型新增",
            edit: "仪表灯类型编辑",
        }
        let modeKey = "instrumentLampType/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的信息")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.InstrumentLampTypeDetails({
                modeKey,
                dispatch,
                mode,
                rowInfo: selectInfo.rowInfo,
            }),
        })
    }
    //配置开关
    const sweitchChange = (checked, e, id) => {
        e.stopPropagation()
        updateInstrumentLightLlassify({ id: id, classifyShow: checked ? 1 : 0 }).then((res) => {
            // if (res && res.retCode === 200) {
                message.success("操作成功")
            // } else {
            //     message.error(res.retMsg)
            // }
        })
    }
    //table配置
    const columns = [
        {
            title: "分类名称",
            align: "center",
            dataIndex: "classifyName",
        },
        {
            title: "分类描述",
            align: "center",
            dataIndex: "classifyDescribe",
        },
        {
            title: "排序",
            align: "center",
            dataIndex: "classifySort",
        },
        {
            title: "图片",
            align: "center",
            dataIndex: "classifyImage",
            render: (text) => (text ? <Image src={`${fileLookUrl().image}${text}`} width={34} height={34} /> : null )
        },
        {
            title: "操作",
            align: "center",
            width: "12%",
            dataIndex: "classifyShow",
            render: (text, record) => (
                <Switch size="small" defaultChecked={text === 1 ? true : false} onChange={(checked, e) => sweitchChange(checked, e, record.id)} />
            ),
        },
    ]
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
    ]
    return (
        <>
            {state.activeMenu === "instrumentLampType" && (
                <>
                    <TopSearch title="仪表灯分类" />
                    <ListComponent
                        title="仪表灯分类列表"
                        buttons={topButtons}
                        columns={columns}
                        dataSource={instrumentLampTypeList}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />
                </>
            )}
        </>
    )
}
export default InstrumentLampType
