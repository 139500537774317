import React, { useEffect, useState } from "react"
import { Table, Modal, Button, Form, Row, Col, Select, Spin, Input, message, Upload, InputNumber } from "antd"
import { getTeamMemberList } from "@/service/teamService"
import {
    maintainOrderAdd,
    getMaintainOrderOne,
    maintainOrderMaterialBatchAdd,
    maintainOrderMaterialdelete,
    maintainOrderMateriallApplyReturn,
    maintainOrderMateriallApplyEditPrice
} from "@/service/ordersService"
import { EditableRow, EditableCell } from "./editable"
import { download, convertRes2Blob } from "@/utils/exportExcel"
import { API_SERVER_HOST } from "@/config/app"
import Log from "./log"
import { getToken } from "@/utils/dataStorage"
// import { EditableRow, EditableCell } from "../sanbaoEditable"
import debounce from "lodash/debounce"
import YhIcon from "@/components/pubIcon"
import Add from "./add"
import { MaintainOrdeStatus } from "@/utils/enum"
import Loading from "@/components/Loading"
export default (props) => {
    const { visible, setVisible, handleSuc, rowInfo, mode } = props
    const [form] = Form.useForm()
    const [list, setList] = useState([])
    const [addVisible, setAddVisible] = useState(false)
    const [logData, setlogData] = useState({
        visible: false,
        id: "",
    })
    const [loading, setLoading] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    //团队列表
    const [teamInfo, setTeamInfo] = useState({
        id: "", //列表
        data: [],
        fetching: false,
    })

    //成员列表
    const [memberInfo, setMemberInfo] = useState({
        id: "", //列表
        data: [],
        fetching: false,
    })

    useEffect(() => {
        if (rowInfo && rowInfo.id) {
            if (mode === "edit" || mode === "look") {
                getDetail()
            }
        }
    }, [visible, rowInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    //获取详情
    const getDetail = () => {
        getMaintainOrderOne(rowInfo.id).then(({ retData }) => {
            const { orderCode, userPhone, teamId, userId, teamName, userName, materialList,remark } = retData
            form.setFieldsValue({
                orderCode,
                phone: userPhone,
                teamId,
                userId,
                remark
            })
            setTeamInfo({
                id: teamId,
                data: [{ text: teamName, value: teamId }],
                fetching: false,
            })
            setMemberInfo({
                id: userId,
                data: [{ text: userName, value: userId, phone: userPhone }],
                fetching: false,
            })
            setList(
                materialList.map((item) => {
                    return { ...item, addStatus: "old" }
                })
            )
        })
    }

    const columns = [
        {
            title: "商品编号",
            align: "center",
            dataIndex: "materialCode",
        },
        {
            title: "商品名称",
            align: "center",
            dataIndex: "materialName",
        },
        {
            title: "领取数量",
            align: "center",
            dataIndex: "num",
            editable: true,
        },
        {
            title: "采购单价",
            align: "center",
            dataIndex: "price",
            editable: true,
        },
        {
            title: "销售单价",
            align: "center",
            dataIndex: "lateSalePrice",
            editable: true,
        },
        {
            title: "可用库存",
            align: "center",
            dataIndex: "availableStock",
        },
        {
            title: "是否平台",
            align: "center",
            dataIndex: "isPlatform",
            render: (text) => (text ? "是" : "否"),
        },
        {
            title: "库房",
            align: "center",
            dataIndex: "warehouseName",
        },
        {
            title: "货位",
            align: "center",
            dataIndex: "shelfCode",
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
            editable: true,
        },
        {
            title: "操作",
            align: "center",
            width: "80px",
            dataIndex: "operation",
            render: (_, record) => {
                return <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => handleDeleteOne(record)} />
            },
        },
    ]

    const columnsEdit = [
        {
            title: "商品编号",
            align: "center",
            dataIndex: "materialCode",
            width: "100px",
        },
        {
            title: "商品名称",
            align: "center",
            dataIndex: "materialName",
            width: "160px",
        },
        {
            title: "采购单号",
            align: "center",
            dataIndex: "purchaseCode",
            width: "160px",
        },
        {
            title: "领取数量",
            align: "center",
            dataIndex: "pickupNum",
            width: "80px",
        },
        {
            title: "采购单价",
            align: "center",
            dataIndex: "price",
            width: "80px",
        },
        {
            title: "销售单价",
            align: "center",
            dataIndex: "lateSalePrice",
            width: "80px",
        },
        {
            title: "剩余数量",
            align: "center",
            dataIndex: "remainingNum",
            width: "80px",
        },
        {
            title: "退回数量",
            align: "center",
            dataIndex: "returnNum",
            width: "80px",
        },
        {
            title: "是否平台",
            align: "center",
            dataIndex: "isPlatform",
            render: (text) => (text ? "是" : "否"),
            width: "80px",
        },
        {
            title: "状态",
            align: "center",
            dataIndex: "status",
            render: (text) => MaintainOrdeStatus[text],
            width: "80px",
        },
        {
            title: "库房",
            align: "center",
            dataIndex: "warehouseName",
            width: "120px",
        },
        {
            title: "货位",
            align: "center",
            dataIndex: "shelfCode",
            width: "120px",
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
            width: "120px",
        },
        {
            title: "创建人",
            align: "center",
            dataIndex: "createUserName",
            width: "120px",
        },
        {
            title: "日志",
            align: "center",
            dataIndex: "num",
            render: (text, record) => (
                <div
                    style={{ color: "#2e3aeb", cursor: "pointer" }}
                    onClick={() => {
                        handleSeeLog(record)
                    }}
                >
                    查看日志
                </div>
            ),
            width: "120px",
        },
        mode === "look"
            ? {}
            : {
                  title: "操作",
                  align: "center",
                  width: "200px",
                  dataIndex: "operation",
                  render: (_, record) => {
                      if (record.status === "tobe_picked") {
                          return (
                              <>
                                  <Button type="primary" size="small" style={{marginRight:"10px"}} onClick={() => handleEditPrice(record)}>
                                      修改销售单价
                                  </Button>
                                  <Button type="primary" size="small" danger onClick={() => handleDeleteOne(record)}>
                                      删除
                                  </Button>
                              </>
                          )
                      }
                      if (record.status !== "tobe_picked" && record.remainingNum > 0) {
                          return (
                              <Button type="primary" size="small" onClick={() => handleApplyReturn(record)}>
                                  申请退料
                              </Button>
                          )
                      }
                  },
                  fixed: "right",
              },
    ]

    const handleEditPrice = (record) => {
        let lateSalePrice = ""
        Modal.confirm({
            title: "修改销售单价",
            content: (
                <InputNumber placeholder="请输入销售单价" style={{width:"100%"}}  onChange={(e) => {lateSalePrice = e}}/>
            ),
            okText: "确定",
            cancelText: "取消",
            onOk: () => {
                return new Promise((resolve, reject) => {
                    if (lateSalePrice) {
                        if(record.price > lateSalePrice){
                            message.warning("销售单价不能低于采购单价")
                            reject("销售单价不能低于采购单价")
                        }else{
                            resolve(lateSalePrice)
                        }
                        
                    } else {
                        message.warning("请输入正确的销售单价")
                        reject("修改销售单价")
                    }
                }).then((res) => {
                    maintainOrderMateriallApplyEditPrice({id:record.id,lateSalePrice}).finally(res => {
                        getDetail()
                    })
                })
            },
            onCancel: () => {},
        })
    }

    //申请退料
    const handleApplyReturn = (record) => {
        const { id, remainingNum: returnNum } = record
        maintainOrderMateriallApplyReturn({ id, returnNum }).then((res) => {
            message.success("已申请退料")
            getDetail()
        })
    }

    const handleSeeLog = (record) => {
        setlogData({
            visible: true,
            id: record.id,
        })
    }

    //单元格编辑保存
    const handleSave = (row) => {
        const newData = [...list]
        let index = newData.findIndex((item) => row.inventoryDetailsId === item.inventoryDetailsId)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setList(newData)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }
    const maintenanceColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    //成员列表查询
    const memberQuery = (value) => {
        setMemberInfo({
            ...memberInfo,
            data: [],
            fetching: true,
        })
        let teamId = form.getFieldValue("teamId")
        getTeamMemberList({
            pageNum: 1,
            pageSize: 10000,
            searchItem: { disableFlag: 0, userName: value, teamId },
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                arr.push({
                    text: item.userName,
                    value: item.id,
                    userPhone: item.userPhone,
                })
                return arr
            }, [])
            setMemberInfo({
                ...memberInfo,
                data: data,
                fetching: false,
            })
        })
    }
    //防抖
    const memberQuerys = debounce(memberQuery, 1000)
    const handleChangeMember = (v, d) => {
        console.log(v, d)
        form.setFieldsValue({ phone: d.phone })
    }

    const [choosedItem, setChoosedItem] = useState([]) // 批量删除的key
    const maintenanceSelection = {
        columnWidth: "30px",
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRowKeys)
            setChoosedItem(selectedRowKeys)
        },
    }

    //新增物料回调
    const chooseMaterialCbk = (materials) => {
        setAddVisible(false)
        let arr = []
        materials.forEach((item) => {
            if (!list.find((m) => m.inventoryDetailsId === item.id)) {
                let newItem = { ...item, inventoryDetailsId: item.id, id: null,remark:'' }
                arr.push(newItem)
            }
        })
        console.log(JSON.stringify(arr))
        setList([...list, ...arr])
    }

    //批量删除
    const deleteMore = () => {
        if (choosedItem.length === 0) {
            message.warning("请选择你要删除的维修物料")
            return
        }
        let arr = list.filter((item) => choosedItem.indexOf(item.inventoryDetailsId) < 0)
        setList(arr)
        setChoosedItem([])
    }

    //删除
    const handleDeleteOne = (record) => {
        const { id, inventoryDetailsId } = record
        if (id) {
            maintainOrderMaterialdelete(id).then((res) => {
                let arr = [...list]
                let newArr = arr.filter((item) => item.inventoryDetailsId !== inventoryDetailsId)
                setList(newArr)
            })
        } else {
            let arr = [...list]
            let newArr = arr.filter((item) => item.inventoryDetailsId !== inventoryDetailsId)
            setList(newArr)
        }
    }

    //保存
    const handelSave = () => {
        if (mode === "insert") {
            form.validateFields().then((res) => {
                if (list.lenth === 0) {
                    return message("请添加维修物料")
                }
                let materialList = []
                let isPass = true
                list.forEach((item) => {
                    console.log(item)
                    if (!item.price) {
                        isPass = false
                        return message.error(`${item.materialName}的采购价必填`)
                    }
                    if (!item.lateSalePrice) {
                        isPass = false
                        return message.error(`${item.materialName}的销售价必填`)
                    }
                    if (!(item.price > 0)) {
                        isPass = false
                        return message.error(`${item.materialName}的采购价需要大于0`)
                    }
                    if (item.lateSalePrice < item.price) {
                        isPass = false
                        return message.error(`${item.materialName}的销售价需要大于等于采购价`)
                    }

                    materialList.push({
                        inventoryDetailsId: item.inventoryDetailsId,
                        materialName: item.materialName,
                        num: item.num,
                        materialCode: item.materialCode,
                        warehouseName: item.warehouseName,
                        shelfCode: item.shelfCode,
                        price: item.price,
                        lateSalePrice: item.lateSalePrice,
                        remark: item.remark,
                    })
                })
                if (!isPass) {
                    return
                }

                let data = {
                    ...res,
                    materialList,
                }

                setSaveLoading(true)
                maintainOrderAdd(data)
                    .then((res) => {
                        setVisible(false)
                        handleSuc && handleSuc()
                    })
                    .finally(() => {
                        setSaveLoading(false)
                    })
            })
        } else if (mode === "edit") {
            let materialList = []
            list.forEach((item) => {
                if (!item.id) {
                    return materialList.push({ inventoryDetailsId: item.inventoryDetailsId, num: item.num, ciMaintainOrderId: rowInfo.id })
                }
            })
            if (materialList.length > 0) {
                setSaveLoading(true)
                maintainOrderMaterialBatchAdd(materialList)
                    .then((res) => {
                        setVisible(false)
                        handleSuc && handleSuc()
                    })
                    .finally(() => {
                        setSaveLoading(false)
                    })
            } else {
                setVisible(false)
                handleSuc && handleSuc()
            }
        } else {
            setVisible(false)
            handleSuc && handleSuc()
        }
    }
    //导出模版
    const exportTemplete = async () => {
        let data = {
            type: "get",
        }
        let resFlie = await download("/ci/maintain-order/export/template", data)
        if (resFlie && resFlie.status === 200) {
            message.success("已导出")
            convertRes2Blob(resFlie)
        } else {
            message.warning("导出异常，请重新尝试")
        }
    }

    //导入数据
    const onChangeUpload = (info) => {
        setLoading(true)
        if (info.file.status === "done") {
            const { retCode, retData, retMsg } = info.file.response
            if (retCode === 200) {
                console.log(retData)
                let { materialInfos, messages } = retData
                if (materialInfos && materialInfos.length > 0) {
                    let arr = []
                    materialInfos.forEach((item) => {
                        if (!list.find((m) => m.inventoryDetailsId === item.inventoryDetailsId)) {
                            console.log(item)
                            arr.push(item)
                        }
                    })
                    setList([...list, ...arr])
                }
                if (messages && messages.length > 0) {
                    message.error(
                        <>
                            {messages.map((item) => (
                                <p>{item}</p>
                            ))}
                        </>
                    )
                }
            } else {
                message.error(retMsg)
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name}导入异常，请尝试重新导入`)
        }
        setLoading(false)
    }

    return (
        <>
            <Modal
                // mask={false}
                // maskClosable={false}
                forceRender
                visible={visible}
                title="维修领料单"
                onCancel={() => {
                    setVisible(false)
                }}
                centered={true}
                footer={[
                    <Button key="back" type="primary" onClick={handelSave} loading={saveLoading}>
                        保存
                    </Button>,

                    <Button key="link" onClick={() => setVisible(false)}>
                        取消
                    </Button>,
                ]}
                width={1200}
                zIndex={1000}
            >
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={6}>
                                <Form.Item label="订单编号" name="orderCode">
                                    <Input disabled placeholder="自动生成" />
                                </Form.Item>
                            </Col>
                            {/* <Col span={6}>
                                <Form.Item label="团队" name="teamId" rules={[{ required: true, message: "请选择团队" }]}>
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="请选择团队"
                                        notFoundContent={teamInfo.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onChange={handleChangeTeam}
                                        onFocus={() => teamQuery()}
                                        onSearch={teamQuerys}
                                        style={{ width: "100%" }}
                                        disabled={mode === "edit"}
                                    >
                                        {teamInfo.data.map((item) => (
                                            <Select.Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            <Col span={6}>
                                <Form.Item label="领料人" name="userId" rules={[{ required: true, message: "请选择领料人" }]}>
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="请选择领料人"
                                        notFoundContent={memberInfo.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onFocus={() => memberQuery()}
                                        onChange={handleChangeMember}
                                        onSearch={memberQuerys}
                                        style={{ width: "100%" }}
                                        disabled={mode !== "insert"}
                                    >
                                        {memberInfo.data.map((item) => (
                                            <Select.Option value={item.value} key={item.value} phone={item.userPhone}>
                                                {item.text}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="联系电话" name="phone">
                                    <Input disabled placeholder="自动带出" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="备注" name="remark">
                                    <Input disabled={mode!=='insert'} placeholder="请输入备注" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="list-but" style={{ marginBottom: "20px", marginTop: "20px" }}>
                    {mode === "insert" && (
                        <>
                            <Button
                                shape="round"
                                type="primary"
                                className="but-left"
                                style={{ marginRight: "20px" }}
                                onClick={() => {
                                    setAddVisible(true)
                                }}
                            >
                                新增
                            </Button>

                            <Button shape="round" type="primary" className="but-left" style={{ marginRight: "20px" }} onClick={deleteMore}>
                                批量删除
                            </Button>
                            <Upload
                                name="file"
                                method="post"
                                showUploadList={false}
                                headers={{ authorization: getToken() }}
                                action={`${API_SERVER_HOST}/ci/maintain-order/import-conversion`}
                                onChange={onChangeUpload}
                            >
                                <Button shape="round" type="primary" className="but-left" style={{ marginRight: "20px" }}>
                                    导入明细
                                </Button>
                            </Upload>
                            <Button shape="round" type="primary" className="but-left" style={{ marginRight: "20px" }} onClick={() => exportTemplete()}>
                                导出模版
                            </Button>
                        </>
                    )}
                </div>
                <Table
                    size="small"
                    rowKey={(record) => (record.id ? record.id : record.inventoryDetailsId)}
                    components={components}
                    columns={mode === "insert" ? maintenanceColumns : columnsEdit}
                    dataSource={list}
                    pagination={false}
                    scroll={{ x: 1100, y: 660 }}
                    rowSelection={maintenanceSelection}
                />
            </Modal>
            <Add visible={addVisible} onClose={(bool) => setAddVisible(bool)} onItemOk={chooseMaterialCbk} />
            {logData.visible && <Log logData={logData} setLogData={setlogData} />}
            {loading && (
                <Loading
                    style={{
                        background: "rgba(0,0,0,0.5)",
                        height: "100vh",
                        with: "100vh",
                        position: "fixed",
                        left: "0",
                        top: "0",
                        zIndex: 9999,
                    }}
                />
            )}
        </>
    )
}
