import React, { useState, useEffect } from "react"
import { Form, Input, Row, Col, Checkbox, Button, Select, Upload, Modal, Tabs, message } from "antd"
import "./insert.less"
import BraftEditor from "braft-editor"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"

import { ContentUtils } from "braft-utils"
import { getPcodeDict, getToken } from "@/utils/dataStorage"
import { wvSettingTree, wvVehicleVehicleAttr, wvVehicleOne, wvVehicleGetVehicleAttr, wvVehicleUpdate } from "@/service/allCarService"
import { PlusOutlined } from "@ant-design/icons"
const { TabPane } = Tabs

const AllCarEdit = (props) => {
    const { rowInfo, dispatch } = props
    const [id, setID] = useState(0)
    const [form] = Form.useForm()
    const [tree, setTree] = useState([])
    const [series, setSeries] = useState([])
    const [model, setModel] = useState([])
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(null)) //富文本 整车配置

    const [otherConfigEditorState, setOtherConfigEditorState] = useState(BraftEditor.createEditorState(null)) //富文本 其他配置

    const [coreSellPointEditorState, setCoreSellPointEditorState] = useState(BraftEditor.createEditorState(null)) //富文本 核心卖点

    const [moreParamsEditorState, setMoreParamsEditorState] = useState(BraftEditor.createEditorState(null)) //富文本 更多参数

    const [idFileList, setIDFileList] = useState([]) //图片list

    const [attachmentType2FileList, setattachmentType2FileList] = useState([]) //图片list
    const [attachmentType3FileList, setattachmentType3FileList] = useState([]) //图片list

    const [previewImage, setpreviewImage] = useState({
        //预览展示头像MD5
        visible: false,
        md5: "",
    })
    useEffect(() => {
        setID(rowInfo.id)
        wvSettingTree({}).then(async (res) => {
            console.log(res)
            setTree(res.retData)
            resetFormItem(res.retData)
        })
    }, [rowInfo.id])
    // useEffect(() => {
    //     resetFormItem();
    // }, [rowInfo.id])
    useEffect(() => {
        wvVehicleGetVehicleAttr(rowInfo.id).then((res) => {
            console.log(res)
            // if (res && res.retCode === 200) {
                const retData = res.retData
                let idFileList = [],
                    attachmentType2FileList = [],
                    attachmentType3FileList = []
                retData.forEach((item) => {
                    if (item.attachmentType === 1) {
                        const ids = item.attachment.split(",")
                        idFileList = ids.map((id, idx) => {
                            return {
                                uid: idx,
                                status: "done",
                                response: {
                                    retData: id,
                                },
                                url: `${fileLookUrl().image}${id}`,
                            }
                        })
                    }
                    if (item.attachmentType === 2) {
                        const ids = item.attachment.split(",")
                        attachmentType2FileList = ids.map((id, idx) => {
                            return {
                                uid: idx,
                                status: "done",
                                response: {
                                    retData: id,
                                },
                                url: `${fileLookUrl().image}${id}`,
                            }
                        })
                    }
                    if (item.attachmentType === 3) {
                        const ids = item.attachment.split(",")
                        attachmentType3FileList = ids.map((id, idx) => {
                            return {
                                uid: idx,
                                status: "done",
                                response: {
                                    retData: id,
                                },
                                url: `${fileLookUrl().image}${id}`,
                            }
                        })
                    }
                })
                setIDFileList(idFileList)
                setattachmentType2FileList(attachmentType2FileList)
                setattachmentType3FileList(attachmentType3FileList)
            // }
        })
    }, [rowInfo.id])
    const resetFormItem = (trees) => {
        wvVehicleOne(rowInfo.id).then((res) => {
            console.log(res)
            // if (res && res.retCode === 200) {
                const retData = res.retData
                form.setFieldsValue({
                    ...retData,
                })
                retData.vehicleConfig && setEditorState(BraftEditor.createEditorState(retData.vehicleConfig))
                retData.otherConfig && setOtherConfigEditorState(BraftEditor.createEditorState(retData.otherConfig))
                retData.coreSellPoint && setCoreSellPointEditorState(BraftEditor.createEditorState(retData.coreSellPoint))
                retData.moreParams && setMoreParamsEditorState(BraftEditor.createEditorState(retData.moreParams))
                const index = trees.findIndex((item) => item.id === retData.vehicleBrand)
                setSeries(trees[index].subItem || [])
                const idx = trees[index].subItem ? trees[index].subItem.findIndex((item) => item.id === retData.vehicleSeries) : "null"
                setModel(idx === "null" ? [] : trees[index].subItem[idx].subItem)
            // }
        })
    }
    //富文本配置
    const controls = [
        "undo",
        "redo",
        "separator",
        "font-size",
        "line-height",
        "letter-spacing",
        "separator",
        "text-color",
        "bold",
        "italic",
        "underline",
        "strike-through",
        "separator",
        "superscript",
        "subscript",
        "remove-styles",
        "emoji",
        "separator",
        "text-indent",
        "text-align",
        "separator",
        "headings",
        "list-ul",
        "list-ol",
        "blockquote",
        "code",
        "separator",
        "link",
        "separator",
        "hr",
        "separator",
        // 'media', 'separator',
        "clear",
    ]
    //富文本上传图片
    const uploadHandler = (param) => {
        if (param.file.status === "done") {
            setEditorState(
                ContentUtils.insertMedias(editorState, [
                    {
                        type: "IMAGE",
                        url: `${fileLookUrl().image}${param?.file?.response?.retData}`,
                    },
                ])
            )
        }
    }

    const extendControls = [
        {
            key: "antd-uploader",
            type: "component",
            component: (
                <Upload
                    accept="image/*"
                    headers={{ Authorization: getToken() }}
                    action={`${fileUploadUrl}upload/image_old`}
                    showUploadList={false}
                    onChange={uploadHandler}
                >
                    {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
                    <button type="button" className="control-item button upload-button" data-title="插入图片">
                        上传图片
                    </button>
                </Upload>
            ),
        },
    ]
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    )
    const handleIDChange = async (info) => {
        console.log(info)
        // setIDFileList([]);
        console.log(idFileList)
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setpreviewImage({ visible: false, md5: md5 })
            console.log(idFileList)
        }
        setIDFileList(info.fileList)
    }
    return (
        <>
            <Tabs defaultActiveKey="1" onChange={() => {}} size="small" style={{ marginTop: "43px" }} tabBarStyle={{ marginLeft: "10px", marginTop: "43px" }}>
                <TabPane tab="车辆配置编辑" key="1">
                    <div className="list-container" style={{ marginTop: "10px" }}>
                        <div className="list-header">
                            {/* <div className="list-name"><span>移动车详情</span></div> */}
                            <div className="list-but">
                                <Button
                                    shape="round"
                                    className="but-left"
                                    onClick={() => {
                                        form.validateFields().then((fieldsValue) => {
                                            console.log(fieldsValue)
                                            const salesArea = fieldsValue.salesArea
                                                ? Array.isArray(fieldsValue.salesArea)
                                                    ? fieldsValue.salesArea.join(",")
                                                    : fieldsValue.salesArea
                                                : ""

                                            wvVehicleUpdate({
                                                id,
                                                ...fieldsValue,
                                                vehicleOrigin: "import",
                                                salesArea,
                                                vehicleConfig: editorState.toHTML(),
                                                otherConfig: otherConfigEditorState.toHTML(),
                                                coreSellPoint: coreSellPointEditorState.toHTML(),
                                                moreParams: moreParamsEditorState.toHTML(),
                                            }).then((res) => {
                                                console.log(res)
                                                // if (res && res.retCode === 200) {
                                                    setID(res.retData.id)
                                                    message.success("新增成功")
                                                // } else {
                                                //     message.error(res.retMsg)
                                                // }
                                            })
                                            console.log({
                                                ...fieldsValue,
                                                vehicleConfig: editorState.toHTML(),
                                                otherConfig: otherConfigEditorState.toHTML(),
                                                coreSellPoint: coreSellPointEditorState.toHTML(),
                                                moreParams: moreParamsEditorState.toHTML(),
                                            })
                                        })
                                    }}
                                >
                                    保存
                                </Button>
                                <Button
                                    shape="round"
                                    className="but-left"
                                    onClick={() => {
                                        dispatch({
                                            type: "removeTabs",
                                            targetKey: "allCarManage/edit",
                                            activeMenu: "allCarList",
                                        })
                                    }}
                                >
                                    取消{" "}
                                </Button>
                            </div>
                        </div>
                        <div className="form-details-warp" style={{ padding: "0 20px 0 0" }}>
                            <Form form={form} {...formItemLayout}>
                                <div
                                    className="from-line"
                                    style={{
                                        marginRight: "0px",
                                        marginBottom: "5px",
                                    }}
                                ></div>
                                <span className="lit_title">基本信息</span>
                                <Row gutter={20}>
                                    <Col span={6}>
                                        <Form.Item
                                            label="商品名称"
                                            name="vehicleName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入商品名称",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入商品名称" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="公告型号" name="announcementModel">
                                            <Input placeholder="请输入公告型号" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="车辆类型" name="vehicleType">
                                            <Select showSearch allowClear placeholder="请选择车辆类型" style={{ width: "100%" }}>
                                                {getPcodeDict("029").children.map((item) => (
                                                    <Select.Option value={item.dictCode} key={item.id}>
                                                        {item.title}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="驱动形式"
                                            name="driveForm"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入驱动形式",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入驱动形式" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="品牌"
                                            name="vehicleBrand"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请选择品牌",
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                placeholder="请选择品牌"
                                                style={{ width: "100%" }}
                                                onChange={(id) => {
                                                    console.log(id)
                                                    const index = tree.findIndex((item) => item.id === id)
                                                    setSeries(tree[index].subItem || [])
                                                }}
                                            >
                                                {tree.map((item) => (
                                                    <Select.Option value={item.id} key={item.id}>
                                                        {item.settingName}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="车系"
                                            name="vehicleSeries"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请选择车系",
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                placeholder="请选择车系"
                                                style={{ width: "100%" }}
                                                onChange={(id) => {
                                                    console.log(id)
                                                    const index = series ? series.findIndex((item) => item.id === id) : "null"
                                                    setModel(index === "null" ? [] : series[index].subItem)
                                                }}
                                            >
                                                {series.map((item) => (
                                                    <Select.Option value={item.id} key={item.id}>
                                                        {item.settingName}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="车型"
                                            name="vehicleModel"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请选择车型",
                                                },
                                            ]}
                                        >
                                            <Select showSearch allowClear placeholder="请选择车型" style={{ width: "100%" }}>
                                                {model &&
                                                    model.map((item) => (
                                                        <Select.Option value={item.id} key={item.id}>
                                                            {item.settingName}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="车身长度" name="vehicleLength">
                                            <Input placeholder="请输入车身长度" suffix="米" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="产地" name="vehicleProductionAddress">
                                            <Input placeholder="请输入产地" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="最大马力"
                                            name="maxHorsepower"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入最大马力",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入最大马力" suffix="马力" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="排放标准"
                                            name="emissionStandard"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入排放标准",
                                                },
                                            ]}
                                        >
                                            <Select showSearch allowClear placeholder="请选择排放标准" style={{ width: "100%" }}>
                                                {getPcodeDict("032").children.map((item) => (
                                                    <Select.Option value={item.dictCode} key={item.id}>
                                                        {item.title}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                            {/* <Input placeholder="请输入排放标准"  /> */}
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="指导价格"
                                            name="guidePrice"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入指导价格",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入指导价格" suffix="¥" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="燃油类型"
                                            name="fuelType"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请选择燃油类型",
                                                },
                                            ]}
                                        >
                                            <Select showSearch allowClear placeholder="请选择燃油类型" style={{ width: "100%" }}>
                                                {getPcodeDict("031").children.map((item) => (
                                                    <Select.Option value={item.dictCode} key={item.id}>
                                                        {item.title}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div
                                    className="from-line"
                                    style={{
                                        marginRight: "0px",
                                        marginBottom: "5px",
                                    }}
                                ></div>
                                <span className="lit_title">底盘配置</span>
                                <Row gutter={20}>
                                    <Col span={6}>
                                        <Form.Item label="发动机" name="engineConfig">
                                            <Input placeholder="请输入发动机" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="变速箱" name="gearboxConfig">
                                            <Input placeholder="请输入变速箱" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="大梁" name="girderConfig">
                                            <Input placeholder="请输入大梁" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="离合器" name="clutchConfig">
                                            <Input placeholder="请输入离合器" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="商品前桥" name="frontAxleConfig">
                                            <Input placeholder="请输入前桥" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="后桥/速比" name="rearAxleConfig">
                                            <Input placeholder="请输入后桥/速比" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="油箱" name="fuelTankConfig">
                                            <Input placeholder="请输入油箱" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="轮胎" name="tireConfig">
                                            <Input placeholder="请输入轮胎" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="悬挂" name="suspensionConfig">
                                            <Input placeholder="请输入悬挂" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div
                                    className="from-line"
                                    style={{
                                        marginRight: "0px",
                                        marginBottom: "5px",
                                    }}
                                ></div>
                                <span className="lit_title">使用工况</span>
                                <Row gutter={20}>
                                    <Col span={6}>
                                        <Form.Item label="路况" name="roadCondition">
                                            <Input placeholder="请输入路况" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="载重" name="vehicleLoad">
                                            <Input placeholder="请输入载重" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="主要用途" name="mainPurpose">
                                            <Input placeholder="请输入主要用途" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div
                                    className="from-line"
                                    style={{
                                        marginRight: "0px",
                                        marginBottom: "5px",
                                    }}
                                ></div>
                                <span className="lit_title">整车配置</span>
                                <Row gutter={20}>
                                    {/* vehicleConfig  整车配置*/}
                                    <Col span={24}>
                                        <div className="editor_nargin">
                                            <BraftEditor
                                                value={editorState}
                                                controls={controls}
                                                onChange={(e) => setEditorState(e)}
                                                extendControls={extendControls}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <span className="lit_title">其他配置</span>
                                <Row gutter={20}>
                                    <Col span={24}>
                                        {/* otherConfig 其他配置 */}
                                        <div className="editor_nargin">
                                            <BraftEditor
                                                value={otherConfigEditorState}
                                                controls={controls}
                                                onChange={(e) => setOtherConfigEditorState(e)}
                                                extendControls={extendControls}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <span className="lit_title">核心卖点</span>
                                <Row gutter={20}>
                                    <Col span={24}>
                                        {/* coreSellPoint 核心卖点*/}
                                        <div className="editor_nargin">
                                            <BraftEditor
                                                value={coreSellPointEditorState}
                                                controls={controls}
                                                onChange={(e) => setCoreSellPointEditorState(e)}
                                                extendControls={extendControls}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <span className="lit_title">更多参数</span>
                                <Row gutter={20}>
                                    <Col span={24}>
                                        {/* moreParams 更多参数*/}
                                        <div className="editor_nargin">
                                            <BraftEditor
                                                value={moreParamsEditorState}
                                                controls={controls}
                                                onChange={(e) => setMoreParamsEditorState(e)}
                                                extendControls={extendControls}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <div
                                    className="from-line"
                                    style={{
                                        marginRight: "0px",
                                        marginBottom: "5px",
                                    }}
                                ></div>
                                <span className="lit_title">销售区域</span>
                                <Form.Item name="salesArea">
                                    <Checkbox.Group
                                        style={{
                                            width: "100%",
                                            marginLeft: "26px",
                                        }}
                                        onChange={(e) => {}}
                                    >
                                        <Row>
                                            {/* salesArea 销售区域*/}
                                            {getPcodeDict("030").children.map((item) => (
                                                <Col span={4} key={item.id}>
                                                    <Checkbox value={item.dictCode}>{item.title}</Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </TabPane>
                <TabPane tab="车辆图片" key="2" disabled={id === 0}>
                    <div style={{ position: "relative" }}>
                        <Tabs
                            defaultActiveKey="11"
                            onChange={() => {}}
                            size="small"
                            style={{ marginTop: "55px" }}
                            tabBarStyle={{
                                marginLeft: "10px",
                                marginTop: "90px",
                            }}
                        >
                            <TabPane tab="内饰" key="11">
                                <Upload
                                    action={`${fileUploadUrl}upload/image_old`}
                                    headers={{ Authorization: getToken() }}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={idFileList}
                                    onPreview={(file) => {
                                        const md5 = file.response.retData
                                        setpreviewImage({ visible: true, md5 })
                                    }}
                                    onChange={handleIDChange}
                                >
                                    {uploadButton}
                                </Upload>
                                <Button
                                    shape="round"
                                    className="but-left"
                                    onClick={() => {
                                        if (idFileList.length < 1) return
                                        const attachment = idFileList.map((file) => file.response.retData).join(",")
                                        wvVehicleVehicleAttr({
                                            attachmentType: 1,
                                            attachment,
                                            vehicleId: id,
                                        }).then((res) => {
                                            // if (res && res.retCode === 200) {
                                                message.success("新增成功")
                                            // } else {
                                            //     message.error(res.retMsg)
                                            // }
                                        })
                                    }}
                                >
                                    保存{" "}
                                </Button>
                            </TabPane>
                            <TabPane tab="外观" key="22">
                                <Upload
                                    action={`${fileUploadUrl}upload/image_old`}
                                    headers={{ Authorization: getToken() }}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={attachmentType2FileList}
                                    onPreview={(file) => {
                                        const md5 = file.response.retData
                                        setpreviewImage({ visible: true, md5 })
                                    }}
                                    onChange={(info) => {
                                        console.log(info)
                                        if (info.file.status === "done") {
                                            let md5 = info?.file?.response?.retData
                                            setpreviewImage({
                                                visible: false,
                                                md5: md5,
                                            })
                                        }
                                        setattachmentType2FileList(info.fileList)
                                    }}
                                >
                                    {uploadButton}
                                </Upload>
                                <Button
                                    shape="round"
                                    className="but-left"
                                    onClick={() => {
                                        if (attachmentType2FileList.length < 1) return
                                        const attachment = attachmentType2FileList.map((file) => file.response.retData).join(",")
                                        wvVehicleVehicleAttr({
                                            attachmentType: 2,
                                            attachment,
                                            vehicleId: id,
                                        }).then((res) => {
                                            // if (res && res.retCode === 200) {
                                                message.success("新增成功")
                                            // } else {
                                            //     message.error(res.retMsg)
                                            // }
                                        })
                                    }}
                                >
                                    保存{" "}
                                </Button>
                            </TabPane>
                            {/* <TabPane tab="全息" key="33">
                                <Upload
                                    action={`${fileUrl}/file/uploadFile`}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={attachmentType3FileList}
                                    onPreview={(file) => {
                                        const md5 = file.response.retData;
                                        setpreviewImage({ visible: true, md5 })
                                    }}
                                    onChange={(info) => {
                                        console.log(info)
                                        if (info.file.status === "done") {
                                            let md5 = info?.file?.response?.retData;
                                            setpreviewImage({ visible: false, md5: md5 })
                                        }
                                        setattachmentType3FileList(info.fileList);
                                    }}
                                >
                                    {uploadButton}
                                </Upload>
                                <Button shape="round" className="but-left"
                                    onClick={
                                        () => {
                                            if (attachmentType3FileList.length < 1) return
                                            const attachment = attachmentType3FileList.map(file => file.response.retData).join(',')
                                            wvVehicleVehicleAttr({
                                                attachmentType: 3,
                                                attachment,
                                                vehicleId: id
                                            }).then(res => {
                                                if (res && res.retCode === 200) {
                                                    message.success('新增成功');
                                                } else {
                                                    message.error(res.retMsg);
                                                }
                                            })
                                        }
                                    }
                                >保存 </Button>						</TabPane> */}
                        </Tabs>
                    </div>
                </TabPane>
            </Tabs>
            {/* <div className="toolbar"><span className="modules-name">车辆配置</span></div> */}
            <Modal visible={previewImage.visible} title="头像查看" footer={null} onCancel={() => setpreviewImage({ ...previewImage, visible: false })}>
                <img alt="" style={{ width: "100%" }} src={`${fileLookUrl().image}${previewImage.md5}`} />
            </Modal>
        </>
    )
}

export default AllCarEdit
