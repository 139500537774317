import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";
class SaleOrderService {
    async page(searchVo) {
        const apiUrl = "/ci-storage/sale/order/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async total(searchVo) {
        const apiUrl = "/ci-storage/sale/order/total";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async one(id) {
        const apiUrl = `/ci-storage/sale/order/findById/${id}`;
        return await httpRequest.get(apiUrl);
    }

    async insert(insertVo) {
        const apiUrl = "/ci-storage/sale/order/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async update(updateVo) {
        const apiUrl = "/ci-storage/sale/order/update";
        return await httpRequest.post(apiUrl, updateVo);
    }
    // 明细删除
    async materialDelete(params) {
        const apiUrl = "/ci-storage/sale/order/materialDelete";
        return await httpRequest.post(apiUrl, params);
    }
    // 导出
    async export(params) {
        const apiUrl = "/ci-analyze/sale/order/export";
        return await httpRequest.post(apiUrl, params);
    }
    // 转销售单
    async transfer(planCode) {
        const apiUrl = `/ci-storage/sale/order/transfer/${planCode}`;
        return await httpRequest.get(apiUrl);
    }
    // //详情导入
    // async invalidate(){
    //     const apiUrl = "/ci-storage/sale/order/detail/downloadTpl";
    //     return await httpRequest.getFile<boolean>(apiUrl);
    // }
    // 销售订单结算(仅限商城订单)
    async settle(params) {
        const apiUrl = "/ci-storage/sale/order/settle";
        return await httpRequest.post(apiUrl, params);
    }
    
    //下载模板
    async downloadTpl() {
        const apiUrl = "/ci-storage/sale/order/detail/downloadTpl";
        return await httpRequest.getFile(apiUrl);
    }
    //作废
    async invalidate(params) {
        const apiUrl = "/ci-storage/sale/order/invalidate";
        return await httpRequest.post(apiUrl, params);
    }
    //详情导出
    async detailsExport(params) {
        const apiUrl = "/ci-analyze/sale/order/detailsExport";
        return await httpRequest.post(apiUrl, params);
    }
    //打印次数
    async printNum(id) {
        const apiUrl = `/ci-storage/sale/order/print/${id}`;
        return await httpRequest.get(apiUrl);
    }

    // 结算账户查询
    //查询特定得收款账户
    accountFind(params) {
        const apiUrl = "/fms/account/find";
        return httpRequest.post(apiUrl, params);
    }

    // 商城审核 status 0 拒绝 1 通过
    examine(params){
        const apiUrl = "/ci-storage/sale/order/examineOrder";
        return httpRequest.post(apiUrl, params);
    }
}

export const saleOrderService = new SaleOrderService();