import React, { FC } from "react";
import "./style.less";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum";

export const YhTag = ({
    color,
    status,
    text,
    disabled = false,
    onClick = () => null
}) => {

    const statusColor = {
        //采购单
        [DictCodeEnmu.ADVANCED_CREATE]: "blue",
        [DictCodeEnmu.ADVANCED_SUBMIT]: "cyan",
        [DictCodeEnmu.ADVANCED_WAIT_TRANSFER]: "green",
        [DictCodeEnmu.ADVANCED_TRANSFER]: "orange",
        [DictCodeEnmu.ADVANCED_WAIT_AUDIT]: "geekblue",
        [DictCodeEnmu.ADVANCED_WAIT_CONFIRM]: "red",
        //采购计划
        [DictCodeEnmu.PPLAN_TRANSFER]: "blue",
        [DictCodeEnmu.PPLAN_CREATE]: "cyan",
        //采购单
        [DictCodeEnmu.PO_CREATE]: "blue",
        [DictCodeEnmu.PO_COMPLETELY_OUT]: "orange",
        [DictCodeEnmu.PO_COMPLETELY_IO]: "cyan",
        [DictCodeEnmu.PO_BILL]: "green",
        [DictCodeEnmu.PO_SETTLE]: "green",
        [DictCodeEnmu.PO_CHANGE]: "red",
        [DictCodeEnmu.PO_WRITE_OFF]: "geekblue",
        //采购退货
        [DictCodeEnmu.PRO_CREATE]: "blue",
        [DictCodeEnmu.PRO_CHANGE]: "red",
        [DictCodeEnmu.PRO_STOCK_IO]: "cyan",
        [DictCodeEnmu.PRO_SETTLE]: "green",
        [DictCodeEnmu.PRO_BILL]: "green",
        [DictCodeEnmu.PRO_WRITE_OFF]: "green",

        // 销售订单
        [DictCodeEnmu.SALE_ORDER_CREATE]:'blue',
        [DictCodeEnmu.SALE_ORDER_CHANGE]:'cyan',
        [DictCodeEnmu.SALE_ORDER_TRANSFER]:'red',
        [DictCodeEnmu.SALE_ORDER_SETTLE]:'green',
        [DictCodeEnmu.SALE_ORDER_REJECT]:'geekblue',
        // 销售单
        [DictCodeEnmu.SALE_REPLENISH_STATUS_REPLENISH]: 'green',
        [DictCodeEnmu.SALE_SETTLE]: 'green',
        [DictCodeEnmu.SALE_CHANGE]: 'red',
        [DictCodeEnmu.SALE_STOCK_IO]: 'cyan',
        [DictCodeEnmu.SALE_CREATE]: 'blue',
        [DictCodeEnmu.SALE_SIGN]: 'pink',
        // 销售退货单
        [DictCodeEnmu.SALE_RETURN_STATUS_WRITE_OFF]:'green',
        [DictCodeEnmu.SALE_RETURN_STATUS_CHANGE]:'red',
        [DictCodeEnmu.SALE_RETURN_STATUS_SETTLE]:'cyan',
        [DictCodeEnmu.SALE_RETURN_STATUS_STOCK_IO]:'green',
        [DictCodeEnmu.SALE_RETURN_STATUS_CREATE]:'blue',

        // 上架状态
        [DictCodeEnmu.DISPLAY_STATUS_ALREADY]:'green',
        [DictCodeEnmu.DISPLAY_STATUS_WAITING]:'cyan'
    }

    return <span
        className={`ant-tags ant-tags-${color ?? statusColor[status]} ${disabled ? 'yh-tag-disabled' : ''}`}
        onClick={() => { !disabled && onClick() }}
    >{text}</span>
}