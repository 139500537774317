import { useState, Key, useEffect } from "react"
import { createModel } from "hox"
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel"
import { saleRoService } from "../../saleRoService"
import { formatNum } from "@/erp_subpackage/utils/util"
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile"
import { useLockFn } from "ahooks"
import { message } from "antd"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { IsFranchisees } from '@/utils/common'

export const useSaleRoDetailModel = createModel(function () {
    const {
        user: { shopId, shopName, shopCode, userName, orgName, orgCode, bentityName, bentityCode },
    } = useGlobalModel()
    const { getTreeNodeName } = useDictModel();
    const [logisticsDraer, setLogisticsDraer] = useState(false) //物流登记弹框
    const [settleVisible, setSettleVisible] = useState(false) //结算弹框

    const [changeSaleDrawer, serChangeSaleDrawer] = useState(false) //转销售单弹框
    const [wsConfirmModal, setWsConfirmModal] = useState(false) //库房确认弹框
    const [registerModal, setRegisterModal] = useState(false) //入库登记弹框
    const [discountModal, setDiscountModal] = useState(false) //折扣弹框

    const [addModalVisible, setAddModalVisible] = useState(false) //新增商品弹框
    const [vehicleEditAll, setVehicleEditAll] = useState([]) //实时切换车辆 编辑
    const [vehicleAddAll, setVehicleAddAll] = useState([]) //实时切换车辆 新增
    const [vinEditAll, setVinEditAll] = useState([])  // 实时切换vin编辑
    const [vinAddAll, setVinAddAll] = useState([]) // 实时切换vin  新增
    const initDesc = {
        numAll: 0, //数量
        itemNumAll: 0, //项数
        totalAll: 0, //总计
        discountAll: 0, //折扣,
        receivableAll: 0, //应收
    }
    const initForm = {
        shopName,
        createUser: userName,
        bentityName: bentityName,
        favorableAmount: 0,
        orderSourceCode: DictCodeEnmu.SOURCE_ADD,
        orderSourceName: "新增",
    }
    const initPgCfg = { pageSize: 15, current: 1 }
    const initSearchParam = {}
    //新增数据
    //新增详情form
    const [detailAddForm, setDetailAddForm] = useState(initForm)
    //新增详情列表
    const [detailAddList, setDetailAddList] = useState([])
    //新增详情列表选中行
    const [detaiAddDataRowKeys, setDetaiAddDataRowKeys] = useState([])
    const [detaiAddDataRow, setDetaiAddDataRow] = useState([])
    const [addDesc, setAddDesc] = useState(initDesc)

    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState({})
    //查看详情列表
    const [detailLookList, setDetailLookList] = useState([])
    const [lookDesc, setLookDesc] = useState(initDesc)
    //查看结算详情
    const [lookSettleDetails, setLookSettleDetails] = useState([])
    //详情数据
    //详情列表参数
    const [detailDataSource, setDetailDataSource] = useState([])
    //详情表格选中行
    const [detailDataRowKeys, setDetailDataRowKeys] = useState([])
    const [detailDataRow, setDetailDataRow] = useState([])
    const [detailEditForm, setDetailEditForm] = useState({})
    const [editDesc, setEditDesc] = useState(initDesc)
    //编辑结算详情
    const [editSettleDetails, setEditSettleDetails] = useState([])
    //商品列表配置
    const [workDataSource, setWorkDataSource] = useState([])
    const [searchWorkData, setSearchWorkData] = useState(initSearchParam)
    const [workPgCfg, setWorkPgCfg] = useState(initPgCfg)
    //已选商品列表
    const [addDataSource, setAddDataSource] = useState([])
    //表单改变或表格数据发生改变时，控制功能按钮的启用禁用
    const [topButsControl, setTopButsControl] = useState(false)

    //实时详情列表货位数据
    const [shelfAllList, setShelfAllList] = useState([])

    const [saveBtnLoading, setSaveBtnLoading] = useState(false); //  保存按钮
    //设置底部总数展示（查看|编辑|查看）
    useEffect(() => {
        let newDesc = initDesc
        detailDataSource &&
            detailDataSource.forEach((item) => {
                newDesc.numAll += Number(item.num)
                newDesc.totalAll += Number(item.amount)
            })
        newDesc.itemNumAll = detailDataSource && detailDataSource.length
        setEditDesc(newDesc)
    }, [detailDataSource, detailEditForm])

    useEffect(() => {
        let newDesc = initDesc
        detailAddList &&
            detailAddList.forEach((item) => {
                newDesc.numAll += Number(item.num)
                newDesc.totalAll += Number(item.amount)
            })
        newDesc.itemNumAll = detailAddList && detailAddList.length
        setAddDesc(newDesc)
    }, [detailAddList, detailAddForm])

    useEffect(() => {
        let newDesc = initDesc
        detailLookList &&
            detailLookList.forEach((item) => {
                newDesc.numAll += Number(item.num)
                newDesc.totalAll += Number(item.amount)
            })
        newDesc.itemNumAll = detailLookList && detailLookList.length
        setLookDesc(newDesc)
    }, [detailLookList, detailLookForm])

    //新增表单缓存
    const updateAddFormVal = (val) => {
        let newVal = val
        setDetailAddForm({ ...detailAddForm, ...newVal })
    }
    //编辑表单缓存
    const updatEditFormVal = (val) => {
        let newVal = val
        setDetailEditForm({ ...detailEditForm, ...newVal })
    }
    //获取一条编辑或查看数据
    const getDetailEditOne = async (id, pattern) => {
        // debugger
        let { retData } = await saleRoService.one(id)
        let { details, settleDetails, ...from } = retData
        const newDetails = details?.map((item) => {
            return {
                ...item,
                notAmount: +formatNum(Number(item.num) * Number(item.price)),
                detailsId: item.id,
                discountAmount: +formatNum(item.discountAmount) ?? +formatNum(Number(item.num) * Number(item.price) * (Number(item.discountRate) / 100)) ?? 0,
            }
        })
        if(settleDetails && settleDetails.length > 0){
            const { cashAttach, cashAttachFileName } = settleDetails[0]
            if(cashAttach && cashAttachFileName){
                from.cashAttach = cashAttach
                from.cashAttachFileName = cashAttachFileName
            }
        }
        if (retData && pattern === "edit") {
            setDetailDataSource(newDetails ?? [])
            setDetailEditForm(from)
            setTopButsControl(false)
            setEditSettleDetails(settleDetails ?? [])
        } else if (retData && pattern === "look") {
            setDetailLookForm({ ...from, cashName: settleDetails?.[0]?.payMethodName })
            setDetailLookList(newDetails ?? [])
            setLookSettleDetails(settleDetails ?? [])
        }
        return retData
    }
    //关闭弹框
    const resetWorkloadData = async (formVal, params) => {
        setSearchWorkData({ ...params })
        workloadData(formVal, initPgCfg, { ...params })
    }
    //加载商品数据
    const workloadData = async (formVal, page, searchValue = searchWorkData) => {
        let param = {...searchWorkData,materialName:searchWorkData.materialName?.trim(),materialCode:searchWorkData.materialCode?.trim()}
        setSearchWorkData(param)
        const {customerCode, bentityCode} = formVal
        const searchParams = {
            ...param,
            shopId,
            shopName,
            shopCode,
            pageSize: page?.pageSize ?? workPgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: page?.pageNum ?? workPgCfg.current ?? initPgCfg.current,
            bentityCode,
            customerCode
        }
        let { retData } = await saleRoService.saleDetails(searchParams)
        const { records, ...pg } = retData
        const newRecords = records.map((item) => {
            let { price, discountRate, sreturnNum, leftDecreaseAmount, decreaseAmount } = item;
            //可退的数量 = 销售数量 - 已退数量
            let num = item.num - sreturnNum;
            //退货金额 = 可退的数量 * 单价 * 折扣
            let amount = +formatNum(num * price * discountRate / 100);
            //之前退过商品， 剩余的退货金额 - 前面已优惠金额
            if (leftDecreaseAmount) {
                amount = +formatNum(amount - leftDecreaseAmount);
            }
            return {
                ...item,
                saleNum: item.num,
                salePrice: item.price,
                saleTotal: item.amount,
                price: item.price,
                detailsId: item.id,
                num,
                amount,
                decreaseAmount: leftDecreaseAmount
            }
        })
        setWorkPgCfg(transPgToPagination(pg))
        setWorkDataSource(newRecords)
    }
    //筛选模块详情 详情和新增的列表已有数据的方法
    const modelScreenMethod = async (modelList, detailList) => {
        const repetition = []
        const newDetailData = []
        console.log(modelList)
        modelList.forEach((Item) => {
            let isSelect = detailList && detailList.find((item) => item.detailId === Item.id)
            if (isSelect === undefined) {
                newDetailData.push({
                    ...Item,
                    detailId: Item.id,
                    id: "",
                    amount: +formatNum(Number(Item.amount) - Number(Item.decreaseAmount)),  //退货金额
                    discountAmount: +formatNum(Number(Item.num) * Number(Item.price) * (Number(Item.discountRate) / 100)),  //退货折扣金额
                    rdecreaseAmount: +formatNum(Item.decreaseAmount) ?? 0  //单向优惠金额
                })
            } else {
                repetition.push(Item.materialName)
            }
        })
        return { newDetailData, repetition }
    }
    //添加到详情列表
    const transformsToPoDetailList = async (mode) => {
        //添加列表
        if (mode === "add") {
            const { repetition, newDetailData } = await modelScreenMethod(addDataSource, detailAddList)
            setDetailAddList([...detailAddList, ...newDetailData])
            repetition.length !== 0 && message.warning(`商品名称：${repetition.join(",")}，采购单号相同，重复的将不在添加。`)
        } else if (mode === "edit") {
            //详情列表
            const { repetition, newDetailData } = await modelScreenMethod(addDataSource, detailDataSource)
            setDetailDataSource([...detailDataSource, ...newDetailData])
            repetition.length !== 0 && message.warning(`商品名称：${repetition.join(",")}，采购单号相同，重复的将不在添加。`)
        }
    }
    //删除详情列表数据
    const detailDetailList = async (mode, Material) => {
        if (mode === "add") {
            // const filterDetailList = detailAddList.filter(el => !!!detaiAddDataRowKeys.find(ele => ele === el.materialCode))
            const filterDetailList = Material
                ? detailAddList.filter((el) => !!!(Material.detailsId === el.detailsId))
                : detailAddList.filter((el) => !!!detaiAddDataRowKeys.find((ele) => ele === el.detailsId))
            setDetailAddList(filterDetailList)
            message.success("删除成功")
            setDetaiAddDataRowKeys([])
            setDetaiAddDataRow([])
        } else if (mode === "edit") {
            const backendDetailList = Material
                ? Material.id
                    ? [{ id: Material.id }]
                    : []
                : detailDataRow.reduce((all, next) => {
                    //前端页面维护的需要删除的id
                    if (next.id) {
                        all.push({ id: next.id })
                    }
                    return all
                }, [])

            //删除详情列表调用的方法
            const deleteData = () => {
                // const filterDetailList = detailDataSource.filter(el => !!!detailDataRowKeys.find(ele => ele === el.detailsId))
                const filterDetailList = Material
                    ? detailDataSource.filter((el) => !!!(Material.detailsId === el.detailsId))
                    : detailDataSource.filter((el) => !!!detailDataRowKeys.find((ele) => ele === el.detailsId))
                setDetailDataSource(filterDetailList)
                message.success("删除成功")
                setDetailDataRowKeys([])
                setDetailDataRow([])
            }

            if (backendDetailList.length > 0) {
                await saleRoService.materialDelete({ ids: backendDetailList, returnCode: detailEditForm.returnCode }).then((res) => {
                    if (res.retData) {
                        deleteData()
                    } else {
                        message.success("删除失败")
                    }
                })
            } else {
                deleteData()
            }
        }
    }
    //新增采购单
    const insertDetail = useLockFn(async (params) => {
        setSaveBtnLoading(true)
        let newParams = {
            ...params,
            note: (params.note ?? ""),
            details: detailAddList.map(item => {
                let { discountAmount, ...params } = item
                return params
            }),
            createUser: userName,
            // bentityName, bentityCode,
            shopId,
            shopName,
            shopCode,
            orgName,
            orgCode,
            orderTypeCode: params.type === 'saleSo' ? DictCodeEnmu.SALE_TYPE_TRANSFER : DictCodeEnmu.TRADE_TYPE_SALE_RETURN,
            orderTypeName: params.type === 'saleSo' ? getTreeNodeName(DictCodeEnmu.SALE_TYPE_TRANSFER) : getTreeNodeName(DictCodeEnmu.TRADE_TYPE_SALE_RETURN)
        }
        let { retData } = await saleRoService.insert(newParams)
        setSaveBtnLoading(false)
        return retData
    })
    //编辑采购单
    const editDetail = useLockFn(async (params) => {
        setSaveBtnLoading(true)
        let newParams = {
            ...params,
            // note: "测试" + (params.note ?? ""),
            details: detailDataSource.map(item => {
                let { discountAmount, ...params } = item
                return params
            }),
            updateUser: userName,
            // bentityName, bentityCode,
            shopId,
            shopName,
            shopCode,
            orgName,
            orgCode,
        }
        let { retData } = await saleRoService.update(newParams)
        setSaveBtnLoading(false)
        return retData
    })
    //关闭页签清不同状态缓存
    const resetAddCatch = async () => {
        setDetailAddList([])
        setDetailAddForm({})
    }
    const resetEditCatch = async () => {
        setDetailEditForm({})
        setDetailDataSource([])
    }
    const downloadTpl = async () => {
        let result = await saleRoService.downloadTpl()
        convertRes2Blob(result)
    }
    const warehousing = async () => {
        let { id, returnCode } = detailEditForm
        let paramas = {
            id,
            returnCode,
            storekeeper: userName,
            // storekeeper: user.username
        }
        let { retData } = await saleRoService.warehousing(paramas)
        return retData
    }
    const onSettle = async (params) => {
        let { retData } = !IsFranchisees() ? await saleRoService.settle(params) : await saleRoService.settleJoin(params);
        return retData
    }

    // 验收
    const onExamine = async (params) => {
        let data = {
            ...params,
        }
        let { retData } = await saleRoService.examine(data)
        return retData
    }
    return {
        vinEditAll,
        vinAddAll,
        settleVisible,
        changeSaleDrawer,
        wsConfirmModal,
        registerModal,
        logisticsDraer,
        discountModal,
        setSettleVisible,
        serChangeSaleDrawer,
        setWsConfirmModal,
        setRegisterModal,
        setLogisticsDraer,
        setDiscountModal,
        vehicleEditAll,
        setVehicleEditAll,
        vehicleAddAll,
        setVehicleAddAll,
        // data
        addModalVisible,
        initPgCfg,
        detailAddForm,
        detailAddList,
        detaiAddDataRow,
        addDesc,
        detailLookForm,
        detailLookList,
        lookDesc,
        detailDataSource,
        detailDataRowKeys,
        detailDataRow,
        detailEditForm,
        editDesc,
        workDataSource,
        searchWorkData,
        workPgCfg,
        addDataSource,
        shelfAllList,
        detaiAddDataRowKeys,
        // model
        setVinEditAll,
        setVinAddAll,
        setAddModalVisible,
        setDetailAddForm,
        setDetailAddList,
        setDetaiAddDataRow,
        setAddDesc,
        setDetailLookForm,
        setDetailLookList,
        setLookDesc,
        setDetailDataSource,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailEditForm,
        setEditDesc,
        setWorkDataSource,
        setSearchWorkData,
        setWorkPgCfg,
        setAddDataSource,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        resetWorkloadData,
        workloadData,
        transformsToPoDetailList,
        detailDetailList,
        insertDetail,
        editDetail,
        resetAddCatch,
        resetEditCatch,
        setShelfAllList,
        setDetaiAddDataRowKeys,
        downloadTpl,
        modelScreenMethod,
        warehousing,
        onSettle,
        initForm,
        topButsControl,
        setTopButsControl,
        lookSettleDetails,
        setLookSettleDetails,
        editSettleDetails,
        setEditSettleDetails,
        onExamine,setSaveBtnLoading,saveBtnLoading,
    }
})
