import { useState, useEffect } from "react";
import { createModel } from "hox";
import useStorageState from "@/erp_subpackage/hooks/useStorageState";
import { dictService } from "./dictService";
import { forFind } from "@/erp_subpackage/utils/util";
import { defaultMdCmParam } from "@/erp_subpackage//model/globalModel";
import { message } from "antd";

function listChangeTree(list) {
    let menuObj = {}
    list.forEach(item => {
        item.children = []
        menuObj[item.code] = item
    })
    console.log(menuObj)
    return list.filter(item => {
        if (item.pcode !== null && item.pcode !== "") {
            menuObj[item.pcode] && menuObj[item.pcode].children.push(item)
            return false
        }
        return true
    })
}

export const useDictModel = createModel(function () {
    const { sourceApp, branch } = defaultMdCmParam;
    const [treeSource, setTreeSource, clearTreeSource] = useStorageState("ci.dictTree", []);  //字典数据源
    const [originDictList, setOriginDictList] = useStorageState("ci.dictList", []);  //字典数据源
    const [selectedNodes, setSelectedNodes] = useState([]);   //树选中的行信息
    const [mode, setMode] = useState("");
    const [formVal, setFormVal] = useState(selectedNodes[0]);  //表单缓存

    //实时缓存
    const onChangeFormVal = (changedValues) => {
        setFormVal({ ...formVal, ...changedValues });
    }

    const initDictFormFields = {
        pcode: "",
        code: "",
        name: "",
        note: "",
        ver: 0,
        sourceAuthKey: "",
        children: [],
        enable: 0,
        status: 0,
        branch,
        value: "",
        key: ""
    }
    useEffect(() => {
        if (mode === "add") {
            setFormVal({ ...selectedNodes[0], ...initDictFormFields, pcode: selectedNodes[0].pcode, shopCode: [] })
        } else if (mode === "nextInsert") {
            setFormVal({ ...selectedNodes[0], ...initDictFormFields, pcode: selectedNodes[0].code, shopCode: [] })
        }
    }, [mode])

    const loadData = async () => {
        let data = {
            sourceApp,
            pageSize: -1,
            pageNum: 1,
        }
        let { retData } = await dictService.pages(data);
        let { records } = retData;
        setOriginDictList(records);
        setTreeSource(listChangeTree(records));
    }

    //同步
    const sync = async () => {
        let { retData } = await dictService.sync({
            sourceApp,
            branchList: [branch, "main", "vrm", "scm"]
        });
        if (retData) {
            loadData();
            message.success("同步成功。。。")
        }
    }

    //重置
    const reset = () => {
        if (mode === "add") {
            setFormVal({ ...selectedNodes[0], ...initDictFormFields, pcode: selectedNodes[0].pcode, branch, shopCode: [] })
        }
        else if (mode === "nextInsert") {
            setFormVal({ ...selectedNodes[0], ...initDictFormFields, pcode: selectedNodes[0].code, branch, shopCode: [] })
        }
        else if (mode === "edit") {
            setFormVal(selectedNodes[0])
        }
    }
    //表单提交
    const onSubmit = async () => {
        // return console.log(formVal)
        let { code, pcode, note, name, enable, nameEn, status, value, branch } = formVal;
        let data = {
            sourceApp, branch,
            code, pcode, note, name, nameEn, value,
            enable: enable ? 1 : 0,
            status: status ? 0 : 1,
        }
        // if (branch !== defaultMdCmParam.branch) {
        //     message.warning("您无权修改ci以外分支的字典")
        //     return;
        // }
        if (!data.code) {
            message.warning("编码为必填项")
            return;
        }
        if (mode === "add" || mode === "nextInsert") {
            let { retData } = await dictService.insert(data);
            retData && message.success("字典增加成功");
            loadData();
            setSelectedNodes([]);
        } else if (mode === "edit") {
            let { retData } = await dictService.update(data);
            retData && message.success("字典编辑成功");
            loadData();
            setSelectedNodes([]);
        }
    }

    //根据code返回 select框的options 格式；
    const getSelectedDictOne = (code, branch) => {
        if (!code) return [];
        let findTree = forFind(treeSource, code, "code");
        let tempArr = [];
        if (!findTree) return [];

        findTree.children.forEach(item => {
            if (!item.status) {
                if (branch) {
                    if (branch.indexOf(item.branch) > -1) {
                        tempArr.push(item.value ? { label: item.name, value: item.code, relationvalue: item.value } : { label: item.name, value: item.code })
                    }
                } else {
                    tempArr.push(item.value ? { label: item.name, value: item.code, relationvalue: item.value } : { label: item.name, value: item.code })

                }
                // if (item.enable && item.branch === defaultMdCmParam.branch) {
            }
        })
        return tempArr;
        // return findTree ? findTree.children.map(item => {
        //     let { value } = item;
        //     return value ? { label: item.name, value: item.code, relationvalue: value } : { label: item.name, value: item.code }
        // }) : []
    }

    //根据code 返回对应的node节点名称
    const getTreeNodeName = (code) => {
        if (!code) return code;
        let findNode = originDictList.find(item => item.code === code);
        return findNode ? findNode["name"] : ""
        
    }

    //根据code 返回对应树节点
    const getTreeNodeOne = (code) => {
        if (!code) return [];
        let findTree = forFind(treeSource, code, "code");
        return findTree ? [findTree] : [];
    }

    const mapTree = (org) => {
        const haveChildren = Array.isArray(org.children) && org.children.length > 0;
        return {
            title: org.name,
            value: org.code,
            //判断它是否存在子集，若果存在就进行再次进行遍历操作，知道不存在子集便对其他的元素进行操作
            children: haveChildren ? org.children.map(i => mapTree(i)) : []
        };
    }
    //根据code 返回treeSelect 数据格式
    const getTreeSelectOne = (code) => {
        let findTree = forFind(treeSource, code, "code");
        return findTree ? mapTree(findTree).children : [];
    }
    return {
        /***field***/
        treeSource,
        selectedNodes, originDictList,
        mode,
        formVal,
        /***method***/
        setTreeSource,
        setSelectedNodes,
        setMode,
        setFormVal,
        onChangeFormVal,
        loadData,
        onSubmit,
        getTreeNodeName,
        getSelectedDictOne,
        getTreeNodeOne,
        getTreeSelectOne,
        sync, reset
    }
});