import {useState} from "react";
import {createModel} from "hox";
import useGlobalModel, {transPgToPagination} from "@/erp_subpackage/model/globalModel";
import {goodsService} from "./goodsService";
import {message} from "antd";
import {convertRes2Blob} from "@/erp_subpackage/utils/exportFile";

export const useGoodsModel = createModel(function () {
    const {user: {shopId, shopName, shopCode, userName, organType}} = useGlobalModel()
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState({spinning: false, tip: "加载中..."});
    const [uploading, setUploading] = useState({spinning: false, tip: "加速上传中..."});
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //分页参数
    const initPgCfg = {pageSize: 15, current: 1}
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [saveBtnLoading, setSaveBtnLoading] = useState(false); //  保存按钮
    //列表查询
    const loadData = async (page, searchValue = searchParam) => {
        setLoading({spinning: true, tip: "列表加载中..."});
        const searchParams = {
            ...searchValue,
            shopId: searchValue.shopId ?? shopId,
            shopName, shopCode,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: page?.pageNum ?? pgCfg.current ?? initPgCfg.current,
        }
        let {retData} = await goodsService.page(searchParams);
        const {records, ...pg} = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
        setLoading({spinning: false});
    }
    //设置搜索value
    const updateSearchParam = (updateParam) => {
        setSearchParam({...searchParam, ...updateParam})
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    //导出
    const exportGoods = (searchValue = searchParam) => {
        const searchParams = {
            ...searchValue,
            shopId: searchValue.shopId ?? shopId,
            shopName, shopCode,
            pageSize: pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: pgCfg.current ?? initPgCfg.current,
            ids: selectedRowKeys,
        }
        goodsService.export(searchParams).then(res => {
            message.success(res.retData)
        })
    }

    //下载模板
    const downloadTpl = async (id) => {
        let result = await goodsService.downloadTpl();
        convertRes2Blob(result)
        return result;
    }

    //启用禁用
    const onDisabled = async (id, checked) => {
        let {retData} = await goodsService.disable({id, enable: checked ? 1 : 0});
        loadData();
        message.success("操作成功");
        return retData;
    }
    /**
     * 表单详情
     */
    const initFormVal = {
        shopName,
        createUser: userName,
        enable: true
    }
    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState({});
    //新增表单缓存
    const [addFormVal, setAddFormVal] = useState(initFormVal);

    //表单修改
    const updateFormVal = (mode, val) => {
        const setFormVal = mode === "edit" ? setEditFormVal : setAddFormVal;
        setFormVal(origin => val ? {...origin, ...val} : initFormVal);
    }

    //获取一条详情数据 Api
    const getGoodsOne = async (id) => {
        let {retData} = await goodsService.one(id);
        let newRetData = {...retData, photo: retData.photo?.map(item => item.photoMd5) ?? []}
        retData && setEditFormVal(newRetData);
    }

    //表单新增 Api
    const insertGoods = async (data) => {
        setSaveBtnLoading(true)
        try{
            let {retData} = await goodsService.insert({
                ...data,
                shopId, shopName, shopCode, userName, sourceApp: "ci"
            });
            message.success("商品新增成功");
            setSaveBtnLoading(false)
            return retData;
        }catch{
            setSaveBtnLoading(false)
        }
       
       
    }

    //表单编辑 Api
    const editGoods = async (data) => {
        setSaveBtnLoading(true)
        try{
            let {retData} = await goodsService.update({
                ...data,
                shopId, shopName, shopCode, sourceApp: "ci"
            });
            message.success("商品编辑成功");
            setSaveBtnLoading(false)
            return retData;
        }catch{
            setSaveBtnLoading(false)
        }
    }
    //同步
    const sync = async (params = {}) => {
        setUploading({spinning: true, tip: "正在同步中..."})
        try {
            let result = await goodsService.sync({
                ...params,
                shopId,
                shopName,
                "sourceApp": "ci"
            });
            loadData()
            setUploading({spinning: false})
            message.success("同步成功")
        } catch (err) {
            setUploading({spinning: false})
        }
    }


    return {
        /***列表***/
        searchParam, shopId, organType, shopName,
        dataSource,
        selectedRowKeys,
        pgCfg,
        loading,
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        downloadTpl,
        exportGoods,
        onDisabled,
        /*详情 */
        editFormVal,
        addFormVal,
        updateFormVal,
        insertGoods,
        editGoods,
        getGoodsOne,
        uploading, setUploading, sync,setSaveBtnLoading,saveBtnLoading
    }
});
